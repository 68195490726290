// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/pagination";
import "swiper/css/navigation";
// import "swiper/css/scrollbar";

// import required Swiper modules
import { Autoplay, Navigation } from "swiper";
import { Img } from "components/commons/Img";
import slugs from "navigation/slugs";

// import NFL3_slide1 from "assets/slides/NFL3_slide1.svg";
// import main_slide2_v1 from "assets/slides/main_slide2_v1.svg";
// import main_slide3_v1 from "assets/slides/main_slide3_v1.svg";
// import main_slide5_v1 from "assets/slides/main_slide5_v1.svg";
// import main_slide6_v1 from "assets/slides/main_slide6_v1.svg";
// import main_slide7_v1 from "assets/slides/main_slide7_v1.svg";
// import main_slide8_v1 from "assets/slides/main_slide8_v1.svg";
// import main_slide9_v1 from "assets/slides/main_slide9_v1.svg";
// import main_slide10_v1 from "assets/slides/main_slide10_v1.svg";
// import mlb_event from "assets/slides/mlb-event.svg";

// const previousData = [
//   {
//     keyID: "1",
//     title: "mainnet live now",
//     desc: "Know more about us here.",
//     url: "/sports/american-football",
//     style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
//     wallpaper: NFL3_slide1,
//     target: "",
//   },
//   {
//     keyID: "1",
//     title: "mainnet live now",
//     desc: "Know more about us here.",
//     url: "https://docs.goal3.xyz/sportsbook/gasless-betting",
//     style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
//     wallpaper: main_slide1_v3,
//     target: "",
//   },
//   {
//     keyID: "10",
//     title: "mainnet live now",
//     desc: "Know more about us here.",
//     url: `${process.env.PUBLIC_URL}/p/mlb-early-lead-insurance`,
//     style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
//     wallpaper: mlb_event,
//     target: "",
//   },
//   {
//     keyID: "2",
//     title: "playdrop",
//     desc: "Know more about us here.",
//     url: "https://syncswap.xyz/pool/0x92EaE0B3A75F3eF6C50369cE8CA96B285d2139B8",
//     style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
//     wallpaper: main_slide2_v1,
//   },
//   {
//     keyID: "3",
//     title: "referral",
//     desc: "Know more about us here.",
//     url: "https://galxe.com/goal3",
//     style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
//     wallpaper: main_slide3_v1,
//   },
//   {
//     keyID: "5",
//     title: "docs",
//     desc: "docs.",
//     url: "https://app.goal3.xyz/p/referral",
//     style: "bg-gradient-to-tr from-red-600 to-pink-500",
//     wallpaper: main_slide5_v1,
//   },
//   {
//     keyID: "6",
//     title: "twitter",
//     desc: "twitter",
//     url: "https://t.me/goal3",
//     style: "bg-gradient-to-tr from-green-600 to-lime-500",
//     wallpaper: main_slide6_v1,
//   },
//   {
//     keyID: "7",
//     title: "Discord group",
//     desc: "Discord group",
//     url: "https://discord.gg/goal3",
//     style: "bg-gradient-to-tr from-green-600 to-lime-500",
//     wallpaper: main_slide7_v1,
//   },
//   {
//     keyID: "8",
//     title: "zealy",
//     desc: "zealy",
//     url: "https://zealy.io/c/goal3xyz",
//     style: "bg-gradient-to-tr from-green-600 to-lime-500",
//     wallpaper: main_slide8_v1,
//   },
//   {
//     keyID: "8",
//     title: "blogs",
//     desc: "blogs",
//     url: "https://twitter.com/Goal3_xyz",
//     style: "bg-gradient-to-tr from-green-600 to-lime-500",
//     wallpaper: main_slide9_v1,
//   },
//   {
//     keyID: "8",
//     title: "blogs",
//     desc: "blogs",
//     url: "https://blog.goal3.xyz/",
//     style: "bg-gradient-to-tr from-green-600 to-lime-500",
//     wallpaper: main_slide10_v1,
//   },
// ];

const data = [
  {
    keyID: "-4",
    title: "Lottery live now",
    desc: "Know more about us here.",
    url: "#",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-LoL.png",
    target: "_self",
  },
  {
    keyID: "-3",
    title: "Lottery live now",
    desc: "Know more about us here.",
    url: slugs.lottery,
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-LOTTERY.jpg",
    target: "_self",
  },
  {
    keyID: "-2",
    title: "x1000 live now",
    desc: "Know more about us here.",
    url: "https://staging.goal3.xyz/x1000",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/banner_x1000_contest.png",
    target: "",
  },
  {
    keyID: "-1",
    title: "cs2 live now",
    desc: "Know more about us here.",
    url: "/sports/cs2",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-CS2.png",
    target: "",
  },
  {
    keyID: "0",
    title: "app mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/install_app.svg",
    target: "",
  },
  {
    keyID: "1",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-1.svg",
    target: "",
  },
  {
    keyID: "2",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-2.svg",
    target: "",
  },
  {
    keyID: "3",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-3.svg",
    target: "",
  },
  {
    keyID: "4",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-4.svg",
    target: "",
  },
  {
    keyID: "5",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-5.svg",
    target: "",
  },

  {
    keyID: "6",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-6.svg",
    target: "",
  },

  {
    keyID: "7",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-7.svg",
    target: "",
  },
  {
    keyID: "8",
    title: "mainnet live now",
    desc: "Know more about us here.",
    url: "/sports/american-football",
    style: "bg-gradient-to-tr from-blue-600 to-cyan-500",
    wallpaper: "/banner/BANNER-SLIDE-8.svg",
    target: "",
  },
];

const SlideOpt = {
  breakpoints: {
    640: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
  },
};

// Feature Markets
function Banners() {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      // pagination={pagination},
      navigation={true}
      modules={[Autoplay, Navigation]}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      // Responsive breakpoints
      breakpoints={SlideOpt.breakpoints}
      // breakpoints={{
      // 	640: {
      // 		width: 640,
      // 		slidesPerView: 1,
      // 		spaceBetween: 0,
      // 	},
      // 	768: {
      // 		width: 768,
      // 		slidesPerView: 2,
      // 		spaceBetween: 20,
      // 	},
      // 	1024: {
      // 		width: 1024,
      // 		slidesPerView: 3,
      // 		spaceBetween: 20,
      // 	},
      // }}
      className="w-full flex-shrink-0 rounded-box mt-6"
    >
      {data.map((item: any, index: number) => (
        <SwiperSlide key={index}>
          {item?.showInfo ? (
            <a
              href={item.url}
              key={item.id}
              target={item?.target !== undefined ? item?.target : "_blank"}
              className="group rounded-box overflow-hidden relative h-full w-full mx-auto flex"
              rel="noreferrer"
            >
              <div className="absolute left-0 md:left-2/3 lg:left-2/4 right-0 top-0 bottom-0 opacity-10 md:opacity-100 flex">
                <Img
                  addPrefix
                  alt={""}
                  className="object-cover md:object-left flex-1"
                  src={item.wallpaper}
                />
              </div>
              <div
                className={`flex flex-col justify-center p-6 lg:px-8 2xl:px-10 w-full min-h-[200px] ${item.style}`}
              >
                <div className={`w-full max-w-sm`}>
                  <h3 className="text-3xl text-primary-content mb-1 font-semibold">
                    {item.title}
                  </h3>
                  <div className="text-base text-primary-content line-clamp-3 mt-1 font-medium">
                    {item.desc}
                  </div>
                  <button className="py-2 px-6 bg-primary-content bg-opacity-10 border border-primary-content text-primary-content hover:bg-primary-content hover:text-primary rounded-box max-w-xs font-semibold mt-6 transition">
                    Learn More
                  </button>
                </div>
              </div>
            </a>
          ) : (
            <a
              href={item.url}
              key={item.id}
              target={item?.target ? item?.target : "_blank"}
              className="group rounded-box overflow-hidden relative h-full w-full mx-auto flex"
              rel="noreferrer"
            >
              <Img
                addPrefix
                alt={""}
                className="object-cover flex-1"
                src={item.wallpaper}
              />
            </a>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Banners;
