import React, { useEffect } from "react";

interface Props {
  modalId: string;
  title?: string;
  icon?: string;
  isOpen: boolean;
  toggleModal: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  position?: "bottom" | "full";
  readonly?: boolean;
  customClassModal?: string;
  customClassContent?: string;
  customBody?: boolean;
}
export const ModalLayout = ({
  modalId,
  title,
  icon,
  isOpen,
  toggleModal,
  children,
  footer,
  position = "bottom",
  customBody = false,
  customClassContent = "",
}: Props) => {
  useEffect(() => {
    const body = document.querySelector("body");
    if (isOpen) {
      body?.classList.add("has-Modal");
    } else {
      body?.classList.remove("has-Modal");
    }
  }, [isOpen]);

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        checked={isOpen}
        onChange={() => {}}
        className="modal-toggle"
      />
      <div
        className={`modal modal-bottom sm:modal-middle${
          isOpen ? " modal-open z-[999]" : " -z-10 hidden"
        }`}
      >
        <div
          className={`${
            customClassContent
              ? customClassContent
              : "modal-box relative text-base scale-100"
          } ${position === "full" ? " w-full h-full" : ""}`}
        >
          {title && (
            <>
              <div className="modal-box--header">
                <div className="flex flex-1 items-center text-lg">
                  <i className={`icon-primary text-lg ${icon} mr-3`}></i>
                  <h3 className="">{title}</h3>
                  <button
                    onClick={() => toggleModal()}
                    className="btn btn-circle btn-sm btn-ghost absolute right-3 top-2"
                  >
                    <i className="fa-regular fa-xmark text-xl"></i>
                  </button>
                </div>
              </div>
            </>
          )}
          {customBody ? (
            <>{children}</>
          ) : (
            <div className="modal-box--body !p-0 text-sm leading-relaxed">
              {children}
            </div>
          )}

          {footer && <div className="modal-box--footer">{footer}</div>}
        </div>
      </div>
    </>
  );
};
