import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import League from "../page";

const routes: AppRouteType[] = [
  {
    path: slugs.league,
    component: League,
    container: MainLayout,
    isAuthNeeded: false,
  },
];

export default routes;
