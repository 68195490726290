import OrderSlips from "components/organisms/PlaceOrder/orderSlips";
import { orderSlipProxy } from "proxy-state/betting/OrderSlip";
import React from "react";
import { useSnapshot } from "valtio";
import OrderHeader from "./Components/header";
import { Responsive as Screen } from "components/commons";

export const OrderSlip = () => {
  const { total, items } = useSnapshot(orderSlipProxy);

  return (
    <>
      {/* header  */}
      {/* <Screen upto="md"> */}
      <OrderHeader />
      {/* </Screen> */}
      {/* body  */}
      <OrderSlips />
    </>
  );
};
