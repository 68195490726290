const NOTIFICATION_API_URL = `${process.env.REACT_APP_NOTIFICATIONS_API_URL}`;
const FUNDING_API_URL = `${process.env.REACT_APP_FUNDING_API_URL}`;
const client_id =
  process.env.REACT_APP_CLIENT_ID || "cd3a5797-737c-4375-be6f-549caa49bc8d";
export const getNotificationsUrl = (user_id: string) => {
  return `${NOTIFICATION_API_URL}/notification?recipient_id=${user_id}&client_id=${client_id}`;
};
export const getUnreadCountingUrl = (user_id: string) => {
  return `${NOTIFICATION_API_URL}/notification/unread-count?recipient_id=${user_id}&client_id=${client_id}`;
};
export const putReadNotificationUrl = (user_noti_id: string) => {
  return `${NOTIFICATION_API_URL}/notification/${user_noti_id}/click`;
};

// api for funding
export const CreateOrGetOTPFundingUrl = `${FUNDING_API_URL}/api/v1/otp`;
export const getOTPFundingUrl = `${FUNDING_API_URL}/api/v1/otp`;
