import { proxy } from "valtio";
import {
  IWagerStatus,
  ILayoutType,
  IPageType,
  ITimeFilterType,
  IOrderBy,
  IMyBetLayout,
} from "shared/enum";
interface IMyBetFilter {
  filter: string;
  search: string;
  wagerStatus: IWagerStatus;
  layoutType: ILayoutType;
  MyBetLayout: IMyBetLayout;
  pageType: IPageType;
  UnClaimed: boolean;
  orderBy: IOrderBy;
  timeFilter: ITimeFilterType;
  setTimeFilter: (time: ITimeFilterType) => void;
  setFilter: (filter: string) => void;
  setWagerStatus: (status: IWagerStatus) => void;
  setLayoutType: (layout: ILayoutType) => void;
  setPageType: (page: IPageType) => void;
  setUnClaimed: (unclaimed: boolean) => void;
  setOrderBy: (order: IOrderBy) => void;
  setSearch: (search: string) => void;
  setMyBetLayout: (layout: IMyBetLayout) => void;
  resetFilter: () => void;
}
export const BetFilterProxy: IMyBetFilter = proxy({
  filter: "",
  search: "",
  wagerStatus: IWagerStatus.ALL,
  layoutType: ILayoutType.GRID,
  MyBetLayout: IMyBetLayout.BETS,
  pageType: IPageType.ORDER,
  UnClaimed: false,
  orderBy: IOrderBy.DESC,
  timeFilter: ITimeFilterType.allTime,
  setTimeFilter(time: ITimeFilterType) {
    BetFilterProxy.timeFilter = time;
  },
  setFilter(filter: string) {
    BetFilterProxy.filter = filter;
  },
  setWagerStatus(status: IWagerStatus) {
    BetFilterProxy.wagerStatus = status;
  },
  setLayoutType(layout: ILayoutType) {
    BetFilterProxy.layoutType = layout;
  },
  setPageType(page: IPageType) {
    BetFilterProxy.pageType = page;
  },
  setUnClaimed(unclaimed: boolean) {
    BetFilterProxy.UnClaimed = unclaimed;
  },
  setOrderBy(order: IOrderBy) {
    BetFilterProxy.orderBy = order;
  },
  setSearch(search: string) {
    BetFilterProxy.search = search;
  },
  setMyBetLayout(layout: IMyBetLayout) {
    BetFilterProxy.MyBetLayout = layout;
  },
  resetFilter() {
    BetFilterProxy.filter = "";
    BetFilterProxy.search = "";
    // BetFilterProxy.MyBetLayout = IMyBetLayout.BETS;
    // BetFilterProxy.wagerStatus = IWagerStatus.ALL;
    BetFilterProxy.layoutType = ILayoutType.GRID;
    // BetFilterProxy.pageType = IPageType.ORDER;
    BetFilterProxy.UnClaimed = false;
    BetFilterProxy.orderBy = IOrderBy.DESC;
    BetFilterProxy.timeFilter = ITimeFilterType.allTime;
  },
});
