import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import CampaignDetail from "../page";

const routes: AppRouteType[] = [
	{
		path: slugs.campaignDetail,
		component: CampaignDetail,
		container: MainLayout,
		isAuthNeeded: false,
	},
];

export default routes;
