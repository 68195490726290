import {
  notiState,
  notitficationState,
  tabFilter,
} from "proxy-state/notificationProxy";
import React from "react";
import { useSnapshot } from "valtio";
const NotiHeader = () => {
  const { show: notiStatus, counter, filter } = useSnapshot(notiState);
  return (
    <div className="htabs-container mt-3 px-6">
      <div className="htabs">
        <button
          className={`htab${filter === tabFilter.all ? " htab-active" : ""}`}
          onClick={() => notitficationState.setFilter(tabFilter.all)}
        >
          <span className="flex flex-col justify-center items-center">
            <span className="htab-title">All</span>
            <span className="htab-border"></span>
          </span>
        </button>
        <button
          className={`htab${filter === tabFilter.unread ? " htab-active" : ""}`}
          onClick={() => notitficationState.setFilter(tabFilter.unread)}
        >
          <span className="flex flex-col justify-center items-center">
            <span className="htab-title">
              Unread
              {counter ? (
                <span className="badge badge-noti">{counter}</span>
              ) : (
                ""
              )}
            </span>
            <span className="htab-border"></span>
          </span>
        </button>
        {/* <button className="htab">
					<span className="flex flex-col justify-center items-center">
						<span className="htab-title">Promotion <span className="badge badge-noti">2</span></span>
						<span className="htab-border"></span>
					</span>
				</button> */}
      </div>
    </div>
  );
};
export default NotiHeader;
