import auth from "modules/auth/route";
import home from "modules/home/route";
import sports from "modules/sports/route";
import category from "modules/category/route";
import league from "modules/league/route";
import event from "modules/event/route";
import myBets from "modules/myBets/route";
import refCode from "modules/refCode/route";
// import Profile from "modules/profile/route";
import metamask from "modules/metamask/route";
// import notification from "modules/notification/route";
import notificationCenter from "modules/notificationCenter/route";
import orderSlip from "modules/orderSlip/route";
import campaign from "modules/campaign/route";
import campaignDetail from "modules/campaign-detail/route";

const mainRouters: AppRouteType[] = [
  ...auth,
  ...home,
  ...sports,
  ...category,
  ...league,
  ...event,
  ...myBets,
  // ...Profile,
  ...refCode,
  ...metamask,
  // ...notification,
  ...notificationCenter,
  ...orderSlip,
  ...campaign,
  ...campaignDetail,
];

interface Props {
  children: React.ReactNode;
}

export type AppRouteType = {
  path: string;
  component: React.FC;
  container: React.FC<Props>;
  isAuthNeeded: boolean;
};

export { mainRouters };
