import React, { Fragment, useState } from "react";
import LogoApp from "../Logo";
import { useSnapshot } from "valtio";
import { notiState, notitficationState } from "proxy-state/notificationProxy";
import WalletBtn from "./walletBtn";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugs from "navigation/slugs";
import { modalFunc, navFunc, navProxy } from "proxy-state/global";
import { Responsive } from "components/commons";
import Breadcrumb from "../BreadCrumb";
import { orderSlipProxy } from "proxy-state/betting/OrderSlip";
import { isIOS } from "react-device-detect";
import { IS_CLOSED_TOPBAR, getItem, saveItem } from "local-storage";
import { toast } from "react-toastify";
import clsx from "clsx";
import { useAuth } from "contexts/authContext";

const isInStandaloneMode = window.matchMedia(
  "(display-mode: standalone)"
).matches;

type NavBarProps = {
  tab?: string;
  setTab?: React.Dispatch<React.SetStateAction<string>>;
  breadcrumb?: Array<any>;
};

const Header: React.FC<NavBarProps> = ({ breadcrumb }) => {
  const isClosedTopbar = JSON.parse(getItem(IS_CLOSED_TOPBAR)!);
  const [isTopbarClosed, setTopbar] = useState(isClosedTopbar);
  const {token: isAuthenticated} = useAuth();

  const navigate = useNavigate();

  const { show: notiStatus, counter } = useSnapshot(notiState);
  const { total } = useSnapshot(orderSlipProxy);

  const { isShowOrder } = useSnapshot(navProxy);
  const { pathname } = useLocation();

  const { category, league, event } = useParams<{
    category: string;
    league: string;
    event: string;
  }>();

  const pageTitle = {
    [slugs.home]: "Lobby",
    [slugs.myBets]: "My Bets",

    [slugs.sports]: "Sports",
    [`${slugs.sports}/${category}`]: "Sports",
    [`${slugs.sports}/${category}/${league}`]: "Sports",

    [`${slugs.sports}/${category}/${league}/${event}`]: "Match Details",
  };

  const headerClass =
    "sticky flex flex-col items-center flex-shrink-0 top-0 left-0 right-0 w-full z-50 bg-base-300/90 backdrop-blur";

  const topbarClass =
    "sticky flex-shrink-0 top-0 left-0 right-0 w-full h-12 bg-primary/90 text-primary-content backdrop-blur z-50";

  const toolbarBtnClass =
    "btn btn-md btn-ghost btn-circle tooltip tooltip-bottom";
  const toolbarBtnNormalClass = "";
  const toolbarBtnActiveClass = "icon-primary text-primary";
  
  return (
    <Fragment>
      <header
        id={"header"}
        className={clsx(headerClass, {
          "pt-10": isInStandaloneMode && isIOS,
        })}
      >
        {!isTopbarClosed && (
          <div className={`${topbarClass}`}>
            <div className="flex items-center md:text-center relative pl-4 pr-12 h-full w-full">
              <div
                className={`flex items-center gap-3 text-sm relative md:mx-auto`}
              >
                <i className="text-xl fa-solid fa-parachute-box animate-pulse"></i>
                <span className="font-medium">
                  Goal3 Airdrop is coming soon!
                </span>
                <div
                  // href=""
                  // target={"_blank"}
                  // rel="noreferrer"
                  className="btn btn-sm btn-base200"
                  onClick={() => toast.success("Coming soon!")}
                >
                  Check{" "}
                  <span className="hidden md:inline ml-1">eligibility</span>
                  <i className="fa-regular fa-square-arrow-up-right ml-3"></i>
                </div>
              </div>
              <button
                onClick={() => {
                  setTopbar(true);
                  saveItem(IS_CLOSED_TOPBAR, true);
                }}
                className={`btn btn-sm btn-circle btn-ghost hover:!bg-base-100/20 absolute right-2 md:right-4 top-2`}
              >
                <i className="text-lg fa-regular fa-xmark"></i>
              </button>
            </div>
          </div>
        )}
        <div
          className={
            "container flex justify-between items-center h-full px-2 md:px-4 py-3"
          }
        >
          <Responsive upto={"md"}>
            {pathname === slugs.home ? (
              <LogoApp />
            ) : (
              <h1 className="inline-block text-xl font-semibold">
                {pageTitle[pathname]}
              </h1>
            )}
          </Responsive>

          <Responsive from={"lg"}>
            <div className="flex items-center gap-2">
              {/* {pageTitle[pathname] === "Match Details" && (
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-sm btn-ghost font-normal text-base"
                >
                  <i className="fa-solid fa-angle-left text-xl text-primary"></i>
                </button>
              )} */}

              <h1 className="inline-block text-xl font-semibold">
                {pageTitle[pathname]}
              </h1>
            </div>
          </Responsive>

          <div className="flex items-center gap-2">
            <div
              className={"flex flex-row items-center justify-between lg:gap-2"}
            >
              {isAuthenticated && (
                <>
                  <button
                    onClick={() => {
                      modalFunc.setModalOpen("isPremiumOpen", true);
                    }}
                    className={toolbarBtnClass}
                    data-tip="Get referral Code"
                  >
                    <i className="fa-solid fa-gift text-lg"></i>
                    <span className="sr-only ml-2">Get Referral code</span>
                  </button>

                  <button
                    className={`${toolbarBtnClass}`}
                    onClick={() => {
                      notitficationState.setCounter(0);
                      notitficationState.setShow(true);
                    }}
                    data-tip="Notifications"
                  >
                    <i
                      className={`fa-solid fa-bell text-lg ${!notiStatus ? toolbarBtnNormalClass : toolbarBtnActiveClass
                        }`}
                    ></i>
                    <span className="sr-only">Notifications</span>
                    {counter > 0 && (
                      <span className="badge-noti absolute right-1 top-1.5">
                        {counter}
                      </span>
                    )}
                  </button>
                </>
              )}

              {/* Active Button */}
              <button
                className={`${toolbarBtnClass} hidden lg:block`}
                data-tip="Order Slips"
                onClick={() => navFunc.setNavOpen("isShowOrder", !isShowOrder)}
              >
                <i
                  className={`fa-solid fa-receipt text-lg  ${
                    !isShowOrder ? toolbarBtnNormalClass : toolbarBtnActiveClass
                  }`}
                ></i>
                <span className="sr-only">Order Slips</span>
                {total > 0 && (
                  <span className="badge-noti absolute text-[10px] right-1 top-2">
                    {total}
                  </span>
                )}
              </button>
              {/* Active Button */}
            </div>
            <WalletBtn isAuth={isAuthenticated} />
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
