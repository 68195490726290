import { signInAnonymously, signOut } from "firebase/auth";

const SignInWithFirebase = async (auth: any) => {
  try {
    if (auth && !auth?.currentUser) {
      return await signInAnonymously(auth);
    }
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
  }
};

const SignOutWithFirebase = async (auth: any) => {
  try {
    await signOut(auth as any);
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
  }
};

export { SignInWithFirebase, SignOutWithFirebase };
