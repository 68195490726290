import React, { useState, useEffect } from "react";

interface CountDownProps {
  date: number | string;
  type?: string;
  sec_show?: boolean;
  classes?: string;
  unit_classes?: string;
}
interface timeProps {
  days: number | string;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
  type?: string;
  sec_show?: boolean;
  classes?: string;
  unit_classes?: string;
}
const unitType = ({ unit, type }: any) => {
  if (type === "sort") {
    return unit === "Days"
      ? "d"
      : unit === "Hours"
        ? "h"
        : unit === "Min"
          ? "m"
          : "s";
  } else {
    return unit;
  }
};
export const CountDown = ({
  date,
  type,
  sec_show,
  classes,
  unit_classes,
}: CountDownProps) => {
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  // const countDownDate =  new Date(date).getTime();
  const countDownDate = Number(date) * 1000;

  useEffect(() => {
    // eslint-disable-next-line no-var
    var updateTime = setInterval(() => {
      const now = new Date().getTime();

      const difference = countDownDate - now;

      const newDays = Math.floor(difference / (1000 * 60 * 60 * 24));
      const newHours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const newMinutes = Math.floor(
        (difference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const newSeconds = Math.floor((difference % (1000 * 60)) / 1000);

      setDays(newDays);
      setHours(newHours);
      setMinutes(newMinutes);
      setSeconds(newSeconds);

      if (difference <= 0) {
        clearInterval(updateTime);
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      }
    });

    return () => {
      clearInterval(updateTime);
    };
  }, [countDownDate]);
  const closed = days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0;
  return !closed ? (
    <div>
      <TimerContainer
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        type={type}
        sec_show={sec_show}
        classes={classes}
        unit_classes={unit_classes}
      />
    </div>
  ) : null;
};
const TimerContainer = ({
  days,
  hours,
  minutes,
  seconds,
  type,
  sec_show,
  classes,
  unit_classes,
}: timeProps) => {
  let daysFlip = false;
  let hoursFlip = false;
  let minutesFlip = false;
  let secondsFlip = true;

  if (
    Number(seconds) <= 0 &&
    Number(minutes) <= 0 &&
    Number(hours) <= 0 &&
    Number(days) <= 0
  ) {
    daysFlip = false;
    hoursFlip = false;
    minutesFlip = false;
    secondsFlip = false;
  }

  if (seconds == 0) {
    if (minutes != 0) {
      seconds = 59;
    }

    secondsFlip = false;
    minutesFlip = true;
  }
  if (minutes == 0) {
    if (hours != 0) {
      minutes = 59;
    }

    minutesFlip = false;
    hoursFlip = true;
  }

  if (hours == 0) {
    hoursFlip = false;
    if (days != 0) {
      daysFlip = true;
    }
  }

  if (Number(days) < 10) {
    days = "0" + days;
  }

  if (Number(hours) < 10) {
    hours = "0" + hours;
  }

  if (Number(minutes) < 10) {
    minutes = "0" + minutes;
  }

  if (Number(seconds) < 10) {
    seconds = "0" + seconds;
  }
  const pageCls =
    type === "sort" ? "flex space-x-2 items-baseline" : "flex gap-4";
  return (
    <>
      <div className={`timerRoot${classes ? " " + classes : ""}`}>
        <div className={pageCls}>
          {days !== "00" ? (
            <NumberBox
              num={days}
              unit="Days"
              type={type}
              unit_classes={unit_classes}
            />
          ) : null}
          <NumberBox
            num={hours}
            unit="Hours"
            type={type}
            unit_classes={unit_classes}
          />
          <NumberBox
            num={minutes}
            unit="Min"
            type={type}
            unit_classes={unit_classes}
          />
          {(sec_show || Number(days) < 1) && (
            <NumberBox
              num={seconds}
              unit="Sec"
              unit_classes={unit_classes}
              type={type}
            />
          )}
        </div>
      </div>
    </>
  );
};

interface numProp {
  num: string | number;
  unit: string;
  type?: string;
  unit_classes?: string;
}

const NumberBox = ({ num, unit, type, unit_classes }: numProp) => {
  if (num === 0) return null;
  const child = (
    <div className="flex items-baseline lowercase">
      <span
        className={"countdown slashed-zero leading-4 font-semibold"}
      >
        {num}
      </span>
      <span
        className={`${
          unit_classes ? unit_classes : "text-base-content text-opacity-70 ml-1"
        }`}
      >
        {unitType({ unit, type })}
      </span>
    </div>
  );

  return child;
};
