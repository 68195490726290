import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DocumentData,
  DocumentSnapshot,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { mmFormatUTC } from "use-moment/format";
import { useAccount } from "./useAccount";
import {
  userHistoryCollRef,
} from "utils/firebase/firebaseDBRef";
import { userWagerProxy } from "proxy-state/userWagerProxy";
import { getEventIdsFromOutcomeIdsV2 } from "utils/firebase/helper";
import { eventProxy } from "proxy-state/eventProxy";
import { PAGINATION_SIZE } from "shared/constants";
import { isMobile } from "react-device-detect";
import { IOrderBy, ITimeFilterType } from "shared/enum";
import { getStartTime } from "shared/tab-filter";
import { useSearchParams } from "react-router-dom";

interface HistoryData {
  txHash: string;
  transactionTime: number;
  amounts: Array<string>;
  total_amount: number;
  order_status: string;
  outcomes: Array<string>;
  system_type: number;
  type: string;
  wagers: Array<any>;
  user_id: string;
}

const getWagerIds = (data: HistoryData[]) => {
  return data.map((item) => item.wagers.map((w: any) => w.id)).flat();
};

const getConstraints = (params: {
  time: ITimeFilterType;
  filterOrderBy?: IOrderBy;
}): QueryConstraint[] => {
  const { time, filterOrderBy = IOrderBy.DESC} = params;

  const _constraints: QueryConstraint[] = [];

  if (time) {
    const startTime = getStartTime(time);
    if (startTime) {
      _constraints.push(where("transactionTime", ">=", mmFormatUTC(startTime)));
    }
  }

  _constraints.push(orderBy("transactionTime", filterOrderBy));

  return _constraints;
};

const parseDoc = (doc: QueryDocumentSnapshot<any>) => {
  const _doc = doc.data();

  return {
    txHash: doc.id,
    transactionTime: _doc.transactionTime,
    amounts: _doc.amounts,
    total_amount: _doc.total_amount,
    order_status: _doc.order_status,
    outcomes: _doc.outcomes,
    wagers: _doc.wagers,
    system_type: _doc.system_type,
    type: _doc.type,
    user_id: _doc.user_id,
  };
};

const useHistory = () => {
  const { address: internalAddress } = useAccount();
  const [searchParams] = useSearchParams();

  const extendAddress = searchParams.get("address");

  const address = extendAddress || internalAddress;
  // const address = "0xc615e3178a63BA2d720eb245f7872a129495C27C"; // TODO:
  // const [historyDataPage, setHistoryDataPage] =
  //   useState<QuerySnapshot<any> | null>(null);
  // const [nextCursorPointer, setNextCursorPointer] =
  //   useState<DocumentData | null>(null);
  // const [prevCursorPointer, setPrevCursorPointer] =
  //   useState<DocumentData | null>(null);
  // const [historyRaw, setHistoryRaw] = React.useState<HistoryData[]>([]);
  // const [loading, setLoading] = React.useState<boolean>(true);
  const [filter, setFilter] = React.useState<string>("");
  const [filterOrderBy, setFilterOrderBy] = React.useState<IOrderBy>(IOrderBy.DESC)
  const [timeFilter, setTimeFilter] = React.useState<ITimeFilterType>(
    ITimeFilterType.allTime
  );
  // const [isNext, setIsNext] = React.useState<boolean>(false);
  // const [isPrev, setIsPrev] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  // const [dataChanges, setDataChanges] = useState<
  //   QueryDocumentSnapshot<DocumentData>[] | null
  // >(null);
  // const [lastRecord, setLastRecord] = useState<any>();
  // const [lastVisible, setLastVisible] = useState<boolean>(false);
  // const [loadingMore, setLoadingMore] = useState<boolean>(false);

  // const { userHistoryCollRef, userWagerCollRef } = useFiretore();

  // ================== new pagination ==================
  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const [count, setCount] = useState(0);
  const [data, setData] = useState<QueryDocumentSnapshot<any, DocumentData>[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [isLoading, setLoading1] = useState<boolean>(true);

  /// fetch raw data
  useEffect(() => {
    const fetchData = async () => {
      if (!address) return;

      try {
        setLoading1(true);
        const constraints = getConstraints({ time: timeFilter, filterOrderBy });
        const cursor = cursors.current.get(page);
        if (cursor) {
          constraints.push(startAfter(cursor));
        }

        const _query: Query<DocumentData> = query(
          userHistoryCollRef(address),
          ...constraints,
          limit(PAGINATION_SIZE)
        );

        const result: QuerySnapshot<any> = await getDocs(_query);

        if (isMobile) {
          // scroll infinity for mobile
          if (page === 0) {
            setData(result.docs);
          } else {
            setData((data) => data.concat(result.docs));
          }
        } else {
          setData(result.docs);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: useHistory.tsx:181 ~ fetchData ~ error:",
          error
        );
      } finally {
        setLoading1(false);
      }
    };
    fetchData();
  }, [address, page, timeFilter, filterOrderBy]);

  /// fetch count
  useEffect(() => {
    const fetchData = async () => {
      if (!address) return;
      try {
        const constraints = getConstraints({ time: timeFilter, filterOrderBy });
        const snapshot = await getCountFromServer(
          query(userHistoryCollRef(address), ...constraints)
        );
        setCount(snapshot.data()?.count);
      } catch (error) {
        console.log(
          "🚀 ~ file: useHistory.tsx:169 ~ fetchData ~ error:",
          error
        );
      }
    };
    fetchData();
  }, [address, timeFilter, filterOrderBy]);

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        if (!data) return page;

        // first, we save the last document as page's cursor
        cursors.current.set(page + 1, data[data.length - 1]);

        // then we update the state with the next page's number
        return nextPage;
      });
    },
    [data]
  );

  const historyRaw = useMemo(() => {
    const _data = data.map((doc) => parseDoc(doc));

    /// dev: fetch wagers by bids
    const ids = getWagerIds(_data);
    userWagerProxy.loadByIds(address!, ids);

    /// fetch events
    const eventIds: string[] = [];
    _data.forEach((history) => {
      const subEventIds = getEventIdsFromOutcomeIdsV2(history?.outcomes);
      eventIds.push(...subEventIds);
    });
    eventProxy.loadByIds(eventIds);

    return _data;
  }, [data, address]);

  const handleChangeFilter = (value: ITimeFilterType) => {
    handleResetDataOnMobile();
    setPage(0);
    setTimeFilter(value);
  };
  const handleChangeOrderBy = (value: IOrderBy) => {
    handleResetDataOnMobile();
    setPage(0);
    setFilterOrderBy(value);
  };

  /// dev: only mobile for infinity scroll
  const handleResetDataOnMobile = () => {
    if (isMobile) {
      setData([]);
    }
  };

  // ================== end: new pagination ==================

  // dev: previous version, no longer used
  // const buildQuery = (
  //   filters: filters,
  //   isNext: boolean,
  //   isPrev: boolean,
  //   initWager: boolean
  // ) => {
  //   const { time } = filters;
  //   let _query: Query<DocumentData>;
  //   let _cursor = isNext
  //     ? startAfter(nextCursorPointer)
  //     : isPrev
  //     ? endBefore(prevCursorPointer)
  //     : startAfter(null);
  //   const _limit = isPrev ? limitToLast(PAGE_SIZE + 1) : limit(PAGE_SIZE + 1);

  //   const now = moment().format("YYYY-MM-DD");

  //   switch (time) {
  //     case ITimeFilterType.today:
  //       var d = moment(now).subtract(1, "days").format("YYYY-MM-DD");
  //       console.log("🚀 ~ file: useHistory.tsx:260 ~ useHistory ~ d:", d);
  //       _query = initWager
  //         ? query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(d)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             limit(PAGE_SIZE + 1)
  //           )
  //         : query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(d)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             _cursor,
  //             _limit
  //           );
  //       break;

  //     case ITimeFilterType.yesterday:
  //       const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  //       const y_date = new Date(yesterday);
  //       y_date.setUTCHours(0, 0, 0, 0);
  //       _query = initWager
  //         ? query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(y_date)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             limit(PAGE_SIZE + 1)
  //           )
  //         : query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(y_date)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             _cursor,
  //             _limit
  //           );
  //       break;

  //     case ITimeFilterType.thisWeek:
  //       const lastWeek = moment().subtract(7, "days").format("YYYY-MM-DD");
  //       const w_date = new Date(lastWeek);
  //       w_date.setUTCHours(0, 0, 0, 0);
  //       _query = initWager
  //         ? query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(w_date)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             limit(PAGE_SIZE + 1)
  //           )
  //         : query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(w_date)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             _cursor,
  //             _limit
  //           );
  //       break;

  //     case ITimeFilterType.thisMonth:
  //       const lastMonth = moment().subtract(30, "days").format("YYYY-MM-DD");
  //       const lastMonth_date = new Date(lastMonth);
  //       _query = initWager
  //         ? query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(lastMonth_date)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             limit(PAGE_SIZE + 1)
  //           )
  //         : query(
  //             userHistoryCollRef(address),
  //             where("transactionTime", ">=", mmFormatUTC(lastMonth_date)),
  //             where("transactionTime", "<=", mmFormatUTC(now)),
  //             orderBy("transactionTime", "desc"),
  //             _cursor,
  //             _limit
  //           );
  //       break;
  //     default:
  //       _query = initWager
  //         ? query(
  //             userHistoryCollRef(address),
  //             orderBy("transactionTime", "desc"),
  //             limit(PAGE_SIZE + 1)
  //           )
  //         : query(
  //             userHistoryCollRef(address),
  //             orderBy("transactionTime", "desc"),
  //             _cursor,
  //             _limit
  //           );
  //       break;
  //   }

  //   return _query;
  // };

  // dev: previous version, no longer used
  // const getlasthistoryBet: (
  //   filters: filters
  // ) => Promise<HistoryData[]> = async (filters) => {
  //   const _query: Query<DocumentData> = buildQuery(filters, false, false, true);
  //   return await getDocs(_query).then((snapshot) => {
  //     let count: number = 0;
  //     setHistoryDataPage(snapshot);
  //     let data: HistoryData[] = [];
  //     snapshot.docs.forEach((doc) => {
  //       if (!count) {
  //         setLastRecord(doc);
  //       }
  //       if (
  //         count <= snapshot.docs.length - 2 ||
  //         snapshot.docs.length !== PAGE_SIZE + 1
  //       ) {
  //         data.push({
  //           txHash: doc.id,
  //           transactionTime: doc.data().transactionTime,
  //           amounts: doc.data().amounts,
  //           total_amount: doc.data().total_amount,
  //           order_status: doc.data().order_status,
  //           outcomes: doc.data().outcomes,
  //           wagers: doc.data().wagers,
  //           system_type: doc.data().system_type,
  //           type: doc.data().type,
  //           user_id: doc.data().user_id,
  //         });
  //       }
  //       if (
  //         snapshot.docs.length === PAGE_SIZE + 1 &&
  //         count === snapshot.docs.length - 2
  //       ) {
  //         setNextCursorPointer(doc);
  //         setPrevCursorPointer(null);
  //       } else {
  //         setLastVisible(true);
  //       }
  //       count++;
  //     });
  //     return data;
  //   });
  // };

  // dev: previous version, no longer used
  // const handleUpdateData = (data: HistoryData[]) => {
  //   setHistoryRaw(data);

  //   /// dev: fetch wagers by bids
  //   const ids = getWagerIds(data);
  //   userWagerProxy.loadByIds(address!, ids);

  //   /// fetch events
  //   const eventIds: string[] = [];
  //   data.forEach((history) => {
  //     const subEventIds = getEventIdsFromOutcomeIdsV2(history?.outcomes);
  //     eventIds.push(...subEventIds);
  //   });
  //   eventProxy.loadByIds(eventIds);
  //   setLoading(false);
  // };

  // dev: previous version, no longer used
  /// mobile: handle load more
  // useEffect(() => {
  //   const loadMoreItem = async () => {
  //     if (lastVisible) return;
  //     // setLoading(true);
  //     setLoadingMore(true);
  //     try {
  //       const q: Query<DocumentData> = buildQuery(
  //         { time: timeFilter },
  //         true,
  //         false,
  //         false
  //       );
  //       const snapShot: QuerySnapshot<any> = await getDocs(q);
  //       if (!snapShot.empty) {
  //         let count: number = 0;
  //         setHistoryDataPage(snapShot);
  //         let data: HistoryData[] = [];
  //         snapShot.docs.forEach((doc) => {
  //           if (
  //             count <= snapShot.docs.length - 2 ||
  //             snapShot.docs.length !== PAGE_SIZE + 1
  //           ) {
  //             data.push({
  //               txHash: doc.id,
  //               transactionTime: doc.data().transactionTime,
  //               amounts: doc.data().amounts,
  //               total_amount: doc.data().total_amount,
  //               order_status: doc.data().order_status,
  //               outcomes: doc.data().outcomes,
  //               wagers: doc.data().wagers,
  //               system_type: doc.data().system_type,
  //               type: doc.data().type,
  //               user_id: doc.data().user_id,
  //             });
  //           }
  //           if (
  //             snapShot.docs.length === PAGE_SIZE + 1 &&
  //             count === snapShot.docs.length - 2
  //           ) {
  //             setLastVisible(false);
  //             setNextCursorPointer(doc);
  //           }
  //           count++;
  //         });
  //         if (!snapShot.empty) {
  //           setPrevCursorPointer(snapShot.docs[0]);
  //         }
  //         if (snapShot.docs.length !== PAGE_SIZE + 1) {
  //           setLastVisible(true);
  //           setNextCursorPointer(null);
  //         }
  //         if (data.length) {
  //           handleUpdateData([...historyRaw, ...data]);
  //           setIsNext(false);
  //         } else {
  //           setHistoryRaw([]);
  //         }
  //       }
  //     } catch (error) {
  //       console.log("====================================");
  //       console.log("error =======>", error);
  //       console.log("====================================");
  //     }
  //   };
  //   const isBottom = (el: HTMLElement) => {
  //     return el.getBoundingClientRect().bottom <= window.innerHeight;
  //   };

  //   // Load more items when user scrolls to the bottom
  //   const handleScroll = () => {
  //     const element = document.getElementById("history");

  //     if (element && isBottom(element) && !lastVisible && !loadingMore) {
  //       loadMoreItem().then();
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [lastVisible, loadingMore]);

  // dev: previous version, no longer used
  /// fetch bet history raw data
  // useEffect(() => {
  //   if (!address) return;

  //   setLoading(true);

  //   getlasthistoryBet({ time: timeFilter }).then((res) => {
  //     if (res.length === 0) {
  //       setLoading(false);
  //       return;
  //     }

  //     handleUpdateData(res);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [address, timeFilter]);

  // dev: previous version, no longer used
  // out of date
  // useEffect(() => {
  //   if (!historyRaw.length) {
  //     setHistoryData([]);
  //     setLoading(false);
  //     return;
  //   }
  //   setLoading(true);
  //   Promise.all(splitWagerHistory(historyRaw, address)).then(
  //     (data: any[][]) => {
  //       const _data = data.flat();
  //       const eventIds: string[] = [];
  //       const _historyData: HistoryData[] = historyRaw.map((item) => {
  //         const subEventIds = getEventIdsFromOutcomeIdsV2(item?.outcomes);
  //         eventIds.push(...subEventIds);
  //         const wagers = item.wagers.map((wager) => {
  //           const _wager = _data.find((d) => d.id === wager.id);
  //           return { ...wager, ..._wager };
  //         });
  //         return { ...item, wagers };
  //       });
  //       Promise.all(getEventByIds(eventIds, getEventColl, getLeagueDocRef))
  //         .then((res) => {
  //           const EventsData = res.flat();
  //           setEvents(EventsData);
  //           setHistoryData(_historyData);
  //           setLoading(false);
  //           setLoadingMore(false);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setLoading(false);
  //         });
  //     }
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [historyRaw]);

  /// handle click next
  // useEffect(() => {
  //   if (isNext) {
  //     setLoading(true);
  //     const _filters: filters = {
  //       time: timeFilter,
  //     };
  //     const q: Query<DocumentData> = buildQuery(_filters, true, false, false);
  //     getDocs(q).then((snapShot: QuerySnapshot<any>) => {
  //       try {
  //         let count: number = 0;
  //         setHistoryDataPage(snapShot);
  //         let data: HistoryData[] = [];
  //         snapShot.docs.forEach((doc) => {
  //           if (
  //             count <= snapShot.docs.length - 2 ||
  //             snapShot.docs.length !== PAGE_SIZE + 1
  //           ) {
  //             data.push({
  //               txHash: doc.id,
  //               transactionTime: doc.data().transactionTime,
  //               amounts: doc.data().amounts,
  //               total_amount: doc.data().total_amount,
  //               order_status: doc.data().order_status,
  //               outcomes: doc.data().outcomes,
  //               wagers: doc.data().wagers,
  //               system_type: doc.data().system_type,
  //               type: doc.data().type,
  //               user_id: doc.data().user_id,
  //             });
  //           }
  //           if (
  //             snapShot.docs.length === PAGE_SIZE + 1 &&
  //             count === snapShot.docs.length - 2
  //           ) {
  //             setNextCursorPointer(doc);
  //           }
  //           count++;
  //         });
  //         if (!snapShot.empty) {
  //           setPrevCursorPointer(snapShot.docs[0]);
  //         }
  //         if (snapShot.docs.length !== PAGE_SIZE + 1) {
  //           setNextCursorPointer(null);
  //         }
  //         if (data.length) {
  //           handleUpdateData(data);
  //           setIsNext(false);
  //         } else {
  //           setHistoryRaw([]);
  //         }
  //       } catch (error) {
  //         console.log("====================================");
  //         console.log("error =======>", error);
  //         console.log("====================================");
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isNext]);

  // dev: previous version, no longer used
  /// handle latest record
  // useEffect(() => {
  //   if (lastRecord) {
  //     const historyCollRef = userHistoryCollRef(address);
  //     const q: any = query(
  //       historyCollRef,
  //       orderBy("transactionTime", "desc"),
  //       limit(PAGE_SIZE + 1)
  //     );
  //     onSnapshot(q, (querySnapshot: any) => {
  //       if (!querySnapshot.empty) {
  //         let d: any[] = [];
  //         querySnapshot.docs.forEach((data: any) => {
  //           d.unshift(data);
  //         });
  //         if (d.length) {
  //           setDataChanges(d);
  //         }
  //       }
  //     });
  //   }
  // }, [lastRecord]);

  // dev: previous version, no longer used
  /// dev: out of date
  // useEffect(() => {
  //   if (dataChanges) {
  //     let data: HistoryData[] = [...historyRaw];

  //     dataChanges.forEach((doc) => {
  //       if (data.filter((d) => d.txHash === doc.id).length) return;
  //       data.unshift({
  //         txHash: doc.id,
  //         transactionTime: doc.data().transactionTime,
  //         amounts: doc.data().amounts,
  //         total_amount: doc.data().total_amount,
  //         order_status: doc.data().order_status,
  //         outcomes: doc.data().outcomes,
  //         system_type: doc.data().system_type,
  //         type: doc.data().type,
  //         wagers: doc.data().wagers,
  //         user_id: doc.data().user_id,
  //       });
  //       if (data.length >= PAGE_SIZE) {
  //         data.pop();
  //       }
  //     });

  //     handleUpdateData(data);
  //   }
  // }, [dataChanges]);

  /// handle click prev
  // useEffect(() => {
  //   if (isPrev) {
  //     setLoading(true);
  //     // const useWagerRef = userHistoryCollRef(address);
  //     const _filters: filters = {
  //       time: timeFilter,
  //     };
  //     const q: Query<DocumentData> = buildQuery(_filters, false, true, false);
  //     getDocs(q).then((snapShot: QuerySnapshot<any>) => {
  //       let count: number = 0;
  //       setHistoryDataPage(snapShot);
  //       let data: HistoryData[] = [];
  //       snapShot.docs.forEach((doc) => {
  //         if (
  //           count <= snapShot.docs.length - 2 ||
  //           snapShot.docs.length !== PAGE_SIZE + 1
  //         ) {
  //           data.push({
  //             txHash: doc.id,
  //             transactionTime: doc.data().transactionTime,
  //             amounts: doc.data().amounts,
  //             total_amount: doc.data().total_amount,
  //             order_status: doc.data().order_status,
  //             outcomes: doc.data().outcomes,
  //             wagers: doc.data().wagers,
  //             system_type: doc.data().system_type,
  //             type: doc.data().type,
  //             user_id: doc.data().user_id,
  //           });
  //         }
  //         count++;
  //       });

  //       setNextCursorPointer(snapShot.docs[snapShot.docs.length - 1]);
  //       if (snapShot.docs.length === PAGE_SIZE + 1) {
  //         setPrevCursorPointer(snapShot.docs[1]);
  //       } else {
  //         setPrevCursorPointer(null);
  //       }
  //       if (data.length) {
  //         handleUpdateData(data);
  //         setIsPrev(false);
  //       } else {
  //         setHistoryRaw([]);
  //         setIsPrev(false);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isPrev]);

  return {
    historyRaw,
    loading: isLoading,
    filter,
    setFilter,
    timeFilter,
    setTimeFilter: handleChangeFilter,
    setFilterOrderBy: handleChangeOrderBy,
    search,
    setSearch,
    onPageChanged,
    count,
    page,
  };
};
export { useHistory };
