import { toast } from "react-toastify";
import { getContract } from "../contracts/Contract";
import { ContractAddress } from "../contracts/config";
import { BaseProvider } from "@ethersproject/providers";
import { redeem } from "utils/apis/bookmakerApis";
import { BigNumber, WEI6, bnSort, buildWagerId } from "./betUtils";
import { userWagerProxy } from "proxy-state/userWagerProxy";
import { UserWagers } from "interfaces/firebase";
const allowCaim = WEI6.mul(20);
export const HandleClaimed = async (
  ids: any[],
  address: any,
  signer: any,
  provider: BaseProvider,
  setClaiming: any,
  setWagerIdsClaimed: React.Dispatch<React.SetStateAction<Array<any>>>,
  isPaymaster = true
) => {
  if (!ids.length) {
    toast.error("No wager to claim!");
    return;
  }
  const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 300;
  const contractGroup = ContractAddress[Number(chainId)];
  const addressContract = contractGroup["Wager"];

  if (!addressContract) {
    toast.error("Contract address not found!");
    return;
  }
  const _ids: any[] = [];
  // sort wager Id acsending
  const _buildWagerIds = ids.map((w: any) =>
    buildWagerId(w.outcomeIds.map((o: any) => BigNumber.from(o)))
  );
  const _sortBN = bnSort(_buildWagerIds);
  const wIds = _sortBN.map((w) => w.toHexString());
  wIds.forEach((w_id: string) => {
    const _wager = ids.find(
      (w) =>
        w?.wager_id === w_id ||
        BigNumber.from(w?.wager_id).eq(BigNumber.from(w_id))
    );
    _wager && _ids.push(_wager?.outcomeIds);
  });
  const zkid = 1;
  try {
    setClaiming(true);
    const contractInstance = getContract("Wager", addressContract, signer);
    let tx;
    const transaction = await contractInstance.populateTransaction.redeem(
      zkid,
      _ids
    );
    tx = await signer.sendTransaction(transaction);

    await tx.wait();
    if (tx.hash) {
      const wagerIds = ids.map((w: any) => w?.id);
      setWagerIdsClaimed(wagerIds);
      toast.success("Claimed successfully!");
    }
    setClaiming(false);
    return tx.hash;
  } catch (e) {
    toast.error("Failed. Please try again later!");
    console.log(e);
    setClaiming(false);
  }
};
export const handleClaimedV2 = async (
  wagers: UserWagers[],
  setClaiming: any,
  setWagerIdsClaimed: React.Dispatch<React.SetStateAction<Array<any>>>
) => {
  const zkid = 1;
  const _ids: any[] = [];
  let amount = 0;
  // sort wager Id acsending
  const _buildWagerIds = wagers.map((w: UserWagers) =>
    buildWagerId(w.outcomeIds.map((o) => BigNumber.from(o)))
  );
  const _sortBN = bnSort(_buildWagerIds);
  const wIds = _sortBN.map((w) => w.toHexString());
  wIds.forEach((w_id: string) => {
    const _wager = wagers.find(
      (w) =>
        w?.wager_id === w_id ||
        BigNumber.from(w?.wager_id).eq(BigNumber.from(w_id))
    );
    _wager && _ids.push(_wager?.outcomeIds);
    if (_wager?.status === 4) {
      amount += Number(_wager?.amount || 0);
    } else if (_wager?.status === 5) {
      amount += Number(_wager?.redeemable || 0);
    }
  });
  if (amount < Number(allowCaim.toString())) {
    toast.error("Min claim: 20 zkUSD");
    return;
  }
  console.log({ zkid, _ids });
  try {
    const resp = await redeem(zkid, _ids);
    if (resp.data) {
      const wagerIds = wagers.map((w: any) => w?.wager_id);
      setWagerIdsClaimed(wagerIds);

      wagers.forEach((wager) => {
        const _wager = userWagerProxy.items.get(wager.wager_id);

        if (_wager) {
          userWagerProxy.items.set(wager.wager_id, {
            ..._wager,
            redeemable: 0,
            redeemAmount: wager.amount || wager.redeemable,
            amount: 0,
            isRedeemed: true,
          });
        }
      });
      toast.success("Claimed successfully!");
    }
    setClaiming(false);
    return resp.data;
  } catch (error) {
    toast.error("Failed. Try again later!");
    console.log("🚀 ---------------------------------------------🚀");
    console.log("🚀 ~ file: actionClaim.tsx:117 ~ error:", error);
    console.log("🚀 ----------------------------------------------🚀");
  } finally {
    setClaiming(false);
  }
};
