// import Swiper core and required modules
import React from "react";
import { Scrollbar, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { NavigationOptions } from "swiper/types";
import { isMobile } from "react-device-detect";
interface Props {
  children: React.ReactNode;
}
const ScollList: React.FC<Props> = ({ children }) => {
  const [prevShow, setPrevShow] = React.useState(false);
  const [nextShow, setNextShow] = React.useState(false);
  const handleReachEnd = () => {
    ("Reached the end");
    setNextShow(false);
    setPrevShow(true);
    // Add your logic for the last element event
  };

  const handleReachBeginning = () => {
    ("Reached the beginning");
    setNextShow(true);
    setPrevShow(false);
    // Add your logic for the first element event
  };
  const swiperOptions: NavigationOptions = {
    enabled: true,
    prevEl: ".swiper-shadow-prev",
    nextEl: ".swipe-shadow-next",
    // Add other Swiper configuration options here
  };
  const count = isMobile ? 5 : 10;
  return (
    <Swiper
      modules={[Scrollbar, Navigation]}
      navigation={swiperOptions}
      slidesPerView="auto"
      freeMode={true}
      spaceBetween={8}
      onDrag={(e) => {
        e.preventDefault();
      }}
      grabCursor={true}
      className="swiper-container"
      style={{ margin: "inherit" }}
      onReachEnd={handleReachEnd}
      onRealIndexChange={(swiper) => {
        if (swiper.realIndex === 0) {
          setPrevShow(false);
        } else {
          setPrevShow(true);
        }
        if (swiper.realIndex === swiper.slides.length - 1) {
          setNextShow(false);
        } else {
          setNextShow(true);
        }

      }}
      onReachBeginning={handleReachBeginning}
      // style={{ overflow: 'visible', '--swiper-scrollbar-color': 'transparent' }}
    >
      {React.Children.map(children, (child) => (
        <SwiperSlide style={{ width: "auto" }}>{child}</SwiperSlide>
      ))}
      {/* Custom navigation */}
      {React.Children.count(children) > count && (
        <>
        {prevShow  && <div className="swiper-shadow-prev">&nbsp;</div>}
        {nextShow && <div className="swiper-shadow-next">&nbsp;</div>}
        </>
      )}
    </Swiper>
  );
};
export default ScollList;
