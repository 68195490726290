import React from "react";
import ScrollList from "../Sports/utils/scrollList";

const normalClass = "btn-ghost !text-base-content/50";
const activeClass =
  "border-primary text-primary bg-transparent hover:bg-transparent hover:border-primary";

const Tabs = ({ tabList, tab, setTab, scroll }: any) => {
  if (0 === tabList.length) return null;
  const child = tabList.map((item: any, idx: number) => {
    if (item.hasOwnProperty("name") && item.hasOwnProperty("key")) {
      return (
        <button
          key={idx}
          onClick={() => setTab(item.key)}
          className={`btn btn-md px-3 capitalize ${
            tab === item.key ? activeClass : normalClass
          }`}
        >
          <span className={``}>{item.name}</span>
        </button>
      );
    } else {
      return (
        <button
          key={idx}
          onClick={() => setTab(item)}
          className={`btn btn-md px-3 capitalize ${
            tab === item ? activeClass : normalClass
          }`}
        >
          <span className="flex flex-col justify-center items-center">
            <span className={``}>{item}</span>
          </span>
        </button>
      );
    }
  });
  return (
    <div className="flex items-center gap-2 overflow-x-auto overflow-y-hidden md:pb-2">
      {scroll ? (
        <>
          <ScrollList>{child}</ScrollList>
        </>
      ) : (
        child
      )}
    </div>
  );
};
export default Tabs;
