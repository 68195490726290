import { proxy } from "valtio";
import { saveOrderProps } from "../utils/apis/bookmakerApis";
export interface orderData {
	amount: number;
	txhash: string;
	roundId: number;
	poolId: number;

}
interface OrderDoneModalProxy {
	isOpen: boolean;
	data: saveOrderProps;
	setData: (data: saveOrderProps) => void;
	setOpen: (isOpen: boolean) => void;

}
const orderModalProxy = proxy<OrderDoneModalProxy>({
	isOpen: false,
	data: {
		outcomeIds: [],
		totalAmount: 0,
		odds: [],
		systemType: 0,
		txHash: "",
		orderType: "",
		wagerAmounts: [],
		amounts: []
	},
	setData: (data: saveOrderProps) => {
		orderModalProxy.data = data;
		orderModalProxy.isOpen = true;
	},
	setOpen: (isOpen: boolean) => {
		orderModalProxy.isOpen = isOpen;
	},
});
export default orderModalProxy;