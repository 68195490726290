import React, { useEffect } from "react";
import { floatFormat } from "../../../common/utils";
import _ from "lodash";
import OddElement from "./OddElement";
interface OddType5Props {
  idx: number;
  marketLiquid: number;
  market: any;
  item: any;
  odds: any;
  parentUrl: string;
}
const OddType5: React.FC<OddType5Props> = (props) => {
  const { idx, market, item, odds, parentUrl, marketLiquid } = props;
  const [homeScore, setHomeScore] = React.useState(0);
  const [awayScore, setAwayScore] = React.useState(0);
  const [odd, setOdd] = React.useState<any>({});
  const [homeMaxScore, setHomeMaxScore] = React.useState(0);
  const [awayMaxScore, setAwayMaxScore] = React.useState(0);

  // get first max score
  useEffect(() => {
    const maxScore = [0, 0];
    const scores = [];
    odds.map((odd: any) => {
      let [home, away] = odd?.name.split("-");
      if (odd?.header === "2") [home, away] = odd?.name.split("-").reverse();
      const [homeMax, awayMax] = maxScore;
      maxScore[0] = Number(home) > homeMax ? Number(home) : homeMax;
      maxScore[1] = Number(away) > awayMax ? Number(away) : awayMax;
    });

    setHomeMaxScore(maxScore[0]);
    setAwayMaxScore(maxScore[1]);
  }, [odds]);

  // finding max away score from home score
  useEffect(() => {
    const maxScore = [0, 0];
    const scores: Array<any> = [];
    odds.map((odd: any) => {
      const [homeMax, awayMax] = maxScore;
      let [home, away] = odd?.name.split("-");
      if (odd?.header === "2") [home, away] = odd?.name.split("-").reverse();
      if (Number(home) === homeScore) {
        scores.push(Number(away));
      }
    });

    setAwayMaxScore(_.max(scores) || 0);
  }, [homeScore]);

  // finding max home score from away score
  useEffect(() => {
    const maxScore = [0, 0];
    const scores: Array<any> = [];
    odds.map((odd: any) => {
      const [homeMax, awayMax] = maxScore;
      let [home, away] = odd?.name.split("-");
      if (odd?.header === "2") [home, away] = odd?.name.split("-").reverse();
      if (Number(away) === awayScore) {
        scores.push(Number(home));
      }
    });

    setHomeMaxScore(_.max(scores) || 0);
  }, [awayScore]);

  //check get exactly odd from score
  useEffect(() => {
    const _odd =
      odds.find(
        (odd: any) =>
          odd?.name === `${homeScore}-${awayScore}` ||
          (odd?.header === "2" &&
            odd?.name.split("-").reverse().join("-") ===
              `${homeScore}-${awayScore}`)
      ) || {};
    setOdd(_odd);
  }, [homeScore, awayScore]);

  const outcomeClass =
    "group w-full flex flex-col md:flex-row justify-between md:items-center rounded-box bg-base-200 border border-transparent shadow-xs hover:border-primary hover:shadow focus:bg-primary focus:text-primary-content focus:shadow-lg p-2 transition-all";

  const outcomeTitleClass =
    "opacity-80 group-hover:opacity-100 group-focus:opacity-100 text-xs w-full text-left truncate pr-1 font-medium";
  const outcomeValueClass =
    "group-hover:text-primary group-focus:text-primary-content font-semibold text-sm";

  return (
    <>
      <div className="space-y-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
          <div className="border border-base-content border-opacity-20 hover:border-opacity-100 rounded-box text-sm p-0 flex items-center justify-between">
            <span className={`${outcomeTitleClass} px-2`}>
              {market?.home?.name}
            </span>
            <div className="grid grid-cols-3 w-1/2">
              <button
                className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${
                  homeScore <= 0 ? " btn-disabled" : ""
                }`}
                onClick={() => setHomeScore(homeScore > 0 ? homeScore - 1 : 0)}
              >
                <span className="">-</span>
              </button>
              <span className="w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100">
                {homeScore}
              </span>
              <button
                className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${
                  homeScore >= homeMaxScore ? " btn-disabled" : ""
                }`}
                onClick={() => setHomeScore(homeScore + 1)}
              >
                <span className="">+</span>
              </button>
            </div>
          </div>

          <div className="border border-base-content border-opacity-20 hover:border-opacity-100 rounded-box text-sm p-0 flex items-center justify-between">
            <span className={`${outcomeTitleClass} px-2`}>
              {market?.away?.name}
            </span>
            <div className="grid grid-cols-3 w-1/2">
              <button
                className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${
                  awayScore <= 0 ? " btn-disabled" : ""
                }`}
                onClick={() => setAwayScore(awayScore > 0 ? awayScore - 1 : 0)}
              >
                <span className="">-</span>
              </button>
              <span className="w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100">
                {awayScore}
              </span>
              <button
                className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${
                  awayScore >= awayMaxScore ? " btn-disabled" : ""
                }`}
                onClick={() => setAwayScore(awayScore + 1)}
              >
                <span className="">+</span>
              </button>
            </div>
          </div>
          <OddElement
            idx={0}
            odd={odds.find((odd: any) => odd?.name === `1-0`)}
            item={item}
            market={market}
            parentUrl={parentUrl}
            marketLiquid={marketLiquid}
          />
        </div>

        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
				<div className="border border-base-content border-opacity-20 hover:border-opacity-100 rounded-box text-sm p-0 flex items-center justify-between">
					<span className={`${outcomeTitleClass} px-2`}>{market?.home?.name}</span>
					<div className="grid grid-cols-3 w-1/2">
						<button className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${homeScore <= 0 ? ' btn-disabled' : ''}`} onClick={()=> setHomeScore(homeScore > 0 ? homeScore - 1 : 0)}>
							<span className="">-</span>
						</button>
						<span className="w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100">{homeScore}</span>
						<button className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${homeScore >= homeMaxScore ? ' btn-disabled' : ''}`} onClick={()=> setHomeScore(homeScore +1)}>
							<span className="">+</span>
						</button>
					</div>
				</div>

				<div className="border border-base-content border-opacity-20 hover:border-opacity-100 rounded-box text-sm p-0 flex items-center justify-between">
					<span className={`${outcomeTitleClass} px-2`}>{market?.away?.name}</span>
					<div className="grid grid-cols-3 w-1/2">
						<button className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${awayScore <= 0 ? ' btn-disabled' : ''}`} onClick={()=> setAwayScore(awayScore > 0 ? awayScore - 1 : 0)}>
							<span className="">-</span>
						</button>
						<span className="w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100">{awayScore}</span>
						<button className={`w-full h-full flex justify-center items-center font-semibold opacity-70 hover:opacity-100${awayScore >= awayMaxScore ? ' btn-disabled' : ''}`} onClick={()=> setAwayScore(awayScore+1)}>
							<span className="">+</span>
						</button>
					</div>
				</div>
				{odd?.outcome_id && (<OddElement idx={0} odd={odd} item={item} market={market} parentUrl={parentUrl} /> )}
			</div> */}
      </div>
    </>
  );
};

export default OddType5;
