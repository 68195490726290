import { proxy } from 'valtio';
import { Category } from "interfaces/firebase";
const categoryNavProxy: {
	cateTab: string;
	cateTabList: Category[];
	setCateTab: (tab: string) => void;
	setCateTabList: (tabList: Category[]) => void;
} = proxy({
	cateTab: 'soccer',
	cateTabList: [],
	setCateTab: (tab) => {
		categoryNavProxy.cateTab = tab;
	},
	setCateTabList: (tabList) => {
		categoryNavProxy.cateTabList = tabList;
	}
});
export default categoryNavProxy;