import React, { Fragment, useEffect, useRef, useState } from "react";
import { BigNumber, utils as EUtils, ethers } from "ethers";
import { Disclosure } from "@headlessui/react";
import utils, { floatFormat, formatBigNumber } from "common/utils";
import { getPriceImpact } from "hooks/useOrderSlip";
import { BN0, DECIMALS } from "functions/betUtils";
import moment from "moment";
import { getTitleMatches } from "components/organisms/Sports/utils/sports.utils";
import { calculateSingleBetV2 } from "functions/amm-v2";
import {
  GetEventMarketLiquidRewards,
  getOfferedBetPayout,
} from "functions/event";
import { getThresholdFromConfig } from "functions/threshold.utils";
import { parseBigNumber, string2Number } from "common/utils";
import { useSnapshot } from "valtio";
import { outcomeProxy } from "proxy-state/outcomeProxy";
import { betOrder, OrderSlipFunc } from "proxy-state/betting/OrderSlip";
import { eventProxy } from "proxy-state/eventProxy";
import { TargetFunc, targetProxy } from "proxy-state/betting/target";
import { configProxy } from "proxy-state/configProxy";
import { liquidProxy } from "proxy-state/betting/Liquidity";

interface OrderBoxProps {
  item: betOrder;
  betidx: number;
  modeTab: string;
  sameOrder: boolean;
}

const OrderBox = ({ item, betidx, modeTab, sameOrder }: OrderBoxProps) => {
  const { data: OutcomesLiquids } = useSnapshot(outcomeProxy);
  const { data: events } = useSnapshot(eventProxy);
  const { threshold: thresholdData, symbol } = useSnapshot(configProxy);
  const { singleTarget, default: defaultTarget } = useSnapshot(targetProxy);
  const event = events.get(item?.eventId?.toString());
  const { sensitivity } = useSnapshot(liquidProxy);
  const [rewards, setRewards] = useState<Map<string, BigNumber>>(new Map());
  const [currentTime, setCurrentTime] = useState(moment().unix());
  const inputRef = useRef<HTMLInputElement>(null);
  // const [inputTarget, setInputTarget] = useState<string>(defaultTarget.toString());
  let closed: boolean = false;
  const startTime = Number(item?.time) - 30;
  if (Number(item?.time_status) > 1 || currentTime >= startTime) {
    closed = true;
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const now = moment().unix();
  //     setCurrentTime(now);
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [item?.time_status]);
  // console.log("singleTargetArray ====> ", singleTargetArray);
  const oddName = utils._getOddName(item);
  const target =
    (singleTarget.has(item?.odds?.outcome_id)
      ? singleTarget.get(item?.odds?.outcome_id)
      : defaultTarget) || 0;
  // useEffect(() => {
  //   if (!target || target !== Number(inputTarget)) {
  //     TargetFunc.setSingleTarget(item?.odds?.outcome_id, Number(inputTarget));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inputTarget]);
  let odds_cal = EUtils.parseUnits(
    Number(item?.odds?.roi.toString()).toFixed(DECIMALS),
    DECIMALS
  );
  const { wagerAmounts } = calculateSingleBetV2(
    [BigNumber.from(item?.odds?.outcome_id)],
    [event],
    OutcomesLiquids,
    [parseBigNumber(Number(target) || 0, true)],
    sensitivity
  );
  let totalWagerAmount: BigNumber = wagerAmounts.reduce(
    (a: any, b: any) => a.add(b),
    BN0
  );
  let potentialWin: number = formatBigNumber(totalWagerAmount, true);

  const { priceImpactPercent: priceImpact } = getPriceImpact(
    Number(target),
    formatBigNumber(odds_cal),
    potentialWin
  );
  useEffect(() => {
    if (!item) return;
    const getOfferPayout = async () => {
      const res = await GetEventMarketLiquidRewards(
        item?.eventId.toString(),
        item.marketKey
      );
      setRewards(res.rewards);
    };
    getOfferPayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  const threshold = getThresholdFromConfig(
    thresholdData,
    item?.category_id,
    item?.marketKey
  );
  // const { offeredBet, offeredPayout } = getOfferedBetPayout(res.rewards, item.marketLiquid, threshold, Number(floatFormat(offerOdd,2)2, item.odds.outcome_id, item.placeOrder.amount);
  const { offeredBet, offeredPayout } = getOfferedBetPayout(
    rewards,
    ethers.utils.parseUnits(
      Number(item.marketLiquid).toFixed(DECIMALS).toString(),
      DECIMALS
    ),
    ethers.utils.parseUnits(
      Number(threshold).toFixed(DECIMALS).toString(),
      DECIMALS
    ),
    parseBigNumber(Number(floatFormat(item?.odds?.roi, 2)), true),
    item?.odds?.roi || 0,
    item.odds.outcome_id,
    ethers.utils.parseUnits(
      target.toFixed(DECIMALS).toString() || "0",
      DECIMALS
    )
  );
  const _offeredPayout = formatBigNumber(offeredPayout, true);

  if (formatBigNumber(offeredPayout, true) > 0) {
    potentialWin = formatBigNumber(offeredPayout, true);
  }
  useEffect(() => {
    if (Number(_offeredPayout) > 0) {
      OrderSlipFunc.setOverPayout(item.placeOrder.outcomeId, true);
    } else {
      OrderSlipFunc.setOverPayout(item.placeOrder.outcomeId, false);
    }
    // OrderSlipFunc.addItem(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_offeredPayout]);

  const removeBetSlipsBtn = (outcomeId: string) => {
    OrderSlipFunc.deleteItem(outcomeId);
    TargetFunc.deleteTarget(outcomeId);
  };

  if (!item) return null;
  const OrderBoxChild = () => (
    <div
      className={`bet${
        modeTab === "single-mode" && Number(_offeredPayout) > 0
          ? " bet--same-order"
          : ""
      }`}
      key={item.eventId}
    >
      <div className="bet-header">
        <h5 className="pr-2 font-medium text-xs">
          {getTitleMatches({
            home: item.eventName.split(" vs ")[0],
            icon_classes: item?.cate_icon_class || "fa-duotone fa-futbol",
            away: item.eventName.split(" vs ")[1],
            matches_url: `${item?.eventUrl}`,
          })}
        </h5>
        <button
          className={`btn bg-neutral btn-circle btn-xs hover:text-error tooltip tooltip-left opacity-50 hover:opacity-100 -mr-2`}
          data-tip="Remove Slip"
          onClick={() => removeBetSlipsBtn(item.placeOrder.outcomeId)}
        >
          <i className="fa-solid fa-minus"></i>
        </button>
      </div>

      <div className="mt-2 px-4 pb-3">
        <div className="">
          <div className="text-base-content/50 text-xs">{item.marketName}</div>
          <div className="flex justify-between items-center font-semibold mt-1">
            <span className="text-base-content">{oddName}</span>
            <span className={`text-primary font-semibold`}>
              {floatFormat(Number(item?.odds?.roi || item?.odds?.odds))}
            </span>
          </div>
        </div>
        {modeTab === "single-mode" && (
          <>
            <div className="flex justify-between items-center mt-1">
              <label className="label-placeholder flex-shrink-0">
                <span className="text-xs opacity-50">Order Amount</span>
              </label>
              <div className="-mr-2 w-40 lg:w-32 flex items-center font-semibold text-xs relative">
                <input
                  value={target || ""}
                  min="0"
                  type="number"
                  step="any"
                  ref={inputRef}
                  maxLength={6}
                  placeholder="0.0"
                  title="Currency"
                  onChange={(e) => {
                    const _value = e.target.value
                      .replace(/[^0-9.-]/g, "")
                      .replace(/(\..*)\./g, "$1")
                      .replace(/(\-.*)\-/g, "$1");
                    // setInputTarget(_value);
                    // const _singleTarget = singleTarget;
                    // _singleTarget.set(item?.odds?.outcome_id, string2Number(_value) || 0);
                    // TargetFunc.setSingleTargets(_singleTarget);
                    TargetFunc.setSingleTarget(
                      item?.odds?.outcome_id,
                      string2Number(_value) || 0
                    );
                  }}
                  className={`input input-bordered h-auto text-sm text-right w-full pr-[3.1rem] pl-1 py-0.5`}
                />
                <span className="absolute right-2 top-auto mt-[1px] font-medium leading-none">
                  {symbol}
                </span>
              </div>
            </div>
            <div className="mt-0">
              <Disclosure as="div" className="" defaultOpen={false}>
                {({ open }) => (
                  <Fragment>
                    <Disclosure.Button className="w-full mb-1">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center text-xs">
                          <span className="opacity-50 mr-2">Potential Win</span>
                          {!open ? (
                            <i className="fa-solid fa-ellipsis [text-10px] transition-all"></i>
                          ) : (
                            <i className="fa-solid fa-ellipsis text-primary [text-10px] transition-all"></i>
                          )}
                        </div>
                        <div className="pl-2 flex items-center text-xs font-medium">
                          {floatFormat(potentialWin, 2)}
                          <span className="ml-1 font-medium">{symbol}</span>
                        </div>
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      <div className="flex justify-between items-center">
                        <span className="opacity-50 text-xs">
                          Potential Profit
                        </span>
                        <div className="pl-2 flex items-center text-xs font-medium">
                          {floatFormat(
                            Number(potentialWin) - Number(target),
                            2
                          )}
                          <span className="ml-1 font-medium">{symbol}</span>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="opacity-50 text-xs">Price Impact</span>
                        <div className="pl-2 flex items-center text-xs font-medium">
                          {floatFormat(priceImpact)}%
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Fragment>
                )}
              </Disclosure>
            </div>
          </>
        )}
        {closed && (
          <>
            <div className="flex flex-col items-center justify-center absolute top-11 left-2 right-2 bottom-3 bg-error/10 backdrop-blur rounded-box p-4">
              <span className="flex items-baseline">
                <i className="fa-duotone fa-flag-swallowtail text-sm mr-2"></i>
                <span className="font-medium text-sm opacity-50">Closed</span>
              </span>
              {/* <span className="text-xs opacity-70 mt-1">Please remove it from your Order Slip!</span> */}
            </div>
          </>
        )}
      </div>
      {modeTab === "single-mode" &&
        formatBigNumber(offeredPayout, true) > 0 && (
          <span className="flex items-center text-xs bg-warning text-warning-content px-4 py-1 rounded-box">
            <i className="fa-solid fa-circle-exclamation text-sm mr-2"></i> Bet
            exceeded threshold! You can bet up to{" "}
            {floatFormat(formatBigNumber(offeredBet, true), 2)} {symbol} on this
            outcome.
          </span>
        )}
      {modeTab === "single-mode" && !target && !closed && (
        <>
          <span className="flex items-center text-xs bg-warning text-warning-content px-4 py-1 rounded-box">
            <i className="fa-solid fa-circle-exclamation text-sm mr-2"></i>{" "}
            Please enter your order amount!
          </span>
        </>
      )}
    </div>
  );
  return <>{OrderBoxChild()}</>;
};

export default OrderBox;
