import {
  goerli,
  zkSync,
  zkSyncSepoliaTestnet,
  mainnet,
  lineaTestnet,
} from "wagmi/chains";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { ChainId } from "utils/constants/config";

const goerliProvider = new StaticJsonRpcProvider(
  goerli.rpcUrls.default.http[0],
  goerli.id
);

const zksyncMainnetProvider = new StaticJsonRpcProvider(
  "https://rough-autumn-mountain.zksync-mainnet.quiknode.pro/6be07016f6fe4ff2f38ac2159a34b15312398851",
  zkSync.id
);

const mainnetProvider = new StaticJsonRpcProvider(
  mainnet.rpcUrls.default.http[0],
  mainnet.id
);

const zksyncTestnetProvider = new StaticJsonRpcProvider(
  zkSyncSepoliaTestnet.rpcUrls.default.http[0],
  zkSyncSepoliaTestnet.id
);

const lineaTestnetProvider = new StaticJsonRpcProvider(
  lineaTestnet.rpcUrls.default.http[0],
  lineaTestnet.id
);

const lineaMainnetProvider = new StaticJsonRpcProvider(
  "https://rpc.linea.build	",
  59144
);

export const PROVIDERS: { [chainId in ChainId]: StaticJsonRpcProvider } = {
  [ChainId.GOERLI]: goerliProvider,
  [ChainId.ZKSYNC_MAINNET]: zksyncMainnetProvider,
  [ChainId.LINEA_MAINNET]: lineaMainnetProvider,
  [ChainId.LINEA_TESTNET]: lineaTestnetProvider,
  [ChainId.ZKSYNC_TESTNET]: zksyncTestnetProvider,
  [ChainId.MAINNET]: mainnetProvider,
};
export const getProvider = (chainId: ChainId): StaticJsonRpcProvider => {
  if (!PROVIDERS[chainId]) {
    throw new Error(`Unknown provider for chainId ${chainId}`);
  }

  return PROVIDERS[chainId];
};
export const useProvider = (chainId: string): StaticJsonRpcProvider => {
  let _chainId: ChainId = ChainId.ZKSYNC_MAINNET;
  switch (chainId) {
    case "324":
      _chainId = ChainId.ZKSYNC_MAINNET;
      break;
    case "300":
      _chainId = ChainId.ZKSYNC_TESTNET;
  }
  if (!PROVIDERS[_chainId]) {
    throw new Error(`Unknown provider for chainId ${chainId}`);
  }

  return PROVIDERS[_chainId];
};
