import { SubLayout } from "components/layouts/MainLayout/subLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import NotificationCenter from "../page";

const routes: AppRouteType[] = [
  {
    path: slugs.notificationCenter,
    component: NotificationCenter,
    container: SubLayout,
    isAuthNeeded: false,
  },
];

export default routes;
