import { BigNumber } from "ethers";
import { Threshold } from "proxy-state/configProxy";
import { parseEther } from "ethers/lib/utils";
import { BN0, WEI } from "./betUtils";

export const getThresholdFromConfig = (
  data: Threshold,
  sport: string,
  market: string
) => {
  let threshold = 0;
  if (data[sport]) {
    for (const [, value] of Object.entries(data[sport])) {
      if (value && value[market]) {
        threshold = Number(value[market]);
      }
    }
  }
  return threshold;
};

export const getLiquidRewardState = (
  outcome: string,
  curBet: BigNumber, // luong tien user bet cho outcome do
  odd: number, // odd cua outcome do
  curRewards: Map<string, BigNumber>, // reward cho tung outcome cua 1 market
  curLiquid: BigNumber, // liquid cua market cua outcome do
  threshold: BigNumber
) => {
  const userReward = curBet.mul(parseEther(`${odd ? odd : 0}`)).div(WEI);
  const riskTolerance = threshold.add(curLiquid);
  const curMaxReward = getMaxBigNumber(
    Object.keys(curRewards).map((outcomeId) => curRewards.get(outcomeId) ?? BN0)
  );
  return {
    curMaxReward,
    riskTolerance,
    userReward,
    curReward: curRewards.get(outcome) ?? BN0,
  };
};

export const offerBet = (
  riskTolerance: BigNumber,
  curReward: BigNumber,
  odd: number
): BigNumber =>
  riskTolerance
    .sub(curReward)
    .mul(WEI)
    .div(parseEther(`${odd}`));

export const getMaxBigNumber = (bns: BigNumber[]): BigNumber => {
  return bns.reduce((currentMax, currentValue) => {
    if (currentValue.gt(currentMax)) {
      return currentValue;
    } else {
      return currentMax;
    }
  }, BigNumber.from(0));
};
