import React, { Fragment } from "react";
import HeaderSub from "components/organisms/Header/header-sub";
import { useMatch } from "react-router-dom";
import slugs from "navigation/slugs";
import {
  MainNavigation,
  MobileNavigation,
  Responsive,
} from "components/commons";
import { useSnapshot } from "valtio";
import { navFunc, navProxy } from "proxy-state/global";
import clsx from "clsx";
import OrderSlips from "components/organisms/PlaceOrder";
interface Props {
  children: React.ReactNode;
}

const paneLeftClass = "fixed left-0 top-0 bottom-0 z-50";
const paneCenterClass = "flex flex-col w-full";
// ClassName
const paneRightClass =
  "bg-base-200 shadow-2xl fixed top-0 bottom-0 right-0 overflow-hidden z-50 w-full lg:w-80 transition-all";

export const SubLayout: React.FC<Props> = ({ children }) => {
  const isEventMatch = useMatch(slugs.event);
  const { isCollapse, isShowOrder } = useSnapshot(navProxy);

  return (
    <Fragment>
      <>
        <div className="main-layout--wrapper pb-20 lg:pb-0">
          <div className="flex items-stretch w-full h-full z-[1] relative">
            <Responsive from={"lg"}>
              <div
                className={`${paneLeftClass} ${isCollapse ? "w-14" : "w-60"}`}
              >
                <MainNavigation collapse={isCollapse} />
              </div>
            </Responsive>

            <div
              className={`${paneCenterClass}  ${
                isCollapse ? "lg:pl-14" : "lg:pl-60"
              } ${!isShowOrder ? "lg:pr-0" : "lg:pr-80"}`}
            >
              {" "}
              <div
                className={`pane-center--body ${
                  !isShowOrder ? "" : "lg:overflow-y-auto lg:overflow-x-hidden"
                }`}
              >
                <div className="flex flex-col flex-1 min-h-full items-stretch lg:pb-0">
                  <HeaderSub />
                  <main className="h-full flex-1">{children}</main>
                  <br className="clear-both" />
                </div>
              </div>
            </div>
            <aside
              className={`${paneRightClass} ${
                !isShowOrder
                  ? "-right-full translate-x-full"
                  : "right-0 translate-x-0"
              }`}
            >
              <OrderSlips />
            </aside>
          </div>
        </div>
      </>
    </Fragment>
  );
};
