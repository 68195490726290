import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { getAddressSimpleString } from "utils/blockchain";
import AcceptedToken from "components/commons/TokenIcon";
// import ActivitiesSkeleton from "../skeleton";
import { Link } from "react-router-dom";
import { DECIMALS} from "functions/betUtils";
import { ethers } from "ethers";
import { ReactComponent as Rookie } from "assets/badges/rookie.svg";
import { ReactComponent as Veteran } from "assets/badges/veteran.svg";
import { ReactComponent as Regular } from "assets/badges/regular.svg";
import { ReactComponent as Starter } from "assets/badges/starter.svg";
import { ReactComponent as HallOfFame } from "assets/badges/hall-of-fame.svg";
import { ReactComponent as AllStar } from "assets/badges/all-star.svg";
import { ConvertString2BN } from "common/utils";
import { useAccount } from "hooks/useAccount";
import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
  DocumentData,
  query,
  getCountFromServer,
  QueryConstraint,
  orderBy,
  startAfter,
  limit,
  getDocs,
  where,
  getDoc,
} from "firebase/firestore";
import { userCollRef, userDocRef } from "utils/firebase/firebaseDBRef";
import { useSnapshot } from "valtio";
import { myProfileProxy } from "proxy-state/myProfileProxy";
import { PAGINATION_SIZE } from "shared/constants";
import { Pagination } from "components/organisms/Pagination/Pagination";
import { CHAIN_ID } from "constant";
import { CHAINS } from "config";
import { isMobile } from "react-device-detect";
import { isAddress } from "viem";
import { ELeaderBoardFilter } from "shared/enum";
import { leaderBoardFilter } from "proxy-state/leaderBoardFilter";
import { IOrderBy } from 'shared/enum';
import { Img } from "components/commons/Img";

const explorerUrl = CHAINS[Number(CHAIN_ID) || 280].blockScanUrl;

const skClass = "bg-base-100/40 h-6 w-full rounded-box";

const LoadingSkeleton: React.FC<{ rows?: number }> = (props) => {
  const { rows = 5 } = props;
  const rowsList = Array.from(Array(rows).keys());
  return (
    <div
      className={
        "skeleton-loading w-full flex flex-col items-center justify-center space-y-2"
      }
    >
      <div className={"w-full grid grid-cols-12 gap-2 rounded-box"}>
        <div className={`col-span-2 ${skClass}`}></div>
        <div className={`col-span-3 ${skClass}`}></div>
        <div className={`col-span-4 ${skClass}`}></div>
        <div className={`col-span-3 ${skClass}`}></div>
      </div>
      {rowsList.map((e: any, idx: number) => {
        return (
          <div key={idx} className={`w-full grid grid-cols-12 gap-2`}>
            <div className={`col-span-2 ${skClass}`}></div>
            <div className={`col-span-3 ${skClass}`}></div>
            <div className={`col-span-4 ${skClass}`}></div>
            <div className={`col-span-3 ${skClass}`}></div>
          </div>
        );
      })}
    </div>
  );
};

const Empty = () => {
  return (
    <div
      className={
        "card w-full h-full flex flex-col items-center justify-start pt-[10%] pb-[10%]"
      }
    >
      <Img addPrefix alt={""} src={"/assets/history.svg"} />
      <span className={"text-base-content text-opacity-70 mt-2"}>
        No player found
      </span>
    </div>
  );
};

interface LeaderBoardsProps {}

const getUserRank = (dataRaw: any) => {
  let data = {
    ...dataRaw,
    rank: "Rookie",
  };
  if (!data?.totalAmount || !data?.totalPayout || !data?.PNL) {
    data.totalAmount = 0;
    data.totalPayout = 0;
    data.PNL = 0;
  }

  if (
    dataRaw?.totalAmount / 1000 >= 1000 &&
    dataRaw?.totalAmount / 1000 < 5000
  ) {
    data = { ...dataRaw, rank: "Starter" };
  }
  if (
    dataRaw?.totalAmount / 1000 >= 5000 &&
    dataRaw?.totalAmount / 1000 < 10000
  ) {
    data = { ...dataRaw, rank: "Regular" };
  }
  if (
    dataRaw?.totalAmount / 1000 >= 10000 &&
    dataRaw?.totalAmount / 1000 < 25000
  ) {
    data = { ...dataRaw, rank: "Veteran" };
  }
  if (
    dataRaw?.totalAmount / 1000 >= 25000 &&
    dataRaw?.totalAmount / 1000 < 50000
  ) {
    data = { ...dataRaw, rank: "All Star" };
  }
  if (dataRaw?.totalAmount / 1000 >= 50000) {
    data = { ...dataRaw, rank: "Hall of Fame" };
  }

  return data;
};

const PAGE_NUMB = 10;

const tabList = [
  { title: "Wagered", name: ELeaderBoardFilter.wagerer },
  { title: "Winning", name: ELeaderBoardFilter.winning },
];

const getConstraints = (filterType: string, sortBy: IOrderBy) => {
  const constraints: QueryConstraint[] = [];

  if (filterType === ELeaderBoardFilter.wagerer) {
    constraints.push(orderBy("totalAmount", sortBy));
  } else {
    constraints.push(orderBy("totalWon", sortBy));
  }

  return constraints;
};

const getFilter = (filterType: string) => {
  return filterType === ELeaderBoardFilter.wagerer ? "totalAmount" : "totalWon";
};

const getMinAmount = (
  filterType: string,
  totalAmount: number | undefined,
  totalWon: number | undefined
) => {
  let amount;
  if (getFilter(filterType) === "totalAmount") {
    amount = totalAmount || 0;
  } else {
    amount = totalWon || 0;
  }

  return amount;
};

const Table: React.FC<{ children: React.ReactNode; filterType: string }> = ({
  children,
  filterType,
}) => {
  return (
    <div className={"w-full overflow-x-auto"}>
      <table className={"w-full text-sm"}>
        <thead>
          <tr className={"tr"}>
            <th className={"th text-left !w-2"}>No.</th>
            <th className={"th text-center !w-24"}>Rank</th>
            <th className={"th text-left"}>Wallet</th>
            <th className="th text-right">Wagered</th>
            <th className="th text-right">Winning</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const Leaderboard: React.FC<LeaderBoardsProps> = () => {
  const { address }: any = useAccount();

  const { profile: user } = useSnapshot(myProfileProxy);

  const { filterType, orderBy: sortBy, filterChanged } = useSnapshot(leaderBoardFilter);

  const [search, setSearch] = useState<string>("");

  // ================== new pagination ==================
  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const [count, setCount] = useState(0);
  const [data, setData] = useState<QueryDocumentSnapshot<any, DocumentData>[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [isLoadingData, setLoading] = useState<boolean>(true);
  const [userRank, setUserRank] = useState(0);
  const [searchUser, setSearchUser] = useState<any>();

  const observerTarget = useRef(null);

  const nextButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (filterChanged){
      setPage(0)
      leaderBoardFilter.filterChanged = false;
    }
  }, [filterChanged])
  
  /// fetch raw data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const constraints = getConstraints(filterType, sortBy);
        const cursor = cursors.current.get(page);
        if (cursor) {
          constraints.push(startAfter(cursor));
        }
        const _query = query(userCollRef(), ...constraints, limit(PAGE_NUMB));
        const result = await getDocs(_query);
        console.log("🚀 ~ file: leaderBoard.tsx:205 ~ fetchData ~ filterType, sortBy", filterType, sortBy, page)
        if (isMobile) {
          // scroll infinity for mobile
          if (page === 0) {
            setData(result.docs);
          } else {
            setData((data) => data.concat(result.docs));
          }
        } else {
          setData(result.docs);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: leaderBoard.tsx:205 ~ fetchData ~ error:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filterType, page, sortBy]);

  /// fetch count
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const userCollection = userCollRef();

        const constraints = getConstraints(filterType, sortBy);

        const _query = query(userCollection, ...constraints);

        const result = await getCountFromServer(_query);
        setCount(result.data()?.count);
      } catch (error) {
        console.log(
          "🚀 ~ file: leaderBoard.tsx:68 ~ fetchCount ~ error:",
          error
        );
      }
    };

    fetchCount();
  }, [filterType, sortBy]);

  /// fetch current user rank
  useEffect(() => {
    const f = async () => {
      try {
        const _filter = getFilter(filterType);

        const amount = getMinAmount(
          filterType,
          user?.totalAmount,
          user?.totalWon
        );

        const _query = query(
          userCollRef(),
          where(_filter, ">=", amount),
          orderBy(_filter, "desc")
        );
        const result = await getCountFromServer(_query);
        const _rank = result.data()?.count;
        setUserRank(_rank);
      } catch (error) {}
    };

    f();
  }, [user?.totalAmount, user?.totalWon, filterType]);
  /// fetch user by search input
  useEffect(() => {
    const f = async () => {
      try {
        if (!isAddress(search)) return;
        setLoading(true);

        const user = await getDoc(userDocRef(search));

        const _filter = getFilter(filterType);

        const _user = user?.data();

        if (!user?.exists()) {
          setSearchUser(undefined);
          setLoading(false);
          return;
        }

        const amount = getMinAmount(
          filterType,
          _user?.totalAmount,
          _user?.totalWon
        );

        const _query = query(
          userCollRef(),
          where(_filter, ">=", amount),
          orderBy(_filter, "desc")
        );

        const result = await getCountFromServer(_query);
        const _rank = result.data()?.count;
        
        const _searchUser = {
          ..._user,
          index: _rank,
          rank: getUserRank(_user).rank,
        };
        setSearchUser(_searchUser);
      } catch (error) {
        console.log("🚀 ~ file: leaderBoard.tsx:284 ~ f ~ error:", error);
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [filterType, search]);

  /// dev: handle infinity scroll ::::::: trigger click next button for simulate pagination on mobile
  useEffect(() => {
    let observer: IntersectionObserver;

    observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("visible");
          nextButtonRef.current?.click();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current && observer) {
        console.log("unmount");
        observer.unobserve(observerTarget.current);
      }
    };
  }, []);

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        if (!data) return page;

        // first, we save the last document as page's cursor
        cursors.current.set(page + 1, data[data.length - 1]);

        // then we update the state with the next page's number
        return nextPage;
      });
    },
    [data]
  );

  /// count index for desktop
  let index = page * PAGE_NUMB + 1;

  /// reset index -> 1 for mobile scroll infinity
  if (isMobile) {
    index = 1;
  }

  const leaderboardData = data.map((item) => {
    let _data: DocumentData = item.data();

    _data = getUserRank({
      index: index,
      rank: "",
      totalAmount: _data[`totalAmount`] ? _data[`totalAmount`] : "0",
      totalPayout: _data[`totalWon`] ? _data[`totalWon`] : "0",
      id: item.id,
    });

    index++;

    return _data;
  });

  const currentUser = {
    ...user,
    index: userRank,
    rank: getUserRank(user).rank,
  };

  // ================== end: new pagination ==================

  const renderItem = (user: any) => {
    return (
      <tr className="tr tr-hilite">
        <td className={`td text-center`}>
          <span className={"inline-flex justify-center rounded-full py-0.5"}>
            {user?.index ? user.index : "-"}
          </span>
        </td>
        <td className={`td text-center`}>
          {user.rank === "Hall of Fame" && (
            <span className="tooltip" data-tip={user.rank}>
              <HallOfFame width={20} height={20} className="h-6" />
            </span>
          )}
          {user.rank === "All Star" && (
            <span className="tooltip" data-tip={user.rank}>
              <AllStar width={20} height={20} className="h-6" />
            </span>
          )}
          {user.rank === "Veteran" && (
            <span className="tooltip" data-tip={user.rank}>
              <Veteran width={20} height={20} className="h-6" />
            </span>
          )}
          {user.rank === "Regular" && (
            <span className="tooltip" data-tip={user.rank}>
              <Regular width={20} height={20} className="h-6" />
            </span>
          )}
          {user.rank === "Rookie" && (
            <span className="tooltip" data-tip={user.rank}>
              <Rookie width={20} height={20} className="h-6" />
            </span>
          )}
          {user.rank === "Starter" && (
            <span className="tooltip" data-tip={user.rank}>
              <Starter width={20} height={20} className="h-6" />
            </span>
          )}
        </td>
        <td className={`td`}>
          <div className={"flex w-full items-center"}>
            <i className="fa-duotone fa-user mr-2 text-xs"></i>
            <span className={`font-semibold`}>
              {getAddressSimpleString(user?.id?.toString(), 3)}
            </span>
          </div>
        </td>
        <td className={`td`}>
          <AcceptedToken
            price={Number(
              ethers.utils.formatUnits(
                ConvertString2BN(user?.totalAmount || 0),
                DECIMALS
              )
            ) || 0}
            showIcon={true}
            justify={"justify-end"}
          />
        </td>
        <td className={`td text-right`}>
          <AcceptedToken
            price={Number(
              ethers.utils.formatUnits(
                ConvertString2BN(user?.totalPayout || 0),
                DECIMALS
              )
            ) || 0}
            showIcon={true}
            justify={"justify-end"}
          />
        </td>
      </tr>
    );
  };

  const renderSearchResult = (filterType: string) => {
    if (isLoadingData) return <LoadingSkeleton />;

    if (!isLoadingData && !searchUser) return <Empty />;

    return <Table filterType={filterType}>{renderItem(searchUser)}</Table>;
  };

  const renderMainList = () => {
    return (
      <Table filterType={filterType}>
        {currentUser?.id && renderItem(currentUser)}
        {leaderboardData?.length
          ? leaderboardData.map((item: any, idx: number) => {
              const RankNoClass =
                item?.index === 1
                  ? " w-5 font-semibold bg-gradient-to-tr from-yellow-500 to-yellow-300 text-yellow-900"
                  : item?.index === 2
                  ? " w-5 font-semibold bg-gradient-to-tr from-slate-400 to-slate-100 text-slate-900"
                  : item?.index === 3
                  ? " w-5 font-semibold bg-gradient-to-tr from-stone-600 to-stone-400 text-stone-900"
                  : "";
              return (
                <tr key={idx} className={"tr"}>
                  <td className={`td text-center`}>
                    <span
                      className={`relative flex h-5  justify-center items-center rounded-full${RankNoClass}`}
                    >
                      {item?.index === 1 && (
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500 opacity-75"></span>
                      )}
                      {item?.index}
                    </span>
                  </td>
                  <td className={`td text-center`}>
                    {item.rank === "Hall of Fame" && (
                      <span className="tooltip" data-tip={item.rank}>
                        <HallOfFame width={20} height={20} className="h-6" />
                      </span>
                    )}
                    {item.rank === "All Star" && (
                      <span className="tooltip" data-tip={item.rank}>
                        <AllStar width={20} height={20} className="h-6" />
                      </span>
                    )}
                    {item.rank === "Veteran" && (
                      <span className="tooltip" data-tip={item.rank}>
                        <Veteran width={20} height={20} className="h-6" />
                      </span>
                    )}
                    {item.rank === "Regular" && (
                      <span className="tooltip" data-tip={item.rank}>
                        <Regular width={20} height={20} className="h-6" />
                      </span>
                    )}
                    {item.rank === "Rookie" && (
                      <span className="tooltip" data-tip={item.rank}>
                        <Rookie width={20} height={20} className="h-6" />
                      </span>
                    )}
                    {item.rank === "Starter" && (
                      <span className="tooltip" data-tip={item.rank}>
                        <Starter width={20} height={20} className="h-6" />
                      </span>
                    )}
                  </td>
                  <td className={`td`}>
                    <div
                      className={"flex w-full items-center"}
                      data-tip={item?.id}
                    >
                      <Link
                        to={`${explorerUrl}/address/${item?.id}`}
                        target={`_blank`}
                      >
                        <span className={`font-semibold`}>
                          {getAddressSimpleString(item?.id.toString(), 3)}
                        </span>
                      </Link>
                    </div>
                  </td>
                  <td className={`td`}>
                    <AcceptedToken
                      price={
                        Number(
                          ethers.utils.formatUnits(
                            ConvertString2BN(item?.totalAmount || 0),
                            DECIMALS
                          )
                        ) || 0
                      }
                      showIcon={true}
                      justify={"justify-end"}
                    />
                  </td>
                  <td className={`td text-right`}>
                    <AcceptedToken
                      price={
                        Number(
                          ethers.utils.formatUnits(
                            ConvertString2BN(item?.totalPayout || 0),
                            DECIMALS
                          )
                        ) || 0
                      }
                      showIcon={true}
                      justify={"justify-end"}
                    />
                  </td>
                </tr>
              );
            })
          : null}
      </Table>
    );
  };

  const renderMainContent = () => {
    if (isAddress(search)) {
      return renderSearchResult(filterType);
    }

    if (!isLoadingData && leaderboardData.length === 0) return <Empty />;

    /// desktop
    if (!isMobile) {
      if (isLoadingData) {
        return <LoadingSkeleton />;
      }

      return renderMainList();
    }

    /// mobile
    return (
      <>
        {renderMainList()}
        {isLoadingData && <LoadingSkeleton />}
      </>
    );
  };

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <Fragment>
      {/* <button
					className="btn btn-sm btn-primary px-2 ml-2 tooltip tooltip-left lg:tooltip-top"
					data-tip="Get Premium Code"
				>
					<i className="fa-solid fa-gift-card"></i>
					<span className="sr-only ml-2">Get Premium code</span>
				</button> */}
      <div className="flex items-center w-full flex-1">
        <div className={`relative w-full`}>
          <input
            type="text"
            onChange={handleChangeSearch}
            placeholder={`Search for Player`}
            className="input w-full font-medium h-auto min-h-0 py-3 pr-10 text-sm bg-base-200 focus:bg-base-100 pl-12"
            value={search}
          />
          <span className="absolute left-4 top-1.5">
            <i className="fa-solid fa-magnifying-glass text-sm"></i>
          </span>
          {search && (
            <button
              className="absolute right-2 top-2 btn btn-xs btn-circle btn-ghost"
              onClick={() => {
                setSearch("");
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          )}
        </div>
      </div>
      {/* <FilterAction
					fitlerType={filterType}
					screenName={screenName}
					setFilterType={setFilterType}
					setSortType={setSortType}
					sortType={sortType}
				/> */}
      {/* </div> */}
      <div>
        {renderMainContent()}
        {isMobile && (
          <div
            className="flex flex-col gap-4 pl-4 pr-2"
            ref={observerTarget}
          ></div>
        )}
      </div>

      {count > PAGINATION_SIZE && !search && (
        <div className="hidden md:block">
          <Pagination
            count={count}
            pageSize={PAGE_NUMB}
            currentPage={page}
            pageChanged={onPageChanged}
            position={"end"}
            nextButtonRef={nextButtonRef}
          />
        </div>
      )}
    </Fragment>
  );
};

export default Leaderboard;
