import React, { Fragment } from "react";
import OddElement from "./OddElement";
import { oddProps } from "./oddInterface";
interface oddType {
  name: string;
  header: string;
  odds: string;
  outcome_id: string;
  mId: string;
  update_at: string;
}
const OddType6: React.FC<oddProps> = ({
  idx,
  market,
  item,
  odds,
  parentUrl,
  marketLiquid,
}) => {
  let header1Odd: any;
  let header2Odd: any;
  let drawOdd: any;
  // Create an empty object to store the grouped items
  const grouped: any = {};
  // Iterate over each object in the array
  const groupedUnder = odds?.filter((odd: any) => odd?.header === "Under");
  const groupedOver = odds?.filter((odd: any) => odd?.header === "Over");
  odds.forEach((odd: oddType) => {
    const name = odd?.name;

    // If the name is not already a property in the grouped object, create an empty array
    if (!grouped[name]) {
      grouped[name] = [];
    }

    // Push the current object into the corresponding array in the grouped object
    grouped[name].push(odd);
  });
  return (
    <Fragment key={idx}>
      <div className={`grid grid-cols-2 gap-2 w-full`}>
        <div className="flex flex-col items-center space-y-2">
          {/* <span className="font-medium opacity-70 text-xs text-center">Over</span> */}
          {groupedOver.length > 0 &&
            groupedOver?.map((itemOdd: any, idx: number) => {
              return (
                <OddElement
                  key={idx}
                  idx={idx}
                  odd={itemOdd}
                  item={item}
                  market={market}
                  parentUrl={parentUrl}
                  marketLiquid={marketLiquid}
                />
              );
            })}
        </div>
        <div className="flex flex-col items-center space-y-2">
          {/* <span className="font-medium opacity-70 text-xs text-center">Under</span> */}
          {groupedUnder.length > 0 &&
            groupedUnder?.map((itemOdd: any, idx: number) => {
              return (
                <OddElement
                  key={idx}
                  idx={idx}
                  odd={itemOdd}
                  item={item}
                  market={market}
                  parentUrl={parentUrl}
                  marketLiquid={marketLiquid}
                />
              );
            })}
        </div>
      </div>
    </Fragment>
  );
};

export default OddType6;
