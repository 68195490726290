import { proxy } from "valtio";
import { saveItem, ORDER_DATA, deleteItem } from "local-storage";
import { proxyMap } from "valtio/utils";
export interface betOrder {
  away: string;
  eventName: string;
  eventUrl: string;
  eventId: number;
  home: string;
  marketId: number;
  marketKey: string;
  category_id: string;
  cate_icon_class: string;
  last_updated_at: string;
  league: string;
  leagueIcon: string;
  marketLiquid: number;
  marketName: string;
  markets?: any;
  odds: {
    odds: string;
    roi: number;
    outcome_id: string;
    liquidity: number;
    index?: any;
  };
  placeOrder: {
    amount: number;
    outcomeId: string;
    odds: number;
    potentialWin: number;
  };
  updated_at: string;
  time_status: string;
  time: string;
}
export enum OrderTab {
  OrderSlip = "order-slip",
  RecentBet = "recent-bet",
}
const saveLocalOrder = (value: betOrder[]) => {
  saveItem(ORDER_DATA, JSON.stringify(value));
};
export const orderSlipProxy: {
  items: Map<string, betOrder>;
  total: number;
  sameOrder: boolean;
  itemClosed: boolean;
  itemsData: betOrder[];
  orderTab: OrderTab;
  overPayout: Map<string, boolean>;
  overPayoutStatus: boolean;
  systemType: number;
} = proxy({
  items: proxyMap<string, betOrder>([]),
  total: 0,
  sameOrder: false,
  itemClosed: false, 
  overPayoutStatus: false,
  overPayout: proxyMap<string, boolean>([]),
  systemType: 2,
  get itemsData(){
    return Array.from(orderSlipProxy.items.values());
  },
  orderTab: OrderTab.OrderSlip,
});
export const OrderSlipFunc = {
  orderSlipProxy,
  setItems: (data: betOrder[]) => {
    data.forEach((item) => {
      orderSlipProxy.items.set(item.odds.outcome_id, item);
    });
    orderSlipProxy.total = data.length;
    checkSameOrder(data);
  },
  addItem: (value: betOrder) => {
    orderSlipProxy.items.set(value.odds.outcome_id, value);
    const data = Array.from(orderSlipProxy.items.values());
    saveLocalOrder(data);
    orderSlipProxy.total = data.length;
    checkSameOrder(data);
  },
  deleteItem: (outcome_id: string) => {
    orderSlipProxy.items.delete(outcome_id);
    const dataArr = Array.from(orderSlipProxy.items.values());
    saveLocalOrder(dataArr);
    checkSameOrder(dataArr);
    orderSlipProxy.total = dataArr.length;
  },
  setTotal: (value: number) => {
    orderSlipProxy.total = value;
  },
  clearItems: () => {
    orderSlipProxy.items.clear();
    orderSlipProxy.sameOrder = false;
    orderSlipProxy.overPayout.clear();
    orderSlipProxy.total = 0;
    deleteItem(ORDER_DATA);
    // saveLocalOrder([]);
  },
  setClosed: (value: boolean) => {
    orderSlipProxy.itemClosed = value;
  },
  setOrderTab: (value: OrderTab) => {
    orderSlipProxy.orderTab = value;
  },
  setOverPayout: (key: string, value: boolean) => {
    orderSlipProxy.overPayout.set(key, value);
    const _over = Array.from(orderSlipProxy.overPayout.values());
    orderSlipProxy.overPayoutStatus = _over.includes(true);
  },
  setSystemType: (value: number) => {
    orderSlipProxy.systemType = value;
  }
};
const checkSameOrder = (data: Array<betOrder>) => {
  const eventId: string[] = [];
  orderSlipProxy.sameOrder = false;
  data.forEach((item) => {
    if (eventId.includes(item.eventId.toString())) {
      orderSlipProxy.sameOrder = true;
    }
    eventId.push(item.eventId.toString());
  });
};