import React from "react";
import { ModalLayout } from "./Layout";
import { useSnapshot } from "valtio";
import { modalFunc, modalProxy } from "proxy-state/global";
import Profile from "modules/profile/page";

export const ProfileModal = () => {
  const { isProfileOpen } = useSnapshot(modalProxy);
  const toggleModal = () =>
    modalFunc.setModalOpen("isProfileOpen", !isProfileOpen);
  return (
    <ModalLayout
      modalId="profile-modal"
      isOpen={isProfileOpen}
      toggleModal={toggleModal}
      position="full"
      customBody={true}
    >
      <div id="user_modal" className="modal-box--body !p-0 leading-relaxed">
        <Profile />
      </div>
    </ModalLayout>
  );
};
