import React, { Fragment } from "react";
import LinkScan from "common/LinkScan";
import moment from "moment";
import {
  currencyFormat,
  floatFormat,
  getAddressSimpleString,
} from "common/utils";
import { ethers } from "ethers";
import { shortTime } from "common/utils";
import { DECIMALS } from "functions/betUtils";
import { isMobileOnly } from 'react-device-detect';

const MyOrderList = (props: any) => {
  const { ordersData } = props;
  const EmptyOrder = () => {
    return (
      <div className="mt-6">
        <div className="grid grid-cols-1">
          <div className="mt-6">
            <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
              <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
              <span className="text-lg font-bold">No order found</span>
              <span className="text-xs opacity-70 text-center mt-1">
                Ooops, nothing found! Please, try to place or complete your
                orders!
              </span>
              <a
                href="/"
                className="btn btn-primary shadow h-auto min-h-0 py-3 mt-4"
              >
                Go to Homepage
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!ordersData.length) return <EmptyOrder />;
  return (
    <Fragment>
      <div className="relative overflow-x-auto mt-2">
        <table className="w-full text-sm text-left">
          <thead className="thead">
            <tr className="tr">
              <th scope="col" className="whitespace-nowrap th">
                Transactions
              </th>
              <th scope="col" className="whitespace-nowrap th text-left">
                Amount
              </th>
              <th scope="col" className="whitespace-nowrap th text-left">
                Order Type
              </th>
              <th scope="col" className="whitespace-nowrap th text-right">
                Time
              </th>
            </tr>
          </thead>
          <tbody>
            {ordersData?.map((item: any, index: number) => {
              return (
                <tr className="tr" key={index}>
                  <td className="td whitespace-nowrap">
                    <LinkScan
                      linkView={item?.txHash}
                      title={getAddressSimpleString(item?.txHash, 4)}
                      classes={`uppercase hover:text-primary font-semibold`}
                      icon={true}
                      subClasses={` `}
                    />
                  </td>
                  <td
                    className="td text-left"
                    data-tip={ethers.utils.formatUnits(
                      item?.total_amount?.toString() || 0,
                      DECIMALS
                    )}
                  >
                    {currencyFormat(
                      Number(
                        ethers.utils.formatUnits(
                          item?.total_amount?.toString() || 0,
                          DECIMALS
                        )
                      ),
                      true
                    )}
                  </td>
                  <td className="td text-left">
                    <span className="uppercase text-xs tracking-wider font-medium">
                      {item?.type === "combo" ? "Parlay" : item?.type}
                    </span>
                  </td>
                  <td
                    className="td text-right"
                    data-tip={item?.transactionTime}
                  >
                    {item?.transactionTime
                      ? isMobileOnly 
                      ? shortTime(moment(item?.transactionTime).fromNow())
                      : moment(item?.transactionTime).fromNow()
                      : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
export default MyOrderList;
