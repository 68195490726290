import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import Event from "../page";

const routes: AppRouteType[] = [
  {
    path: slugs.event,
    component: Event,
    container: MainLayout,
    isAuthNeeded: false,
  },
];

export default routes;
