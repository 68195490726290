import React, { useEffect, useState } from "react";
import EventStartIn from "../CountDown/EventStartIn";
import moment from "moment";

interface EventStatusProps {
  eventDateTime: number;
  time_status: number;
}

const EventStatus: React.FC<EventStatusProps> = ({
  eventDateTime,
  time_status,
}) => {
  const [currentTime, setCurrentTime] = useState(moment().unix());

  const start = moment.unix(eventDateTime).format("D MMM HH:mm");
  const startDateFull = moment.unix(eventDateTime).format("D MMM YYYY HH:mm");
  const startEventUnix = moment.unix(eventDateTime).subtract(1, "h").unix();

  let status = 0;
  let showCountdown = true;
  if (currentTime < startEventUnix) {
    showCountdown = false;
  } else if (startEventUnix < currentTime && currentTime <= eventDateTime) {
    showCountdown = true;
  } else if (currentTime > eventDateTime) {
    showCountdown = false;
    status = 1;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().unix();
      setCurrentTime(now);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [eventDateTime]);

  return (
    <div className="flex items-center space-x-1 text-xs">
      {time_status === 0 && status === 0 && (
        <>
          {showCountdown && (
            <EventStartIn targetDate={new Date(startDateFull)} />
          )}
          {!showCountdown && (
            <>
              <span
                className="text-base-content text-opacity-70 font-semibold"
                title={moment.unix(eventDateTime).toLocaleString()}
              >
                {start}
              </span>
            </>
          )}
        </>
      )}
      {(time_status === 1 || status === 1) && (
        <span className="text-accent font-semibold whitespace-nowrap">
          <i className="fa-solid fa-signal-stream fa-fade mr-1"></i> IN PLAY
        </span>
      )}
    </div>
  );
};

export default EventStatus;
