import orderModalProxy from "proxy-state/orderDoneModalProxy";
import React from "react";
import { useSnapshot } from "valtio";
import { currencyFormat, floatFormat } from "common/utils";
import { Link } from "react-router-dom";
import { DataChain } from "contracts/config";
import { OrderSlipFunc } from "proxy-state/betting/OrderSlip";
import { BigNumber, ethers } from "ethers";
import { DECIMALS } from "functions/betUtils";

const OrderDoneModal = () => {
  const { isOpen, data, setOpen } = useSnapshot(orderModalProxy);
  const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 280;
  const CHAIN_NAME = DataChain[chainId]["NEXT_PUBLIC_LINK_EXPLORER_NAME"];
  const explorerURLs = DataChain[chainId]["NEXT_PUBLIC_LINK_EXPLORER_SCAN"];
  const toggleModal = () => {
    setOpen(!isOpen);
    OrderSlipFunc.clearItems();
  };
  const oddShow = data.outcomeIds.length === 1 || data.orderType === 'combo';
  const oddsValue = oddShow ? (parseFloat(ethers.utils.formatUnits(BigNumber.from(data.wagerAmounts[0]).toString(), DECIMALS)) / Number(data.totalAmount)) : 0;
  return isOpen ? (
    <>
      {/* Receipt Dialog */}
      <input
        type="checkbox"
        id="receipt--modal"
        checked={isOpen}
        className="modal-toggle"
        onChange={toggleModal}
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box relative md:max-w-md">
          <label
            htmlFor="receipt--modal"
            className="btn btn-circle btn-ghost btn-sm absolute right-3 top-3"
          >
            <i className="fa-regular fa-xmark text-xl"></i>
          </label>

          <div className="modal-box--body !p-8 text-center">
            <div className=" bg-success/10 text-success w-12 h-12 inline-flex justify-center items-center rounded-full mb-4">
              <i className="fa-duotone fa-receipt text-2xl "></i>
            </div>
            <h3 className="text-xl mb-8">Order Submitted</h3>

            <div className="space-y-3">
              <div className="flex items-baseline justify-between">
                <span className="text-base-content/50 flex-shrink-0 text-sm">
                  Bets
                </span>
                <span className="border-b border-dashed border-base-content border-opacity-10 w-full mx-2"></span>
                <span className="whitespace-nowrap font-medium">
                  <strong className="font-bold">{data.wagerAmounts.length}</strong>
                </span>
              </div>
              {oddShow && (
                <div className="flex items-baseline justify-between">
                  <span className="text-base-content/50 flex-shrink-0 text-sm">
                    Odds
                  </span>
                  <span className="border-b border-dashed border-base-content border-opacity-10 w-full mx-2"></span>
                  <span className="whitespace-nowrap font-medium">
                    <strong className="font-bold">{floatFormat(oddsValue)}</strong>
                  </span>
                </div>
              )}
              <div className="flex items-baseline justify-between">
                <span className="text-base-content/50 flex-shrink-0 text-sm">
                  Type
                </span>
                <span className="border-b border-dashed border-base-content border-opacity-10 w-full mx-2"></span>
                <span className="whitespace-nowrap font-medium">
                  <strong className="font-bold">{data.orderType}</strong>
                </span>
              </div>
              <div className="flex items-baseline justify-between">
                <span className="text-base-content/50 flex-shrink-0 text-sm">
                  Gas Fee
                </span>
                <span className="border-b border-dashed border-base-content border-opacity-10 w-full mx-2"></span>
                <span className="whitespace-nowrap font-medium">
                  <strong className="font-bold">FREE</strong>
                </span>
              </div>
            </div>

            <div className="flex items-baseline justify-between border-t border-base-content/20 pt-6 mt-6">
              <span className="text-base-content/50 flex-shrink-0">Total</span>
              <span className="border-b border-dashed border-base-content border-opacity-10 w-full mx-2"></span>
              <span className="whitespace-nowrap font-medium">
                <strong className="font-bold text-xl">
                  {currencyFormat(data.totalAmount)}
                </strong>
                <span className="opacity-70 ml-1">zkUSD</span>
              </span>
            </div>

            {/* <div className="flex items-baseline justify-between text-left bg-accent/20 px-3 py-2 rounded-box mt-6">
						<div>
							<span className="text-base-content/50 flex-shrink-0">xLOOT (ERC-20):</span>
							<span className="font-mono ml-2">0x95...8005</span>
						</div>
						<button className="btn btn-sm btn-ghost btn-circle tooltip" data-tip="Copy Address">
							<i className="fa-solid fa-copy"></i>
						</button>
					</div> */}
          </div>

          <div className="px-8 pb-6 flex flex-col md:flex-row justify-evenly items-center gap-2">
            <Link
              to={`${explorerURLs}/${data.txHash}`}
              className="btn btn-md btn-neutral w-full md:w-1/2"
              target="_blank"
              rel="noreferrer"
            >
              {CHAIN_NAME}
            </Link>
            <label
              htmlFor="receipt--modal"
              className="btn btn-md btn-primary w-full md:w-1/2"
            >
              Close
            </label>
          </div>
        </div>
      </div>
      {/* //Receipt Dialog */}
    </>
  ) : null;
};
export default OrderDoneModal;
