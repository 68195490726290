import { MainLayoutPublib } from "components/layouts/MainLayoutPublib";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import Metamask from "../page/Metamask";

const routes: AppRouteType[] = [
  {
    path: slugs.metamask,
    component: Metamask,
    container: MainLayoutPublib,
    isAuthNeeded: false,
  },
];

export default routes;
