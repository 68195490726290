import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import {
  currencyFormat,
  floatFormat,
  formatBigNumber,
  parseBigNumber,
} from "common/utils";
import PlaceOrderButton from "./placeOrderButton";
import { getItem } from "local-storage";
import {
  BN0,
  bnSort,
  DECIMALS,
  minPayoutWithSlippage,
} from "functions/betUtils";
import { BigNumber, ethers, utils } from "ethers";
import Helpers from "common/utils";

// import { saveOrder } from "../../../../functions/placeOrderSave";
import { Disclosure } from "@headlessui/react";
import { OutcomeLiquidity } from "interfaces/firebase";
import { updateState2 } from "./orderFunction";
import { getContract } from "contracts/Contract";
import { ContractAddress } from "contracts/config";
import { _getOdds } from "functions/amm-v2";
import { setSystemTypes, string2Number } from "common/utils";
import { ReactComponent as ZKUSDSvg } from "assets/currency-icons/zkUSD.svg";
import { useEthersSigner } from "hooks/useSigner";
import { useAccount } from "hooks/useAccount";
import { useSnapshot } from "valtio";
import { myBalanceProxy } from "proxy-state/myBalanceProxy";
import { outcomeProxy } from "proxy-state/outcomeProxy";
import { eventProxy } from "proxy-state/eventProxy";
import {
  OrderSlipFunc,
  betOrder,
  orderSlipProxy,
  OrderTab,
} from "../../../proxy-state/betting/OrderSlip";
import { targetProxy, TargetFunc } from "proxy-state/betting/target";
import { liquidProxy } from "proxy-state/betting/Liquidity";
import { configProxy } from "proxy-state/configProxy";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";
interface OrderData {
  outComeIds: BigNumber[];
  singleAmount: BigNumber[];
  _odds: BigNumber[];
  _liquids: BigNumber[];
  _mliquids: BigNumber[];
}

const OrderSlipFooter = ({
  modeTab,
  _betSlips,
}: {
  modeTab: string;
  _betSlips: betOrder[];
}) => {
  const { data: outcomesLiquid } = useSnapshot(outcomeProxy);
  const { data: events } = useSnapshot(eventProxy);
  const myBalance = useSnapshot(myBalanceProxy);
  const {
    target,
    default: targetDefault,
    singleTarget,
  } = useSnapshot(targetProxy);
  const inputRef = React.useRef<HTMLInputElement>(null);
  let maxAmountPay: number = 0;
  let totalWagerAmount: BigNumber = BigNumber.from(0);
  let potentialWin: number = 0;
  let priceImpact: number = 0;
  let totalOdds = 0;
  let quickAmount = 0;
  let orderType = 1;
  const [isLoadingOutcomeLiquid, setIsLoadingOutcomeLiquid] =
    useState<boolean>(false);
  const { address } = useAccount();
  const signer = useEthersSigner();
  const [inputTarget, setInputTarget] = useState<string>(
    targetDefault.toString()
  );
  const { total, items, systemType } = useSnapshot(orderSlipProxy);
  const { symbol } = useSnapshot(configProxy);
  const { sensitivity } = useSnapshot(liquidProxy);
  const systemTypes = setSystemTypes(total);
  // const { chain } = useNetwork();
  const orderSetingDefault = {
    defaultCurrency: "zkUSD",
    quickOrder1: 50,
    quickOrder2: 100,
    quickOrder3: 250,
    quickOrder4: 500,
    slippage: 1,
  };
  const orderSettings = JSON.parse(
    getItem("orderSeting") || JSON.stringify(orderSetingDefault)
  );
  const getMinimunWin = (potentialWin: number, slippage: number) => {
    if (slippage === 0) return potentialWin;
    if (slippage >= 100) slippage = 99;
    return potentialWin > 0
      ? potentialWin - (potentialWin * slippage) / 100
      : 0;
  };

  const singleAmountArr: Array<number> = [];
  Array.from(singleTarget.values()).forEach((item) => {
    singleAmountArr.push(item);
  });

  const singleAmountSum: BigNumber = singleAmountArr.reduce(
    (a: BigNumber, b: number) =>
      a.add(
        ethers.utils.parseUnits(b.toFixed(DECIMALS).toString() || "0", DECIMALS)
      ),
    BN0
  );
  const avgSingleAmount: number = singleAmountSum.gt(BN0)
    ? Number(formatBigNumber(singleAmountSum, true)) / singleAmountArr.length
    : 0;
  quickAmount = modeTab === "single-mode" ? avgSingleAmount : Number(target);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(false);
    const timer = setTimeout(() => {
      setLoaded(true);
      // console.log("Loaded");
    }, 1000);
    return () => clearTimeout(timer);
  }, [modeTab]);
  const betSlips: betOrder[] = _betSlips.map((item) => {
    const { eventId } = item ?? {};
    const event = events.get(eventId.toString());
    const data: betOrder = {
      ...item,
      markets: event?.markets,
    };
    return data;
  });
  const initialOrderData: OrderData = {
    outComeIds: [],
    singleAmount: [],
    _odds: [],
    _liquids: [],
    _mliquids: [],
  };

  const { outComeIds, singleAmount, _odds, _liquids, _mliquids } =
    betSlips.reduce((acc: OrderData, item: betOrder) => {
      const { placeOrder, odds: itemOdds, marketLiquid } = item ?? {};

      if (!placeOrder) return acc;
      const amount = parseBigNumber(Number(placeOrder.amount) || 0, true);
      const outcomeId = BigNumber.from(placeOrder.outcomeId);
      const oddsValue = BigNumber.from(
        utils.parseUnits(floatFormat(Number(itemOdds?.odds || 0), 6), DECIMALS)
      );
      const liquidValue = itemOdds?.liquidity
        ? utils.parseUnits(
            itemOdds?.liquidity.toFixed(DECIMALS)?.toString(),
            DECIMALS
          )
        : BigNumber.from(0);
      const marketLiquidValue = marketLiquid
        ? utils.parseUnits(marketLiquid.toFixed(DECIMALS).toString(), DECIMALS)
        : BigNumber.from(0);

      return {
        outComeIds: [...acc.outComeIds, outcomeId],
        singleAmount: [...acc.singleAmount, amount],
        _odds: [...acc._odds, oddsValue],
        _liquids: [...acc._liquids, liquidValue],
        _mliquids: [...acc._mliquids, marketLiquidValue],
      };
    }, initialOrderData);
  const _outComeIds = outComeIds.map((item: BigNumber) => item.toString());
  const _outcomeSort = bnSort(outComeIds);
  const outcomeIds: string[] = _outcomeSort.map((item) =>
    item.toString()
  );
  const liquids: BigNumber[] = [];
  const mliquids: BigNumber[] = [];
  const odds: BigNumber[] = [];
  const amount: BigNumber[] = outcomeIds.map((id: string) =>
    parseBigNumber(singleTarget.get(id) || 0, true)
  );

  outcomeIds.forEach((ocs: any) => {
    const index = _outComeIds.indexOf(ocs);
    odds.push(_odds[index]);
    liquids.push(_liquids[index]);
    mliquids.push(_mliquids[index]);
  });
  const checkSystemType = (outComeLength: number) => {
    let typeDefault = systemType;
    if (systemType && systemType > outComeLength + 2) {
      typeDefault = outComeLength + 2;
      OrderSlipFunc.setSystemType(typeDefault);
    }
    return typeDefault;
  };

  // @ts-ignore
  const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 324;
  const isValidEventsData =
    betSlips.filter((item) => item.markets).length === betSlips.length;
  if (total > 0) {
    if (isValidEventsData) {
      const check_type = checkSystemType(outcomeIds.length);
      const amounts =
        modeTab === "single-mode"
          ? amount
          : [
              ethers.utils.parseUnits(
                target.toFixed(DECIMALS).toString(),
                DECIMALS
              ),
            ];
      const eventData: any[] = betSlips.map((item) => ({
        ...item,
        id: item.eventId,
      }));
      const {
        totalWagerAmount: _totalWagerAmount,
        potenialWin: _potenialWin,
        maxAmountPay: _maxAmountPay,
        priceImpact: _priceImpact,
        totalOdds: _totalOdds,
        _type,
      } = updateState2(
        check_type,
        modeTab,
        outcomeIds.map((item) => BigNumber.from(item)),
        eventData,
        outcomesLiquid,
        amounts,
        sensitivity
      );

      potentialWin = _potenialWin;
      totalWagerAmount = _totalWagerAmount;
      orderType = _type;
      totalOdds = _totalOdds;
      maxAmountPay = _maxAmountPay;
      priceImpact = _priceImpact;
    }
  }
  const UpdateBetShips = async (
    outcomeLiquids: Map<string, OutcomeLiquidity>
  ) => {
    const betSlips: betOrder[] = Array.from(items.values());
    const eventIds: string[] = betSlips.map((item) => item.eventId.toString());

    //get Odds of outcome
    for (let betSlip of betSlips) {
      let odds: any[] = [];
      // let _odd = "";
      const event = events.get(betSlip.eventId.toString());
      if (!event) {
        eventProxy.loadByIds(eventIds);
      } else {
        // eslint-disable-next-line no-loop-func
        Object.keys(event?.markets).forEach((maGroup: string) => {
          let f = false;
          event?.markets?.[maGroup].forEach((ma: any) => {
            let check = ma.odds.findIndex((odd: any) => {
              return odd.outcome_id === betSlip.odds.outcome_id;
            });
            if (check >= 0) {
              f = true;
              odds = ma.odds;
              // betSlip.odds?.index = check;
              // _odd = ma.odds[check].odds;
            }
          });
          if (f) {
            return;
          }
        });
        // betSlip.time_status = Number(event?.time_status || 0).toString();
      }

      // console.log("odds123", odds);

      // const marketOdds = getMarketOdds([betSlip.odds].map((odd: any) => BigNumber.from(odd.outcome_id)), events);

      // console.log("betSlip", betSlip);
      const marketOutcomeLiquids = odds.map(
        (item) => outcomeLiquids.get(item.outcome_id)?.liquidity || 0
      );
      const marketOutcomeOdds = odds.map((item) => Number(item.odds));

      let marketLiquid = marketOutcomeLiquids.reduce(
        (result, value) => result + value,
        0
      );
      const liquidity = Number(
        outcomeLiquids.get(betSlip.odds.outcome_id)?.liquidity || 0
      );
      const odd_roi = _getOdds(
        betSlip.odds.index,
        marketOutcomeOdds,
        marketOutcomeLiquids,
        sensitivity
      );

      if (betSlip.odds.roi !== odd_roi) {
        const new_betSlip = { 
          ...betSlip,
          odds: {
            ...betSlip.odds,
            roi: Number(odd_roi),
            liquidity: liquidity,
          },
          marketLiquid: marketLiquid,
         };
        OrderSlipFunc.addItem(new_betSlip);
      }
    }
  };

  const getOutcomesLiquidityOnchain = async () => {
    const betSlips: betOrder[] = Array.from(items.values());
    if (betSlips.length <= 0 || !signer?.provider || isLoadingOutcomeLiquid)
      return;
    try {
      setIsLoadingOutcomeLiquid(true);
      //get Odds of outcome
      let outcomeIds: string[] = [];
      for (let betSlip of betSlips) {
        const event = events.get(betSlip.eventId.toString());
        if (!event) {
          eventProxy.loadByIds([betSlip.eventId.toString()]);
        } else {
          // eslint-disable-next-line no-loop-func
          Object.keys(event?.markets).forEach((maGroup) => {
            event?.markets[maGroup].forEach((ma: any) => {
              let check = ma.odds.find((odd: any) => {
                return odd.outcome_id === betSlip.odds.outcome_id;
              });
              if (check) {
                outcomeIds = [
                  ...outcomeIds,
                  ...ma.odds.map((odd: any) => odd.outcome_id),
                ];
              }
            });
          });
        }
      }
      const contractGroup = ContractAddress[Number(chainId)];
      const signerProvider = new ethers.providers.Web3Provider(
        (signer?.provider as any)?.provider
      ).getSigner();
      const bookmakerAddress = `0x${contractGroup["BookMakerV2"].replace(
        /^0x/,
        ""
      )}`;
      const bookmakerContract = getContract(
        "BookMakerV2",
        bookmakerAddress,
        signerProvider
      );
      const liquids = (
        await bookmakerContract.callStatic.getLiquids(outcomeIds)
      )._outcomeLiquids;
      
      outcomeIds.forEach((item, index) => {
        //TODO: LUCAS TEST CODE
        const outcomeLiquids = outcomesLiquid.get(item);
        if (outcomeLiquids) {
          const newoutcomeLiquid: Partial<OutcomeLiquidity> = {
            ...outcomeLiquids,
            liquidity: Number(ethers.utils.formatUnits(liquids[index], DECIMALS)),
          };
          outcomeProxy.updateOutcomeLiquidity(item, newoutcomeLiquid);
        } else {
          outcomeProxy.updateOutcomeLiquidity(item, {
            id: item,
            status: false,
            liquidity: Number(ethers.utils.formatUnits(liquids[index], DECIMALS)),
          } as Partial<OutcomeLiquidity>);
        }
      });

      UpdateBetShips(outcomesLiquid).then((result) => {});
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingOutcomeLiquid(false);
    }
  };

  useEffect(() => {
    if (outcomesLiquid) {
      getOutcomesLiquidityOnchain().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomesLiquid]);
  const retrunUrl = Helpers.base64URLEncode(window.location.pathname);
  const renderButton = () => {
    if (!address) {
      return (
        <Link
          to={slugs.login + `?return=${retrunUrl}`}
          type="button"
          className={`btn btn-md btn-primary btn-block shadow`}
        >
          <i className="fa-duotone fa-user text-sm mr-2"></i>
          Sign In
        </Link>
      );
    }
    if (!loaded)
      return (
        <button
          type="button"
          className={`btn btn-md btn-primary btn-block shadow btn-disabled opacity-50 cursor-not-allowed`}
        >
          Order
        </button>
      );
    return (
      <PlaceOrderButton
        orderType={orderType}
        singleAmount={amount}
        networkFee={0}
        modeTab={modeTab}
        maxAmountPay={Number(maxAmountPay)}
        totalOdds={0}
        target={Number(target)}
        getOutcomesLiquidityOnchain={getOutcomesLiquidityOnchain}
        minPayOut={minPayoutWithSlippage(
          totalWagerAmount,
          Number(orderSettings?.slippage) || 1
        )}
        outcomeIds={outcomeIds}
        setOrderTab={() => {
          OrderSlipFunc.setOrderTab(OrderTab.RecentBet);
        }}
      />
    );
  };

  const placeBtnClass = "bg-neutral btn btn-sm tooltip tooltip-top";
  const quickBets = modeTab === "single-mode" ? [1, 2, 3, 4] : [2, 3, 4];
  return (
    <Fragment>
      <div className="panel-footer">
        {modeTab === "system-mode" && (
          <Fragment>
            <div className="flex flex-col w-full text-sm border-b border-base-content/10 pb-4 mb-4">
              <div className="mb-3 flex justify-between">
                <span className="font-semibold">System</span>
                <span className="ml-2">
                  <a
                    href="https://blog.goal3.xyz/everything-you-need-to-know-about-system-betting-61eba4a9f89c"
                    className="text-xs border-b border-dashed border-base-content/60 opacity-70 hover:text-primary hover:border-primary hover:opacity-100"
                    target="_blank"
                    rel="noreferrer"
                  >
                    What's this?
                  </a>
                  <span
                    className="ml-2 tooltip tooltip-left cursor-pointer opacity-70 hover:text-primary hover:opacity-100"
                    data-tip="System bets are based on combinations of selections that you make"
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </span>
                </span>
              </div>
              {systemTypes && (
                <>
                  <div className="w-full">
                    <select
                      id="mySelect"
                      className="select h-auto min-h-0 py-1 select-bordered w-full"
                      defaultValue={2}
                      onChange={(event) => {
                        const selectedOption = (
                          event.target as HTMLSelectElement
                        ).value;
                        OrderSlipFunc.setSystemType(Number(selectedOption));
                      }}
                    >
                      {systemTypes.map((item: any, index: number) => {
                        if (item) {
                          return (
                            <>
                              <option
                                value={index}
                                selected={systemType === index ? true : false}
                              >
                                {item?.name}
                              </option>
                            </>
                          );
                        }
                        return null;
                      })}
                    </select>
                  </div>
                </>
              )}
            </div>
            {/* <ReactTooltip id="system-mode" place="right" type="dark" effect="float" /> */}
          </Fragment>
        )}
        <div className={`flex justify-between items-center text-xs`}>
          {/* Currency Dropdown */}
          <div className="dropdown">
            <label tabIndex={0} className="dropdown-toggle">
              <button className="dropdown-toggle-title">
                <span className="flex items-center">
                  <ZKUSDSvg width={16} height={16} />
                  <span className="ml-2 truncate">{symbol}</span>
                </span>
              </button>
            </label>
          </div>
          {/* //Currency Dropdown */}

          <div>
            <span>Available:</span>
            <span className="ml-1 font-medium">
              {currencyFormat(myBalance.balanceOf, true)}
            </span>
          </div>
        </div>
        <div
          className={`${
            modeTab === "single-mode"
              ? "grid-cols-4"
              : "grid-cols-2 lg:grid-cols-7"
          } grid  gap-1 mt-2`}
        >
          {modeTab !== "single-mode" && (
            <>
              <div className="relative lg:col-span-3">
                <input
                  value={target || inputTarget || ""}
                  ref={inputRef}
                  type="number"
                  step="any"
                  min={0}
                  maxLength={6}
                  placeholder="Order Amount"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const _value = e.target.value
                      .replace(/[^0-9.-]/g, "")
                      .replace(/(\..*)\./g, "$1")
                      .replace(/(\-.*)\-/g, "$1");
                    setInputTarget(_value);
                    TargetFunc.setTarget(string2Number(_value) || 0);
                    if (betSlips.length > 0) {
                      const _singleTarget: Map<string, number> = new Map();
                      for (let outcome of betSlips) {
                        _singleTarget.set(
                          outcome?.placeOrder?.outcomeId,
                          string2Number(_value) || 0
                        );
                      }
                      TargetFunc.setSingleTargets(_singleTarget);
                    }
                  }}
                  title="Order Amount"
                  className="input input-sm input-bordered w-full font-semibold pl-3 pr-14 text-sm"
                />
                <span className="absolute right-2 top-1/2 -mt-2.5 text-sm font-medium">
                  {symbol}
                </span>
              </div>
              <div className="grid grid-cols-3 gap-1 lg:col-span-4">
                {quickBets.map((item: number) => (
                  <Fragment key={item}>
                    <button
                      className={`${placeBtnClass}${
                        quickAmount ===
                        Number(orderSettings?.[`quickOrder${item}`])
                          ? " border-primary text-primary bg-transparent hover:bg-transparent hover:border-primary"
                          : ""
                      }`}
                      data-tip={`${
                        orderSettings?.[`quickOrder${item}`]
                      } ${symbol}`}
                      onClick={() => {
                        const quickValue = Number(
                          orderSettings?.[`quickOrder${item}`]
                        );
                        TargetFunc.setTarget(quickValue);
                        if (betSlips.length > 0 && modeTab === "single-mode") {
                          const _singleTarget: Map<string, number> = new Map();
                          for (let outcome of betSlips) {
                            _singleTarget.set(
                              outcome?.placeOrder?.outcomeId,
                              quickValue || 0
                            );
                          }
                          TargetFunc.setSingleTargets(_singleTarget);
                        }
                      }}
                    >
                      {orderSettings?.[`quickOrder${item}`]}
                    </button>
                  </Fragment>
                ))}
              </div>
            </>
          )}
          {modeTab === "single-mode" && (
            <>
              {quickBets.map((item: number) => (
                <Fragment key={item}>
                  <button
                    className={`${placeBtnClass}${
                      quickAmount ===
                      Number(orderSettings?.[`quickOrder${item}`])
                        ? " border-primary text-primary bg-transparent hover:bg-transparent hover:border-primary"
                        : ""
                    }`}
                    data-tip={`${
                      orderSettings?.[`quickOrder${item}`]
                    } ${symbol}`}
                    onClick={() => {
                      const quickValue = Number(
                        orderSettings?.[`quickOrder${item}`]
                      );
                      TargetFunc.setTarget(quickValue);
                      if (betSlips.length > 0 && modeTab === "single-mode") {
                        const _singleTarget: Map<string, number> = new Map();
                        for (let outcome of betSlips) {
                          _singleTarget.set(
                            outcome?.placeOrder?.outcomeId,
                            quickValue || 0
                          );
                        }
                        TargetFunc.setSingleTargets(_singleTarget);
                      }
                    }}
                  >
                    {orderSettings?.[`quickOrder${item}`]}
                  </button>
                </Fragment>
              ))}
            </>
          )}
        </div>
        <Disclosure as="div" className="" defaultOpen={false}>
          {({ open }) => (
            <Fragment>
              <Disclosure.Button
                as="div"
                className="w-full pt-3 grid grid-cols-1 gap-1 cursor-pointer"
              >
                <div className={`flex justify-between items-center`}>
                  <div className="flex items-center text-xs">
                    <span className="opacity-50 mr-2">Total</span>
                  </div>

                  <div className="pl-2 flex items-center text-xs font-medium">
                    {floatFormat(maxAmountPay, 2)}
                    <span className="ml-1 font-medium">{symbol}</span>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <div className="flex items-center text-xs">
                    <span className="opacity-50 mr-2">
                      Potential Win
                      {!open ? (
                        <i className="ml-2 fa-solid fa-ellipsis [text-10px] transition-all"></i>
                      ) : (
                        <i className="ml-2 fa-solid fa-ellipsis text-primary [text-10px] transition-all"></i>
                      )}
                    </span>
                  </div>

                  <div className="pl-2 flex items-center text-sm leading-none text-primary font-semibold">
                    {floatFormat(potentialWin, 2)}
                    <span className="ml-1">{symbol}</span>
                  </div>
                </div>
              </Disclosure.Button>

              <Disclosure.Panel className="grid grid-cols-1 gap-1 mt-1">
                {modeTab === "combo-mode" && (
                  <>
                    <div className="flex justify-between items-center">
                      <span className="opacity-50 text-xs">Total Odds</span>
                      <div className="pl-2 flex items-center text-xs font-medium">
                        {floatFormat(totalOdds)}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="opacity-50 text-xs">Price Impact</span>
                      <div className="pl-2 flex items-center text-xs font-medium">
                        {floatFormat(priceImpact)}%
                      </div>
                    </div>
                  </>
                )}
                <div className={`flex justify-between items-center`}>
                  <span className="opacity-50 text-xs">Slippage</span>
                  <div className="pl-2 flex items-center text-xs font-medium">
                    {orderSettings?.slippage || 1}%
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="opacity-50 text-xs">Minimum Win</span>
                  <div className="pl-2 flex items-center text-xs font-medium">
                    {floatFormat(
                      getMinimunWin(potentialWin, orderSettings?.slippage || 1),
                      2
                    )}
                    <span className="ml-1 font-medium">{symbol}</span>
                  </div>
                </div>
              </Disclosure.Panel>
            </Fragment>
          )}
        </Disclosure>
        <div className="pt-2">
          {renderButton()}
          {/* <PlaceOrderButton
            orderType={orderType}
            singleAmount={amount}
            networkFee={0}
            modeTab={modeTab}
            maxAmountPay={Number(maxAmountPay)}
            totalOdds={0}
            target={Number(target)}
            getOutcomesLiquidityOnchain={getOutcomesLiquidityOnchain}
            minPayOut={minPayoutWithSlippage(
              totalWagerAmount,
              Number(orderSettings?.slippage) || 1
            )}
            outcomeIds={outcomeIds}
            setOrderTab={() => {
              OrderSlipFunc.setOrderTab(OrderTab.RecentBet);
            }}
          /> */}
        </div>
      </div>
    </Fragment>
  );
};
export default OrderSlipFooter;
