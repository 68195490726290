import utils, { currencyFormat } from "common/utils";
import React from "react";
import { myProfileProxy } from "proxy-state/myProfileProxy";
import { useSnapshot } from "valtio";
import { myBalanceProxy } from "proxy-state/myBalanceProxy";
import { ReactComponent as ZKUSDSvg } from "assets/currency-icons/zkUSD.svg";
import { modalFunc } from "proxy-state/global";
import CustomAvatar from "utils/wagmi/avatar";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";

const WalletBtn: React.FC<{ isAuth?: boolean }> = ({isAuth}) => {
  const myProfile = useSnapshot(myProfileProxy);
  const myBalance = useSnapshot(myBalanceProxy);

  const userInfo = myProfile.profile;
  const returnUrl = utils.base64URLEncode(window.location.pathname);
  return isAuth ? (
    <>
      <button
        className="relative inline-block flex-shrink-0"
        onClick={() => modalFunc.setModalOpen("isProfileOpen", true)}
      >
        <div className="flex items-center relative rounded-box">
          <div className="btn btn-md !px-2 btn-outline border-base-content/20 bg-base-200 hover:bg-base-100 hover:border-primary hover:text-base-content z-[1] relative gap-2">
            <ZKUSDSvg className="w-4 h-4 stroke" />
            <span>{currencyFormat(myBalance.balanceOf, true)}</span>
            {userInfo?.picture && (
              <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100/10 flex items-center justify-center">
                <img
                  height="60"
                  width="60"
                  src={`${userInfo?.picture}`}
                  alt="Avatar"
                />
              </div>
            )}
            {!userInfo?.picture && (
              <CustomAvatar size={18} address={userInfo?.id} ensImage={''} />
            )}
          </div>
        </div>
      </button>
    </>
  ) : (
    <Link 
      to={slugs.login+`?return=${returnUrl}`}
        className="btn btn-md btn-primary shadow border-base-content/20 hover:bg-base-100 hover:border-primary hover:text-base-content relative"
    >
      {/* <ZKUSDSvg className="w-4 h-4 stroke" /> */}
      <i className="fa-duotone fa-user text-sm mr-2"></i>
      {/* <span></span> */}
      Sign In
    </Link>
  );
};

export default WalletBtn;
