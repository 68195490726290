import { customAxios as axios } from "utils/server";
import { CreateOrGetOTPFundingUrl } from "./urls";

export const getFundingHeaders = (clientId: any) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Basic ${clientId}`,
  };
  return headers;
};

// API smart deposit
export const CreateOrGetOTPFunding = async (
  data: string,
  type: "post" | "get" = "get",
  clientId: any
) => {
  try {
    if (type === "get") {
      const res = await axios.get<{ data: any }>(
        `${CreateOrGetOTPFundingUrl}?otp=${data}`,
        {
          headers: getFundingHeaders(clientId),
        }
      );
      return res.data;
    } else {
      const res = await axios.post<{ data: any }>(
        CreateOrGetOTPFundingUrl,
        {
          walletAddress: data,
        },
        {
          headers: getFundingHeaders(clientId),
        }
      );

      return res.data;
    }
  } catch (error: any) {
    console.log("error ====>", error);
    throw Error(error?.response?.data?.message);
  }
};

// ========================== GOOGLE API ===========================
export const getGoogleUserInfo = async (token: string) => {
  try {
    const userInfo = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return userInfo;
  } catch (error) {
    throw error;
  }
};
