import { SPACE_COLL } from "./collectionMap";
import {
  DocumentData,
  Query,
  collection,
  query,
  where,
} from "firebase/firestore";
import { eventRef, userCollRef, userWagerCollRef } from "./firebaseDBRef";

const appId = process.env.REACT_APP_APP_NAME || "sportbook_testnet2";
const chainId = process.env.REACT_APP_FIREBASE_SUFFIX || "280";
const spaceId = `${chainId}_${appId}`;
const SPACE_CHANGE = `${SPACE_COLL}/${spaceId}`;

export const outcomesRef = (firebaseDb: any) => {
  return collection(firebaseDb, `${SPACE_CHANGE}/outcomes`);
};

export const queryEventByIds = (ids: string[]) => {
  return query(eventRef(), where("id", "in", ids));
};

export const queryUserWagerByIds = (account: string, ids: string[]) => {
  return query(userWagerCollRef(account), where("wager_id", "in", ids));
};

export const queryValidUserWagersByAddress = (account: string) => {
  return query(userWagerCollRef(account), where("status", "in", [5, 4, 0]));
};

export const queryUserBySocialId = (socialId: string) => {
  const userCollection = userCollRef();
  return query(userCollection, where("google", "==", socialId));
};

export const queryUserDocsRefByShortCode = (
  shortCode: string
): Query<DocumentData> => {
  const userCollection = userCollRef();
  return query(userCollection, where("short_code", "==", shortCode));
};

export const queryUserDocsRefByPremiumCode = (
  premiumCode: string
): Query<DocumentData> => {
  const userCollection = userCollRef();
  return query(userCollection, where("premium_code", "==", premiumCode));
};
