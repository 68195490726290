import { floatFormat } from "common/utils";

import { getDocs, setDoc } from "firebase/firestore";
import { modalFunc, modalProxy } from "proxy-state/global";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { Link } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { myProfileProxy } from "../../../proxy-state/myProfileProxy";
import { userDocRef } from "utils/firebase/firebaseDBRef";
import {
  queryUserDocsRefByPremiumCode,
  queryUserDocsRefByShortCode,
} from "utils/firebase";

const MIN_LOCKED_FOR_VIPCODE = 10000000000;
const MIN_PLAYERD_FOR_VIPCODE = 100000000;

const PremiumCodeModal: React.FC = () => {
  const { isPremiumOpen } = useSnapshot(modalProxy);
  const [isMatchCondition, setIsMatchCondition] = useState<boolean>(false);
  const [isEdittingCode, setIsEdittingCode] = useState<boolean>(false);
  const [tab, setTab] = useState<string>("regular");
  const [referralCode, setReferralCode] = useState<boolean>(false);
  const [isUpdatingCode, setIsUpdatingCode] = useState<boolean>(false);
  const inputEditRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState<string>();
  const [originalCode, setOriginalCode] = useState<any>("");
  const [errorText, setErrorText] = useState<string | null>(null);
  const { profile } = useSnapshot(myProfileProxy);
  const user = profile;
  useEffect(() => {
    const body = document.querySelector("body");
    if (isPremiumOpen) {
      body?.classList.add("has-Modal");
    } else {
      body?.classList.remove("has-Modal");
    }
  }, [isPremiumOpen]);
  useEffect(() => {
    if (Number(user?.totalAmount) >= MIN_PLAYERD_FOR_VIPCODE) {
      setReferralCode(true);
      setCode(user?.premium_code || user?.short_code);
      setOriginalCode(user?.premium_code || user?.short_code);
      setTab("premium");
    } else {
      setReferralCode(false);
    }
    if (
      Number(user?.totalAmount) >= MIN_PLAYERD_FOR_VIPCODE &&
      Number(user?.totalIV) >= MIN_LOCKED_FOR_VIPCODE
    ) {
      setIsMatchCondition(true);
      if (!user?.premium_code) {
        updatePremiumCode().then();
      }
      setCode(user?.premium_code || user?.short_code);
      setOriginalCode(user?.premium_code || user?.short_code);
    } else {
      setIsMatchCondition(false);
    }
  }, [user]);

  //   useEffect(() => {
  //     modalFunc.setModalOpen("isPremiumOpen", !isPremiumOpen);
  //   }, [isPremiumOpen]);
  const { address } = useAccount();

  const updatePremiumCode: () => Promise<void> = async () => {
    try {
      if (!address) return;
      const code = user?.short_code;
      const userRef = userDocRef(address);
      await setDoc(userRef, { premium_code: code }, { merge: true });
    } catch (error) {}
  };

  //check if short code valid
  const checkShortcodeValid = (shortCode: string | undefined) => {
    if (!shortCode) {
      return false;
    }
    return shortCode?.length >= 4 && shortCode?.length <= 10;
  };

  const onUpdateShortCode = async () => {
    try {
      if (!checkShortcodeValid(code)) {
        throw new Error("Code must be 4-10 characters long");
      }
      setIsUpdatingCode(true);

      const listUserByShortCodeQuery = queryUserDocsRefByShortCode(
        code ? code.toLowerCase() : ""
      );
      const listUserByPremiumCodeQuery = queryUserDocsRefByPremiumCode(
        code ? code.toLowerCase() : ""
      );
      const [querySnapshot, premiumSnapshot] = await Promise.all([
        getDocs(listUserByShortCodeQuery),
        getDocs(listUserByPremiumCodeQuery),
      ]);

      if (
        (querySnapshot.empty && premiumSnapshot.empty) ||
        (querySnapshot.docs[0] && querySnapshot.docs[0].id === user?.id) ||
        ""
      ) {
        const userRef = userDocRef(user?.id || "");
        await setDoc(
          userRef,
          { premium_code: code?.toLowerCase() },
          { merge: true }
        );
        setIsUpdatingCode(false);
        setIsEdittingCode(false);
        setErrorText(null);
      } else {
        throw new Error("Code already exists");
      }
    } catch (error: any) {
      setIsUpdatingCode(false);
      setErrorText(error.message);
    }
  };

  const getPercentage = (total: number, value: number): number => {
    if (value > total) return 100;
    return Math.ceil((value / total) * 100);
  };
  const getMessage = () => {
    if (referralCode && !isMatchCondition) {
      return (
        <>
          <span className="grad-text">Congratulations!</span>
          <span className="block">Regular Referral Code is now available</span>
        </>
      );
    } else if (isMatchCondition) {
      return (
        <>
          <span className="grad-text">Congratulations!</span>
          <span className="block">Your Referral code is now Premium</span>
        </>
      );
    } else {
      return `Bet $${floatFormat(
        (MIN_PLAYERD_FOR_VIPCODE - Number(user?.totalAmount || 0)) / 1e6
      )} ${
        Number(user?.totalAmount || 0) > 0 ? "more " : ""
      }to activate your Referral Code`;
    }
  };
  const totalIV = referralCode ? Number(user?.totalIV || 0) : 0;
  const totalIVpercent = referralCode
    ? getPercentage(MIN_LOCKED_FOR_VIPCODE, Number(user?.totalIV || 0))
    : 0;
  const totalAmountPercent = getPercentage(
    MIN_PLAYERD_FOR_VIPCODE,
    Number(user?.totalAmount || 0)
  );

  return (
    <Fragment>
      <input
        type="checkbox"
        id="gen-vipcode--modal"
        checked={isPremiumOpen}
        onChange={() => {}}
        className="modal-toggle"
      />

      <div
        className={`modal modal-bottom sm:modal-middle ${
          isPremiumOpen ? " modal-open z-[999]" : "-z-[10] hidden"
        }`}
      >
        <div className="modal-box relative text-base scale-100">
          <button
            onClick={() => {
              modalFunc.setModalOpen("isPremiumOpen", false);
            }}
            className="btn btn-circle btn-sm btn-ghost absolute right-2 top-2"
          >
            <i className="fa-regular fa-xmark text-lg"></i>
          </button>

          <div className="modal-box--body text-sm leading-relaxed">
            <div className="mb-2 flex flex-col items-center justify-center text-center ">
              <div className="flex items-center justify-center w-16 h-16 rounded-full bg-primary/10 mb-6 mt-2">
                <i className="fa-duotone fa-gift text-2xl icon-primary"></i>
              </div>

              <h3 className="text-base leading-relaxed font-medium">
                {getMessage()}
              </h3>
            </div>

            {!isMatchCondition && referralCode && (
              <div className="text-center leading-relaxed mt-6">
                <div className="text-center">
                  <span className="inline-block p-1 border border-base-content/40 rounded-full">
                    <span className="pl-3 pr-1 overflow-hidden max-w-[300px] truncate">
                      <span className="text-accent font-mono text-lg font-semibold">{`${code}`}</span>
                    </span>

                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_PUBLIC_URL || 'https://app.goal3.xyz'}?r=${code}`
                        );
                        toast("Copied");
                      }}
                      className="btn btn-sm btn-circle btn-ghost tooltip tooltip-top"
                      data-tip="Click to copy"
                    >
                      <i className="fa-solid fa-copy"></i>
                    </button>
                  </span>
                </div>
              </div>
            )}

            {!isMatchCondition && !referralCode && (
              <div className="mt-6 text-center leading-relaxed">
                <div className="text-center">
                  <span className="inline-block border border-base-content/40 p-1 rounded-full">
                    <span className="inline-block font-mono text-base px-3 py-1 text-accent">
                      ********
                    </span>
                  </span>
                </div>
              </div>
            )}

            {referralCode && isMatchCondition && (
              <>
                <div className="flex flex-row flex-wrap justify-center items-center mt-6 w-full gap-3">
                  <div className="w-auto text-center">
                    {isEdittingCode ? (
                      <>
                        <div className="w-64 group flex items-stretch border border-base-content/40 p-1 rounded-full">
                          <input
                            className="input input-sm input-ghost font-semibold text-base w-full"
                            value={code}
                            ref={inputEditRef}
                            onChange={(e) => {
                              setCode(e.target.value);
                            }}
                            minLength={4}
                            maxLength={10}
                            disabled={isUpdatingCode}
                            placeholder="Enter Your Prefer Code"
                          />
                          {isUpdatingCode ? (
                            <div className="btn btn-sm btn-circle btn-active no-animation">
                              <i className="fa-solid fa-circle-notch fa-spin text-base"></i>
                            </div>
                          ) : (
                            <>
                              <button
                                onClick={() => {
                                  setIsEdittingCode(false);
                                  setCode(originalCode);
                                }}
                                className="btn btn-sm btn-circle btn-ghost mr-2 tooltip"
                                data-tip="Cancel"
                              >
                                <i className="fa-regular fa-arrow-turn-down-left leading-none"></i>
                              </button>
                              <button
                                onClick={onUpdateShortCode}
                                className="btn btn-sm btn-circle btn-primary tooltip"
                                data-tip="Click to save"
                              >
                                <i className="fa-regular fa-floppy-disk text-lg leading-none"></i>
                              </button>
                            </>
                          )}
                        </div>
                        {errorText && (
                          <div className="text-xs opacity-70 mt-2">
                            {errorText}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex items-stretch border border-accent/40 p-1 rounded-full">
                        <div className="flex items-center justify-between text-base w-full">
                          <span className="pl-3 pr-2 overflow-hidden max-w-[300px] truncate">
                            <span className="text-accent font-mono font-semibold text-lg">{`${code}`}</span>
                          </span>

                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_PUBLIC_URL || 'https://app.goal3.xyz'}?r=${code}`
                              );
                              toast("Copied");
                            }}
                            className="btn btn-sm btn-ghost btn-circle tooltip tooltip-top"
                            data-tip="Click to copy"
                          >
                            <i className="fa-solid text-xs fa-copy"></i>
                          </button>
                        </div>
                        <div className="flex items-center">
                          <button
                            onClick={() => {
                              setIsEdittingCode(true);
                            }}
                            className="btn btn-sm btn-ghost btn-circle tooltip tooltip-top"
                            data-tip="Click to edit"
                          >
                            <i className="fa-regular text-xs fa-pen-line"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-auto text-center hidden">
                    <button
                      className="btn btn-md btn-circle tooltip tooltip-top"
                      data-tip="Add Code"
                    >
                      <i className="fa-regular text-xs fa-plus"></i>
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="mt-8 -mx-3 md:mx-0 bg-base-100/40 rounded-box">
              <div className="relative flex justify-evenly border-b border-base-content/10">
                <button
                  className={`px-4 py-3 w-full opacity-70 ${
                    tab === "regular" ? "opacity-100" : ""
                  } ${referralCode ? "opacity-70" : ""}`}
                  onClick={() => setTab("regular")}
                >
                  <div className="flex justify-center items-center">
                    {referralCode ? (
                      <i className="fa-duotone fa-circle-check text-2xl text-success mr-3"></i>
                    ) : (
                      <i className="fa-regular fa-circle-xmark text-2xl opacity-70 mr-3"></i>
                    )}
                    <div className="flex flex-col items-start">
                      <span className="text-xs font-semibold uppercase tracking-wider">
                        Regular Code
                      </span>
                      <span className="text-xs opacity-70 truncate">
                        $100 Wager Amount
                      </span>
                    </div>
                  </div>
                </button>
                <div
                  className="w-4 absolute right-[50%] top-0 bottom-0 -mr-2"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-base-content/10"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <button
                  className={`px-4 py-3 w-full opacity-70 ${
                    tab === "premium" ? "opacity-100" : ""
                  } ${isMatchCondition ? "opacity-70" : ""}`}
                  onClick={() => setTab("premium")}
                >
                  <div className="flex justify-center items-center">
                    {isMatchCondition ? (
                      <i className="fa-duotone fa-circle-check text-2xl text-success mr-3"></i>
                    ) : (
                      <i className="fa-regular fa-circle-xmark text-2xl opacity-70 mr-3"></i>
                    )}
                    <div className="flex flex-col items-start">
                      <span className="text-xs font-semibold uppercase tracking-wider">
                        Premium Code
                      </span>
                      <span className="text-xs opacity-70 truncate">
                        $10K Referral Volume
                      </span>
                    </div>
                  </div>
                </button>
              </div>

              {tab === "regular" && (
                <div className="px-4 py-6">
                  <div className="px-2 opacity-70">
                    {/* <p>Activate your code to refer frens and start earning $ZKG rewards.</p> */}
                    <p>Regular Code Benefits:</p>
                    <ul className="mt-2 space-y-1">
                      <li className="flex items-start">
                        <i className="fa-regular fa-check text-success mt-1 mr-2 w-4"></i>
                        <span>Refer frens</span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-xmark text-error mt-1 mr-2 w-4"></i>
                        <span className="line-through">Extra $ZKG rewards</span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-xmark text-error mt-1 mr-2 w-4"></i>
                        <span className="line-through">Personalized codes</span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-xmark text-error mt-1 mr-2 w-4"></i>
                        <span className="line-through">
                          Private Telegroup support
                        </span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-xmark text-error mt-1 mr-2 w-4"></i>
                        <span className="line-through">
                          Co-marketing: Sponsored giveaways, zkUSD voucher &
                          many more
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="rounded-box divide-y border border-base-content/10 divide-base-content/10 mt-6 overflow-hidden">
                    <div className="px-4 py-4 bg-success/10">
                      <div className="text-sm mb-2 flex justify-between">
                        <span>
                          {totalAmountPercent > 0
                            ? `${totalAmountPercent}% complete`
                            : "Not started"}
                        </span>
                        <span
                          className={`opacity-70${
                            totalAmountPercent === 100 ? " hidden" : ""
                          }`}
                        >
                          {floatFormat(
                            (MIN_PLAYERD_FOR_VIPCODE -
                              Number(user?.totalAmount || 0)) /
                              1e6
                          )}{" "}
                          zkUSD left
                        </span>
                      </div>
                      <div className="w-full rounded-full bg-success/20 relative flex justify-center items-center p-0.5">
                        <div
                          className={`absolute top-0 bottom-0 left-0 w-[${totalAmountPercent}%] bg-success rounded-full transition-all z-0`}
                          style={{ width: `${totalAmountPercent}%` }}
                        ></div>
                        <span className="relative z-1 text-[10px] font-medium uppercase text-accent-content leading-none"></span>
                      </div>
                    </div>
                    <div className="flex justify-between items-center px-4 py-3">
                      <span className="opacity-70">Your Current Wager</span>
                      <span>
                        {floatFormat(Number(user?.totalAmount || 0) / 1e6)}{" "}
                        zkUSD
                      </span>
                    </div>
                    <div className="flex justify-between items-center px-4 py-3">
                      <span className="opacity-70">Requirement</span>
                      <span>
                        {floatFormat(MIN_PLAYERD_FOR_VIPCODE / 1e6)} zkUSD
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {tab === "premium" && (
                <div className="px-4 py-6">
                  <div className="px-2 opacity-70">
                    {/* <span>Upgrade to Premium Code to enjoy extra $ZKG rewards, personalized codes, and weekly lossback.</span> */}
                    <p>Premium Code Benefits:</p>
                    <ul className="mt-2 space-y-1">
                      <li className="flex items-start">
                        <i className="fa-regular fa-check text-success mt-1 mr-2 w-4"></i>
                        <span>Refer frens</span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-check text-success mt-1 mr-2 w-4"></i>
                        <span>Extra $ZKG rewards</span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-check text-success mt-1 mr-2 w-4"></i>
                        <span>Personalized codes</span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-check text-success mt-1 mr-2 w-4"></i>
                        <span>Private Telegroup support</span>
                      </li>
                      <li className="flex items-start">
                        <i className="fa-regular fa-check text-success mt-1 mr-2 w-4"></i>
                        <span>
                          Co-marketing: Sponsored giveaways, zkUSD voucher &
                          many more
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="rounded-box divide-y border border-base-content/10 divide-base-content/10 mt-6 overflow-hidden">
                    <div className="px-4 py-4 bg-accent/10">
                      <div className="text-sm mb-2 flex justify-between">
                        <span>
                          {totalIVpercent > 0
                            ? `${totalIVpercent}% Complete`
                            : "Not started"}
                        </span>
                        <span
                          className={`opacity-70${
                            totalIVpercent === 100 ? " hidden" : ""
                          }`}
                        >
                          {floatFormat(
                            (MIN_LOCKED_FOR_VIPCODE - totalIV) / 1e6
                          )}{" "}
                          zkUSD left
                        </span>
                      </div>
                      <div className="w-full rounded-full bg-accent/20 relative flex justify-center items-center p-0.5">
                        <div
                          className={`absolute top-0 bottom-0 left-0 w-[${totalIVpercent}%] bg-accent rounded-full transition-all z-0`}
                          style={{ width: `${totalIVpercent}%` }}
                        ></div>
                        <span className="relative z-1 text-[10px] font-medium uppercase text-accent-content leading-none"></span>
                      </div>
                    </div>
                    <div className="flex justify-between items-center px-4 py-3">
                      <span className="opacity-70">Your Referral Volume</span>
                      <span>{floatFormat(totalIV / 1e6)} zkUSD</span>
                    </div>
                    <div className="flex justify-between items-center px-4 py-3">
                      <span className="opacity-70">Requirement</span>
                      <span>
                        {floatFormat(MIN_LOCKED_FOR_VIPCODE / 1e6)} zkUSD
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-8 text-sm text-left flex justify-center items-center">
              <Link
                className="opacity-70 hover:text-primary hover:opacity-100"
                to="https://t.me/+sj9_pjns7jdiMDQ9"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-solid fa-paper-plane mr-2"></i>
                Get Support
              </Link>
              <span className="mx-3 opacity-70">•</span>
              <Link
                className="opacity-70 hover:text-primary hover:opacity-100"
                to="https://docs.goal3.xyz/referral-program"
                target={`_Blank`}
              >
                <i className="fa-solid fa-square-question mr-2"></i>
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PremiumCodeModal;
