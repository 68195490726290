import { useAuth } from "contexts/authContext";
import { useAccount } from "hooks/useAccount";
import { userWagerProxy } from "proxy-state/userWagerProxy";
import { useEffect } from "react";

export const useFetchUserWager = () => {
  const { token } = useAuth();
  const { address } = useAccount();
  // const address = "0xc615e3178a63BA2d720eb245f7872a129495C27C";
  useEffect(() => {
    if (!token || !address) return;

    setTimeout(() => {
      userWagerProxy.load(address);
    }, 500);

    return () => {
      userWagerProxy.unsubscribeDb();
    };
  }, [token, address]);
};
