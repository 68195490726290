import React, { Fragment, useEffect, useRef, useState } from "react";
import ScrollList from "./scrollList";
import { League } from "interfaces/firebase";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";
import { useSnapshot } from "valtio";
import { leagueProxy } from "proxy-state/leagueProxy";
import categoryNavProxy from "proxy-state/categoryNavProxy";
import _ from "lodash";
import LeagueNavProxy from "proxy-state/leagueNavProxy";

const LeaguesNav: React.FC = () => {
  const {leagueTab, setLeagueTab } = useSnapshot(LeagueNavProxy);
  const { data: publishedLeagues, isLoading: isLoadingLeagues } = useSnapshot(leagueProxy);
  const { cateTab } = useSnapshot(categoryNavProxy);
  const leagues = _.orderBy(Array.from(publishedLeagues.values()), 'eventCount', 'desc')?.filter((league) => league.category_id === cateTab);
  const leagueNav = _.orderBy(
    leagues.filter( (league) => league.eventCount > 0 ),
    ["ordering"],
    ["asc"]
  );

  const totalMatches = leagueNav.reduce(
    (acc, curr) => acc + (curr.eventCount || 0),
    0
  );
  const navRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [open, setOpen] = useState(false);

  //close the dropdown when clicking outside the referenced element
  const ref = useRef<HTMLDivElement | null>(null);
  const setSelected = (label: string) => {
    setLeagueTab(label);
    setOpen(false);
  };

  useEffect(() => {
    if (navRef.current) {
      const navWidth = navRef.current.offsetWidth;
      const ActiveTabId =
        leagueNav.find((nav) => nav.id === leagueTab)?.id || "";
      const activeTab = document.getElementById(ActiveTabId || leagueNav[0].id);
      const firstTab = document.getElementById(leagueNav[0].id);
      const lastTab = document.getElementById(
        leagueNav[leagueNav.length - 1].id
      );
      if (activeTab && firstTab && lastTab) {
        const activeTabLeft = activeTab.offsetLeft;
        const activeTabWidth = activeTab.offsetWidth;
        const firstTabLeft = firstTab.offsetLeft;
        const lastTabLeft = lastTab.offsetLeft;
        const lastTabWidth = lastTab.offsetWidth;

        let scrollOffset = activeTabLeft - navWidth / 2 + activeTabWidth / 2;

        if (scrollOffset < firstTabLeft - navWidth / 2 + lastTabWidth / 2) {
          scrollOffset = firstTabLeft - navWidth / 2 + lastTabWidth / 2;
        }

        if (scrollOffset > lastTabLeft - navWidth / 2 + lastTabWidth / 2) {
          scrollOffset = lastTabLeft - navWidth / 2 + lastTabWidth / 2;
        }
        navRef.current.scrollLeft = scrollOffset;
      }
    }
  }, [leagueTab, leagueNav]);
  if (!leagueNav.length) return null;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(event.pageX - navRef.current!.offsetLeft);
    setScrollLeft(navRef.current!.scrollLeft);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    event.preventDefault();
    const x = event.pageX - navRef.current!.offsetLeft;
    const scroll = x - startX;
    navRef.current!.scrollLeft = scrollLeft - scroll;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <>
      <div className="w-full h-full flex md:pr-6">
        <div className="dropdown flex-shrink-0 mr-2" ref={ref}>
          <label
            tabIndex={0}
            className="dropdown-toggle"
            // onClick={() => setOpen((prev) => !prev)}
          >
            <button className="btn btn-sm btn-base200 px-3 shadow">
              <i className="fa-solid fa-bars text-xs"></i>
            </button>
          </label>
          <div tabIndex={0} className="dropdown-content menu w-80 mt-2">
            <Link
              to={`${slugs.sports}/${cateTab}`}
              className={`dropdown-menu-item ${
                leagueTab === "all" || leagueTab === "" ? " isActive" : ""
              }`}
              onClick={() => setSelected("all")}
            >
              <span className="flex items-center">
                <span className="w-5 mr-2 text-center flex-shrink-0">
                  <i className={`fa-duotone fa-futbol text-base`}></i>
                </span>
                <span className="truncate max-w-[208px]">All Leagues</span>
              </span>
              <span className="mr-1 text-xs">{totalMatches}</span>
            </Link>

            {leagueNav.filter((league) => league.has_toplist === 1)
              .length > 0 && (
              <>
                <span className="block w-full h-[1px] bg-base-content bg-opacity-20 my-2"></span>
                {leagueNav
                  .filter((league) => league.has_toplist === 1)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <Link
                        key={item?.id}
                        to={`/${item?.url_path}`}
                        className={`dropdown-menu-item${
                          leagueTab === item?.id ? " isActive" : ""
                        }`}
                        onClick={() => setSelected(item.id)}
                      >
                        <span className="flex items-center">
                          {item.icon_classes && (
                            <span className="flex flex-shrink-0 items-center justify-center w-5 mr-2">
                              <i
                                className={`${item.icon_classes} text-base`}
                              ></i>
                            </span>
                          )}
                          <span className="truncate w-52">{item.name}</span>
                        </span>
                        {item?.eventCount > 0 && (
                          <span className="mr-1 text-xs">
                            {item.eventCount}
                          </span>
                        )}
                      </Link>
                    </Fragment>
                  ))}
              </>
            )}
            {leagueNav.filter((league) => league.has_toplist === 0)
              .length > 0 && (
              <>
                <span className="block w-full h-[1px] bg-base-content bg-opacity-20 my-2"></span>
                {leagueNav
                  .filter((league) => league.has_toplist === 0)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <Link
                        key={item.name}
                        to={`/${item?.url_path}`}
                        className={`dropdown-menu-item${
                          leagueTab === item?.id ? " isActive" : ""
                        }`}
                        onClick={() => setSelected(item.id)}
                      >
                        <span className="flex items-center">
                          {item.icon_classes && (
                            <span className="w-5 mr-2 text-center flex-shrink-0">
                              <i
                                className={`${item.icon_classes} text-base`}
                              ></i>
                            </span>
                          )}
                          <span className="truncate max-w-[208px]">
                            {item.name}
                          </span>
                        </span>
                        {item.eventCount > 0 && (
                          <span className="mr-1 text-xs">
                            {item.eventCount}
                          </span>
                        )}
                      </Link>
                    </Fragment>
                  ))}
              </>
            )}
          </div>
        </div>
        <div
          className="w-full h-full overflow-hidden"
          ref={navRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <ScrollList>
            {_.orderBy(leagues, 'eventCount', 'desc')?.map((item) => (
                <Fragment key={item?.id}>
                  <Link
                    key={item.name}
                    id={item.id}
                    onClick={() => setSelected(item.id)}
                    to={`/${item?.url_path}`}
                    className={`btn btn-sm md:gap-2 px-2 ${
                      leagueTab === item?.id
                        ? "font-semibold border-primary !text-primary bg-transparent hover:bg-transparent hover:border-primary"
                        : "font-normal btn-ghost !text-base-content/50"
                      }${(!item.eventCount || item.eventCount <= 0) ? ' disabled cursor-not-allowed ' : ''}`}
                  >
                    {item.icon_classes && (
                      <span className="flex flex-shrink-0 items-center justify-center mr-2 md:mr-0">
                        <i className={`${item.icon_classes} text-base`}></i>
                      </span>
                    )}
                    <span className="text-xs md:text-sm">{item.name}</span>
                    <span
                      className={`badge badge-noti ${
                        leagueTab !== item?.id && "badge-noti-alt"
                      }`}
                    >
                    {(!item.eventCount || item.eventCount <= 0) ? 0 : item.eventCount}
                    </span>
                  </Link>
                </Fragment>
              ))}
          </ScrollList>
        </div>
      </div>
    </>
  );
};

export default LeaguesNav;


