import { customAxios as axios } from "utils/server";
import initAxios from "axios";
import { getItem } from "local-storage";
import { toast } from "react-toastify";
import { AxiosInstance } from "axios";

export interface PlaceOrder {
  user_address: string;
  system_type: number;
  outcome_ids: string[];
  min_payout: string;
  amounts: string[];
}

const getHeaders = () => {
  const accessToken = getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export interface saveOrderProps {
  outcomeIds: string[];
  totalAmount: number;
  odds: number[];
  systemType: number;
  txHash: string;
  orderType: string;
  wagerAmounts: any[];
  amounts: any[];
}
export enum SocialType {
  GOOGLE = "google",
  TWITTER = "twitter",
  WALLET = "wallet",
}
const BOOKMARKER_URL = process.env.REACT_APP_BOOKMAKER_URL;
export const buildTxPlaceOrderApi = (order: PlaceOrder, token: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/bookmakers/build-transaction`;
  return axios.post(url, order, {
    headers: { authorization: "Bearer " + token },
  });
};

export const getConfigPlaceOrderApi = (token: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/bookmakers/config`;
  return axios.get(url);
};
export const getAccessToken = (token: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/login`;
  const res = axios.post(
    url,
    { token },
    { headers: { "Content-Type": "application/json" } }
  );
  /* result: {
    token: string;
    expires_in: number;
    token_type: string;
    isReferred: boolean;
    code: string;
  }*/
  return res;
};
export const SavePlaceOrder = (data: saveOrderProps, token: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/bookmakers/save-order`;
  return axios.post(
    url,
    { ...data },
    { headers: { authorization: "Bearer " + token } }
  );
};

export const verifyReferralCode = (token: string, code: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/referral-code`;
  return axios.put(
    url,
    { refcode: code },
    { headers: { authorization: "Bearer " + token } }
  );
};
export const getAppVersions = () => {
  const url = `${BOOKMARKER_URL}/api/v1/app-version`;
  return axios.get(url);
};

export const login = (address: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/login`;
  return axios.post(url, {
    address: address,
  });
};

export const linkSocial = (
  address: `0x${string}` | undefined,
  socialId: string,
  socialType: SocialType
) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/link-social`;
  if (!address) {
    toast.error("Connect wallet before link to social");
  } else {
    return axios.post(url, {
      address,
      socialId,
      socialType,
    });
  }
};

export const createSmartAccount = (address: `0x${string}` | undefined) => {
  const url = `${BOOKMARKER_URL}/api/v1/eoa-aa`;
  if (!address) {
    toast.error("Connect wallet before create smart account");
  } else {
    return axios.post(
      url,
      {
        address,
      },
      { timeout: 120 * 1000 }
    );
  }
};

export const makeTxPlaceOrderApi = (order: PlaceOrder) => {
  const accessToken = getItem("access_token");
  const url = `${BOOKMARKER_URL}/api/v1/bookmakers/place-order`;
  return axios.post(url, order, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createSession = (wallet: string, pvKey: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/eoa-aa/session`;
  return axios.post(url, {
    wallet,
    pvKey,
  });
};

export const checkSessionExist = (wallet: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/check-session/${wallet}`;

  return axios.get(url);
};

export const revokeSessionApi = () => {
  const accessToken = getItem("access_token");
  const url = `${BOOKMARKER_URL}/api/v1/eoa-aa/revoke-session`;
  return axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const loginSocial = (token: string, socialType: SocialType) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/login-social`;
  return axios.post(url, {
    token,
    socialType,
  });
};

export const linkWallet = (
  googleToken: string,
  socialType: SocialType,
  wallet: string | undefined
) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/link-wallet`;
  return axios.post(url, {
    token: googleToken,
    socialType,
    wallet,
  });
};

export const enterInviteCode = (refcode: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/auth/referral-code`;
  return axios.put(
    url,
    {
      refcode,
    },
    {
      headers: getHeaders(),
    }
  );
};

export const checkWalletExist = (address: string) => {
  const url = `${BOOKMARKER_URL}/api/v1/eoa-aa/check-exist`;
  return axios.get(url, {
    params: {
      address,
    },
  });
};

export const redeem = (zkId: number, ids: Array<string>) => {
  const accessToken = getItem("access_token");
  const url = `${BOOKMARKER_URL}/api/v1/bookmakers/redeem`;
  return axios.post(
    url,
    {
      zk_id: zkId,
      outcome_ids: ids,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const updateWager = (address: string, wagerIds: Array<string>) => {
  const accessToken = getItem("access_token");
  const url = `${BOOKMARKER_URL}/api/v1/wager/update`;
  return axios.put(
    url,
    {
      address,
      wagerIds,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const exportWallet = () => {
  const accessToken = getItem("access_token");
  const url = `${BOOKMARKER_URL}/api/v1/eoa-aa/export`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Base BookMaker API
class BaseApi {
  protected axios: AxiosInstance;

  private get baseUrl(): string {
    return process.env.REACT_APP_BOOKMAKER_URL! + "/api/v1";
    // return "http://localhost:4031/api/v1";
  }

  constructor(prefixUrl: string) {
    const _axios = initAxios.create({
      baseURL: this.baseUrl + prefixUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });
    _axios.interceptors.response.use(
      (res) => res.data,
      (err) => Promise.reject(err.response.data.message)
    );
    this.axios = _axios;
  }
}

class AuthApi extends BaseApi {
  constructor() {
    super("/auth");
  }

  async getNonce(address: string): Promise<string> {
    try {
      const { nonce } = await this.axios.get<any, { nonce: string }>("/nonce", {
        params: { address },
      });
      return nonce;
    } catch (error) {
      throw error;
    }
  }

  async loginWallet(
    message: any,
    signature: string
  ): Promise<{ access_token: string; address: string }> {
    try {
      return await this.axios.post("/login-wallet", { message, signature });
    } catch (error) {
      throw error;
    }
  }

  async checkExist(address: string): Promise<boolean> {
    try {
      const result = await this.axios.get<any, boolean>(
        `/check-session/${address}`
      );
      return result;
    } catch (error) {
      throw error;
    }
  }
}

class EoaAa extends BaseApi {
  constructor() {
    super("/eoa-aa");
  }
}

export const bookMarkerApi = {
  auth: new AuthApi(),
  eoaAa: new EoaAa(),
};
