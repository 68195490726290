import React from "react";
import { useSnapshot } from "valtio";
import { BetFilterProxy } from "proxy-state/betting/myBetFilter";
import { IMyBetLayout, IOrderBy, IPageType, ITimeFilterType } from "shared/enum";
const BetsFilterModal = () => {
	const [modalShow, setModalShow] = React.useState<boolean>(false);
	const { orderBy, setOrderBy, UnClaimed, setUnClaimed, timeFilter, setTimeFilter, MyBetLayout } = useSnapshot(BetFilterProxy);

	return (
		<>
			<input type="checkbox" id="bets-filter--modal" checked={modalShow} className="modal-toggle" onChange={(e) => setModalShow(e.target.checked)}></input>

			<label htmlFor="bets-filter--modal" className={`modal modal-bottom sm:modal-middle${modalShow ? '' : ' -z-10 hidden'}`}>
				<label className="modal-box relative overflow-auto lg:max-w-md transition-all">
					<div className="h-full flex flex-col w-full overflow-y-auto max-h-[calc(var(--vh,1vh)*100)]">
						<div className="modal-box--header justify-between">
							<h3 className="">Sort by</h3>
							<label htmlFor="bets-filter--modal" className="btn btn-circle btn-sm btn-ghost">
								<i className="fa-regular fa-xmark text-lg"></i>
							</label>
						</div>

						<div className="modal-box--body flex flex-col gap-6 !px-4">
							{MyBetLayout === IMyBetLayout.BETS && (
								<div className="space-y-4">
									<div className="flex flex-col bg-base-100 p-2 rounded-box">
										<button className="flex items-center justify-between py-4 px-4 rounded-box z-[1]">
											<span className="flex items-start">Unclaimed Only</span>
											<span className="flex items-center">
												<div className="form-control">
													<input type="checkbox" checked={UnClaimed} className="toggle toggle-primary" onChange={(e) => setUnClaimed(e.target.checked)} />
												</div>
											</span>
										</button>
									</div>
								</div>
							)}
							<div className="space-y-4">
								<div className="text-xs uppercase tracking-wide opacity-70 ml-3">Time</div>
								<div className="flex flex-col bg-base-100 p-2 rounded-box">
									<button 
										onClick={() => setTimeFilter(ITimeFilterType.allTime)}
										className={`flex items-center justify-between py-4 px-4 rounded-box z-[1]${timeFilter === ITimeFilterType.allTime ? '' : ' text-base-content/50 hover:text-base-content'}`}>
										<span className="flex items-start">All Time</span>
										{timeFilter === ITimeFilterType.allTime && (
											<span className="flex items-center">
												<i className="fa-solid fa-check"></i>
											</span>
										)}
									</button>

									<span className="ml-2 mr-2 h-[1px] bg-base-content/10 -mt-[1px] z-0" role="separator"></span>

									<button 
										onClick={() => setTimeFilter(ITimeFilterType.today)}
										className={`flex items-center justify-between py-4 px-4 rounded-box z-[1]${timeFilter === ITimeFilterType.today ? '' : ' text-base-content/50 hover:text-base-content'}`}>
										<span className="flex items-start">Last 24 hours</span>
										{timeFilter === ITimeFilterType.today && (
											<span className="flex items-center">
												<i className="fa-solid fa-check"></i>
											</span>
										)}
									</button>

									<span className="ml-2 mr-2 h-[1px] bg-base-content/10 -mt-[1px] z-0" role="separator"></span>

									<button onClick={() => setTimeFilter(ITimeFilterType.thisWeek)}
										className={`flex items-center justify-between py-4 px-4 rounded-box z-[1]${timeFilter === ITimeFilterType.thisWeek ? '' : ' text-base-content/50 hover:text-base-content'}`}>
										<span className="flex items-start">Last 7 days</span>
										{timeFilter === ITimeFilterType.thisWeek && (
											<span className="flex items-center">
												<i className="fa-solid fa-check"></i>
											</span>
										)}
									</button>

									<span className="ml-2 mr-2 h-[1px] bg-base-content/10 -mt-[1px] z-0" role="separator"></span>

									<button onClick={() => setTimeFilter(ITimeFilterType.thisMonth)}
										className={`flex items-center justify-between py-4 px-4 rounded-box z-[1]${timeFilter === ITimeFilterType.thisMonth ? '' : ' text-base-content/50 hover:text-base-content'}`}>
										<span className="flex items-start">Last 30 days</span>
										{timeFilter === ITimeFilterType.thisMonth && (
											<span className="flex items-center">
												<i className="fa-solid fa-check"></i>
											</span>
										)}
									</button>
								</div>
							</div>

							<div className="space-y-4">
								<div className="text-xs uppercase tracking-wide opacity-70 ml-3">Ordering</div>
								<div className="flex flex-col bg-base-100 p-2 rounded-box">
									<button onClick={() => setOrderBy(IOrderBy.ASC)}
										className={`flex items-center justify-between py-4 px-4 rounded-box z-[1]${orderBy === IOrderBy.ASC ? '' : ' text-base-content/50 hover:text-base-content'}`}>
										<span className="flex items-start">
											<span className="flex items-center justify-center w-6 mr-4 mt-1">
												<i className="fa-solid fa-arrow-up text-lg"></i>
											</span>
											Sort Ascending
										</span>
										{orderBy === IOrderBy.ASC && (
											<span className="flex items-center">
												<i className="fa-solid fa-check"></i>
											</span>
										)}
									</button>

									<span className="ml-14 mr-2 h-[1px] bg-base-content/10 -mt-[1px] z-0" role="separator"></span>

									<button onClick={() => setOrderBy(IOrderBy.DESC)}
										className={`flex items-center justify-between py-4 px-4 rounded-box z-[1]${orderBy === IOrderBy.DESC ? '' : ' text-base-content/50 hover:text-base-content'}`}>
										<span className="flex items-start">
											<span className="flex items-center justify-center w-6 mr-4 mt-1">
												<i className="fa-solid fa-arrow-down text-lg"></i>
											</span>
											Sort Descending
										</span>
										{orderBy === IOrderBy.DESC && (
											<span className="flex items-center">
												<i className="fa-solid fa-check"></i>
											</span>
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</label>
			</label>
		</>
	);
}

export default BetsFilterModal;
