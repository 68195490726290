import React from "react";
import { isChrome } from "react-device-detect";
import FooterLanding from "./footerLanding";
import BgVideo from "assets/wallpapers/soccer.mp4";

interface WrongBrowserPageProps {
  isDevice: "ios" | "android";
}
export const WrongBrowserPage: React.FC<WrongBrowserPageProps> = ({
  isDevice,
}) => {
  return (
    <>
      <div
        data-theme="dark"
        className="main-layout--wrapper !max-w-screen !max-h-screen bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
      >
        <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen  bg-cover">
          <video
            className="object-cover w-full h-screen"
            autoPlay
            playsInline
            loop
            muted
          >
            <source type="video/webm" src={BgVideo} />
          </video>
        </div>

        <div className="main-layout">
          <div className="w-full min-h-screen flex items-center justify-center flex-col z-0 relative">
            <div className="mx-auto w-full max-w-sm text-center p-4 lg:p-0">
              <div className="flex flex-col gap-6">
                <i className="fa-duotone fa-compass icon-primary text-6xl"></i>

                <h2 className="text-2xl font-semibold">
                  Wrong browser detected
                </h2>

                <div className="leading-relaxed">
                  Please visit{" "}
                  <span className="text-primary">app.goal3.xyz</span> in{" "}
                  <span className="text-primary">
                    {isDevice === "ios" ? "Safari" : "Chrome"}
                  </span>{" "}
                  browser to install app.
                </div>
              </div>

              <FooterLanding />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WrongBrowserPage;
