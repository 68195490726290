const LOL = ({ classes =""}: {classes: string}) => {
	console.log('====================================');
	console.log('classes', classes);
	console.log('====================================');
	return (
		<svg version="1.1"
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 32 32" 
			fill="currentColor"
			data-editor-id="navbarIcon"
			data-cy="sport-dota2"
			width="24"
			height="24"
			className={`${classes ? classes : 'h-6 w-6 rounded-full'}`}
			color="currentColor"
			>
			<path d="M2.766 0l3.739 6.042v19.893l-3.985 6.065h23.361l6.119-8h-16.111v-24h-13.123zM7.563 2.667h5.645v24h13.382l-2.037 2.667h-17.077l1.71-2.602v-21.443l-0.199-0.32-1.424-2.302zM18.571 2.922v2.721c5.365 1.217 9.384 5.99 9.384 11.69 0 1.403-0.246 2.748-0.694 4h2.812c0.363-1.272 0.563-2.613 0.563-4 0-7.176-5.212-13.153-12.065-14.411zM3.824 8.927c-1.35 1.911-2.258 4.149-2.566 6.573-0.076 0.6-0.115 1.212-0.115 1.833 0 0.748 0.056 1.483 0.165 2.201 0.35 2.283 1.235 4.392 2.516 6.206v-16.813z"></path>
		</svg>
	)
}
export default LOL;