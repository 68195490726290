const Tab = ({ tabList, tab, setTab }: any) => {
  if (0 === tabList.length) return null;
  const child = tabList.map((item: any, idx: number) => {
    return (
      <button
        key={idx}
        className={`btn btn-sm${
          tab === item
            ? " text-base-content bg-neutral hover:bg-neutral-focus"
            : " btn-ghost text-base-content/50"
        }}`}
        onClick={() => setTab(item)}
      >
        <span className="">{item}</span>
      </button>
    );
  });
  return (
    <div className="bg-base-200 rounded-box flex items-center gap-1">
      {child}
    </div>
  );
};
export { Tab };
