import Countdown from "react-countdown";
import { CountdownTimeDeltaFn } from "react-countdown/dist/Countdown";

const getColor = (minutes: number) => {
  switch (true) {
    case minutes < 10:
      return "text-orange-500";

    case minutes >= 10 && minutes <= 60 * 24 * 7:
      return "text-[#1BC57E]";

    case minutes >= 0 && minutes < 10:
      return "text-red-500";

    default:
  }
};

export const CountdownTimer = ({
  end_time,
  onComplete,
  onTick,
  hourText = "h",
  minText = "m",
  secText = "s",
  completeText = "Expired",
}: {
  end_time: number;
  onComplete?: () => void;
  onTick?: CountdownTimeDeltaFn;
  hourText?: string;
  minText?: string;
  secText?: string;
  completeText?: string;
}) => {
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      // Render a completed state
      return (
        <span className=" text-primary font-medium text-sm">
          {completeText}
        </span>
      );
    } else {
      // Render a countdown
      return (
        <span className={``}>
          <span>
            {hours}
            {hourText}
          </span>{" "}
          :{" "}
          <span>
            {minutes}
            {minText}
          </span>{" "}
          :{" "}
          <span>
            {seconds}
            {secText}
          </span>
        </span>
      );
    }
  };

  return (
    <Countdown
      date={end_time}
      renderer={renderer}
      onComplete={onComplete}
      onTick={onTick}
    />
  );
};
