import { CategoryList } from "components/organisms/Sports";
import CategoryNav from "components/organisms/Sports/utils/categoryNav";
import HomeProvider from "contexts/homeContext";
import React from "react";
import { useParams } from "react-router-dom";

export default function League() {
  const { category, league } = useParams<{
    category: string;
    league: string;
  }>();
  return (
    <HomeProvider>
      <CategoryNav root={"sports"} />
      <div className="container">
        <CategoryList cate={category || "soccer"} league={league || ""} />
      </div>
    </HomeProvider>
  );
}
