import React, { useEffect } from "react";
import { BigNumber, BN0 } from "functions/betUtils";
import { toast } from "react-toastify";
import { currencyFormat, formatBigNumber, parseBigNumber } from "common/utils";
import { isArray } from "lodash";
import {
  makeTxPlaceOrderApi,
  saveOrderProps,
  SavePlaceOrder,
} from "utils/apis/bookmakerApis";
import { ACCESS_TOKEN, getItem } from "local-storage";
import { ethers } from "ethers";
import { useAccount } from "hooks/useAccount";
import { useEthersSigner } from "hooks/useSigner";
import { useProvider } from "hooks/useProvider";
import { SignInWithFirebase } from "utils/firebase/firebaseAuth";
import { ContractAddress } from "contracts/config";
import { sendTransactionPaymaster } from "contracts/Contract";
import { useWaitForTransaction } from "wagmi";
import { useSnapshot } from "valtio";
import { utils, Web3Provider } from "zksync-web3";
import { myBalanceProxy } from "proxy-state/myBalanceProxy";
import { orderSlipProxy } from "proxy-state/betting/OrderSlip";
import { auth } from "utils/firebase/config2";
import { configProxy } from "proxy-state/configProxy";
import { liquidProxy } from "proxy-state/betting/Liquidity";
import { targetProxy } from "proxy-state/betting/target";
import orderModalProxy from "proxy-state/orderDoneModalProxy";

interface PlaceOrderButtonProps {
  outcomeIds: any[];
  singleAmount: any[];
  minPayOut: BigNumber;
  modeTab: string;
  maxAmountPay: number;
  totalOdds: number;
  target: number;
  orderType: number;
  networkFee: number;
  getOutcomesLiquidityOnchain: Function;
  setOrderTab: React.Dispatch<React.SetStateAction<string>>;
}

const PlaceOrderButton: React.FC<PlaceOrderButtonProps> = ({
  modeTab,
  maxAmountPay,
  totalOdds,
  target,
  singleAmount,
  outcomeIds,
  minPayOut,
  orderType,
  networkFee,
  getOutcomesLiquidityOnchain,
  setOrderTab,
}) => {
  const myBalance = useSnapshot(myBalanceProxy);
  const {
    itemClosed,
    sameOrder,
    overPayoutStatus: overPayout,
  } = useSnapshot(orderSlipProxy);
  const { singleTarget } = useSnapshot(targetProxy);
  const targetStatus = Array.from(singleTarget.values()).some(
    (item) => item === 0
  );
  const { address }: any = useAccount();
  const { symbol } = useSnapshot(configProxy);
  const { maxAmount } = useSnapshot(liquidProxy);
  const { appConfig } = useSnapshot(configProxy);
  const balanceOf = myBalance.balanceOf;
  const [orderData, setOrderData] = React.useState<saveOrderProps>();
  const MAX_BET = Number(
    ethers.utils.formatUnits(BigNumber.from(maxAmount || "0"), 6)
  );
  const [loading, setLoading] = React.useState(false);
  // const { balanceUpdate, setBalanceUpdate, balanceOf } = useSnapshot(historiesProxy);
  // const { status: walletStatus }: any = useSnapshot(connectWalletProxy);
  const blockList: string[] = appConfig?.blockList ?? [];
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [transactionsData, setTransactionsData] = React.useState<any>(null);
  const signer = useEthersSigner();
  const provider = useProvider(process.env.REACT_APP_CHAIN_ID as string);
  // const [isDisabled, setIsDisabled] = React.useState(true);
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [btnText, setBtnText] = React.useState<string>("");
  // const [confirmModal, setConfirmModal] = React.useState<boolean>(false);

  const hookEnabled = () => {
    return (
      outcomeIds.length > 0 &&
      maxAmountPay <= 10000 &&
      !itemClosed &&
      (!sameOrder || (sameOrder && modeTab === "single-mode")) &&
      Number(myBalance.balanceOf) > 1 &&
      !(modeTab === "system-mode" && orderType === 0) &&
      !loading &&
      maxAmountPay > 0
    );
  };
  const isDisabled = !hookEnabled();

  // useEffect(() => {
  //   setIsDisabled(true);
  //   const timer = setTimeout(() => {
  //     setIsDisabled(!hookEnabled());
  //   }, 100);
  //   return () => clearTimeout(timer);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   modeTab,
  //   maxAmountPay,
  //   totalOdds,
  //   target,
  //   singleAmount,
  //   outcomeIds,
  //   minPayOut,
  //   orderType,
  // ]);

  // eslint-disable-next-line no-empty-pattern
  const {} = useWaitForTransaction({
    confirmations: 1,
    hash: transactionsData?.hash,
    onSuccess() {
      setIsSuccess(true);
    },
    onError() {
      console.log("onError", transactionsData?.hash);
    },
  });

  const createPlaceOrderTransaction = async () => {
    const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 300;
    const contractGroup = ContractAddress[Number(chainId)];
    const fakeToken = contractGroup["USDC"];

    // const provider = connector?.options?.getProvider();
    // const bookmakerAddress = `0x${contractGroup["Bookmaker"].replace(/^0x/, "")}`;
    // const bookmakerContract = getContract("Bookmaker", bookmakerAddress, signerProvider);
    const paymasterParams = utils.getPaymasterParams(
      contractGroup["PaymasterBookmaker"],
      {
        type: "ApprovalBased",
        token: fakeToken,
        // set minimalAllowance as we defined in the paymaster contract
        minimalAllowance: BigNumber.from(0),
        // empty bytes as testnet paymaster does not use innerInput
        innerInput: new Uint8Array(),
      }
    );
    let transaction: any;
    let offerOdds: any;
    let wagerAmounts: any;
    try {
      const order =
        modeTab === "single-mode"
          ? {
              zk_id: 1,
              amounts: singleAmount.map((item) => item.toString()),
              system_type: 1,
              user_address: address,
              min_payout: minPayOut.toString(),
              outcome_ids: outcomeIds.map((item) => item.toString()),
            }
          : {
              zk_id: 1,
              amounts: [parseBigNumber(target || 0, true).toString()],
              system_type: orderType,
              user_address: address,
              min_payout: minPayOut.toString(),
              outcome_ids: outcomeIds.map((item) => item.toString()),
            };
      const res = await makeTxPlaceOrderApi(order);

      transaction = res.data;
      offerOdds = res.data.offer_odds;
      wagerAmounts = res.data.wager_amounts;
    } catch (error: any) {
      if (error.response) {
        if (Array.isArray(error.response.data.message)) {
          toast.error(error.response.data.message[0]);
        } else {
          let expandMessage = "";
          if (
            (error.response.data.message as string).includes(
              "Exceed Amount or Payout"
            )
          ) {
            expandMessage = ". Check your order and re-try";
            getOutcomesLiquidityOnchain().then();
          }
          toast.error(error.response.data.message + expandMessage);
        }
      } else if (error.request) {
        console.log(error.request);
        toast.error("An error occurred");
      } else {
        toast.error("Error", error.message);
      }
      console.error(error);
      console.error(error?.data);
      console.error(JSON.stringify(error));
      setLoading(false);
      throw error;
    }

    if (transaction && transaction.transaction) {
      let totalAmount = 0;
      singleAmount.forEach((item: any) => {
        totalAmount += Number(formatBigNumber(item, true));
      });
      totalAmount = orderType > 1 ? maxAmountPay : totalAmount;
      // setBalanceUpdate(!balanceUpdate);
      const saveOrderData: saveOrderProps = {
        outcomeIds: outcomeIds.map((item) => item.toString()),
        totalAmount,
        odds: offerOdds,
        systemType: orderType,
        txHash: "",
        orderType:
          orderType === 1
            ? "single"
            : orderType === outcomeIds.length
            ? "combo"
            : "system",
        amounts:
          orderType === 1
            ? singleAmount.map((sm: BigNumber) => sm.toString())
            : orderType === outcomeIds.length
            ? [maxAmountPay.toString()]
            : wagerAmounts.map((wa: any) =>
                parseBigNumber(maxAmountPay, true)
                  .div(BigNumber.from(wagerAmounts.length))
                  .toString()
              ),
        wagerAmounts: wagerAmounts,
      };

      try {
        let transactionData: any;
        if (!transaction.isAA) {
          // const transactionData = await signerProvider.sendTransaction(transaction);
          if (signer) {
            const signerProvider = new Web3Provider(
              (signer?.provider as any)?.provider
            ).getSigner();
            transactionData = await sendTransactionPaymaster(
              transaction.transaction,
              paymasterParams,
              signerProvider,
              provider
            );
          }
        } else {
          const sentTx = await provider.perform("sendTransaction", {
            signedTransaction: transaction.transaction,
          });
          transactionData = await provider.getTransaction(sentTx);
        }
        saveOrderData.txHash = transactionData?.hash;
        setOrderData(saveOrderData);
        setTransactionsData(transactionData);
        setIsSuccess(true);
      } catch (e: any) {
        if (!(e?.message as string)?.includes("user rejected signing")) {
          toast.error(e?.message);
        }
        console.log("transaction", JSON.stringify(transaction));
        console.error(e);
        console.error(e?.data);
        console.error(JSON.stringify(e));
        setLoading(false);
        throw e;
      }
    }
  };
  const handlePlaceOrder = async () => {
    if (!address) {
      return false;
    }
    setLoading(true);
    // if (!whiteList.includes(address)) {
    // 	toast.warning("You are not whitelisted");
    // 	return false;
    // }
    if (isArray(blockList) && blockList.indexOf(address) !== -1) {
      toast.warning("You are not whitelisted");
      return false;
    }
    // check sign in firebase
    if (!auth?.currentUser) await SignInWithFirebase(auth);

    await createPlaceOrderTransaction();
  };
  useEffect(() => {
    if (isSuccess && transactionsData && orderData) {
      const access_token = getItem(ACCESS_TOKEN) || "";
      SavePlaceOrder(orderData, access_token).then(() => {
        orderModalProxy.setData(orderData);
        setLoading(false);
        myBalance.updateBalance(myBalance.balanceOf - orderData.totalAmount);
        // toast.success("Bet Submitted");
        setOrderTab("my-bets");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const isApproveDisabled = () => {
    return (
      loading ||
      maxAmountPay < 10 ||
      isDisabled ||
      itemClosed ||
      overPayout ||
      (sameOrder && modeTab !== "single-mode") ||
      btnDisabled ||
      (totalOdds > 1000 && modeTab !== "single-mode") ||
      (maxAmountPay < 10 && modeTab !== "single-mode") ||
      (targetStatus && modeTab === "single-mode") ||
      maxAmountPay > Number(myBalance.balanceOf)
    );
  };
  useEffect(() => {
    const amountPay =
      modeTab === "single-mode"
        ? Number(
            formatBigNumber(
              singleAmount.reduce((a: BigNumber, b: BigNumber) => a.add(b), BN0)
            )
          )
        : target;
    if (modeTab === "system-mode" && orderType === 0) {
      setBtnText("Select a system type");
    } else if (Number(balanceOf) === 0 || Number(balanceOf) < amountPay) {
      setBtnText("Insufficient Balance");
      setBtnDisabled(true);
    } else if (totalOdds > 1000 && modeTab !== "single-mode") {
      setBtnText("Odds exeeding x1000");
      setBtnDisabled(true);
    } else if (Number(amountPay) > MAX_BET) {
      setBtnText(`Max bet: ${currencyFormat(MAX_BET, false)} ${symbol}`);
      setBtnDisabled(true);
    } else if (amountPay < 10) {
      setBtnText(`Min bet: 10 ${symbol}`);
      setBtnDisabled(true);
    } else {
      setBtnText("Order");
      setBtnDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    maxAmountPay,
    singleAmount,
    balanceOf,
    target,
    modeTab,
    orderType,
    totalOdds,
    MAX_BET,
  ]);
  return (
    <div className={"w-full"}>
      <button
        type="button"
        onClick={() => {
          if (
            Number(balanceOf) === 0 ||
            Number(balanceOf) < maxAmountPay + networkFee
          ) {
            return;
          }
          if (maxAmountPay > MAX_BET) {
            return;
          }
          if (target < 10 && modeTab !== "single-mode") {
            return;
          }
          handlePlaceOrder();
          // setConfirmModal(true);
        }}
        className={`btn btn-md btn-primary btn-block shadow relative${
          isApproveDisabled()
            ? " btn-disabled opacity-50 cursor-not-allowed"
            : ""
        }`}
      >
        {loading && (
          <svg
            className="w-5 h-5 mr-3 -ml-1 text-base-content animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {btnText}
      </button>
      {/* <Modal
        isOpen={confirmModal}
        toggleModal={() => {
          setConfirmModal(!confirmModal);
        }}
      >
        helo work
      </Modal> */}
    </div>
  );
};

export default PlaceOrderButton;
