import React, { Fragment } from "react";
import OddElement from "./OddElement";
import { oddProps } from "./oddInterface";
import { Responsive } from "components/commons/Responsive";
const getNamedItem = (name: string, market: any) => {
  const nameArr = name.split(" & ");
  const name1 = nameArr[0].split(" ");
  const name2 = name1[name1.length - 1]?.replace(/\s+/g, "");
  const speard = market?.allMarkets?.find(
    (item: any) => item?.key === "spread"
  );

  if (speard && speard?.odds?.length !== 0) {
    const header = speard?.odds?.find((item: any) => item?.handicap === name2);
    if (header) return Number(header?.header);
  }

  const header1 = market?.home?.name;
  const header2 = market?.away?.name;
  if (name.includes(header1)) {
    return 1;
  }
  if (name.includes(header2)) {
    return 2;
  }
  // const overOrUnder = name.includes('Over') ? 'O' : 'U';
  // const header = total?.odds?.find((item: any) => item?.handicap === `${overOrUnder} ${totalNameFind}`);
  // console.log('====================================');
  // console.log('money_line', money_line);
  // console.log('header?.header', header?.header);
  // console.log('====================================');
  // return Number(header?.header);
};
const OddType3: React.FC<oddProps> = ({
  idx,
  market,
  item,
  odds,
  parentUrl,
  marketLiquid,
}) => {
  let header1Odd: any;
  let header2Odd: any;
  let drawOdd: Array<any> = [];
  let eitherOdd: Array<any> = [];
  switch (item?.key) {
    case "correct_score":
      header1Odd = odds.filter((odd: any) => Number(odd.header) === 1);
      header2Odd = odds.filter((odd: any) => Number(odd.header) === 2);
      drawOdd = odds.filter((odd: any) => odd.header === "Draw");
      break;
    case "result_total_goals":
    case "result_both_teams_to_score":
      header1Odd = odds.filter((odd: any) => Number(odd.name) === 1);
      header2Odd = odds.filter((odd: any) => Number(odd.name) === 2);
      drawOdd = odds.filter((odd: any) => odd.name === "Draw");
      break;

    case "goalscorers":
      header1Odd = odds.filter((odd: any) => odd.name2 === market?.home?.name);
      header2Odd = odds.filter((odd: any) => odd.name2 === market?.away?.name);
      break;

    case "match_handicap_and_total":
    case "run_line_and_total":
      header1Odd = odds.filter(
        (odd: any) => getNamedItem(odd.name, market) === 1
      );
      header2Odd = odds.filter(
        (odd: any) => getNamedItem(odd.name, market) === 2
      );
      break;

    case "winning_margins":
      header1Odd = odds.filter((odd: any) => Number(odd.header) === 1);
      header2Odd = odds.filter((odd: any) => Number(odd.header) === 2);
      break;
    case "total_rounds":
      header1Odd = odds.filter((odd: any) => odd.header === "Over");
      header2Odd = odds.filter((odd: any) => odd.header === "Under");
      break;
    default:
      header1Odd = odds.filter(
        (odd: any) => Number(odd.header) === 1 || Number(odd.name) === 1
      );
      drawOdd =
        odds.filter(
          (odd: any) => odd.header === "Draw" || odd.name === "Draw"
        ) || [];
      header2Odd = odds.filter(
        (odd: any) => Number(odd.header) === 2 || Number(odd.name) === 2
      );
      eitherOdd =
        odds.filter(
          (odd: any) => odd.header === "Either" || odd.name === "Either"
        ) || [];
      break;
  }

  return (
    <Fragment key={idx}>
      <div
        className={`grid ${
          drawOdd && drawOdd?.length > 0
            ? eitherOdd?.length > 0
              ? "grid-cols-2 lg:grid-cols-4"
              : "grid-cols-3"
            : "grid-cols-2"
        } gap-2 w-full`}
      >
        <div className="flex flex-col items-center space-y-2">
          {item?.key === "total" || item?.key === "total_rounds" ? (
            <span className="font-medium opacity-70 text-xs text-center">
              Over
            </span>
          ) : (
            <span className="font-medium opacity-70 text-xs text-center">
              {market?.home?.name}
            </span>
          )}
          {header1Odd &&
            header1Odd.map((itemOdd: any, idx: number) => {
              return (
                <OddElement
                  key={idx}
                  idx={idx}
                  odd={itemOdd}
                  item={item}
                  market={market}
                  parentUrl={parentUrl}
                  marketLiquid={marketLiquid}
                />
              );
            })}
        </div>
        {drawOdd && drawOdd?.length > 0 && !eitherOdd?.length && (
          <div className="flex flex-col items-center space-y-2">
            <span className="font-medium opacity-70 text-xs text-center">
              Draw
            </span>
            {drawOdd &&
              drawOdd.map((itemOdd: any, idx: number) => {
                return (
                  <OddElement
                    key={idx}
                    idx={idx}
                    odd={itemOdd}
                    item={item}
                    market={market}
                    parentUrl={parentUrl}
                    marketLiquid={marketLiquid}
                  />
                );
              })}
          </div>
        )}
        {eitherOdd?.length > 0 && (
          <Responsive from="lg">
            <div className="flex flex-col items-center space-y-2">
              <span className="font-medium opacity-70 text-xs text-center">
                Either
              </span>
              {eitherOdd?.length > 0 &&
                eitherOdd.map((itemOdd: any, idx: number) => {
                  return (
                    <OddElement
                      key={idx}
                      idx={idx}
                      odd={itemOdd}
                      item={item}
                      market={market}
                      parentUrl={parentUrl}
                      marketLiquid={marketLiquid}
                    />
                  );
                })}
            </div>
            {drawOdd && drawOdd?.length > 0 && (
              <div className="flex flex-col items-center space-y-2">
                <span className="font-medium opacity-70 text-xs text-center">
                  Draw
                </span>
                {drawOdd &&
                  drawOdd.map((itemOdd: any, idx: number) => {
                    return (
                      <OddElement
                        key={idx}
                        idx={idx}
                        odd={itemOdd}
                        item={item}
                        market={market}
                        parentUrl={parentUrl}
                        marketLiquid={marketLiquid}
                      />
                    );
                  })}
              </div>
            )}
          </Responsive>
        )}
        <div className="flex flex-col items-center space-y-2">
          {item?.key === "total" || item?.key === "total_rounds" ? (
            <span className="font-medium opacity-70 text-xs text-center">
              Under
            </span>
          ) : (
            <span className="font-medium opacity-70 text-xs text-center">
              {market?.away?.name}
            </span>
          )}
          {header2Odd &&
            header2Odd.map((itemOdd: any, idx: number) => {
              return (
                <OddElement
                  key={idx}
                  idx={idx}
                  odd={itemOdd}
                  item={item}
                  market={market}
                  parentUrl={parentUrl}
                  marketLiquid={marketLiquid}
                />
              );
            })}
        </div>
      </div>
      {eitherOdd && eitherOdd?.length > 0 && (
        <Responsive upto="md">
          <div className={`grid grid-cols-2 gap-2 w-full mt-4 md:mt-6`}>
            <div className="flex flex-col items-center space-y-2">
              <span className="font-medium opacity-70 text-xs text-center">
                Either
              </span>
              {eitherOdd &&
                eitherOdd.map((itemOdd: any, idx: number) => {
                  return (
                    <OddElement
                      key={idx}
                      idx={idx}
                      odd={itemOdd}
                      item={item}
                      market={market}
                      parentUrl={parentUrl}
                      marketLiquid={marketLiquid}
                    />
                  );
                })}
            </div>
            {drawOdd && drawOdd?.length > 0 && (
              <div className="flex flex-col items-center space-y-2">
                <span className="font-medium opacity-70 text-xs text-center">
                  Draw
                </span>
                {drawOdd &&
                  drawOdd.map((itemOdd: any, idx: number) => {
                    return (
                      <OddElement
                        key={idx}
                        idx={idx}
                        odd={itemOdd}
                        item={item}
                        market={market}
                        parentUrl={parentUrl}
                        marketLiquid={marketLiquid}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </Responsive>
      )}
    </Fragment>
  );
};

export default OddType3;
