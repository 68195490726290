import { getAddressSimpleString } from "utils/blockchain";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { CHAINS } from "config";
import {
  LOCKDROP_ADDRESS_MAINNET,
  ZKUSD_ADDRESS_MAINNET,
} from "shared/constants";

const explorerUrl = CHAINS[324].blockScanUrl;

interface ContractModalProps {}
const ContractModal: React.FC<ContractModalProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setContractOpen = (e: any) => {
    setIsOpen(e.target.checked);
  };
  const copyAddress = (address: string) => {
    if (!address) return;
    navigator.clipboard.writeText(address);
    toast("Copied");
  };
  return (
    <>
      <input
        type="checkbox"
        id="contracts-view--modal"
        className="modal-toggle"
        onChange={(e) => {
          setContractOpen(e);
        }}
      ></input>
      <label
        htmlFor="contracts-view--modal"
        className={`modal modal-bottom sm:modal-middle ${
          isOpen ? "modal-open z-[999]" : "-z-[10] hidden"
        }`}
      >
        <label className="modal-box relative">
          <div className="modal-box--header">
            <h3 className="text-success">
              <i className="fa-solid fa-shield-check mr-2"></i>Trust, But Verify
            </h3>
            <label
              htmlFor="contracts-view--modal"
              className="btn btn-circle btn-sm btn-ghost absolute right-3 top-2"
            >
              <i className="fa-regular fa-xmark"></i>
            </label>
          </div>

          <div className="max-h-[calc(100vh-10rem)] min-h-[180px] overflow-y-auto p-6 pb-0">
            <div className="text-sm mb-2 leading-relaxed">
              Smart contracts transparency and security are at the core of
              Goal3.
            </div>

            <div className="text-sm mb-6 leading-relaxed">
              We have made our codebase open-source{" "}
              <a
                href="https://github.com/goal3-xyz/contracts"
                target="_blank"
                rel="noreferrer"
                className="text-primary border-b border-transparent hover:border-primary transition font-semibold"
              >
                available on GitHub
              </a>{" "}
              to enable community collaboration.
            </div>

            <div className="mt-4 bg-primary/10 rounded-lg px-4 py-3 text-sm">
              <div className="font-semibold">Smart Contracts on zkSync Era</div>

              {/* <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 uppercase tracking-wide">Bookmaker</span>
                <a href={`${explorerUrl}/address/${`0xD2cA21Df45479824F954a6e1759D436a57d63faF`}`} target="_blank" rel="noreferrer" className='text-primary border-b border-transparent hover:border-primary ml-2 mr-1 font-mono transition'>
                  {getAddressSimpleString(`0xD2cA21Df45479824F954a6e1759D436a57d63faF`, 4)}
                  <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
                </a>
              </div> */}
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 uppercase tracking-wide">
                  BookMaker V2
                </span>
                <a
                  href={`${explorerUrl}/address/0xE54710D6b9537582c77f137Bdd9eE1bFD610e11B`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary border-b border-transparent hover:border-primary ml-2 mr-1 font-mono transition"
                >
                  {getAddressSimpleString(
                    "0xE54710D6b9537582c77f137Bdd9eE1bFD610e11B",
                    4
                  )}
                  <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
                </a>
              </div>
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 uppercase tracking-wide">
                  Wager
                </span>
                <a
                  href={`${explorerUrl}/address/${`0x1F090f91EE09768ca36dcD52640F4a5eAe146555`}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary border-b border-transparent hover:border-primary ml-2 mr-1 font-mono transition"
                >
                  {getAddressSimpleString(
                    `0x1F090f91EE09768ca36dcD52640F4a5eAe146555`,
                    4
                  )}
                  <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
                </a>
              </div>
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 uppercase tracking-wide">
                  Bookie
                </span>
                <a
                  href={`${explorerUrl}/address/${`0xC523df658DBEc88Dc03Fb23a703BCBd7FFb5ea01`}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary border-b border-transparent hover:border-primary ml-2 mr-1 font-mono transition"
                >
                  {getAddressSimpleString(
                    `0xC523df658DBEc88Dc03Fb23a703BCBd7FFb5ea01`,
                    4
                  )}
                  <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
                </a>
              </div>
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 uppercase tracking-wide">
                  Event Manager
                </span>
                <a
                  href={`${explorerUrl}/address/${`0x116a4A5Ed4c7d5712E109D4188E17616D8E5784a`}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary border-b border-transparent hover:border-primary ml-2 mr-1 font-mono transition"
                >
                  {getAddressSimpleString(
                    `0x116a4A5Ed4c7d5712E109D4188E17616D8E5784a`,
                    4
                  )}
                  <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
                </a>
              </div>
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 uppercase tracking-wide">
                  Lockdrop
                </span>
                <a
                  href={`${explorerUrl}/address/${LOCKDROP_ADDRESS_MAINNET}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary border-b border-transparent hover:border-primary ml-2 mr-1 font-mono transition"
                >
                  {getAddressSimpleString(LOCKDROP_ADDRESS_MAINNET, 4)}
                  <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
                </a>
              </div>
            </div>

            <div className="mt-4 bg-primary/10 rounded-lg px-4 py-3 text-sm">
              <div className="font-semibold">Token Addresses</div>
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 tracking-wide">$zkUSD</span>
                <div className="flex items-baseline flex-shrink-0">
                  <a
                    href="#"
                    className="btn btn-xs btn-circle btn-ghost"
                    onClick={() => copyAddress(ZKUSD_ADDRESS_MAINNET)}
                  >
                    <i className="fa-regular fa-copy text-xs"></i>
                  </a>
                  <a
                    href={`${explorerUrl}/address/${ZKUSD_ADDRESS_MAINNET}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary border-b border-transparent hover:border-primary ml-2 mr-1 font-mono transition"
                  >
                    {getAddressSimpleString(ZKUSD_ADDRESS_MAINNET, 4)}
                    <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
                  </a>
                </div>
              </div>
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <span className="opacity-70 uppercase tracking-wide">$ZKG</span>
                <span className="opacity-70 font-medium">TBA after TGE</span>
              </div>
            </div>

            <div className="mt-4 bg-primary/10 rounded-lg px-4 py-3 text-sm">
              <div className="font-semibold">Audit Partner</div>
              <div className="flex justify-between items-center border-t border-base-content/5 mt-2 pt-2">
                <a
                  href="https://docs.goal3.xyz/audit"
                  target="_blank"
                  rel="noreferrer"
                  data-theme="dark"
                  className="flex items-center justify-center border border-base-content/20 bg-base-100/80 hover:bg-base-100 py-3 px-4 rounded-box transition"
                >
                  <img
                    alt={"PeckShield"}
                    className={"h-6 flex-shrink-0"}
                    src={`/logo/peckshield-logo.png`}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="p-6 flex justify-center items-center">
            <label
              htmlFor="contracts-view--modal"
              className="btn btn-md btn-block"
            >
              Close
            </label>
          </div>
        </label>
      </label>
    </>
  );
};

export default ContractModal;
