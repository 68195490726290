import { useEffect, useState } from "react";
import {
  QueryDocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { UserWagers } from "interfaces/firebase";
import { formatBigNumber } from "../common/utils";
import { useAccount } from "./useAccount";
import {
  userHistoryCollRef,
  userWagerCollRef,
} from "utils/firebase/firebaseDBRef";
interface BetStats {
  totalBet: number;
  totalWin: number;
  wagerHighEst: {
    amount: number;
    txHash: string[];
    outcomeIds: string[];
    wager_id: string;
  };
  betLastTime: {
    id: string | undefined;
    transactionTime: string | undefined;
  };
  totalCanClaim: number;
  totalWonBet: number;
  won_CanClaim: any[];
  betLatest: {
    amount: number;
    txHash: string[];
    transactionTime: string;
    wager_id: string;
  };
  totalResolving: number;
}
const BetStatsInit: BetStats = {
  totalBet: 0,
  totalWin: 0,
  betLastTime: {
    id: "",
    transactionTime: "",
  },
  totalWonBet: 0,
  wagerHighEst: {
    amount: 0,
    txHash: [],
    outcomeIds: [],
    wager_id: "",
  },
  totalCanClaim: 0,
  won_CanClaim: [],
  betLatest: {
    amount: 0,
    txHash: [],
    transactionTime: "",
    wager_id: "",
  },
  totalResolving: 0,
};
enum WagerStatus {
  OPEN = 0,
  LOST = 3,
  WON = 4,
  CANCELLED = 5,
}
interface WagerDocData {
  wager_id: string;
  user_id: string;
  txhash: Array<string>;
  status: WagerStatus;
  outcome_ids: Array<string>;
  bet_amount: number;
  amount: number;
}
function checkWagerData(wagerData: any): wagerData is WagerDocData {
  return (
    "wager_id" in wagerData &&
    "user_id" in wagerData &&
    "txhash" in wagerData &&
    "status" in wagerData &&
    "outcome_ids" in wagerData &&
    "bet_amount" in wagerData &&
    "amount" in wagerData &&
    wagerData.txhash.length &&
    wagerData.outcome_ids.length > 0 &&
    wagerData.bet_amount
  );
}
export const useBetStats = () => {
  const [betStats, setBetStats] = useState<BetStats>(BetStatsInit);
  const [loading, setLoading] = useState<boolean>(false);
  const { address } = useAccount();

  useEffect(() => {
    if (address) {
      setLoading(true);
      getUserStats(address).then(() => setLoading(false));
    }
  }, [address]);

  const getUserStats = async (address: string, retry: number = 1) => {
    const latestOrderRef: any = await getDocs(
      query(
        userHistoryCollRef(address),
        orderBy("transactionTime", "desc"),
        limit(1)
      )
    );

    const latestOrder = latestOrderRef.docs.length
      ? {
          id: latestOrderRef.docs[0].id,
          transactionTime: latestOrderRef.docs[0].data().transactionTime,
        }
      : { id: "", transactionTime: "" };

    const userWagerRef = await getDocs(query(userWagerCollRef(address)));

    if (!userWagerRef.empty) {
      const totalOrder = userWagerRef.docs.length;
      const userWager: UserWagers[] = userWagerRef.docs.map((doc: any) => {
        const data: any = doc.data();
        const wager: UserWagers = {
          amount: data.amount,
          created_at: data.created_at,
          wager_id: data.wager_id,
          isRedeemed: data.isRedeemed,
          outcomeIds: data.outcome_ids,
          redeemable: data.redeemable,
          txhash: data.txhash,
          status: data.status,
          redeemAmount: data.redeemAmount,
          id: ""
        };
        return wager;
      });
      const wagers_isnotClaimed: UserWagers[] = userWager.filter(
        (wager: UserWagers) =>
          (wager.status === 4 || wager.status === 5) &&
          (wager.redeemable || wager.amount) > 0
      );
      const totalCanClaim = wagers_isnotClaimed?.reduce(
        (acc, cur) => acc + (cur.redeemable || cur.amount),
        0
      );

      const wagerWon = userWager.filter((w) => w.status === 4);

      const HighWon = wagerWon.length
        ? wagerWon.reduce((acc, cur) =>
            (acc.redeemable || acc.amount || acc.redeemAmount) >
            (cur.redeemable || cur.amount || cur.redeemAmount)
              ? acc
              : cur
          )
        : {
            ...BetStatsInit.wagerHighEst,
            redeemAmount: 0,
            txhash: [],
          };
      setBetStats((prev: BetStats) => ({
        ...prev,
        betLastTime: latestOrder,
        totalBet: totalOrder,
        totalWonBet: wagerWon.length,
        totalCanClaim: formatBigNumber(totalCanClaim, true),
        won_CanClaim: wagers_isnotClaimed,
        wagerHighEst: {
          amount: HighWon.amount || HighWon.redeemAmount,
          outcomeIds: HighWon.outcomeIds,
          txHash: HighWon.txhash,
          wager_id: HighWon.wager_id,
        },
      }));
    }
  };

  return {
    betStats,
    loading,
  };
};
