import React, { Fragment } from "react";
import { isUndefined } from "lodash";
import { useThemeContext } from "contexts/themeContext";
import { moreNavigation } from "helpers/constants";
import FooterMini from "components/organisms/Footer/footer-mini";
import { getIcon } from "components/commons/icons";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import { saveItem } from "local-storage";
import { navigations } from "helpers/constants";
import slugs from "navigation/slugs";
import { ReactComponent as LogoSvg } from "assets/logo/logo-dark-goal3.svg";
import { ReactComponent as LogoSvgLight } from "assets/logo/logo-light-goal3.svg";
// import { ReactComponent as LogoSvgSm } from "assets/logo/logo-dark-sm-goal3.svg";
// import { ReactComponent as LogoSvgLightSm } from "assets/logo/logo-light-sm-goal3.svg";

// log mery christmas
// import { ReactComponent as LogoSvg } from "assets/logo/Goal3_xmas_full_logo_dark.svg";
// import { ReactComponent as LogoSvgLight } from "assets/logo/Goal3_xmas_full_logo_light.svg";

// import { ReactComponent as LogoSvgSm } from "assets/logo/Goal3_xmas_dark.svg";
// import { ReactComponent as LogoSvgLightSm } from "assets/logo/Goal3_xmas_light.svg";

import { useSnapshot } from "valtio";
import { categoryProxy } from "../../../proxy-state/categoryProxy";
import { userWagerProxy } from "../../../proxy-state/userWagerProxy";
import { leagueProxy } from "../../../proxy-state/leagueProxy";
import { Responsive } from "../Responsive";
import { Footer } from "components/shared/Footer";
import { Disclosure } from "@headlessui/react";
import _ from "lodash";
import { modalFunc } from "proxy-state/global";
import CS2 from "components/commons/icons/CS2";
import DOTA from "components/commons/icons/DOTA";
import { useAuth } from "contexts/authContext";

const pdNavigation = [
  {
    icon: "fa-duotone fa-wreath-laurel",
    text: "Sportsbook",
    href: `${window.location.origin}`,
    isNew: false,
    isActive: !window.location.pathname.includes('lottery') && !window.location.pathname.includes('x1000'),
    isDemo: false,
    isDisabled: false,
    isProd: true,
  },
  {
    icon: "fa-duotone fa-arrows-cross",
    text: "X1000",
    href: slugs.x1000,
    href2: "https://twitter.com/Goal3_xyz/status/1729100610040131634",
    isNew: false,
    isDemo: true,
    isDisabled: false,
    isProd: true,
  },
  {
    icon: "fa-duotone fa-slot-machine",
    text: "Lottery",
    href: slugs.lottery,
    isNew: true,
    isDemo: false,
    isProd: true,
    isDisabled: false,
  },
  {
    icon: "fa-duotone fa-coins",
    text: "Portal",
    href: "https://portal.goal3.xyz",
    target: "_blank",
    isNew: false,
  },
];

const pd2Navigation: any[] = [];

// import "/node_modules/flag-icons/css/flag-icons.min.css";
// Main Navigation
interface MainNavigationProps {
  collapse?: boolean;
  closeModal?: () => void;
}
export const MainNavigation: React.FC<MainNavigationProps> = ({
  collapse,
  closeModal,
}) => {
  const path = useLocation();
  const pathArr = path?.pathname?.split("/");
  const rootPath = pathArr[1];
  const catePath = pathArr.length > 1 ? pathArr[2] : "";
  const pathName = path.pathname;
  const { category, league } = useParams<{
    sports: string;
    category: string;
    league: string;
    event: string;
  }>();

  const [isShowAboutUs, setIsShowAboutUs] = React.useState<boolean>(false);
  const { token: isAuthenticated } = useAuth();
  const { publishedCategories } = useSnapshot(categoryProxy);
  const { publishedLeagues } = useSnapshot(leagueProxy);
  const { data: userWager } = useSnapshot(userWagerProxy);
  const { theme } = useThemeContext();
  // const navigator = useNavigate();
  const [showMore, setShowMore] = React.useState(true);
  // const [leaguesData, setLeaguesData] = React.useState<League[]>([]);
  const leaguesData = publishedLeagues.filter(
    (item) => item.featured && item.eventCount > 0
  );
  const wagersWonIsNotClaimed =
    userWager.filter(
      (w) =>
        (w.status === 4 || w.status === 5) &&
        !w.isRedeemed &&
        (w.amount > 0 || w.redeemable > 0)
    ).length || 0;

  const categories = publishedCategories.map((category) => {
    const eventCount = _.sumBy(
      publishedLeagues.filter((league) => category.id === league.category_id),
      "eventCount"
    );
    return {
      ...category,
      eventCount: eventCount,
      img: !category?.icon_classes ? category.id : "",
    };
  });
  const mainNavigation: any = [
    {
      subMenu: categories.filter((item: any) => item.eventCount > 0),
      name: "Sports",
      url_key: "sports",
      cate_id: "",
      local_key: "menu-sports",
      defaultOpen: false, //getItem("menu-sports"),
      icon: "fa-duotone fa-circle-ellipsis",
      url: slugs.sports,
      url_path: slugs.sports,
      current: false,
      soon: false,
    },
  ];

  let mainNav: any[] = [
    navigations.map((item: any) => {
      if (item.url === slugs.myBets) {
        return {
          ...item,
          isDisabled: isAuthenticated ? false : true,
          counter: wagersWonIsNotClaimed,
        };
      }
      return item;
    }),
    mainNavigation,
  ];
  if (leaguesData.length > 0) {
    mainNav = [
      navigations.map((item: any) => {
        if (item.url === slugs.myBets) {
          return {
            ...item,
            isDisabled: isAuthenticated ? false : true,
            counter: wagersWonIsNotClaimed,
          };
        }
        return item;
      }),
      [...leaguesData],
      mainNavigation,
    ];
  }

  // ClassName
  const mainNavClass = "flex flex-col w-full h-full bg-base-200";
  const navItemClass =
    "flex items-center justify-between text-base lg:text-sm w-full rounded-box transition cursor-pointer px-2 py-3 lg:py-2";
  const navItemNormalClass =
    "text-base-content/50 hover:text-base-content/100 hover:bg-base-100";
  const navItemActiveClass =
    "bg-base-100 font-semibold text-base-content hover:text-base-content hover:bg-base-100";

  // const subNavItemClass =
  //   "text-base-content/50 hover:text-base-content/100 text-xs flex items-center py-2 px-2";
  const subNavItemActiveClass =
    "bg-base-100/40 text-base-content hover:text-base-content hover:bg-base-100";
  if (isShowAboutUs) {
    return <Footer setFooterPage={setIsShowAboutUs} />;
  }

  return (
    <div className={`${mainNavClass}`}>
      {/* Logo */}
      <div className="flex justify-between mt-6 ml-6 mb-6">
        <div className="flex flex-shrink-0 items-center">
          <Link
            className="flex-shrink-0 relative"
            to={slugs.home}
            preventScrollReset={true}
          >
            {theme === "dark" ? (
              <LogoSvg className="w-32 h-8" />
            ) : (
              <LogoSvgLight className="w-32 h-8" />
            )}
          </Link>
        </div>
        {/* Modal Close on Mobile */}
        <Responsive upto="md">
          <button onClick={closeModal} className="btn btn-sm btn-circle btn-base200 mr-3 z-50">
            <i className="fa-regular fa-xmark text-xl"></i>
          </button>
        </Responsive>
        {/* //Modal Close on Mobile */}
      </div>
      

      {/* //Logo */}

      {/* Navigation */}
      <div className="flex-1 overflow-y-auto overflow-x-hidden">
        <div className={`grid gap-2 p-4`}>
          <Disclosure as="div" defaultOpen={true} className={``}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`px-3 w-full text-base-content/50 hover:text-primary flex items-center gap-2`}
                >
                  <div className="text-xs uppercase tracking-wide">
                    Sportsbook
                  </div>
                  <span className="text-xs">
                    {!open ? (
                      <i className="fa-solid fa-caret-right"></i>
                    ) : (
                      <i className="fa-solid fa-caret-down"></i>
                    )}
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="pt-3 space-y-2">
                  <ul className={`w-full space-y-2`}>
                    {mainNav.map((items: any, idx: number) => (
                      <Fragment key={idx}>
                        <ul className={`w-full space-y-2 `}>
                          {items?.map((item: any, idx: any) => (
                            <li className="relative" key={item?.name}>
                              <Link
                                onClick={closeModal}
                                to={`${item?.url || `/${item?.url_path}`}`}
                                className={`${navItemClass} ${
                                  path.pathname === `/${item?.url_path}` ||
                                  item?.url === path.pathname ||
                                  rootPath === item?.url_key ||
                                  (category && category === item?.url_key) ||
                                  (league && league === item?.url_key)
                                    ? navItemActiveClass
                                    : navItemNormalClass
                                } ${
                                  collapse
                                    ? "tooltip tooltip-right py-2 px-1 justify-center"
                                    : "px-2 py-3 lg:py-2"
                                }${item?.isDisabled ? " disabled" : ""}`}
                                preventScrollReset={true}
                                data-tip={item?.name}
                                
                              >
                                <div className={`flex w-full items-center`}>
                                  <span className="flex flex-shrink-0 w-6 items-center justify-center">
                                    {(item?.icon || item?.icon_classes) && (
                                      <>
                                        <i
                                          className={`text-lg leading-none ${
                                            item?.icon || item?.icon_classes
                                          }`}
                                        ></i>
                                      </>
                                    )}
                                    {item?.img && (
                                      <div className={`h6`}>
                                        {getIcon(item?.img ?? "")}
                                      </div>
                                    )}
                                  </span>
                                  <span
                                    className={`ml-3 max-w-[240px] lg:max-w-[120px] truncate`}
                                  >
                                    {item?.name}
                                  </span>
                                </div>
                                {!isUndefined(item?.counter) &&
                                  item?.counter !== 0 && (
                                    <span className="badge-noti">
                                      {item?.counter}
                                    </span>
                                  )}
                                {!collapse && item.eventCount && (
                                  <span className="mr-1 text-xs">
                                    {item.eventCount}
                                  </span>
                                )}
                              </Link>

                              <Disclosure
                                as="div"
                                className={`mt-2 ${
                                  !item?.subMenu?.length || collapse
                                    ? "hidden"
                                    : ""
                                }`}
                                defaultOpen={item?.defaultOpen}
                              >
                                {({ open }) => (
                                  <>
                                    {item?.local_key &&
                                      saveItem(item?.local_key, open)}
                                    <Disclosure.Button
                                      className={`btn btn-xs btn-circle absolute right-1 top-1.5 ${
                                        !open ? "" : ""
                                      }`}
                                    >
                                      {!open ? (
                                        <i className="fa-solid fa-caret-right transition-all"></i>
                                      ) : (
                                        <i className="fa-solid fa-caret-right rotate-90 transition-all"></i>
                                      )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="">
                                      <ul className="space-y-2">
                                        {item?.subMenu?.length &&
                                          item?.subMenu?.map(
                                            (subItem: any, idx: any) => {
                                              return (
                                                <Fragment key={idx}>
                                                  {!subItem?.soon && (
                                                    <li>
                                                      <Link
                                                        onClick={closeModal}
                                                        to={`/${item?.url_key}/${subItem?.url_key}`}
                                                        preventScrollReset={
                                                          true
                                                        }
                                                        className={`${navItemClass} ${
                                                          subItem?.url_key ===
                                                          catePath
                                                            ? subNavItemActiveClass
                                                            : navItemNormalClass
                                                        }
                                              ${
                                                subItem.soon
                                                  ? "opacity-50 cursor-not-allowed disabled"
                                                  : ""
                                              } 
                                            ${
                                              collapse
                                                ? "tooltip tooltip-right py-2 px-1 justify-center"
                                                : "p-2"
                                            }`}
                                                      >
                                                        <div
                                                          className={`flex w-full items-center  ${
                                                            collapse
                                                              ? "justify-center"
                                                              : ""
                                                          }`}
                                                        >
                                                          <span className="flex flex-shrink-0 w-6 items-center justify-center">
                                                            {subItem?.icon_classes && (
                                                              <>
                                                                <i
                                                                  className={`text-lg leading-none ${
                                                                    subItem?.icon ||
                                                                    subItem?.icon_classes
                                                                  }`}
                                                                ></i>
                                                              </>
                                                            )}
                                                            {subItem?.img && (
                                                              <div
                                                                className={`h6`}
                                                              >
                                                                {getIcon(
                                                                  subItem?.img ??
                                                                    ""
                                                                )}
                                                              </div>
                                                            )}
                                                          </span>
                                                          <span
                                                            className={`${
                                                              collapse
                                                                ? "sr-only hidden"
                                                                : "ml-3 max-w-[240px] lg:max-w-[120px] truncate"
                                                            }`}
                                                          >
                                                            {subItem?.name}
                                                          </span>
                                                          {!collapse &&
                                                            subItem.soon && (
                                                              <span className="badge badge-xs badge-outline ml-1">
                                                                soon
                                                              </span>
                                                            )}
                                                        </div>
                                                        {!collapse &&
                                                          subItem.eventCount && (
                                                            <span className="mr-1 text-xs">
                                                              {
                                                                subItem.eventCount
                                                              }
                                                            </span>
                                                          )}
                                                      </Link>
                                                    </li>
                                                  )}
                                                </Fragment>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </li>
                          ))}
                        </ul>
                        {idx < mainNav.length - 1 && (
                          <span className="h-[1px] bg-base-content/5 block mx-2"></span>
                        )}
                      </Fragment>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <div className={`grid gap-2 p-4`}>
          <Disclosure as="div" defaultOpen={true} className={``}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`px-3 w-full text-base-content/50 hover:text-primary flex items-center gap-2`}
                >
                  <div className="text-xs uppercase tracking-wide">
                    on Goal3
                  </div>
                  <span className="text-xs">
                    {!open ? (
                      <i className="fa-solid fa-caret-right"></i>
                    ) : (
                      <i className="fa-solid fa-caret-down"></i>
                    )}
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="pt-3 space-y-2">
                  <ul className={`w-full space-y-2`}>
                    {pdNavigation.map((item) => {
                      return (
                        <li className="relative" key={item.text}>
                          <a
                            href={item.href}
                            className={`${navItemClass} ${
                              item.isActive
                                ? navItemActiveClass
                                : navItemNormalClass
                            } px-2 py-3 lg:py-2 ${
                              item.isDisabled && "pointer-events-none"
                            }`}
                            target={item.target ?? "_self"}
                            data-tip={item.text}
                          >
                            <div
                              className={`flex w-full items-center  ${
                                collapse ? "justify-center" : ""
                              }`}
                            >
                              <span className="flex flex-shrink-0 w-6 items-center justify-center">
                                <i
                                  className={`text-lg leading-none ${item.icon}`}
                                ></i>
                              </span>
                              <span
                                className={`${
                                  collapse
                                    ? "sr-only"
                                    : "ml-3 max-w-[240px] lg:max-w-[120px] truncate"
                                }`}
                              >
                                {item.text}
                              </span>
                            </div>
                            {!collapse && item.isNew ? (
                              <span className="badge-noti">New</span>
                            ) : (
                              ""
                            )}
                            {!collapse && item.isDemo ? (
                              <span className="badge-noti">Demo</span>
                            ) : (
                              ""
                            )}
                          </a>
                        </li>
                      );
                    })}
                    {pd2Navigation.length > 0 &&
                      pd2Navigation?.map((item: any) => {
                        return (
                          <li
                            className={`relative ${showMore ? "hidden" : ""}`}
                            key={item.text}
                          >
                            <Link
                              to={item.href}
                              target={item.target}
                              className={`${navItemClass} ${
                                item?.current
                                  ? navItemActiveClass
                                  : navItemNormalClass
                              } px-2 py-3 lg:py-2`}
                              data-tip={item.text}
                            >
                              <div className={`flex w-full items-center`}>
                                <span className="flex flex-shrink-0 w-6 items-center justify-center">
                                  <i className={`text-lg ${item.icon}`}></i>
                                </span>
                                <span
                                  className={`ml-3 max-w-[240px] lg:max-w-[120px] truncate`}
                                >
                                  {item.text}
                                </span>
                              </div>
                              {item.isSoon ? (
                                <span className="badge-noti">soon</span>
                              ) : (
                                ""
                              )}
                            </Link>
                          </li>
                        );
                      })}
                    {pd2Navigation.length > 0 && (
                      <>
                        <li
                          className={`mt-2 relative text-center ${
                            showMore ? "" : ""
                          }`}
                        >
                          <button
                            className="btn btn-md lg:btn-sm btn-outline btn-outline-neutral text-xs no-animation btn-base200 btn-block"
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore ? (
                              <>
                                <span className="">Show More</span>{" "}
                                <i className="fa-solid fa-angle-down ml-2"></i>
                              </>
                            ) : (
                              <>
                                <span className="">Show Less</span>{" "}
                                <i className="fa-solid fa-angle-down rotate-180 ml-2"></i>
                              </>
                            )}
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <div className={`grid gap-2 p-4`}>
          <Disclosure as="div" defaultOpen={true} className={``}>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={`px-3 w-full text-base-content/50 hover:text-primary flex items-center gap-2`}
                >
                  <div className="text-xs uppercase tracking-wide">More</div>
                  <span className="text-xs">
                    {!open ? (
                      <i className="fa-solid fa-caret-right"></i>
                    ) : (
                      <i className="fa-solid fa-caret-down"></i>
                    )}
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="pt-3 space-y-2">
                  <ul className={`w-full space-y-2`}>
                    {moreNavigation.length > 0 &&
                      moreNavigation?.map((item: any) => {
                        const active = pathName === item.href;
                        return (
                          <li className="relative" key={item.name}>
                            <a
                              href={item.href}
                              className={`${navItemClass} ${
                                active ? navItemActiveClass : navItemNormalClass
                              }`}
                              data-tip={item.name}
                            >
                              <div className={`flex w-full items-center`}>
                                <span className="flex flex-shrink-0 w-6 items-center justify-center">
                                  <i className={`text-lg ${item.icon}`}></i>
                                </span>
                                <span
                                  className={`ml-3 max-w-[240px] lg:max-w-[120px] truncate`}
                                >
                                  {item.name}
                                </span>
                              </div>
                              {item.isNew ? (
                                <span className="badge-noti">New</span>
                              ) : (
                                ""
                              )}
                            </a>
                          </li>
                        );
                      })}
                    <Responsive from={"lg"}>
                      <li className="relative dropdown dropdown-right dropdown-end w-full">
                        <button
                          tabIndex={0}
                          className={`${navItemClass} ${navItemNormalClass} w-full`}
                          onClick={() =>
                            modalFunc.setModalOpen("isFooterOpen", true)
                          }
                          data-tip="About Us"
                        >
                          <div className={`flex w-full items-center`}>
                            <span
                              className={`flex flex-shrink-0 w-6 items-center justify-center`}
                            >
                              <i
                                className={`text-lg fa-duotone fa-circle-ellipsis`}
                              ></i>
                            </span>
                            <span
                              className={`ml-3 max-w-[240px] lg:max-w-[120px] truncate`}
                            >
                              About Us
                            </span>
                          </div>
                        </button>
                        {/* <div tabIndex={0} className="dropdown-content dropdown-menu-content">
														<Footer />
													</div> */}
                      </li>
                    </Responsive>
                    <Responsive upto={"md"}>
                      <li className="relative w-full">
                        <button
                          tabIndex={0}
                          className={`${navItemClass} ${navItemNormalClass} w-full`}
                          data-tip="About Us"
                          onClick={(e) => {
                            setIsShowAboutUs(true);
                          }}
                        >
                          <div className={`flex w-full items-cente`}>
                            <span
                              className={`flex flex-shrink-0 w-6 items-center justify-center`}
                            >
                              <i
                                className={`text-lg fa-duotone fa-circle-ellipsis`}
                              ></i>
                            </span>
                            <span
                              className={`ml-3 max-w-[240px] lg:max-w-[120px] truncate`}
                            >
                              About Us
                            </span>
                          </div>
                          <span className="flex items-center">
                            <i className="fa-duotone fa-angle-right"></i>
                          </span>
                        </button>
                      </li>
                      <li className="relative w-full">
                        <label
                          tabIndex={0}
                          className={`${navItemClass} ${navItemNormalClass} w-full`}
                          data-tip="About Us"
                          htmlFor="contracts-view--modal"
                        >
                          <div className={`flex w-full items-cente`}>
                            <span
                              className={`flex flex-shrink-0 w-6 items-center justify-center`}
                            >
                              <i
                                className={`text-lg fa-duotone fa-shield-check`}
                              ></i>
                            </span>
                            <span
                              className={`ml-3 max-w-[240px] lg:max-w-[120px] truncate`}
                            >
                              Official Contracts
                            </span>
                          </div>
                          <span className="flex items-center">
                            <i className="fa-duotone fa-angle-right"></i>
                          </span>
                        </label>
                      </li>
                    </Responsive>
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      {/* //Navigation */}
      <div className="flex flex-col mx-4 py-6 lg:border-t border-base-content/10">
        <FooterMini />
      </div>
    </div>
  );
};
