import { proxy } from "valtio";
import { firebaseDb } from 'utils/firebase/config2';
import { getConfigurationBookmakerDocRef } from 'utils/firebase/firebaseDBRef';
import { onSnapshot } from "firebase/firestore";

let unsubscribes: any[] = [];
class LiquidProxy {
  liquidLimit: number = Number(process.env.NEXT_PUBLIC_LIQUID_LIMIT || 0);
  isLoading: boolean = false;
  maxAmount: number = Number(process.env.NEXT_PUBLIC_MAX_AMOUNT || 0);
  sensitivity: number = Number(process.env.NEXT_PUBLIC_SENSITIVITY || 100000);
  subscribed: boolean = false;

  load() {
    if (this.subscribed) return;
    this.subscribed = true;
    this.isLoading = true;
    const ref = getConfigurationBookmakerDocRef(firebaseDb);
    unsubscribes.push(
      onSnapshot(ref, (doc) => {
        this.isLoading = false;
        if(doc.exists()){
          const data = doc.data();
          this.liquidLimit = Number(data?.liquidLimit) || 0;
          this.maxAmount = Number(data?.maxAmount) || 0;
          this.sensitivity = Number(data?.sensitivity) || 0;
        }
      })
    );
  }
  unsubscribeDb() {
    this.subscribed = false;
    for (const unsubscribe of unsubscribes) {
      if (unsubscribe) unsubscribe();
    }
  }
}
export const liquidProxy = proxy(new LiquidProxy());
