import BgVideo from "assets/wallpapers/soccer.mp4";
export const ReOpenPWA = ({ version = "1.0.0" }: { version?: string }) => {
  return (
    <div
      data-theme="dark"
      className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
    >
      <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen bg-cover">
        <video
          className="object-cover w-full h-screen"
          autoPlay
          playsInline
          loop
          muted
        >
          {/* <source type="video/webm" src={`/wallpapers/dark/waves.webm`} /> */}
          <source type="video/webm" src={BgVideo} />
        </video>
      </div>

      <div className="main-layout">
        <div className="w-full h-[calc(var(--vh,1vh)*100)]  flex items-center justify-center flex-col z-0 relative">
          <div className="mx-auto w-full max-w-screen-sm md:max-w-sm text-center p-6 pb-8 lg:p-0">
            <div className="flex flex-col gap-6">
              <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center">
                <i className="fa-duotone fa-rocket-launch icon-primary text-3xl"></i>
              </div>
              <h2 className="text-2xl font-semibold">New Version Available</h2>
              <div className="text-base leading-relaxed">
                Goal3 App {version}
                <br /> Update to continue using the app.
              </div>
            </div>

            <div className="mt-12 ">
              <div className="bg-success/20 text-base-content rounded-box px-2 py-3 w-3/4 mx-auto">
                <span className="text-base">Close the app and open again!</span>
              </div>
              {/* <label htmlFor="newversion--modal" className="mt-2 btn btn-ghost w-2/3">
									<span className="text-base">What's new?</span>
								</label> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
