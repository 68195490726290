import { proxy, subscribe } from "valtio";
import { onSnapshot, query, where } from "firebase/firestore";
import { leagueRef } from "../utils/firebase/firebaseDBRef";
import { League } from "../interfaces/firebase";
import _ from "lodash";

let unsubscribe: any;

class LeagueProxy {
  isLoading: boolean = false;
  data: League[] = [];
  subscribed: boolean = false;

  load() {
    if (this.subscribed) return;
    this.subscribed = true;
    this.isLoading = true;
    const ref = leagueRef();
    const _query = query(ref, where("status", "==", "published"));
    unsubscribe = onSnapshot(_query, (snapshot) => {
      this.isLoading = false;
      this.data = [];
      snapshot.docs.forEach((doc) => {
        this.data.push({
          id: doc?.id,
          ...doc?.data(),
          url_path: `sports/${doc.data()?.category_id}/${doc.id}`,
          eventCount: doc?.data()?.eventCount ?? 0,
        } as League);
      });
    });
  }

  get publishedLeagues(): League[] {
    return _.orderBy(
      this.data.filter((item) => item.status === "published"),
      ["ordering"],
      ["asc"]
    );
  }

  unsubscribeDb() {
    if (unsubscribe) {
      unsubscribe();
      this.subscribed = false;
    }
  }
}

export const leagueProxy = proxy(new LeagueProxy());

subscribe(leagueProxy.data, (state) => {
  console.log("leagueProxy.publishedLeagues", state);
});
