import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const pageNav = [
  { name: "Introduction", href: "#intro", current: true },
  { name: "Getting Started", href: "#started", current: false },
  { name: "Referral code & referral link", href: "#code", current: false },
  { name: "Tier system", href: "#tier", current: false, hasSub: false },
  { name: "Rewards", href: "#rewards", current: false },
];

const subpageNav1 = [
  { name: "Sub-pagenav #1", href: "#", current: false },
  { name: "Sub-pagenav #2", href: "#", current: false },
  { name: "Sub-pagenav #3", href: "#", current: false },
];

const navItemClass = "py-2 flex items-center";
const navItemNormalClass = "text-base-content/70 hover:text-primary";
const navItemActiveClass = "text-primary";
interface ReferralProps {
  menuNav: string;
  setMenuNav: React.Dispatch<React.SetStateAction<string>>;

}
export const ReferralEvent: React.FC<ReferralProps> = (props) => {
  const { menuNav, setMenuNav } = props;
  const location = useLocation();
  const urlPath = location.pathname
  useEffect(() => {
    const path = urlPath.split('#')[1];
    if (path) {
      setMenuNav(`#${path}`);
    } else {
      setMenuNav("#intro")
    }
  }, [urlPath])

  return (
    <>
      <div className="container-lg h-full">
        <div className="flex h-full">

          {/* Content */}
          <div className="flex-1 lg:pr-20">

            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl">
                Referral Program
              </h1>
              <div className="timerRoot inline-flex text-base bg-success text-success-content border border-base-content/5 px-3 py-1 rounded shadow">
                <div className="uppercase tracking-wide text-sm">
                  Lifetime Event
                </div>
              </div>
            </div>

            <div className="mb-12">
              <img alt="" className="object-cover flex-1 rounded-box" src="/event-thumbs/referral_program.svg" />
            </div>

            {/* <div id="intro" className="mb-8 text-sm anchor">
              <h5 className="uppercase py-4 sr-only">Quick Info</h5>
              <div className="divide-y divide-base-content/10">
                <div className="flex items-center py-4">
                  <span className="opacity-70 w-40">Total Prize</span>
                  <div className="flex items-center">
                    <span className="text-base-content font-medium">5,000,000 $ZKG</span>
                    <span className="text-base-content font-medium opacity-70 mx-1">&amp;</span>
                    <span className="text-base-content font-medium">$40,000</span>
                  </div>
                </div>
                <div className="flex items-center py-4">
                  <span className="opacity-70 w-40">Provider</span>
                  <div className="flex items-center">
                    <img alt="" title="Provider: Goal3" className="h-5 inline-block" src="/logo/logo-dark-goal3.svg" />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="type">
              <div id="intro" className="anchor">
                <p>The Goal3 Referral program is designed to incentivize and reward loyal participants who help spread the word about our sportsbook. By referring friends and colleagues to Goal3, participants can earn $ZKG rebates sustainably.</p>
              </div>

              <div id="started" className="anchor">
                <h2>Getting started</h2>
                <p>Before joining the Referral Program, you need to get access to the sportsbook under someone else’s invite.</p>
              </div>

              <div id="code" className="anchor">
                <h2>Referral code & referral link</h2>
                <p>To activate your Referral Code, you need to wager a minimum $100 on the sportsbook.</p>
                <p>When someone enters your code or clicks on your referral link and connects their wallet for the first time, they become your referee.</p>
                <p>Wager volume of all referees will earn you weekly rewards in $ZKG tokens.</p>
                <p>If you achieve a total 10,000 zkUSD in referred volume, your referral code is eligible to be upgraded to a premium one.</p>
                <p>Premium Codes give extra $ZKG rewards, customizable options, and additional lossback feature.</p>
              </div>

              <div id="tier" className="anchor">
                <h2>Tier system</h2>
                <p>Our referral program uses a tier system to incentivize our users to refer more people to our sportsbook.</p>
                <p>The more people you refer, the higher your tier and the more rewards you can earn.</p>
                <div className="img">
                  <img alt="" className="object-cover flex-1 rounded-box" src="/articles/ref_program_1.jpg" />
                </div>
              </div>

              <div id="rewards" className="anchor">
                <h2>Rewards</h2>
                <p>Rewards are distributed weekly.</p>
                <p>Rewards are paid in $ZKG tokens and are calculated based on the volume generated by your referred users.</p>
                <p>The amount of $ZKG tokens you earn depends on a number of parameters, with the formula being:</p>
                <div className="code">
                  $ZKG rewards = IV * BR * FR <br />
                  with  <br />
                  IR = IV/TSV*100
                </div>
                <p>Refer to the below page for abbreviations:</p>
                <div className="img">
                  <img alt="" className="object-cover flex-1 rounded-box" src="/articles/ref_program_2.jpg" />
                </div>
                <p>Visit <Link to="https://www.canva.com/design/DAFgbpKbJLU/CDra5hNAEZXsMh8Qya6v9A/view" target="_blank" rel="noreferrer" >Referral Program Presentation</Link> for further information.</p>
              </div>

            </div>
          </div>
          {/* //Content */}

          {/* Page Nav */}
          <div className="w-72 sticky top-40 self-start text-sm hidden lg:block">
            <div className="">
              <h5 className="mb-4 uppercase">On This Page</h5>
              <nav>
                <ul>
                  {pageNav.map((item) => (
                    <li className="relative" key={item.name}>
                      <Link
                        to={item.href}
                        className={`${navItemClass} font-semibold ${menuNav === item?.href
                          ? navItemActiveClass
                          : navItemNormalClass
                          }
                        `}
                        onBlur={() => {
                          setMenuNav(item.href)
                        }}
                      >
                        <span className={``}>
                          {item.name}
                        </span>
                      </Link>
                      {item.hasSub &&
                        <>
                          <ul className="ml-2">
                            {subpageNav1.map((item: any, idx: any) => (
                              <li key={idx}>
                                <a
                                  href={item.href}
                                  className={`${navItemClass} ${menuNav === item?.href
                                    ? navItemActiveClass
                                    : navItemNormalClass
                                    }
                                  `}
                                  onBlur={() => {
                                    setMenuNav(item.href)
                                  }}
                                >
                                  <i className="fa-sharp fa-regular fa-angle-right mr-2"></i>
                                  <span className={``}>
                                    {item.name}
                                  </span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </>
                      }
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          {/* //Page Nav */}
        </div>
      </div>
    </>
  );
}
