import { en, kr } from "./base";

export const flattenObject = (inputObj: { [key: string]: any }) => {
  const outputObj: { [key: string]: string } = {};

  for (const i in inputObj) {
    if (inputObj.hasOwnProperty(i)) {
      if (typeof inputObj[i] === "object") {
        const flatObject = flattenObject(inputObj[i]);
        for (const x in flatObject) {
          if (flatObject.hasOwnProperty(x)) {
            outputObj[`${i}.${x}`] = flatObject[x];
          }
        }
      } else {
        outputObj[i] = inputObj[i];
      }
    }
  }
  return outputObj;
};

const language: { [key: string]: any } = {
  kr: flattenObject(kr),
  en: flattenObject(en),
};

export default language;
