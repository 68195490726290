import React from "react";
import {
  IMyBetLayout,
  ILayoutType,
  IPageType,
  IWagerStatus,
} from "shared/enum";
import { useSnapshot } from "valtio";
import { BetFilterProxy } from "proxy-state/betting/myBetFilter";
import { userWagerProxy } from "proxy-state/userWagerProxy";

const Toolbar: React.FC = () => {
  const {
    layoutType,
    setLayoutType,
    pageType,
    setPageType,
    MyBetLayout,
    setMyBetLayout,
    wagerStatus,
    setWagerStatus,
  } = useSnapshot(BetFilterProxy);

  const { data: userWager } = useSnapshot(userWagerProxy);
  const wagerWonCounting = userWager.filter( (w: any) => (w?.status === 4 || w?.status === 5) && Number(w?.amount) > 0 ).length;
  const setMybetLayoutFunc = (layout: IMyBetLayout) => {
    BetFilterProxy.resetFilter();
    setMyBetLayout(layout);
  };
  return (
    <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-4">
      <div className="flex items-center gap-2 pr-2">
        <button
          className={`btn btn-sm  ${
            MyBetLayout === IMyBetLayout.BETS
              ? "font-semibold border-primary text-primary bg-transparent hover:bg-transparent hover:border-primary"
              : "font-normal btn-ghost text-base-content/50"
          }`}
          onClick={() => setMybetLayoutFunc(IMyBetLayout.BETS)}
        >
          <i className="fa-solid fa-receipt mr-2"></i>
          <span className={``}>{IMyBetLayout.BETS}</span>
        </button>
        <button
          className={`btn btn-sm  ${
            MyBetLayout === IMyBetLayout.HISTORY
              ? "font-semibold border-primary text-primary bg-transparent hover:bg-transparent hover:border-primary"
              : "font-normal btn-ghost text-base-content/50"
          }`}
          onClick={() => setMybetLayoutFunc(IMyBetLayout.HISTORY)}
        >
          <i className="fa-solid fa-clock-rotate-left mr-2"></i>
          <span className={``}>{IMyBetLayout.HISTORY}</span>
        </button>
        {/* <i className="fa-duotone fa-receipt"></i> */}
        {/* <h1>My Bets</h1> */}
      </div>

      <div className="flex items-center justify-between gap-2 w-full lg:w-auto flex-shrink-0">
        {MyBetLayout === IMyBetLayout.BETS && (
          <div className="bg-base-200 rounded-box flex items-center gap-1">
            <button
              onClick={() => setWagerStatus(IWagerStatus.ALL)}
              className={`btn btn-sm${
                wagerStatus === IWagerStatus.ALL
                  ? " text-base-content bg-neutral hover:bg-neutral-focus"
                  : " btn-ghost text-base-content/50"
              }`}
            >
              <span className={``}>All</span>
            </button>
            <button
              onClick={() => setWagerStatus(IWagerStatus.OPEN)}
              className={`btn btn-sm${
                wagerStatus === IWagerStatus.OPEN
                  ? " text-base-content bg-neutral hover:bg-neutral-focus"
                  : " btn-ghost text-base-content/50"
              }`}
            >
              <span className={``}>Open</span>
            </button>
            <button
              onClick={() => setWagerStatus(IWagerStatus.WON)}
              className={`btn btn-sm${
                wagerStatus === IWagerStatus.WON
                  ? " text-base-content bg-neutral hover:bg-neutral-focus"
                  : " btn-ghost text-base-content/50"
              }`}
            >
              <span className={``}>Won</span>
              {wagerWonCounting > 0 && (
                <span className="relative flex h-2.5 w-2.5 justify-center items-start ml-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-accent"></span>
                </span>
              )}
            </button>
            <button
              onClick={() => setWagerStatus(IWagerStatus.LOST)}
              className={`btn btn-sm${
                wagerStatus === IWagerStatus.LOST
                  ? " text-base-content bg-neutral hover:bg-neutral-focus"
                  : " btn-ghost text-base-content/50"
              }`}
            >
              <span className={``}>Lost</span>
            </button>
          </div>
        )}
        {MyBetLayout === IMyBetLayout.HISTORY && (
          <div className="bg-base-200 rounded-box flex items-center gap-1">
            <button
              onClick={() => setPageType(IPageType.ORDER)}
              className={`btn btn-sm${
                pageType === IPageType.ORDER
                  ? " text-base-content bg-neutral hover:bg-neutral-focus"
                  : " btn-ghost text-base-content/50"
              }`}
            >
              <span className={``}>Orders</span>
            </button>
            <button
              onClick={() => setPageType(IPageType.CLAIMS)}
              className={`btn btn-sm${
                pageType === IPageType.CLAIMS
                  ? " text-base-content bg-neutral hover:bg-neutral-focus"
                  : " btn-ghost text-base-content/50"
              }`}
            >
              <span className={``}>Claims</span>
            </button>
          </div>
        )}

        <div className="flex items-center gap-3">
          {MyBetLayout === IMyBetLayout.HISTORY && (
            <div
              className={`flex items-center${
                pageType === IPageType.CLAIMS ? " disabled" : ""
              }`}
            >
              <span className="sr-only">View as:</span>
              <div className="bg-base-200 rounded-box flex items-center gap-1">
                <button
                  onClick={() => setLayoutType(ILayoutType.GRID)}
                  className={`btn btn-sm${
                    layoutType === ILayoutType.GRID
                      ? " text-base-content bg-neutral hover:bg-neutral-focus"
                      : " btn-ghost text-base-content/50"
                  }`}
                >
                  <i className="fa-solid fa-grid-2"></i>
                </button>
                <button
                  onClick={() => setLayoutType(ILayoutType.LIST)}
                  className={`btn btn-sm${
                    layoutType === ILayoutType.LIST
                      ? " text-base-content bg-neutral hover:bg-neutral-focus"
                      : " btn-ghost text-base-content/50"
                  }`}
                >
                  <i className="fa-solid fa-list"></i>
                </button>
              </div>
            </div>
          )}
          <div className="flex items-center">
            <label
              htmlFor="bets-filter--modal"
              tabIndex={0}
              className="btn btn-sm btn-base200 gap-3"
            >
              <span className="">
                <i className="fa-solid fa-bars-filter"></i>
              </span>
              <span className="text-sm">All</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Toolbar;
