import React, { Fragment } from "react";
import OddElements from "../OddElements";
import EventStatus from "./eventStatus";
import { getTitleMatches } from "../Sports/utils/sports.utils";
import { Link } from "react-router-dom";
import { ReactComponent as Basketball } from "assets/icons/basketball.svg";
import { ReactComponent as Baseball } from "assets/icons/baseball-bat-ball.svg";
import mma from "assets/icons/hand-fist.png";
import { ReactComponent as Soccer } from "assets/icons/futbol.svg";
import { ReactComponent as Csgo2 } from "assets/icons/CSGO.svg";
import { getIcon } from "components/commons/icons";
import { Img } from "components/commons/Img";
import DOTA from "components/commons/icons/DOTA";



const MarketSort = ({ item, market, parentUrl, category, eventFilter }: any) => {
  const [isValidImage, setIsValidImage] = React.useState<{
    [key: string]: boolean;
  }>({});

  const [isValidImageAway, setIsValidImageAway] = React.useState<{
    [key: string]: boolean;
  }>({});
  const isNameInView = [
    "match_result_and_total",
    "winning_margin",
    "match_handicap_and_total",
  ].includes(eventFilter);
  return (
    <Fragment>
      <div className={`flex flex-col py-4`}>
        <div className={`match`}>
          <div className="match-header">
            <div className="isolate flex -space-x-3 relative top-0.5 mr-4">
              <div
                className={`relative w-8 h-8 z-[1] rounded-full bg-base-100 flex justify-center items-center border-base-100 ring-1 ring-base-content/10 text-base-content/50 ${Number(item.time_status) === 1 ? "shadow" : ""
                  }`}
              >
                {item?.home?.image_id && (
                  <img
                    className={`${!isValidImage[item?.home?.image_id]
                      ? "hidden"
                      : "h-6 w-6 rounded-full"
                      }`}
                    src={`https://assets.b365api.com/images/team/b/${item?.home?.image_id}.png`}
                    alt={market?.home?.name}
                    onLoad={(event: any) => {
                      if (event?.target?.height > 1) {
                        setIsValidImage({
                          ...isValidImage,
                          [item?.home?.image_id]: true,
                        });
                      }
                    }}
                  />
                )}

                {(!isValidImage[item?.home?.image_id] ||
                    !item?.home?.image_id) && (
                    <>
                      {getIcon(category.toString())}
                      {/* {category.toString() === "mma" && <Img src={mma} alt="mma" className="h-6 w-6 rounded-full" />}
                      {category.toString() === "soccer" && <Soccer className="h-6 w-6 rounded-full" />}
                      {category.toString() === "baseball" && <Baseball className="h-6 w-6 rounded-full" />}
                      {category.toString() === "basketball" && <Basketball className="h-6 w-6 rounded-full" />}
                      {category.toString() === "cs2" && <Csgo2 className="h-6 w-6 rounded-full" />}
                    {category.toString() === "dota2" && (<> <div className="h-6 w-6 rounded-full bg-transparent hover:bg-base-100"><DOTA /></div> </>)} */}
                    </>

                  )}
              </div>

              <div
                className={`relative w-8 h-8 z-0 rounded-full bg-base-100 flex justify-center items-center border-base-100 ring-1 ring-base-content/10 text-base-content/50 ${Number(item.time_status) === 1 ? "shadow" : ""
                  }`}
              >
                {item?.away?.image_id && (
                  <img
                    className={`${!isValidImageAway[item?.away?.image_id]
                        ? "hidden"
                        : "h-6 w-6 rounded-full"
                      }`}
                    src={`https://assets.b365api.com/images/team/b/${item?.away?.image_id}.png`}
                    alt={market?.away?.name}
                    onLoad={(event: any) => {
                      if (event?.target?.height > 1) {
                        setIsValidImageAway({
                          ...isValidImageAway,
                          [item?.away?.image_id]: true,
                        });
                      } 
                    }}
                  />
                )}

                {(!isValidImageAway[item?.away?.image_id] ||
                    !item?.away?.image_id) && (
                  <>
                    {getIcon(category.toString())}
                    {/* {category.toString() === "mma" && <Img src={mma} alt="mma" className="h-6 w-6 rounded-full" />}
                    {category.toString() === "soccer" && <Soccer className="h-6 w-6 rounded-full" />}
                    {category.toString() === "baseball" && <Baseball className="h-6 w-6 rounded-full" />}
                    {category.toString() === "basketball" && <Basketball className="h-6 w-6 rounded-full" />}
                    {category.toString() === "cs2" && <Csgo2 className="h-6 w-6 rounded-full" />}
                    {category.toString() === "dota2" && (<> <div className="h-6 w-6 rounded-full bg-transparent hover:bg-base-100"><DOTA /></div> </>)} */}
                  </>
                  )}
              </div>
            </div>
            <div>
              <EventStatus
                eventDateTime={item?.time}
                time_status={Number(item.time_status)}
              />
              <h5 className={`text-sm mt-1 truncate max-w-[60vw]`}>
                {getTitleMatches({
                  home: item.home.name,
                  away: item.away.name,
                  icon_classes: item.icon_classes,
                  matches_url: `${parentUrl}/${item?.url_key}`,
                })}
              </h5>
            </div>
          </div>
          <div className="flex justify-between flex-1 w-full">
            {market.length > 0 &&
              market?.map((itemOdds: any, idx: any) => {
                return (
                  <OddElements
                    key={idx}
                    idx={idx}
                    odds={itemOdds?.odds}
                    item={itemOdds}
                    market={item}
                    parentUrl={`${parentUrl}/${item?.url_key}`}
                    type={"sort"}
                    eventFilter={eventFilter}
                  />
                );
              })}
            {!market.length && (
              <div className="flex justify-center gap-1 p-3 items-center w-full bg-base-200 rounded-box text-xs text-base-content/50">
                The match doesn't have this market
              </div>
            )}
            <div>
              {market?.length > 0 && isNameInView && (
                <span className="font-medium opacity-0 text-xs text-center hidden md:inline-block">
                  view
                </span>
              )}
              <Link
                className="match-morebtn text-base-content !text-opacity-70 hover:!text-opacity-100 text-xs"
                to={`${parentUrl}/${item?.url_key}`}
              >
                <span className="">+{item.allMarkets.length}</span>
                <i className="md:!hidden fa-solid fa-angle-right ml-2"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MarketSort;
