import React from "react";
import ContractModal from "./contract";
import OrderSlipModal from "./OrderSlip-Modal";
// import ChatModal from "./ChatModal";
import PremiumCodeModal from "./PremiumCodeModal";
import { NotificationModal } from "./NotificationModal";
import { ProfileModal } from "./ProfileModa";
import BetsFilterModal from "./bets-filter-modal";
import AboutUsModal from "./about-us-modal";
import { Responsive } from "components/commons";
import OrderDoneModal from "./receipt-order-modal";

interface ModalProps {}
const GlobalModal: React.FC<ModalProps> = () => {
  return (
    <div className="global-modal">
      <ContractModal />
      <OrderSlipModal />
      {/* <ChatModal /> */}
      <PremiumCodeModal />
      <NotificationModal />
      <ProfileModal />
      <BetsFilterModal />
      <OrderDoneModal />
      <Responsive from={"lg"}>
        <AboutUsModal />
      </Responsive>
    </div>
  );
};
export default GlobalModal;
