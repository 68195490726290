import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import CampaignList from "../page";

const routes: AppRouteType[] = [
	{
		path: slugs.campaign,
		component: CampaignList,
		container: MainLayout,
		isAuthNeeded: false,
	},
];

export default routes;
