import { BigNumber } from "ethers";
import { AMM } from "functions/amm";

const parseOutcomeId = (item: any) => {
  return AMM.parseOutcomeId(BigNumber.from(item)).eventId.toString();
};

export const getEventIdsFromOutcomeIdsV2 = (data: string[]) => {
  return data.map((o: any) => parseOutcomeId(o));
};
