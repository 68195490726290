import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DocumentData,
  DocumentSnapshot,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
} from "firebase/firestore";
import { ConvertString2BN, floatFormat } from "common/utils";
import { getAddressSimpleString } from "utils/blockchain";
import moment from "moment";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import AcceptedToken from "components/commons/TokenIcon";
import { ExplorerURLs } from "contracts/config";
import { DECIMALS } from "functions/betUtils";
import { historyOrderCollRef } from "utils/firebase/firebaseDBRef";
import { PAGINATION_SIZE_RECENT_BETS } from "shared/constants";
import { Pagination } from "components/organisms/Pagination/Pagination";
import { isMobile } from "react-device-detect";
import { shortTime } from "common/utils";

interface RecentBetProps {}

interface RecentTableProps {
  dataBet: BetData[];
  // setDataBet: React.Dispatch<React.SetStateAction<BetData[]>>;
  // dataChanges?: QueryDocumentSnapshot<DocumentData>[] | null;
  // isLoad: boolean;
  // isPrev: boolean;
  // isNext: boolean;
  // loadMore: boolean;
  // lastItem: boolean;
}

interface BetData {
  txHash: string;
  outcomeIds: string[];
  orderType: string;
  wallet: string;
  created_at: string;
  odds: string;
  betAmount: number;
}

const ChainId = Number(process.env.REACT_APP_CHAIN_ID);
const blockExplorersUrl = ExplorerURLs[ChainId];
// const shortTime = (time: string) => {
//   let t = time.split(" ");
//   if (time.includes("seconds")) {
//     if (time.includes("a few")) {
//       return `1s`;
//     }
//     return `${t[0]}s`;
//   } else if (time.includes("a minute")) {
//     return `1m`;
//   } else if (time.includes("minutes")) {
//     return `${t[0]}m`;
//   } else if (time.includes("an hour")) {
//     return `1h`;
//   } else if (time.includes("hours")) {
//     return `${t[0]}h`;
//   } else if (time.includes("a day")) {
//     return `1d`;
//   } else if (time.includes("days")) {
//     return `${t[0]}d`;
//   } else if (time.includes("a month")) {
//     return `1m`;
//   } else if (time.includes("months")) {
//     return `${t[0]}m`;
//   } else if (time.includes("a year")) {
//     return `1y`;
//   } else {
//     return `${t[0]}Y`;
//   }
// };

const updateTimeRecentLocks: (time: string) => string = (time: string) => {
  time = moment(time).fromNow();
  return time;
};
const EmptyScreen: React.FC = () => {
  return (
    <div className="flex flex-row justify-center pt-[10%] pb-[10%]">
      <div>
        <div className="w-full flex justify-center mb-6">
          <i className="text-6xl fa-duotone fa-clock-rotate-left icon-primary"></i>
        </div>
        <div>
          <span className="text-sm opacity-70 text-center">
            No transaction found
          </span>
        </div>
      </div>
    </div>
  );
};

const formatDataHistory = (data: any) => {
  let _eventType: string;
  let _betAmount: number = 0;
  if (data?.contractName === "BookMaker") {
    _eventType =
      data?.eventName === "SingleOrderPlaced"
        ? "Single"
        : data?.eventName === "SystemOrderPlaced" &&
          data?.values?.wagerIds?.length === 1
        ? "Parlay"
        : "System";
    _betAmount =
      data?.eventName === "SingleOrderPlaced"
        ? data?.values?.amounts?.reduce(
            (a: any, b: any) => Number(a) + Number(b),
            0
          )
        : Number(data.values?.amount) * data?.values?.wagerIds.length;
  } else {
    _eventType =
      data?.type?.toString() === "single"
        ? "Single"
        : data?.type?.toString() === "combo"
        ? "Parlay"
        : "System";
    _betAmount =
      data?.type?.toString() === "single"
        ? data?.values?.amounts?.reduce(
            (a: any, b: any) => Number(a) + Number(b),
            0
          )
        : Number(data.values?.amount) * data?.values?.wagerIds.length;
  }
  return {
    betAmount: _betAmount,
    created_at: data?.time,
    orderType: _eventType,
    wallet: data?.user_id,
    txHash: data?.txhash,
    outcomeIds: data?.values?.outcomeIds,
    odds: "",
  };
};

const skClass = "bg-base-100/40 h-6 w-full rounded-box";

const LoadingSkeleton: React.FC<any> = (props) => {
  const { rows = 5 } = props;
  const rowsList = Array.from(Array(rows).keys());
  return (
    <div
      className={
        "skeleton-loading w-full flex flex-col items-center justify-center space-y-2"
      }
    >
      <div className={"w-full grid grid-cols-12 gap-2 rounded-box"}>
        <div className={`col-span-2 ${skClass}`}></div>
        <div className={`col-span-3 ${skClass}`}></div>
        <div className={`col-span-4 ${skClass}`}></div>
        <div className={`col-span-3 ${skClass}`}></div>
      </div>
      {rowsList.map((e: any, idx: number) => {
        return (
          <div key={idx} className={`w-full grid grid-cols-12 gap-2`}>
            <div className={`col-span-2 ${skClass}`}></div>
            <div className={`col-span-3 ${skClass}`}></div>
            <div className={`col-span-4 ${skClass}`}></div>
            <div className={`col-span-3 ${skClass}`}></div>
          </div>
        );
      })}
    </div>
  );
};

const RecentTable: React.FC<RecentTableProps> = ({
  dataBet,
  // setDataBet,
  // dataChanges,
}) => {
  // useEffect(() => {
  //   if (dataChanges) {
  //     let _data = [...dataBet];

  //     dataChanges.forEach((data) => {
  //       let _dataChange = data.data();
  //       _data.unshift(formatDataHistory(_dataChange));

  //       if (_data.length >= PAGE_SIZE) {
  //         _data.pop();
  //       }
  //     });

  //     setDataBet(_data);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataChanges]);
  return (
    <div className="w-full overflow-x-auto">
      <table className={"w-full text-sm"}>
        <thead>
          <tr className={"tr"}>
            <th className={"whitespace-nowrap th text-left !w-24"}>Tx.hash</th>
            <th className={`whitespace-nowrap th text-left`}>Order Type</th>
            <th className={`whitespace-nowrap th text-left`}>Wallet</th>
            <th className={"whitespace-nowrap th text-right"}>time</th>
            <th className={"whitespace-nowrap th text-right"}>Bet</th>
          </tr>
        </thead>
        <tbody>
          {dataBet.map((betItem: BetData, idx: any) => {
            const item = betItem?.orderType;
            if (!idx) {
              return (
                <tr
                  className="tr animate__animated animate__pulse animate__faster"
                  key={idx}
                >
                  <td className={"td text-left"}>
                    <Link
                      to={`${blockExplorersUrl}/tx/${betItem?.txHash}`}
                      target="_blank"
                      className="hover:text-primary whitespace-nowrap"
                      rel="noreferrer"
                    >
                      {getAddressSimpleString(betItem.txHash, 3)}
                    </Link>
                  </td>
                  <td className={"td text-left"}>
                    <div className="flex items-center flex-shrink-0">
                      {item === "Single" ? (
                        <i className="fa-duotone fa-futbol mr-2"></i>
                      ) : (
                        <i className="fa-duotone fa-layer-group mr-2"></i>
                      )}
                      <div className="flex flex-col items-baseline">
                        {/* <span className="font-semibold text-xs">
													{item?.vesting}
												</span> */}
                        <span className="ml-0">
                          <span className="">{item}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="td">
                    <div className="inline-flex items-center">
                      {getAddressSimpleString(betItem.wallet, 3)}
                    </div>
                  </td>
                  <td className={"td text-right"}>
                    <span
                      className="tooltip tooltip-left"
                      data-tip={updateTimeRecentLocks(betItem.created_at)}
                    >
                      {shortTime(updateTimeRecentLocks(betItem.created_at), true)}
                    </span>
                  </td>
                  <td className="td text-right">
                    <div className="inline-flex items-center">
                      <AcceptedToken
                        price={Number(
                          floatFormat(
                            Number(
                              ethers.utils.formatUnits(
                                ConvertString2BN(betItem.betAmount || 0),
                                DECIMALS
                              )
                            )
                          )
                        )}
                        showIcon={true}
                        justify={"justify-end"}
                      />
                    </div>
                  </td>
                </tr>
              );
            }
            return (
              <tr className="tr" key={idx}>
                <td className={"td text-left"}>
                  <Link
                    to={`${blockExplorersUrl}/tx/${betItem.txHash}`}
                    target="_blank"
                    className="hover:text-primary whitespace-nowrap"
                    rel="noreferrer"
                  >
                    {getAddressSimpleString(betItem.txHash, 3)}
                  </Link>
                </td>
                <td className={"td text-left"}>
                  <div className="flex items-center flex-shrink-0">
                    {item === "Single" ? (
                      <i className="fa-duotone fa-futbol mr-2"></i>
                    ) : (
                      <i className="fa-duotone fa-layer-group mr-2"></i>
                    )}
                    <div className="flex flex-col items-baseline">
                      {/* <span className="font-semibold text-xs">
														{item?.vesting}
													</span> */}
                      <span className="ml-0">
                        <span className="">{item}</span>
                      </span>
                    </div>
                  </div>
                </td>
                <td className="td">
                  <div className="inline-flex items-center">
                    {getAddressSimpleString(betItem.wallet, 3)}
                  </div>
                </td>

                <td className={"td text-right"}>
                  <span
                    className="tooltip tooltip-left"
                    data-tip={updateTimeRecentLocks(betItem.created_at)}
                  >
                    {shortTime(updateTimeRecentLocks(betItem.created_at), true)}
                  </span>
                </td>
                <td className="td text-right">
                  <div className="inline-flex items-center">
                    <AcceptedToken
                      price={Number(
                        floatFormat(
                          Number(
                            ethers.utils.formatUnits(
                              ConvertString2BN(betItem.betAmount || 0),
                              DECIMALS
                            )
                          )
                        )
                      )}
                      showIcon={true}
                      justify={"justify-end"}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
const RecentBets: React.FC<RecentBetProps> = () => {
  // const [lastRecord, setLastRecord] = useState<any>();
  // const [dataChanges, setDataChanges] = useState<
  //   QueryDocumentSnapshot<DocumentData>[] | null
  // >(null);
  // const [nextCursorPointer, setNextCursorPointer] =
  //   useState<DocumentData | null>(null);
  // const [prevCursorPointer, setPrevCursorPointer] =
  //   useState<DocumentData | null>(null);
  // const [dataBet, setDataBet] = useState<BetData[]>([]);
  // const [isPrev, setIsPrev] = useState<boolean>(false);
  // const [isNext, setIsNext] = useState<boolean>(false);
  // const [isLoad, setIsLoad] = useState<boolean>(true);
  // const [lastItem, setLastItem] = useState<boolean>(false);
  // const [loadMore, setLoadMore] = useState<boolean>(false);
  // const access_token = getItem(ACCESS_TOKEN) || "";
  // const calcuLateComboOdds = (odds: any[]) => {
  // 	let result = 0;
  // 	odds.forEach((odd, index) => {
  // 		if (!index) {
  // 			result = odd.odds.roi;
  // 		} else {
  // 			result = result * odd.odds.roi;
  // 		}
  // 	});
  // 	return result.toString();
  // };

  // ============ dev: previous version, no longer used ================
  // const getLatestBets: () => Promise<BetData[]> = () => {
  //   return new Promise((resolve, reject) => {
  //     const betHistoryColl = historyOrderCollRef();
  //     const q = query(
  //       betHistoryColl,
  //       orderBy("time", "desc"),
  //       limit(PAGE_SIZE + 1)
  //     );
  //     const data: BetData[] = [];
  //     resolve(
  //       getDocs(q)
  //         .then((betHistorySnapshot) => {
  //           let count = 0;
  //           betHistorySnapshot.forEach((betHistory) => {
  //             if (!count) {
  //               setLastRecord(betHistory);
  //             }
  //             let _data = betHistory.data();
  //             if (
  //               count <= betHistorySnapshot.docs.length - 2 ||
  //               betHistorySnapshot.docs.length !== PAGE_SIZE + 1
  //             ) {
  //               data.push(formatDataHistory(_data));
  //             }
  //             if (
  //               betHistorySnapshot.docs.length === PAGE_SIZE + 1 &&
  //               count === betHistorySnapshot.docs.length - 2
  //             ) {
  //               setNextCursorPointer(betHistory);
  //             }
  //             count++;
  //           });

  //           return data;
  //         })
  //         .catch((err) => {
  //           return data;
  //         })
  //     );
  //   });
  // };

  // useEffect(() => {
  //   if (access_token) {
  //     Promise.all([getLatestBets()]).then(([BetData]) => {
  //       setDataBet(BetData);
  //       setIsLoad(false);
  //     });
  //   } else {
  //     setIsLoad(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [access_token]);

  // useEffect(() => {
  //   if (isNext) {
  //     setIsLoad(true);
  //     const orderBetCollection = historyOrderCollRef();
  //     let data: BetData[] = [];
  //     const q = query(
  //       orderBetCollection,
  //       orderBy("time", "desc"),
  //       startAfter(nextCursorPointer),
  //       limit(PAGE_SIZE + 1)
  //     );
  //     getDocs(q).then((orderBetSnapShot: QuerySnapshot<any>) => {
  //       let count = 0;
  //       orderBetSnapShot.forEach((orderBets) => {
  //         let _data = orderBets.data();
  //         if (
  //           count <= orderBetSnapShot.docs.length - 2 ||
  //           orderBetSnapShot.docs.length !== PAGE_SIZE + 1
  //         ) {
  //           data.push(formatDataHistory(_data));
  //         }
  //         if (
  //           orderBetSnapShot.docs.length === PAGE_SIZE + 1 &&
  //           count === orderBetSnapShot.docs.length - 2
  //         ) {
  //           setNextCursorPointer(orderBets);
  //         }
  //         count++;
  //       });
  //       if (!orderBetSnapShot.empty) {
  //         setPrevCursorPointer(orderBetSnapShot.docs[0]);
  //       }
  //       if (orderBetSnapShot.docs.length !== PAGE_SIZE + 1) {
  //         setNextCursorPointer(null);
  //       }
  //       setIsLoad(false);
  //       setIsNext(false);
  //       setDataBet(data);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isNext]);

  // useEffect(() => {
  //   const LoadMoreItem = async () => {
  //     try {
  //       setLoadMore(true);
  //       const orderBetCollection = historyOrderCollRef();
  //       let data: BetData[] = [];
  //       const q = query(
  //         orderBetCollection,
  //         orderBy("time", "desc"),
  //         startAfter(nextCursorPointer),
  //         limit(PAGE_SIZE + 1)
  //       );
  //       const orderBetSnapShot = await getDocs(q);
  //       let count = 0;
  //       orderBetSnapShot.forEach((orderBets) => {
  //         let _data = orderBets.data();
  //         if (
  //           count <= orderBetSnapShot.docs.length - 2 ||
  //           orderBetSnapShot.docs.length !== PAGE_SIZE + 1
  //         ) {
  //           data.push(formatDataHistory(_data));
  //         }
  //         if (
  //           orderBetSnapShot.docs.length === PAGE_SIZE + 1 &&
  //           count === orderBetSnapShot.docs.length - 2
  //         ) {
  //           setNextCursorPointer(orderBets);
  //         }
  //         count++;
  //       });
  //       if (!orderBetSnapShot.empty) {
  //         setPrevCursorPointer(orderBetSnapShot.docs[0]);
  //       }
  //       if (orderBetSnapShot.docs.length !== PAGE_SIZE + 1) {
  //         setLastItem(true);
  //         setNextCursorPointer(null);
  //       }
  //       setIsLoad(false);
  //       setIsNext(false);
  //       if (data.length) {
  //         setDataBet([...dataBet, ...data]);
  //       }
  //     } catch (error) {
  //       console.log("====================================");
  //       console.log("error ===>", error);
  //       console.log("====================================");
  //     } finally {
  //       setLoadMore(false);
  //     }
  //   };
  //   // Load more items when user scrolls to the bottom
  //   const handleScroll = () => {
  //     const element = document.getElementById("scroll-loaded");
  //     const isBottom = (el: HTMLElement) => {
  //       return el.getBoundingClientRect().bottom <= window.innerHeight;
  //     };

  //     if (element && isBottom(element) && !lastItem) {
  //       LoadMoreItem().then();
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lastItem, nextCursorPointer]);

  // useEffect(() => {
  //   if (isPrev) {
  //     setIsLoad(true);

  //     const orderBetCollection = historyOrderCollRef();
  //     let data: BetData[] = [];

  //     const q = query(
  //       orderBetCollection,
  //       orderBy("time", "desc"),
  //       endBefore(prevCursorPointer),
  //       limitToLast(PAGE_SIZE + 1)
  //     );
  //     getDocs(q).then((orderBetSnapShot: QuerySnapshot<any>) => {
  //       let count = 0;

  //       orderBetSnapShot.forEach((orderBets) => {
  //         let _data = orderBets.data();
  //         if (!count) {
  //           if (orderBetSnapShot.docs.length !== PAGE_SIZE + 1) {
  //             data.push(formatDataHistory(_data));
  //           }
  //         } else {
  //           data.push(formatDataHistory(_data));
  //         }
  //         count++;
  //       });

  //       setNextCursorPointer(
  //         orderBetSnapShot.docs[orderBetSnapShot.docs.length - 1]
  //       );
  //       if (orderBetSnapShot.docs.length === PAGE_SIZE + 1) {
  //         setPrevCursorPointer(orderBetSnapShot.docs[1]);
  //       } else {
  //         setPrevCursorPointer(null);
  //       }

  //       setIsLoad(false);
  //       setIsPrev(false);
  //       setDataBet(data);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isPrev]);

  // useEffect(() => {
  //   if (lastRecord) {
  //     const OrderBetSnapshot = historyOrderCollRef();
  //     const q = query(
  //       OrderBetSnapshot,
  //       orderBy("time", "desc"),
  //       limit(PAGE_SIZE)
  //     );
  //     onSnapshot(q, (querySnapshot) => {
  //       if (!querySnapshot.empty) {
  //         let _data: QueryDocumentSnapshot<DocumentData>[] = [];

  //         querySnapshot.docs.forEach((data: any) => {
  //           return _data.unshift(data);
  //         });

  //         if (_data.length) {
  //           setDataChanges(_data);
  //         }
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lastRecord]);
  // ============ END: dev: previous version, no longer used ================

  // ================== new pagination ==================
  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const [count, setCount] = useState(0);
  const [data, setData] = useState<QueryDocumentSnapshot<any, DocumentData>[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  const observerTarget = useRef(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  /// fetch raw data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const constraints: QueryConstraint[] = [orderBy("time", "desc")];
        const cursor = cursors.current.get(page);
        if (cursor) {
          constraints.push(startAfter(cursor));
        }
        const _query = query(
          historyOrderCollRef(),
          ...constraints,
          limit(PAGINATION_SIZE_RECENT_BETS)
        );
        const result: QuerySnapshot<any> = await getDocs(_query);

        if (isMobile) {
          // scroll infinity for mobile
          if (page === 0) {
            setData(result.docs);
          } else {
            setData((data) => data.concat(result.docs));
          }
        } else {
          setData(result.docs);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [page]);

  /// fetch count
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const orderBetCollection = historyOrderCollRef();
        const q = query(orderBetCollection);
        const querySnapshot = await getCountFromServer(q);
        setCount(querySnapshot.data()?.count);
      } catch (error) {
        console.log(
          "🚀 ~ file: recentBets.tsx:677 ~ fetchCount ~ error:",
          error
        );
      }
    };

    fetchCount();
  }, []);

  /// dev: handle infinity scroll ::::::: trigger click next button for simulate pagination on mobile
  useEffect(() => {
    let observer: IntersectionObserver;

    observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("visible");
          nextButtonRef.current?.click();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current && observer) {
        console.log("unmount");
        observer.unobserve(observerTarget.current);
      }
    };
  }, []);

  const dataBet = data.map((item) => {
    return formatDataHistory(item.data());
  });

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        if (!data) return page;

        // first, we save the last document as page's cursor
        cursors.current.set(page + 1, data[data.length - 1]);

        // then we update the state with the next page's number
        return nextPage;
      });
    },
    [data]
  );
  // ================== end: new pagination ==================

  const renderMainContent = () => {
    if (!isLoading && dataBet.length === 0) return <EmptyScreen />;

    /// desktop
    if (!isMobile) {
      if (isLoading) {
        return <LoadingSkeleton />;
      }

      return <RecentTable dataBet={dataBet} />;
    }

    /// mobile
    return (
      <>
        <RecentTable dataBet={dataBet} />
        {isLoading && <LoadingSkeleton />}
      </>
    );
  };

  return (
    <Fragment>
      <div className="flex flex-col gap-4">
        {renderMainContent()}
        {/* {!isLoading && dataBet.length === 0 ? (
        <EmptyScreen />
      ) : (
        <Fragment>
          <RecentTable
            dataBet={dataBet}
            // setDataBet={setDataBet}
            // dataChanges={dataChanges}
            // isLoad={isLoading}
            // isPrev={isPrev}
            // isNext={isNext}
            // loadMore={loadMore}
            // lastItem={lastItem}
          />
        </Fragment>
      )} */}
        {isMobile && (
          <div
            className="flex flex-col gap-4 pl-4 pr-2"
            ref={observerTarget}
          ></div>
        )}

        {count > PAGINATION_SIZE_RECENT_BETS && (
          <div className="hidden md:block">
            <Pagination
              count={count}
              currentPage={page}
              pageChanged={onPageChanged}
              position={"end"}
              pageSize={PAGINATION_SIZE_RECENT_BETS}
              nextButtonRef={nextButtonRef}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default RecentBets;
