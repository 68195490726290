import React, { Fragment } from "react";
import Markets from "../../Markets";
import { Disclosure } from "@headlessui/react";
import _ from "lodash";
import { Link } from "react-router-dom";
interface LeaguesListProps {
  leagueData: any;
  eventsData: any;
  category: string;
  type: string;
  filter: string;
}
const LeaguesList: React.FC<LeaguesListProps> = ({
  leagueData,
  category,
  eventsData,
  type,
  filter,
}) => {
  const marketGroupClass = "disclosure";
  let __eventsData = _.orderBy(eventsData, ["time"], ["asc"]);
  const child = leagueData.map((item: any, idx: number) => {
    return (
      <Fragment key={idx}>
        {/* Market List 1 */}
        <Disclosure
          as="div"
          className={marketGroupClass}
          defaultOpen={item?.has_toplist ? true : false}
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="disclosure-btn">
                <div className="flex items-center">
                  {item?.icon_classes && (
                    <span className="flex flex-shrink-0 items-center justify-center mr-2">
                      <i className={`${item?.icon_classes}`}></i>
                    </span>
                  )}
                  {!item?.icon_classes && item.image && item.image.length && (
                    <span className="flex flex-shrink-0 items-center justify-center mr-2">
                      <img
                        className={`block relative m-auto`}
                        width="15px"
                        src={`${
                          item.image && item.image.length
                            ? item.image[0]?.downloadURL
                            : "/placeholder.svg"
                        }`}
                        alt={`${item.id}`}
                      />
                    </span>
                  )}
                  <h3 className="text-sm">
                    <Link
                      to={`/${item?.url_path}`}
                      className="hover:text-primary transition"
                    >
                      {item.name}
                    </Link>
                  </h3>
                </div>
                <div>
                  {!open ? (
                    <>
                      <span className="badge badge-rounded mr-4">
                        {item.eventCount}
                      </span>
                      <i className="fa-solid fa-caret-right text-sm transition-all"></i>
                    </>
                  ) : (
                    <i className="fa-solid fa-caret-right text-sm rotate-90 transition-all"></i>
                  )}
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="disclosure-panel fadein">
                <Markets
                  parentUrl={`/${item?.url_path}`}
                  league={item?.id}
                  category={category}
                  eventsData={__eventsData}
                  eventFilter={filter}
                  type={type}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Fragment>
    );
  });

  return (
    <Fragment>
      {child}
    </Fragment>
  );
};
export default LeaguesList;
