import { DataChain } from "contracts/config";
import { BigNumber } from "ethers";
import React, { FC, useContext, useEffect, useState } from "react";
import { Provider } from "zksync-web3";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useAuth } from "contexts/authContext";
import { Link } from "react-router-dom";
import {
  currencyFormat,
  formatBigNumber,
  getAddressSimpleString,
} from "common/utils";
import { ReactComponent as ZKUSD } from "assets/currency-icons/zkUSD.svg";
import { ReactComponent as Ethereum } from "assets/currency-icons/Ethereum_1.svg";

import SmartDeposit from "../components/smartDeposit";
import { useController } from "./Controller";
import BetModalBody from "../components/bettingSetting";
import { ThemeContext } from "contexts/themeContext";
import { LanguageContext } from "contexts/languageContext";
import { useSnapshot } from "valtio";
import { LANGUAGES} from "helpers/constants";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { modalFunc, modalProxy } from "proxy-state/global";
import NotiModalBody from "../components/NotiSetting";
// import { DepositContext } from "contexts/depositContext";
import { SmartDepositOption } from "../components/smartDeposit/depositOptions";
import CustomAvatar from "utils/wagmi/avatar";

const ProfileLayout: FC = () => {
  const { myProfile, myBalance, chainId, address } =
    useController();
  const { theme, setTheme } = useContext(ThemeContext);
  const { language, setLanguge } = useContext(LanguageContext);
  // const { deposit, setDeposit } = useContext(DepositContext);

  const { isNotiSettingOpen: notiSettingPage } = useSnapshot(modalProxy);

  // const navigate = useNavigate();
  const { balanceOf } = myBalance;

  const userInfo = myProfile.profile;
  const intl = useIntl();

  const [ethBl, setEthBl] = useState<BigNumber>(BigNumber.from(0));

  const [depositPage, setDepositPage] = useState(false);
  const [depositOptionPage, setDepositOptionPage] = useState(false);
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState<boolean>(false);

  const [bettingPage, setBettingPage] = useState(false);
  // const [notiSettingPage, setNotiSettingPage] = useState(false);

  const { handleLogout } = useAuth();

  useEffect(() => {
    if (!address) return;
    const provider = new Provider(DataChain[chainId]["NEXT_PUBLIC_RPC_URL"]);
    const run = async () => {
      setEthBl(BigNumber.from((await provider.getBalance(address)).toString()));
    };
    run();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const zkUSDBalace: number =
    myBalance?.data?.find((item: any) => item?.symbol === "zkUSD")?.balance ||
    0;
  // const USDCBalance: number =
  //   myBalance?.data?.find((item: any) => item?.symbol === "USDC")?.balance || 0;
  const balancezkUSDViewer =
    balanceOf === zkUSDBalace
      ? `${currencyFormat(balanceOf, true)}`
      : `${currencyFormat(balanceOf, true)} / ${currencyFormat(
          zkUSDBalace,
          true
        )}`;

  // const modalClasses = `modal modal-bottom sm:modal-middle `;
  const ModalShow =
    depositOptionPage || depositPage || bettingPage || notiSettingPage;

  useEffect(() => {
    // Function to handle scroll events
    const modalElm = document.getElementById("user_modal") as HTMLElement;
    const handleScroll = () => {
      const elem = document.getElementById("user_info") as HTMLElement;
      // Adjust this value to change when the element should hide

      if (modalElm.scrollTop > 100 || elem?.getBoundingClientRect()?.top < 10) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    };

    // Attach the scroll event listener
    modalElm?.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      modalElm?.removeEventListener("scroll", handleScroll);
    };
  }, []);
 
  return (
    <>
      {depositPage && (
        <SmartDeposit
          setDepositPage={setDepositPage}
          setDepositOptionPage={setDepositOptionPage}
        />
      )}
      {depositOptionPage && (
        <SmartDepositOption
          setDepositPage={setDepositPage}
          setDepositOptionPage={setDepositOptionPage}
        />
      )}
      {bettingPage && <BetModalBody setBettingPage={setBettingPage} />}
      {notiSettingPage && <NotiModalBody />}

      {!ModalShow && (
        <div>
          <div
            className={`sticky top-0 left-0 right-0 h-16 flex justify-between px-4 md:px-6 py-4 z-50 ${
              !isShow ? "" : "bg-base-200/80 backdrop-blur"
            }`}
          >
            {/* Show on Scroll top */}
            {/* <div className="sticky top-0 left-0 right-0 flex justify-between px-4 md:px-6 py-4 bg-base-200/80 backdrop-blur z-50"> */}
            <div className={`flex gap-4 justify-center items-center`}>
              {isShow && (
                <>
                  {userInfo?.picture ? (
                    <>
                      <div className="w-8 h-8 text-base rounded-full overflow-hidden bg-base-300/10 flex items-center justify-center shadow-xl">
                        <img height="60" width="60" src={`${userInfo?.picture || "https://i.pravatar.cc/60"}`} alt="Avatar" />
                      </div>
                      <div className="text-left">
                        <h3 className="text-base font-semibold">
                          {intl.formatMessage({ id: "Hi" })},{" "}
                          {userInfo?.userName || userInfo?.email.split("@")[0]}
                        </h3>
                      </div>
                    </>
                  ): (
                    <>
                      <div className="flex gap-4 justify-center items-center">
                        <div className="w-8 h-8 text-2xl rounded-full overflow-hidden bg-base-300/10 flex items-center justify-center shadow-xl">
                          🦀
                        </div>
                        <div className="text-left">
                          <h3 className="text-base font-semibold">0x0...131</h3>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div></div>

            <label
              htmlFor="balance--modal"
              className="btn btn-circle btn-ghost btn-sm"
              onClick={() => modalFunc.setModalOpen("isProfileOpen", false)}
            >
              <i className="fa-regular fa-xmark text-xl"></i>
            </label>
          </div>

          <div
            id="user_info"
            className={`p-6 flex gap-4 justify-center items-center${userInfo?.picture ? ' flex-col -mt-12' : ''}`}
          >
            {userInfo?.picture ? (
              <>
                <div className="w-16 h-16 text-base rounded-full overflow-hidden bg-base-100/10 flex items-center justify-center shadow-xl">
                  <img src={`${userInfo?.picture}`} alt="Avatar" />
                </div>
                <div className="text-center">
                  <h3 className="text-2xl font-semibold">
                    {intl.formatMessage({ id: "Hi" })},{" "}
                    {userInfo?.userName || userInfo?.email?.split("@")[0]}
                  </h3>
                  <div className="text-base-content/50 text-sm mt-1">
                    {userInfo?.email}
                  </div>
                  <div className="btn btn-sm rounded-full text-base-content/50 text-sm mt-2 gap-2 relative z-10">
                    <CopyToClipboard
                      text={address!}
                      onCopy={() => toast.success("Copied to clipboard")}
                    >
                      <span className="flex items-center cursor-pointer">
                        <span className="font-normal text-sm mr-3">
                          {getAddressSimpleString(address!, 4)}
                        </span>
                        <i className="fa-solid fa-copy"></i>
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              </>
            ): (
              <>
                {/* <div className="w-16 h-16 text-base rounded-full overflow-hidden bg-base-100/10 flex items-center justify-center shadow-xl">
                </div> */}
                <CustomAvatar size="20" address={userInfo?.id} ensImage={''} classes="w-8 h-8 text-2xl rounded-full overflow-hidden bg-base-300/10 flex items-center justify-center shadow-xl" />

                <div className="flex items-center gap-1 text-center">
                  <CopyToClipboard
                    text={address!}
                    onCopy={() => toast.success("Copied to clipboard")}
                  >
                    <span className="flex items-center cursor-pointer">
                      <span className="font-normal text-sm mr-3">
                        {getAddressSimpleString(address!, 4)}
                      </span>
                      <i className="fa-solid fa-copy"></i>
                    </span>
                  </CopyToClipboard>
                </div>
              </>
            )}
          </div>
          <div className="p-4 md:p-6 !pb-6 gap-6 flex flex-col container">
            <div className="space-y-4 text-base">
              <div className="text-xs uppercase tracking-wide opacity-70 ml-3">
                {intl.formatMessage({ id: "Your Balance" })}
              </div>
              <div className="flex flex-col bg-base-100 px-3 rounded-box">
                <div className="flex items-center justify-between py-4 px-1">
                  <span className="flex items-center flex-shrink-0">
                    <ZKUSD className="w-5 h-5 mr-3" />
                    zkUSD
                  </span>
                  {balanceOf === zkUSDBalace ? (
                    <span
                      className="whitespace-nowrap font-medium tooltip tooltip-top"
                      data-tip={currencyFormat(balanceOf, false)}
                    >
                      {currencyFormat(balanceOf, true)}
                    </span>
                  ) : (
                    <span
                      className="whitespace-nowrap font-medium tooltip tooltip-top"
                      data-tip={`Available / Total`}
                    >
                      {balancezkUSDViewer}
                    </span>
                  )}
                </div>

                <span
                  className="ml-9 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>

                {balanceOf !== zkUSDBalace && (
                  <>
                    <div className="flex items-center justify-between py-3 px-1">
                      <span className="flex items-center text-base-content flex-shrink-0">
                        <ZKUSD className="w-5 h-5 mr-3 grayscale" />
                        zkUSD (Locked)
                      </span>
                      <div className="flex items-center">
                        <span
                          className="flex items-center whitespace-nowrap font-medium tooltip tooltip-top"
                          data-tip={currencyFormat(
                            zkUSDBalace - balanceOf,
                            false
                          )}
                        >
                          {currencyFormat(zkUSDBalace - balanceOf, true)}
                        </span>
                        <Link
                          to={`https://docs.goal3.xyz/lockdrop/unlock-mechanism`}
                          target={`_blank`}
                        >
                          <span
                            className="ml-2 tooltip tooltip-left -mt-0.5"
                            data-tip="zkUSD to be unlocked linearly till the end of the lock period"
                          >
                            <i className="fa-solid fa-lock text-[10px] text-error"></i>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <span
                      className="ml-9 h-[1px] bg-base-content/10"
                      role="separator"
                    ></span>
                  </>
                )}
                <div className="flex items-center justify-between py-3 px-1">
                  <span className="flex items-center text-base-content flex-shrink-0">
                    <Ethereum className="w-5 h-5 mr-3" />
                    ETH
                  </span>
                  <span
                    className="whitespace-nowrap font-medium tooltip tooltip-top"
                    data-tip={currencyFormat(
                      formatBigNumber(ethBl, true, 18),
                      true
                    )}
                  >
                    {currencyFormat(formatBigNumber(ethBl, true, 18), true)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col bg-base-100 px-3 rounded-box">
                <button
                  className="flex items-center justify-between py-4 px-2"
                  onClick={() => {
                    setDepositOptionPage(true);
                  }}
                >
                  <span className="flex items-center text-base-content">
                    <span className="flex items-center justify-center w-6 mr-4 mt-1">
                      <i className="fa-duotone fa-coins icon-primary text-lg"></i>
                    </span>
                    <div className="flex flex-col gap-1">
                      <span className="text-primary">Smart Deposit</span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </button>
              </div>
              {/* <button className="flex flex-col bg-base-100 px-3 rounded-box w-full">
                <div className="flex items-center justify-between py-4 px-2 w-full">
                  <span
                    className="flex items-center text-base-content"
                    onClick={() => setDepositOptionPage(true)}
                  >
                    <span className="flex items-center justify-center w-6 mr-4">
                      <i className="fa-duotone fa-coins icon-primary text-lg"></i>
                    </span>
                    <div className="flex flex-col gap-1">
                      <span className="">Smart Deposit</span>
                    </div>
                  </span>

                  <div className="space-x-1">
                    <div className="dropdown dropdown-bottom dropdown-end">
                      <label tabIndex={0} className="dropdown-toggle">
                        <button
                          className="dropdown-toggle-title"
                          onClick={() => setOpen(!open)}
                        >
                          <span className="flex items-center">
                            <span className="truncate text-sm opacity-50 mr-1 font-normal">
                              {
                                SMART_DEPOSIT.find((el) => el.value === deposit)
                                  ?.label
                              }
                            </span>
                          </span>
                          <i className="fa-solid fa-angle-down ml-2"></i>
                        </button>
                      </label>
                      <div
                        tabIndex={0}
                        className={clsx(
                          "dropdown-content !bg-base-300 menu w-60 shadow",
                          {
                            hidden: !open,
                            block: open,
                          }
                        )}
                      >
                        {SMART_DEPOSIT.map((el) => (
                          <button
                            className={`dropdown-menu-item w-full`}
                            onClick={() => {
                              setDeposit(el.value);
                              setOpen(!open);
                            }}
                          >
                            <span className="flex items-center">
                              {el.imgUrl.map((img, index) => (
                                <>
                                  {index > 0 && (
                                    <i className="fa-regular fa-plus mx-2 text-white" />
                                  )}
                                  <img
                                    alt={el.label}
                                    className={`h-5 md:h-6`}
                                    src={img}
                                  />
                                </>
                              ))}
                            </span>
                            {deposit === el.value && (
                              <i className="fa-solid fa-check ml-2"></i>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </button> */}
            </div>
            <div className="space-y-4 text-base">
              <div className="text-xs uppercase tracking-wide opacity-70 ml-3">
                {intl.formatMessage({ id: "Settings" })}
              </div>

              <div className="flex flex-col bg-base-100 px-3 rounded-box">
                <button
                  className="flex items-center justify-between py-4 px-2"
                  onClick={() => {
                    setBettingPage(true);
                  }}
                >
                  <span className="flex items-center text-base-content">
                    <span className="flex items-center justify-center w-6 mr-4">
                      <i className="fa-duotone fa-cart-shopping icon-primary text-lg"></i>
                    </span>
                    <div className="flex flex-col gap-1">
                      <span className="">
                        {intl.formatMessage({ id: "Betting Preferences" })}
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </button>

                <span
                  className="ml-12 mr-2 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>

                <button
                  className="flex items-center justify-between py-4 px-2"
                  onClick={() => {
                    modalProxy.isNotiSettingOpen = true;
                  }}
                >
                  <span className="flex items-center text-base-content">
                    <span className="flex items-center justify-center w-6 mr-4">
                      <i className="fa-duotone fa-bell icon-primary text-lg"></i>
                    </span>
                    <div className="flex flex-col gap-1">
                      <span className="">
                        {intl.formatMessage({ id: "Notification settings" })}
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </button>
              </div>

              <div className="flex flex-col bg-base-100 px-3 rounded-box">
                <div className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-center text-base-content">
                    <span className="flex items-center justify-center w-6 mr-4">
                      <i className="fa-duotone fa-swatchbook icon-primary text-lg"></i>
                    </span>
                    <div className="flex flex-col gap-1">
                      <span className="">
                        {intl.formatMessage({ id: "Themes" })}
                      </span>
                    </div>
                  </span>

                  <div className="space-x-1">
                    {/* Active */}
                    <button
                      className={`btn btn-sm  btn-circle ${
                        theme === "light" ? "btn-active " : "btn-ghost "
                      }`}
                      onClick={() => setTheme("light")}
                    >
                      <i className="fa-solid fa-sun"></i>
                      <span className="sr-only">Light</span>
                    </button>
                    {/* //Active */}
                    <button
                      className={`btn btn-sm btn-circle ${
                        theme === "dark" ? "btn-active " : "btn-ghost "
                      }`}
                      onClick={() => setTheme("dark")}
                    >
                      <i className="fa-solid fa-moon"></i>
                      <span className="sr-only">Dark</span>
                    </button>
                  </div>
                </div>
                <span
                  className="ml-12 mr-2 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>
                <div className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-center text-base-content">
                    <span className="flex items-center justify-center w-6 mr-4">
                      <i className="fa-duotone fa-language icon-primary text-lg"></i>
                    </span>
                    <div className="flex flex-col gap-1">
                      <span className="">
                        {intl.formatMessage({ id: "Languages" })}
                      </span>
                    </div>
                  </span>

                  <div className="space-x-1">
                    {/* Time Sort */}
                    <div className="dropdown dropdown-top dropdown-end ">
                      <label tabIndex={0} className="dropdown-toggle">
                        <button
                          className="dropdown-toggle-title"
                          onClick={() => setOpen(!open)}
                        >
                          <span className="flex items-center">
                            <span className="truncate text-sm opacity-50 mr-1 font-normal">
                              {intl.formatMessage({
                                id: LANGUAGES.find(
                                  (el) => el.value === language
                                )?.label,
                              })}
                            </span>
                          </span>
                          <i className="fa-solid fa-angle-down ml-2"></i>
                        </button>
                      </label>
                      <div
                        tabIndex={0}
                        className={clsx(
                          "dropdown-content !bg-base-300 menu w-40 shadow",
                          {
                            hidden: !open,
                            block: open,
                          }
                        )}
                      >
                        {LANGUAGES.map((lang) => (
                          <button
                            key={lang.value}
                            className={`dropdown-menu-item w-full`}
                            onClick={() => {
                              setLanguge(lang.value);
                              setOpen(!open);
                            }}
                          >
                            <span className="flex items-center">
                              <span className="truncate">
                                {intl.formatMessage({ id: lang?.label })}
                              </span>
                            </span>
                            {language === lang.value && (
                              <i className="fa-solid fa-check ml-2"></i>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    {/* //Time Sort */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2 px-4 md:px-6 !pb-8 fadein mt-auto container">
            <button
              className="btn btn-outline border-base-content/20 hover:text-error hover:bg-transparent hover:border-error"
              onClick={handleLogout}
            >
              <i className="fa-duotone fa-arrow-right-from-bracket mr-3"></i>{" "}
              Sign out
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default ProfileLayout;
