import {
  bookMarkerApi,
  checkSessionExist,
  checkWalletExist,
  createSession,
  enterInviteCode,
  linkWallet,
  loginSocial,
  SocialType,
} from "utils/apis/bookmakerApis";
import { ADDRESS, getItem, saveItem } from "local-storage";
import { SignInWithFirebase } from "utils/firebase/firebaseAuth";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { BigNumber, Signer } from "ethers";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSignMessage,
  useSwitchNetwork,
} from "wagmi";
import { useEthersSigner, walletClientToSigner } from "hooks/useSigner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugs from "navigation/slugs";
import { RawWalletConnect } from "components/organisms/Wallets/RawWalletConnect";
import BgVideo from "assets/wallpapers/soccer.mp4";
import { ReactComponent as GoogleSvg } from "assets/icons/google.svg";
import { eoa, prepareCreateSessionParams } from "utils/blockchain";
import { parseAddressFromToken } from "utils/index";
import OneSignal from "react-onesignal";
import { notiApis } from "utils/apis";
import { useAuth } from "contexts/authContext";
import { auth } from "utils/firebase/config2";
import { loadDataByAddress } from "proxy-state/helper";
import { ReactComponent as LogoSvg } from "assets/logo/logo-dark-sm-goal3.svg";
import { userDocRef } from "utils/firebase/firebaseDBRef";
import { getDoc } from "firebase/firestore";
import { User } from "interfaces/firebase";
import { SiweMessage } from "siwe";
import { CHAIN_ID } from "constant";
import { sleep } from "zksync-web3/build/src/utils";
import utils from "common/utils";

export enum AuthStep {
  Login = "login",
  Link = "link",
  LinkEOA = "link-eoa",
  LinkSocial = "link-social",
  Approve = "approve",
  Invitation = "invitation",
}

const LoginScreenPage = () => {
  const navigator = useNavigate();
  const { status, address, isDisconnected } = useAccount();
  const { disconnectAsync: disconnect } = useDisconnect();
  const { updateToken } = useAuth();
  const searchParams = new URLSearchParams(window.location.search);
  const returnUrl = searchParams.get("return");
  const callBackUrl = returnUrl ? utils.base64URLDecode(returnUrl) : slugs.home;
  const [googleToken, setGoogleToken] = useState<string>("");

  const [step, setStep] = useState<AuthStep>(AuthStep.Login);
  const [socialId, setSocialId] = useState<string>("");
  const [socialType, setSocialtype] = useState<SocialType>(SocialType.GOOGLE);
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [picture, setPicture] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(false);
  const [isCheckingSession, setIsCheckingSession] = useState<boolean>(false);
  const [isApproveSession, setIsApproveSession] = useState<boolean>(false);
  const [linkedWalletLoading, setLinkedWalletLoading] =
    useState<boolean>(false);
  const [isApproveZkUSD, setIsApproveZkUSD] = useState<boolean>(false);
  const [isApplyWalletSuccess, setIsApplyWalletSuccess] =
    useState<boolean>(false);
  const [isApplySuccess, setIsApplySuccess] = useState<boolean>(false);
  const [inviteCode, setInviteCode] = useState<string>("");
  const [isExpired, setIsExpired] = useState(false);
  const { state } = useLocation();
  // const [isCopiedCode, setIsCopiedCode] = useState(false);
  const [userInfo, setUserInfo] = useState<User>();
  const [isLoadingMetamask, setIsLoadingMetamask] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (state?.expired && !isExpired) {
      setIsExpired(true);
      // navigator(slugs.login, { replace: true })
      toast.warning("Your session has expired. Please login again.");
    }
  }, [state?.expired, isExpired]);
  const { chain } = useNetwork();
  const signer = useEthersSigner({
    chainId: chain?.id,
  });

  // useEffect(() => {
  //   disconnect();
  //   if (step === AuthStep.Login) {
  //     if (getItem("access_token")) {
  //       localStorage.clear();
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (isDisconnected) {
      setIsApproveSession(false);
    }
  }, [isDisconnected]);

  useEffect(() => {
    if (signer && address && step === AuthStep.LinkEOA && !chain?.unsupported) {
      const f = async () => {
        try {
          setIsCheckingSession(true);
          const result = await eoa.checkApproved(address);
          if (result) {
            const result = await checkSessionExist(address);
            if (result.data) {
              setIsApproveSession(true);
            }
          }
        } catch (error) {
          toast.error("Error code: CEF"); // check exist failure ❌
        } finally {
          setIsCheckingSession(false);
        }
      };
      f();
    }
  }, [step, address, signer, chain?.unsupported]);

  useEffect(() => {
    if (address && !userInfo && auth?.currentUser) {
      const getUserInfo = async () => {
        try {
          const userRef = await getDoc(userDocRef(address));
          if (userRef.exists()) {
            const data = userRef.data();
            if (data) {
              const userData: User = {
                id: data.id,
                created_at: data.created_at,
                bonusRate: data.bonusRate || 0,
                invitation_code: data.invitation_code || data.totalIV || "",
                invite_by_premiumcode: data.invite_by_premiumcode || false,
                position: data.position || 0,
                premium_code: data.premium_code || "",
                short_code: data.short_code || "",
                totalAmount: data.totalAmount || 0,
                totalETHAmount: data.totalETHAmount || 0,
                totalPayout: data.totalPayout || 0,
                totalReferee: data.totalReferee || 0,
                totalRefereeVolume: data.totalRefereeVolume || 0,
                totalWon: data.totalWon || 0,
                total_invited: data.total_invited || 0,
                total_invited_locked: data.total_invited_locked || 0,
                total_locked: data.total_locked || 0,
                totalIV: data.totalIV || 0,
                userName: data.userName || "",
                email: data.email || "",
                smart_account: data.smart_account || "",
                picture: data.picture || "",
              };
              setUserInfo(userData);
            }
          }
        } catch (error) {
          console.log(
            "🚀 ~ file: LoginScreenDesktop.tsx:162 ~ getUserInfo ~ error:",
            error
          );
        }
      };
      getUserInfo().then();
    }
  }, [address]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsGoogleLoading(true);
      setGoogleToken(tokenResponse.access_token);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }
      );

      setSocialId(userInfo.data.sub);
      setSocialtype(SocialType.GOOGLE);
      setUserName(userInfo.data.name);
      setEmail(userInfo.data.email);
      setPicture(userInfo.data.picture);
      try {
        await SignInWithFirebase(auth);
        await loginGoogleWrapper(tokenResponse.access_token);
        if (returnUrl) {
          window.location.href = callBackUrl;
        } else {
          navigator(slugs.home)
        };
        // window.location.href = callBackUrl;
      } catch (error: any) {
        if (error?.response) {
          // if (error.response.data.message === AuthStep.Link) {
          // 	setStep(AuthStep.Link);
          // } else if (error.response.data.message === AuthStep.LinkEOA) {
          // 	setStep(AuthStep.LinkEOA);
          // }
          setStep(AuthStep.LinkEOA);
        }
      } finally {
        setIsGoogleLoading(false);
      }
    },
    onError(errorResponse) {
      console.log("errorResponse", errorResponse);
    },
    onNonOAuthError: (err) => {
      console.log(err);
      setIsGoogleLoading(false);
    },
  });

  const applyReferralCode = async () => {
    try {
      setIsLoading(true);
      setIsApplySuccess(false);
      const resp = await enterInviteCode(inviteCode);

      if (resp.data) {
        if (googleToken) await applyEOA();
        setIsApplySuccess(true);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const applyEOA = async () => {
    setIsLoading(true);
    setIsApplySuccess(false);
    try {
      await SignInWithFirebase(auth);

      const resp = await linkWallet(googleToken, socialType, address);
      if (resp.data) {
        setIsApplySuccess(true);
      }
      setIsLoading(false);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const applyWalletSession = async () => {
    try {
      if (!address || !signer) {
        toast.error("Error code: NTAS"); // Not found address | Signer ❌
        return;
      }
      setIsSessionLoading(true);

      const existSA = await eoa.checkExist(address);
      if (!existSA) {
        await eoa.deploySmartAccount(signer, address);
      }

      const accountContract = await eoa.getAccountContractFromAddress(
        address,
        signer
      );

      const { validAfter, validUntil, pubKey, privateKey } =
        await prepareCreateSessionParams();

      await (
        await accountContract.setSession(
          pubKey,
          validAfter.toString(),
          validUntil.toString(),
          {
            value: BigNumber.from(0),
          }
        )
      ).wait();
      await createSession(address as string, privateKey);
      setIsApproveSession(true);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsSessionLoading(false);
    }
  };

  const afterLinkWalletAction = async () => {
    try {
      setLinkedWalletLoading(true);
      const checkWalletExistRes = await checkWalletExist(address as string);
      console.log("checkWalletExistRes", checkWalletExistRes);
      if (!checkWalletExistRes.data || !userInfo?.invitation_code) {
        setStep(AuthStep.Invitation);
        setIsApplyWalletSuccess(true);
      } else {
        const resp = await linkWallet(
          googleToken,
          socialType,
          address as string
        );
        if (resp.data) {
          await loginGoogleWrapper(googleToken);
          navigator(slugs.home);
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
    } finally {
      setLinkedWalletLoading(false);
    }
  };

  const startPlaying = async () => {
    try {
      await SignInWithFirebase(auth);
      await loginGoogleWrapper(googleToken);
      await applyReferralCode();
      navigator(slugs.home);
    } catch (error: any) {
      console.log("error", error);
      if (error?.response) {
        toast.error(error?.response?.data?.message);
      }
    }

    if (returnUrl) {
      window.location.href = callBackUrl;
    } else {
      navigator(slugs.home)
    };
  };

  const afterLogin = async (address: string) => {
    try {
      console.log("run one signal!!!");
      await OneSignal.login(address);
      console.log("run one signal done!!!");
    } catch (error) {
      console.log(
        "🚀 ~ file: LoginScreenMobile.tsx:105 ~ loginWrapper ~ error:",
        error
      );
    }
    await notiApis.postTriggerUser(address);
  };

  const loginGoogleWrapper = async (googleToken: string) => {
    const res = await loginSocial(googleToken, SocialType.GOOGLE);
    const token = res.data.access_token;
    updateToken(token);
    const address = parseAddressFromToken(token);
    saveItem(ADDRESS, address);
    loadDataByAddress(address);

    await afterLogin(address);
  };

  // ========= Login with metamask =========
  const { signMessageAsync } = useSignMessage();
  const { isConnected } = useAccount();
  const { connect, connectors } = useConnect({
    onSuccess: async (data) => {
      const signer = await data.connector?.getWalletClient();
      await login(walletClientToSigner(signer as any));
    },
    onError: () => {
      setIsLoadingMetamask(false);
      toast.error("Error code: CEF"); // check exist failure ❌
    },
  });
  const { switchNetworkAsync } = useSwitchNetwork();

  const connectorMetamask = connectors.find(
    (connector) => connector.id === "metaMask"
  );

  const login = async (signer: Signer) => {
    try {
      const address = await signer?.getAddress();

      const sessionExist = await bookMarkerApi.auth.checkExist(address);

      if (!sessionExist) {
        const isApproved = await eoa.checkApproved(address);

        // if (!isApproved) {
        if ((await signer.getChainId()) !== CHAIN_ID) {
          await switchNetworkAsync?.(CHAIN_ID);
        }

        if (!(await eoa.checkExist(address))) {
          await eoa.deploySmartAccount(signer!, address);
        }

        const accountContract = await eoa.getAccountContractFromAddress(
          address,
          signer
        );

        const { validAfter, validUntil, pubKey, privateKey } =
          await prepareCreateSessionParams();

        await (
          await accountContract.setSession(
            pubKey,
            validAfter.toString(),
            validUntil.toString(),
            {
              value: BigNumber.from(0),
            }
          )
        ).wait();
        await createSession(address as string, privateKey);
        // }
      }

      const nonce = await bookMarkerApi.auth.getNonce(address);
      const statement =
        "Welcome. By signing this message you are verifying your digital identity. This is completely secure and does not cost anything!";

      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement,
        uri: window.location.origin,
        version: "1",
        chainId: CHAIN_ID,
        nonce: nonce,
      });

      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      });

      try {
        const { access_token: accessToken } =
          await bookMarkerApi.auth.loginWallet(message, signature);

        updateToken(accessToken);
        saveItem(ADDRESS, address);
      } catch (error: any) {
        setError(error);
        return;
      }
      // waiting for firebase
      await SignInWithFirebase(auth);

      const userRef = await getDoc(userDocRef(address));
      if (userRef.exists() && (!userRef.data()?.invitation_code)) {
        setStep(AuthStep.Invitation);
      } else {
        if (returnUrl) {
          window.location.href = callBackUrl;
        } else {
          navigator(slugs.home)
        };
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoadingMetamask(false);
    }
  };

  const nextButtonRef = useRef<HTMLButtonElement>(null);

  const handleLoginMetamask = async () => {
    setIsLoadingMetamask(true);
    await disconnect();
    await sleep(200);
    nextButtonRef.current?.click();
  };

  // ========= End: Login with metamask =========

  const name = userName
    ? userName
    : address
      ? `${address.slice(0, 6)}...${address.slice(-6)}`
      : "-";

  return (
    <>
      {step === AuthStep.Login && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>
          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-screen-sm md:max-w-sm text-center p-6 pb-8 lg:p-0">
                <div className="flex flex-col gap-6">
                  <div className="mx-auto scale-110 relative -left-1">
                    <div className="inline-flex flex-shrink-0 items-center">
                      <div className="flex items-center">
                        <LogoSvg className="w-14 h-14" />
                      </div>
                    </div>
                  </div>

                  <div className="mx-auto text-base">
                    <h2 className="text-2xl font-semibold">Welcome to Goal3</h2>
                    <p className="mt-6">
                      The First Gasless, No-KYC & On-chain Betting App on
                      zkSync.
                    </p>
                  </div>
                </div>
                <div className="mt-12 mx-auto grid grid-cols-1 gap-3">
                  <button
                    className="btn btn-primary justify-between gap-3"
                    onClick={() => {
                      googleLogin();
                      setIsGoogleLoading(true);
                    }}
                    disabled={isGoogleLoading}
                  >
                    <span className="flex items-center gap-3">
                      {isGoogleLoading ? (
                        <i className="fa-solid fa-spinner-third animate-spin"></i>
                      ) : (
                        <span className="bg-base-100 rounded-full p-1">
                          <GoogleSvg className="w-5 h-5 min-w-[20px] cursor-pointer" />
                        </span>
                      )}
                      <span className="text-base">Sign in with Google</span>
                    </span>
                    <span>
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </button>
                  <button
                    className="btn justify-between gap-3"
                    disabled={isLoadingMetamask}
                    onClick={handleLoginMetamask}
                  >
                    <span className="flex items-center gap-3">
                      {isLoadingMetamask ? (
                        <i className="fa-solid fa-spinner-third animate-spin"></i>
                      ) : (
                        <i className="fa-duotone fa-wallet icon-primary text-lg"></i>
                      )}
                      <span className="text-base">Sign in with Wallet</span>
                    </span>
                    <span>
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </button>
                  {error && (
                    <div className="mt-2 px-3 py-2 bg-error/10 text-error rounded-box flex gap-3 text-sm">
                      <i className="fa-solid fa-triangle-exclamation mt-1"></i>
                      <span>{error}</span>
                    </div>
                  )}
                  <button
                    ref={nextButtonRef}
                    className="hidden"
                    onClick={() => {
                      setError("");
                      connect({ connector: connectorMetamask });
                    }}
                  ></button>
                  <div className="flex items-center justify-center relative my-1">
                    <span className="h-[1px] w-full absolute inset-x-0 bg-base-content bg-opacity-10"></span>
                    <span className="bg-base-200 relative z-0 text-xs uppercase px-2 rounded-full leading-none tracking-wide">
                      Or
                    </span>
                  </div>
                  <a
                    className="btn btn-block btn-outline justify-center border-base-content/10 hover:bg-transparent hover:text-base-content hover:border-base-content/20"
                    href={callBackUrl}
                  >
                    <span className="flex items-center gap-3">
                      <span className="text-base">I'll sign in later</span>
                    </span>
                  </a>
                  
                </div>

                {/* <footer className={"mt-16 text-center text-sm"}>
                <div className={"container px-2 md:px-4 flex flex-col items-center justify-center"}>

                  <div className={"flex flex-row items-center space-x-4 cursor-pointer mt-4"}>
                    <a
                      href={"https://blog.goal3.xyz/"}
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-slate-400 text-opacity-70 hover:text-primary transition"
                    >
                      <i className="fa-brands fa-medium text-xl"></i>
                    </a>
                    <a
                      href={"https://twitter.com/Goal3_xyz"}
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-slate-400 text-opacity-70 hover:text-primary transition"
                    >
                      <i className="fa-brands fa-twitter text-xl"></i>
                    </a>
                    <a
                      href={"http://discord.gg/goal3"}
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-slate-400 text-opacity-70 hover:text-primary transition"
                    >
                      <i className="fa-brands fa-discord text-xl"></i>
                    </a>
                  </div>

                  <div className={"text-slate-400 text-opacity-70"}>&copy; 2023 Goal3. All Rights Reserved.</div>

                </div>
              </footer> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {step === AuthStep.Link && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/70 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-10 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-md text-center p-4 lg:p-0">
                <div className="absolute top-0 right-0 left-0 flex justify-center pt-6">
                  <button
                    type="button"
                    className="btn btn-md px-3 text-xs md:text-sm !inline-flex gap-3"
                    onClick={() => {
                      disconnect();
                      setIsApproveSession(false);
                      setIsApproveZkUSD(false);
                      setStep(AuthStep.Login);
                    }}
                  >
                    {picture && (
                      <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100 flex items-center justify-center">
                        <img
                          height="60"
                          width="60"
                          src={picture}
                          alt="Avatar"
                        />
                      </div>
                    )}
                    <span className="text-xs md:text-sm">{name}</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                </div>

                <div className="">
                  <span className="fa-stack fa-2x mb-8">
                    <i className="fa-duotone fa-wallet icon-primary fa-stack-2x"></i>
                  </span>

                  <h2 className="text-2xl font-bold mb-4">
                    Prepare Your Wallet
                  </h2>

                  <div className="mt-4 mx-auto text-base">
                    <p>
                      Link your crypto wallet or use your Google Account as your
                      wallet
                    </p>
                  </div>
                </div>

                <div className="mt-12 max-w-sm mx-auto flex flex-col gap-3  justify-center items-center">
                  {/* <button
										onClick={() => {
											setStep(AuthStep.Invitation);
										}}
										className="btn btn-primary btn-block"
									>
										<span className="text-base">Use My Google Account</span>
										<i className="fa-solid fa-angle-right ml-3"></i>
									</button> */}
                  <button
                    onClick={() => {
                      setStep(AuthStep.LinkEOA);
                    }}
                    className="btn btn-outline border-base-content/20 btn-block"
                  >
                    <span className="text-base">Link Crypto Wallet</span>
                  </button>
                  {/* <ConnectButton /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === AuthStep.LinkEOA && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-sm text-center p-4 lg:p-0">
                <div className="absolute top-0 right-0 left-0 flex justify-center pt-6">
                  <button
                    type="button"
                    className="btn btn-md px-3 text-xs md:text-sm !inline-flex gap-3"
                    onClick={() => {
                      disconnect();
                      setIsApproveSession(false);
                      setIsApproveZkUSD(false);
                      setStep(AuthStep.Login);
                    }}
                  >
                    {picture && (
                      <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100 flex items-center justify-center">
                        <img
                          height="60"
                          width="60"
                          src={picture}
                          alt="Avatar"
                        />
                      </div>
                    )}
                    <span className="text-xs md:text-sm">{name}</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center">
                    <i className="fa-duotone fa-wallet icon-primary text-3xl"></i>
                  </div>

                  <h2 className="text-2xl font-semibold">Link Your Wallet</h2>

                  <div className="text-base leading-relaxed">
                    Please follow the instructions below to link your crypto
                    wallet
                  </div>

                  <div className="bg-base-100/20 text-base-content/70 rounded-xl shadow-2xl p-6">
                    <ul className="steps steps-vertical w-full">
                      <li
                        className={
                          status === "connected"
                            ? "step step-neutral"
                            : "step step-primary"
                        }
                      >
                        <RawWalletConnect />
                      </li>
                      <li
                        className={
                          status === "connected" && !isApproveSession
                            ? "step step-primary"
                            : "step step-neutral"
                        }
                      >
                        <button
                          className={`btn btn-md text-sm ${status === "connected" &&
                            !isApproveSession &&
                            !isCheckingSession &&
                            !chain?.unsupported
                            ? "btn-primary"
                            : "btn-disabled"
                            }`}
                          onClick={applyWalletSession}
                          disabled={isSessionLoading || chain?.unsupported}
                        >
                          {((status === "connected" && !isApproveSession) ||
                            status !== "connected") && (
                              <>
                                {(isSessionLoading || isCheckingSession) &&
                                  !chain?.unsupported ? (
                                  <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
                                ) : (
                                  <i className="fa-regular fa-link-simple text-lg mr-3"></i>
                                )}
                                {isCheckingSession ? (
                                  <span className="">
                                    Checking Session Approval
                                  </span>
                                ) : (
                                  <span className="">Approve Your Session</span>
                                )}
                              </>
                            )}
                          {status === "connected" && isApproveSession && (
                            <>
                              <i className="fa-solid fa-circle-check text-success text-lg mr-3"></i>
                              <span className="">Session approved</span>
                            </>
                          )}
                        </button>
                      </li>
                      {/* <li
												className={`${
													status === "connected" && isApproveSession && !isApproveZkUSD
														? "step step-primary"
														: "step step-neutral"
												}`}
											>
												<button
													className={`btn btn-md text-sm ${
														status === "connected" && isApproveSession && !isApproveZkUSD ? "btn-primary" : "btn-disabled"
													}`}
													onClick={applyZkUSD}
												>
													{((status === "connected" && isApproveSession && !isApproveZkUSD) ||
														(status === "connected" && !isApproveSession) ||
														status !== "connected") && (
														<>
															<img
																alt="zkUSD"
																className="h-5 cursor-pointer mr-3 border-2 border-base-300/50 rounded-full"
																src="/currency-icons/zkUSD.png"
															/>
															<span className="">Approve zkUSD</span>
														</>
													)}
													{status === "connected" && isApproveSession && isApproveZkUSD && (
														<>
															<i className="fa-solid fa-circle-check text-success text-lg mr-3"></i>
															<span className="">zkUSD approved</span>
														</>
													)}
												</button>
											</li> */}
                    </ul>
                  </div>
                </div>
                <div className="mt-12">
                  <button
                    className={`btn btn-primary btn-block ${status === "connected" && isApproveSession
                      ? ""
                      : "btn-disabled"
                      }`}
                    onClick={afterLinkWalletAction}
                    disabled={linkedWalletLoading}
                  >
                    {linkedWalletLoading ? (
                      <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
                    ) : null}
                    <span className="text-base">Continue</span>
                    <i className="fa-solid fa-angle-right ml-3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === AuthStep.Invitation && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/70 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-10 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-sm text-center p-4 lg:p-0">
                <div className="absolute top-0 right-0 left-0 flex justify-center pt-6">
                  <button
                    type="button"
                    className="btn btn-md px-3 text-xs md:text-sm !inline-flex gap-3"
                    onClick={() => {
                      setStep(AuthStep.Login);
                      disconnect();
                    }}
                  >
                    {picture && (
                      <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100 flex items-center justify-center">
                        <img
                          height="60"
                          width="60"
                          src={picture}
                          alt="Avatar"
                        />
                      </div>
                    )}
                    <span className="text-xs md:text-sm">{name}</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                </div>

                <div className="">
                  <i className="fa-duotone fa-hand-holding-heart icon-primary text-6xl mb-8"></i>

                  <h2 className="text-3xl">Invitation Code</h2>

                  <div className="mt-4 mx-auto text-base">
                    <p>
                      To access Goal3, you'll need a referral code.
                      <br /> Our 3-Tier Referral Program rewards early joiners
                      with more $ZKG.{" "}
                      <a
                        href="https://docs.goal3.xyz/referral-program/referral-code"
                        target={"_blank"}
                        rel="noreferrer"
                        className="link text-primary"
                      >
                        How to get one?
                      </a>
                    </p>
                  </div>

                  <div className="mt-6 pl-6 pr-2 bg-success/10 border border-base-content/20 rounded-box shadow-2xl flex items-center justify-between gap-4 text-left">
                    <div className="flex-1">
                      <input
                        type="text"
                        placeholder="Invitation code"
                        value={inviteCode}
                        onChange={(e) => {
                          setInviteCode(e.target.value);
                        }}
                        className="input input-ghost input-lg !p-0 w-full"
                      />
                    </div>
                    {isLoading ? (
                      <i className="fa-solid fa-circle-notch fa-spin text-lg text-primary mr-2"></i>
                    ) : isApplySuccess ? (
                      <i className="fa-solid fa-check text-lg text-success mr-2"></i>
                    ) : (
                      <button
                        className="btn btn-md"
                        onClick={isApplyWalletSuccess ? applyEOA : applyReferralCode}
                      >
                        Apply
                      </button>
                    )}
                  </div>

                  {/* <div className="mt-6 flex items-center justify-center gap-2">
                    Default Code:
                    <div className="bg-primary/20 rounded-full pl-3 pr-1">
                      <span className="text-primary tracking-wider">goal3</span>
                      <button
                        className="btn btn-sm btn-ghost text-primary btn-circle"
                        onClick={handleCopyCode}
                      >
                        {isCopiedCode ? (
                          <i className="fa-solid fa-copy"></i>
                        ) : (
                          <i className="fa-regular fa-copy"></i>
                        )}
                      </button>
                    </div>
                  </div> */}
                </div>

                <div className="mt-12">
                  <button
                    className={`btn btn-primary btn-block ${isApplySuccess ? "" : "btn-disabled"
                      }`}
                    onClick={startPlaying}
                  >
                    <span className="text-base">Start Playing</span>
                    <i className="fa-solid fa-angle-right ml-3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default LoginScreenPage;
