import React from "react";

const skClass = "bg-base-100/40 h-6 w-full rounded-box";
interface LoadingSkeletonProps {
  rows?: number;
}
const LoadingSkeleton: React.FC<LoadingSkeletonProps> = (props) => {
  const { rows = 5 } = props;
  const rowsList = Array.from(Array(rows).keys());
  return (
    <div
      className={
        "skeleton-loading w-full flex flex-col items-center justify-center space-y-2 py-4"
      }
    >
      <div className={"w-full grid grid-cols-12 gap-2 rounded-box"}>
        <div className={`col-span-2 ${skClass}`}></div>
        <div className={`col-span-3 ${skClass}`}></div>
        <div className={`col-span-4 ${skClass}`}></div>
        <div className={`col-span-3 ${skClass}`}></div>
      </div>
      {rowsList.map((e: any, idx: number) => {
        return (
          <div key={idx} className={`w-full grid grid-cols-12 gap-2`}>
            <div className={`col-span-2 ${skClass}`}></div>
            <div className={`col-span-3 ${skClass}`}></div>
            <div className={`col-span-4 ${skClass}`}></div>
            <div className={`col-span-3 ${skClass}`}></div>
          </div>
        );
      })}
    </div>
  );
};
export default LoadingSkeleton;
