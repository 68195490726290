import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  NotiElement as NotiProps,
  notitficationState,
} from "proxy-state/notificationProxy";
import { notiApis } from "utils/apis";
import { modalFunc } from "proxy-state/global";

export interface NotiElementProps {
  idx: number;
  data: NotiProps;
}

const NotiElement: React.FC<NotiElementProps> = ({ data, idx }) => {
  const navigate = useNavigate();
  const type = data.notification.type || "system";
  // const time = new Date(data.notification.created_at).toLocaleString();
  const time = moment(data.notification.created_at).format("ddd, DD MMM");
  const isWon = data.notification.title.toLowerCase().includes("won");
  const isCancel = data.notification.title.toLowerCase().includes("cancelled");

  const putUpdateRead = async () => {
    try {
      return await notiApis.putReadNotification(data.id);
    } catch (error) {
      console.log("====================================");
      console.log("error ====>", error);
      console.log("====================================");
      return false;
    }
  };
  return (
    <button
      onClick={() => {
        putUpdateRead().then(() => {
          notitficationState.setRead(data.notification.id);
          if (!data?.notification?.launch_url) return;

          const launch_url = data?.notification?.launch_url;
          let domain = new URL(launch_url);

          if (domain?.hostname === window?.location?.hostname) {
            notitficationState.setShow(false);
            navigate(domain.pathname, {
              state: { shallow: true },
            });
          } else {
            window.open(launch_url);
          }
        });
      }}
      key={idx}
      className={`${
        data.status === "clicked" ? "opacity-50 " : ""
      }rounded-box bg-base-100/50 p-4 flex items-center justify-between gap-4 text-left text-sm text-base-content/70`}
    >
      <span className="flex items-center justify-center w-8">
        {type === "promotion" && <i className="fa-duotone fa-gift text-xl"></i>}
        {type === "update" && (
          <i className="fa-sharp fa-solid fa-clock text-xl"></i>
        )}
        {type === "system" && <i className="fa-duotone fa-rocket text-xl"></i>}
        {type === "bet" && <i className="fa-duotone fa-receipt text-xl"></i>}

        {type === "soccer" && <i className="fa-duotone fa-futbol text-xl"></i>}
        {type === "football" && (
          <i className="fa-duotone fa-football text-xl"></i>
        )}
        {type === "basketball" && (
          <i className="fa-duotone fa-basketball text-xl"></i>
        )}
        {type === "mma" && <i className="fa-duotone fa-hand-fist text-xl"></i>}
        {type === "baseball" && (
          <i className="fa-duotone fa-baseball-bat-ball text-xl"></i>
        )}
      </span>

      <span className="flex-1 flex flex-col">
        <span className="flex items-center text-xs mb-2">
          {type === "bet" && !isWon && (
            <strong className="text-error font-bold">
              {data.notification.title}
            </strong>
          )}
          {type === "bet" && isWon && (
            <strong className="text-success font-bold">
              {data.notification.title}
            </strong>
          )}
          {type === "bet" && isCancel && (
            <strong className="text-accent font-bold">
              {data.notification.title}
            </strong>
          )}

          {type === "system" && (
            <strong className="text-primary font-bold">
              {data.notification.title}
            </strong>
          )}

          {type !== "bet" && type !== "system" && (
            <strong className="text-base-content font-bold">
              {data.notification.title}
            </strong>
          )}

          <span className="mx-2">•</span>
          <span className="">{time}</span>
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: data.notification.content_html || data.notification.content,
          }}
        />
      </span>

      {type !== "system" && (
        <span className="">
          <i className="fa-solid fa-angle-right"></i>
        </span>
      )}
    </button>
  );
};
export default NotiElement;
