import HomeProvider from "contexts/homeContext";
import { useParams } from "react-router-dom";
import { CategoryList } from "components/organisms/Sports/Category";
import CategoryNav from "components/organisms/Sports/utils/categoryNav";

export default function Category() {
  let { category } = useParams<"category">();
  return (
    <HomeProvider>
      <CategoryNav root={"sports"} />
      <div className="container">
        <CategoryList cate={category || "soccer"} league={""} />
      </div>
    </HomeProvider>
  );
}
