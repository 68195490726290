import { DataChain } from "contracts/config";
import { ethers } from "ethers";
export const getAddressSimpleString = (address: string, length = 3) => {
  if (!address) return "";

  return (
    address.substring(0, length) +
    "..." +
    address.substring(address.length - length, address.length)
  );
};

export const getProvider = (chainId: string) => {
  const rpcUrl = DataChain[Number(chainId)].NEXT_PUBLIC_RPC_URL;
  const provider = new ethers.providers.StaticJsonRpcProvider(rpcUrl);
  return provider;
};

export * from "./eoa-aa";
export * from "./helper";
