import {proxy} from 'valtio';
import { League } from 'interfaces/firebase';
const LeagueNavProxy: {
	leagueTab: string;
	leagueTabList: League[];
	setLeagueTab: (tab: string) => void;
	setLeagueTabList: (tabList: League[]) => void;
} = proxy({
	leagueTab: 'soccer',
	leagueTabList: [],
	setLeagueTab: (tab) => {
		LeagueNavProxy.leagueTab = tab;
	},
	setLeagueTabList: (tabList) => {
		LeagueNavProxy.leagueTabList = tabList;
	}
});
export default LeagueNavProxy;