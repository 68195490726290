import { navFunc } from "proxy-state/global";
import { Matches } from "./sportsInterface";
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

export const getTitleMatches = (item: Matches) => {
  const orderSlipClosed = () => {
    isMobile && navFunc.setNavOpen("isShowOrder", false);
  }
  return (
    <>
      <Link onClick={orderSlipClosed} to={item.matches_url} className="hover:text-primary transition" >
        {item.icon_classes && <i className={`${item.icon_classes} mr-1`}></i>}
        {item.home}
        <span className="text-xs opacity-50 mx-1 font-normal">vs.</span>
        {item.away}
      </Link>
    </>
  );
};
