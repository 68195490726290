import React, { Fragment } from "react";
import { BigNumber } from "../../../functions/betUtils";
import OddType1 from "./odd-type-1";
import OddType2 from "./odd-type-2";
import OddType3 from "./odd-type-3";
import MoneyLine from "./money-lines";
import OddType5 from "./odd-type-5";
import OddType6 from "./odd-type-6";
import { getMarketOdds } from "../../../functions/event";
import { _getOdds } from "../../../functions/amm-v2";
import { useSnapshot } from "valtio";
import { outcomeProxy } from "../../../proxy-state/outcomeProxy";
import { OutcomeLiquidity } from "../../../interfaces/firebase";
import { liquidProxy } from "proxy-state/betting/Liquidity";

const getMarketLiquid = (
  odds: any,
  outcomes: Map<string, OutcomeLiquidity>
) => {
  let market_liquid = 0;
  for (let odd of odds) {
    let outcomeLiquid = outcomes.get(odd?.outcome_id);
    const liquidity = Number(outcomeLiquid?.liquidity || 0);
    market_liquid += liquidity;
  }
  return market_liquid;
};
const OddElements = ({
  idx,
  market,
  item,
  odds,
  parentUrl,
  type,
  scoreView,
}: any) => {
  const { data: outcomesLiquid } = useSnapshot(outcomeProxy);
  const { sensitivity } = useSnapshot(liquidProxy);

  if (item?.hide_from_frontend && Number(market?.time_status) === 0)
    return null;
  if (odds.length === 0) return null;
  let child: any;

  const marketLiquid = getMarketLiquid(odds, outcomesLiquid);

  const marketOdds = getMarketOdds(
    odds.map((odd: any) => {
      return BigNumber.from(odd.outcome_id ?? 0);
    }),
    [market]
  );

  const oddsFix = odds.map((odd: any, idx: number) => {
    if (odd?.odds === "0" || odd?.odds === "-") return odd;
    let outcomeLiquid = outcomesLiquid.get(odd?.outcome_id);
    const marketOutcomeOdds = marketOdds[idx].marketOutcomeOdds.map((item) =>
      Number(item.odds)
    );
    const marketOutcomeLiquids = marketOdds[idx].marketOutcomeOdds.map(
      (item) => outcomesLiquid.get(item?.outcome_id)?.liquidity || 0
    );

    const odd_roi = _getOdds(
      marketOdds[idx].index,
      marketOutcomeOdds,
      marketOutcomeLiquids,
      sensitivity
    );

    return {
      ...odd,
      liquidity: outcomeLiquid?.liquidity || 0,
      roi: odd_roi,
    };
  });
  switch (Object.keys(odds).length) {
    case 3:
      child = (
        <OddType1
          idx={idx}
          market={market}
          item={item}
          odds={oddsFix}
          parentUrl={parentUrl}
          marketLiquid={marketLiquid}
        />
      );
      break;
    case 2:
      child = (
        <OddType2
          idx={idx}
          market={market}
          item={item}
          odds={oddsFix}
          parentUrl={parentUrl}
          marketLiquid={marketLiquid}
        />
      );
      break;
    default:
      child = (
        <OddType3
          idx={idx}
          market={market}
          item={item}
          odds={oddsFix}
          parentUrl={parentUrl}
          marketLiquid={marketLiquid}
        />
      );
      break;
  }
  if (item?.key === "correct_score") {
    child = scoreView ? (
      <OddType5
        idx={idx}
        market={market}
        item={item}
        odds={oddsFix}
        parentUrl={parentUrl}
        marketLiquid={marketLiquid}
      />
    ) : (
      <OddType3
        idx={idx}
        market={market}
        item={item}
        odds={oddsFix}
        parentUrl={parentUrl}
        marketLiquid={marketLiquid}
      />
    );
  }
  if (item?.key === "alternative_total_goal" || item?.key === "total_rounds") {
    child = (
      <OddType6
        idx={idx}
        market={market}
        item={item}
        odds={oddsFix}
        parentUrl={parentUrl}
        marketLiquid={marketLiquid}
      />
    );
  }
  if (
    item?.key === "to_win_both_halves" ||
    item?.key === "total_goals_both_teams_to_score"
  ) {
    child = (
      <OddType2
        idx={idx}
        market={market}
        item={item}
        odds={oddsFix}
        marketLiquid={marketLiquid}
        parentUrl={parentUrl}
      />
    );
  }
  if (["money_line", "spread", "total"].includes(item?.key)) {
    child =
      type === "sort" ? (
        <MoneyLine
          idx={idx}
          market={market}
          item={item}
          odds={oddsFix}
          parentUrl={parentUrl}
          marketLiquid={marketLiquid}
        />
      ) : item?.key !== "spread" ? (
        <OddType2
          idx={idx}
          market={market}
          item={item}
          odds={oddsFix}
          parentUrl={parentUrl}
          marketLiquid={marketLiquid}
        />
      ) : (
        <OddType3
          idx={idx}
          market={market}
          item={item}
          odds={oddsFix}
          parentUrl={parentUrl}
          marketLiquid={marketLiquid}
        />
      );
  }
  if (item?.key === "total_corners") {
    child = (
      <OddType2
        idx={idx}
        market={market}
        item={item}
        odds={oddsFix}
        parentUrl={parentUrl}
        marketLiquid={marketLiquid}
      />
    );
  }
  
  if (item?.key === 'match_handicap'){
    child = (
      <OddType3
        idx={idx}
        market={market}
        item={item}
        odds={oddsFix}
        parentUrl={parentUrl}
        marketLiquid={marketLiquid}
      />
    );
  }
  // React.useEffect(() => {
  // 	console.log("market ========>", market);
  // }, [market]);

  return (
    <Fragment key={idx}>
      {type === "sort" ? (
        <>{child}</>
      ) : (
        <>
          <div className={`grid divide-y divide-base-content/5`}>
            <div className={`flex flex-col py-4`}>{child}</div>
          </div>
        </>
      )}
    </Fragment>
  );
};
export default OddElements;
