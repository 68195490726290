import React, { Fragment } from "react";
import Markets from "../../Markets";
import { Disclosure } from "@headlessui/react";
import { League } from "interfaces/firebase";
import _ from "lodash";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";

const HomeLeaguesList = ({
  root,
  cat,
  leagueData,
  eventsData,
  type = "sort",
  lv,
  filter,
}: any) => {
  const marketGroupClass = "disclosure";
  const child = leagueData.map((item: League, idx: number) => {
    let __eventsData = _.orderBy(eventsData, ["time"], ["asc"]);
    if (__eventsData.length > 3 && !lv) __eventsData = __eventsData.slice(0, 3);
    
    return (
      <Fragment key={idx}>
        {/* Market List 1 */}

        <Disclosure
          as="div"
          className={marketGroupClass}
          defaultOpen={item?.has_toplist === 1 ? true : false}
        >
          {({ open }) => (
            <>
              {/* {!lv && ( */}
                <Disclosure.Button className="disclosure-btn">
                  <div className="flex items-center">
                    {item?.icon_classes && (
                      <span className="flex flex-shrink-0 items-center justify-center mr-2">
                        <i className={`${item?.icon_classes}`}></i>
                      </span>
                    )}
                    {!item?.icon_classes && item.image && item.image.length && (
                      <span className="flex flex-shrink-0 items-center justify-center mr-2">
                        <img
                          className={`block relative m-auto`}
                          width="15px"
                          src={`${
                            item.image && item.image.length
                              ? item.image[0]?.downloadURL
                              : "/placeholder.svg"
                          }`}
                          alt={`${item.id}`}
                        />
                      </span>
                    )}
                    <h3 className="text-sm">
                      <Link
                        to={`${slugs.sports}/${item?.url_path?.replace(
                          "sports/",
                          ""
                        )}`}
                        className="hover:text-primary transition"
                      >
                        {item.name}
                      </Link>
                    </h3>
                  </div>
                  <div>
                    {!open ? (
                      <>
                        <span className="badge badge-rounded mr-4">{item.eventCount}</span>
                        <i className="fa-solid fa-caret-right text-sm transition-all"></i>
                      </>
                    ) : (
                      <>
                        <i className="fa-solid fa-caret-right text-sm rotate-90 transition-all text-base-content"></i>
                      </>
                    )}
                  </div>
                </Disclosure.Button>
              {/* )} */}

              <Disclosure.Panel className="disclosure-panel fadein">
                <Markets
                  parentUrl={`${slugs.sports}/${item?.url_path.replace(
                    "sports/",
                    ""
                  )}`}
                  category={item?.category_id}
                  league={item?.id}
                  eventsData={__eventsData}
                  eventFilter={filter}
                  type={type}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Fragment>
    );
  });

  return (
    <Fragment>
      {child}
    </Fragment>
  );
};
export default HomeLeaguesList;
