import React, { Fragment, useEffect } from "react";
import { getItem, ORDER_DATA } from "local-storage";
import { betOrder } from "proxy-state/betting/OrderSlip";
import { SignInWithFirebase } from "utils/firebase/firebaseAuth";
import { myProfileProxy } from "../../proxy-state/myProfileProxy";
import { outcomeProxy } from "../../proxy-state/outcomeProxy";
import { categoryProxy } from "../../proxy-state/categoryProxy";
import { OrderSlipFunc, orderSlipProxy } from "proxy-state/betting/OrderSlip";

import { myBalanceProxy } from "proxy-state/myBalanceProxy";

import { useBalance } from "hooks/useBalance";
import { leagueProxy } from "../../proxy-state/leagueProxy";
import { eventProxy } from "../../proxy-state/eventProxy";
import { useFetchUserWager } from "./hooks/useFetchUserWager";
import { useSnapshot } from "valtio";
import { useAuth } from "contexts/authContext";
import { auth } from "utils/firebase/config2";
import { configProxy } from "proxy-state/configProxy";
import { liquidProxy } from "proxy-state/betting/Liquidity";
import { useFetchNoti } from "./hooks/useFetchNoti";
import { parseAddressFromToken } from "utils/index";

interface Props {
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ children }) => {
  const { token } = useAuth();
  const [firebaseAuth, setFirebaseAuth] = React.useState<boolean>(auth?.currentUser ? true : false);
  useFetchUserWager();
  useBalance();
  useFetchNoti();

  const { items: orderSlip } = useSnapshot(orderSlipProxy);
  // const { address } = useAccount();
  // const accessToken = getItem(ACCESS_TOKEN) || "";
  const address: string = token ? parseAddressFromToken(token) : '';

  const LOCAL_betOrder_storage = getItem(ORDER_DATA);
  useEffect(() => {
    if (auth && !auth?.currentUser) {
      const SignFirebaseAuth = async (auth: any) => {
        await SignInWithFirebase(auth);
        setFirebaseAuth(true);
      };
      SignFirebaseAuth(auth);
    }
  }, [auth])

  useEffect(() => {
    if (LOCAL_betOrder_storage) {
      const data: betOrder[] = JSON.parse(LOCAL_betOrder_storage) || [];
      if (Array.from(orderSlip.values()).length === data.length) return;
      OrderSlipFunc.setItems(data);
    }
  }, [LOCAL_betOrder_storage]);

  useEffect(() => {
    if (!firebaseAuth) return;

    setTimeout(() => {
      eventProxy.loadActiveEvent();
      outcomeProxy.load();
    }, 100);
    configProxy.loadConfig();
    configProxy.loadThreshold();
    liquidProxy.load();
    categoryProxy.load();
    leagueProxy.load();

    return () => {
      outcomeProxy.unsubscribeDb();
      categoryProxy.unsubscribeDb();
      eventProxy.unsubscribeDb();
      leagueProxy.unsubscribeDb();
      configProxy.unsubscribeDb();
    };
  }, [firebaseAuth]);

  useEffect(() => {
    if (!address) return;
    myProfileProxy.load(address);
    return () => {
      myProfileProxy.unsubscribeDb();
    };
  }, [address]);

  useEffect(() => {
    if (!address) return;
    myBalanceProxy.getBalance(address);

    const interval = setInterval(() => {
      myBalanceProxy.getBalance(address);
    }, 1000 * 60);

    return () => {
      clearTimeout(interval);
    };
  }, [address]);

  return (
    <Fragment>{children}</Fragment>
  );
};

export default Container;
