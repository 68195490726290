import React from "react";
import BetSkeleton from "./BetSkeleton";
interface OrderHistoryProps {
  length: number;
}
const HistorySkeleton: React.FC<OrderHistoryProps> = ({ length }) => {
  return (
    <>
      {Array.from(Array(length).keys()).map((i) => (
        <BetSkeleton key={i} />
      ))}
    </>
  );
};
export default HistorySkeleton;
