import { BigNumber } from "ethers";
const MIN_ROI = 1;
const WEI = 1000000;
const WEI6 = BigNumber.from(10).pow(18);
const MAX_WEIGHT = BigNumber.from(10).pow(6).mul(900).div(1000);
const MAX_STRENGTH = BigNumber.from(10).pow(6).mul(990).div(1000);
const PERCENT_BASE = 1000;
const RAKE = 40;
const ROI_ADJUST_RATIO = 200;

export const AMM = {
  WEI6: WEI6,
  WEI: WEI,
  config: {
    WEI6: WEI6,
    WEI: WEI,
    MAX_WEIGHT: MAX_WEIGHT,
    MAX_STRENGTH: MAX_STRENGTH,
    liquidLimit: Number(process.env.NEXT_PUBLIC_LIQUID_LIMIT),
    maxAmount: Number(process.env.NEXT_PUBLIC_MAX_AMOUNT),
  },
  getRoi: (
    strength: any,
    liquid: number,
    marketLiquid: number,
    availLP: number
  ) => {
    if (availLP == 0) return MIN_ROI;
    let _strength = strength;

    if (marketLiquid > 0) {
      // console.log('Get roi', strength, liquid, marketLiquid, availLP)
      let _base = availLP;
      let _astrength = (liquid * WEI) / marketLiquid;
      let _delta = 0;
      // console.log(_astrength, _strength)
      if (_astrength > _strength) {
        _delta = (_astrength - _strength) / 2;
        if (marketLiquid < _base)
          _delta = Math.round((_delta * marketLiquid) / _base);
        // console.log("delta: ", _delta)
        _strength += _delta;
      } else {
        _delta = (_strength - _astrength) / 4;
        if (marketLiquid < _base) _delta = (_delta * marketLiquid) / _base;
        _strength -= _delta;
      }
    }

    if (_strength == 0) return MIN_ROI;

    let roi = Math.round((WEI * PERCENT_BASE) / _strength);
    roi = Math.round((roi * (PERCENT_BASE - RAKE)) / PERCENT_BASE);
    return roi < MIN_ROI ? MIN_ROI : roi;
  },
  getOdd: (odd: any, liquid: any, marketLiquid: number, limitLiquid: any) => {
    let _astrength = BigNumber.from(WEI6).mul(WEI6).div(odd);
    if (marketLiquid > 0) {
      let _lqW = BigNumber.from(WEI6).mul(marketLiquid).div(limitLiquid);
      if (_lqW > MAX_WEIGHT) _lqW = MAX_WEIGHT;
      _astrength = _astrength
        .mul(BigNumber.from(WEI6).sub(_lqW))
        .add(BigNumber.from(WEI6).mul(liquid).mul(_lqW).div(marketLiquid))
        .div(WEI6);
    }
    if (_astrength > MAX_STRENGTH) _astrength = MAX_STRENGTH;
    const _odd = BigNumber.from(WEI6).mul(WEI6).div(_astrength);
    return _odd;
  },

  estimatePayout: (
    strength: any,
    amount: number,
    liquid: number,
    marketLiquid: number,
    availLP: number
  ) => {
    if (amount < 1) return amount;
    if (availLP < amount) return amount;

    let _estPayout = Math.floor(
      ((AMM.getRoi(strength, liquid, marketLiquid, availLP) - 1) * amount) /
        PERCENT_BASE
    );
    if (_estPayout < amount) return amount;

    let _estProfit = _estPayout - amount;
    if (_estProfit > availLP) _estProfit = availLP;

    let _pi = Math.floor(
      (_estProfit * ROI_ADJUST_RATIO) / (_estProfit + availLP)
    );

    let payout = ((PERCENT_BASE - _pi) * _estProfit) / PERCENT_BASE + amount;

    return payout;
  },

  parseStrengths: (odds: any[]) => {
    let _totalStrength = 0;
    let _strengths = [];
    for (let i = 0; i < odds.length; i++) {
      _strengths[i] = Math.round(WEI / odds[i]);
      _totalStrength += _strengths[i];
    }
    for (let i = 0; i < _strengths.length; i++) {
      _strengths[i] = Math.round((_strengths[i] * WEI) / _totalStrength);
    }
    return _strengths;
  },
  parseOutcomeId: (outcomeId: BigNumber) => {
    let _outcomeId = outcomeId;
    let _marketId = outcomeId.shr(32);
    let _eventId = outcomeId.shr(64);
    return {
      eventId: _eventId.toNumber(),
      marketId: _marketId,
      outcomeId: _outcomeId,
    };
  },

  buildSystemOrders: (comboType: number, outcomes: any[]) => {
    // findout all combo
    let _min = 1;
    let _max = 0;
    let _idx = 0;

    let _temp = [];
    const count = outcomes.length;

    if (comboType <= count) {
      _min = comboType;
      _max = _min;
    } else if (comboType == count + 1) {
      // not include single
      _min = 2;
      _max = count;
    } else if (comboType == count + 2) {
      // include single
      _min = 1;
      _max = count;
    } else {
      return null;
    }

    // build outcomes group

    for (let i = _min; i <= _max; i++) {
      let _cset = AMM._cxofy(i, count);

      // place orders
      for (let j = 0; j < _cset.length; j++) {
        let _aset = _cset[j].map((c) => outcomes[c]);
        _temp[_idx++] = [..._aset];
      }
    }
    return _temp;
  },

  _cxofy: (x: any, y: any) => {
    // calculate number of combo = y!/x!
    let length = 1;
    let x1 = x * 2 < y ? x : y - x;
    for (let i = 0; i < x1; i++) {
      length *= y - i;
    }
    for (let i = 0; i < x1; i++) {
      length /= i + 1;
    }
    let _cset = [];

    let done = false;
    let aset = [];
    let counter = 0;
    let j = 0;
    let idx = 0;

    while (!done) {
      if (counter < x) {
        if (j <= y - (x - counter)) {
          aset[counter] = j;
          counter++;
          j++;
        } else {
          if (counter > 0) {
            counter--;
            j = aset[counter] + 1;
          } else {
            done = true;
          }
        }
      } else {
        // push to result
        _cset[idx++] = [...aset];
        // push back
        counter--;
        if (idx == length) done = true;
      }
    }

    return _cset;
  },
};

export const _estimatePayout = (
  outcomeIds: number[],
  amount: number,
  eventsData: any[],
  availLP: number
) => {
  let _payout = amount;
  for (let i = 0; i < outcomeIds.length; i++) {
    const outcomeId = outcomeIds[i];
    const event = eventsData[outcomeId];
    const liquid = event?.odds?.liquidity;
    const strength = event?.odds?.strength;
    const marketLiquid = event?.market_liquid;
    _payout = AMM.estimatePayout(
      strength,
      _payout,
      liquid,
      marketLiquid,
      availLP
    );
  }

  return _payout;
};
export const estimatePayout = (
  type: number,
  outcomeIds: number[],
  amount: number,
  eventsData: any[],
  availLP: number
) => {
  const outcomesArr = AMM.buildSystemOrders(type, outcomeIds);
  const _payout = outcomesArr?.map((outcomeId: number[]) =>
    _estimatePayout(outcomeId, amount, eventsData, availLP)
  );

  return _payout?.reduce((a, b) => a + b, 0) || 0;
};

if (typeof window !== "undefined") {
  (window as any).amm = AMM;
}
