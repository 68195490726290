import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DocumentData,
  DocumentSnapshot,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useAccount } from "./useAccount";
import { userClaimedWagerCollRef } from "utils/firebase/firebaseDBRef";
import { getStartTime } from "shared/tab-filter";
import { IOrderBy, ITimeFilterType } from "shared/enum";
import { mmFormatUTC } from "use-moment/format";
import { isMobile } from "react-device-detect";
interface ClaimedType {
  id: string;
  amount: number;
  txHash: string;
  created_at: string;
  wagerCount: number;
}
interface filters {
  filter?: string;
  timeFilter: string;
  search?: string;
  page?: number;
}
export enum timeFilterType {
  allTime = "All Times",
  today = "Last 24h",
  yesterday = "Yesterday",
  thisWeek = "Last 7d",
  thisMonth = "Last 30d",
}
const PAGE_SIZE = 15;

const getConstraints = (params: {
  time: ITimeFilterType;
  filterOrderBy?: IOrderBy;
}): QueryConstraint[] => {
  const { time, filterOrderBy = IOrderBy.DESC } = params;

  const _constraints: QueryConstraint[] = [];

  if (time) {
    const startTime = getStartTime(time);
    if (startTime) {
      _constraints.push(where("created_at", ">=", mmFormatUTC(startTime)));
    }
  }

  _constraints.push(orderBy("created_at", filterOrderBy));

  return _constraints;
};
const parseDoc = (doc: QueryDocumentSnapshot<any>): ClaimedType => {

  const _doc = doc.data();
  return {
    id: doc.id,
    amount: _doc.amount,
    txHash: _doc.txHash,
    created_at: _doc.created_at,
    wagerCount: _doc.wagerIds?.length || 0,
  };
};
export const useClaimed = () => {
  const { address } = useAccount();
  // const [claimedData, setClaimedData] = React.useState<ClaimedType[]>([]);
  // const [claimDataPage, setClaimDataPage] =
  //   React.useState<QuerySnapshot<any> | null>(null);
  // const [loading, setLoading] = React.useState<boolean>(false);
  // const [page, setPage] = React.useState<number>(0);
  // const [nextCursorPointer, setNextCursorPointer] =
  //   React.useState<DocumentData | null>(null);
  // const [prevCursorPointer, setPrevCursorPointer] =
  //   React.useState<DocumentData | null>(null);
  // const [filter, setFilter] = React.useState<string>("All");
  // const [timeFilter, setTimeFilter] = React.useState<string>("All Times");
  const [search, setSearch] = React.useState<string>("");
  // const [isNext, setIsNext] = React.useState<boolean>(false);
  // const [isPrev, setIsPrev] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>("");
  const [filterOrderBy, setFilterOrderBy] = React.useState<IOrderBy>(IOrderBy.DESC)
  const [timeFilter, setTimeFilter] = React.useState<ITimeFilterType>(
    ITimeFilterType.allTime
  );
  // ================== new pagination ==================
  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const [count, setCount] = useState(0);
  const [data, setData] = useState<QueryDocumentSnapshot<any, DocumentData>[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [isLoading, setLoading1] = useState<boolean>(true);
  /// fetch raw data
  useEffect(() => {
    const fetchData = async () => {
      if (!address) return;

      try {
        setLoading1(true);
        const constraints = getConstraints({ time: timeFilter, filterOrderBy });
        const cursor = cursors.current.get(page);
        if (cursor) {
          constraints.push(startAfter(cursor));
        }

        const _query: Query<DocumentData> = query(
          userClaimedWagerCollRef(address),
          ...constraints,
          limit(PAGE_SIZE)
        );

        const result: QuerySnapshot<any> = await getDocs(_query);

        if (isMobile) {
          // scroll infinity for mobile
          if (page === 0) {
            setData(result.docs);
          } else {
            setData((data) => data.concat(result.docs));
          }
        } else {
          setData(result.docs);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: useHistory.tsx:181 ~ fetchData ~ error:",
          error
        );
      } finally {
        setLoading1(false);
      }
    };
    fetchData();
  }, [address, page, timeFilter, filterOrderBy]);

  /// fetch count
  useEffect(() => {
    const fetchData = async () => {
      if (!address) return;
      try {
        const constraints = getConstraints({ time: timeFilter, filterOrderBy });
        const snapshot = await getCountFromServer(
          query(userClaimedWagerCollRef(address), ...constraints)
        );
        setCount(snapshot.data()?.count);
      } catch (error) {
        console.log("🚀 ~ file: useClaimed.tsx:158 ~ fetchData ~ error:", error);
      }
    };
    fetchData();
  }, [address, timeFilter, filterOrderBy]);

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        if (!data) return page;

        // first, we save the last document as page's cursor
        cursors.current.set(page + 1, data[data.length - 1]);

        // then we update the state with the next page's number
        return nextPage;
      });
    },
    [data]
  );
  const claimedData = useMemo(() => {
    const _data = data.map((doc) => parseDoc(doc));
    return _data;
  }, [data, address]);
  
  const handleChangeFilter = (value: ITimeFilterType) => {
    handleResetDataOnMobile();
    setPage(0);
    setTimeFilter(value);
  };
  const handleChangeOrderBy = (value: IOrderBy) => {
    handleResetDataOnMobile();
    setPage(0);
    setFilterOrderBy(value);
  };

  /// dev: only mobile for infinity scroll
  const handleResetDataOnMobile = () => {
    if (isMobile) {
      setData([]);
    }
  };
  // const buildQuery = (filters: filters) => {
  //   const { filter, timeFilter, search, page } = filters;
  //   const claimedRef = userClaimedWagerCollRef(address);
  //   const q: Query<DocumentData> = query(
  //     claimedRef,
  //     orderBy("created_at", "desc")
  //   );
  //   switch (timeFilter) {
  //     case timeFilterType.allTime:
  //       break;

  //     default:
  //       break;
  //   }
  //   return q;
  // };
  // const getLatestClaimed = async (_filters: filters) => {
  //   const query = buildQuery(_filters);
  //   return await getDocs(query).then((querySnapshot) => {
  //     let count: number = 0;
  //     let _claimed: ClaimedType[] = [];
  //     setClaimDataPage(querySnapshot);
  //     querySnapshot.forEach((doc) => {
  //       _claimed.push({
  //         id: doc.id,
  //         amount: doc.data().amount,
  //         txHash: doc.id,
  //         created_at: doc.data().created_at,
  //         wagerCount: doc.data()?.wagerIds?.length || 0,
  //       });
  //       if (
  //         querySnapshot.docs.length === PAGE_SIZE + 1 &&
  //         count === querySnapshot.docs.length - 2
  //       ) {
  //         setNextCursorPointer(doc);
  //         setPrevCursorPointer(null);
  //       }
  //       count++;
  //     });
  //     return _claimed;
  //   });
  // };
  // useEffect(() => {
  //   getLatestClaimed({ timeFilter }).then((data) => {
  //     console.log("====================================");
  //     console.log("data :", data);
  //     console.log("====================================");
  //     setClaimedData(data);
  //   });
  // }, [address, timeFilter]);
  // useEffect(() => {
  //   if (isNext) {
  //     const _filters = { timeFilter, search, page: page + 1 };
  //     const query = buildQuery(_filters);
  //     getDocs(query).then((querySnapshot) => {
  //       try {
  //         let count: number = 0;
  //         let data: ClaimedType[] = [];
  //         querySnapshot.forEach((doc) => {
  //           if (
  //             count <= querySnapshot.docs.length - 2 ||
  //             querySnapshot.docs.length !== PAGE_SIZE + 1
  //           ) {
  //             data.push({
  //               id: doc.id,
  //               amount: doc.data().amount,
  //               txHash: doc.data().txHash,
  //               created_at: doc.data().created_at,
  //               wagerCount: doc.data()?.wagerIds?.length || 0,
  //             });
  //           }
  //           if (
  //             querySnapshot.docs.length === PAGE_SIZE + 1 &&
  //             count === querySnapshot.docs.length - 2
  //           ) {
  //             setNextCursorPointer(doc);
  //           }
  //           count++;
  //         });
  //         if (!querySnapshot.empty) {
  //           setPrevCursorPointer(querySnapshot.docs[0]);
  //         }
  //         if (querySnapshot.docs.length !== PAGE_SIZE + 1) {
  //           setNextCursorPointer(null);
  //         }

  //         setClaimDataPage(querySnapshot);

  //         setClaimedData(data);
  //         setIsNext(false);
  //       } catch (error) {
  //         console.log("====================================");
  //         console.log(error);
  //         console.log("====================================");
  //       }
  //     });
  //   }
  // }, [isNext]);
  // useEffect(() => {
  //   if (isPrev) {
  //     const _filters = { timeFilter, search, page: page - 1 };
  //     const query = buildQuery(_filters);
  //     getDocs(query).then((querySnapshot) => {
  //       try {
  //         let count: number = 0;
  //         let data: ClaimedType[] = [];
  //         setClaimDataPage(querySnapshot);
  //         querySnapshot.forEach((doc) => {
  //           data.push({
  //             id: doc.id,
  //             amount: doc.data().amount,
  //             txHash: doc.data().txHash,
  //             created_at: doc.data().created_at,
  //             wagerCount: doc.data()?.wagerIds?.length || 0,
  //           });
  //           count++;
  //         });
  //         setNextCursorPointer(
  //           querySnapshot.docs[querySnapshot.docs.length - 1]
  //         );
  //         if (querySnapshot.docs.length === PAGE_SIZE + 1) {
  //           setPrevCursorPointer(querySnapshot.docs[1]);
  //         } else {
  //           setPrevCursorPointer(null);
  //         }
  //         setClaimedData(data);
  //         setIsPrev(false);
  //       } catch (error) {
  //         console.log("====================================");
  //         console.log(error);
  //         console.log("====================================");
  //       }
  //     });
  //   }
  // }, [isPrev]);

  return {
    claimedData,
    loading: isLoading,
    page,
    setPage,
    setFilter,
    timeFilter,
    setTimeFilter: handleChangeFilter,
    setFilterOrderBy: handleChangeOrderBy,
    filter,
    search,
    setSearch,
    // setIsNext,
    // setIsPrev,
    PAGE_SIZE,
    onPageChanged,
    count,
  };
};
