import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required Swiper modules
import { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";
import { Img } from "components/commons/Img";
const EventHero: React.FC<any> = () => {
	const banners = [
		{
			title: "PlayDrop",
			altTitle: "Featured Event",
			desc: `PlayDrop is a monthly rewards program that incentivizes individuals, groups, and communities to contribute to our "$1B by 2024" revenue mission.`,
			style: "bg-gradient-to-br from-primary/10 to-primary/15",
			deco: "campaign/hero-playdrop.png",
		},
	];
	return (
		<Swiper
			slidesPerView={1}
			spaceBetween={10}
			navigation={false}
			modules={[Autoplay, Navigation]}
			autoplay={{
				delay: 10000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			}}
			className="w-full flex-shrink-0 rounded-box"
		>
			{banners.map((item: any, index: number) => (
				<SwiperSlide key={index}>
					<div className="group rounded-box shadow-lg overflow-hidden relative h-full w-full mx-auto flex">
						<div className="absolute left-0 lg:left-2/4 right-0 top-0 bottom-0 opacity-10 lg:opacity-100 flex">
							<Img addPrefix alt={""} className="object-cover md:object-right flex-1" src={item.deco} />
						</div>
						<div className={`flex flex-col justify-center p-6 md:py-10 md:px-12 w-full min-h-[200px] ${item.style}`}>
							<div className={`w-full max-w-lg`}>
								<div className="text-base inline-block grad-text tracking-wide drop-shadow-md mb-2 font-medium">
									<span className="grad-text">{item.altTitle}</span>
								</div>
								<h3 className="text-4xl font-semibold">{item.title}</h3>
								<div className="text-base mt-6 leading-loose">{item.desc}</div>
								<Link to={`${slugs.campaign}/playdrop`}>
									<button className="btn btn-accent btn-md mt-8">
										Learn More<i className="fa-regular fa-arrow-right ml-2"></i>
									</button>
								</Link>
							</div>
						</div>
					</div>
				</SwiperSlide>
			))}
		</Swiper>
	);
};
export default EventHero;
