import React, { Fragment } from "react";
import { leaderBoardFilter } from "proxy-state/leaderBoardFilter";
import { ELeaderBoardFilter, IOrderBy } from "shared/enum";
import { useSnapshot } from "valtio";

export const WagerFilter = () => {
	const { filterType, orderBy } = useSnapshot(leaderBoardFilter);
	const setFilterType = (filterType: ELeaderBoardFilter) => {
		leaderBoardFilter.filterChanged = true;
		leaderBoardFilter.filterType = filterType;
	};
	const setOrderBy = (orderBy: IOrderBy) => {
		leaderBoardFilter.filterChanged = true;
		leaderBoardFilter.orderBy = orderBy;
	};

	return (
		<Fragment>
			<div className="flex items-center">
				{/* Dropdown */}
				<div className="dropdown dropdown-v2 dropdown-end">
					<label tabIndex={0} className="dropdown-toggle">
						<span className="mr-2">
							<i className="fa-solid fa-bars-filter"></i>
						</span>
						<button className="dropdown-toggle-title">
							<span className="flex items-center">
								<span className="truncate">Wagers</span>
							</span>
							<i className="fa-solid fa-angle-down ml-2 text-xs"></i>
						</button>
					</label>

					<div tabIndex={0} className="dropdown-content menu w-52">
						<button className={`dropdown-menu-item${filterType === ELeaderBoardFilter.wagerer ? ' isActive' : ''}`} onClick={() => setFilterType(ELeaderBoardFilter.wagerer)} >
							<span className="flex items-center">
								<span className="truncate">Wagers</span>
							</span>
							{filterType === ELeaderBoardFilter.wagerer  && <i className="fa-solid fa-check ml-2 text-xs"></i>}
						</button>

						<button className={`dropdown-menu-item${filterType === ELeaderBoardFilter.winning ? ' isActive' : ''}`} onClick={() => setFilterType(ELeaderBoardFilter.winning)} >
							<span className="flex items-center">
								<span className="truncate">Winnings</span>
							</span>
							{filterType === ELeaderBoardFilter.winning && <i className="fa-solid fa-check ml-2 text-xs"></i>}
						</button>

						{/* <button className={`dropdown-menu-item`}>
							<span className="flex items-center">
								<span className="truncate">Odds</span>
							</span>
						</button> */}

						<span className="block w-full h-[1px] bg-base-content bg-opacity-20 my-2"></span>

						<button className={`dropdown-menu-item${orderBy === IOrderBy.ASC ? ' isActive' : ''}`} onClick={() => setOrderBy(IOrderBy.ASC)} >
							<span className="flex items-center">
								<i className="fa-regular fa-arrow-up mr-2 w-5"></i>
								Sort Ascending
							</span>
							{orderBy === IOrderBy.ASC  && <i className="fa-solid fa-check ml-2 text-xs"></i>}
						</button>
						<button className={`dropdown-menu-item${orderBy === IOrderBy.DESC ? ' isActive' : ''}`} onClick={() => setOrderBy(IOrderBy.DESC)} >
							<span className="flex items-center">
								<i className="fa-regular fa-arrow-down mr-2 w-5"></i>
								Sort Descending
							</span>
							{orderBy === IOrderBy.DESC && <i className="fa-solid fa-check ml-2 text-xs"></i>}
						</button>
					</div>
				</div>
				{/* //Dropdown */}
			</div>
		</Fragment>
	)
};