import { Wallet } from "ethers";

export const prepareCreateSessionParams = async () => {
  const w = Wallet.createRandom();
  const s = Date.now();
  const u = new Date();
  u.setDate(u.getDate() + 1);
  const _u = u.getTime();
  const validAfter = Math.floor(s / 1000);
  const validUntil = Math.floor(_u / 1000);
  const pubKey = await w.getAddress();
  const privateKey = w.privateKey;

  return {
    validAfter,
    validUntil,
    pubKey,
    privateKey,
  };
};
