export enum ITimeFilterType {
  allTime = "All Times",
  today = "Last 24h",
  yesterday = "Yesterday",
  thisWeek = "Last 7d",
  thisMonth = "Last 30d",
}
export enum IWagerStatus {
  ALL = "All",
  OPEN = "Open",
  WON = "Won",
  LOST = "Lost",
  REFUND = "Refund",
}
export enum ILayoutType {
  LIST = "List",
  GRID = "Grid",
}
export enum IPageType {
  ORDER = "Order",
  CLAIMS = "Claims",
}
export enum IMyBetLayout {
  HISTORY = "History",
  BETS = "Bets",
}
export enum IOrderBy {
  DESC = "desc",
  ASC = "asc",
}
export enum ELeaderBoardFilter {
  wagerer = "Wagerer",
  winning = "Winning",
}
