import axios, { AxiosInstance } from "axios";
import { getItem } from "local-storage";

class NotiApis {
  private readonly axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({ baseURL: this.baseUrl });
    this.axios.interceptors.response.use((res) => res.data.data);
  }

  private get baseUrl() {
    return process.env.REACT_APP_NOTIFICATIONS_API_URL;
  }

  private get clientId() {
    return (
      process.env.REACT_APP_CLIENT_ID || "cd3a5797-737c-4375-be6f-549caa49bc8d"
    );
  }

  private get segmentUrl() {
    return "/segment";
  }

  private get notificationUrl() {
    return "/notification";
  }

  private get notificationUnreadCountUrl() {
    return `${this.notificationUrl}/unread-count`;
  }

  private get postTriggerUserUrl() {
    return `${this.segmentUrl}/trigger-user`;
  }

  private get header() {
    const token: string = getItem("access_token") || "";
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  private getPutSegmentSwitchByIdUrl(segment_id: string): string {
    return `${this.segmentUrl}/${segment_id}/switch`;
  }

  private getPutReadNotificationUrl(user_noti_id: string) {
    return `${this.notificationUrl}/${user_noti_id}/click`;
  }

  async getSegment(client_uid: string) {
    try {
      const result = await this.axios.get<any, Array<any>>(this.segmentUrl, {
        params: {
          client_id: this.clientId,
          client_uid,
        },
      });
      return result;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  async putSegmentSwitchById(client_uid: string, segment_id: string) {
    try {
      await this.axios.put(
        this.getPutSegmentSwitchByIdUrl(segment_id),
        {
          client_id: this.clientId,
          client_uid,
        },
        {
          params: {},
        }
      );
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  async postTriggerUser(client_uid: string) {
    try {
      await this.axios.post(
        this.postTriggerUserUrl,
        {
          client_id: this.clientId,
          client_uid,
        },
        {}
      );
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  async putReadNotification(user_noti_id: string) {
    try {
      const res = await this.axios.put<{ data: any }>(
        this.getPutReadNotificationUrl(user_noti_id),
        {
          user_notification_id: user_noti_id,
        },
        {
          headers: this.header,
        }
      );
      return res;
    } catch (error: any) {
      throw Error(error?.response?.data?.message);
    }
  }

  async getNotifications(user_id: string) {
    try {
      const res = await this.axios.get<any, Array<any>>(this.notificationUrl, {
        params: {
          recipient_id: user_id,
          client_id: this.clientId,
        },
        headers: this.header,
      });
      return res;
    } catch (error: any) {
      throw Error(error?.response?.data?.message);
    }
  }

  async getNotificationUnreadCount(recipient_id: string) {
    try {
      return await this.axios.get<any, number>(
        this.notificationUnreadCountUrl,
        {
          params: {
            recipient_id,
            client_id: this.clientId,
          },
        }
      );
    } catch (error: any) {
      throw Error(error?.response?.data?.message);
    }
  }
}

export const notiApis = new NotiApis();
