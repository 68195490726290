import React, { Fragment } from "react";

// import "/node_modules/flag-icons/css/flag-icons.min.css";
import {
  useLocation,
  useNavigate,
  useMatch,
  useParams,
} from "react-router-dom";
import slugs from "navigation/slugs";
import { isIOS } from "react-device-detect";

const isInStandaloneMode = window.matchMedia(
  "(display-mode: standalone)"
).matches;

const HeaderSub = () => {
  const localtion = useLocation();
  const navigate = useNavigate();
  const pathName = localtion.pathname;
  // const { category, league } = useParams<{ category: string, league: string }>();
  let headerLayout = (
    <>
      <strong className="inline-block grow mr-8">SPORTSBOOK</strong>
    </>
  );
  const isSportsMatchs = useMatch(slugs.sports);
  if (isSportsMatchs) {
    headerLayout = (
      <>
        <strong className="inline-block grow mr-8">Sports</strong>
      </>
    );
  }
  const isCategoryMatches = useMatch(slugs.category);
  if (isCategoryMatches) {
    headerLayout = (
      <>
        <strong className="inline-block grow mr-8">Sports</strong>
      </>
    );
  }

  const isLeagueMatch = useMatch(slugs.league);
  if (isLeagueMatch) {
    headerLayout = (
      <>
        <strong className="inline-block grow mr-8">Sports</strong>
      </>
    );
  }
  const isEventMatch = useMatch(slugs.event);
  if (isEventMatch) {
    headerLayout = (
      <>
        <strong className="inline-block grow mr-8">Match Details</strong>
      </>
    );
  }

  // ClassName
  const headerClass =
    "sticky flex flex-col items-center flex-shrink-0 top-0 left-0 py-3 right-0 w-full z-50 bg-base-300/90 backdrop-blur";

  return (
    <Fragment>
      <header
        id={"header"}
        className={`${
          isInStandaloneMode && isIOS ? "pt-10 h-20" : "h-14"
        } ${headerClass}`}
      >
        <div className={"container h-full text-center"}>
          <div className="flex items-center">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-sm rounded-full btn-ghost text-primary font-normal text-base"
            >
              <i className="fa-solid fa-angle-left text-xl text-primary"></i>
            </button>
            {headerLayout}
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default HeaderSub;
