import React, { useEffect } from "react";
import Market from "../Market";
// Market Card
function Markets({
  category,
  parentUrl,
  league,
  eventsData,
  eventFilter,
  type = "sort",
}: any) {
  // if (eventsData.filter((item: any) => item.league_id === league).length === 0)
  //   return <div>No Events</div>;

  const child = eventsData
    .filter((item: any) => item?.league_id === league)
    .map((item: any, idx: number) => {
      return (
        <Market
          key={idx}
          idx={idx}
          item={item}
          type={type}
          category={category}
          eventFilter={eventFilter}
          parentUrl={parentUrl}
        />
      );
    });
  return <div className="grid divide-y divide-base-content/5">{child}</div>;
}

export default Markets;
