import React from "react"

type BaseImgProps = React.ImgHTMLAttributes<HTMLImageElement>

export interface ImgProps extends BaseImgProps {
	addPrefix?: boolean
}

export const Img = React.forwardRef<HTMLImageElement, ImgProps>(function ({ src, addPrefix, ...props }, ref) {
	const _src = addPrefix ? process.env.PUBLIC_URL + src : src

	return <img ref={ref} src={_src} alt={props.alt ?? ''} {...props} />
})

Img.displayName = "Img"
