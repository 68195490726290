import CS2 from "./CS2";
import DOTA from "./DOTA";
import LOL from "./LOL";
import { ReactComponent as Basketball } from "assets/icons/basketball.svg";
import { ReactComponent as Baseball } from "assets/icons/baseball-bat-ball.svg";
import mma from "assets/icons/hand-fist.png";
import { ReactComponent as Soccer } from "assets/icons/futbol.svg";
import { Img } from "../Img";

export const getIcon = (icon: string, classes: string = '') => {
	switch (icon) {
		case "mma":
			return <Img src={mma} alt="mma" className={`${classes ? classes : "h-6 w-6 rounded-full"}`} />;
		case "soccer":
			return <Soccer className={classes} />;
		case "baseball":
			return <Baseball className={classes} />;
		case "basketball":
			return <Basketball className={classes} />;
		case "cs2":
			return <CS2 classes={classes} />;
		case "dota2":
			return <div className="rounded-full bg-transparent hover:bg-base-100"><DOTA classes={classes} /></div>;
		case "lol":
			return <div className="rounded-full bg-transparent hover:bg-base-100"><LOL classes={classes} /></div>;
		default:
			return "";
	}
};