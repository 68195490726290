/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { LoadingSkeleton } from "../../Loading";
import _ from "lodash";
import moment from "moment";
// import CategoryNav from "../utils/categoryNav";
import LeaguesNav from "../utils/leaguesNav";
import { MatchTypeFilter, defaultFilter } from "../utils/matchTypeFilter";
import { LeaguesList } from "../League";
import { useSnapshot } from "valtio";
import { categoryProxy } from "proxy-state/categoryProxy";
import { TopMatches } from "utils/constants/constants";
import { useLocation } from "react-router-dom";
import { leagueProxy } from "../../../../proxy-state/leagueProxy";
import { eventProxy } from "../../../../proxy-state/eventProxy";
// import { myProfileProxy } from "../../../../proxy-state/myProfileProxy";
import { Category } from "../../../../interfaces/firebase";
import categoryNavProxy from "proxy-state/categoryNavProxy";

interface FilterActionProps {}

export interface CategoryListProps {
  root?: string;
  cate?: string;
  league?: string;
  breadCrumbData?: any;
}

const tabList = [TopMatches.ALL, TopMatches.IN24HOUR, TopMatches.IN48HOUR];

const CategoryList = ({ cate, league }: CategoryListProps) => {
  const { publishedCategories, isLoading: isLoadingCategories } =
    useSnapshot(categoryProxy);
  const { publishedLeagues, isLoading: isLoadingLeagues } =
    useSnapshot(leagueProxy);
  const { cateTab, setCateTab } = useSnapshot(categoryNavProxy)
  const { events, isLoadingActiveEvent } = useSnapshot(eventProxy);
  // const { address } = useSnapshot(myProfileProxy);
  // const [cateTab, setCateTab] = useState<string>(cate || "soccer");
  const [leagueTab, setLeagueTab] = useState<string>(league || "all");
  const [tab, setTab] = useState(tabList[0]);
  const [filter, setFilter] = useState("full_time_result");
  const { pathname } = useLocation();

  // const isSignedIn = address?.length > 0;
  const isLoading =
    isLoadingLeagues || isLoadingCategories || isLoadingActiveEvent;

  const categories = publishedCategories.map((category) => {
    const eventCount = _.sumBy(
      publishedLeagues.filter((league) => category.id === league.category_id),
      "eventCount"
    );
    return {
      ...category,
      show: eventCount > 0,
      eventCount: eventCount,
    };
  });

  const currentCategory = categories.find((cate: any) => cate.id === cateTab);

  const leagues = _.orderBy(
    publishedLeagues.filter(
      (league) =>
        league.eventCount > 0 && currentCategory?.id === league.category_id
    ),
    ["ordering"],
    ["asc"]
  );
  const leagueIds = leagues.map((league) => league.id);

  let activeEvents = _.orderBy(
    events.filter(
      (item) =>
        item.status === "published" &&
        item.resolved === 0 &&
        item.time_status < "2" &&
        !item.featured &&
        leagueIds.includes(item.league_id)
    ),
    ["time_status", "time"],
    ["asc", "asc"]
  );

  if (leagueTab !== "all") {
    activeEvents = activeEvents.filter((item) => item.league_id === leagueTab);
  }

  const now = moment();
  const nowTime = now.unix();

  switch (tab) {
    case TopMatches.IN24HOUR:
      const nextMidnight = now.add(24, "h").unix();
      activeEvents = activeEvents?.filter(
        (e) => e.time > nowTime && e.time < nextMidnight
      );
      break;
    case TopMatches.IN48HOUR:
      const nexthalfMidnight = now.add(48, "h").unix();
      activeEvents = activeEvents?.filter(
        (e) => e.time > nowTime && e.time < nexthalfMidnight
      );
      break;
    default:
      break;
  }

  useEffect(() => {
    if (categories?.filter((cat) => cat.eventCount).length > 0 && !cateTab) {
      const activeCategory = _.orderBy(categories, 'eventCount', 'desc').filter((category: Category) => {
        return category.show;
      });
      if (activeCategory.length) {
        setCateTab(activeCategory[0].id);
      }
    }
  }, [categories]);

  useEffect(() => {
    if (cateTab) {
      const filterActive = defaultFilter(cateTab)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setFilter(filterActive);
      setLeagueTab("all");
    }
  }, [cateTab]);

  useEffect(() => {
    if (!league) return;
    setLeagueTab(league);
  }, [league]);

  useEffect(() => {
    if (!cate) return;
    setCateTab(cate);
  }, [cate]);

  useEffect(() => {
    // Retrieve the hashtag from the URL
    const hashtag = pathname.substring(pathname.lastIndexOf("#") + 1);
    if (hashtag) {
      if (hashtag === "%F0%9F%8F%80" || hashtag === "basketball") {
        setCateTab("basketball");
      } else if (hashtag === "soccer") {
        setCateTab("soccer");
      }
    }
  }, [pathname]);

  const FilterAction: React.FC<FilterActionProps> = () => {
    return (
      <div className="bg-base-200 rounded-box flex items-center gap-1">
        {tabList.map((item: TopMatches, index: number) => (
          <button
            key={index}
            className={`btn btn-sm  ${
              tab === item
                ? "text-base-content bg-neutral hover:bg-neutral-focus"
                : "btn-ghost text-base-content/50"
            }`}
            onClick={() => setTab(item)}
          >
            <span className="">{item}</span>
          </button>
        ))}
      </div>
      // <Tabs tabList={tabList} tab={tab} setTab={setTab} />
    );
  };

  // const EmptyEventPage: React.FC<any> = () => {
  //   return (
  //     <div className="mt-6 md:mt-12">
  //       <div className="grid grid-cols-1">
  //         <div className="mt-6">
  //           <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
  //             <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
  //             <span className="text-lg font-bold">No Matches found</span>
  //             <span className="text-xs opacity-70 text-center mt-1">
  //               Ooops, nothing found!!
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <Fragment>
      {/* <CategoryNav
        root={"sports"}
        categories={categories}
      /> */}
      <div className="section">
        <div className="section-header">
          <div className="hidden lg:flex items-center gap-3">
            <i className="fa-duotone fa-wreath-laurel icon-primary text-xl"></i>
            <h2 className="text-xl font-semibold capitalize">
              {cateTab === "mma" ? "MMA" : cateTab?.replace(/-/g, " ")} Matches
            </h2>
          </div>

          {!isLoading && 
          // isSignedIn && 
          (
            <div className="flex items-center justify-between gap-2 w-full lg:w-auto flex-shrink-0">
              <FilterAction />
              <MatchTypeFilter
                cateId={cateTab}
                filter={filter}
                setFilter={setFilter}
              />
            </div>
          )}
        </div>

        <div className="">
            {leagues.length > 0 && (
              <>
                <div className="mb-4">
                  <LeaguesNav />
                </div>
              </>
            )}

            {isLoading && <LoadingSkeleton />}
            {!isLoading &&
              // isSignedIn &&
              leagues?.length > 0 && (
                <div className="space-y-2">
                  {leagues.map((league, index: number) => {
                    let eventsByLeague = activeEvents?.filter(
                      (event: any) =>
                        event?.league_id === league?.id &&
                        Number(event?.time_status) < 2 &&
                        !event?.resolved
                    );
                    if (eventsByLeague.length === 0) return null;
                    return (
                      <Fragment key={index}>
                        <LeaguesList
                          leagueData={[league]}
                          category={currentCategory?.id || ""}
                          eventsData={eventsByLeague}
                          type={"sort"}
                          filter={filter}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              )}
          </div>
      </div>
    </Fragment>
  );
};
export default CategoryList;
