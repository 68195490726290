import { Img } from "components/commons/Img";

interface PromotionProps { }
const Promotion: React.FC<PromotionProps> = (props) => {
	return (
		<>
			<div className="container-lg h-full">
				<div className="flex h-full">
					{/* Content */}
					<div className="flex-1 lg:pr-20">
						<div className="flex justify-between items-center mb-8">
							<h1 className="text-3xl">MLB 2023 Early Lead Insurance: 5th Inning Refund</h1>
						</div>
						<div className="mb-8">
							<Img addPrefix alt="" className="object-cover flex-1 rounded-box" src="/event-thumbs/mlb-content.svg" />
						</div>
						<div className="">
							<span>
								The 2023 MLB season is here, and we're excited to offer our Early Lead Insurance promotion to help you{" "}
								<strong>protect your bets</strong>. If your team{" "}
								<strong>leads at the end of the 5th inning but ends up losing the game</strong>, you'll still be paid
								out as a <strong>winner!</strong>
							</span>
						</div>
						<br />
						<div className="">
							<span className="text-[18px] font-bold">How it works</span>
						</div>
						<br />
						<div className="">
							<span>
								Simply place a <strong>single bet</strong> on the <strong>Moneyline</strong> market for any MLB game. If
								your team leads at the end of the 5th inning but loses the game, you'll receive a{" "}
								<strong>refund of your initial stake, up to $100</strong>. This promotion works for{" "}
								<strong>1 wager at a time only.</strong>
							</span>
						</div>
						<br />
						<div className="">
							<span className="text-[18px] font-bold">Promotion dates</span>
						</div>
						<br />
						<div className="">
							<span>
								The Early Lead Insurance promotion runs from <strong>July 15th to the end of the season.</strong>
							</span>
						</div>
						<br />
						<div className="">
							<span className="text-[18px] font-bold">Stake amount</span>
						</div>
						<br />
						<div>
							<span>The minimum stake amount is 10 zkUSD, and the maximum payout amount is 100 zkUSD.</span>
						</div>
						<br />
						<div>
							<span className="text-[18px] font-bold">Reward pool</span>
						</div>
						<br />
						<div>
							<span>
								The reward pool for each match is 1,000 zkUSD, and it will be distributed on a first-come, first-served
								basis.
							</span>
						</div>
						<br />
						<div>
							<span className="text-[18px] font-bold">Reward distribution</span>
						</div>
						<br />
						<div>
							<p>&emsp; - Winners will be announced every Sunday on Goal3 Discord.</p>
						</div>
						<div>
							<p>
								&emsp; - To claim your reward, please create a ticket in{" "}
								<a href="https://discord.gg/goal3" target="_blank">
									<strong>
										<u>our Discord</u>
									</strong>
								</a>
								!
							</p>
						</div>
						<br />
						<div>
							<span className="text-[18px] font-bold">Terms and conditions</span>
						</div>
						<br />
						<div>
							<p>
								&emsp; - The refund will only be credited if your Moneyline bet loses but the selected team leads until
								the end of the 5th inning.
							</p>
						</div>
						<div>
							<p>&emsp; - Voided bets will be disqualified for this promotion.</p>
						</div>
						<div>
							<p>&emsp; - Wash trades or odds manipulation activities will be disqualified from the promotion.</p>
						</div>
						<div>
							<p>
								&emsp; - Goal3 reserves the right to disqualify any user due to promotion or account abuse, which may
								also result in a permanent ban.
							</p>
						</div>
						<div>
							<p>
								&emsp; - Goal3 reserves the right to modify the rules and regulations of this campaign without any
								previous notice.
							</p>
						</div>
						<br />
						<div>
							<span className="text-[18px] font-bold">Additional terms and conditions</span>
						</div>
						<br />
						<div>
							<p>&emsp; - Parlay bets are not eligible for this promotion.</p>
						</div>
						<div>
							<p>&emsp; - If you bet more than 100 zkUSD, your maximum refund will be 100 zkUSD.</p>
						</div>
						<div>
							<p>&emsp; - You must claim your refund within 7 days of the game's completion.</p>
						</div>
						<div>
							<p>&emsp; - This promotion ends at the end of the MLB season.</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Promotion;
