import {
  BETTING_HISTORY,
  CATEGORY_COLL,
  EVENT_COLL,
  LEAGUE_COLL,
  ORDER_LOGS,
  OUTCOME_COLL,
  PAYOUT_HISTORY,
  SPACE_COLL,
  USER_COLL,
  USER_WAGER,
  WAGER_COLL,
} from "./collectionMap";
import { DocumentData, Query, collection, doc } from "firebase/firestore";
import { firebaseDb } from "./config2";

const appId = process.env.REACT_APP_APP_NAME || "sportbook";
const chainId = process.env.REACT_APP_FIREBASE_SUFFIX || "280";
const spaceId = `${chainId}_${appId}`;
const SPACE_CHANGE = `${SPACE_COLL}/${spaceId}`;

export const outcomesRef = () => {
  return collection(firebaseDb, `${SPACE_CHANGE}/${OUTCOME_COLL}`);
};

export const userDocRef = (address: string) => {
  return doc(firebaseDb, `${SPACE_CHANGE}/${USER_COLL}/${address}`);
};

export const categoriesRef = () => {
  return collection(firebaseDb, `${SPACE_CHANGE}/${CATEGORY_COLL}`);
};

export const leagueRef = () => {
  return collection(firebaseDb, `${SPACE_CHANGE}/${LEAGUE_COLL}`);
};

export const eventRef = () => {
  return collection(firebaseDb, `${SPACE_CHANGE}/${EVENT_COLL}`);
};

export const wagerDocRef = (id: string) => {
  return doc(firebaseDb, `${SPACE_CHANGE}/${WAGER_COLL}/${id}`);
};

export const userWagerCollRef = (account: string | undefined) => {
  return collection(
    firebaseDb,
    `${SPACE_CHANGE}/user/${account}/${USER_WAGER}`
  );
};

export const userHistoryCollRef = (
  account: string | undefined
): Query<DocumentData> => {
  return collection(
    firebaseDb,
    `${SPACE_CHANGE}/user/${account}/${BETTING_HISTORY}`
  );
};

export const userCollRef = () => {
  return collection(firebaseDb, `${SPACE_CHANGE}/${USER_COLL}`);
};
export const getGlobalConfig = () => {
  return doc(firebaseDb, `${SPACE_CHANGE}`);
};
export const getThreshold = () => {
  return doc(firebaseDb, `${SPACE_CHANGE}/configuration/threshold`);
};
export const userClaimedWagerCollRef = (
  account: string | undefined
): Query<DocumentData> => {
  return collection(
    firebaseDb,
    `${SPACE_CHANGE}/user/${account}/${PAYOUT_HISTORY}`
  );
};

export const getEventMarketDocRef = (eventId: string, market: string) => {
  return doc(
    firebaseDb,
    `${SPACE_CHANGE}/${EVENT_COLL}/${eventId}/markets/${market}`
  );
};

export const historyOrderCollRef = () => {
  return collection(firebaseDb, `${SPACE_CHANGE}/${ORDER_LOGS}`);
};

export const getSpaceDocData = () => {
  return doc(firebaseDb, `${SPACE_CHANGE}`);
};

export const getConfigSlideBanner = () => {
  return doc(firebaseDb, `${SPACE_CHANGE}/configuration/banners`);
};

export const getConfigurationThresholdDocRef = () => {
  return doc(firebaseDb, `${SPACE_CHANGE}/configuration/threshold`);
};

export const getConfigurationBookmakerDocRef = (firebaseDb: any) => {
  return doc(firebaseDb, `${SPACE_CHANGE}/configuration/bookmaker`);
};
