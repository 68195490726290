import React, { ChangeEvent, useContext, useEffect, useRef } from "react";
import { useAccount } from "hooks/useAccount";
import CopyToClipboard from "react-copy-to-clipboard";
import { ExplorerURLs } from "contracts/config";
import { toast } from "react-toastify";
import { getAddressSimpleString } from "common/utils";
import { CountdownTimer } from "components/commons/CountdownTimer";
import { CreateOrGetOTPFunding } from "utils/apis/api";
import { Link } from "react-router-dom";
import { DepositContext } from "contexts/depositContext";
import { SMART_DEPOSIT } from "helpers/constants";

export const SmartDeposit = ({
  setDepositPage,
  setDepositOptionPage,
}: {
  setDepositPage: React.Dispatch<React.SetStateAction<boolean>>;
  setDepositOptionPage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 324;
  const explorerUrl = ExplorerURLs[chainId];
  const { address } = useAccount();
  const [dpOtp, setDpOtp] = React.useState(false);
  const [otpValue, setOtpValue] = React.useState<Array<string>>([]);
  const [dpHistory, setDpHistory] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [otpLoading, setOtpLoading] = React.useState(false);
  const [otpInfo, setOtpInfo] = React.useState<any>({});
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const inputRef6 = useRef<HTMLInputElement>(null);
  const inputRefs = [
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    inputRef6,
  ];

  const handlePasteValue = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData("text");
    const valuesToPaste = pastedText.split("");
    const _otpValue: string[] = [];
    valuesToPaste.forEach((input, index) => {
      if (inputRefs[index]) {
        inputRefs[index].current!.value = input;
        _otpValue.push(input);
      }
    });
    setOtpValue(_otpValue);

    // Prevent the default paste behavior to avoid duplicating the input
    event.preventDefault();
  };
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = Number(e.target.value);
    if (isNaN(value)) return;
    if (inputRefs[index]) {
      inputRefs[index].current!.value = value.toString();
      console.log("idnex", index);
      setOtpValue((otpValue) => {
        const _otpValue = index === 0 ? [] : [...otpValue];
        _otpValue[index] = value.toString();
        return _otpValue;
      });
    }
    if (inputRefs[index + 1]) {
      inputRefs[index + 1].current?.focus();
    }
    // Prevent the default paste behavior to avoid duplicating the input
    e.preventDefault();
  };
  const { deposit } = useContext(DepositContext);

  useEffect(() => {
    if (otpValue.length === 6) {
      const checkingOtp = async () => {
        try {
          setOtpLoading(true);
          const otp = otpValue.join("");
          const response = await CreateOrGetOTPFunding(otp, "get", deposit);
          setOtpInfo(response);
          setDpOtp(false);
        } catch (error) {
          // console.log( "🚀 -------------------------------------------------------🚀", );
          // console.log( "🚀 ~ file: deposit.tsx:80 ~ checkingOtp ~ error:", error, );
          // console.log( "🚀 -------------------------------------------------------🚀", );
          toast.error("Deposit code is invalid");
        } finally {
          setOtpLoading(false);
        }
      };
      checkingOtp().then();
    }
  }, [otpValue]);

  useEffect(() => {
    if (!address) return;
    const getOrCreateSession = async () => {
      setLoading(true);
      try {
        const response = await CreateOrGetOTPFunding(
          address as string,
          "post",
          deposit
        );
        setOtpInfo(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getOrCreateSession().then();
  }, [address]);
  const checkDepositOtherAccount: boolean =
    otpInfo && address && otpInfo.wallet_address !== address;
  // console.log(
  //   "🚀 -----------------------------------------------------------------------------------------------🚀"
  // );
  // console.log(
  //   "🚀 ~ file: deposit.tsx:106 ~ SmartDeposit ~ checkDepositOtherAccount:",
  //   checkDepositOtherAccount
  // );
  // console.log(
  //   "🚀 -----------------------------------------------------------------------------------------------🚀"
  // );

  return (
    <>
      {!dpOtp ? (
        <div
          className={`sticky  top-0 left-0 right-0 px-4 md:px-6 py-5 z-50 bg-base-200/80 backdrop-blur text-center`}
        >
          <button
            className="btn btn-sm rounded-full btn-ghost mr-2 absolute left-4 top-4 gap-2 !text-primary font-normal text-base"
            onClick={() => {
              setDepositPage(false);
              setDepositOptionPage(true);
            }}
          >
            <i className="fa-solid fa-angle-left text-xl"></i>
          </button>
          <div className="inline-flex text-base font-semibold">
            <h3>
              {!checkDepositOtherAccount
                ? `Deposit via ${
                    SMART_DEPOSIT.find((el) => el.value === deposit)?.label
                  }`
                : "Deposit zkUSD to another"}
            </h3>
          </div>
        </div>
      ) : (
        <div
          className={`sticky top-0 left-0 right-0 px-4 md:px-6 py-5 z-50 bg-base-200/80 backdrop-blur text-center`}
        >
          <button
            className="btn btn-sm rounded-full btn-ghost mr-2 absolute left-4 top-4 gap-2 !text-primary font-normal text-base"
            onClick={() => {
              checkDepositOtherAccount
                ? setDepositPage(false)
                : setDpOtp(false);
            }}
          >
            <i className="fa-solid fa-angle-left text-xl"></i>
          </button>
          <div className="inline-flex text-base font-semibold">
            <h3>Deposit zkUSD to another</h3>
          </div>
        </div>
      )}
      {/* <button
							className="btn btn-sm rounded-full btn-ghost mr-2 absolute right-2 top-4 gap-2 text-primary font-normal text-base"
							onClick={() => setDpHistory(true)}
						>
							<span className="">History</span>
						</button> */}

      <div className="p-4 md:p-6 !pb-8 gap-8 flex flex-col fadein text-base">
        {/* {!dpOtp && !dpHistory && (
          <div className="flex flex-col px-3">
            <div className="flex flex-col justify-center items-center text-center px-1 gap-4">
              <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center">
                <i className="fa-duotone fa-coins icon-primary text-3xl"></i>
              </div>
              <div className="text-center">
                <h4 className="text-2xl font-semibold">Deposit zkUSD</h4>

                <div className="leading-relaxed mt-2 text-base-content/50">
                  {checkDepositOtherAccount ? (
                    `Send zkUSD on zkSync to another account  automatcially with ETH on Ethereum.`
                  ) : (
                    <span>
                      Swap{" "}
                      <span className="font-semibold text-warning">
                        ETH on Ethereum
                      </span>{" "}
                      to zkUSD on zkSync automatically.
                    </span>
                  )}
                  <Link
                    to="#"
                    target={"_blank"}
                    rel="noreferrer"
                    className="text-primary"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )} */}

        <div className="space-y-4">
          {loading && (
            <div className="flex flex-col h-full w-full justify-center items-center z-50">
              {/* <Icon className="w-16 mb-4" /> */}
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 text-base-content">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
              </div>
            </div>
          )}
          {!dpOtp && !dpHistory && !loading && (
            <>
              <div className="w-60 p-1 bg-white rounded-box mt-2 mx-auto mb-8">
                <img
                  alt="QRCode"
                  src={`https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${otpInfo?.receive_address}&choe=UTF-8`}
                />
              </div>
              <div className="flex flex-col bg-base-100 px-3 rounded-box">
                <div className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col gap-1">
                      <span className="text-sm text-base-content/50">
                        Network
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <span className="">Ethereum (ERC20)</span>
                  </span>
                </div>
                <button className="flex items-center justify-between py-4 px-2 gap-3">
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col items-start text-left gap-1">
                      <span className="text-sm text-base-content/50">
                        Deposit Address
                      </span>
                      <span className="break-all text-sm">
                        {otpInfo?.receive_address}
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center flex-shrink-0  justify-end">
                    <CopyToClipboard
                      text={otpInfo?.receive_address as string}
                      onCopy={() => toast.success("Copied to clipboard")}
                    >
                      <i className="fa-solid fa-copy"></i>
                    </CopyToClipboard>
                  </span>
                </button>
                <span
                  className="ml-2 mr-2 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>

                <div className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col gap-1">
                      <span className="text-sm text-base-content/50">
                        Minimum Deposit
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <span className=""> &#8805; 0.04 ETH</span>
                  </span>
                </div>

                <span
                  className="ml-2 mr-2 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>

                <div className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col gap-1">
                      <span className="text-sm text-base-content/50">
                        Est. Fee
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <span className="">
                      {" "}
                      {
                        SMART_DEPOSIT.find((el) => el.value === deposit)
                          ?.feeDetails
                      }
                    </span>
                  </span>
                </div>

                <span
                  className="ml-2 mr-2 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>
              </div>
              <div className="flex flex-col gap-2 rounded-box bg-warning/10 p-4 px-6 text-base mb-4">
                <span className="text-warning font-semibold">
                  {" "}
                  <i className="fa fa-circle-info mr-1" /> Disclaimer
                </span>
                <span className="leading-relaxed text-sm">
                  Ensure that you send{" "}
                  <span className="text-warning font-semibold">ETH</span> on{" "}
                  <span className="text-warning font-semibold">
                    Ethereum Network{" "}
                  </span>
                  to your Deposit Address.
                  <br />
                </span>
                <span className="leading-relaxed text-sm">
                  *DO NOT deposit a different token or from another network.
                  This will lead to asset loss.
                </span>
              </div>
              <div className="flex flex-col border border-base-content/10 px-3 rounded-box">
                <div className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col gap-1">
                      <span className="text-sm text-base-content/50">
                        Depositing via
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <span className="">
                      {SMART_DEPOSIT.find((el) => el.value === deposit)?.label}
                    </span>
                  </span>
                </div>

                <span
                  className="ml-2 mr-2 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>

                <button className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col gap-1">
                      <span className="text-sm text-base-content/50">
                        {checkDepositOtherAccount
                          ? "Deposit Code"
                          : "Your Deposit Code"}
                      </span>
                    </div>
                  </span>
                  <CopyToClipboard
                    text={otpInfo?.otp}
                    onCopy={() => toast.success("Copied to clipboard")}
                  >
                    <span className="flex items-center cursor-pointer">
                      <span className="mr-3 tracking-wider">
                        {otpInfo?.otp}
                      </span>
                      <i className="fa-solid fa-copy"></i>
                    </span>
                  </CopyToClipboard>
                </button>

                <span
                  className="ml-2 mr-2 h-[1px] bg-base-content/10"
                  role="separator"
                ></span>

                <div className="flex items-center justify-between py-4 px-2">
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col gap-1">
                      <span className="text-sm text-base-content/50">
                        Expires in
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <CountdownTimer end_time={otpInfo?.expired || ""} />
                  </span>
                </div>
              </div>

              {!checkDepositOtherAccount && (
                <div className="flex flex-col border border-base-content/10 px-3 rounded-box">
                  <button
                    className="flex items-center justify-between py-4 px-2"
                    onClick={() => {
                      setDpOtp(true);
                    }}
                  >
                    <span className="flex items-center text-base-content flex-shrink-0">
                      <span className="flex items-center justify-center w-6 mr-4">
                        <i className="fa-duotone fa-link-simple icon-primary text-lg"></i>
                      </span>
                      <span className="">Deposit zkUSD to another account</span>
                    </span>
                    <span className="flex items-center">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </button>
                  {/* 
                  <span className="ml-12 h-[1px] bg-base-content/10" role="separator"></span>

                  <button
                    className="flex items-center justify-between py-4 px-2"
                    onClick={() => {
                      setDpOtp(true);
                    }}
                  >
                    <span className="flex items-center text-base-content flex-shrink-0">
                      <span className="flex items-center justify-center w-6 mr-4">
                        <i className="fa-duotone fa-clock icon-primary text-lg"></i>
                      </span>
                      <span className="">Transactions History</span>
                    </span>
                    <span className="flex items-center">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </button> */}
                </div>
              )}
            </>
          )}

          {dpOtp && !loading && (
            <>
              <div className="flex flex-col bg-base-100 px-3 rounded-box">
                <div className="flex items-start justify-between py-4 px-2">
                  <span className="flex items-center justify-center w-6 mr-4 mt-1">
                    <i className="fa-duotone fa-link-simple icon-primary text-lg"></i>
                  </span>
                  <div className="flex items-center text-base-content w-full">
                    <div className="flex flex-col gap-2 w-full">
                      <div className="font-semibold">
                        Enter the Deposit Code
                      </div>
                      <div className="text-base-content/50 text-sm">
                        Enter the Deposit Code of the account you intend to
                        transfer zkUSD
                      </div>
                      <div className="flex mt-2">
                        <div className="flex flex-shrink-0 justify-evenly items-start w-[240px] bg-base-200 border border-base-content/20 divide-x divide-base-content/20 rounded-box">
                          {inputRefs.map((inputRef, index) => (
                            <input
                              key={index}
                              ref={inputRef}
                              type="number"
                              maxLength={1}
                              placeholder="-"
                              value={otpValue[index] || ""}
                              onPaste={handlePasteValue}
                              onChange={(e) => handleOnChange(e, index)}
                              className="input input-ghost w-[40px] rounded-none text-center !px-0 font-bold text-base"
                            />
                          ))}
                        </div>
                      </div>
                      {otpLoading && (
                        <>
                          <span className="flex items-center justify-center ml-2 p-4">
                            <i className="fa-solid fa-circle-notch fa-spin mr-2"></i>
                            <span className="text-sm opacity-50 mr-3">
                              Cheking...
                            </span>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="flex flex-col bg-base-100 px-3 rounded-box">
								<div className="flex items-start justify-between py-4 px-2">
									<span className="flex items-center justify-center w-6 mr-4 mt-1">
										<i className="fa-duotone fa-coins icon-primary text-lg"></i>
									</span>
									<div className="flex flex-col gap-2 w-full">
										<div className="font-semibold">Start a new depositing session</div>
										<div className="text-base-content/50 text-sm">
											Create a fresh depositing session for 24h
										</div>
									</div>
								</div>

								<span className="ml-12 h-[1px] bg-base-content/10" role="separator"></span>

								<button
									className="flex items-center justify-between py-4 px-2"
									onClick={() => {
										setDpOtp(true);
									}}
								>
									<span className="flex items-center text-base-content flex-shrink-0">
										<span className="flex items-center justify-center w-6 mr-4">
											&nbsp;
										</span>
										<span className="text-primary">Deposit Now</span>
									</span>
									<span className="flex items-center">
										<i className="fa-solid fa-angle-right"></i>
									</span>
								</button>
							</div>

							<div className="flex flex-col bg-base-100 px-3 rounded-box">
								<button
									className="flex items-center justify-between py-4 px-2"
									onClick={() => setDpHistory(true)}
								>
									<span className="flex items-center text-base-content flex-shrink-0">
										<span className="flex items-center justify-center w-6 mr-4">
											<i className="fa-duotone fa-clock icon-primary text-lg"></i>
										</span>
										<span className="">Transactions History</span>
									</span>
									<span className="flex items-center">
										<i className="fa-solid fa-angle-right"></i>
									</span>
								</button>
							</div> */}
            </>
          )}
        </div>

        {/* {dpHistory && <DepositHistory />} */}
      </div>
    </>
  );
};
