import React, { Fragment } from "react";
import EventHero from "./hero";
import { useSnapshot } from "valtio";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";
import { navProxy } from "proxy-state/global";
import {ReactComponent as ReferralEventSvg } from "assets/campaign/referral_program.svg";
import {ReactComponent as MlbEventSvg } from "assets/campaign/mlb-event.svg";
import {ReactComponent as PlayDropSvg } from "assets/campaign/playdrop.svg";
import {ReactComponent as DepositX2Svg } from "assets/campaign/deposit_x2_newplayer.svg";
import {ReactComponent as ParlaysWeeklySvg } from "assets/campaign/parlays_weekly_madness.svg";
import {ReactComponent as DiscordSvg } from "assets/logo/discord-logo.svg";
import {ReactComponent as ZealySvg } from "assets/logo/zealy-logo.svg";
import {ReactComponent as Goal3Svg } from "assets/logo/logo-dark-goal3.svg";
import {ReactComponent as Goal3LightSvg } from "assets/logo/logo-light-goal3.svg";
import { Img } from "components/commons/Img";

const GetImage = (slug: string = '') => {
	switch (slug) {
		case "referral":
			return <ReferralEventSvg width={350} height={180} />;
		case "mlb-early-lead-insurance":
			return <MlbEventSvg width={350} height={180} />;
		case "parlays-weekly-madness":
			return <ParlaysWeeklySvg width={350} height={180} />;
		case "playdrop":
			return <PlayDropSvg width={350} height={180} />;
		case "deposit-x2-newplayer":
			return <DepositX2Svg width={350} height={180} />;
		default:
			return "";
	}
};
const CampaignList: React.FC = () => {
	// const campaignEvents: campaignEvent[] = [];
	const { isShowOrder } = useSnapshot(navProxy);
	const campaignEvents = [
		{
			slug: "referral",
			eventImg: "campaign/referral_program.svg",
			title: "Referral Program",
			prize: ["$50,000"],
			endDate: "Lifetime Event",
			provider: "Goal3",
			providerImg: "logo/logo-dark-goal3.svg",
			isLifetime: true,
		},
		{
			slug: "mlb-early-lead-insurance",
			eventImg: "campaign/mlb-event.svg",
			title: "MLB Early Lead Insurance",
			prize: ["$50,000"],
			endDate: "July 15th - End of season",
			provider: "Goal3",
			providerImg: "logo/logo-dark-goal3.svg",
			isSeasonalEvent: true,
		},
		{
			slug: "playdrop",
			eventImg: "campaign/playdrop.png",
			title: "PlayDrop",
			prize: ["5,000,000 $ZKG", "$40,000 zkUSD"],
			endDate: "7:00 UTC, 20 Apr 2023",
			provider: "Goal3",
			providerImg: "logo/logo-dark-goal3.svg",
		},
		{
			eventUrl: "deposit-x2-newplayer",
			eventImg: "campaign/deposit_x2_newplayer.svg",
			title: "X2 Deposit Bonus",
			prize1: "Upto $1,000",
			endDate: "7:00 UTC, 20 Apr 2023",
			provider: "Discord",
			providerImg: "logo/discord-logo.svg",
			disabled: true,
		},
		{
			eventUrl: "parlays-weekly-madness",
			eventImg: "campaign/parlays_weekly_madness.svg",
			title: "Parlays Weekly Madness",
			prize1: "$50,000",
			endDate: "7:00 UTC, 20 Apr 2023",
			provider: "Zealy",
			providerImg: "logo/zealy-logo.svg",
			disabled: true,
		},
	];

	return (
		<Fragment>
			<div className="container">
				<EventHero />
				<div className="mt-12">
					{/* Tabs */}
					<div className="ctabs-container ctabs-compact-container">
						<div className="flex items-center">
							<i className="fa-duotone fa-party-horn icon-primary"></i>
							<h1 className="">Events</h1>
						</div>
						{/* <div className="ctabs">
							<button className={`ctab ctab-active`}>
								<span className={`ctab-title`}>Current</span>
								<span className={`ctab-border`}></span>
							</button>
							<button className={`ctab`}>
								<span className={`ctab-title`}>Upcoming</span>
								<span className={`ctab-border`}></span>
							</button>
							<button className={`ctab`}>
								<span className={`ctab-title`}>Ended</span>
								<span className={`ctab-border`}></span>
							</button>
						</div> */}
					</div>
					{/* //Tabs */}
					<div className="mt-12">
						<div
							className={`grid ${
								isShowOrder ? "md:grid-cols-2 2xl:grid-cols-3 gap-8" : "md:grid-cols-2 xl:grid-cols-3 gap-8"
							}`}
						>
							{/* {loading && <Loading />} */}
							{campaignEvents.length > 0 &&
								campaignEvents.map((item, idx) => (
									<Link
										key={idx}
										to={`${slugs.campaign}/${item.slug}`}
										className={`rounded-box pb-4 hover:scale-[1.03] hover:bg-base-200 hover:shadow-lg group transition-all${
											item.disabled ? " disabled" : ""
										}`}
									>
										<div className="relative">
											<div
												className={`timerRoot flex items-center justify-center absolute -top-3 left-0 right-0 w-full `}
											>
												<div
													className={`inline-flex items-center mx-auto text-sm border border-base-content/5 px-3 h-6 rounded shadow ${
														item.isLifetime || item.isSeasonalEvent ? "bg-success text-success-content" : "bg-base-100"
													}`}
												>
													{item.isLifetime ? (
														<div className="text-xs uppercase tracking-wide font-medium">Lifetime Event</div>
													) : item.isSeasonalEvent ? (
														<div className="text-xs uppercase tracking-wide font-medium">Seasonal Event</div>
													) : (
														<div className="flex space-x-2 items-baseline">
															<i className="fa-regular fa-clock text-xs relative bottom-[1px] opacity-70"></i>
															<div className="flex items-baseline lowercase">
																<span className="countdown slashed-zero leading-4 font-semibold font-mono">00</span>
																<span className="opacity-70 ml-1">d</span>
															</div>
															<div className="flex items-baseline lowercase">
																<span className="countdown slashed-zero leading-4 font-semibold font-mono">00</span>
																<span className="opacity-70 ml-1">h</span>
															</div>
															<div className="flex items-baseline lowercase">
																<span className="countdown slashed-zero leading-4 font-semibold font-mono">00</span>
																<span className="opacity-70 ml-1">m</span>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className="rounded-box overflow-hidden">
												{GetImage(item.slug) ? GetImage(item.slug) : <Img addPrefix alt={""} className="object-cover flex-1" src={item.eventImg} /> }
											</div>
										</div>

										<div className="flex-1 mt-6 flex flex-col items-stretch px-2 group-hover:px-3 transition-[padding]">
											<div className="flex justify-between items-center">
												<h5 className="w-full truncate text-base group-hover:text-primary transition">{item.title}</h5>
											</div>

											<div className="mt-4 text-xs space-y-2">
												<div className="flex justify-between items-center">
													<span className="opacity-70">Total Prize</span>
													<div className="flex items-center opacity-70 group-hover:opacity-100">
														{/* <span className="text-base-content font-medium">{item.prize[0]}</span>
													{item.prize[1] &&
														<>
															<span className="text-base-content font-medium opacity-70 mx-1">&</span>
															<span className="text-base-content font-medium">{item.prize[1]}</span>
														</>
													} */}
														<span className="text-base-content font-medium">
															{item.slug === "mlb-early-lead-insurance" ? "1000 zkUSD/match" : "TBD"}
														</span>
													</div>
												</div>
												<div className="flex justify-between items-center">
													<span className="opacity-70">End Date</span>
													<div className="flex items-center opacity-70 group-hover:opacity-100">
														<span className="text-base-content font-medium">
															{item.isLifetime
																? "-"
																: item.slug === "mlb-early-lead-insurance"
																? "Until season ends"
																: "TBD"}
														</span>
													</div>
												</div>
											</div>
										</div>
									</Link>
								))}
						</div>
					</div>
			</div>
			</div>

		</Fragment>
	);
};
export default CampaignList;
