import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterLanding from "./footerLanding";
import { useAuth } from "contexts/authContext";
import { verifyReferralCode } from "utils/apis/bookmakerApis";
import { ACCESS_TOKEN, REF_CODE, deleteItem, saveItem } from "local-storage";
import { getAddressSimpleString } from "utils/blockchain";
import slugs from "navigation/slugs";
import { ReactComponent as ReactCopyIcon } from "assets/icons/copy.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import BgVideo from "assets/wallpapers/soccer.mp4";
import { useAccount } from "hooks/useAccount";

export const InvitationCode: React.FC = () => {
  const { token, handleLogout: logout } = useAuth();
  const navigate = useNavigate();
  const { address } = useAccount();
  const [checking, setChecking] = React.useState<boolean>(false);
  const [invitationCode, setInvitationCode] = React.useState<string>("");
  const [isError, setIsError] = React.useState<string>("");
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const checkingInvitationCode = (code: string) => {
    setChecking(true);
    verifyReferralCode(token, code)
      .then((res) => {
        if (res.data) {
          setIsSuccess(true);
          setIsError("");
          saveItem(REF_CODE, code);
        } else {
          setIsError(res.data?.message || "");
          setIsSuccess(false);
        }
      })
      .catch((error: any) => {
        setIsError(error?.message || "");
        setIsSuccess(false);
      })
      .finally(() => {
        setChecking(false);
      });
  };
  const handleLogout = async () => {
    try {
      logout();
      deleteItem(ACCESS_TOKEN);
    } catch (error) {
      console.error("An error occurred", error);
    }
  };
  useEffect(() => {
    if (!token) {
      navigate(slugs.login, { replace: true });
    }
  }, [token]);

  return (
    <>
      <div
        data-theme="dark"
        className="main-layout--wrapper !max-w-screen !max-h-screen bg-gradient-to-b from-gray-900 to-gray-950 text-base-content/70 overflow-hidden"
      >
        <div className="fixed inset-0 opacity-10 z-0 w-screen h-screen bg-cover">
          <video
            className="object-cover w-full h-screen"
            autoPlay
            playsInline
            loop
            muted
          >
            {/* <source type="video/webm" src={`/wallpapers/dark/waves.webm`} /> */}
            <source type="video/webm" src={BgVideo} />
          </video>
        </div>

        <div className="main-layout">
          <div className="w-full min-h-screen flex items-center justify-center flex-col z-0 relative">
            <div className="mx-auto w-full max-w-md bg text-center p-4 lg:p-0">
              <div className="absolute top-0 right-0 left-0 flex justify-center pt-6">
                <button
                  type="button"
                  className="btn btn-md px-3 text-xs md:text-sm !inline-flex"
                  onClick={handleLogout}
                >
                  <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100/10 mr-2 flex items-center justify-center">
                    <img
                      height="60"
                      width="60"
                      src="https://i.pravatar.cc/60"
                      alt="Avatar"
                    />
                  </div>
                  <span className="mr-2 text-xs md:text-sm">
                    {getAddressSimpleString(address as string, 3)}
                  </span>
                  <i className="fa-solid fa-right-from-bracket"></i>
                </button>
              </div>

              <div className="">
                <i className="fa-duotone fa-hand-holding-heart icon-primary text-6xl mb-6"></i>

                <h2 className="text-2xl font-bold mb-4">Invitation Code</h2>

                <div className="mt-4 mx-auto text-base">
                  <p>
                    You'll need an invitation code to start playing on Goal3.
                    Don't have one?{" "}
                    <Link
                      to=""
                      target={"_blank"}
                      rel="noreferrer"
                      className="link text-primary"
                    >
                      Get it here!
                    </Link>
                  </p>
                </div>

                <div
                  className={`mt-8 px-6 border rounded-xl shadow-2xl flex items-center justify-between gap-4 text-left${
                    isSuccess
                      ? " bg-success/10 border-base-content/20"
                      : isError
                      ? " bg-error/10 border-base-content/20"
                      : checking
                      ? " bg-primary/10 border-base-content/20"
                      : " bg-base-300/70 border-base-content"
                  }`}
                >
                  <div className="flex-1">
                    <input
                      type="text"
                      placeholder="invitation code"
                      className={`input input-ghost input-lg !p-0 w-full${
                        isSuccess
                          ? " font-mono !text-base-content"
                          : isError
                          ? " font-mono !text-error"
                          : checking
                          ? " font-mono !text-primary"
                          : " font-mono !text-base-content"
                      }`}
                      value={invitationCode}
                      onChange={(e) => {
                        setInvitationCode(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value)
                          checkingInvitationCode(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          checkingInvitationCode(invitationCode);
                        }
                      }}
                    />
                  </div>
                  {checking && (
                    <i className="fa-solid fa-circle-notch fa-spin text-lg text-primary"></i>
                  )}
                  {isError && (
                    <i className="fa-solid fa-triangle-exclamation text-lg text-error"></i>
                  )}
                  {isSuccess && (
                    <i className="fa-solid fa-check text-lg text-success"></i>
                  )}
                  {!checking && !isError && !isSuccess && (
                    <button className="btn btn-sm btn-circle">
                      <i className="fa-solid fa-paste"></i>
                    </button>
                  )}
                </div>
                {isError && (
                  <div className="mt-4 text-error">Invalid Code: {isError}</div>
                )}
                {isSuccess && (
                  <div className="mt-4 text-success">
                    Invitation code applied successfully
                  </div>
                )}
                {/* <div className="mt-4 flex items-center justify-center gap-2">
                  Default Code:
                  <div className="bg-primary/20 rounded-full pl-3 pr-1">
                    <span className="text-primary tracking-wider">GOAL3</span>
                    <div className="btn btn-sm btn-ghost text-primary btn-circle">
                      <CopyToClipboard
                        text={"GOAL3"}
                        onCopy={() => {
                          toast.success("Copied!");
                        }}
                      >
                        <ReactCopyIcon className="w-4" />
                      </CopyToClipboard>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="mt-12">
                <button
                  className={`btn btn-primary w-1/2${
                    isSuccess && !isError ? "" : " btn-disabled"
                  }`}
                  onClick={() => navigate(slugs.home, { replace: true })}
                >
                  <span className="text-base">Start Playing</span>
                  <i className="fa-regular fa-arrow-right ml-3"></i>
                </button>
              </div>

              <FooterLanding />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
