import React from "react";
import { MatchTypeFilterProps, filterItemType } from "./sportsInterface";
import { Responsive } from "components/commons";
export const defaultFilter = (cate: string) => {
  let filter = "money_line";
  switch (cate) {
    case 'soccer':
      filter = "full_time_result";
      break;
    case 'mma':
      filter = "to_win_fight";
      break;
    case 'baseball':
      filter = "total";
      break;
    case 'cs2':
    case 'dota2':
    case 'lol':
      filter = "to_win";
      break;
  }
  return filter;
};
export const soccerFilter: filterItemType[] = [
  {
    name: "Full Time Result",
    key: "full_time_result",
    ordering: 1,
  },
  {
    name: "Both Teams to Score",
    key: "both_teams_to_score",
    ordering: 2,
  },
  {
    name: "Goals Over/Under",
    key: "goals_over_under",
    ordering: 3,
  },
];
export const MMAFilter: filterItemType[] = [
  {
    name: "Winner",
    key: "to_win_fight",
    ordering: 1,
  },
];
export const basketballFilter: filterItemType[] = [
  {
    name: "Money Line",
    key: "money_line",
    ordering: 1,
  },
  {
    name: "Total",
    key: "total",
    ordering: 1,
  },
  {
    name: "Spread",
    key: "spread",
    ordering: 1,
  },
  {
    name: "Match Result & Total",
    key: "match_result_and_total",
    ordering: 2,
  },
  {
    name: "Winning Margin",
    key: "winning_margin",
    ordering: 3,
  },
  {
    name: "Match Handicap & Total",
    key: "match_handicap_and_total",
    ordering: 4,
  },
];
export const americanFootball: filterItemType[] = [
  {
    name: "Money Line",
    key: "money_line",
    ordering: 1,
  },
  {
    name: "Total",
    key: "total",
    ordering: 1,
  },
  {
    name: "Spread",
    key: "spread",
    ordering: 1,
  },
];

export const cs2: filterItemType[] = [
  {
    name: "To Win",
    key: "to_win",
    ordering: 1,
  },
  {
    name: "Match Handicap",
    key: "match_handicap",
    ordering: 1,
  },
  {
    name: "Total Map",
    key: "total_map",
    ordering: 1,
  },
];
export const Dota2: filterItemType[] = [
  ...cs2
];

export const MatchTypeFilter: React.FC<MatchTypeFilterProps> = ({
  cateId,
  filter,
  setFilter,
}) => {
  if (!cateId) return null;
  const listFilter: filterItemType[] =
    cateId === "soccer"
      ? soccerFilter
      : cateId === "mma"
      ? MMAFilter
      : cateId === "american-football"
      ? americanFootball
      : cateId === "cs2"
      ? cs2
      : cateId === "dota2"
      ? Dota2
      : cateId === "lol"
      ? Dota2
      : basketballFilter;

  const truncatedFilter = (filter: any, limit: number, slice: number) => {
    return filter && filter.length > limit
      ? `${filter.slice(0, slice)}...`
      : filter;
  };

  return (
    <div className="dropdown dropdown-v2 dropdown-end">
      <label tabIndex={0} className="dropdown-toggle">
        <button className="dropdown-toggle-title">
          <span className="mr-2">
            <i className="fa-solid fa-bars-filter"></i>
          </span>{" "}
          <Responsive upto="xxs">
            <span className="">
              {truncatedFilter(
                listFilter.find((item: filterItemType) => item?.key === filter)
                  ?.name,
                16,
                13
              )}
            </span>
          </Responsive>
          <Responsive from="xs">
            <span className="">
              {
                listFilter.find((item: filterItemType) => item?.key === filter)
                  ?.name
              }
            </span>
          </Responsive>
          {/* <i className="fa-solid fa-angle-down ml-2 text-xs"></i> */}
        </button>
      </label>
      <div tabIndex={0} className="dropdown-content menu w-56">
        {listFilter.map((item: filterItemType, index: number) => {
          if (!item) return null;
          const checked =
            filter === item?.key ? <i className="fa-solid fa-check"></i> : "";
          return (
            <button
              key={index}
              className={`dropdown-menu-item ${checked && "isActive"}`}
              onClick={() => setFilter(item?.key)}
            >
              <Responsive upto="xxs">
                {checked ? truncatedFilter(item.name, 23, 20) : item.name}
              </Responsive>
              <Responsive from="xs">{item.name}</Responsive>
              {checked}
            </button>
          );
        })}
      </div>
    </div>
  );
};
