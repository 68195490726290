import axios from "axios";

const customAxios = axios.create();

const inviteCodePath = "/invite-code";

customAxios.interceptors.response.use(
  (response) => response, // If the response is successful, pass it through
  (error) => {
    if (error.response?.data && error.response.data?.statusCode === 401) {
      // Handle the 401 error and redirect to the login page
      // You can use your routing library's navigation function here
      // For example, if using React Router:
      window.localStorage.clear();
      window.location.href = "/login";
      return;
    }

    if (
      error.response?.data &&
      error.response.data.statusCode === 403 &&
      error.response.data?.message === "User's not enter invite code yet!" &&
      window.location.pathname !== inviteCodePath
    ) {
      // Handle the 401 error and redirect to the login page
      // You can use your routing library's navigation function here
      // For example, if using React Router:
      window.location.href = inviteCodePath;
      return;
    }

    return Promise.reject(error); // Reject the promise to propagate the error
  }
);

export { customAxios };
