import { useEffect } from "react";
import { notiApis } from "utils/apis";
import { notitficationState } from "proxy-state/notificationProxy";
import { useAccount } from "hooks/useAccount";

export const useFetchNoti = () => {
  const { address } = useAccount();
  const { pathname } = window.location;

  useEffect(() => {
    const f = async () => {
      if (!address) return;
      const result = await notiApis.getNotificationUnreadCount(address);
      notitficationState.setCounter(result);
    };

    window.addEventListener("focus", f);

    return () => {
      window.removeEventListener("focus", f);
    };
  }, [address]);

  useEffect(() => {
    const f = async () => {
      if (!address) return;
      try {
        const result = await notiApis.getNotificationUnreadCount(address);
        notitficationState.setCounter(result);
      } catch (error) {
        console.log("🚀 ~ file: useFetchNoti.ts:16 ~ f ~ error:", error);
      }
    };
    f();
  }, [address, pathname]);
};
