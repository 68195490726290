import { proxy } from "valtio";
import { DocumentData, DocumentSnapshot, onSnapshot } from "firebase/firestore";
import { userDocRef } from "../utils/firebase/firebaseDBRef";
import { User } from "../interfaces/firebase";

let unsubscribe: any;

class MyProfileProxy {
  isLoading: boolean = false;
  profile: User | null = null;
  address: string = "";
  subscribed: boolean = false;

  load(address: string) {
    if (this.subscribed) return;
    this.subscribed = true;
    this.isLoading = true;
    this.address = address;
    const ref = userDocRef(address);
    unsubscribe = onSnapshot(ref, (doc: DocumentSnapshot<DocumentData>) => {
      if (doc.exists()) {
        const user = doc.data();
        this.profile = {
          id: user.id,
          created_at: user.created_at,
          bonusRate: user.bonusRate || 0,
          invitation_code: user.invitation_code || "",
          invite_by_shortcode: user.invite_by_shortcode || false,
          invite_by_premiumcode: user.invite_by_premiumcode || false,
          isWhitelisted: user.isWhitelisted ? user.isWhitelisted : false,
          position: user.position,
          premium_code: user.premium_code,
          short_code: user.short_code,
          totalAmount: user.totalAmount,
          totalETHAmount: user.totalETHAmount,
          totalPayout: user.totalPayout,
          totalReferee: user.totalReferee,
          totalRefereeVolume: user.totalRefereeVolume,
          totalWon: user.totalWon || 0,
          total_invited: user.total_invited,
          total_invited_locked: user.total_invited_locked,
          total_locked: user.total_locked,
          totalIV: Number(user.totalRefereeVolume),
          userName: user?.user_name,
          email: user.email,
          smart_account: user.smart_account,
          picture: user.picture,
        };
      }
    });
  }

  unsubscribeDb() {
    if (unsubscribe) {
      unsubscribe();
      this.subscribed = false;
    }
  }
}

export const myProfileProxy = proxy(new MyProfileProxy());
