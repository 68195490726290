import utils from "../../common/utils";
import { siteName } from "../../contracts/config";
import { config } from "./firebaseConfig";
// export enum TopMatches {
//   ALL = "All",
//   IN3HOUR = "3h",
//   IN6HOUR = "6h",
//   IN12HOUR = "12h",
// }

export enum TopMatches {
  ALL = "All",
  IN24HOUR = "24h",
  IN48HOUR = "48h",
}

export const FunctionGetKey = () => {
  return utils.base64URLDecode(utils.desencryptData(config, siteName));
};
