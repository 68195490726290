import React from "react";

const partnersDefault = [
  {
    name: "zkSync Era",
    href: "https://portal.zksync.io",
    imgUrl: "/logo/zksync-logo.svg",
    height: "h-2 md:h-3",
  },
  {
    name: "Argent",
    href: "https://www.argent.xyz/",
    imgUrl: "/logo/argent-logo.svg",
    height: "h-4 md:h-5",
  },
  {
    name: "BitKeep",
    href: "https://bitkeep.com/en/index",
    imgUrl: "/logo/bitkeep-logo.png",
    height: "h-4 md:h-5",
  },
  // { name: "Multichain", href: "https://multichain.xyz/", imgUrl: "/logo/multichain-logo.png", height: "h-3 md:h-4" },
  {
    name: "OKX",
    href: "https://www.okx.com/",
    imgUrl: "/logo/okx-logo.svg",
    height: "h-2 md:h-3",
  },
  {
    name: "Orbiter",
    href: "https://www.orbiter.finance/",
    imgUrl: "/logo/orbiter-logo.svg",
    height: "h-5 md:h-6",
  },
  // { name: "SpaceFi", href: "https://spacefi.io/", imgUrl: "/logo/spacefi-logo.png", height: "h-3 md:h-4" },
  {
    name: "SyncSwap",
    href: "https://syncswap.xyz/",
    imgUrl: "/logo/syncswap-logo.png",
    height: "h-5 md:h-6",
  },
  {
    name: "Socket",
    href: "https://socket.tech/",
    imgUrl: "/logo/socket-logo.png",
    height: "h-5 md:h-6",
  },
];

const Partners = () => {
  return (
    <div className={"w-full"}>
      <h5 className="mb-4 uppercase tracking-wide">Our Partners</h5>
      <div className={"flex flex-wrap gap-2"}>
        {partnersDefault.map((item, idx) => (
          <a
            data-theme="dark"
            key={idx}
            href={item.href}
            target="_blank"
            rel="noreferrer"
            className="group inline-flex items-center justify-center bg-base-300/80 px-4 h-10 hover:bg-base-300 rounded-box transition"
          >
            <img
              alt={item.name}
              className={`${item.height}`}
              src={item.imgUrl}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export { Partners };
