import useCustomEffect from "hooks/useCustomEffect.hook";
import React, { createContext, useMemo, useState } from "react";

const obiterSyncswapClientID: any = process.env.REACT_APP_FUNDING_API_KEY || "";
const layerZeroClientID: any =
  process.env.REACT_APP_LAYERZERO_FUNDING_API_KEY || "";

export enum ClientType {
  ORBITER_SYNCSWAP = obiterSyncswapClientID,
  LAYERZERO = layerZeroClientID,
}

export interface DepositContextProps {
  deposit: any;
  setDeposit: (deposit: any) => void;
}

/**
 * Deposit Context.
 */

export const DepositContext = createContext<DepositContextProps>({
  deposit: ClientType.ORBITER_SYNCSWAP,
  setDeposit: () => {},
});

export const useDepositContext = () => React.useContext(DepositContext);
/**
 * Deposit Context Provider.
 *
 * @param value string
 * @param children ReactNode
 * @returns ReactNode
 */
export const DepositContextProvider = ({
  value = ClientType.ORBITER_SYNCSWAP,
  children,
}: {
  value?: any;
  children: React.ReactNode;
}) => {
  const [deposit, setDeposit] = useState(value);

  useCustomEffect(() => {
    const storeDeposit =
      localStorage.getItem("deposit") || ClientType.ORBITER_SYNCSWAP;
    setDeposit(storeDeposit);
    applyDeposit(storeDeposit);
  }, []);

  /**
   * Apply deposit.
   *
   * @param deposit string
   */
  const applyDeposit = (deposit: any) => {
    localStorage.setItem("deposit", deposit);
  };

  /**
   * Handle deposit change.
   *
   * @param deposit string
   */
  const handleDepositChange = (deposit: any) => {
    setDeposit(deposit);
    applyDeposit(deposit);
  };

  /**
   * Current context value for deposit.
   */
  const val = useMemo(
    () => ({
      deposit,
      setDeposit: handleDepositChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deposit]
  );

  return (
    <DepositContext.Provider value={val}>{children} </DepositContext.Provider>
  );
};
