import React, { Fragment, useState } from "react";
import MarketLong from "./MarketLong";
import MarketShort from "./MarketSort";
import Tabs from "../Tabs";
import _ from "lodash";

enum TabListType {
  All = "All",
  Total = "Total",
  Goals = "Goals",
  AsianHadicap = "Asian Handicap",
  Half = "Half",
  Special = "Special",
}
interface tabListType {
  name: string;
  key: string;
}

interface MarketData {
  id: string;
  key: string;
  last_updated_at: string;
  name: string;
  odds: Array<OddData>;
  updated_at: string;
}

interface OddData {
  id: string;
  name: string;
  odds: string;
  outcome_id: string;
}

interface OutcomeLiquidity {
  id?: string;
  liquidity?: number;
}

interface MarketChildProps {
  idx: number;
  type: string;
  item: any;
  category: string;
  market: MarketData[];
  filter: string;
  parentUrl: string;
  eventFilter: string;
}

const MarketChild: React.FC<MarketChildProps> = ({
  idx,
  type,
  item,
  market,
  category,
  filter,
  parentUrl,
  eventFilter,
}) => {
  if (type === "long") {
    return (
      <MarketLong
        key={idx}
        item={item}
        market={market}
        filter={filter}
        parentUrl={parentUrl}
      />
    );
  } else {
    return (
      <MarketShort
        key={idx}
        item={item}
        category={category}
        market={market}
        parentUrl={parentUrl}
        eventFilter={eventFilter}
      />
    );
  }
};

const Market = ({ idx, item, type, category, eventFilter, parentUrl }: any) => {
  const [filterTab, setFilterTab] = useState<string>("all");

  const showLottery = item?.lottery || 0;

  const _market =
    type === "sort" && item?.allMarkets?.length > 0
      ? item?.allMarkets.filter(
          (item: any) => item.key === eventFilter && !item?.hide_from_frontend
        )
      : item?.allMarkets;
  const market = _.orderBy(_market, "ordering", "asc");

  const tabList: tabListType[] = [];
  // initial tab list and sorting tab list
  tabList.push({ name: "All", key: "all" }, { name: "Main", key: "main" });
  const tabListType = market
    ? [...market?.map((item: any) => item?.marketType)]
    : [];
  const _tabList = tabListType.sort();
  _tabList.forEach((item: string) => {
    if (item !== "main" && item !== "others") {
      if (tabList.filter((e: tabListType) => e.key === item).length === 0) {
        tabList.push({ name: item.replace(/_/g, " "), key: item });
      }
    }
  });
  if (_tabList.includes("others")) {
    tabList.push({ name: "Others", key: "others" });
  }
  if (item.length === 0) {
    return (
      <Fragment key={idx}>
        <div>No events</div>
      </Fragment>
    );
  }

  return (
    <Fragment key={idx}>
      {type === "long" && (
        <>
          <div className="flex items-center gap-2 overflow-x-auto overflow-y-hidden pb-2 -mb-4">
            {_tabList.filter((e: string) => e !== "main").length > 0 && (
              <Tabs
                tabList={tabList}
                tab={filterTab}
                setTab={setFilterTab}
                scroll={false}
              />
            )}
            {showLottery === 1 && (
              <div className="pl-4 pb-2 -mr-2 flex-shrink-0">
                <label
                  htmlFor="lotte--modal"
                  className="btn btn-sm btn-primary px-2 color-change !border-transparent"
                >
                  <i className="fa-duotone fa-dice"></i>
                  <span className="hidden md:block ml-2">Lottery</span>
                </label>
              </div>
            )}
          </div>
        </>
      )}
      {/* {child} */}
      <MarketChild
        idx={idx}
        filter={filterTab}
        item={item}
        market={market}
        category={category}
        parentUrl={parentUrl}
        type={type}
        eventFilter={eventFilter}
      />
    </Fragment>
  );
};
export default Market;
