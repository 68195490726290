import { Address } from "viem";

export const CONTRACTS: {
  [key: number]: { AAFactory: Address };
} = {
  300: {
    AAFactory: "0x33fbbC985369192659d9a36a8aD3141335C79c69",
  },
  324: {
    AAFactory: "0x4b04200d78c0409B5bbb17058792203FA5fd844C",
  },
};
