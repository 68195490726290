import Tabs from "components/organisms/Tabs";
import React from "react";
import { useSnapshot } from "valtio";
import { userWagerProxy } from "../../../../proxy-state/userWagerProxy";
import { useIntl } from "react-intl";
import {
  OrderSlipFunc,
  OrderTab,
  orderSlipProxy,
} from "proxy-state/betting/OrderSlip";
const OrderHeader = () => {
  const intl = useIntl();
  const { total, orderTab } = useSnapshot(orderSlipProxy);
  const { data: userWager } = useSnapshot(userWagerProxy);

  const wagerOpenCounting = userWager.filter(
    (w: any) => w?.status === 0
  ).length;
  const [betSlipStatus, setBetSlipStatus] = React.useState<boolean>(false);
  const TabList = [
    { name: intl.formatMessage({ id: "Order Slip" }), key: OrderTab.OrderSlip },
    { name: intl.formatMessage({ id: "Recent" }), key: OrderTab.RecentBet },
  ];
  const setTab = (tab: OrderTab) => {
    OrderSlipFunc.setOrderTab(tab);
  };
  return (
    <>
      <Tabs tab={orderTab} setTab={setTab} tabList={TabList} />
    </>
  );
};
export default OrderHeader;
