import { ExplorerURLs } from "contracts/config";
import { myProfileProxy } from "proxy-state/myProfileProxy";
import { useSnapshot } from "valtio";
import { myBalanceProxy } from "proxy-state/myBalanceProxy";

export const useController = () => {
  const myProfile = useSnapshot(myProfileProxy);
  const myBalance = useSnapshot(myBalanceProxy);
  const address = myProfile.address;

  const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 324;
  const explorerUrl = ExplorerURLs[chainId];

  const pasteFunc = async (inputId: string) => {
    const input = document.getElementById(inputId) as HTMLInputElement;
    const text = await navigator.clipboard.readText();
    input.value = text;
  };

  const handleWithdraw = (inputWallet: string, amount: string) => {};
  return {
    pasteFunc,
    myProfile,
    explorerUrl,
    myBalance,
    chainId,
    handleWithdraw,
    address,
  };
};
