import React from "react";
import { DataChain } from "contracts/config";
type LinkProps = {
  linkView: string;
  title?: string;
  icon?: any;
  classes?: string;
  subClasses?: string;
};

function LinkScan({
  linkView,
  title,
  icon = true,
  classes = "",
  subClasses = "",
}: LinkProps) {
  const chainID = process.env.REACT_APP_CHAIN_ID || "8453";
  const dataChain = DataChain[Number(chainID)];
  return dataChain ? (
    <a
      href={dataChain.NEXT_PUBLIC_LINK_EXPLORER_SCAN + linkView}
      target={"_blank"}
      rel="noreferrer"
      className={classes ? classes : "link flex flex-row items-center pt-4"}
    >
      {icon && <i className="fa-solid fa-square-arrow-up-right mr-2"></i>}
      <span className={subClasses ? subClasses : "pr-2"}>
        {title || dataChain.NEXT_PUBLIC_LINK_EXPLORER_NAME}
      </span>
    </a>
  ) : null;
}

export default LinkScan;
