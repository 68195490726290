import { emojiAvatarForAddress } from "./emojiAvatarForAddress";

const CustomAvatar = ({ address, ensImage, size, classes }: any) => {
  const { color, emoji } = emojiAvatarForAddress(address);
  return  (
		<div
			className={classes}
			style={{
				backgroundColor: color,
				borderRadius: 999,
				height: size,
				width: size,
			}}
		>
			{emoji}
		</div>
  );
};

export default CustomAvatar;
