import { proxyMap } from "valtio/utils";
import { proxy } from "valtio";
import {
  DocumentData,
  onSnapshot,
  query,
  Query,
  where,
} from "firebase/firestore";
import { outcomesRef } from "../utils/firebase/firebaseDBRef";
import { OutcomeLiquidity } from "../interfaces/firebase";
import { ConvertString2BN, formatBigNumber } from "../common/utils";

let unsubscribe: any;

class OutcomeProxy {
  isLoading: boolean = false;
  data: Map<string, OutcomeLiquidity> = proxyMap<string, OutcomeLiquidity>([]);
  subscribed: boolean = false;

  load() {
    if (this.subscribed) return;
    this.subscribed = true;
    this.isLoading = true;

    const outcomeCollRef = outcomesRef();
    const q: Query<DocumentData> = query(
      outcomeCollRef,
      where("status", "==", 0)
    );
    unsubscribe = onSnapshot(q, (snapshot) => {
      this.isLoading = false;

      snapshot.docs.forEach((doc) => {
        const liquidity = Number(
          formatBigNumber(ConvertString2BN(doc.data()?.v2_liquidity || "0"))
        );

        this.data.set(doc.id, {
          id: doc.id,
          liquidity: liquidity,
          status: Boolean(doc.data()?.status ?? false),
        });
      });
    });
  }
  updateOutcomeLiquidity(outcomeId: string, newData: Partial<OutcomeLiquidity>) {
    this.data.set(outcomeId, newData);
  }
  unsubscribeDb() {
    if (unsubscribe) {
      unsubscribe();
      this.subscribed = false;
    }
  }
}

export const outcomeProxy = proxy(new OutcomeProxy());
