import { BigNumber, ethers } from "ethers";
import {
  BN0,
  buildSystemOrders,
  buildWagerId,
  DECIMALS,
  WEI6,
} from "./betUtils";
import { getMarketOdds } from "./event";
import {
  ConvertString2BN,
  formatBigNumber,
  parseBigNumber,
} from "common/utils";
import { OutcomeLiquidity } from "interfaces/firebase";

const aSum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);

function _toStrength(odds: number, sensitivity: number) {
  return odds ? sensitivity / odds : 0;
}

const _getData = (oddses: number[], liquids: number[], sensitivity: number) => {
  const strengths = oddses.map((o) => _toStrength(o, sensitivity));
  const totalStrength = aSum(strengths);
  const marketLiquid = aSum(liquids);
  return { strengths, totalStrength, marketLiquid };
};

function _dStrength(oddsStrength: number, totalStrength: number) {
  return oddsStrength / totalStrength;
}

function _dLiquid(
  oddsStrength: number,
  totalStrength: number,
  oddsLiquid: number,
  marketLiquid: number
) {
  return (oddsStrength + oddsLiquid) / (totalStrength + marketLiquid);
}

function _oddsByLiquid(
  oddsStrength: number,
  totalStrength: number,
  oddsLiquid: number,
  marketLiquid: number,
  sensitivity: number
) {
  const dS = _dStrength(oddsStrength, totalStrength);
  const dL = _dLiquid(oddsStrength, totalStrength, oddsLiquid, marketLiquid);
  const oL =
    sensitivity /
    (totalStrength *
      _dLiquid(oddsStrength, totalStrength, oddsLiquid, marketLiquid));
  const orgOdds = sensitivity / oddsStrength;
  return dL > dS ? oL : ((dS - dL) / dS) * (orgOdds - 1) + orgOdds;
}

export function _getOdds(
  oIdx: number,
  oddses: number[],
  liquids: number[],
  sensitivity: number
) {
  if (!oddses[oIdx]) return 0;
  const { strengths, totalStrength, marketLiquid } = _getData(
    oddses,
    liquids,
    sensitivity
  );
  const oddsStrength = strengths[oIdx];
  const oddsLiquid = liquids[oIdx];

  return _oddsByLiquid(
    oddsStrength,
    totalStrength,
    oddsLiquid,
    marketLiquid,
    sensitivity
  );
}

export function _getOfferOdds(
  amount: number,
  oIdx: number,
  oddses: number[],
  liquids: number[],
  sensitivity: number
) {
  const { strengths, totalStrength, marketLiquid } = _getData(
    oddses,
    liquids,
    sensitivity
  );
  const oddsStrength = strengths[oIdx];
  const oddsLiquid = liquids[oIdx];
  const futureMarketLiquid = marketLiquid + amount;
  const rake = totalStrength / sensitivity;

  const futureOddses = strengths.map((_, i) =>
    _oddsByLiquid(
      strengths[i],
      totalStrength,
      liquids[i] + (i == oIdx ? amount : 0),
      futureMarketLiquid,
      sensitivity
    )
  );
  // calculate offerOdds with rake
  let _s = rake;
  for (let i = 0; i < futureOddses.length; i++) {
    if (i != oIdx) {
      _s -= 1 / futureOddses[i];
    }
  }
  let offerOdds = _s > 0 ? 1 / _s : 1;
  const currentOdds = _oddsByLiquid(
    oddsStrength,
    totalStrength,
    oddsLiquid,
    marketLiquid,
    sensitivity
  );
  const futureOdds = _oddsByLiquid(
    oddsStrength,
    totalStrength,
    oddsLiquid + amount,
    marketLiquid + amount,
    sensitivity
  );

  const maxOdds = (currentOdds + futureOdds) / 2;
  if (offerOdds > maxOdds) offerOdds = maxOdds;
  if (offerOdds < futureOdds) offerOdds = futureOdds;
  return offerOdds * 0.999;
}

export function _getWager(
  amount: number,
  oIdx: number,
  oddses: number[],
  liquids: number[],
  sensitivity: number
) {
  return amount * _getOfferOdds(amount, oIdx, oddses, liquids, sensitivity);
}

export function _getRake(oddses: number[]) {
  let rake = 0;
  for (let i = 0; i < oddses.length; i++) {
    rake += oddses[i] ? 1 / oddses[i] : 0;
  }
  return rake - 1;
}

export const _checkOdd = (odd: number, comboSize: number): number => {
  // check if need apply max odd
  const maxOdds = [100, 200, 300, 500, 750, 1000];
  const maxOdd = maxOdds[comboSize - 1];
  return maxOdd < odd ? maxOdd : odd;
};

const getOutcomesData = (
  outcomeIds: BigNumber[],
  eventsData: any[],
  outcomeLiquids: Map<string, OutcomeLiquidity>
): Record<
  string,
  {
    index: number;
    outcomeLiquid: BigNumber;
    marketOutcomeOdds: number[];
    marketOutcomeLiquids: number[];
  }
> => {
  const data: Record<
    string,
    {
      index: number;
      outcomeLiquid: BigNumber;
      marketOutcomeOdds: number[];
      marketOutcomeLiquids: number[];
    }
  > = {};

  const marketOdds = getMarketOdds(outcomeIds, eventsData);
  const outcomes = marketOdds.reduce(
    (accumulator, item) => [...accumulator, ...item.marketOutcomeOdds],
    [] as any[]
  );
  const liquids = outcomes.map((item) => {
    const outcomeLiquid = outcomeLiquids.get(item.outcome_id);
    if (outcomeLiquid) {
      return parseBigNumber(Number(outcomeLiquid.liquidity || 0), true);
    }
    return BigNumber.from(0);
  });

  // const liquids = await this.getLiquids(
  //         outcomes.map((item) => item.outcome_id)
  // );

  const outcomeLiquidsAll: Record<string, BigNumber> = {};
  // const marketLiquidsAll: Record<string, BigNumber> = {};

  outcomes.forEach((item, index) => {
    outcomeLiquidsAll[item.outcome_id] = liquids[index];
    // marketLiquidsAll[BigNumber.from(item.outcome_id).shr(32).toString()] =
    //         liquids.marketLiquids[index];
  });

  for (let i = 0; i < outcomeIds.length; i++) {
    const outcomeId = outcomeIds[i].toString();

    const marketOutcomeLiquids = marketOdds[i].marketOutcomeOdds.map((item) => {
      return Number(
        formatBigNumber(
          ConvertString2BN(outcomeLiquidsAll[item.outcome_id]),
          true
        )
      );
    });

    const marketOutcomeOdds = marketOdds[i].marketOutcomeOdds.map((item) =>
      Number(item.odds)
    );

    data[outcomeId] = {
      index: marketOdds[i].index,
      outcomeLiquid: outcomeLiquidsAll[outcomeId],
      // marketLiquid: marketLiquidsAll[outcomeIds[i].shr(32).toString()],
      marketOutcomeOdds: marketOutcomeOdds,
      marketOutcomeLiquids: marketOutcomeLiquids,
    };
  }

  return data;
};

export const calculateSingleBetV2 = (
  outcomeIds: BigNumber[],
  eventsData: any[],
  outcomeLiquids: Map<string, OutcomeLiquidity>,
  amounts: BigNumber[],
  sensitivity: number
): {
  wagerIds: BigNumber[];
  wagerAmounts: BigNumber[];
  totalAmount: BigNumber;
  totalWagerAmount: BigNumber;
  wagerOdds: number[];
} => {
  if (outcomeIds.length !== amounts.length) {
    throw new Error("Length of outcomes not the same with amounts");
  }
  const data = getOutcomesData(outcomeIds, eventsData, outcomeLiquids);

  const wagerIds: BigNumber[] = [];
  const wagerAmounts: BigNumber[] = [];
  const wagerOdds: number[] = [];
  let totalAmount = BN0;
  let totalWagerAmount = BN0;

  for (let i = 0; i < outcomeIds.length; i++) {
    const outcomeId = outcomeIds[i].toString();

    const odd = _getOfferOdds(
      Number(formatBigNumber(amounts[i], true)),
      data[outcomeId].index,
      data[outcomeId].marketOutcomeOdds,
      data[outcomeId].marketOutcomeLiquids,
      sensitivity
    );

    wagerOdds[i] = odd;
    wagerAmounts[i] = ethers.utils.parseUnits(
      (
        Number(ethers.utils.formatUnits(amounts[i] || 0, DECIMALS)) *
        _checkOdd(odd, 1)
      ).toFixed(DECIMALS),
      DECIMALS
    );
    wagerIds[i] = buildWagerId([outcomeIds[i]]);
    totalAmount = totalAmount.add(amounts[i]);
    totalWagerAmount = totalWagerAmount.add(wagerAmounts[i]);
  }

  return {
    wagerIds,
    wagerAmounts,
    totalAmount,
    totalWagerAmount,
    wagerOdds,
  };
};

export const calculateSystemBetV2 = (
  systemType: number,
  outcomeIds: BigNumber[],
  eventsData: any[],
  outcomeLiquids: Map<string, OutcomeLiquidity>,
  amount: BigNumber,
  sensitivity: number
): {
  wagerIds: BigNumber[];
  wagerAmounts: BigNumber[];
  wagerOutcomes: BigNumber[][];
  oddCount: number;
  wagerOdds: number[];
  totalWagerAmount: BigNumber;
} => {
  const data = getOutcomesData(outcomeIds, eventsData, outcomeLiquids);
  const _combos: number[][] = buildSystemOrders(systemType, outcomeIds.length);

  const wagerIds: BigNumber[] = [];
  const wagerAmounts: BigNumber[] = [];
  const wagerOdds: number[] = [];
  const wagerOutcomes: BigNumber[][] = [];
  let oddCount = 0;
  let totalWagerAmount = BN0;

  for (let i = 0; i < _combos.length; i++) {
    let wagerAmount = Number(formatBigNumber(amount, true));
    const _ocs: BigNumber[] = [];
    let odd = 1;
    for (let j = 0; j < _combos[i].length; j++) {
      const _idx = _combos[i][j];
      const outcomeId = outcomeIds[_idx].toString();
      _ocs[j] = outcomeIds[_idx];
      const offerOdds = _getOfferOdds(
        wagerAmount,
        data[outcomeId].index,
        data[outcomeId].marketOutcomeOdds,
        data[outcomeId].marketOutcomeLiquids,
        sensitivity
      );
      odd = odd * offerOdds;
      wagerAmount = Number(formatBigNumber(amount, true)) * odd;
    }

    wagerOutcomes.push(_ocs);

    const finalWagerAmount =
      Number(formatBigNumber(amount, true)) * _checkOdd(odd, _combos[i].length);

    wagerAmounts[i] = parseBigNumber(
      Number(finalWagerAmount.toFixed(DECIMALS)),
      true
    );
    wagerOdds[i] = _checkOdd(odd, _combos[i].length);
    oddCount += _combos[i].length;

    wagerIds[i] = buildWagerId(_ocs);
    totalWagerAmount = totalWagerAmount.add(wagerAmounts[i]);
  }

  return {
    wagerIds,
    wagerAmounts,
    oddCount,
    wagerOdds,
    wagerOutcomes,
    totalWagerAmount,
  };
};
