import React, { Fragment } from "react";
import MyOrderGrid from "components/organisms/Bets/OrderHistory/Type/grid";
import { History } from "interfaces/firebase";
import { limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { AMM } from "functions/amm";
import { BigNumber } from "ethers";
import HistorySkeleton from "components/commons/Loading/HistorySkeleton";
import { useAccount } from "hooks/useAccount";
import { useSnapshot } from "valtio";
import { eventProxy } from "proxy-state/eventProxy";
import { userHistoryCollRef } from "utils/firebase/firebaseDBRef";
import { userWagerProxy } from "proxy-state/userWagerProxy";
import { useAuth } from "contexts/authContext";

const MyOrder = () => {
  const { address } = useAccount();
  const [historyData, setHistoryData] = React.useState<History[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { events } = useSnapshot(eventProxy);
  const { data: userWagers } = useSnapshot(userWagerProxy);
  const {token: isAuthenticated} = useAuth();

  React.useEffect(() => {
    if (!address) return;
    try {
      setLoading(true);
      const historyRef: any = userHistoryCollRef(address);
      const q = query(historyRef, orderBy("transactionTime", "desc"), limit(3));
      onSnapshot(q, (snapshot: any) => {
        let eventId: string[] = [];
        const data: History[] = [];
        for (let [, doc] of snapshot?.docs?.entries()) {
          const eventIds: string[] = doc
            .data()
            .outcomes.map((o: any) =>
              AMM.parseOutcomeId(BigNumber.from(o)).eventId.toString()
            );
          eventId.push(...eventIds);
          eventProxy.loadByIds(eventIds);
          const unLoadedWager: string[]= [];
          let check_Open = true;
          const type = Number(doc.data().system_type) > 1 ? doc.data().wagers?.length === doc.data()?.outcomes.length ? 'parlay' : "system" : "single";
          const wagerMaping = doc.data().wagers.map((wager: any) => {
            const _w = userWagers.find((w) => w.id === wager.id);
            if (!_w && doc.data().order_status !== "pending"){
              unLoadedWager.push(wager.id);
            }
            
            return { ...wager, ..._w };
          });
          
          if (doc.data().order_status !== "pending") {
            if (type === "parlay" && wagerMaping.filter((w: any) => w?.status !== 0)?.length) {
              check_Open = false;
            }
            if (type !== "parlay" && wagerMaping.filter((w: any) => w?.status === 0)?.length) {
              check_Open = true;
            } else if (type !== "parlay" && wagerMaping.filter((w: any) => w?.status !== 0)?.length) {
              check_Open = false;
            }
          }
          if (unLoadedWager.length){
            userWagerProxy.loadByIds(address!, unLoadedWager);
          }
          check_Open && data.push({
            txHash: doc.id,
            amounts: doc.data().amounts,
            created_at: doc.data().created_at,
            outcomes: doc.data().outcomes,
            system_type: doc.data().system_type,
            transactionTime: doc.data().transactionTime,
            total_amount: doc.data().total_amount,
            wagers: wagerMaping,
            order_status: doc.data().order_status,
            type: doc.data().type,
          });
        }
        setHistoryData(data);
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  if (!isAuthenticated) return (
    <>
      <div className="pt-[50%]">
        <div className="empty-state flex flex-col items-center">
          <i className="text-6xl text-primary fa-duotone fa-receipt icon-primary mb-6"></i>
          <span className="text-md font-semibold">Please login to view your bets!</span>
        </div>
      </div>
    </>
  );
  if (loading) return <HistorySkeleton length={3} />;

  if (!historyData.length)
    return (
      <>
        <div className="pt-[50%]">
          <div className="empty-state flex flex-col items-center">
            <i className="text-6xl text-primary fa-duotone fa-receipt icon-primary mb-6"></i>
            <span className="text-lg font-semibold">Open bets not found!</span>
          </div>
        </div>
      </>
    );
  return (
    <>
      {historyData.length > 0 && (
        <Fragment>
          <div className="grid gap-2 p-2">
            <MyOrderGrid
              ordersData={historyData}
              eventsData={events}
              type={"sb-right"}
              loading={loading}
            />
          </div>
        </Fragment>
      )}
    </>
  );
};

export default MyOrder;
