import moment from "moment";
import React, { useState, useEffect } from "react";

interface EventStartInProps {
  targetDate: Date;
}

const EventStartIn: React.FC<EventStartInProps> = ({ targetDate }) => {
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;

      setCountdown(distance);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  const formatTime = (time: number): string => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);

    return minutes > 0 ? `${minutes}m` : `${seconds}s`;
  };

  return (
    <span
      className="flex items-center text-error font-semibold"
      title={moment(targetDate).format("HH:mm a, DD/MM/YYYY")}
    >
      <i className="fa-sharp fa-solid fa-clock fa-fade mr-1"></i>
      Starts in {formatTime(countdown)}
    </span>
  );
};

export default EventStartIn;
