import {
  goerli,
  zkSync,
  zkSyncSepoliaTestnet,
  mainnet,
  lineaTestnet,
} from "wagmi/chains";
export { FunctionGetKey as F_ } from "./constants";

export enum ChainId {
  GOERLI = goerli.id,
  ZKSYNC_TESTNET = zkSyncSepoliaTestnet.id,
  ZKSYNC_MAINNET = zkSync.id,
  MAINNET = mainnet.id,
  LINEA_TESTNET = lineaTestnet.id,
  LINEA_MAINNET = 59144,
}
