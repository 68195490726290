import React from "react";
const MainTopOutrights = () => {
  return (
    <>
      <div className="empty-state flex flex-col items-center">
        <i className="text-6xl text-primary fa-duotone fa-gift icon-primary mb-6"></i>
        <span className="text-lg font-semibold"></span>
        <span>coming soon</span>
      </div>
    </>
  );
};
export default MainTopOutrights;
