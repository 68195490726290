import _ from "lodash";
import React, { Fragment } from "react";
import { floatFormat, formatBigNumber } from "common/utils";
import { ConvertString2BN } from "../../../../common/utils";
import { UserWagers } from "interfaces/firebase";

const SystemMode = ({ item }: any) => {
  const [showAll, setShowAll] = React.useState(false);
  const order_type = item?.order_type;
  let wagerLimit = _.orderBy(item?.wagers, ["amount"], ["desc"]);
  if (!showAll) {
    wagerLimit = wagerLimit?.slice(0, 4);
  }
  const outcomeIds: string[] = item?.outcomes;

  const getComboName = (outcome_ids: string[]) => {
    const comboName = outcome_ids?.map(
      (outcomeId: string) => outcomeIds.findIndex((id) => id === outcomeId) + 1
    );
    comboName?.sort();
    return comboName?.join(" - ");
  };
  if (item?.order_status === "pending" || item?.order_status === "processing") {
    return (
      <Fragment>
        <div className="text-xs py-4 px-2 flex flex-col">
          <div className="flex justify-between items-center">
            <strong>System Details</strong>
            <span>{item?.order_status}</span>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="text-xs py-4 px-2 flex flex-col">
        <div className="flex justify-between items-center">
          <strong>System Details</strong>
          <span>
            {order_type}
            {`(`}
            {item?.wagers?.length} Bets{`)`}
          </span>
        </div>
        <div className="flex w-full justify-evenly opacity-70 mt-4 mb-2">
          <span className="w-1/2">Combo</span>
          <span className="w-1/3 text-center">Odd</span>
          <span className="w-1/3 text-center">Amount</span>
          <span className="w-1/4 text-center">Payout</span>
          <span className="w-1/3 text-right">Status</span>
        </div>
        {wagerLimit.length > 0 &&
          wagerLimit.map((wager: UserWagers, idx: number) => (
            <Fragment key={idx}>
              <div className="flex w-full justify-evenly py-1">
                <span className="w-1/2">{getComboName(wager?.outcomeIds, )}</span>
                <span className="w-1/3 text-center">
                  {floatFormat(
                    wager?.amount /
                      (item?.total_amount / Number(item?.wagers.length))
                  )}
                </span>
                <span className="w-1/3 text-center">
                  {floatFormat(
                    formatBigNumber(
                      ConvertString2BN(
                        item?.total_amount / Number(item?.wagers.length)
                      ),
                      true
                    )
                  )}
                </span>
                <span className="w-1/4">
                  {floatFormat(
                    formatBigNumber(ConvertString2BN(wager?.amount), true)
                  )}
                </span>
                <span className="w-1/3 text-right">
                  <span
                    className={`uppercase ${
                      Number(wager?.status) === 4 ? "text-success" : ""
                    } ${Number(wager?.status) === 3 ? "text-error" : ""} ${
                      Number(wager?.status) === 5 ? "text-warning" : ""
                    }`}
                  >
                    {(Number(wager.status) === 0 || !wager.status) && "Open"}
                    {Number(wager.status) === 3 && "Lost"}
                    {Number(wager.status) === 4 && "Won"}
                    {Number(wager.status) === 5 && "Voided"}
                  </span>
                </span>
              </div>
            </Fragment>
          ))}
        {item?.wagers.length > 4 && (
          <div className="flex w-full justify-evenly opacity-70 mt-1 mb-2">
            <span className="w-1/2">...</span>
            <button
              className="w-1/2 text-right"
              onClick={() => {
                setShowAll(!showAll);
              }}
            >
              {showAll ? "Show less" : "Show all"}
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default SystemMode;
