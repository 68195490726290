import jwtDecode from "jwt-decode";

export const ACCESS_TOKEN = "access_token";
export const PRIVY_TOKEN = "privy:token";
export const PRIVY_CONNECTED = "privy:connections";
export const CLIENT_VERSION = "client_version";
export const IS_REQUIRED_RE_OPEN_PWA = "is_required_re_open_pwa";
export const REF_CODE = "ref_code";
export const ORDER_DATA = "order_data";
export const ADDRESS = "address";
export const IS_CLOSED_TOPBAR = "is_closed_topbar";
export const INVITATION_CODE = "invitation_code";
export const LOTTERY_TOKEN = "lottery_access_token";

function isJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
export const getItem: (key: string) => string | null = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? (isJSON(data) ? JSON.parse(data)?.value : data) : null;
};

export const saveItem = (key: string, value: string | any) => {
  if (typeof window !== "undefined") {
    const timestemp = new Date().getTime();
    localStorage.setItem(key, JSON.stringify({ value, timestemp }));
  }
};
export const getExpiredItem = (key: string) => {
  const data = localStorage.getItem(key);
  if (data && isJSON(data)) {
    const { value } = JSON.parse(data);
    const datas: { sub: string; exp: number; iat: number } = jwtDecode(
      value as string
    );
    // create address
    if (datas?.sub && !getItem(ADDRESS)) {
      saveItem(ADDRESS, datas.sub);
    }
    const now = new Date().getTime() / 1000;
    const expired = datas?.exp > 0 && datas?.exp - 60 * 60 * 12 < now; // 1 days
    return expired ? true : false;
  }
  return true;
};

export const deleteItem = (name: string) => {
  return localStorage.removeItem(name);
};
export const clearStorage = () => {
  return localStorage.clear();
};
