import React, { Fragment } from "react";
import OddElement from "./OddElement";

interface gameLinesProps {
  idx: number;
  marketLiquid: number;
  market: any;
  item: any;
  odds: any;
  parentUrl: string;
}

const MoneyLine: React.FC<gameLinesProps> = ({
  idx,
  market,
  item,
  odds,
  parentUrl,
  marketLiquid,
}) => {
  let header1Odd: any = {};
  let header2Odd: any = {};
  header1Odd = odds.find((odd: any) => Number(odd.header) === 1);
  header2Odd = odds.find((odd: any) => Number(odd.header) === 2);
  const oddMap = [
    {
      ...header1Odd,
      name: market?.home?.name,
    },
    {
      ...header2Odd,
      name: market?.away?.name,
    },
  ];
  if (header1Odd === undefined && header2Odd === undefined) return null;
  return (
    <Fragment key={idx}>
      <div className="grid grid-cols-2 gap-2 w-full">
        {oddMap.map((itemOdd: any, idx: number) => {
          return (
            <Fragment key={idx}>
              <OddElement
                idx={idx}
                odd={itemOdd}
                item={item}
                market={market}
                parentUrl={parentUrl}
                marketLiquid={marketLiquid}
              />
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export default MoneyLine;
