import React from "react";
import { ReactComponent as IconShare } from "assets/icons/share.svg";
import { ReactComponent as IconPlus } from "assets/icons/plus.svg";
import FooterLanding from "./footerLanding";
import BgVideo from "assets/wallpapers/soccer.mp4";

interface Props {
  isDevice: string;
}

export const InstalledPage: React.FC<Props> = ({ isDevice }) => {
  return (
    <>
      {/* <div data-theme="dark" className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-900 to-gray-950 text-base-content/70 overflow-hidden">
				<div className="fixed inset-0 opacity-20 z-0 w-screen h-screen  bg-cover">
					<video className="object-cover w-full h-screen" autoPlay playsInline loop muted>
						<source type="video/webm" src={BgVideo} />
					</video>
				</div>
				<div className="main-layout">
					<div className="w-full min-h-screen flex items-center justify-center flex-col z-0 relative">
						<div className="mx-auto w-full max-w-md bg text-center p-4 lg:p-0">

							<div className="flex items-center justify-center">
								<img alt={""} className={"h-10 cursor-pointer mx-auto"} src={`/logo/logo-dark-goal3.svg`} />
							</div>

							<div className="mt-8 mx-auto text-base">
								<p>The First Decentralized Sportsbook on zkSync<br />Super PWA with self-custody AA wallets</p>
							</div>

							<div className="mt-8 p-8 bg-base-300/70 rounded-xl shadow-2xl">

								<h2 className="text-2xl font-bold mb-4">Install Goal3 App</h2>
								{isDevice === 'ios' && (
									<>
										<ul className="steps steps-vertical w-full">
											<li className="step step-primary">
												<div className="w-full text-left">Tap the Share button&nbsp; <span className="h-5 inline-block relative -top-1" ><img src="/icons/share.svg" /></span> &nbsp;on the bottom</div>
											</li>
											<li className="step step-primary">
												<div className="w-full text-left">Scroll down until you see <strong>Add to Home Screen</strong></div>
											</li>
											<li className="step step-primary">
												<div className="w-full text-left">Tap <strong className="text-base-content">Add to Home Screen</strong>&nbsp; <span className="h-4 inline-block relative -mt-1" ><img src={'/icons/plus.svg'} /></span></div>
											</li>
										</ul>
									</>
								)}
								{isDevice === 'android' && (
									<ul className="steps steps-vertical w-full">
										<li className="step step-neutral">
											<div className="w-full text-left">
												Open Chrome Menu&nbsp; <i className="fa-regular fa-ellipsis-vertical text-base-content"></i> &nbsp;at the top right
											</div>
										</li>
										<li className="step step-neutral">
											<div className="w-full text-left">
												Scroll down until you see <strong>Add to Home Screen</strong>
											</div>
										</li>
										<li className="step step-neutral">
											<div className="w-full text-left">
												Tap <strong>Add to Home Screen</strong>&nbsp;{" "}
												<img
													alt=""
													className="h-4 inline-block relative -mt-1 fill-slate-50"
													src="/icons/add-to-home-screen.svg"
												/>
											</div>
										</li>
									</ul>
								)}
							</div>

							<FooterLanding />
						</div>
					</div>
				</div>
			</div> */}
      <div
        data-theme="dark"
        className="main-layout--wrapper !max-w-screen !max-h-screen bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
      >
        <div className="fixed inset-0 opacity-10 z-0 w-screen h-screen  bg-cover">
          <video
            className="object-cover w-full h-screen"
            autoPlay
            playsInline
            loop
            muted
          >
            <source type="video/webm" src={BgVideo} />
          </video>
        </div>

        <div className="main-layout">
          {/* <div className={`w-full h-[calc(var(${minHeight ? minHeight : "1vh"})*100)] flex items-center justify-center flex-col z-0 relative`}> */}
          <div
            className={`w-full h-[calc(var(--vh,1vh)*100)]  flex items-center justify-center flex-col z-0 relative`}
          >
            <div className="mx-auto w-full max-w-sm text-center p-4 lg:p-0">
              <div className="flex flex-col gap-6">
                <div className=" w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center">
                  <i className="fa-duotone fa-arrow-down-to-line icon-primary text-3xl"></i>
                </div>

                <h2 className="text-2xl font-semibold">Install App</h2>

                <div className="bg-base-100/20 text-base-content/70 rounded-xl shadow-2xl p-6">
                  {isDevice === "ios" && (
                    <>
                      <ul className="steps steps-vertical w-full">
                        <li className="step step-neutral">
                          <div className="w-full text-left">
                            Click on &nbsp;{" "}
                            <IconShare className="inline-block relative -top-1 fill-slate-50" />{" "}
                            &nbsp;at the bottom of Safari Browser
                          </div>
                        </li>
                        <li className="step step-neutral">
                          <div className="w-full text-left">
                            Scroll down and choose{" "}
                            <strong>Add to Home Screen</strong>
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                  {isDevice === "android" && (
                    <ul className="steps steps-vertical w-full">
                      <li className="step step-neutral">
                        <div className="w-full text-left">
                          Open Chrome Menu&nbsp;{" "}
                          <i className="fa-regular fa-ellipsis-vertical text-base-content"></i>{" "}
                          &nbsp;at the top right
                        </div>
                      </li>

                      <li className="step step-neutral">
                        <div className="w-full text-left">
                          Tap <strong>Add to Home Screen</strong>&nbsp;{" "}
                          <IconPlus className="inline-block relative -mt-1 fill-slate-50" />
                        </div>
                      </li>
                      <li className="step step-neutral">
                        <div className="w-full text-left">
                          Wait for the app to be installed
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <FooterLanding />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
