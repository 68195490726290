import React, { Fragment, useEffect, useState } from "react";
import OrderBox from "./orderBox";
// import OrderSlipHeader from "./orderSlipHeader";
import OrderSlipFooter from "./orderSlipFooter";
import MyOrder from "./myOrder";
import _ from "lodash";
import slugs from "navigation/slugs";
import { Link, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { connectWalletProxy, navFunc, navProxy } from "proxy-state/global";
import { OrderSlipFunc, orderSlipProxy } from "proxy-state/betting/OrderSlip";
import { betOrder, OrderTab } from "proxy-state/betting/OrderSlip";
import { eventProxy } from "proxy-state/eventProxy";
import { _getOdds } from "functions/amm-v2";
import { outcomeProxy } from "proxy-state/outcomeProxy";
import { useAuth } from "contexts/authContext";
import { liquidProxy } from "proxy-state/betting/Liquidity";
import { TargetFunc } from "proxy-state/betting/target";
import OrderSlipHeader from "./orderSlipHeader";

let timeoutClearAll: NodeJS.Timeout | undefined;
const OrderSlips = () => {
  const { token: isAuthenticated } = useAuth();
  const { sensitivity } = useSnapshot(liquidProxy);
  const { data: events } = useSnapshot(eventProxy);
  const { isShowOrder } = useSnapshot(navProxy);
  const naviagtior = useNavigate();
  // const { total, items, empty } = useOrderPlace();
  const {
    total,
    itemsData: betSlips,
    sameOrder,
    orderTab,
  } = useSnapshot(orderSlipProxy);
  const { data: OutcomeLiquids } = useSnapshot(outcomeProxy);
  const { clearItems: empty } = OrderSlipFunc;
  // const [orderTab, setOrderTab] = useState("order-slip");
  const [modeTab, setModeTab] = useState("single-mode");
  const [isClearing, setIsClearing] = useState(false);
  const _betSlips: betOrder[] = [];
  if (betSlips.length > 0) {
    // const betSlips: betOrder[] = Array.from(OrderItems.values());
    const eventIds: string[] = betSlips.map((item) => item.eventId.toString());
    //get Odds of outcome
    for (let betSlip of betSlips) {
      const _betSlip = {
        ...betSlip,
        odds: { ...betSlip.odds },
        placeOrder: { ...betSlip.placeOrder },
      };
      let odds: any[] = [];
      let index = 0;
      // let _odd = "";
      const event = events.get(_betSlip.eventId.toString());
      if (!event) {
        eventProxy.loadByIds(eventIds);
      } else {
        // eslint-disable-next-line no-loop-func
        Object.keys(event?.markets).forEach((maGroup: string) => {
          let f = false;
          event?.markets?.[maGroup].forEach((ma: any) => {
            let check = ma.odds.findIndex((odd: any) => {
              return odd.outcome_id === _betSlip.odds.outcome_id;
            });
            if (check >= 0) {
              f = true;
              odds = ma.odds;
              index = check;
              // _odd = ma.odds[check].odds;
            }
          });
          if (f) {
            return;
          }
        });
        _betSlip.time_status = Number(event?.time_status || 0).toString();
      }
      const marketOutcomeLiquids = odds.map(
        (item) => OutcomeLiquids.get(item.outcome_id)?.liquidity || 0
      );
      const marketOutcomeOdds = odds.map((item) => Number(item.odds));

      let marketLiquid = marketOutcomeLiquids.reduce(
        (result, value) => result + value,
        0
      );

      const liquidity = Number(
        OutcomeLiquids.get(_betSlip.odds.outcome_id)?.liquidity || 0
      );

      const odd_roi = _getOdds(
        index,
        marketOutcomeOdds,
        marketOutcomeLiquids,
        sensitivity
      );

      if (_betSlip.odds.roi !== odd_roi) {
        _betSlip.odds.roi = odd_roi;
        _betSlip.odds.liquidity = liquidity;
        _betSlip.marketLiquid = marketLiquid;
        // OrderSlipFunc.addItem(_betSlip);
      }
      _betSlips.push(_betSlip);
    }
  }
  useEffect(() => {
    if (total >= 2) {
      setModeTab("combo-mode");
    } else {
      setModeTab("single-mode");
    }
  }, [total]);

  const closed = (rawData: betOrder[]) => {
    return rawData.some((element: betOrder) => {
      const _closed = Number(element?.time_status) > 0;
      return _closed;
    });
  };
  const _closed = closed(_betSlips);
  if (_closed) {
    OrderSlipFunc.setClosed(true);
  } else {
    OrderSlipFunc.setClosed(false);
  }
  const orderSlipsEmpty = () => {
    empty();
    setModeTab("single-mode");
    OrderSlipFunc.setClosed(false);
    TargetFunc.singleTargetClear();
  };

  const handleClear = () => {
    setIsClearing(true);
    timeoutClearAll = setTimeout(() => {
      setIsClearing(false);
      orderSlipsEmpty();
    }, 3000);
  };

  const handleCancelClear = () => {
    setIsClearing(false);
    if (timeoutClearAll) {
      clearTimeout(timeoutClearAll);
    }
  };

  let child: any;

  if (_betSlips.length > 0) {
    let Data: any = [];
    if (sameOrder && modeTab !== "single-mode") {
      Data = _.groupBy(_betSlips, "eventId");
      const betSlipKeys = Object.keys(Data);
      let sameCounter = 0;
      child = betSlipKeys.map((betId, idx) => {
        const items = Data[betId];
        if (items.length > 1) sameCounter += 1;
        if (items.length === 1) sameCounter = 0;
        return (
          <Fragment key={idx}>
            {items.length > 1 ? (
              <div className={`bet--same-order bet--same-order-${sameCounter}`}>
                {items.map((item: any, index: number) => (
                  <OrderBox
                    item={item}
                    key={index}
                    betidx={idx}
                    modeTab={modeTab}
                    sameOrder={items.length > 1 ? true : false}
                  />
                ))}
                <span className="flex items-center text-xs bg-warning text-warning-content px-4 py-1">
                  <i className="fa-solid fa-circle-exclamation text-sm mr-2"></i>{" "}
                  Selections cannot be combined
                </span>
              </div>
            ) : (
              <OrderBox
                item={items[0]}
                key={idx}
                betidx={idx}
                modeTab={modeTab}
                sameOrder={false}
              />
            )}
          </Fragment>
        );
      });
    } else {
      child = _betSlips.map((item: betOrder, index: number) => {
        return (
          <OrderBox
            item={item}
            key={index}
            betidx={index}
            modeTab={modeTab}
            sameOrder={false}
          />
        );
      });
    }
  } else {
    child = (
      <div className="flex flex-col h-full items-center justify-center">
        <div className="empty-state flex flex-col items-center">
          <i className="text-6xl fa-duotone fa-receipt mb-6 icon-primary"></i>
          <span className="text-lg font-semibold">Order Slip empty</span>
          <span className="text-xs opacity-50 text-center mt-1">
            To add a bet to your order slip, choose a market and make your
            selection!
          </span>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <div
        className={`panel${
          !isShowOrder
            ? " h-full w-0 -z-10 invisible"
            : " h-full lg:min-h-screen"
        }`}
      >
        <OrderSlipHeader />
        <div className={`panel-body flex-1${betSlips.length === 0 ? " !overflow-hidden" : ''}`}>
          {orderTab === OrderTab.OrderSlip && (
            <Fragment>
              <div className="panel-subheader !border-b-0 !pb-0">
                <div className="htabs px-2">
                  <div className="htabs">
                    <button
                      className={`htab${
                        modeTab === "single-mode" ? " htab-active" : ""
                      }`}
                      onClick={() => setModeTab("single-mode")}
                    >
                      <span className="htab-title">Single</span>
                      <span className="htab-border"></span>
                    </button>
                    <button
                      disabled={total < 2 ? true : false}
                      className={`htab${
                        modeTab === "combo-mode" ? " htab-active" : ""
                      }`}
                      onClick={() => setModeTab("combo-mode")}
                    >
                      <span className="htab-title">Parlay</span>
                      <span className="htab-border"></span>
                    </button>

                    <button
                      className={`htab${
                        modeTab === "system-mode" ? " htab-active" : ""
                      }`}
                      onClick={() => setModeTab("system-mode")}
                      disabled={total < 3 ? true : false}
                    >
                      <span className="htab-title">System</span>
                      <span className="htab-border"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className={`flex flex-col gap-2 p-2${betSlips.length === 0 ? ' h-full' : ''}`}>{child}</div>
              {!isClearing && total >= 3 && (
                <div className="mt-2 text-right px-2">
                  <button
                    onClick={() => handleClear()}
                    className="btn btn-sm w-30 !font-medium opacity-50 bg-neutral relative hover:opacity-100 hover:text-error overflow-hidden"
                  >
                    <i className="fa-duotone fa-trash-can-list mr-2"></i>
                    Clear All
                    <div className="absolute h-full left-0 right-0 bottom-0 bg-accent/20 w-0"></div>
                  </button>
                </div>
              )}
              {isClearing && (
                <div className="mt-2 text-right px-2">
                  <button
                    onClick={() => handleCancelClear()}
                    className="btn btn-sm w-30 !font-medium opacity-50 bg-neutral relative hover:opacity-100 hover:text-error overflow-hidden"
                  >
                    <i className="fa-duotone fa-arrow-turn-down-left mr-2"></i>
                    Cancel
                    <div className="absolute h-full left-0 right-0 bottom-0 bg-accent/20 w-[20%] progress-in-button-clear"></div>
                  </button>
                </div>
              )}
            </Fragment>
          )}
          {orderTab === OrderTab.RecentBet && (
            <Fragment>
              <div className="panel-body">
                <div className="">
                  <MyOrder />
                </div>
                {isAuthenticated && (
                  <>
                    <div className="text-xs text-center mt-8 pb-8">
                      <div className="px-3">
                        {/* <Link href={`/account/bets`} className="btn btn-sm btn-block">View All({wagerOpenCounting})</Link> */}

                        <p className="mb-1">
                          Only <strong>Open Bets</strong> will be listed here.
                        </p>
                        <p className="mb-4">
                          To view your History, please go to My Bets.
                        </p>
                        <div className="px-3">
                          <button
                            onClick={() => {
                              navFunc.setNavOpen("isShowOrder", false);
                              naviagtior(slugs.myBets);
                            }}
                            className="btn btn-md btn-block btn-outline btn-outline-neutral border-base-content/10 text-sm"
                          >
                            My Bets
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Fragment>
          )}
        </div>
        {orderTab === OrderTab.OrderSlip && (
          <OrderSlipFooter modeTab={modeTab} _betSlips={_betSlips} />
        )}
      </div>
    </Fragment>
  );
};
export default OrderSlips;
