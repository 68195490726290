import { SubLayout } from "components/layouts/MainLayout/subLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import OrderSlip from "../page";

const routes: AppRouteType[] = [
  {
    path: slugs.orderSlip,
    component: OrderSlip,
    container: SubLayout,
    isAuthNeeded: false,
  },
];

export default routes;
