import React, { Fragment, useEffect, useState } from "react";
import HomeLeaguesList from "../League/home";
import { LoadingSkeleton } from "../../Loading";
import _ from "lodash";
import moment from "moment";
import CategoryNav from "../utils/categoryNav";
import { MatchTypeFilter, defaultFilter } from "../utils/matchTypeFilter";
import { TopMatches } from "utils/constants/constants";
import { Category } from "interfaces/firebase";
import { Link } from "react-router-dom";
import LeaguesNav from "../utils/leaguesNav";
import { useSnapshot } from "valtio";
import { categoryProxy } from "proxy-state/categoryProxy";
import { leagueProxy } from "proxy-state/leagueProxy";
import { League } from "interfaces/firebase";
import { eventProxy } from "proxy-state/eventProxy";
import slugs from "navigation/slugs";
import { Responsive } from "components/commons";
import Banners from "components/organisms/Banners";
import { navigateTab, setNavTab } from "proxy-state/global";
import { useAuth } from "contexts/authContext";
import categoryNavProxy from "proxy-state/categoryNavProxy";

interface FilterActionProps {}

interface HomeCateProps {
  type?: string;
  page: string;
}

const tabList = [TopMatches.ALL, TopMatches.IN24HOUR, TopMatches.IN48HOUR];

const MainMarkets: React.FC<HomeCateProps> = ({ type, page }) => {
  const { publishedCategories } = useSnapshot(categoryProxy);
  const { publishedLeagues } = useSnapshot(leagueProxy);
  const { events, isLoadingActiveEvent } = useSnapshot(eventProxy);
  const {cateTab, setCateTab} = useSnapshot(categoryNavProxy)
  const { token } = useAuth()

  // const snapNavigateTab = useSnapshot(navigateTab);
  // const cateTab2 = snapNavigateTab?.tab?.Home["Top Matches"]?.subTab;
  // const setCateTab2 = (tab: string) => {
  //   setNavTab("Home", "Top Matches", tab);
  // };

  const [leagueTab, setLeagueTab] = useState<string>("all");
  const [tab, setTab] = useState(tabList[0]);
  const [filter, setFilter] = useState("full_time_result");
  // const [listFilter, setListFilter] = useState<filterItemType[]>([]);

  const isSignedIn = token !== "";
  const categories = publishedCategories.map((category) => {
    const eventCount = _.sumBy(
      publishedLeagues.filter(
        (league) => category.id === league.category_id && league.eventCount > 0
      ),
      "eventCount"
    );
    return {
      ...category,
      show: eventCount > 0,
      eventCount: eventCount,
    };
  });
  const currentCategory = categories.find(
    (cate: any) => cate.id === cateTab && cate.eventCount > 0
  );
  let totalEventHome = 0;
  publishedLeagues
    .filter((league) => currentCategory?.id === league.category_id)
    .forEach((league) => {
      totalEventHome += league.eventCount > 3 ? 3 : league.eventCount || 0;
      return league.id;
    });
  const leagues = publishedLeagues.filter(
    (item) => item.category_id === cateTab && item.eventCount > 0
  );
  const leagueIds = leagues.map((league) => league.id);
  let activeEvents = _.orderBy(
    events.filter(
      (item) =>
        item.status === "published" &&
        item.resolved === 0 &&
        item.time_status < "2" &&
        !item.featured &&
        leagueIds.includes(item.league_id)
    ),
    ["time_status", "time"],
    ["asc", "asc"]
  );

  const now = moment();
  const nowTime = now.unix();

  switch (tab) {
    case TopMatches.IN24HOUR:
      const nextMidnight = now.add(24, "h").unix();
      activeEvents = activeEvents?.filter(
        (e) => e.time > nowTime && e.time < nextMidnight
      );
      break;
    case TopMatches.IN48HOUR:
      const nexthalfMidnight = now.add(48, "h").unix();
      activeEvents = activeEvents?.filter(
        (e) => e.time > nowTime && e.time < nexthalfMidnight
      );
      break;
    default:
      break;
  }

  useEffect(() => {
    if (categories.length) {
      if (
        categories?.filter((cat) => cat.eventCount > 0).length > 0 &&
        !currentCategory
      ) {
        const activeCategory = _.orderBy(categories, 'eventCount', 'desc').filter((category: Category) => {
          return category.show;
        });
        if (activeCategory.length) {
          setCateTab(activeCategory[0].id);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (cateTab) {
      const filterActive = defaultFilter(cateTab)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setFilter(filterActive);
    }
  }, [cateTab]);

  const FilterAction: React.FC<FilterActionProps> = () => {
    return (
      <div className="bg-base-200 rounded-box flex items-center gap-1">
        {tabList.map((item: TopMatches, index: number) => (
          <button
            key={index}
            className={`btn btn-sm  ${
              tab === item
                ? "text-base-content bg-neutral hover:bg-neutral-focus"
                : "btn-ghost text-base-content/50"
            }`}
            onClick={() => setTab(item)}
          >
            <span className="">{item}</span>
          </button>
        ))}
      </div>
      // <Tabs tabList={tabList} tab={tab} setTab={setTab} />
    );
  };
  const EmptyEventPage: React.FC<any> = () => {
    return (
      <div className="mt-6 md:mt-12">
        <div className="grid grid-cols-1">
          <div className="mt-6">
            <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
              <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
              <span className="text-lg font-bold">No Matches found</span>
              <span className="text-xs opacity-70 text-center mt-1">
                Ooops, nothing found!!
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Fragment>
        {page === "home" && (
          <Responsive from="lg">
            {/* Banners */}
            <Banners />
            {/* //Banners */}
          </Responsive>
        )}
        <div className="section">
          <div className="section-header">
            {page === "sports" ? (
              <div className="hidden lg:flex items-center gap-3">
                <i className="fa-duotone fa-wreath-laurel icon-primary text-xl"></i>
                <h2 className="text-xl font-semibold capitalize">
                  {cateTab === "mma" ? "MMA" : cateTab?.replace(/-/g, " ")}{" "}
                  Matches
                </h2>
              </div>
            ) : (
              <div className="hidden lg:flex items-center gap-3">
                <i className="fa-duotone fa-fire icon-primary text-xl"></i>
                <h2 className="text-xl font-semibold">Top Matches</h2>
              </div>
            )}

            {!isLoadingActiveEvent && (
              <div className="flex items-center justify-between gap-2 w-full lg:w-auto flex-shrink-0">
                <FilterAction />
                <MatchTypeFilter
                  cateId={cateTab}
                  filter={filter}
                  setFilter={setFilter}
                />
              </div>
            )}
          </div>

          {page === "sports" && leagues.length > 0 && (
            <LeaguesNav />
          )}
          <div className="disclosures">
          {isLoadingActiveEvent && <LoadingSkeleton />}
          {!isLoadingActiveEvent && activeEvents.length === 0 && (
            <EmptyEventPage />
          )}
          {!isLoadingActiveEvent &&
            // isSignedIn &&
            publishedLeagues?.length > 0 &&
            _.orderBy(
              publishedLeagues.filter(
                (item) => item.category_id === cateTab && item.eventCount > 0
              )
            ).map((league, index: number) => {
              const eventsByLeague = activeEvents.filter(
                (event: any) =>
                  event?.league_id === league?.id && event?.resolved === 0
              );
              if (eventsByLeague.length === 0) return null;
              return (
                <Fragment key={index}>
                  <HomeLeaguesList
                    root={"sports"}
                    leagueData={[league]}
                    eventsData={eventsByLeague}
                    type={"sort"}
                    filter={filter}
                    lv={page === "sports" ? page : ""}
                  />
                </Fragment>
              );
            })}
          </div>
          {!page &&
            totalEventHome < Number(currentCategory?.eventCount || 0) && (
              <div className="flex justify-between">
                <Link
                  to={`${slugs.sports}/${cateTab}`}
                  className="btn btn-block btn-outline btn-outline-neutral border-base-content/10 text-sm"
                >
                  See All {currentCategory?.eventCount} Matches{" "}
                  <i className="fa-solid fa-angle-right ml-2"></i>
                </Link>
              </div>
          )}

        </div>
      </Fragment>
    </>
  );
};
export default MainMarkets;
