import React, { Fragment, useEffect, useState } from "react";
import MainTopMatches from "modules/home/page/MainTopMatches";
import MainTopOutrights from "modules/home/page/MainTopOutrights";
import { useIntl } from "react-intl";
import { Responsive } from "components/commons";
import { CategoryList } from "components/organisms/Sports/Category";
import CategoryNav from "components/organisms/Sports/utils/categoryNav";
import { navigateTab, setNavTab } from "proxy-state/global";
import { useSnapshot } from "valtio";
import { categoryProxy } from "proxy-state/categoryProxy";
import { leagueProxy } from "proxy-state/leagueProxy";
import _ from "lodash";

export default function Sports() {
  const intl = useIntl();
  // const [page, setPage] = React.useState("top-matches");

	const [scrollDirection, setScrollDirection] = useState<boolean>(false);
	const snapNavigateTab = useSnapshot(navigateTab);
	const page = snapNavigateTab.tab.Home.currentTab;
	const setPage = (tab: string) => {
		setNavTab("Sports", 'Matches', tab);
	};
	const { data: publishedCategories, isLoading } = useSnapshot(categoryProxy);
	const { data: publishedLeagues, isLoading: leagueLoading } = useSnapshot(leagueProxy)
	const categories = publishedCategories.map((category) => {
		const eventCount = _.sumBy(
			publishedLeagues.filter(
				(league) => category.id === league.category_id && league.eventCount > 0
			),
			"eventCount"
		);
		return {
			...category,
			show: eventCount > 0,
			eventCount: eventCount,
		};
	});
	useEffect(() => {
		let prevScrollPos = window.scrollY;
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			if (currentScrollPos > prevScrollPos) {
				setScrollDirection(false);
			} else if (currentScrollPos < prevScrollPos) {
				setScrollDirection(true);
			}

			prevScrollPos = currentScrollPos;
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

  return (
    <Fragment>
			<CategoryNav root={"sports"} />
      <Responsive upto="md">
        {/* <div className="ctabs-container ctabs-compact-container container">
					<div className="ctabs">
						<button
							className={`ctab${page === "top-matches" ? " ctab-active" : ""}`}
							onClick={() => setPage("top-matches")}
						>
							<span className="flex flex-col justify-center items-center">
								<span className="ctab-title">
									{intl.formatMessage({ id: "Top Matches" })}
								</span>
								<span className="ctab-border"></span>
							</span>
						</button>
						<button
							className={`ctab${page === "top-outrights" ? " ctab-active" : ""
								}`}
							onClick={() => setPage("top-outrights")}
						>
							<span className="flex flex-col justify-center items-center">
								<span className="ctab-title">
									{intl.formatMessage({ id: "Top Outrights" })}
								</span>
								<span className="ctab-border"></span>
							</span>
						</button>
					</div>
				</div> */}
        <div className="container">
          <MainTopMatches page="sports" />
          {/* {page === "top-matches" && <MainTopMatches page="sports" />}
          {page === "top-outrights" && <MainTopOutrights />} */}
        </div>
      </Responsive>

      <Responsive from="lg">
        <div className="container">
          <CategoryList />
        </div>
      </Responsive>
    </Fragment>
  );
}
