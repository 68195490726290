import {
  DocumentData,
  DocumentSnapshot,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { mmFormatUTC } from "use-moment/format";
import { useAccount } from "./useAccount";
import { eventProxy } from "proxy-state/eventProxy";
import { getEventIdsFromOutcomeIdsV2 } from "utils/firebase/helper";
import { userWagerCollRef } from "utils/firebase/firebaseDBRef";
import { Wager , UserWagers} from "interfaces/firebase";
import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { PAGINATION_SIZE } from "shared/constants";
import { IOrderBy, ITimeFilterType } from "shared/enum";
import { getStartTime } from "shared/tab-filter";

const parseDoc = (doc: DocumentData): UserWagers => {
  const _data = doc.data();

  return {
    id: doc.id,
    wager_id: _data.wager_id,
    amount: _data.amount,
    betAmount: _data?.bet_amount,
    outcomeIds: _data?.outcome_ids || [],
    redeemAmount: _data?.redeemAmount || 0,
    status: _data.status,
    redeemable: _data?.redeemable,
    txhash: _data?.txhash,
    created_at: _data.created_at,
    isRedeemed: _data?.isRedeemed,
  };
};

const getConstraints = (params: {
  status: number;
  time: ITimeFilterType;
  unClaimOnly?: boolean;
  filterOrderBy?: IOrderBy;
}) => {
  const { status, time, unClaimOnly, filterOrderBy = IOrderBy.DESC } = params;
  const _constraints: QueryConstraint[] = [];

  if (unClaimOnly) {
    _constraints.push(
      where("status", "==", 4),
      where("isRedeemed", "==", false)
      /// dev: where("isRedeemed", "==", false) <=> where("amount", ">", 0)
      // where("amount", ">", 0),
      // orderBy("amount", "desc")
    );
  } else {
    if (status !== 1) {
      _constraints.push(where("status", "==", status));
    }
    if (status === 4) {
      _constraints.push(orderBy("isRedeemed", "asc"));
    }
  }

  if (time) {
    const startTime = getStartTime(time);
    if (startTime) {
      _constraints.push(where("created_at", ">=", mmFormatUTC(startTime)));
    }
  }

  _constraints.push(orderBy("created_at", filterOrderBy));

  return _constraints;
};

const getEventIdsFromWagers = (wagers: UserWagers[]): string[] => {
  const eventIds: string[] = [];
  for (let wager of wagers) {
    const subEventIds: string[] = getEventIdsFromOutcomeIdsV2(wager.outcomeIds);
    eventIds.push(...subEventIds);
  }
  return eventIds;
};

const useFetchWager = (params: {
  status: number;
  time?: any;
  unClaimOnly?: boolean;
  filterOrderBy?: IOrderBy;
  cursor: DocumentSnapshot | undefined;
}) => {
  const { cursor, status, time, unClaimOnly, filterOrderBy } = params;

  const [count, setCount] = useState(0);
  const [data, setData] = useState<QueryDocumentSnapshot<any, DocumentData>[]>(
    []
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  const { address: internalAddress } = useAccount();
  const [searchParams] = useSearchParams();

  const extendAddress = searchParams.get("address");

  const address = extendAddress || internalAddress;

  useEffect(() => {
    const f = async () => {
      if (!address) return;

      try {
        setLoading(true);

        const constraints = getConstraints({ status, time, unClaimOnly, filterOrderBy });

        if (cursor) {
          constraints.push(startAfter(cursor));
        }

        const _query: Query<DocumentData> = query(
          userWagerCollRef(address),
          ...constraints,
          limit(PAGINATION_SIZE)
        );

        const result: QuerySnapshot<any> = await getDocs(_query);

        if (isMobile) {
          // scroll infinity for mobile
          if (cursor === undefined) {
            setData(result.docs);
          } else {
            setData((data) => data.concat(result.docs));
          }
        } else {
          setData(result.docs);
        }
      } catch (error) {
        console.log("🚀 ~ file: useWager.ts:171 ~ f ~ error:", error);
      } finally {
        setLoading(false);
      }
    };

    f();
  }, [address, cursor, status, time, unClaimOnly, filterOrderBy]);

  useEffect(() => {
    const f = async () => {
      if (!address) return;
      try {
        const constraints = getConstraints({ status, time, unClaimOnly, filterOrderBy });

        const snapshot = await getCountFromServer(
          query(userWagerCollRef(address), ...constraints)
        );
        setCount(snapshot.data()?.count);
      } catch (error) {
        console.log("🚀 ~ file: useWager.ts:112 ~ f ~ error:", error);
      }
    };
    f();
  }, [address, status, time, unClaimOnly, filterOrderBy]);

  return { count, data, isLoading, setData };
};

export const useWager = () => {
  // const address = "0xc615e3178a63BA2d720eb245f7872a129495C27C"; // TODO:
  const [wagerFilter, setWagerFilter] = useState<number>(1);
  const [timeFilter, setTimeFilter] = useState<string>(ITimeFilterType.allTime);
  const [unClaimOnly, setUnClaimOnly] = useState<boolean>(false);
  const [filterOrderBy, setFilterOrderBy] = useState<IOrderBy>(IOrderBy.DESC);

  // ===================== pagination =====================
  const [page, setPage] = useState<number>(0);
  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());
  const {
    count,
    data: rawData,
    isLoading: loading,
    setData: setRawData,
  } = useFetchWager({
    status: wagerFilter,
    time: timeFilter,
    unClaimOnly,
    cursor: cursors.current.get(page),
    filterOrderBy,
  });

  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        if (!rawData) return page;

        // first, we save the last document as page's cursor
        cursors.current.set(page + 1, rawData[rawData.length - 1]);

        // then we update the state with the next page's number
        return nextPage;
      });
    },
    [rawData]
  );

  const wagers = useMemo(() => {
    const _data = rawData?.map((doc: any) => parseDoc(doc)) ?? [];
    const eventIds = getEventIdsFromWagers(_data);
    eventProxy.loadByIds(eventIds);
    return _data;
  }, [rawData]);
  // ==================== end: pagination =====================

  // dev: previous version, no longer used
  // const buildQuery = (
  //   filters: filters,
  //   isNext: boolean,
  //   isPrev: boolean,
  //   initWager: boolean
  // ) => {
  //   const { status, time, unClaimOnly } = filters;
  //   let _query: Query<DocumentData> = query(
  //     userWagerCollRef(address),
  //     limit(PAGINATION_SIZE + 1)
  //   );
  //   let _cursor = isNext
  //     ? startAfter(nextCursorPointer)
  //     : isPrev
  //     ? endBefore(prevCursorPointer)
  //     : startAfter(null);
  //   const _limit = isPrev
  //     ? limitToLast(PAGINATION_SIZE + 1)
  //     : limit(PAGINATION_SIZE + 1);

  //   const now = new Date();
  //   switch (time) {
  //     case ITimeFilterType.today:
  //       var d = moment().subtract(24, "hours").format("YYYY,MM-DD HH:mm:ss");
  //       if (!unClaimOnly) {
  //         if (status === 4) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(d)),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(d)),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(d)),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(d)),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         }
  //       } else {
  //         _query = initWager
  //           ? query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where("created_at", ">=", mmFormatUTC(d)),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               limit(PAGINATION_SIZE + 1)
  //             )
  //           : query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where("created_at", ">=", mmFormatUTC(d)),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               _cursor,
  //               _limit
  //             );
  //       }
  //       break;

  //     case ITimeFilterType.yesterday:
  //       const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  //       const y_date = new Date(yesterday);
  //       y_date.setUTCHours(0, 0, 0, 0);
  //       if (!unClaimOnly) {
  //         if (status === 0) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else if (status === 4) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         }
  //       } else {
  //         _query = initWager
  //           ? query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               limit(PAGINATION_SIZE + 1)
  //             )
  //           : query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where("created_at", ">=", mmFormatUTC(y_date.toUTCString())),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               _cursor,
  //               _limit
  //             );
  //       }
  //       break;

  //     case ITimeFilterType.thisWeek:
  //       const lastWeek = moment().subtract(7, "days").format("YYYY-MM-DD");
  //       const w_date = new Date(lastWeek);
  //       w_date.setUTCHours(0, 0, 0, 0);
  //       if (!unClaimOnly) {
  //         if (status === 0) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else if (status === 4) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         }
  //       } else {
  //         _query = initWager
  //           ? query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               limit(PAGINATION_SIZE + 1)
  //             )
  //           : query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where("created_at", ">=", mmFormatUTC(w_date.toUTCString())),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               _cursor,
  //               _limit
  //             );
  //       }
  //       break;

  //     case ITimeFilterType.thisMonth:
  //       const lastMonth = moment().subtract(30, "days").format("YYYY-MM-DD");
  //       const lastMonth_date = new Date(lastMonth);
  //       if (!unClaimOnly) {
  //         if (status === 0) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where(
  //                   "created_at",
  //                   ">=",
  //                   mmFormatUTC(lastMonth_date.toUTCString())
  //                 ),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where(
  //                   "created_at",
  //                   ">=",
  //                   mmFormatUTC(lastMonth_date.toUTCString())
  //                 ),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else if (status === 4) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where(
  //                   "created_at",
  //                   ">=",
  //                   mmFormatUTC(lastMonth_date.toUTCString())
  //                 ),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where(
  //                   "created_at",
  //                   ">=",
  //                   mmFormatUTC(lastMonth_date.toUTCString())
  //                 ),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 orderBy("isRedeemed", "asc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where(
  //                   "created_at",
  //                   ">=",
  //                   mmFormatUTC(lastMonth_date.toUTCString())
  //                 ),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 where(
  //                   "created_at",
  //                   ">=",
  //                   mmFormatUTC(lastMonth_date.toUTCString())
  //                 ),
  //                 where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         }
  //       } else {
  //         _query = initWager
  //           ? query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where(
  //                 "created_at",
  //                 ">=",
  //                 mmFormatUTC(lastMonth_date.toUTCString())
  //               ),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               limit(PAGINATION_SIZE + 1)
  //             )
  //           : query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("isRedeemed", "==", false),
  //               where(
  //                 "created_at",
  //                 ">=",
  //                 mmFormatUTC(lastMonth_date.toUTCString())
  //               ),
  //               where("created_at", "<=", mmFormatUTC(now.toUTCString())),
  //               orderBy("created_at", "desc"),
  //               _cursor,
  //               _limit
  //             );
  //       }
  //       break;
  //     default:
  //       if (!unClaimOnly) {
  //         if (status === 0) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else if (status === 4) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else if (status === 1) {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         } else {
  //           _query = initWager
  //             ? query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 orderBy("created_at", "desc"),
  //                 limit(PAGINATION_SIZE + 1)
  //               )
  //             : query(
  //                 userWagerCollRef(address),
  //                 where("status", "==", status),
  //                 orderBy("created_at", "desc"),
  //                 _cursor,
  //                 _limit
  //               );
  //         }
  //       } else {
  //         _query = initWager
  //           ? query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("amount", ">", 0),
  //               orderBy("amount", "desc"),
  //               orderBy("created_at", "desc"),
  //               limit(PAGINATION_SIZE + 1)
  //             )
  //           : query(
  //               userWagerCollRef(address),
  //               where("status", "==", 4),
  //               where("amount", ">", 0),
  //               orderBy("amount", "desc"),
  //               orderBy("created_at", "desc"),
  //               _cursor,
  //               _limit
  //             );
  //       }
  //       break;
  //   }

  //   return _query;
  // };

  // function checkWagerData(wagerData: any): wagerData is WagerDocData {
  //   return (
  //     "wager_id" in wagerData &&
  //     "user_id" in wagerData &&
  //     "txhash" in wagerData &&
  //     "status" in wagerData &&
  //     "outcome_ids" in wagerData &&
  //     "bet_amount" in wagerData &&
  //     "amount" in wagerData &&
  //     wagerData.txhash.length &&
  //     wagerData.outcome_ids.length > 0 &&
  //     wagerData.bet_amount
  //   );
  // }

  // dev: previous version, no longer used
  // const getlastWagers: (
  //   filters: filters,
  //   retry: number
  // ) => Promise<UserWagers[]> = async (filters, retry) => {
  //   let _query: Query = buildQuery(filters, false, false, true);
  //   let data: UserWagers[] = [];
  //   let userWagerSnapShot: QuerySnapshot<any> = await getDocs(_query);
  //   let count = 0;
  //   setWagerDataPage(userWagerSnapShot);
  //   userWagerSnapShot.forEach((doc: any) => {
  //     const _data = doc.data();
  //     if (
  //       count <= userWagerSnapShot.docs.length - 2 ||
  //       userWagerSnapShot.docs.length !== PAGINATION_SIZE + 1
  //     ) {
  //       data.push({
  //         wagerId: doc.id,
  //         amount: _data.amount,
  //         betAmount: _data?.bet_amount,
  //         outcomeIds: _data?.outcome_ids || [],
  //         burnedAmount: _data?.redeemAmount || 0,
  //         status: _data.status,
  //         redeemable: _data?.redeemable,
  //         txhash: _data?.txhash,
  //       });
  //     }
  //     if (
  //       userWagerSnapShot.docs.length === PAGINATION_SIZE + 1 &&
  //       count === userWagerSnapShot.docs.length - 2
  //     ) {
  //       setLastVisible(false);
  //       setNextCursorPointer(doc);
  //     }
  //     count++;
  //   });

  //   if (userWagerSnapShot.docs.length !== PAGINATION_SIZE + 1) {
  //     // setLastVisible(true);
  //     setNextCursorPointer(null);
  //   }
  //   return data;
  // };
  // const getWagerDocsByQuery: (
  //   q: Query<DocumentData>,
  //   retry: number
  // ) => Promise<QuerySnapshot<any>> = async (
  //   q: Query<DocumentData>,
  //   retry: number = 1
  // ) => {
  //   const userWagerSnapShot: QuerySnapshot<any> = await getDocs(q);
  //   return userWagerSnapShot;
  // };

  // const updateWagerDataAndFetchEvents = (data: any) => {
  //   setWagers(data);
  //   /// DEV: Fetch event by ids
  //   const eventIds = getEventIdsFromWagers(data);
  //   eventProxy.loadByIds(eventIds);
  //   setLoading(false);
  //   setLoadingMore(false);
  // };

  // dev: implement later
  // useEffect(() => {
  //   const loadMoreItem = async () => {
  //     if (lastVisible) return;
  //     try {
  //       setLoadingMore(true);
  //       let data: UserWagers[] = [];
  //       const _filters: filters = {
  //         status: wagerFilter,
  //         time: timeFilter,
  //         unClaimOnly,
  //       };
  //       const q: Query<DocumentData> = buildQuery(_filters, true, false, false);
  //       const userWagerSnapShot: QuerySnapshot<any> = await getDocs(q);
  //       if (userWagerSnapShot.empty) {
  //         setLoadingMore(false);
  //         setLastVisible(true);
  //         return;
  //       }
  //       let count = 0;
  //       userWagerSnapShot.forEach((userWager) => {
  //         let _data = userWager.data();
  //         if (
  //           count <= userWagerSnapShot.docs.length - 2 ||
  //           userWagerSnapShot.docs.length !== PAGINATION_SIZE + 1
  //         ) {
  //           data.push({
  //             wagerId: userWager.id,
  //             amount: _data.amount,
  //             burnedAmount: _data?.redeemAmount || 0,
  //             outcomeIds: _data?.outcome_ids || [],
  //             redeemable: _data?.redeemable,
  //             status: _data?.status,
  //             betAmount: _data?.bet_amount,
  //             txhash: _data?.txhash,
  //           });
  //         }
  //         if (
  //           userWagerSnapShot.docs.length === PAGINATION_SIZE + 1 &&
  //           count === userWagerSnapShot.docs.length - 2
  //         ) {
  //           setNextCursorPointer(userWager);
  //         }
  //         count++;
  //       });

  //       if (!userWagerSnapShot.empty) {
  //         setPrevCursorPointer(userWagerSnapShot.docs[0]);
  //       }
  //       if (userWagerSnapShot.docs.length !== PAGINATION_SIZE + 1) {
  //         setLastVisible(true);
  //         setNextCursorPointer(null);
  //       }
  //       if (data.length) {
  //         setIsNext(false);
  //         setWagerDataPage(userWagerSnapShot);
  //         // setWagers([...wagers, ...data]);
  //         updateWagerDataAndFetchEvents([...wagers, ...data]);
  //       }
  //       if (userWagerSnapShot.docs.length === 0) {
  //         setLoadingMore(false);
  //       }
  //     } catch (error) {
  //       console.log("====================================");
  //       console.log("error", error);
  //       console.log("====================================");
  //     }
  //   };
  //   const isBottom = (el: HTMLElement) => {
  //     return el.getBoundingClientRect().bottom <= window.innerHeight;
  //   };

  //   // Load more items when user scrolls to the bottom
  //   const handleScroll = () => {
  //     const element = document.getElementById("scroll-loaded");
  //     if (element && isBottom(element) && !lastVisible && !loadingMore) {
  //       // loadMoreItem().then();
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lastVisible, loadingMore]);

  // dev: previous version, no longer used
  // dev: fetch data when click next button
  // useEffect(() => {
  //   if (!address) return;
  //   setLoading(true);
  //   const _filters: filters = {
  //     status: wagerFilter,
  //     time: timeFilter,
  //     unClaimOnly,
  //   };
  //   getlastWagers(_filters, 1).then((data: UserWagers[]) => {
  //     if (data.length > 0) {
  //       updateWagerDataAndFetchEvents(data);
  //     } else {
  //       setWagers([]);
  //       setWagersData([]);
  //       setLoading(false);
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [wagerFilter, timeFilter, unClaimOnly, address]);

  // dev: previous version, no longer used
  // dev: when click next button
  // useEffect(() => {
  //   if (isNext) {
  //     setLoading(true);
  //     let data: UserWagers[] = [];
  //     const _filters: filters = {
  //       status: wagerFilter,
  //       time: timeFilter,
  //       unClaimOnly,
  //     };
  //     const q: Query<DocumentData> = buildQuery(_filters, true, false, false);
  //     getWagerDocsByQuery(q, 1).then(
  //       (userWagerSnapShot: QuerySnapshot<any>) => {
  //         let count = 0;
  //         userWagerSnapShot.forEach((userWager) => {
  //           let _data = userWager.data();
  //           if (
  //             count <= userWagerSnapShot.docs.length - 2 ||
  //             userWagerSnapShot.docs.length !== PAGINATION_SIZE + 1
  //           ) {
  //             data.push({
  //               wagerId: userWager.id,
  //               amount: _data.amount,
  //               burnedAmount: _data?.redeemAmount || 0,
  //               outcomeIds: _data?.outcome_ids || [],
  //               redeemable: _data?.redeemable,
  //               status: _data?.status,
  //               betAmount: _data?.bet_amount,
  //               txhash: _data?.txhash,
  //             });
  //           }
  //           if (
  //             userWagerSnapShot.docs.length === PAGINATION_SIZE + 1 &&
  //             count === userWagerSnapShot.docs.length - 2
  //           ) {
  //             setNextCursorPointer(userWager);
  //           }
  //           count++;
  //         });
  //         if (!userWagerSnapShot.empty) {
  //           setPrevCursorPointer(userWagerSnapShot.docs[0]);
  //         }
  //         if (userWagerSnapShot.docs.length !== PAGINATION_SIZE + 1) {
  //           setNextCursorPointer(null);
  //         }
  //         if (data.length) {
  //           setIsNext(false);
  //           setWagerDataPage(userWagerSnapShot);
  //           updateWagerDataAndFetchEvents(data);
  //         } else {
  //           setWagers([]);
  //         }
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isNext]);

  // dev: previous version, no longer used
  // dev: when click prev button
  // useEffect(() => {
  //   if (isPrev) {
  //     setLoading(true);
  //     // const useWagerRef = userWagerCollRef(address);
  //     let data: UserWagers[] = [];
  //     const _filters: filters = {
  //       status: wagerFilter,
  //       time: timeFilter,
  //       unClaimOnly,
  //     };
  //     const q: Query<DocumentData> = buildQuery(_filters, false, true, false);
  //     getWagerDocsByQuery(q, 1).then(
  //       (userWagerSnapShot: QuerySnapshot<any>) => {
  //         let count = 0;
  //         userWagerSnapShot.forEach((userWager) => {
  //           let _data = userWager.data();
  //           if (!count) {
  //             if (userWagerSnapShot.docs.length !== PAGINATION_SIZE + 1) {
  //               data.push({
  //                 wagerId: userWager.id,
  //                 amount: _data.amount,
  //                 burnedAmount: _data?.redeemAmount || 0,
  //                 outcomeIds: _data?.outcome_ids || [],
  //                 redeemable: _data?.redeemable,
  //                 status: _data?.status,
  //                 betAmount: _data?.bet_amount,
  //                 txhash: _data?.txhash,
  //               });
  //             }
  //           } else {
  //             data.push({
  //               wagerId: userWager.id,
  //               amount: _data.amount,
  //               outcomeIds: _data?.outcome_ids || [],
  //               burnedAmount: _data?.redeemAmount || 0,
  //               redeemable: _data?.redeemable,
  //               status: _data?.status,
  //               betAmount: _data?.bet_amount,
  //               txhash: _data?.txhash,
  //             });
  //           }
  //           count++;
  //         });

  //         setNextCursorPointer(
  //           userWagerSnapShot.docs[userWagerSnapShot.docs.length - 1]
  //         );
  //         if (userWagerSnapShot.docs.length === PAGINATION_SIZE + 1) {
  //           setPrevCursorPointer(userWagerSnapShot.docs[1]);
  //         } else {
  //           setPrevCursorPointer(null);
  //         }
  //         if (data.length) {
  //           setIsPrev(false);
  //           setWagerDataPage(userWagerSnapShot);
  //           updateWagerDataAndFetchEvents(data);
  //         } else {
  //           setWagers([]);
  //         }
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isPrev]);

  // useEffect(() => {
  //   //get all events
  //   const eventIds = getEventIdsFromWagers(wagers);
  //   if (eventIds.length) {
  //     Promise.all(getEventByIds(eventIds, getEventColl, getLeagueDocRef)).then(
  //       (eventInfo) => {
  //         // let EventInfo = eventInfo.flat();
  //         // setEvents(EventInfo);
  //         setWagersData(wagers);
  //         setLoading(false);
  //         setLoadingMore(false);
  //       }
  //     );
  //   }
  // }, [wagers]);

  const handleChangeOrderBy = (value: IOrderBy) => {
    handleResetDataOnMobile();
    setPage(0);
    setFilterOrderBy(value);
  };
  const handleChangeTimeFilter = (value: string) => {
    handleResetDataOnMobile();
    setPage(0);
    setTimeFilter(value);
  };

  const handleChangeUnClaimOnly = (value: boolean) => {
    handleResetDataOnMobile();
    setPage(0);
    setUnClaimOnly(value);
  };

  const handleChangeType = (value: number) => {
    handleResetDataOnMobile();
    setPage(0);
    setWagerFilter(value);
  };

  /// dev: only mobile for infinity scroll
  const handleResetDataOnMobile = () => {
    if (isMobile) {
      setRawData([]);
    }
  };

  return {
    loading,
    wagersData: wagers,
    timeFilter,
    unClaimOnly,
    setUnClaimOnly: handleChangeUnClaimOnly,
    setTimeFilter: handleChangeTimeFilter,
    page,
    setPage,
    setWagerFilter: handleChangeType,
    setFilterOrderBy: handleChangeOrderBy,
    wagerFilter,
    count,
    onPageChanged,
  };
};
