import { proxy } from "valtio";
export enum tabFilter {
  all = "All",
  unread = "Unread",
  subscribe = "subscribe",
}
export interface NotiElement {
  id: string;
  created_at: string;
  status: string;
  type: string;
  notification: {
    content: string;
    content_html: string;
    title: string;
    id: string;
    launch_url: string;
    created_at: string;
    type: string;
  };
}
interface State {
  show: boolean;
  counter: number;
  data: Array<NotiElement>;
  filter: tabFilter;
  systemNoti: Array<NotiElement> | null;
  subscribeNoti: Array<NotiElement> | null;
}
export const notiState = proxy<State>({
  show: false,
  counter: 0,
  data: [],
  filter: tabFilter.all,
  systemNoti: null,
  subscribeNoti: null,
});
export const notitficationState = {
  notiState,
  setShow: (value: boolean) => {
    notiState.show = value;
  },
  setFilter: (value: tabFilter) => {
    notiState.filter = value;
  },
  setData: (value: Array<NotiElement>) => {
    notiState.data = value;
  },
  setCounter: (value: number) => {
    notiState.counter = value;
  },
  setRead: (id: string) => {
    const index = notiState.data.findIndex(
      (item) => item.notification.id === id
    );
    if (index > -1) {
      notiState.data[index].status = "clicked";
    }
  },
  setSystemNoti: (value: Array<NotiElement>) => {
    notiState.systemNoti = value;
  },
  setSubscribeNoti: (value: Array<NotiElement>) => {
    notiState.subscribeNoti = value;
  },
};
