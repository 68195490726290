import useCustomEffect from "hooks/useCustomEffect.hook";
import translate from "languages/translate";
import moment from "moment";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";

export interface LanguageContextProps {
  language: string;
  setLanguge: (language: string) => void;
}

export enum LanguageType {
  KR = "kr",
  EN = "en",
}

/**
 * Languge Context.
 */

export const LanguageContext = createContext<LanguageContextProps>({
  language: "en",
  setLanguge: () => {},
});

export const useLanguageContext = () => React.useContext(LanguageContext);
/**
 * Language Context Provider.
 *
 * @param value string
 * @param children ReactNode
 * @returns ReactNode
 */
export const LanguageContextProvider = ({
  value = "en",
  children,
}: {
  value?: string;
  children: React.ReactNode;
}) => {
  const [language, setLanguge] = useState(value);

  useCustomEffect(() => {
    const storeLanguage = localStorage.getItem("language") || "en";
    setLanguge(storeLanguage);
    applyLanguage(storeLanguage);
  }, []);

  /**
   * Apply language.
   *
   * @param language string
   */
  const applyLanguage = (language: string) => {
    localStorage.setItem("language", language);
  };

  /**
   * Handle language change.
   *
   * @param language string
   */
  const handleLanguageChange = (language: string) => {
    setLanguge(language);
    applyLanguage(language);
  };

  /**
   * Current context value for language.
   */
  const val = useMemo(
    () => ({
      language,
      setLanguge: handleLanguageChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );
  useEffect(() => {
    moment.locale(language || LanguageType.EN);
  }, [language]);

  return (
    <LanguageContext.Provider value={val}>
      <IntlProvider
        messages={translate[language || LanguageType.EN]}
        locale={language || LanguageType.EN}
      >
        {children}{" "}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
