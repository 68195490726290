import React, { Fragment } from "react";
import { ReferralEvent } from "./referral";
import { PlayDropEvent } from "./playdrop";
// import { useSnapshot } from "valtio";
import Promotion from "./promotion";
import { useParams } from "react-router-dom";

const CampaignDetail: React.FC = () => {
	const {id: slug } = useParams<{ id: string }>();
	console.log('====================================');
	console.log('slug', slug);
	console.log('====================================');
	const [menuNav, setMenuNav] = React.useState<string>("");
	console.log('====================================');
	console.log('slug', slug);
	console.log('====================================');
	let Child = null;
	switch (slug) {
		case "referral":
			Child = <ReferralEvent menuNav={menuNav} setMenuNav={setMenuNav} />;
			break;
		case "playdrop":
			Child = <PlayDropEvent />;
			break;
		case "mlb-early-lead-insurance":
			Child = <Promotion />;
			break;
	}
	return <Fragment>{Child}</Fragment>;
};
export default CampaignDetail;
