import { getAddressSimpleString } from "common/utils";
import { ExplorerURLs } from "contracts/config";
import {
  ZKUSD_ADDRESS_MAINNET,
  LOCKDROP_ADDRESS_MAINNET,
} from "shared/constants";

const blockExplorersUrl = ExplorerURLs[324];

const Addresses = () => {
  return (
    <div className={"w-full border-t border-base-content/10 "}>
      <div className="container py-4 lg:py-3 relative flex flex-row items-center w-full">
        <h5 className="hidden lg:inline-block font-medium opacity-70 lg:pr-3 lg:mr-3 lg:border-r border-base-content/20">
          <i className="fa-solid fa-shield-check text-success mr-2"></i>
          Official Contracts
        </h5>
        <div className="flex justify-between md:justify-start items-center font-medium w-full md:w-auto py-2 md:py-0 border-t md:border-t-0 border-base-content/10">
          <span>Lockdrop</span>
          <div className="ml-2">
            <a
              href={`${blockExplorersUrl}/address/${LOCKDROP_ADDRESS_MAINNET}#contract`}
              target="_blank"
              rel="noreferrer"
              className="font-mono text-primary border-b border-transparent hover:border-primary transition"
            >
              {/* <i className="fa-regular fa-square-arrow-up-right mr-2"></i> */}
              {getAddressSimpleString(LOCKDROP_ADDRESS_MAINNET, 4)}
              <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
            </a>
          </div>
        </div>
        <span className="text-base opacity-70 hidden md:inline-block mx-2">
          •
        </span>
        <div className="hidden lg:flex items-center flex-1 w-full">
          <div className="flex justify-between md:justify-start items-center font-medium w-full md:w-auto py-2 md:py-0 border-t md:border-t-0 border-base-content/10">
            <span>$zkUSD</span>
            <div className="ml-2">
              <button className="btn btn-xs btn-circle btn-ghost mr-1">
                <i className="fa-regular fa-copy text-xs"></i>
              </button>
              <a
                href={`${blockExplorersUrl}/address/${ZKUSD_ADDRESS_MAINNET}#contract`}
                target="_blank"
                rel="noreferrer"
                className="font-mono text-primary border-b border-transparent hover:border-primary transition"
              >
                {/* <i className="fa-regular fa-square-arrow-up-right mr-2"></i> */}
                {getAddressSimpleString(ZKUSD_ADDRESS_MAINNET, 4)}
                <i className="fa-regular fa-square-arrow-up-right ml-2"></i>
              </a>
            </div>
          </div>

          {/* <span className="text-base opacity-70 hidden md:inline-block mx-2">•</span>

					<div className="flex justify-between md:justify-start items-center font-medium w-full md:w-auto py-2 md:py-0 border-t md:border-t-0 border-base-content/10">
						<span>Lockdrop</span>
						<div className="ml-2">
							<a
								href={`#`}
								target="_blank"
								rel="noreferrer"
								className="font-mono text-primary border-b border-transparent hover:border-primary transition"
							>
								0x8D...5164
								<i className="fa-regular fa-square-arrow-up-right ml-2"></i>
							</a>
						</div>
					</div> */}

          <span className="text-base opacity-70 hidden md:inline-block mx-2">
            •
          </span>

          <div className="flex justify-between md:justify-start items-center font-medium w-full md:w-auto py-2 md:py-0 border-t md:border-t-0 border-base-content/10">
            <span>$ZKG</span>
            <span className="ml-2 mr-1 opacity-70">
              {/* <i className="fa-regular fa-square-arrow-up-right mr-2"></i> */}
              TBA after TGE
            </span>
          </div>
        </div>

        <label
          htmlFor="contracts-view--modal"
          className="btn btn-md lg:btn-ghost px-3 mt-2 md:mt-0 w-full lg:w-auto md:absolute md:top-3 md:right-4 lg:static"
        >
          <span className="tooltip" data-tip="Official Contracts">
            <span className="hidden lg:inline-block">More</span>
            <span className="lg:hidden">Official Contracts</span>
            <i className="fa-solid fa-ellipsis ml-2"></i>
          </span>
        </label>
      </div>
    </div>
  );
};

export { Addresses };
