import React, { Fragment } from "react";
import { CountDown } from "./CountDown";
// import { FaRegClock } from 'react-icons/fa';

const CountDownInfo = ({ date, type, eventType }: any) => {
  const heading =
    type == "sort" ? (
      <span className="text-base-content text-opacity-70 flex mr-2 items-baseline font-medium uppercase text-xs">
        <i className="fa-regular fa-clock mr-2 relative"></i>
        {eventType} in:
      </span>
    ) : (
      <strong className="mr-4 mb-2 md:mb-0 uppercase text-xs flex items-baseline">
        {/* <FaRegClock className="mr-2 relative -bottom-0.5" /> */}
        <i className="fa-regular fa-clock mr-2 relative"></i>
        {eventType} in:
      </strong>
    );
  return (
    <Fragment>
      {heading}
      <CountDown date={date} type={type} />
    </Fragment>
  );
};

export default CountDownInfo;
