import { MainLayoutPublib } from "components/layouts/MainLayoutPublib";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import RefCode from "../page";

const routes: AppRouteType[] = [
  {
    path: slugs.refcode,
    component: RefCode,
    container: MainLayoutPublib,
    isAuthNeeded: true,
  },
];

export default routes;
