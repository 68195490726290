import {
  ACCESS_TOKEN,
  REF_CODE,
  deleteItem,
  getItem,
  saveItem,
  LOTTERY_TOKEN,
} from "local-storage";
import { createContext, useContext, useState } from "react";
import slugs from "navigation/slugs";
import { myProfileProxy } from "proxy-state/myProfileProxy";

const AuthContext = createContext<any>(null);

export function AuthProvider({ children }: any) {
  const accessToken = getItem(ACCESS_TOKEN) || "";
  const ref_code = getItem(REF_CODE) || "";
  const [token, setToken] = useState(accessToken || "");
  const [refCode, setRefCode] = useState(ref_code || "");
  const updateToken = (newToken: string) => {
    setToken(newToken);
    saveItem(ACCESS_TOKEN, newToken);
  };
  const updateRefCode = (newRefCode: string) => {
    setRefCode(newRefCode);
    saveItem(REF_CODE, newRefCode);
  };
  const handleLogout = () => {
    try {
      logOut();
      setToken("");
      deleteItem(ACCESS_TOKEN);
      deleteItem(LOTTERY_TOKEN);
    } catch (error) {
      console.log("error ===> auth logout", error);
    }
  };
  const logOut = async () => {
    // await SignOutWithFirebase(auth);
    deleteItem(ACCESS_TOKEN);
    window.location.href = slugs.home;

    myProfileProxy.unsubscribeDb();
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        refCode,
        updateToken,
        updateRefCode,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
