import { Img } from "components/commons/Img";
import { ClientType, DepositContext } from "contexts/depositContext";
import { SMART_DEPOSIT } from "helpers/constants";
import React, { ChangeEvent, Fragment, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export const SmartDepositOption = ({
  setDepositPage,
  setDepositOptionPage,
}: {
  setDepositPage: React.Dispatch<React.SetStateAction<boolean>>;
  setDepositOptionPage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { deposit, setDeposit } = useContext(DepositContext);

  return (
    <>
      <div
        className={`sticky  top-0 left-0 right-0 px-4 md:px-6 py-5 z-50 bg-base-200/80 backdrop-blur text-center`}
      >
        <button
          className="btn btn-sm rounded-full btn-ghost mr-2 absolute left-4 top-4 gap-2 !text-primary font-normal text-base"
          onClick={() => {
            setDepositOptionPage(false);
          }}
        >
          <i className="fa-solid fa-angle-left text-xl"></i>
          Profile
        </button>
        <div className="inline-flex text-base font-semibold">
          <h3>Smart Deposit</h3>
        </div>
      </div>

      <div className={`p-4 md:p-6 !pb-8 gap-8 flex flex-col fadein`}>
        <div className="flex flex-col p-3">
          <div className="flex flex-col justify-center items-center text-center px-1 gap-4">
            <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center">
              <i className="fa-duotone fa-coins icon-primary text-3xl"></i>
            </div>
            <div className="text-center">
              <h4 className="text-2xl font-semibold">Deposit zkUSD</h4>

              <div className="leading-relaxed mt-2 text-base-content/50">
                Get zkUSD on zkSync with ETH on Ethereum
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="text-xs uppercase tracking-wide opacity-50 ml-3">
            Deposit Options
          </div>
          <div className="flex flex-col bg-base-100 px-3 rounded-box">
            {SMART_DEPOSIT.map((el, idx) => (
              <Fragment key={idx}>
                <button
                  key={idx}
                  onClick={() => {
                    setDeposit(el.value);
                    setDepositPage(true);
                    setDepositOptionPage(false);
                  }}
                  className="flex items-center justify-between py-4 px-2 text-left"
                >
                  <span className="flex items-start text-base-content">
                    <div className="flex flex-col gap-1">
                      <span className="flex items-center gap-2">
                        {el.imgUrl.map((img, index) => (
                          <>
                            {index > 0 && (
                              <i key={1} className="fa-regular fa-plus opacity-50" />
                            )}
                            <Img
                              addPrefix
                              key={2}
                              alt={el.label}
                              className={`h-5 md:h-6`}
                              src={img}
                            />
                          </>
                        ))}
                      </span>
                    </div>
                  </span>
                  <span className="flex items-center">
                    <span className="badge text-base-content/50 text-sm mr-3 w-20 !capitalize">
                      Fee: ~${el.fee}
                    </span>
                    <i
                      className="fa-solid fa-angle-right"
                      aria-hidden="true"
                    ></i>
                  </span>
                </button>

                {idx >= 0 && idx < SMART_DEPOSIT.length - 1 && (
                  <span
                    className="ml-2 mr-2 h-[1px] bg-base-content/10"
                    role="separator"
                  ></span>
                )}
              </Fragment>
            ))}

            {/* <button
					onClick={() => {
						setDpOption(DPOptions.ACROSS);
					}}
					className="flex items-center justify-between py-4 px-2 text-left"
				>
					<span className="flex items-start text-base-content">
						<div className="flex flex-col gap-1">
							<span className="flex items-center gap-1">
								<AcrossSvg className="h-6" width={24} height={24} />
								<span className="text-sm font-semibold">Across Protocol</span>
							</span>
						</div>
					</span>
					<span className="flex items-center">
						<span className="badge text-base-content/50 text-sm mr-3 w-20 !capitalize">Fee: ~$8</span>
						<i className="fa-solid fa-angle-right" aria-hidden="true"></i>
					</span>
				</button> */}
          </div>

          <div className="flex flex-col gap-2 rounded-box bg-warning/10 py-4 px-5 text-base mb-4">
            <span className="text-warning font-semibold">How it works?</span>
            <span className="leading-relaxed text-sm">
              ETH on Ethereum will be automatically bridged and swapped to zkUSD
              on zkSync, credited to your balance within 1-3 minutes.{" "}
              <Link
                to="https://docs.goal3.xyz/sportsbook/smart-deposit "
                target={"_blank"}
                rel="noreferrer"
                className="text-primary"
              >
                Learn more{" "}
                <i className="fa-regular fa-square-arrow-up-right"></i>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
