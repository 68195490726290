import { CHAINS, CONTRACTS } from "config";
import { Contract, Signer, ethers } from "ethers";
import AaFactoryABI from "contracts/abi/AaFactory.abi.json";
import AccountABI from "contracts/abi/Account.abi.json";
import { CHAIN_ID } from "constant";
import { utils } from "zksync-web3";

const AbiCoder = new ethers.utils.AbiCoder();
const salt = ethers.constants.HashZero;

class EOA {
  private chainId: number;
  private aaBytecodeHash: string = "";

  constructor(chainId: number) {
    this.chainId = chainId;
    this.initialize();
  }

  public async initialize() {
    this.aaBytecodeHash = await this.getAaBytecodeHash();
  }

  private get aaFactoryAddress() {
    return CONTRACTS[this.chainId].AAFactory;
  }

  private get chain() {
    return CHAINS[this.chainId];
  }

  private get provider() {
    return new ethers.providers.StaticJsonRpcProvider(this.chain.url);
  }

  private get aaFactoryContract() {
    return new Contract(this.aaFactoryAddress, AaFactoryABI, this.provider);
  }

  private async getAaBytecodeHash() {
    return await this.aaFactoryContract.aaBytecodeHash();
  }

  private create2Address(address: string) {
    return utils.create2Address(
      this.aaFactoryAddress,
      this.aaBytecodeHash,
      salt,
      AbiCoder.encode(["address"], [address])
    );
  }

  public async deploySmartAccount(signer: Signer, address: string) {
    try {
      const txn = await this.aaFactoryContract
        .connect(signer)
        .deployAccount(salt, address);
      await txn.wait(1);
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  private getAccountContract(smartAddress: string, signer?: Signer) {
    return new Contract(
      smartAddress,
      AccountABI,
      signer ? signer : this.provider
    );
  }

  private async getOwner(smartAddress: string) {
    try {
      return await this.getAccountContract(smartAddress).getOwner();
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  private async getPubkey(smartAddress: string) {
    try {
      return await this.getAccountContract(smartAddress).pubKey();
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  public async checkExist(address: string) {
    if (!this.aaBytecodeHash) {
      await this.initialize();
    }

    const smartAddress = this.create2Address(address);
    try {
      const owner = await this.getOwner(smartAddress);
      if (owner.toLowerCase() === address.toLowerCase()) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public async checkApproved(address: string) {
    if (!this.aaBytecodeHash) {
      await this.initialize();
    }

    let exist = false;
    const smartAddress = this.create2Address(address);
    try {
      const owner = await this.getOwner(smartAddress);
      if (owner) {
        exist = true;
      }
      // DEV: Tempo skip below code for exist smart account
      // if (owner.toLowerCase() === address.toLowerCase()) {
      //   exist = true;
      // } else {
      //   exist = false;
      // }
    } catch (error) {
      exist = false;
    }
    if (!exist) return false;
    const pubKey = await this.getPubkey(smartAddress);
    if (pubKey !== ethers.constants.AddressZero) {
      return true;
    }
    return false;
  }

  public async getAccountContractFromAddress(address: string, signer?: Signer) {
    const smartAddress = this.create2Address(address);
    return this.getAccountContract(smartAddress, signer);
  }
}

const eoa = new EOA(CHAIN_ID);

eoa.initialize();

export { eoa };
