import { ClientType } from "contexts/depositContext";
import { LanguageType } from "contexts/languageContext";
import slugs from "navigation/slugs";

export const navigations = [
  {
    name: "Lobby",
    icon: "fa-duotone fa-home",
    url: slugs.home,
    current: true,
    soon: false,
  },
  {
    name: "My Bets",
    icon: "fa-duotone fa-user",
    url: slugs.myBets,
    current: false,
    openCounter: 0,
    soon: false,
  },
];

export const tabs = [
  { name: "Videos" },
  { name: "Holdings" },
  { name: "Perks" },
];

export const ContractAddress = {
  300: {
    zkUSD: process.env.REACT_ZKUSD_CONTRACT as string,
    Wager: process.env.REACT_WAGER_CONTRACT as string,
    Bookie: process.env.REACT_BOOKIE_CONTRACT as string,
    BookMaker: process.env.REACT_BOOKMAKER_CONTRACT_ADDRESS as string,
    BookMakerV2: process.env.REACT_BOOKMAKERV2_CONTRACT_ADDRESS_V2 as string,
    EventManager: process.env.REACT_EVENTMANAGER_CONTRACT_ADDRESS_V2 as string,
    PaymasterBookmaker: process.env.REACT_PAYMASTER_CONTRACT_ADDRESS as string,
    PaymasterRedeem: process.env
      .REACT_PAYMASTER_REDEEM_CONTRACT_ADDRESS as string,
  },
  324: {
    zkUSD: process.env.REACT_ZKUSD_CONTRACT as string,
    Wager: process.env.REACT_WAGER_CONTRACT as string,
    Bookie: process.env.REACT_BOOKIE_CONTRACT as string,
    BookMaker: process.env.REACT_BOOKMAKER_CONTRACT_ADDRESS as string,
    BookMakerV2: process.env.REACT_BOOKMAKERV2_CONTRACT_ADDRESS_V2 as string,
    EventManager: process.env.REACT_EVENTMANAGER_CONTRACT_ADDRESS_V2 as string,
    PaymasterBookmaker: process.env.REACT_PAYMASTER_CONTRACT_ADDRESS as string,
    PaymasterRedeem: process.env
      .REACT_PAYMASTER_REDEEM_CONTRACT_ADDRESS as string,
  },
  84531: {
    Wager: process.env.REACT_WAGER_CONTRACT as string,
    Bookie: process.env.REACT_BOOKIE_CONTRACT as string,
    BookMakerV2: process.env.REACT_BOOKMAKERV2_CONTRACT_ADDRESS_V2 as string,
    EventManager: process.env.REACT_EVENTMANAGER_CONTRACT_ADDRESS_V2 as string,
  },
  8453: {
    Wager: process.env.REACT_WAGER_CONTRACT as string,
    Bookie: process.env.REACT_BOOKIE_CONTRACT as string,
    BookMakerV2: process.env.REACT_BOOKMAKERV2_CONTRACT_ADDRESS_V2 as string,
    EventManager: process.env.REACT_EVENTMANAGER_CONTRACT_ADDRESS_V2 as string,
  },
};

export const ChainConfig: { [key: string]: { name: string; url: string } } = {
  300: {
    name: "zksync Era Testnet",
    url: "wss://testnet.era.zksync.dev/ws",
  },
  324: {
    name: "zksync Era Mainnet",
    url: "wss://mainnet.era.zksync.io/ws",
  },

  84531: {
    name: "Base goeril testnet",
    url: "wss://base-goerli.publicnode.com/ws",
  },
  8453: {
    name: "Base",
    url: "wss://base.publicnode.com	",
  },
};

export const LANGUAGES: { value: LanguageType; label: string }[] = [
  { value: LanguageType.KR, label: "language.kr" },
  { value: LanguageType.EN, label: "language.en" },
];

export const SMART_DEPOSIT: {
  value: ClientType;
  label: string;
  imgUrl: string[];
  fee: number;
  feeDetails: string;
}[] = [
  {
    value: ClientType.ORBITER_SYNCSWAP,
    label: "Orbiter + SyncSwap",
    imgUrl: ["/logo/orbiter-logo.svg", "/logo/syncswap-logo.png"],
    fee: 8,
    feeDetails: "~$8 (gwei ~40)",
  },
  {
    value: ClientType.LAYERZERO,
    label: "LayerZero + SyncSwap",
    imgUrl: ["/logo/layer-zero.png", "/logo/syncswap-logo.png"],
    fee: 30,
    feeDetails: "~$30 (gwei ~40)",
  },
];
export const moreNavigation = [
  // { name: "Events", icon: "fa-duotone fa-party-horn", href: slugs.campaign, isNew: false },
];

export const dummyData = [];
