import MetamaskPage from "components/commons/LandingPage/Metamask";

/// Only for metamask verify
function Metamask() {
  return (
    <>
      <MetamaskPage />
    </>
  );
}

export default Metamask;
