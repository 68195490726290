import { ZKUSD_ADDRESS_MAINNET } from "shared/constants";
import { config } from "../utils/firebase/config";
interface contractGroup {
  [key: number]: {
    [key: string]: string;
  };
}
const ContractAddress: contractGroup = {
  84531: {
    Wager: process.env.REACT_APP_SMC_SPORTBOOK_WAGER as string,
    App: process.env.REACT_APP_SMC_BOOKIE as string,
    BookMakerV2: process.env.REACT_APP_SMC_SPORTBOOK_BOOKMAKER_V2 as string,
    EventManager: process.env.REACT_APP_SMC_SPORTBOOK_EVENTMANEGER as string,
  },
  8453: {
    Wager: process.env.REACT_APP_SMC_SPORTBOOK_WAGER as string,
    App: process.env.REACT_APP_SMC_BOOKIE as string,
    BookMakerV2: process.env.REACT_APP_SMC_SPORTBOOK_BOOKMAKER_V2 as string,
    EventManager: process.env.REACT_APP_SMC_SPORTBOOK_EVENTMANEGER as string,
  },
  300: {
    zkUSD: process.env.REACT_APP_SMC_ZKUSD as string,
    Wager: process.env.REACT_APP_SMC_SPORTBOOK_WAGER as string,
    App: "0x05eeF3763Ccbc6EB14eF7fCc6eB34b1ab108ef62",
    Platform: "0xe2006CA30B5D3A95BE5A2520AFD8a3b7871c32fd",
    BookMaker: process.env.REACT_APP_SMC_CONTRACT_ADDRESS as string,
    BookMakerV2: process.env.REACT_APP_SMC_SPORTBOOK_BOOKMAKER_V2 as string,
    EventManager: process.env.REACT_APP_SMC_SPORTBOOK_EVENTMANEGER as string,
    Lockdrop: process.env.REACT_APP_SMC_LOCKDROP_ADDRESS as string,
    PaymasterBookmaker: process.env
      .REACT_APP_SMC_PAYMASTER_CONTRACT_ADDRESS as string,
    PaymasterRedeem: process.env
      .REACT_APP_SMC_PAYMASTER_REDEEM_CONTRACT_ADDRESS as string,
    USDC: "0x0F5667E23145e745c72b5FE26ebb43904C7577b7",
  },
  324: {
    zkUSD: ZKUSD_ADDRESS_MAINNET,
    Wager: process.env.REACT_APP_SMC_SPORTBOOK_WAGER as string,
    BookMaker: process.env.REACT_APP_SMC_CONTRACT_ADDRESS as string,
    BookMakerV2: process.env.REACT_APP_SMC_SPORTBOOK_BOOKMAKER_V2 as string,
    EventManager: process.env.REACT_APP_SMC_SPORTBOOK_EVENTMANEGER as string,
    Lockdrop: process.env.REACT_APP_SMC_LOCKDROP_ADDRESS as string,
    PaymasterBookmaker: process.env
      .REACT_APP_SMC_PAYMASTER_CONTRACT_ADDRESS as string,
    PaymasterRedeem: process.env
      .REACT_APP_SMC_PAYMASTER_REDEEM_CONTRACT_ADDRESS as string,
    USDC: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
  },
};
const DataChain: contractGroup = {
  300: {
    NEXT_PUBLIC_CHAIN_ID_HEX: "0X118",
    NEXT_PUBLIC_CHAIN_CURENCY_NAME: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_SYMBOL: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_DECIMALS: "18",
    NEXT_PUBLIC_CHAIN_NAME: "zksync - Testnet",
    NEXT_PUBLIC_RPC_URL: "https://sepolia.era.zksync.dev",
    NEXT_PUBLIC_NETWORK_NAME: "zksync - Testnet",
    NEXT_PUBLIC_LINK_EXPLORER_SCAN: "https://zksync2-testnet.zkscan.io/tx/",
    NEXT_PUBLIC_LINK_EXPLORER_NAME: "View on Explorer",
  },
  324: {
    NEXT_PUBLIC_CHAIN_ID_HEX: "0X144",
    NEXT_PUBLIC_CHAIN_CURENCY_NAME: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_SYMBOL: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_DECIMALS: "18",
    NEXT_PUBLIC_CHAIN_NAME: "zksync era",
    NEXT_PUBLIC_RPC_URL: "https://mainnet.era.zksync.io",
    NEXT_PUBLIC_NETWORK_NAME: "zksync era",
    NEXT_PUBLIC_LINK_EXPLORER_SCAN: "https://explorer.zksync.io/tx/",
    NEXT_PUBLIC_LINK_EXPLORER_NAME: "View on zksync Explorer",
  },
  84531: {
    NEXT_PUBLIC_CHAIN_ID_HEX: "0X14A33",
    NEXT_PUBLIC_CHAIN_CURENCY_NAME: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_SYMBOL: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_DECIMALS: "18",
    NEXT_PUBLIC_CHAIN_NAME: "Base Goerli",
    NEXT_PUBLIC_RPC_URL: "https://goerli.base.org",
    NEXT_PUBLIC_NETWORK_NAME: "Base Goerli",
    NEXT_PUBLIC_LINK_EXPLORER_SCAN: "https://goerli.basescan.org/tx/",
    NEXT_PUBLIC_LINK_EXPLORER_NAME: "View on Base Goerli Explorer",
  },
  8453: {
    NEXT_PUBLIC_CHAIN_ID_HEX: "0X2105",
    NEXT_PUBLIC_CHAIN_CURENCY_NAME: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_SYMBOL: "ETH",
    NEXT_PUBLIC_CHAIN_CURENCY_DECIMALS: "18",
    NEXT_PUBLIC_CHAIN_NAME: "Base",
    NEXT_PUBLIC_RPC_URL: "https://base.drpc.org",
    NEXT_PUBLIC_NETWORK_NAME: "Base Mainnet",
    NEXT_PUBLIC_LINK_EXPLORER_SCAN: "https://basescan.org/tx/",
    NEXT_PUBLIC_LINK_EXPLORER_NAME: "View on Base Explorer",
  },
};
const ExplorerURLs: { [key: number]: string } = {
  420: "https://goerli-optimism.etherscan.io",
  300: "https://zksync2-testnet.zkscan.io",
  324: "https://explorer.zksync.io",
  10: "https://optimistic.etherscan.io",
  84531: "https://goerli.basescan.org",
  8453: "https://basescan.org",
};
interface TokenType {
  [key: number]: Array<any>;
}
const AcceptedTokens: TokenType = {
  300: [
    // { 'symbol': 'zkUSD', 'address':'0x5955584a96fD51a1D2E2430Ad88EE9C722ACF604', 'decimals': 18, balance: 0 },
    {
      symbol: "zkUSD",
      address: process.env.REACT_APP_SMC_ZKUSD as string,
      decimals: 6,
      balance: 0,
    },
    // { 'symbol': 'LINK', 'address': '0x40609141Db628BeEE3BfAB8034Fc2D8278D0Cc78', 'decimals': 18, balance: 0  },
  ],
  324: [
    {
      symbol: "USDC",
      address: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
      decimals: 6,
      balance: 0,
    },
    {
      symbol: "zkUSD",
      address: ZKUSD_ADDRESS_MAINNET,
      decimals: 6,
      balance: 0,
    },
  ],
};
const siteName =
  (process.env.REACT_APP_NEXT_PUBLIC_SITE_NAME as string) || "Goal3Sportbook";
const firebase_config = config;
export {
  AcceptedTokens,
  ContractAddress,
  ExplorerURLs,
  DataChain,
  siteName,
  firebase_config,
};
