import { useEffect, useState } from "react";
import { getDoc } from "firebase/firestore";
import { getItem } from "local-storage";
import { parseAddressFromToken } from "utils/index";
import { userDocRef } from "utils/firebase/firebaseDBRef";
import { auth } from "utils/firebase/config2";
import { SignInWithFirebase } from "utils/firebase/firebaseAuth";

export interface UserInfo {
  userName: string;
  shortCode: string;
  email?: string;
  picture?: string;
}
export const useAccount = () => {
  const [user, setUser] = useState<string>();
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const accessToken = getItem("access_token");

  useEffect(() => {
    if (accessToken) {
      const user = parseAddressFromToken(accessToken);
      if (user) {
        if(!auth?.currentUser) SignInWithFirebase(auth);
        setUser(parseAddressFromToken(accessToken));
        const userRef = userDocRef(user);
        getDoc(userRef).then((userDoc) => {
          const data: any = userDoc.data();
          setUserInfo({
            userName: data?.user_name,
            shortCode: data?.short_code,
            email: data?.email,
            picture: data?.picture,
          });
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return {
    address: user,
    userInfo,
  };
};
