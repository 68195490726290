import React from "react";
import { Responsive as Screen } from "components/commons/Responsive";
import {
  ConvertString2BN,
  currencyFormat,
  floatFormat,
  formatBigNumber,
} from "common/utils";
import moment from "moment";
import { useBetStats } from "hooks/useBetStats";
import { ethers } from "ethers";
import { ExplorerURLs } from "contracts/config";
import { DECIMALS } from "functions/betUtils";
import { useSnapshot } from "valtio";
import { userWagerProxy } from "../../../proxy-state/userWagerProxy";
import { myProfileProxy } from "../../../proxy-state/myProfileProxy";
import { CHAIN_ID } from "constant";

const Summary = (props: any) => {
  const { handleClaimed, claimLoading, networkFee, minAmountFreeGas } = props;
  const [showMore, setShowMore] = React.useState(true);
  const { loading, betStats } = useBetStats();
  const { data: userWager } = useSnapshot(userWagerProxy);
  const { profile } = useSnapshot(myProfileProxy);
  const user = profile;
  const wagerIdClaim = userWager?.filter(
    (wager) =>
      (wager?.status === 4 || wager?.status === 5) && Number(wager.amount) > 0
  );
    
  const _totalCanClaim: number = wagerIdClaim?.reduce(
    (a: number, b) => a + Number(b?.redeemable || b?.amount),
    0
  );
  const totalCanClaim = Number(
    formatBigNumber(ConvertString2BN(_totalCanClaim || 0), true)
  );
  const blockUrl = ExplorerURLs[CHAIN_ID];
  const { wagerHighEst, betLastTime, totalWonBet, totalBet } = betStats;
  const latestBet = moment(betLastTime?.transactionTime).fromNow();
  const isDisabled =
    claimLoading ||
    !wagerIdClaim?.length ||
    totalCanClaim < Number(minAmountFreeGas);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 border border-base-content/10 rounded-box overflow-hidden">
        <div className="px-6 py-4 bg-success text-success-content rounded-box">
          <div className="flex justify-between items-center font-medium">
            <div className="flex flex-col">
              <span className="text-xs mb-2 uppercase tracking-wide font-medium flex items-center">
                CLAIMABLE WIN
              </span>
              <span className="flex items-center">
                <strong className="text-xl">
                  {floatFormat(Number(totalCanClaim), 2)}
                </strong>
              </span>
            </div>
            <button
              className={`btn btn-md -mb-4 relative ${
                isDisabled
                  ? "cursor-not-allowed"
                  : ""
              }${!wagerIdClaim?.length || loading ? " btn-disabled" : ""}`}
              disabled={
                isDisabled
              }
              onClick={() =>
                !isDisabled
                  ? handleClaimed(
                      wagerIdClaim,
                      totalCanClaim >= minAmountFreeGas ? 0 : networkFee
                    ).then()
                  : {}
              }
            >
              {claimLoading && (
                <svg
                  className="w-5 h-5 mr-3 -ml-1 text-base-content animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Claim
              {/* {totalCanClaim > 0 && totalCanClaim >= minAmountFreeGas && (
                <span className="absolute top-[-1px] right-[-1px] bg-success text-success-content text-[8px] uppercase font-medium px-1 rounded-bl-box rounded-tr-box ml-2">
                  <i className="fa-solid fa-gas-pump-slash"></i>
                </span>
              )} */}
            </button>
          </div>
          {totalCanClaim >= 0 && totalCanClaim < minAmountFreeGas && (
            <div className="inline-flex items-center text-xs bg-base-content/80 px-2 py-1 mt-1 rounded-box">
              <span className="flex items-center text-xs font-semibold">
                <i className="fa-solid fa-bolt mr-1"></i>Min claim:{" "}
                {minAmountFreeGas} zkUSD
              </span>
              <span
                className="ml-2 tooltip cursor-pointer opacity-70 hover:text-primary hover:opacity-100"
                data-tip={`Claim Gasless with ${minAmountFreeGas}+ zkUSD! 😉`}
              >
                <i className="fa-solid fa-circle-info"></i>
              </span>
            </div>
          )}
        </div>

        <Screen upto="sm">
          <div
            className={`lg:col-span-2 grid grid-cols-1 divide-y divide-base-content/10 overflow-hidden md:grid-cols-3 md:divide-x md:divide-y-0 ${
              showMore ? "hidden" : ""
            }`}
          >
            <div className="px-6 py-4">
              <div className="flex justify-between items-baseline">
                <dt className="text-xs mb-2 uppercase tracking-wide font-medium opacity-70">
                  Total Bets
                </dt>
              </div>
              <dd className="flex items-start justify-between md:flex">
                <div className="flex flex-1 items-center text-xl font-semibold">
                  <span>{totalBet || 0}</span>
                </div>
                <div className="flex flex-col">
                  <i className="fa-duotone fa-bolt icon-primary text-2xl text-base-content/70"></i>
                </div>
              </dd>
              <div className="text-xs mt-1">
                <span className="opacity-70">
                  Last Bet: {totalBet > 0 ? latestBet : "N/a"}
                </span>
              </div>
            </div>
            <div className="px-6 py-4">
              <div className="flex justify-between items-baseline">
                <dt className="text-xs mb-2 uppercase tracking-wide font-medium opacity-70">
                  Highest Win
                </dt>
              </div>
              <dd className="flex items-start justify-between md:flex">
                <div className="flex flex-1 items-center text-xl font-semibold">
                  <span>
                    {currencyFormat(
                      Number(
                        ethers.utils.formatUnits(
                          wagerHighEst?.amount || 0,
                          DECIMALS
                        )
                      ),
                      true
                    )}
                  </span>
                </div>
                <div className="flexflex-col">
                  <i className="fa-duotone fa-coins icon-primary text-2xl text-base-content/70"></i>
                </div>
              </dd>
              {wagerHighEst?.amount > 0 && (
                <div className="text-xs mt-1">
                  <a
                    className="opacity-70 hover:opacity-100 hover:text-primary"
                    href={`${blockUrl}/tx/${
                      wagerHighEst?.txHash?.[wagerHighEst?.txHash?.length - 1]
                    }`}
                    target={`_blank`}
                  >
                    {wagerHighEst?.outcomeIds?.length > 1
                      ? `${wagerHighEst?.outcomeIds.length}-Leg Parlay`
                      : `Single Bet`}{" "}
                    <i className="fa-sharp fa-light fa-long-arrow-right ml-1"></i>
                  </a>
                </div>
              )}
            </div>
            <div className="px-6 py-4">
              <div className="flex justify-between items-baseline">
                <dt className="text-xs mb-2 uppercase tracking-wide font-medium opacity-70">
                  Total Win
                </dt>
              </div>
              <dd className="flex items-start justify-between md:flex">
                <div className="flex flex-1 items-center text-xl font-semibold">
                  <span>
                    {currencyFormat(
                      Number(
                        ethers.utils.formatUnits(
                          user?.totalWon ? user?.totalWon.toString() : "0",
                          DECIMALS
                        )
                      ),
                      true
                    )}
                  </span>
                </div>
                <div className="flex flex-col">
                  <i className="fa-duotone fa-sack-dollar icon-primary text-2xl text-base-content/70"></i>
                </div>
              </dd>
              <div className="text-xs mt-1">
                <span className="opacity-70">
                  Won Bets: {floatFormat(totalWonBet)}
                </span>
              </div>
            </div>
          </div>
        </Screen>

        <Screen from="md">
          <div className="lg:col-span-2 grid grid-cols-1 divide-y divide-base-content/10 overflow-hidden md:grid-cols-3 md:divide-x md:divide-y-0">
            <div className="px-6 py-4">
              <div className="flex justify-between items-baseline">
                <dt className="text-xs mb-2 uppercase tracking-wide font-medium opacity-70">
                  Total Bets
                </dt>
              </div>
              <dd className="flex items-start justify-between md:flex">
                <div className="flex flex-1 items-center text-xl font-semibold">
                  <span>{totalBet || 0}</span>
                </div>
                <div className="hidden sm:flex flex-col">
                  <i className="fa-duotone fa-bolt icon-primary text-2xl text-base-content/70"></i>
                </div>
              </dd>
              <div className="text-xs mt-1">
                <span className="opacity-70">Last Bet: {latestBet}</span>
              </div>
            </div>
            <div className="px-6 py-4">
              <div className="flex justify-between items-baseline">
                <dt className="text-xs mb-2 uppercase tracking-wide font-medium opacity-70">
                  Highest Win
                </dt>
              </div>
              <dd className="flex items-start justify-between md:flex">
                <div className="flex flex-1 items-center text-xl font-semibold">
                  $
                  <span>
                    {currencyFormat(
                      Number(
                        ethers.utils.formatUnits(
                          wagerHighEst?.amount || 0,
                          DECIMALS
                        )
                      ),
                      true
                    )}
                  </span>
                </div>
                <div className="hidden sm:flex flex-col">
                  <i className="fa-duotone fa-coins icon-primary text-2xl text-base-content/70"></i>
                </div>
              </dd>
              <div className="text-xs mt-1">
                <a
                  className="opacity-70 hover:opacity-100 hover:text-primary"
                  href={`${blockUrl}/tx/${
                    wagerHighEst?.txHash?.[wagerHighEst?.txHash?.length - 1]
                  }`}
                  target={`_blank`}
                >
                  {wagerHighEst?.outcomeIds?.length > 1
                    ? `${wagerHighEst?.outcomeIds.length}-Leg Parlay`
                    : `Single Bet`}{" "}
                  <i className="fa-sharp fa-light fa-long-arrow-right ml-1"></i>
                </a>
              </div>
            </div>
            <div className="px-6 py-4">
              <div className="flex justify-between items-baseline">
                <dt className="text-xs mb-2 uppercase tracking-wide font-medium opacity-70">
                  Total Win
                </dt>
              </div>
              <dd className="flex items-start justify-between md:flex">
                <div className="flex flex-1 items-center text-xl font-semibold">
                  $
                  <span>
                    {currencyFormat(
                      Number(
                        ethers.utils.formatUnits(
                          user?.totalWon ? user?.totalWon.toString() : "0",
                          DECIMALS
                        )
                      ),
                      true
                    )}{" "}
                  </span>
                </div>
                <div className="hidden sm:flex flex-col">
                  <i className="fa-duotone fa-sack-dollar icon-primary text-2xl text-base-content/70"></i>
                </div>
              </dd>
              <div className="text-xs mt-1">
                <span className="opacity-70">
                  Won bets: {floatFormat(totalWonBet)}
                </span>
              </div>
            </div>
          </div>
        </Screen>
      </div>

      <Screen upto="sm">
        <div className={`relative text-center -mt-1 ${showMore ? "" : ""}`}>
          <button
            className="btn no-animation btn-sm text-xs rounded-t-none btn-block"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <>
                <span className="">Show More</span>{" "}
                <i className="fa-solid fa-angle-down ml-2"></i>
              </>
            ) : (
              <>
                <span className="">Show Less</span>{" "}
                <i className="fa-solid fa-angle-up ml-2"></i>
              </>
            )}
          </button>
        </div>
      </Screen>
    </>
  );
};
export default Summary;
