import _ from "lodash";
import { getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { mmFormatUTC } from "use-moment/format";
import { useAccount } from "./useAccount";
import { userHistoryCollRef, wagerDocRef } from "utils/firebase/firebaseDBRef";

export const useMyOrder = () => {
  const { address }: any = useAccount();
  const [listOrder, setListOrder] = useState<any>([]);
  const [listWager, setListWager] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [myOrder, setMyOrder] = useState<any>([]);
  const [myWagers, setMyWagers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [countData, setCountData] = useState<number>(0);
  const orderPerPage = 15;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastCurrentPage, setLastCurrentPage] = useState<number>(1);
  const [currentTab, setCurrentTab] = useState<string>("Orders");
  const [timeFilter, setTimeFilter] = useState<string>("All Times");
  const [search, setSearch] = useState<string>("");
  // const address = "0xFD5cAD34cb31b7136AA1F54AA9E598A904E14527";

  useEffect(() => {
    if (!address) return;
    const OrderHistoryRef = userHistoryCollRef(address);
    const getOrderData = onSnapshot(OrderHistoryRef, (snapshots: any) => {
      let _wager_ids: any = [];
      let orderData: any = snapshots.docs.map((snap: any) => {
        const data = snap.data();
        data?.wagers?.map((wager: any) => {
          if (!_wager_ids.filter((w: any) => w?.id === wager?.id).length) {
            _wager_ids.push({ ...wager });
          }
          return wager;
        });

        return {
          ...data,
          txHash: snap.id,
        };
      });
      setListOrder(orderData);
      orderData = _.orderBy(orderData, ["created_at"], ["desc"]);
      if (search) {
        orderData = orderData.filter(
          (item: any) =>
            item?.txHash?.toLowerCase().includes(search.toLowerCase()) ||
            item?.outcomes?.find((outcome: any) =>
              outcome?.eventName?.toLowerCase().includes(search.toLowerCase())
            ) ||
            item?.type?.toLowerCase().includes(search.toLowerCase())
        );
      }
      if (!orderData.length) setLoading(false);
      setListWager(_wager_ids);
      setMyOrder(orderData);
      setCountData(orderData?.length);
    });
    return () => {
      getOrderData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, search, currentTab]);

  useEffect(() => {
    if (!address || !listWager.length) {
      return;
    }
    fetchOrder().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, listWager, search, currentTab, timeFilter, currentPage]);

  const fetchOrder = async () => {
    setLoading(true);

    const wagerDocs = await Promise.all(
      listWager.map(async (wager: any) => {
        const wagerRef = wagerDocRef(wager?.id);
        const wagerDoc = await getDoc(wagerRef);
        return wagerDoc;
      })
    );

    const _data = wagerDocs
      .filter((doc) => doc.exists)
      .map((doc) => {
        const wager = listWager.find((_wager: any) => _wager?.id === doc.id);

        return { id: doc.id, ...doc.data(), amount: wager?.amount };
      });

    const data = myOrder
      .map((order: any) => {
        let status = 0;
        const _wagers = order?.wagers?.map((wager: any) => {
          const _wager = _data.find((w: any) => w?.id === wager?.id);
          return { ..._wager, amount: wager?.amount };
        });

        return {
          ...order,
          wagers: _wagers,
        };
      })
      .filter((order: any) => order?.outcomes);
    let data_filter = data;
    switch (timeFilter) {
      case "Today":
        data_filter = data.filter(
          (item: any) =>
            mmFormatUTC(item?.created_at, "YYYY-MM-DD") ===
            mmFormatUTC(new Date(), "YYYY-MM-DD")
        );
        break;
      case "This Week":
        data_filter = data.filter(
          (item: any) =>
            mmFormatUTC(item?.created_at, "YYYY-MM-DD") >=
            mmFormatUTC(
              new Date().setDate(new Date().getDate() - 7),
              "YYYY-MM-DD"
            )
        );
        break;
      case "This Month":
        data_filter = data.filter(
          (item: any) =>
            mmFormatUTC(item?.created_at, "YYYY-MM-DD") >=
            mmFormatUTC(
              new Date().setDate(new Date().getDate() - 30),
              "YYYY-MM-DD"
            )
        );
        break;
    }

    setCountData(data_filter?.length);
    const indexOfLastPost = currentPage * orderPerPage;
    const indexOfFirstPost = indexOfLastPost - orderPerPage;
    const currentPosts = data_filter.slice(indexOfFirstPost, indexOfLastPost);
    setData(currentPosts);
    setLoading(false);
  };

  return {
    data,
    listOrder,
    myOrder,
    loading,
    setCurrentPage,
    currentPage,
    countData,
    setTimeFilter,
    timeFilter,
    setCurrentTab,
    currentTab,
    setMyWagers,
    myWagers,
    search,
    setSearch,
  };
};
