import slugs from "navigation/slugs";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ErrorSvg } from "assets/images/leftArrow.svg";
import { Img } from "components/commons/Img";

const Error404 = () => {
  const pathname = window.location.pathname;
  if (pathname === '/lottery'){
    window.location.href = slugs.lottery;
    return null;
  };
  if (pathname === '/x1000') {
    window.location.href = slugs.x1000;
    return null;
  };
  return (
    <main className={`fadein w-full text-sm`}>
      <div
        className={
          "w-screen h-screen flex flex-col items-center justify-center z-0 relative"
        }
      >
        <div className={"font-semibold text-7xl text-base-content"}>404</div>
        <div className={"font-semibold text-4xl text-base-content capitalize"}>
          page not found
        </div>
        <Img
          addPrefix
          className={"w-full py-8 md:py-10"}
          alt={""}
          src={"assets/images/404.png"}
        />
        <div
          className={
            "text-base-content font-base text-md md:text-2xl text-center pb-4"
          }
        >
          We are unable to find the page you are looking for
        </div>
        <Link
          to={slugs.home}
          className={
            "flex flex-row items-center bg-green-400 p-2 bg-opacity-50 rounded-full hover:bg-green-500 font-base"
          }
        >
          {/* <img alt={''} src={'assets/leftArrow.svg'} /> */}
          <ErrorSvg />
          <div className={"text-base-content ml-2 font-normal"}>
            Back To Home
          </div>
        </Link>
      </div>
    </main>
  );
};

export default Error404;
