import React, { useEffect, useState } from "react";
import { ReactComponent as ZKUSDSvg } from "assets/currency-icons/zkUSD.svg";
import { ReactComponent as ZKETHSvg } from "assets/currency-icons/zkETH.svg";
import { getItem, saveItem } from "local-storage";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { orderSlipProxy } from "proxy-state/betting/OrderSlip";
import { configProxy } from "proxy-state/configProxy";
import { useIntl } from "react-intl";
const SlipPage = [1, 3, 5];
const SLIPPAGE = 1;

const BetModalBody = ({
  setBettingPage,
}: {
  setBettingPage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { symbol } = useSnapshot(configProxy);
  const orderSetingDefault = {
    defaultCurrency: symbol,
    quickOrder1: 20,
    quickOrder2: 50,
    quickOrder3: 200,
    quickOrder4: 500,
    slippage: SLIPPAGE,
  };
  const MySetting = getItem("orderSeting")
    ? JSON.parse(getItem("orderSeting") || JSON.stringify(orderSetingDefault))
    : orderSetingDefault;
  const intl = useIntl();
  const [isChange, setIsChange] = useState<boolean>(false);
  const [slippage, setSlippage] = useState<GLfloat>(SLIPPAGE);
  const [orderSeting, setOrderSeting] = useState<any>(MySetting);
  const [defaultCurrency, setDefaultCurrency] = useState("zkUSD");
  const { items } = useSnapshot(orderSlipProxy);
  
  const updateSetting = (name: any, value: any) => {
    const data = orderSeting;
    if (MySetting[name] !== value) setIsChange(true);
    else setIsChange(false);
    if (name === "defaultCurrency") setDefaultCurrency(value);
    if (name === "slippage") setSlippage(value);
    data[name] = value;
    setOrderSeting(data);
  };

  const saveSetting = () => {
    saveItem("orderSeting", JSON.stringify(orderSeting));
    setIsChange(false);
    toast.success("Saved");
  };

  useEffect(() => {
    setOrderSeting(MySetting);
    setDefaultCurrency(MySetting.defaultCurrency);
    setSlippage(MySetting.slippage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <>
      <div
        className={`sticky top-0 left-0 right-0 px-4 md:px-6 py-5 z-50 bg-base-200/80 backdrop-blur text-center`}
      >
        <button
          className="btn btn-sm rounded-full btn-ghost mr-2 absolute left-4 top-4 gap-2 !text-primary font-normal text-base"
          onClick={() => {
            setBettingPage(false);
          }}
        >
          <i className="fa-solid fa-angle-left text-xl"></i>
          Profile
        </button>
        <div className="inline-flex text-base font-semibold">
          <h3>Betting Preferences</h3>
        </div>
        <button
          className={`btn btn-sm rounded-full mr-2 absolute right-4 top-4 gap-2 font-normal${isChange ? " btn-primary " : " btn-disabled"}`}
          onClick={() => saveSetting()}
        >
          {intl.formatMessage({ id: "Save" })}
        </button>
      </div>

      <div className="p-4 md:p-6 !pb-8 gap-8 flex flex-col fadein text-base">
        {/* <div className={`space-y-4`}>
          <div className="flex flex-col bg-base-100 px-3 rounded-box">
            <label className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <span className="flex items-start text-base-content">
                <div className="flex flex-col gap-1">
                  <span className="">Accept Odds Change</span>
                  <span className="text-base-content/50 text-sm">
                    Accept all possible changes of the odds
                  </span>
                </div>
              </span>
              <div className="form-control">
                <input type="checkbox" className="toggle toggle-primary" />
              </div>
            </label>
          </div>
        </div> */}

        <div className={`space-y-4`}>
          <div className="flex flex-col bg-base-100 px-3 rounded-box">
            <div className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <span className="flex items-start text-base-content">
                <div className="flex flex-col gap-2 w-full">
                  <span className="text-base">Slippage Tolerance</span>
                  <span className="text-base-content/50 text-sm">
                    The amount of price slippage you are willing to accept for a
                    trade
                  </span>
                  <div className="grid grid-cols-3 gap-2 mt-3">
                    {SlipPage.map((slip: number) => (
                      <button
                        key={slip}
                        className={`btn btn-md  ${
                          slippage === slip
                            ? "border-primary hover:border-primary text-primary bg-transparent"
                            : "bg-neutral border-none hover:bg-opacity-40"
                        }`}
                        onClick={() => updateSetting("slippage", slip)}
                      >
                        {slip}%
                      </button>
                    ))}
                  </div>
                  <div className="grid grid-cols-1 gap-2 mt-3">
                    <div className="relative">
                      <input
                        type="number"
                        placeholder="Custom Slippage"
                        max={99}
                        min={0.1}
                        value={Intl.NumberFormat("en").format(Number(slippage))}
                        onChange={(e) => setSlippage(Number(e.target.value))}
                        onBlur={(e) =>
                          updateSetting("slippage", e.target.value)
                        }
                        className="input input-md input-bordered bg-transparent w-full"
                      />
                      <span className="absolute right-3 top-3">%</span>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className={`space-y-4`}>
          <div className="flex flex-col bg-base-100 px-3 rounded-box">
            <div className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <div className="flex items-start text-base-content w-full">
                <div className="flex flex-col gap-2 w-full">
                  <span className="text-base">Default Currency</span>
                  <div className="grid grid-cols-2 gap-2 mt-3">
                    <button className="btn btn-md border-primary hover:border-primary text-primary bg-transparent gap-2">
                      <span className="">
                        <ZKUSDSvg className="w-4 h-4" />
                      </span>
                      zkUSD
                    </button>
                    <button className="btn btn-block btn-md btn-disabled gap-2">
                      <span className="">
                        <ZKETHSvg className="w-4 h-4 grayscale" />
                      </span>
                      zkETH
                      {/* <span className="text-[8px] -mt-3">(SOON)</span> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`space-y-4`}>
          <div className="flex flex-col bg-base-100 px-3 rounded-box">
            <div className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <div className="flex items-start text-base-content w-full">
                <div className="flex flex-col gap-2 w-full">
                  <span className="text-base">Quick Orders</span>
                  <span className="text-base-content/50 text-sm">
                    The amount of orders you often place
                  </span>
                  <div className="grid grid-cols-2 gap-2 mt-3 w-full">
                    {Array.from(Array(4).keys()).map((item, index) => (
                      <div key={index} className="relative">
                        <input
                          type="number"
                          placeholder="10"
                          defaultValue={
                            orderSeting?.[`quickOrder${index + 1}`] || 50
                          }
                          onChange={(e) =>
                            updateSetting(
                              `quickOrder${index + 1}`,
                              e.target.value
                            )
                          }
                          className="input input-md input-bordered bg-transparent w-full"
                        ></input>
                        <span className="absolute right-3 top-4">
                          <ZKUSDSvg className="w-4 h-4" />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="grid grid-cols-2 gap-2 px-3 pt-3 pb-3">
          <button
            className="btn btn-md w-full"
            onClick={() => setBettingPage(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-md w-full btn-primary"
            onClick={() => saveSetting()}
          >
            Save
          </button>
        </div> */}
      </div>
    </>
  );
};
export default BetModalBody;
