import React, { Fragment } from "react";
import { Category } from "interfaces/firebase";
import { Link } from "react-router-dom";
import ScrollList from "./scrollList";
import _ from "lodash";
import {getIcon} from "components/commons/icons";
import { useSnapshot } from 'valtio';
import categoryNavProxy from "proxy-state/categoryNavProxy";
import { categoryProxy } from "proxy-state/categoryProxy";
import { leagueProxy } from "proxy-state/leagueProxy";
import { eventProxy } from "proxy-state/eventProxy";


interface CategoryNavProps {
  root: string;
}

const CategoryNav: React.FC<CategoryNavProps> = ({
  root,
}) => {
  const { cateTab, setCateTab } = useSnapshot(categoryNavProxy);
  const { data: publishedCategories, isLoading } = useSnapshot(categoryProxy);
  const { data: publishedLeagues, isLoading: leagueLoading } = useSnapshot(leagueProxy)
  const { isLoadingActiveEvent } = useSnapshot(eventProxy);
  const categories = publishedCategories.map((category) => {
    const eventCount = _.sumBy(
      publishedLeagues.filter(
        (league) => category.id === league.category_id && league.eventCount > 0
      ),
      "eventCount"
    );
    return {
      ...category,
      show: eventCount > 0,
      eventCount: eventCount,
    };
  });
  const navLoading = isLoading || leagueLoading || isLoadingActiveEvent;
  const Categories = _.orderBy(categories,'eventCount','desc');
  const DataLoading = () => {
    return (
      <ScrollList>
        {Array(3).fill(0).map((_, index) => (
          <div key={index} className="flex rounded-box justify-center items-center p-2 h-auto min-h-0 w-20 border border-transparent transition-all relative">
            <div className="flex flex-col justify-center items-center gap-3 h-full w-full animate-pulse">
              <div className="flex flex-col justify-center items-center mx-auto ">
                <div className="w-[26px] h-[26px] bg-base-content rounded-full z-[1] opacity-30"></div>
              </div>

              <div className="flex flex-col justify-center items-center mx-auto">
                <div className="w-8 h-2 bg-base-content rounded-box opacity-30"></div>
              </div>
            </div>
          </div>
        ))}
      </ScrollList>
    )
  }
  return (
    <div className={`w-full border-t border-b border-base-content/10`}>
      <div className="container flex justify-between items-center gap-2 py-3">
        <div className="grid">
          {navLoading  && <DataLoading />}
          {!navLoading && (
            <ScrollList>
              {_.orderBy(Categories,'eventCount','desc').map((item: Category) => (
                  <Fragment key={item?.id}>
                    {root === "sports" && (
                      <>
                        <button
                          key={item.name}
                          onClick={() => setCateTab && setCateTab(item.id)}
                          className={`tooltip flex rounded-box justify-center items-center p-2 h-auto min-h-0 w-20 border transition-all relative bg-transparent hover:bg-base-100 ${
                            cateTab === item.id
                              ? "border-primary hover:border-primary shadow"
                              : "border-transparent hover:border-base-100"
                          }${
                            Number(item.eventCount) <= 0
                              ? " disabled cursor-not-allowed"
                              : ""
                          }`}
                          disabled={Number(item.eventCount) <= 0}
                          data-tip={item.name}
                        >
                          <span className="flex flex-col gap-3">
                            <span className="flex flex-shrink-0 items-center justify-center h-6">
                              {item.icon_classes ? (
                                <>
                                  <i
                                    className={`${item.icon_classes} text-2xl ${
                                      cateTab === item?.id
                                        ? "text-primary"
                                        : "text-base-content/50"
                                    }`}
                                  ></i>
                                </>
                              ) : (
                                <div
                                  className={`h6 ${
                                    cateTab === item?.id
                                      ? "text-primary"
                                      : "text-base-content/50"
                                  }`}
                                >
                                  {getIcon(item?.id)}
                                </div>
                              )}
                            </span>
                            <span
                              className={`tooltip text-xs truncate leading-none ${
                                cateTab === item?.id
                                  ? "text-primary"
                                  : "text-base-content/50"
                              }`}
                              data-tip={item.name}
                            >
                              {item?.id === "american-football"
                                ? "A. Football"
                                : item?.id === "lol"
                                ? "LoL"
                                : item.name}
                            </span>
                            {Number(item?.eventCount) > 0 && (
                              <span
                                className={`badge badge-noti absolute top-2 ${
                                  cateTab === item?.id ? "" : "badge-noti-alt"
                                } ${
                                  Number(item.eventCount) > 9
                                    ? "right-2"
                                    : "right-4"
                                }`}
                              >
                                {item.eventCount}
                              </span>
                            )}
                          </span>
                        </button>
                      </>
                    )}
                    {root !== "sports" && (
                      <>
                        <Link
                          key={item.name}
                          to={item?.url_path ? `/${item?.url_path}` : "/"}
                          className={`btn btn-md px-3 ${
                            cateTab === item?.id
                              ? "btn-primary shadow"
                              : "btn-base200"
                          }`}
                        >
                          {item.icon_classes && (
                            <span className="flex flex-shrink-0 items-center justify-center mr-2">
                              <i className={`${item.icon_classes} text-base`}></i>
                            </span>
                          )}
                          <span className="">{item.name}</span>
                          {item.eventCount && (
                            <span className="badge badge-rounded ml-2">
                              {item.eventCount}
                            </span>
                          )}
                        </Link>
                      </>
                    )}
                  </Fragment>
                )
              )}
            </ScrollList>
          )}
        </div>
      </div>
    </div>
  );
};
export default CategoryNav;
