import {
  SocialType,
  checkSessionExist,
  createSession,
  linkWallet,
  loginSocial,
} from "utils/apis/bookmakerApis";
import { SignInWithFirebase } from "utils/firebase/firebaseAuth";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import { useEthersSigner } from "hooks/useSigner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import slugs from "navigation/slugs";
import { RawWalletConnect } from "components/organisms/Wallets/RawWalletConnect";
import { getGoogleUserInfo } from "utils/apis/api";
import Loading from "components/organisms/Loading";
import { getDocs } from "firebase/firestore";
import BgVideo from "assets/wallpapers/soccer.mp4";
import { ReactComponent as GoogleSvg } from "assets/icons/google.svg";
import { eoa, prepareCreateSessionParams } from "utils/blockchain";
import { useAuth } from "contexts/authContext";
import { auth } from "utils/firebase/config2";
import { queryUserBySocialId } from "utils/firebase";

/// DEV:
/// Step 1. GG auth
/// Step 2. Link wallet
/// Step 2.1. Connect wallet
/// Step 2.2. Sign contract
/// Step 3. Verify invite code

export enum AuthStep {
  LinkEOA = "link-eoa",
  Linked = "invitation",
}
const MetamaskPage = () => {
  const navigator = useNavigate();
  const { status, address, isDisconnected } = useAccount();
  const { disconnect }: any = useDisconnect();
  const { updateToken } = useAuth();
  const [step, setStep] = useState<AuthStep>(AuthStep.LinkEOA);
  const [socialId, setSocialId] = useState<string>("");
  const [socialType, setSocialtype] = useState<SocialType>(SocialType.GOOGLE);
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [picture, setPicture] = useState<string>("");
  const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(false);
  const [isCheckingSession, setIsCheckingSession] = useState<boolean>(false);
  const [isApproveSession, setIsApproveSession] = useState<boolean>(false);
  const [linkedWalletLoading, setLinkedWalletLoading] =
    useState<boolean>(false);
  const location = useLocation();
  const [isApplyWalletSuccess, setIsApplyWalletSuccess] =
    useState<boolean>(false);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);
  const [errorUserInfo, setErrorUserInfo] = useState("");

  const { token } = useParams();

  const { chain } = useNetwork();
  const signer = useEthersSigner({
    chainId: chain?.id,
  });

  useEffect(() => {
    disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWrapper = async (googleToken: string) => {
    const res = await loginSocial(googleToken, SocialType.GOOGLE);
    updateToken(res.data.access_token);
  };

  useEffect(() => {
    if (isDisconnected) {
      setIsApproveSession(false);
    }
  }, [isDisconnected]);

  /// Dev: check eoa session
  useEffect(() => {
    if (signer && address && step === AuthStep.LinkEOA && !chain?.unsupported) {
      const f = async () => {
        try {
          setIsCheckingSession(true);
          const result = await eoa.checkApproved(address);
          if (result) {
            const result = await checkSessionExist(address);

            if (result.data.data) {
              setIsApproveSession(true);
            }
          }
        } catch (error) {
          toast.error("Error code: CEF"); // check exist failure ❌
        } finally {
          setIsCheckingSession(false);
        }
      };
      f();
      // setIsCheckingSession(true);
      // createSmartAccount(address as `0x${string}`)
      //   ?.then((smartAccountRes) => {
      //     const accountContract = new ethers.Contract(
      //       smartAccountRes?.data,
      //       Account.abi,
      //       signer
      //     );
      //     return accountContract.pubKey();
      //   })
      //   .then((sessionPubKey) => {
      //     if (sessionPubKey !== "0x0000000000000000000000000000000000000000") {
      //       setIsApproveSession(true);
      //     }
      //     setIsCheckingSession(false);
      //   })
      //   .catch(() => {
      //     setIsCheckingSession(false);
      //     toast.error("Error code: CSAF"); // createSmartAccount failure ❌
      //   });
    }
  }, [step, address, signer, chain?.unsupported]);

  /// Dev: fetch user google info
  useEffect(() => {
    const f = async () => {
      if (!token) {
        setIsLoadingUserInfo(false);
        return;
      }

      let socialId: string = "";
      try {
        const userInfo = await getGoogleUserInfo(token);
        socialId = userInfo.data.sub;
        setSocialId(socialId);
        setSocialtype(SocialType.GOOGLE);
        setUserName(userInfo.data.name);
        setEmail(userInfo.data.email);
        setPicture(userInfo.data.picture);
      } catch (error) {
        setErrorUserInfo("Invalid url");
      }

      try {
        const userRef = queryUserBySocialId(socialId);
        const userDoc = await getDocs(userRef);
        const userData: any = userDoc.docs?.[0]?.data();
        if (!userData) return;
        if (!userData?.invitation_code) {
          setStep(AuthStep.Linked);
        }
      } catch (error) {
        console.log("🚀 ~ file: Metamask.tsx:201 ~ f ~ error:", error);
      } finally {
        setIsLoadingUserInfo(false);
      }
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, token]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsGoogleLoading(true);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }
      );

      const socialId = userInfo.data.sub;

      setSocialId(socialId);
      setSocialtype(SocialType.GOOGLE);
      setUserName(userInfo.data.name);
      setEmail(userInfo.data.email);
      setPicture(userInfo.data.picture);
      try {
        await SignInWithFirebase(auth);

        await loginWrapper(tokenResponse.access_token);
        navigator(slugs.home);
      } catch (error: any) {
        if (error.response) {
          // if (error.response.data.message === AuthStep.Link) {
          // 	setStep(AuthStep.Link);
          // } else if (error.response.data.message === AuthStep.LinkEOA) {
          // 	setStep(AuthStep.LinkEOA);
          // }
          setStep(AuthStep.LinkEOA);
        }
      } finally {
        setIsGoogleLoading(false);
      }
    },
    onError(errorResponse) {
      console.log("errorResponse", errorResponse);
    },
    onNonOAuthError: (err) => {
      console.log(err);
      setIsGoogleLoading(false);
    },
  });

  const applyWalletSession = async () => {
    try {
      if (!address || !signer) {
        toast.error("Error code: NTAS"); // Not found address | Signer ❌
        return;
      }
      setIsSessionLoading(true);

      const existSA = await eoa.checkExist(address);
      if (!existSA) {
        await eoa.deploySmartAccount(signer, address);
      }

      const accountContract = await eoa.getAccountContractFromAddress(
        address,
        signer
      );

      const { validAfter, validUntil, pubKey, privateKey } =
        await prepareCreateSessionParams();

      await (
        await accountContract.setSession(
          pubKey,
          validAfter.toString(),
          validUntil.toString(),
          {
            value: BigNumber.from(0),
          }
        )
      ).wait();
      await createSession(address as string, privateKey);
      setIsApproveSession(true);
    } catch (error: any) {
      console.log(
        "🚀 ~ file: Metamask.tsx:297 ~ applyWalletSession ~ error:",
        error
      );
      if (error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsSessionLoading(false);
    }
  };

  /// Dev: Link metamask wallet with social oauth after apply eoa session
  const afterLinkWalletAction = async () => {
    try {
      if (!token) return;

      setLinkedWalletLoading(true);
      /// Dev: checkWalletExist <=> checkInviteCodeExist
      // const checkWalletExistRes = await checkWalletExist(address as string);

      const resp = await linkWallet(token, socialType, address);

      setStep(AuthStep.Linked);
      setIsApplyWalletSuccess(true);

      if (resp.data) {
        await loginWrapper(token);
      }

      // if (!checkWalletExistRes.data) {

      // } else {
      //   const resp = await linkWallet(
      //     socialId,
      //     socialType,
      //     userName,
      //     email,
      //     address,
      //     undefined,
      //     picture
      //   );
      //   if (resp.data) {
      //     await loginWrapper(socialId);
      //   }
      // }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
    } finally {
      setLinkedWalletLoading(false);
    }
  };

  const currentDate = new Date();
  const next24hMilisecond = 1700211600000;

  const isShowLogin = true;

  // const isShowLogin =
  //   window.location.host.includes("localhost") ||
  //   window.location.host.includes("v2")
  //     ? true
  //     : currentDate.valueOf() < next24hMilisecond;

  if (isLoadingUserInfo) return <Loading />;

  // if (errorUserInfo)
  //   return (
  //     <div className="flex w-full h-screen justify-center items-center">
  //       <div className="text text-sm text-center text-error">
  //         {errorUserInfo}
  //       </div>
  //     </div>
  //   );

  return (
    <>
      {step === AuthStep.LinkEOA && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-sm text-center p-4 lg:p-0">
                <div
                  className="md:absolute top-0 right-0 left-0 flex justify-center md:mt-6"
                  style={{
                    marginBottom: 40,
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-md px-3 text-xs md:text-sm !inline-flex gap-3"
                    onClick={() => {
                      disconnect();
                    }}
                  >
                    <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100 flex items-center justify-center">
                      <img height="60" width="60" src={picture} alt="Avatar" />
                    </div>
                    <span className="text-xs md:text-sm">{userName}</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center">
                    <i className="fa-duotone fa-wallet icon-primary text-3xl"></i>
                  </div>

                  <h2 className="text-2xl font-semibold">Link Your Wallet</h2>

                  <div className="text-base leading-relaxed">
                    Connect your wallet and approve session to enable gasless
                    betting
                  </div>

                  <div className="bg-base-100/20 text-base-content/70 rounded-xl shadow-2xl p-6">
                    <ul className="steps steps-vertical w-full">
                      <li
                        className={
                          status === "connected"
                            ? "step step-neutral"
                            : "step step-primary"
                        }
                      >
                        <RawWalletConnect />
                      </li>
                      <li
                        className={
                          status === "connected" && !isApproveSession
                            ? "step step-primary"
                            : "step step-neutral"
                        }
                      >
                        <button
                          className={`btn btn-md text-sm ${
                            status === "connected" &&
                            !isApproveSession &&
                            !isCheckingSession &&
                            !chain?.unsupported
                              ? "btn-primary"
                              : "btn-disabled"
                          }`}
                          onClick={applyWalletSession}
                          disabled={isSessionLoading || chain?.unsupported}
                        >
                          {((status === "connected" && !isApproveSession) ||
                            status !== "connected") && (
                            <>
                              {(isSessionLoading || isCheckingSession) &&
                              !chain?.unsupported ? (
                                <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
                              ) : (
                                <i className="fa-regular fa-link-simple text-lg mr-3"></i>
                              )}
                              {isCheckingSession ? (
                                <span className="">
                                  Checking Session Aproval
                                </span>
                              ) : (
                                <span className="">Approve Your Session</span>
                              )}
                            </>
                          )}
                          {status === "connected" && isApproveSession && (
                            <>
                              <i className="fa-solid fa-circle-check text-success text-lg mr-3"></i>
                              <span className="">Session approved</span>
                            </>
                          )}
                        </button>
                      </li>

                      {/* <li
												className={`${
													status === "connected" && isApproveSession && !isApproveZkUSD
														? "step step-primary"
														: "step step-neutral"
												}`}
											>
												<button
													className={`btn btn-md text-sm ${
														status === "connected" && isApproveSession && !isApproveZkUSD ? "btn-primary" : "btn-disabled"
													}`}
													onClick={applyZkUSD}
												>
													{((status === "connected" && isApproveSession && !isApproveZkUSD) ||
														(status === "connected" && !isApproveSession) ||
														status !== "connected") && (
														<>
															<img
																alt="zkUSD"
																className="h-5 cursor-pointer mr-3 border-2 border-base-300/50 rounded-full"
																src="/currency-icons/zkUSD.png"
															/>
															<span className="">Approve zkUSD</span>
														</>
													)}
													{status === "connected" && isApproveSession && isApproveZkUSD && (
														<>
															<i className="fa-solid fa-circle-check text-success text-lg mr-3"></i>
															<span className="">zkUSD approved</span>
														</>
													)}
												</button>
											</li> */}
                    </ul>
                  </div>
                </div>
                <div className="mt-9">
                  <button
                    className={`btn btn-primary btn-block ${
                      status === "connected" && isApproveSession
                        ? ""
                        : "btn-disabled"
                    }`}
                    onClick={afterLinkWalletAction}
                    disabled={linkedWalletLoading}
                  >
                    {linkedWalletLoading ? (
                      <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
                    ) : null}
                    <span className="text-base">Continue</span>
                    <i className="fa-solid fa-angle-right ml-3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === AuthStep.Linked && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/70 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-10 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-sm text-center p-4 lg:p-0">
                <div className="flex flex-col gap-6">
                  <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center relative">
                    <i className="fa-duotone fa-wallet icon-primary text-3xl"></i>
                    <i className="fa-sharp fa-solid fa-circle-check text-success drop-shadow-lg absolute right-3 bottom-3 text-sm"></i>
                  </div>

                  <h2 className="text-2xl font-semibold">You are linked!</h2>

                  <div className="text-base leading-relaxed">
                    Go back to the Goal3 app to continue
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MetamaskPage;
