import React, { useEffect } from "react";
import { ModalLayout } from "./Layout";
import OrderSlips from "components/organisms/PlaceOrder";
import { modalProxy, modalFunc } from "proxy-state/global";
import { useSnapshot } from "valtio";
const OrderSlipModal: React.FC = () => {
  const { isBetSlipOpen } = useSnapshot(modalProxy);
  useEffect(() => {
    const body = document.querySelector("body");
    if (isBetSlipOpen) {
      body?.classList.add("has-Modal");
    } else {
      body?.classList.remove("has-Modal");
    }
  }, [isBetSlipOpen]);
  return (
    <>
      {/* Orderslips */}
      <ModalLayout
        modalId="betSlips--modal"
        isOpen={isBetSlipOpen}
        toggleModal={() =>
          modalFunc.setModalOpen("isBetSlipOpen", !isBetSlipOpen)
        }
        // customBody={true}
        position="full"
      >
        <div className="pt-0 flex-1 overflow-y-auto max-h-[90vh]">
          {/* <button onClick={() => setBetSlipModal(!betSlipModal)} className="btn btn-ghost btn-sm btn-circle modal-box--close">
						✕
					</button> */}
          <OrderSlips />
        </div>
      </ModalLayout>
    </>
  );
};

export default OrderSlipModal;
