import React, { Fragment, useEffect } from "react";
import utils, { floatFormat } from "../../../common/utils";
import { makeOutcomeId } from "../../../contracts/Contract";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";
import { categoryProxy } from "../../../proxy-state/categoryProxy";
import { leagueProxy } from "../../../proxy-state/leagueProxy";
import { OrderSlipFunc, orderSlipProxy } from "proxy-state/betting/OrderSlip";
import { betOrder, OrderTab } from "proxy-state/betting/OrderSlip";
import { TargetFunc } from "proxy-state/betting/target";


interface OddElementProps {
  idx: number;
  marketLiquid: number;
  odd: any;
  item: any;
  market: any;
  parentUrl: string;
}

const getOddWin = (markets: any) => {
  const win: string[] = [];
  if (!markets) return win;
  Object.keys(markets).forEach((key: any) => {
    const market = markets[key];
    market?.forEach((item: any) => {
      win.push(item?.win_odd_id);
    });
  });
  return win;
};
const OddElement: React.FC<OddElementProps> = ({
  idx,
  odd,
  item,
  market,
  parentUrl,
  marketLiquid,
}) => {
  const router = useLocation();
  const [betSlipStatus, setBetSlipStatus] = React.useState(false);
  const { publishedCategories: categories } = useSnapshot(categoryProxy);
  const { publishedLeagues: leagueData } = useSnapshot(leagueProxy);

  const setOrderTab = (tab: OrderTab) => {
   OrderSlipFunc.setOrderTab(tab);
  };
  const { total, items, orderTab } = useSnapshot(orderSlipProxy);

  const [screenName, setScreenName] = React.useState("full");
  const sellTax = 10;
  const resolved = market?.resolved || 0;
  const eId = market?.id;
  const mId = item?.id;
  const _outcomeId = odd?.outcome_id || makeOutcomeId(eId, mId, odd?.id);
  const leagues = leagueData.find(
    (league: any) => league?.id === market?.league_id
  );
  const category = categories.find(
    (category: any) => category?.id === leagues?.category_id
  );
  const data: betOrder = {
    eventName: `${market?.home?.name} vs ${market?.away?.name}`,
    home: market?.home?.name,
    away: market?.away?.name,
    eventUrl: `${parentUrl}`,
    eventId: eId,
    league: market?.league_name || leagues?.name || "",
    leagueIcon: market?.league_icon_classes || leagues?.icon_classes || "",
    cate_icon_class: category?.icon_classes || "",
    category_id: market?.category_id || category?.id || "",
    placeOrder: {
      outcomeId: _outcomeId,
      amount: sellTax,
      odds: odd?.odds,
      potentialWin: 0,
    },
    marketId: Number(item?.id),
    marketLiquid: Number(marketLiquid || "0"),
    odds: { ...odd, outcome_id: _outcomeId },
    updated_at: item?.updated_at,
    last_updated_at: item?.last_updated_at,
    marketKey: item?.key,
    marketName: item?.name,
    time_status: market?.time_status,
    time: market?.time,
  };

  const oddWinList = resolved ? getOddWin(market?.markets) : [];

  const isOddSelected =
    Array.from(items.values())?.filter(
      (betItem: any) =>
        betItem?.marketKey === item?.key &&
        betItem?.eventId === eId &&
        betItem?.odds?.id === odd.id
    ).length > 0;

  useEffect(() => {
    const onScreenName = () => {
      const screenName =
        document.body.attributes.getNamedItem("data-screen")?.value || "";
      if (["xs", "sm"].indexOf(screenName) > -1) {
        setScreenName("");
      } else {
        setScreenName("full");
      }
    };
    onScreenName();
    window.addEventListener("resize", onScreenName);
    return () => {
      window.removeEventListener("resize", onScreenName);
    };
  }, [router]);

  const btnOnClick = () => {
    if (total >= 6) {
      toast.warning("You can only place 6 bets at a time");
      return;
    }
    if (!_outcomeId) {
      toast.warning("Outcome ID is not found");
      return;
    }
    !betSlipStatus && setBetSlipStatus(true);
    orderTab !== OrderTab.OrderSlip && setOrderTab(OrderTab.OrderSlip);
    _outcomeId && OrderSlipFunc.addItem(data);
    TargetFunc.setSingleTarget(_outcomeId, 10);
  };

  function oddRemovebetSlips(e: any) {
    OrderSlipFunc.deleteItem(_outcomeId);
    TargetFunc.deleteTarget(_outcomeId);
    orderTab !== OrderTab.OrderSlip && setOrderTab(OrderTab.OrderSlip);
    e.target.blur();
  }

  const checkOddWin = (id: string) => {
    let isWin = false;
    isWin = oddWinList.includes(id);
    return isWin;
  };
  return (
    <Fragment>
      <button
        key={idx}
        className={`group outcome${
          isOddSelected ? " outcome--isSelected" : ""
        }${checkOddWin(odd?.id) ? " outcome--isWin" : ""}`}
        onClick={
          isOddSelected
            ? oddRemovebetSlips
            : () => {
                const nowTime = moment().unix();
                const startTime = Number(market.time) - 30;
                if (nowTime >= startTime) {
                  toast.warning("This Match is locked");
                  return;
                } else {
                  btnOnClick();
                }
              }
        }
        disabled={odd?.odds === "0" || odd?.odds === "-"}
      >
        <span className="outcome-title">
          {utils.getOddName(item?.key, data, screenName)}
        </span>
        <div className="flex items-center">
          {Number(floatFormat(odd?.roi, 2)) >
            Number(floatFormat(odd?.odds, 2)) && (
            <span className="mr-1 -mt-[4px] caret-up">
              <i className="fa-solid fa-triangle text-success text-[6px]"></i>
            </span>
          )}
          {Number(floatFormat(odd?.roi, 2)) <
            Number(floatFormat(odd?.odds, 2)) && (
            <span className="mr-1 -mt-3 caret-down">
              <i className="fa-solid fa-triangle rotate-180 text-error text-[6px]"></i>
            </span>
          )}
          <span className="outcome-value">
            {!odd?.roi || odd?.odds === "0" || odd?.odds === "-"
              ? "-"
              : floatFormat(odd?.roi, 2)}
          </span>
        </div>
      </button>
    </Fragment>
  );
};
export default OddElement;
