import React, { Fragment, useState } from "react";
import Leaderboard from "./LeaderBoard";
import RecentBets from "./AllBets";
import { useSnapshot } from "valtio";
import { leaderBoardFilter } from "proxy-state/leaderBoardFilter";
import { Tab } from "components/shared/Tab";
import { ELeaderBoardFilter } from "shared/enum";
import { navigateTab, setNavTab } from "proxy-state/global";
import { WagerFilter } from "./wagerFilter";
export enum tabActive {
  Recent = "Recent",
  Events = "Events",
  Leaderboard = "Leaderboard",
}
const Activities = () => {
  const tabList: tabActive[] = [tabActive.Recent, tabActive.Leaderboard];
  // const [activeTab, setActiveTab] = useState<tabActive>(tabActive.Recent);
  const { filterType } = useSnapshot(leaderBoardFilter);

  const snapNavigateTab = useSnapshot(navigateTab);
  const activeTab = snapNavigateTab?.tab?.Home["Activities"]?.subTab;

  const setCateTab = (tab: string) => {
    setNavTab("Home", "Activities", tab);
  };

  return (
    <Fragment>
      {/* Leaderboard */}
      <div className="section">
        <div className="section-header">
          <div className="hidden lg:flex items-center gap-3">
            <i className="fa-duotone fa-bolt icon-primary"></i>
            <h2 className="text-xl font-semibold">Activities</h2>
          </div>
          <div className="flex items-center justify-between gap-2 w-full lg:w-auto flex-shrink-0">
            <Tab tabList={tabList} tab={activeTab} setTab={setCateTab} />

            {activeTab === tabActive.Recent && (
              <>
                <div className="flex items-center">
                  <div className="dropdown dropdown-v2 dropdown-end">
                    <div tabIndex={-1} className="dropdown-toggle">
                      <span className="mr-2">
                        <i className="fa-solid fa-bars-filter"></i>
                      </span>
                      <button className="dropdown-toggle-title">
                        <span className="flex items-center">
                          <span className="truncate">All</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeTab === tabActive.Leaderboard && <WagerFilter />}
          </div>
        </div>
        {activeTab === tabActive.Recent && <RecentBets />}
        {activeTab === tabActive.Leaderboard && <Leaderboard />}
      </div>
      {/* //Leaderboard */}
    </Fragment>
  );
};
export default Activities;
