import { zkSyncSepoliaTestnet } from "wagmi/chains";
export const CHAINS: {
  [key: number]: {
    chainId: number;
    name: string;
    url: string;
    blockScanUrl: string;
  };
} = {
  324: {
    chainId: 324,
    name: "zksync Era Mainnet",
    url: "https://mainnet.era.zksync.io",
    blockScanUrl: "https://explorer.zksync.io",
  },
  300: {
    chainId: 300,
    name: "zksync Era Testnet",
    url: zkSyncSepoliaTestnet.rpcUrls.default.http[0],
    blockScanUrl: zkSyncSepoliaTestnet.blockExplorers.default.url,
  },
};
