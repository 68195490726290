import React from "react";
import moment from "moment";
import { useMyOrder } from "hooks/useMyOrder";
import { BigNumber } from "ethers";
import { ExplorerURLs } from "contracts/config";
import { CountDown } from "components/organisms/CountDown/CountDown";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as CornerIcon } from "assets/icons/corner.svg";
import basketball_1 from "assets/wallpapers/basketball-1.jpg";
import baseball_1 from "assets/wallpapers/baseball-1.jpeg";
import mma_1 from "assets/wallpapers/mma-1.jpg";
import football_2 from "assets/wallpapers/football-2.jpeg";
import basketball from "assets/icons/basketball.svg";
import baseball from "assets/icons/baseball-bat-ball.svg";
import mma from "assets/icons/hand-fist.png";
import soccer from "assets/icons/futbol.svg";
import csgo2 from "assets/icons/CSGO.svg";
import cs2 from "assets/wallpapers/cs2-1.jpg";
import BG_dota2 from "assets/wallpapers/dota-1.jpg";
import bg_lol from "assets/wallpapers/lol.jpeg";
import { getIcon } from "components/commons/icons";

const defaultImg: any = {
  basketball: basketball,
  baseball: baseball,
  mma: mma,
  soccer: soccer,
  cs2: csgo2,
};

function EventHeader({ market, cate }: any) {
  const { listOrder } = useMyOrder();
  const [currentTime, setCurrentTime] = React.useState<number>(moment().unix());
  const [isValidImage, setIsValidImage] = React.useState<boolean>(false);
  const [isValidImageAway, setIsValidImageAway] =
    React.useState<boolean>(false);

  const e_id = market?.id;
  const startTime = moment.unix(market?.time).format("HH:mm a, DD/MM/YYYY");
  const timeStatus = ["Open in", "In-Play", "Resolving", "Resolved"];
  const allOutcomes = listOrder.length
    ? listOrder?.map((item: any) => item.outcomes).flat()
    : [];
  const hasOutComeCount = allOutcomes.length
    ? allOutcomes.filter((outcomeId: string) => {
        const _eventId = BigNumber.from(outcomeId).shr(64).toNumber();
        if (_eventId === Number(e_id)) return outcomeId;
      }).length
    : 0;
  const explorerUrl =
    ExplorerURLs[Number(process.env.REACT_APP_CHAIN_ID) || 324];

  const start_time = Number(market?.time) - 30;

  let time_status = Number(market?.time_status);

  if (currentTime < start_time) {
    time_status = 0;
  }

  React.useEffect(() => {
    if (Number(market?.time_status) > 1) {
      return;
    }
    const interval = setInterval(() => {
      const now = moment().unix();
      setCurrentTime(now);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [market?.time_status]);

  const marketHeaderClass =
    "bg-base-100 text-base-content shadow rounded-box text-sm relative overflow-hidden";

  let _bg_image = football_2;
  switch (cate) {
    case "basketball":
      _bg_image = basketball_1;
      break;
    case "baseball":
      _bg_image = baseball_1;
      break;

    case "mma":
      _bg_image = mma_1;
      break;
    case "cs2":
      _bg_image = cs2;
      break;
    case "dota2":
      _bg_image = BG_dota2;
      break;
    case "lol":
      _bg_image = bg_lol;
      break;

    default:
      _bg_image = football_2;
      break;
  }
  return (
    <div className={marketHeaderClass}>
      {/* Category Wallpaper - Change based on Category */}
      <div className="absolute inset-0 z-0 flex">
        <img
          className="object-cover flex-1 max-w-full brightness-50 grayscale"
          src={_bg_image}
          alt=""
        />
      </div>
      {/* //Category Wallpaper - Change based on Category */}

      <div className="w-full relative z-[1] bg-base-100/80 p-4 md:p-6 lg:p-8">
        <div className="max-w-4xl mx-auto flex">
          <div className="flex flex-col justify-start items-start md:justify-center md:items-center flex-1">
            <div className="w-12 mb-2 text-base-content/50">
              {market?.home?.image_id && (
                <img
                  className={`${isValidImage ? "" : "hidden"}`}
                  src={`https://assets.b365api.com/images/team/b/${market?.home?.image_id}.png`}
                  alt={market?.home?.name}
                  onLoad={(event: any) => {
                    setIsValidImage(event?.target?.height > 1);
                    // if (event?.target?.height <= 1) {
                    //   (event?.target as HTMLImageElement).src =
                    //     defaultImg[cate];
                    // }
                  }}
                />
              )}

              {(!isValidImage || !market?.home?.image_id) && (
                <>{getIcon(cate.toString(), "w-10 h-10 rounded-full")}</>
              )}
            </div>
            <div
              className={`w-full text-left md:text-center text-xs md:text-sm${
                Number(time_status) > 0 ? " flex-1 md:flex-initial" : ""
              }`}
            >
              <strong className="">{market?.home?.name}</strong>
            </div>
            {cate === "soccer" && Number(time_status) > 0 && (
              <div className="inline-flex text-left md:text-center text-xs md:text-sm font-semibold space-x-3 mt-3">
                <div className="flex items-center" title="Yellow Cards">
                  <span className="icon-yellowcard mr-2">
                    <span className="sr-only">Yellow Cards</span>
                  </span>
                  <span className="">{market?.stats?.yellowcards?.[0]}</span>
                </div>
                <div className="flex items-center" title="Red Cards">
                  <span className="icon-redcard mr-2">
                    <span className="sr-only">Red Cards</span>
                  </span>
                  <span className="">{market?.stats?.redcards?.[0]}</span>
                </div>
                <div className="flex items-center" title="Corner Kicks">
                  <CornerIcon width={12} height={12} className="mr-2" />
                  <span className="">{market?.stats?.corners?.[0]}</span>
                </div>
              </div>
            )}
          </div>

          {/* Timer */}
          <div className="flex flex-col justify-center items-center px-4">
            {market?.cancelled !== 1 && (
              <>
                {Number(time_status) === 0 && (
                  <>
                    <span className="flex items-baseline font-medium uppercase text-xs md:text-sm">
                      <i className="fa-regular fa-clock mr-2 relative"></i>
                      <span className="">
                        {timeStatus[Number(time_status)]}
                      </span>
                    </span>
                    {Number(time_status) < 2 && (
                      <div className="mt-2 md:mt-3 bg-primary text-primary-content rounded-full px-3 py-1 md:px-4 md:py-2 text-sm">
                        <CountDown
                          date={market?.time}
                          type={"sort"}
                          unit_classes={`opacity-50 ml-1`}
                        />
                      </div>
                    )}
                    <div className="mt-2 md:mt-3 text-xs md:text-sm uppercase font-medium">
                      <span>{startTime}</span>
                    </div>
                  </>
                )}
                {Number(time_status) === 1 && (
                  <>
                    <div className="flex items-center space-x-1 text-xs md:text-sm text-accent">
                      <span className="font-semibold">
                        <i className="fa-solid fa-signal-stream fa-fade mr-1"></i>{" "}
                        IN PLAY
                      </span>
                    </div>
                    {cate === "soccer" && (
                      <span className=" text-xs md:text-sm mt-1">
                        {market?.events?.[market?.events?.length - 1]?.text}
                      </span>
                    )}
                    {/* <span className="text-xs">65', 2nd Half</span> */}
                  </>
                )}
                {Number(time_status) === 3 && (
                  <>
                    <span className="flex items-baseline font-semibold uppercase text-xs md:text-sm text-primary">
                      <i className="fa-solid fa-badge-check mr-2 relative"></i>
                      {market?.verified ? "Resolved" : "Resolving"}
                    </span>
                    <span className="text-xs md:text-sm mt-1 uppercase break-all whitespace-nowrap">
                      {startTime}
                    </span>
                  </>
                )}
              </>
            )}

            {(Number(time_status) === 4 || market?.cancelled) && (
              <>
                <Link
                  to={`${explorerUrl}/tx/${market?.cancelled_txn}`}
                  target="_Blank"
                  rel="noreferrer"
                >
                  <span className="flex items-baseline font-semibold uppercase text-xs md:text-sm text-primary">
                    <i className="fa-solid fa-badge-check mr-2 relative"></i>
                    Cancelled
                    <i className="fa-solid fa-square-arrow-up-right ml-2"></i>
                  </span>
                </Link>
                <span className="text-xx md:text-sm mt-1 uppercase">
                  {startTime}
                </span>
              </>
            )}
            {Number(time_status) > 0 && (
              <>
                <div className="flex items-center justify-center mt-3 space-x-2">
                  <div className="bg-primary text-primary-content rounded-box px-3 py-2 text-sm md:text-base">
                    <span className="countdown slashed-zero leading-4 font-semibold">
                      {market?.ss ? market?.ss?.split("-")[0] : 0}
                    </span>
                  </div>
                  <span className="opacity-70 font-bold">:</span>
                  <div className="bg-primary text-primary-content rounded-box px-3 py-2 text-sm md:text-base">
                    <span className="countdown slashed-zero leading-4 font-semibold">
                      {market?.ss ? market?.ss?.split("-")[1] : 0}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="flex flex-col justify-start items-end md:justify-center md:items-center flex-1">
            <div className="w-12 mb-2 text-base-content/50">
              {market?.away?.image_id && (
                <img
                  className={`${isValidImageAway ? "" : "hidden"}`}
                  src={`https://assets.b365api.com/images/team/b/${market?.away?.image_id}.png`}
                  alt={market?.away?.name}
                  onLoad={(event: any) => {
                    setIsValidImageAway(event?.target?.height > 1);
                    // if (event?.target?.height <= 1) {
                    //   (event?.target as HTMLImageElement).src =
                    //     defaultImg[cate];
                    // }
                  }}
                />
              )}

              {(!isValidImageAway || !market?.away?.image_id) && (
                <>{getIcon(cate.toString(), "w-10 h-10 rounded-full")}</>
              )}
            </div>
            <div
              className={`w-full text-right md:text-center text-xs md:text-sm${
                Number(time_status) > 0 ? " flex-1 md:flex-initial" : ""
              }`}
            >
              <strong className="">{market?.away?.name}</strong>
            </div>
            {cate === "soccer" && Number(time_status) > 0 && (
              <div className="inline-flex text-right md:text-center text-xs md:text-sm font-semibold space-x-3 mt-auto md:mt-3">
                <div className="flex items-center" title="Yellow Cards">
                  <span className="icon-yellowcard mr-2">
                    <span className="sr-only">Yellow Cards</span>
                  </span>
                  <span className="">{market?.stats?.yellowcards?.[1]}</span>
                </div>
                <div className="flex items-center" title="Red Cards">
                  <span className="icon-redcard mr-2">
                    <span className="sr-only">Red Cards</span>
                  </span>
                  <span className="">{market?.stats?.redcards?.[1]}</span>
                </div>
                <div className="flex items-center" title="Corner Kicks">
                  <CornerIcon width={12} height={12} className="mr-2" />
                  <span className="">{market?.stats?.corners?.[1]}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {cate === "basketball" &&
          market?.scores &&
          market?.scores[1]?.home &&
          market?.scores[1]?.away && (
            <>
              <div className="max-w-2xl mx-auto grid grid-cols-1 gap-2 p-4 mt-6 bg-base-300/60 backdrop-blur rounded-box">
                <div className="grid grid-cols-11 gap-1 opacity-70">
                  <div className="col-span-4"></div>
                  <div className="text-center">1</div>
                  <div className="text-center">2</div>
                  <div className="text-center">H</div>
                  <div className="text-center">3</div>
                  <div className="text-center">4</div>
                  <div className="text-center">OT</div>
                  <div className="text-center">F</div>
                </div>
                <div className="grid grid-cols-11 gap-1 font-medium">
                  <div className="col-span-4 text-xs md:text-sm">
                    {market?.home?.name}
                  </div>
                  <div className="text-center">
                    {market?.scores[1]?.home || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[2]?.home || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[3]?.home || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[4]?.home || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[5]?.home || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[6]?.home || "-"}
                  </div>
                  <div className="text-center">
                    {market?.ss?.split("-")?.[0] || "-"}
                  </div>
                </div>
                <div className="grid grid-cols-11 gap-1 font-medium">
                  <div className="col-span-4 text-xs md:text-sm">
                    {market?.away?.name}
                  </div>
                  <div className="text-center">
                    {market?.scores[1]?.away || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[2]?.away || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[3]?.away || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[4]?.away || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[5]?.away || "-"}
                  </div>
                  <div className="text-center">
                    {market?.scores[6]?.away || "-"}
                  </div>
                  <div className="text-center">
                    {market?.ss?.split("-")?.[1] || "-"}
                  </div>
                </div>
              </div>
            </>
          )}
        {cate === "baseball" &&
          market?.scores &&
          market?.scores[1]?.home &&
          market?.scores[1]?.away && (
            <>
              <div className="max-w-2xl mx-auto grid grid-cols-12 gap-1 p-4 mt-6 bg-base-300/60 backdrop-blur rounded-box">
                <div className="grid grid-cols-1 gap-1 col-span-4">
                  <div className="flex items-center justify-center opacity-70">
                    <div className="">&nbsp;</div>
                  </div>
                  <div className="font-medium">
                    <div className="text-xs md:text-sm truncate">
                      {market?.home?.name}
                    </div>
                  </div>
                  <div className="font-medium">
                    <div className="text-xs md:text-sm truncate">
                      {market?.away?.name}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-1 col-span-8">
                  <div className="grid grid-cols-11 opacity-50">
                    <div className="text-center">1</div>
                    <div className="text-center">2</div>
                    <div className="text-center">3</div>
                    <div className="text-center">4</div>
                    <div className="text-center">5</div>
                    <div className="text-center">6</div>
                    <div className="text-center">7</div>
                    <div className="text-center">8</div>
                    <div className="text-center">9</div>
                    <div className="text-center">R</div>
                    <div className="text-center">H</div>
                  </div>
                  <div className="grid grid-cols-11 font-medium">
                    <div className="text-center">
                      {market?.scores?.[1]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[2]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[3]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[4]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[5]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[6]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[7]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[8]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[9]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.["run"]?.home || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.["hit"]?.home || "-"}
                    </div>
                  </div>
                  <div className="grid grid-cols-11 font-medium">
                    <div className="text-center">
                      {market?.scores?.[1]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[2]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[3]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[4]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[5]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[6]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[7]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[8]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.[9]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.["run"]?.away || "-"}
                    </div>
                    <div className="text-center">
                      {market?.scores?.["hit"]?.away || "-"}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        {hasOutComeCount > 0 && (
          <>
            {/* Show when user have bets */}
            <div className="max-w-2xl mx-auto flex items-center justify-center pt-4 mt-6 border-t border-dashed border-base-content/40">
              <span className="font-semibold">
                You've placed <span className="">{hasOutComeCount} Bets</span>.
              </span>
              <a href="/account/bets" className="link ml-2 font-semibold">
                Go to My Bets{" "}
                <i className="fa-regular fa-long-arrow-right ml-2 text-sm"></i>
              </a>
            </div>
            {/* //Show when user have bets */}
          </>
        )}
      </div>
    </div>
  );
}

export default EventHeader;
