import _ from "lodash";
import { BigNumber } from "ethers";
import { DocumentData, DocumentReference, getDoc } from "firebase/firestore";
import { offerBet, getLiquidRewardState } from "./threshold.utils";
import { WEI6 } from "./betUtils";
import { parseBigNumber } from "common/utils";
import { getEventMarketDocRef } from "utils/firebase/firebaseDBRef";

/*PARAMS: eventdata from firestore
 *OUTPUT: {[eventId]: outcomes[]}
 */
export const getOddsFromEvent = (eventsData: any[]): Record<string, any[]> => {
  const data: any = {};
  eventsData.forEach((event) => {
    const markets: any[] = _.flatten(Object.values(event.markets));
    data[event.id] = _.flatten(markets.map((market) => market.odds));
  });
  return data;
};

export const findOddOutcomeFromEvent = (outcomeId: string, eventData: any) => {
  const eventOdds = getOddsFromEvent([eventData])[eventData.id];
  return eventOdds.filter((outcome) => outcome.outcome_id === outcomeId)[0];
};

export const getEventIdsFromOutcomeIds = (outcomeIds: BigNumber[]) => {
  return outcomeIds.map((item) => getEventIdFromOutcomeId(item));
};

export const getEventIdFromOutcomeId = (outcomeId: BigNumber) => {
  return outcomeId.shr(64).toString();
};

export const getAllOddsMarketFromEvent = (
  eventData: any,
  outcomeId: string
): any[] => {
  let outcomes: any[] = [];
  Object.keys(eventData?.markets).forEach((maGroup) => {
    eventData?.markets[maGroup].forEach((ma: any) => {
      const check = ma.odds.find((odd: any) => {
        return odd.outcome_id === outcomeId;
      });
      if (check) {
        outcomes = [...outcomes, ...ma.odds];
      }
    });
  });

  return outcomes;
};

export const getMarketOdds = (
  outcomeIds: BigNumber[],
  eventsData: any[]
): {
  index: number;
  marketOutcomeOdds: { odds: number; outcome_id: string }[];
}[] => {
  return outcomeIds.map((outcomeId) => {
    const eventsDataKey = _.keyBy(eventsData, "id");
    const eventId = getEventIdFromOutcomeId(outcomeId);
    if (!eventsDataKey[eventId]) {
      return {
        index: -1,
        marketOutcomeOdds: [],
      };
      // throw new Error("Not found event");
    }

    const marketOutcomes = getAllOddsMarketFromEvent(
      eventsDataKey[eventId],
      outcomeId.toString()
    ) as {
      odds: number;
      outcome_id: string;
    }[];

    const currentOutcomeIndex = marketOutcomes.findIndex(
      (odd) => odd.outcome_id === outcomeId.toString()
    );

    if (currentOutcomeIndex < 0) {
      return {
        index: -1,
        marketOutcomeOdds: [],
      };
      // throw new Error("Not found odd outcome");
    }
    return {
      index: currentOutcomeIndex,
      marketOutcomeOdds: marketOutcomes,
    };
  });
};
export const GetEventMarketLiquidRewards = async (
  eventId: string,
  market: string
) => {
  const marketDocRef: DocumentReference<DocumentData> = getEventMarketDocRef(
    eventId,
    market
  );
  const marketDocSnapshot = await getDoc(marketDocRef);
  const data: any = marketDocSnapshot.data();
  const rewards: Map<string, BigNumber> = new Map();
  if (data && data?.single) {
    Object.keys(data.single).forEach((outcome) => {
      const bets = data.single[outcome];
      const reward: BigNumber = bets.reduce((prev: BigNumber, bet: any) => {
        const payout: BigNumber = bet?.wagerAmount
          ? BigNumber.from(bet.wagerAmount)
          : BigNumber.from(0);
        return prev.add(payout);
      }, BigNumber.from(0));
      rewards.set(outcome, reward);
    });
  }
  return { rewards };
};

export const getOfferedBetPayout = (
  rewards: Map<string, BigNumber>,
  marketLiquid: BigNumber,
  threshold: BigNumber,
  offerOdd: BigNumber,
  odd: number,
  outcome: string,
  amount: BigNumber
) => {
  let offeredBet = BigNumber.from(0);
  let offeredPayout = BigNumber.from(0);
  if (threshold.gt(0)) {
    const { curMaxReward, riskTolerance, userReward, curReward } =
      getLiquidRewardState(
        outcome,
        amount,
        odd,
        rewards,
        marketLiquid,
        threshold
      );

    if (curMaxReward.gt(riskTolerance)) {
      console.log("====================================");
      console.log("curMaxReward > riskTolerance");
      console.log("====================================");
    } else if (userReward.add(curReward).gt(riskTolerance)) {
      offeredBet = offerBet(riskTolerance, curReward, odd);
    }
  }
  offeredPayout = offeredBet.mul(parseBigNumber(odd, true)).div(WEI6);

  return {
    // offeredBet: BigNumber.from(offeredBet).mul(WEI6), // big number
    offeredBet, // number
    offeredPayout, // number
  };
};
