import React from "react";
const NotificationEmpty = () => {
  return (
    <div className="mt-6 md:mt-12">
      <div className="grid grid-cols-1">
        <div className="mt-6">
          <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
            <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
            <span className="text-lg font-bold">Nothing Found</span>
            <span className="text-xs opacity-70 text-center mt-1">
              Place some bets first!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationEmpty;
