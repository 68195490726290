import _ from "lodash";
import { makeOutcomeId } from "../contracts/Contract";
interface oddProps {
  idx: string;
  name: string;
  header: string;
  odds: string;
  outcome_id: string;
  mId: string;
  update_at: string;
}
export const mapMarketData = (data: any) => {
  let allMarkets: any = [];
  let _markets: any = data?.markets;
  const score_to_win_halves = {
    id: 1010229,
    key: "score_in_both_halves",
    name: "Score in Both Halves",
    odds: [] as oddProps[],
    marketType: "",
    updated_at: 0,
  };
  const win_either_half = {
    id: 1010227,
    key: "win_either_half",
    name: "Win Either Half",
    odds: [] as oddProps[],
    marketType: "",
    updated_at: 0,
  };
  const alternative_total_goal = {
    id: 1010209,
    key: "alternative_total_goal",
    name: "Alternative Game Total",
    odds: [] as oddProps[],
    marketType: "",
    updated_at: 0,
  };
  const total_rounds = {
    id: 2620101,
    key: "total_rounds",
    name: "Total Rounds",
    odds: [] as oddProps[],
    marketType: "",
    updated_at: 0,
  };

  const main_markets = _.orderBy(_markets?.main, "id", "asc");
  allMarkets = [
    ...main_markets
      .map((item: any, idx: number) => {
        if (item?.key.includes("total_rounds_")) {
          item.odds.forEach((odd: any) => {
            const odds = {
              ...odd,
              mId: item?.id,
            };
            total_rounds.odds.push(odds);
          });
          total_rounds.marketType = "main";
        } else {
          return {
            ...item,
            ordering:
              item.key === "correct_score"
                ? 10
                : item.key === "full_time_result"
                  ? 1
                  : item.key === "total_goals"
                    ? 2
                    : idx,
            marketType: "main",
          };
        }
      })
      .filter((item: any) => item !== undefined),
    ...allMarkets,
  ];
  for (const _market in _markets) {
    if (_market === "main") continue;
    _markets[_market]?.forEach((item: any) => {
      if (
        item?.key === "to_away_score_in_both_halves" ||
        item?.key === "to_home_score_in_both_halves"
      ) {
        if (item?.hide_from_frontend !== 1) {
          const odds = {
            ...item.odds[0],
            mId: item?.id,
            name:
              item?.key === "to_away_score_in_both_halves"
                ? data?.away?.name
                : data?.home?.name,
          };
          score_to_win_halves.odds.push(odds);
          score_to_win_halves.marketType = _market;
        }
      } else if (
        item?.key === "to_away_win_either_half" ||
        item?.key === "to_home_win_either_half"
      ) {
        if (item?.hide_from_frontend !== 1) {
          const odds = {
            ...item.odds[0],
            mId: item?.id,
            name:
              item?.key === "to_away_win_either_half"
                ? data?.away?.name
                : data?.home?.name,
          };
          win_either_half.odds.push(odds);
          win_either_half.marketType = _market;
        }
      } else if (item?.key.includes("alternative_total_goal_")) {
        if (item?.hide_from_frontend !== 1) {
          item.odds.forEach((odd: any) => {
            const odds = {
              ...odd,
              mId: item?.id,
            };
            alternative_total_goal.odds.push(odds);
          });
          alternative_total_goal.marketType = _market;
        } else if (item?.key.includes("total_rounds_")) {
          item.odds.forEach((odd: any) => {
            const odds = {
              ...odd,
              mId: item?.id,
            };
            total_rounds.odds.push(odds);
          });
          total_rounds.marketType = _market;
        }
      } else {
        allMarkets.push({
          ...item,
          marketType: _market,
          ordering: 4,
        });
      }
    });
  }
  if (score_to_win_halves.odds.length) {
    score_to_win_halves.odds = _.orderBy(
      score_to_win_halves.odds,
      "header",
      "asc"
    );
    allMarkets.push({
      ...score_to_win_halves,
      ordering: 3,
    });
  }
  if (win_either_half.odds.length) {
    win_either_half.odds = _.orderBy(win_either_half.odds, "header", "asc");
    allMarkets.push({
      ...win_either_half,
      ordering: 3,
    });
  }
  if (alternative_total_goal.odds.length) {
    alternative_total_goal.odds = _.orderBy(
      alternative_total_goal.odds,
      "name",
      "asc"
    );
    allMarkets.push({
      ...alternative_total_goal,
      ordering: 3,
    });
  }
  if (total_rounds.odds.length) {
    total_rounds.odds = _.orderBy(total_rounds.odds, "header", "asc");
    allMarkets.push({
      ...total_rounds,
      ordering: 3,
    });
  }

  const newData =
    allMarkets.filter((m: any) => m?.hide_from_frontend !== 1).length > 0
      ? {
          ...data,
          allMarkets,
        }
      : {};

  return newData;
};
