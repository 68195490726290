import React, { Fragment, useEffect, useState } from "react";
import MainTopMatches from "./MainTopMatches";
import MainTopOutrights from "./MainTopOutrights";
import MainActivities from "./MainActivities";
import { useOneSignal } from "hooks/useOneSignal";
import { useIntl } from "react-intl";
import { Responsive } from "components/commons";
import { useSnapshot } from "valtio";
import { navigateTab, setNavTab } from "proxy-state/global";
import { isIOS } from "react-device-detect";
import CategoryNav from "components/organisms/Sports/utils/categoryNav";
import { categoryProxy } from "proxy-state/categoryProxy";
import { leagueProxy } from "proxy-state/leagueProxy";
import _ from "lodash";

const isInStandaloneMode = window.matchMedia(
  "(display-mode: standalone)"
).matches;
// import Banners from "components/organisms/Banners";
export default function Home() {
  useOneSignal();
  const intl = useIntl();

  const [scrollDirection, setScrollDirection] = useState<boolean>(false);
  const snapNavigateTab = useSnapshot(navigateTab);
  const page = snapNavigateTab.tab.Home.currentTab;
  const { data: publishedCategories, isLoading } = useSnapshot(categoryProxy);
  const {data: publishedLeagues, isLoading: leagueLoading} = useSnapshot(leagueProxy)
  const categories = publishedCategories.map((category) => {
    const eventCount = _.sumBy(
      publishedLeagues.filter(
        (league) => category.id === league.category_id && league.eventCount > 0
      ),
      "eventCount"
    );
    return {
      ...category,
      show: eventCount > 0,
      eventCount: eventCount,
    };
  });
  useEffect(() => {
    let prevScrollPos = window.scrollY;
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setScrollDirection(false); 
      } else if (currentScrollPos < prevScrollPos) {
        setScrollDirection(true);
      }

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <Responsive upto="md">
        <div
          className={`w-full container ${
            scrollDirection &&
            isInStandaloneMode &&
            isIOS &&
            "sticky top-24 bg-base-300 z-50"
          }`}
        >
          <div className="htabs-container">
            <div className="htabs htabs-f">
              <button
                className={`htab${
                  page === "Top Matches" ? " htab-active" : ""
                }`}
                onClick={() => setNavTab("Home", "Top Matches")}
              >
                <span className="flex flex-col justify-center items-center">
                  <span className="htab-title">
                    {intl.formatMessage({ id: "Top Matches" })}
                  </span>
                  <span className="htab-border"></span>
                </span>
              </button>
              {/* <button
            className={`htab${page === "top-outrights" ? " htab-active" : ""}`}
            onClick={() => setPage("top-outrights")}
          >
            							<span className="flex flex-col justify-center items-center">

              <span className="htab-title">Top Outrights</span>
              <span className="htab-border"></span>
            </span>
          </button> */}
              <button
                className={`htab${page === "Activities" ? " htab-active" : ""}`}
                onClick={() => setNavTab("Home", "Activities")}
              >
                <span className="flex flex-col justify-center items-center">
                  <span className="htab-title">
                    {intl.formatMessage({ id: "Activities" })}
                  </span>
                  <span className="htab-border"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        {page === "Top Matches" && <CategoryNav root={"sports"}  /> }
        <div className="container">
          {/* Matches Lists */}
          {page === "Top Matches" && <MainTopMatches page="home" />}
          {/* Matches Lists */}

          {/* Outright Lists */}
          {page === "top-outrights" && <MainTopOutrights />}
          {/* Outright Lists */}

          {/* Leaderboard */}
          {page === "Activities" && <MainActivities />}
          {/* //Leaderboard */}
        </div>
      </Responsive>

      <Responsive from="lg">
        <CategoryNav root={"sports"}  />
        <div className="container">
          {/* Matches Lists */}
          {<MainTopMatches page="home" />}
          {/* Matches Lists */}

          {/* Leaderboard */}
          {<MainActivities />}
          {/* //Leaderboard */}
        </div>
      </Responsive>
    </Fragment>
  );
}
