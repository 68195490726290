import { MainLayoutPublib } from "components/layouts/MainLayoutPublib";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import Login from "../page/Login";

const routes: AppRouteType[] = [
  {
    path: slugs.login,
    component: Login,
    container: MainLayoutPublib,
    isAuthNeeded: false,
  },
];

export default routes;
