const DOTA = ({ classes = ''}:{ classes: string }) => {
	return (
		<svg
			data-editor-id="navbarIcon"
			data-cy="sport-dota2"
			width="24"
			height="24"
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			className={`${classes ? classes : 'h-6 w-6 rounded-full'}`}
		>
			<path d="M4 24.6227C4 24.7023 4.03161 24.7786 4.08787 24.8349L7.16514 27.9121C7.2214 27.9684 7.29771 28 7.37728 28H13.0348C13.302 28 13.4359 27.6769 13.2469 27.4879L4.51213 18.7531C4.32314 18.5641 4 18.698 4 18.9652V24.6227Z"></path>
			<path d="M22.4885 27.8944C22.5455 27.9614 22.629 28 22.7169 28H26.6555C26.8048 28 26.9313 27.8902 26.9525 27.7424L27.9749 20.5853C27.9904 20.4769 27.9457 20.3688 27.8582 20.303L6.37688 4.1565C6.25745 4.06672 6.09015 4.07853 5.9845 4.18418L4.19574 5.97293C4.08518 6.08349 4.07808 6.26042 4.1794 6.37949L16.9105 21.3397C16.9407 21.3752 16.9787 21.4031 17.0216 21.4214L19.0496 22.2838C19.1365 22.3208 19.201 22.3965 19.2235 22.4882L19.7504 24.6281C19.7616 24.6733 19.7831 24.7153 19.8132 24.7508L22.4885 27.8944Z"></path>
			<path d="M18.9652 4C18.698 4 18.5641 4.32314 18.7531 4.51213L27.4879 13.2469C27.6769 13.4359 28 13.302 28 13.0348V7.37728C28 7.29771 27.9684 7.2214 27.9121 7.16514L24.8349 4.08787C24.7786 4.03161 24.7023 4 24.6227 4H18.9652Z"></path>
		</svg>
	);
}

export default DOTA;
