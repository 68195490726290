import React from "react";
import LogoApp from "components/organisms/Logo";
import BgVideo from "assets/wallpapers/soccer.mp4";

export default function AppLoading() {
  return (
    <>
      <div
        data-theme="dark"
        className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
      >
        <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen bg-cover">
          <video
            className="object-cover w-full h-screen"
            autoPlay
            playsInline
            loop
            muted
          >
            <source type="video/webm" src={BgVideo} />
          </video>
        </div>

        <div className="main-layout">
          <div className="w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative">
            <div className="mx-auto w-full max-w-screen-sm md:max-w-sm text-center p-6 pb-8 lg:p-0">
              <div className="flex flex-col gap-6">
                <div className="mx-auto scale-150 animate-pulse">
                  <LogoApp />
                </div>
                {/* <div className="mx-auto text-base">
                  The first decentralized Sportsbook on zkSync Era
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
