import { Disclosure } from "@headlessui/react";
import React, { Fragment } from "react";
import utils, {
  ConvertString2BN,
  currencyFormat,
  floatFormat,
  formatBigNumber,
} from "common/utils";
import SystemMode from "./system";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";
import { useSnapshot } from "valtio";
import { configProxy } from "proxy-state/configProxy";
import basketball from "assets/icons/basketball.svg";
import baseball from "assets/icons/baseball-bat-ball.svg";
import mma from "assets/icons/hand-fist.png";
import soccer from "assets/icons/futbol.svg";

const defaultImg: any = {
  basketball: basketball,
  baseball: baseball,
  mma: mma,
  soccer: soccer,
};

const ParlayBet = (props: any) => {
  const { item, type, potentialWin } = props;

  const { symbol } = useSnapshot(configProxy);
  const comboData = type === "wager" ? item?.orderData : item.outcomeInfo;
  if (!comboData) return null;
  const _wagers = item?.wagers || [];
  let betNumber = 1;
  if (item?.type === "system") {
    betNumber = _wagers.length;
  } else if (item?.type === "single") {
    betNumber = comboData.length;
  }
  let flagTeam: any = [];

  let flagTeamLenght: any = 0;
  if (type === "wager") {
    const _flagTeam = item?.flagTeam?.reduce(
      (acc: Array<string>, cur: string) => {
        return acc.length && acc?.includes(cur) ? acc : [...acc, cur];
      },
      []
    );
    flagTeam = _flagTeam?.slice(0, 5);
    flagTeamLenght = _flagTeam?.length;
  } else {
    const _flagTeam = item?.outcomeInfo?.map((outcome: any) =>
      outcome?.flagTeam ? [...outcome?.flagTeam] : []
    );
    const __flagTeam = _flagTeam.reduce(
      (acc: Array<string>, cur: Array<string>) =>
        (acc?.[0] === cur?.[0] && acc?.[1] === cur?.[1]) || !cur
          ? acc
          : [...acc, ...cur],
      []
    );
    flagTeam = __flagTeam?.slice(0, 5);
    flagTeamLenght = __flagTeam?.length;
  }
  const singlePotential = (oucomeId: any, item: any) => {
    const currentWager = item?.wagers?.find((wager: any) =>
      wager?.outcomeIds?.includes(oucomeId)
    );
    const amount =
      (currentWager?.status === 5
        ? currentWager?.redeemable
        : currentWager?.amount) || 0;
    return amount;
  };
  const getSingleOddValue = (potentialWin: number, amount: number) => {
    return potentialWin / amount;
  };

  if (comboData === undefined) return null;

  return (
    <Fragment>
      <div className="px-0 pt-2 flex flex-col">
        <Disclosure
          as="div"
          className="disclosure !bg-transparent"
          defaultOpen={false}
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="disclosure-btn !bg-transparent !px-4 !pt-1 !pb-3">
                <div className={`flex flex-col w-full items-start`}>
                  {/* {type === 'wager' && (
										<div className="flex justify-between items-center w-full text-xs">
											<span className="text-primary font-semibold">PARLAY</span>
											<div className="">
												<span
													className={`uppercase font-semibold ${Number(item?.status) === 4 ? "text-success" : ""} ${Number(item?.status) === 3 ? "text-error" : ""
														} ${Number(item?.status) === 5 ? "text-warning" : ""}`}
												>
													{utils.getStatusOrder(Number(item?.status))}
												</span>
											</div>
										</div>

									)} */}
                  <div className={`flex justify-between w-full pt-1 pb-1`}>
                    <div className="flex flex-col items-start">
                      <span
                        className={`text-base font-semibold w-full truncate`}
                      >
                        <span className="text-2xl">{item?.matchesNumber}</span>
                        <span className="opacity-70 text-sm ml-2">Matches</span>
                      </span>
                    </div>

                    <div className="flex items-center">
                      <div className="isolate flex -space-x-2 shrink-0 mr-2">
                        {flagTeam &&
                          flagTeam.map((item: any, idx: number) => (
                            <Fragment key={idx}>
                              <div className="h-6 w-6 inline-block rounded-full border-base-100 border-2 bg-base-100 ring-1 ring-base-content/20">
                                <img
                                  className={`relative rounded-full z-[${
                                    flagTeam.length - idx
                                  }]`}
                                  src={`https://assets.b365api.com/images/team/b/${
                                    item?.img_id || 0
                                  }.png`}
                                  onLoad={(event: any) => {
                                    if (event?.target?.height <= 1) {
                                      (event?.target as HTMLImageElement).src =
                                        defaultImg[item?.type];
                                    }
                                  }}
                                  alt=""
                                />
                              </div>
                            </Fragment>
                          ))}
                        {flagTeamLenght > 4 && (
                          <span className="relative z-0 inline-block h-6 w-6 rounded-full border-2 border-base-100 bg-base-300 ring-1 ring-base-content/20">
                            <i className="fa-regular fa-ellipsis"></i>
                          </span>
                        )}
                      </div>
                      {!open ? (
                        <i className="fa-solid fa-caret-right text-sm transition-all"></i>
                      ) : (
                        <i className="fa-solid fa-caret-right text-sm rotate-90 transition-all"></i>
                      )}
                    </div>
                  </div>

                  <div className="pt-1 text-xs w-full">
                    <div className="flex justify-between items-center">
                      <span className="text-base-content">
                        <span className="text-base-content text-opacity-70 font-medium">{`${betNumber} bets`}</span>
                      </span>
                      {type === "wager" && (
                        <div className="pl-2 flex items-center text-xs">
                          <span className="text-xs opacity-70 mr-1">
                            Total Odds:
                          </span>
                          <span className="text-base-content text-opacity-70 font-medium">
                            {floatFormat(
                              (item.redeemable ||
                                item.amount ||
                                item.redeemAmount) / item.betAmount
                            )}
                          </span>
                        </div>
                      )}
                      {type !== "wager" && item.type === "combo" && (
                        <div className="pl-2 flex items-center text-xs">
                          <span className="text-xs opacity-70 mr-1">
                            Total Odds:
                          </span>
                          <span className="text-base-content text-opacity-70 font-medium">
                            {floatFormat(potentialWin / item?.total_amount)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="disclosure-panel fadein !px-2 divide-y divide-base-content divide-opacity-10">
                {comboData.map((data: any, idx: any) => {
                  if (data === undefined) return null;
                  return (
                    <div className="py-3" key={idx}>
                      <div className="px-2 py-0 flex flex-col">
                        <div className="flex items-center justify-between text-xs mb-1">
                          <Link
                            to="#"
                            className="text-base-content text-opacity-70 hover:text-primary transition"
                          >
                            <i className={`${data?.leagueIcon} mr-2`}></i>
                            {data?.league}
                          </Link>
                          <span
                            className={`uppercase font-semibold ${
                              Number(data?.status) === 4 ? "text-success" : ""
                            } ${
                              Number(data?.status) === 3 ? "text-error" : ""
                            } ${
                              Number(data?.status) === 5 ? "text-warning" : ""
                            }`}
                          >
                            {Number(data?.status) === 0 && "Open"}
                            {Number(data?.status) === 3 && "Lost"}
                            {Number(data?.status) === 4 && "Won"}
                            {Number(data?.status) === 5 && "Voided"}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <h5 className="w-full truncate">
                            <Link
                              to={
                                data?.eventUrl
                                  ? `${slugs.sports}/${data?.eventUrl.replace(
                                      "sports/",
                                      ""
                                    )}`
                                  : "#"
                              }
                              className="font-semibold hover:text-primary transition"
                            >
                              {data?.eventName}
                            </Link>
                          </h5>
                          <div className="isolate flex -space-x-2 shrink-0">
                            {data?.flagTeam &&
                              data?.flagTeam?.map((item: any, idx: any) => (
                                <Fragment key={idx}>
                                  <div className="h-6 w-6 inline-block rounded-full border-base-100 bg-base-100 ring-1 ring-base-content/20">
                                    <img
                                      className="relative z-[1] rounded-full"
                                      src={`https://assets.b365api.com/images/team/b/${
                                        item?.img_id || 0
                                      }.png`}
                                      alt=""
                                      onLoad={(event: any) => {
                                        if (event?.target?.height <= 1) {
                                          (
                                            event?.target as HTMLImageElement
                                          ).src = defaultImg[item?.type];
                                        }
                                      }}
                                    />
                                  </div>
                                </Fragment>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="px-2 pt-1 text-xs">
                        <div className="flex justify-between items-center">
                          <span className={`text-base-content text-opacity-70`}>
                            <span className="">{data?.name}:</span>
                            <span className={`font-semibold ml-1`}>
                              {utils._getOddName(data)}
                            </span>
                          </span>
                          {type !== "wager" && item?.type === "single" && (
                            <span
                              className={`text-base-content text-opacity-70 font-medium`}
                            >
                              {floatFormat(
                                getSingleOddValue(
                                  Number(
                                    singlePotential(
                                      data?.odds?.outcome_id,
                                      item
                                    )
                                  ),
                                  data?.amount
                                ),
                                2
                              )}
                            </span>
                          )}
                        </div>
                        {item?.type === "single" && (
                          <>
                            <div className="flex justify-between items-center mt-1">
                              <span className="text-base-content text-opacity-70">
                                <span className="">Order Amount</span>
                              </span>
                              <div className="pl-2 flex items-center text-xs">
                                <span className="text-base-content text-opacity-70 font-medium">
                                  {currencyFormat(
                                    formatBigNumber(
                                      ConvertString2BN(data?.amount || 0),
                                      true
                                    )
                                  )}{" "}
                                  {symbol}
                                </span>
                              </div>
                            </div>
                            <div className="flex justify-between items-center mt-1">
                              <span className="text-base-content text-opacity-70">
                                <span className="">
                                  {data?.status === 4 ? "Won" : "Potential Win"}
                                </span>
                              </span>
                              <div className="pl-2 flex items-center text-xs">
                                <span className="text-base-content text-opacity-70 font-medium">
                                  {floatFormat(
                                    Number(
                                      formatBigNumber(
                                        ConvertString2BN(
                                          singlePotential(
                                            data?.odds?.outcome_id,
                                            item
                                          )
                                        ),
                                        true
                                      )
                                    )
                                  )}{" "}
                                  {symbol}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
                {item?.type === "system" && <SystemMode item={item} />}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </Fragment>
  );
};
export default ParlayBet;
