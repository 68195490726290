import { createContext, useContext, useReducer } from "react";

interface Home {
  id: number;
  text: string;
}
export const HomeContext = createContext<Home[]>([]);
export const HomeDispatchContext = createContext<any>({});

export default function HomeProvider({ children }: any) {
  const [home, dispatch] = useReducer(homeReducer, initialhome);

  return (
    <HomeContext.Provider value={home}>
      <HomeDispatchContext.Provider value={dispatch}>
        {children}
      </HomeDispatchContext.Provider>
    </HomeContext.Provider>
  );
}

export function useHome() {
  return useContext(HomeContext);
}

export function useHomeDispatch() {
  return useContext(HomeDispatchContext);
}

function homeReducer(home: any, action: any) {
  switch (action.type) {
    case "added": {
      return [
        ...home,
        {
          id: action.id,
          text: action.text,
        },
      ];
    }
    case "changed": {
      return home.map((t: any) => {
        if (t.id === action.task.id) {
          return action.task;
        } else {
          return t;
        }
      });
    }
    case "deleted": {
      return home.filter((t: any) => t.id !== action.id);
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

const initialhome = [
  { id: 0, text: "text 1" },
  { id: 1, text: "text 2" },
  { id: 2, text: "text 3" },
];
