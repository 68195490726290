import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/globals.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { WagmiConfig } from "wagmi";
import initWagmiClient from "utils/wagmi/client";
import { BrowserRouter } from "react-router-dom";
import { Buffer } from "buffer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "react-error-boundary";
import { UpdateVersion } from "components/commons/UpdateVersion";

// Ref: https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
// @ts-ignore
window.Buffer = Buffer;

Sentry.init({
  dsn:
    process.env.REACT_APP_APP_ENV === "production"
      ? "https://7a0c86b998b08800ceef1af699222ecb@o4506337151549440.ingest.sentry.io/4506337154236416"
      : "",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { config: wagmiClient } = initWagmiClient();
console.log("Wagmi Client Config", wagmiClient);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function fallbackRender({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) {
  return <UpdateVersion version="4.0.4"></UpdateVersion>;
}

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiClient}>
      <ErrorBoundary fallbackRender={fallbackRender}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
        >
          <App />
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
