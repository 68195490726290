import React, { Fragment } from "react";
import { InvitationCode } from "components/commons/LandingPage/invitationCode";
export default function RefCode() {
  return (
    <Fragment>
      <main className="h-full flex-1">
        <div className="container h-full !p-0">
          <InvitationCode />
        </div>
      </main>
    </Fragment>
  );
}
