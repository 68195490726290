import clsx from "clsx";
import { leaderBoardFilter } from "proxy-state/leaderBoardFilter";
import { useState } from "react";
import { ELeaderBoardFilter, IOrderBy } from "shared/enum";
import { useSnapshot } from "valtio";

function LeaderBoardFilterModal() {
  const [modalShow, setModalShow] = useState<boolean>(false);

  const { filterType, orderBy } = useSnapshot(leaderBoardFilter);

  return (
    <>
      <input
        type="checkbox"
        id="generic-filter--modal"
        className="modal-toggle"
        checked={modalShow}
        onChange={(e) => setModalShow(e.target.checked)}
      ></input>
      <label
        htmlFor="generic-filter--modal"
        className={`modal modal-bottom sm:modal-middle${
          modalShow ? "" : " -z-10 hidden"
        }`}
      >
        <label className="modal-box relative overflow-auto lg:max-w-md transition-all">
          <div className="h-full flex flex-col w-full overflow-y-auto max-h-[calc(var(--vh,1vh)*100)]">
            <div className="modal-box--header justify-between">
              <h3 className="">Sort by</h3>
              <label
                htmlFor="generic-filter--modal"
                className="btn btn-circle btn-sm btn-ghost"
              >
                <i className="fa-regular fa-xmark text-lg"></i>
              </label>
            </div>

            <div className="modal-box--body flex flex-col gap-2 !px-4">
              <div className="space-y-4">
                <div className="flex flex-col bg-base-100 p-2 rounded-box">
                  <button
                    className={`flex items-center justify-between py-4 px-4 rounded-box z-[1] ${
                      filterType === ELeaderBoardFilter.wagerer
                        ? ""
                        : "text-base-content/50 hover:text-base-content"
                    }`}
                    onClick={() => {
                      setModalShow(false);
                      leaderBoardFilter.filterChanged = true;
                      leaderBoardFilter.filterType = ELeaderBoardFilter.wagerer;
                    }}
                  >
                    <span className="flex items-start">Wagered</span>
                    {filterType === ELeaderBoardFilter.wagerer && (
                      <span className="flex items-center">
                        <i className="fa-solid fa-check"></i>
                      </span>
                    )}
                  </button>

                  <span
                    className="ml-2 mr-2 h-[1px] bg-base-content/10 -mt-[1px] z-0"
                    role="separator"
                  ></span>

                  <button
                    className={`flex items-center justify-between py-4 px-4 rounded-box z-[1] ${
                      filterType === ELeaderBoardFilter.winning
                        ? ""
                        : "text-base-content/50 hover:text-base-content"
                    }`}
                    onClick={() => {
                      setModalShow(false);
                      leaderBoardFilter.filterChanged = true;
                      leaderBoardFilter.filterType = ELeaderBoardFilter.winning;
                    }}
                  >
                    <span className="flex items-start">Winning</span>
                    {filterType === ELeaderBoardFilter.winning && (
                      <span className="flex items-center">
                        <i className="fa-solid fa-check"></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex flex-col bg-base-100 p-2 rounded-box">
                  <button
                    onClick={() => {
                      setModalShow(false);
                      leaderBoardFilter.filterChanged = true;
                      leaderBoardFilter.orderBy = IOrderBy.ASC;
                    }}
                    className={`flex items-center justify-between py-4 px-4 rounded-box z-[1] ${
                      orderBy === IOrderBy.ASC
                        ? ""
                        : "text-base-content/50 hover:text-base-content"
                    }`}
                  >
                    <span className="flex items-start">
                      <span className="flex items-center justify-center w-6 mr-4 mt-1">
                        <i className="fa-solid fa-arrow-up text-lg"></i>
                      </span>
                      Sort Ascending
                    </span>
                    {orderBy === IOrderBy.ASC && (
                      <span className="flex items-center">
                        <i className="fa-solid fa-check"></i>
                      </span>
                    )}
                  </button>

                  <span
                    className="ml-14 mr-2 h-[1px] bg-base-content/10 -mt-[1px] z-0"
                    role="separator"
                  ></span>

                  <button
                    onClick={() => {
                      setModalShow(false);
                      leaderBoardFilter.filterChanged = true;
                      leaderBoardFilter.orderBy = IOrderBy.DESC;
                    }}
                    className={`flex items-center justify-between py-4 px-4 rounded-box z-[1] ${
                      orderBy === IOrderBy.DESC
                        ? ""
                        : "text-base-content/50 hover:text-base-content"
                    }`}
                  >
                    <span className="flex items-start">
                      <span className="flex items-center justify-center w-6 mr-4 mt-1">
                        <i className="fa-solid fa-arrow-down text-lg"></i>
                      </span>
                      Sort Descending
                    </span>
                    {orderBy === IOrderBy.DESC && (
                      <span className="flex items-center">
                        <i className="fa-solid fa-check"></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </label>
      </label>
    </>
  );
}

export { LeaderBoardFilterModal };
