import React from "react";

const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-2">
      <i className="fas fa-circle-notch fa-spin text-lg"></i>
      <span className="opacity-70 mt-1 text-xs">Loading</span>
    </div>
  );
};
export default Loading;
