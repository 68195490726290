import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import {
  ConvertString2BN,
  currencyFormat,
  floatFormat,
  formatBigNumber,
} from "common/utils";
import { handleClaimedV2 } from "functions/actionClaim";
import { ParlayBet, SingleBet } from "../betCard";
import { BigNumber } from "ethers";
import { ThemeContext } from "contexts/themeContext";
import { ReactComponent as LogDark } from "assets/logo/logo-dark-sm-goal3.svg";
import { ReactComponent as LogLight } from "assets/logo/logo-light-sm-goal3.svg";
import { eventProxy } from "proxy-state/eventProxy";
import { leagueProxy } from "proxy-state/leagueProxy";
import { findById } from "utils/proxy";
import { League, UserWagers } from "interfaces/firebase";
import { configProxy } from "proxy-state/configProxy";
interface detailProps {
  item: UserWagers;
  loadingClaim: boolean;
  type: string;
  wagerIdsClaimed: any[];
  minAmountFreeGas: number;
  networkFee: any;
}
const WagerDetail = (props: detailProps) => {
  const { theme } = React.useContext(ThemeContext);
  const {
    item,
    loadingClaim,
    type,
    wagerIdsClaimed,
  } = props;
  const { symbol } = useSnapshot(configProxy);
  const { events } = useSnapshot(eventProxy);
  const { data: leagues } = useSnapshot(leagueProxy);
  const [loading, setLoading] = React.useState(false);
  const [updateStatus, setUpdateStatus] = React.useState<boolean>(false);
  const WEI6 = BigNumber.from(10).pow(6).toNumber();
  const [wagerIdClaimed, setWagerIdClaimed] = React.useState<any[]>([]);
  const handleClaimedBtn = async (ids: UserWagers[], zkFee: number) => {
    try {
      if (!ids.length) {
        toast.error("No wager to claim!");
        return;
      }
      const _ids = ids.filter((id) => id?.outcomeIds?.length === 0);
      if (_ids.length) {
        toast.warning("Invalid wager!");
        return false;
      }
      setLoading(true);

      await handleClaimedV2(ids, setLoading, setWagerIdClaimed);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log("Error: ", error);
      toast.error(error.message);
    }
  };

  const calculatorPayOut = (amount: number) => {
    return floatFormat(amount / WEI6);
  };

  useEffect(() => {
    if (updateStatus) {
      item.isRedeemed = true;
      item.redeemAmount = item.amount || item.redeemable;
      item.amount = 0;
      item.redeemable = 0;
      setUpdateStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  useEffect(() => {
    if (wagerIdsClaimed.includes(item?.wager_id)) {
      setUpdateStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wagerIdsClaimed]);

  const flagTeam: any = [];
  let verified: any = 0;
  let wagerStatus = 4;
  const matchesNumber: Array<string> = [];
  const outcomeIds = item?.outcomeIds;

  const outComeData =
    outcomeIds &&
    outcomeIds?.map((id: any) => {
      const event = events?.find((event: any) => {
        return event?.allMarkets?.find((market: any) =>
          market?.odds?.find((outcome: any) => outcome?.outcome_id === id)
        );
      });

      if (event === undefined) return null;
      const league = findById(leagues as League[], event?.league_id);

      matchesNumber.push(event?.id!);
      const item = event?.allMarkets?.find((market: any) =>
        market?.odds?.find((outcome: any) => outcome?.outcome_id === id)
      );
      const odds = item?.odds?.find(
        (outcome: any) => outcome?.outcome_id === id
      );
      const eventUrl = `${league?.url_path}/${event?.url_key}`;
      verified = event?.verified || event?.cancelled || 0;
      const oddStatus = item?.win_outcome_id
        ? item?.win_outcome_id === id
          ? 4
          : 3
        : ([4, 5].includes(Number(event?.time_status)) || event?.cancelled)
        ? 5
        : 0;
      if (oddStatus === 0 && wagerStatus !== 3) wagerStatus = 0;
      if (oddStatus === 3) wagerStatus = 3;
      flagTeam.push({
        img_id: event?.home?.image_id,
        type: league?.category_id,
      });
      flagTeam.push({
        img_id: event?.away?.image_id,
        type: league?.category_id,
      });

      const data = {
        ...item,
        marketName: item?.name,
        marketKey: item?.key,
        marketId: item?.id,
        eventUrl: eventUrl,
        odds: odds,
        league_id: event?.league_id,
        league: league?.name,
        leagueIcon: league?.icon_classes,
        eventName: event?.home?.name + " vs " + event?.away?.name,
        event_url: event?.url_key,
        flagTeam: [
          {
            img_id: event?.home?.image_id,
            type: league?.category_id,
          },
          {
            img_id: event?.away?.image_id,
            type: league?.category_id,
          },
        ],
        home: event?.home?.name,
        away: event?.away?.name,
        status: oddStatus,
        verified: verified,
      };
      return data;
    });
  if (!outComeData) return null;
  const data: any = {
    ...item,
    matchesNumber: [...matchesNumber].length,
    verified: verified,
    orderData: outComeData,
    // status: wagerStatus,
    flagTeam: flagTeam,
  };

  const isDataNotReady = data.betAmount === undefined;
  const isSingle = data?.orderData?.length === 1;
  const isParlay = data?.orderData?.length > 1;

  return (
    <div className={`bet bet-NFT group`}>
      <div className="bet-header text-xs">
        <div className="flex items-center">
          <div className="-mt-[1px]">
            {theme === "light" ? (
              <>
                <LogLight width={16} height={16} className="h-4" />
              </>
            ) : (
              <LogDark width={16} height={16} className="h-4" />
            )}
          </div>
          <span className="mx-1 opacity-70"></span>
          <div className="">
            <span className="text-base-content uppercase font-semibold">
              {outComeData.length === 1 && "Single"}
              {outComeData.length > 1 && "Parlay"}
            </span>
          </div>
        </div>

        <div className="">
          {isDataNotReady && (
            <span className="uppercase font-semibold opacity-70">
              <i className="fa-solid fa-spin fa-circle-notch mr-1 text-xs"></i>
              Pending
            </span>
          )}
          {!isDataNotReady && isSingle && (
            <span
              className={`uppercase font-semibold ${
                item.status === 4 ? "text-success" : ""
                } ${item.status === 3 ? "text-error" : ""} ${
                item.status === 5 ? "text-accent" : ""
              }`}
            >
              {data.status === 0 && "Open"}
              {data.status === 4 && "Won"}
              {data.status === 3 && "Lost"}
              {data.status === 5 && "Voided"}
            </span>
          )}
          {/* {item.isSubmitting && (
            <span className="uppercase font-semibold opacity-70">
              <i className="fa-solid fa-spin fa-circle-notch mr-1 text-xs"></i>
              Pending
            </span>
          )} */}
          {isParlay && (
            <>
              <span
                className={`uppercase font-semibold ${
                  data.status === 4 ? "text-success" : ""
                } ${data.status === 3 ? "text-error" : ""} ${
                  data.status === 5 ? "text-accent" : ""
                }`}
              >
                {data.status === 0 && "Open"}
                {data.status === 4 && "Won"}
                {data.status === 3 && "Lost"}
                {data.status === 5 && "Voided"}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="bet-body">
        {outComeData.length === 1 && <SingleBet item={data} type={"wager"} />}
        {outComeData.length > 1 && <ParlayBet item={data} type={"wager"} />}

        <div className="mx-4 py-4 space-y-1 border-t border-dashed border-base-content border-opacity-10">
          <div className="px-0 flex justify-between items-center">
            <span className="text-xs text-base-content text-opacity-70">
              Order Amount
            </span>
            <div className="pl-2 flex items-center text-xs font-medium">
              {isDataNotReady
                ? 0
                : currencyFormat(
                    formatBigNumber(
                      data.betAmount > 1
                        ? ConvertString2BN(data.betAmount)
                        : data.betAmount,
                      true
                    )
                  )}
              <span className="ml-1 font-medium">{symbol}</span>
            </div>
          </div>
          <div className="px-0 flex justify-between items-center">
            {/* {data?.status === 4 && data?.amount > 0 && (
							<>
								<span className="text-xs text-base-content text-opacity-70">Available Win</span>
								<div className="pl-2 flex items-center text-xs font-medium">
									<span className={`${Number(data?.status) === 3 ? 'line-through' : ''}`}>{calculatorPayOut(data?.amount)}</span>
									<span className="ml-1 font-medium">{symbol}</span>
								</div>
							</>
						)} */}

            {data?.status !== 4 && (
              <>
                <span className="text-xs text-base-content text-opacity-70">
                  Potential Win
                </span>
                <div className="pl-2 flex items-center text-xs font-medium">
                  <span
                    className={`${
                      Number(data?.status) === 3 ? "line-through" : ""
                    }`}
                  >
                    {calculatorPayOut(
                      Number(data?.redeemable) > 0
                        ? data?.redeemable
                        : data?.amount
                        ? data?.amount
                        : 0
                    )}
                  </span>
                  <span className="ml-1 font-medium">{symbol}</span>
                </div>
              </>
            )}
          </div>
          {data?.status === 4 &&
            (data?.amount > 0 || data?.redeemAmount > 0) && (
              <div className="px-0 flex justify-between items-center">
                <span className="text-sm font-medium">You Won</span>
                <div className="pl-2 flex items-center text-sm font-semibold text-primary">
                  <span>
                    {calculatorPayOut(
                      data?.redeemable || data?.amount || data?.redeemAmount
                    )}
                  </span>
                  <span className="ml-1 font-semibold">{symbol}</span>
                </div>
              </div>
            )}
        </div>
      </div>
      {Number(data?.status) > 3 && type === "wager" && (
        <div className="bet-footer">
          {Number(data?.status) === 4 && (
            <>
              {(data?.claimed_txn || Number(data?.amount) === 0) && (
                <div className="">
                  <button className="btn btn-md btn-block disabled">
                    Claimed
                  </button>
                </div>
              )}
              {Number(data?.amount) !== 0 && data?.verified !== 0 && (
                <div className="">
                  <button
                    type="button"
                    className={`btn btn-sm btn-block h-auto min-h-0 py-3 relative ${
                      loadingClaim || loading
                        ? " btn-disabled cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() => handleClaimedBtn( [data], 0 ).then() }
                  >
                    {(loadingClaim || loading) && (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-base-content animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Claim {calculatorPayOut(data?.redeemable || data?.amount)}{" "}
                    {symbol}
                  </button>
                </div>
              )}
              {Number(data?.amount) !== 0 && data?.verified !== 1 && (
                <div
                  className="w-full tooltip tooltip-top"
                  data-tip={
                    "Resolution may take up to 30 minutes after match ends"
                  }
                >
                  <button className="btn btn-md btn-block disabled">
                    Resolving
                  </button>
                </div>
              )}
            </>
          )}

          {Number(data?.status) === 5 && (
            <div className="">
              <button
                className={`btn btn-sm btn-block h-auto min-h-0 py-3 relative ${
                  loadingClaim || loading || data?.amount === 0
                    ? " btn-disabled cursor-not-allowed"
                    : ""
                }`}
                onClick={() => handleClaimedBtn([data], 0).then()}
              >
                {(loadingClaim || loading) && (
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-base-content animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Refunded{" "}
                {currencyFormat(Number(data?.redeemable || data?.redeemAmount) / WEI6, false)}{" "}
                {symbol}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default WagerDetail;
