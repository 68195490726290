import { Footer } from "components/shared/Footer";
import { useSnapshot } from "valtio";
import { modalFunc, modalProxy } from "proxy-state/global";
import React from "react";
const AboutUsModal:React.FC = () => {

	const { isFooterOpen } = useSnapshot(modalProxy)
	return (
		<>
			<input
				type="checkbox"
				id="about-us--modal"
				className="modal-toggle"
				checked={isFooterOpen}
				onChange={(e) => {
					e.target.checked ? document.body.classList.add("has-modal") : document.body.classList.remove("has-modal");
					!e.target.checked && modalFunc.setModalOpen("isFooterOpen", false);
				}}
			></input>
			<label htmlFor="about-us--modal" className={`modal modal-bottom sm:modal-middle${isFooterOpen ? '' : ' -z-10 hidden'}`}>
				<label className="modal-box relative overflow-auto md:max-w-lg transition-all">
					<div className="h-full flex flex-col w-full overflow-y-auto max-h-[calc(var(--vh,1vh)*100)]">
						<div className="modal-box--header justify-between">
							<h3 className="">About Us</h3>
							<label htmlFor="about-us--modal" className="btn btn-circle btn-sm btn-ghost">
								<i className="fa-regular fa-xmark text-lg"></i>
							</label>
						</div>

						<div className="pt-4">
							<Footer />
						</div>
					</div>
				</label>
			</label>
		</>
	);
}

export default AboutUsModal;
