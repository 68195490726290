import { proxy } from "valtio";
import { onSnapshot, query, where } from "firebase/firestore";
import { categoriesRef } from "../utils/firebase/firebaseDBRef";
import { Category } from "../interfaces/firebase";
import _ from "lodash";

let unsubscribe: any;

class CategoryProxy {
  isLoading: boolean = false;
  data: Category[] = [];
  subscribed: boolean = false;

  load() {
    if (this.subscribed) return;
    this.subscribed = true;
    this.isLoading = true;

    const ref = categoriesRef();
    const _query = query(ref, where("status", "==", "published"));
    unsubscribe = onSnapshot(_query, (snapshot) => {
      this.isLoading = false;
      this.data = [];

      snapshot.docs.forEach((doc) => {
        this.data.push({ id: doc?.id, ...doc?.data() } as any);
      });
    });
  }

  get publishedCategories() {
    return _.orderBy(
      this.data.filter((item) => item.status === "published"),
      ["position"],
      ["asc"]
    );
  }

  unsubscribeDb() {
    if (unsubscribe) {
      unsubscribe();
      this.subscribed = false;
    }
  }
}

export const categoryProxy = proxy(new CategoryProxy());
