import moment from "moment";
import { ITimeFilterType } from "shared/enum";

export const getStartTime = (time: ITimeFilterType) => {
  switch (time) {
    case ITimeFilterType.today:
      return moment().subtract(24, "hours").format("YYYY,MM-DD HH:mm:ss");

    case ITimeFilterType.yesterday:
      const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
      return new Date(yesterday).setUTCHours(0, 0, 0, 0);

    case ITimeFilterType.thisWeek:
      const lastWeek = moment().subtract(7, "days").format("YYYY-MM-DD");
      const w_date = new Date(lastWeek);
      return w_date.setUTCHours(0, 0, 0, 0);

    case ITimeFilterType.thisMonth:
      const lastMonth = moment().subtract(30, "days").format("YYYY-MM-DD");
      return new Date(lastMonth);

    default:
      return null;
  }
};
