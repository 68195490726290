import HomeProvider from "contexts/homeContext";
import { MyBets } from "components/organisms/Bets";

export default function MyBet() {
  return (
    <HomeProvider>
      <MyBets />
    </HomeProvider>
  );
}
