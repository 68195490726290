import React, { Fragment, useEffect } from "react";
// import BetsInfo from "./info";
import WagerNFT from "./WagerNFT";
import Summary from "./betSummary";
import { handleClaimedV2 } from "functions/actionClaim";
import { toast } from "react-toastify";
import HistoryLayout from "./OrderHistory/HistoryLayout";
import { Provider } from "zksync-web3";
import { ContractAddress, DataChain } from "contracts/config";
import { myProfileProxy } from "proxy-state/myProfileProxy";
import { getContract } from "contracts/Contract";
import { WEI6 } from "functions/betUtils";
import { useSnapshot } from "valtio";
import { CHAIN_ID } from "constant";
import Toolbar from "./toolbar";
import { IMyBetLayout } from "shared/enum";
import { BetFilterProxy } from "proxy-state/betting/myBetFilter";
import { UserWagers } from "interfaces/firebase";


const MyBets = () => {
  const myProfile = useSnapshot(myProfileProxy);
  const address = myProfile?.address;
  const {MyBetLayout, setMyBetLayout} = useSnapshot(BetFilterProxy);
  const [loadingClaim, setLoadingClaim] = React.useState<boolean>(false);
  const [wagerIdsClaimed, setWagerIdsClaimed] = React.useState<string[]>([]);
  const [minAmountFreeGas, setMinAmountFreeGas] = React.useState<number>();

  const handleClaimedBtn = async (ids: UserWagers[], zkFee: number) => {
    try {
      const wagerIds = ids.map((id) => id.wager_id);
      console.log("run here 123123123", wagerIds);
      if (!wagerIds.length) {
        toast.error("No wager to claim!");
        return;
      }
      if (!ids.length) {
        toast.error("No wager to claim!");
        return;
      }
      const _ids = ids.filter((id) => id?.outcomeIds?.length > 0);
      if (!_ids.length) {
        toast.warning("Invalid wager!");
        return false;
      }
      setLoadingClaim(true);
      const txHash = await handleClaimedV2(
        ids,
        setLoadingClaim,
        setWagerIdsClaimed
      );
      setLoadingClaim(false);
    } catch (error: any) {
      setLoadingClaim(false);
      console.log("Error: ", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const getMinAmountFreeGas = async () => {
      //TODO: get min amount from wager
      const provider = new Provider(DataChain[CHAIN_ID]["NEXT_PUBLIC_RPC_URL"]);
      const paymasterContract = getContract(
        "PaymasterRedeem",
        ContractAddress[CHAIN_ID].PaymasterRedeem,
        provider
      );
      const wagerAddress = `0x${ContractAddress[CHAIN_ID].Wager.replace(
        /^0x/,
        ""
      )}`;
      const wagerContract = getContract("Wager", wagerAddress, provider);
      const iface = wagerContract.interface;
      const _minAmountFreeGas = await paymasterContract.callStatic.getMinAmount(
        wagerContract.address,
        iface.getSighash(iface.getFunction("redeem"))
      );

      const minAmount = _minAmountFreeGas.toNumber() / WEI6.toNumber();
      setMinAmountFreeGas(minAmount);
    };
    console.log("address ====>", address);

    if (address) {
      getMinAmountFreeGas().then();
    }
  }, [address]);

  const summaryProps = {
    handleClaimed: handleClaimedBtn,
    claimLoading: loadingClaim,
    setCurrentTab: setMyBetLayout,
    minAmountFreeGas: 20,
  };

  return (
    <Fragment>
      {/* <Screen upto="md">
        <Tabs tab={tabOpen} setTab={setTabOpen} tabList={TabList} />
      </Screen> */}
      {/* Orders List */}
      <div className={`container h-full`}>
        <div className="mt-2">
          <Summary {...summaryProps} />
          <div className="section">
            <Toolbar />
            {MyBetLayout === IMyBetLayout.BETS && (
              <>
                <WagerNFT
                  claimLoading={loadingClaim}
                  wagerIdsClaimed={wagerIdsClaimed}
                  minAmountFreeGas={0}
                  networkFee={0}
                />
              </>
            )}
            {MyBetLayout === IMyBetLayout.HISTORY && <HistoryLayout /> }
          </div>
        </div>
        
      </div>
    </Fragment>
  );
};
export default MyBets;
