import slugs from "navigation/slugs";
import {
  NotiElement as NotiProps,
  notiState,
  notitficationState,
  tabFilter,
} from "proxy-state/notificationProxy";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import jwt_decode from "jwt-decode";
import NotiElement from "../components/NotiElement";
import NotificationEmpty from "../components/NotiEmpty";
import { NotiSkeleton } from "../components/NotiSkeleton";
import { getItem } from "local-storage";
import Tabs from "components/organisms/Tabs";
import { notiApis } from "utils/apis";
import { useOneSignal } from "hooks/useOneSignal";
import Loading from "components/organisms/Loading";
import NotiHeader from "../components/NotiHeader";
import { modalProxy } from "proxy-state/global";
import clsx from "clsx";

export enum TabActive {
  ALL = "All",
  UNREAD = "Unread",
}

export default function NotificationCenter() {
  const { isAllowed, isLoading: isLoadingOneSignal } = useOneSignal();
  const navigate = useNavigate();
  const headerClass =
    "fixed flex flex-col justify-start flex-shrink-0 top-0 left-0 right-0 w-full h-14 z-40 bg-base-300/80 backdrop-blur";

  const [tabOpen, setTabOpen] = React.useState<TabActive>(TabActive.ALL);
  const TabList = [
    { name: "All", key: TabActive.ALL },
    { name: "Unread", key: TabActive.UNREAD },
  ];

  const [list, setList] = useState<Array<NotiProps>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { filter } = useSnapshot(notiState);
  const { data: notiData } = useSnapshot(notiState);
  const accessToken = getItem("access_token") || "";
  let address = "";
  if (accessToken) {
    const decode: any = jwt_decode(accessToken);
    address = decode.sub;
  }
  useEffect(() => {
    if (!address) return;
    const getNoti = async () => {
      try {
        setLoading(true);
        const res = await notiApis.getNotifications(address as string);
        setList(res);
        notitficationState.setData(res);
        return true;
      } catch (error) {
        console.log("====================================");
        console.log("error ====>", error);
        console.log("====================================");
        return [];
      } finally {
        setLoading(false);
      }
    };
    getNoti().then();
  }, [address]);

  useEffect(() => {
    if (filter === tabFilter.unread) {
      setList(notiData?.filter((item: any) => item.status === "read"));
    } else {
      setList(notiData as Array<NotiProps>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, notiData]);

  const handleGoToSetting = () => {
    notitficationState.setShow(false);
    modalProxy.isProfileOpen = true;
    modalProxy.isNotiSettingOpen = true;
  };

  if (isLoadingOneSignal) return <Loading />;

  if (!isAllowed && list?.length === 0 && !loading) {
    return (
      <div className="grid grid-cols-1">
        <div className="mt-6">
          <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
            <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
            <span className="text-lg font-bold">Notifications is disabled</span>
            <span className="text-xs text-center mt-4 italic">
              <button
                className="btn btn-block btn-primary"
                onClick={handleGoToSetting}
              >
                Go to settings{" "}
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {!isAllowed && (
        <div className="px-3 mt-3">
          <div className="flex justify-between items-center w-full px-2 py-2 border-[1px] border-primary hover:border-primary rounded-md bg-transparent hover:bg-transparent text-sm text-base-content/70">
            <div>
              <i className="fa-regular fa-circle-info"></i>
              <span className="ml-2">Your push notification is disabled</span>
            </div>
            <div className="cursor-pointer" onClick={handleGoToSetting}>
              Setting 👉
            </div>
          </div>
        </div>
      )}

      <NotiHeader />

      {loading && (
        <>
          <div className="flex flex-col w-full">
            {Array.from(new Array(10)).map((_, idx) => (
              <NotiSkeleton key={idx} />
            ))}
          </div>
        </>
      )}
      {list?.length === 0 && !loading && <NotificationEmpty />}
      {!loading && list?.length > 0 && (
        <div
          className={clsx("overflow-y-auto", {
            "h-[calc(90vh-152px)] md:h-[calc(70vh-152px)]": !isAllowed,
            "h-[calc(90vh-122px)] md:h-[calc(70vh-102px)]": isAllowed,
          })}
        >
          <div className="flex flex-col  gap-2 p-4">
            {list?.map((notification: any, idx: number) => (
              <NotiElement data={notification} idx={idx} key={idx} />
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
}
