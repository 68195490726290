import React from "react";

import { ThemeContext } from "contexts/themeContext";
import { Partners } from "./Partners";
import { ReactComponent as LogoSvg } from "assets/logo/logo-dark-goal3.svg";
import { ReactComponent as LogoSvgLight } from "assets/logo/logo-light-goal3.svg";

function Footer({
  setFooterPage,
}: {
  setFooterPage?: (param: boolean) => void;
}) {
  const { theme } = React.useContext(ThemeContext);

  return (
    <>
      <div className="lg:hidden sticky top-0 left-0 right-0 px-4 md:px-6 py-5 z-50 bg-base-200/80 backdrop-blur text-center">
        <button
          className="btn btn-sm rounded-full btn-ghost mr-2 absolute left-2 top-4 gap-2 text-primary font-normal text-base"
          onClick={() => setFooterPage?.(false)}
        >
          <i className="fa-solid fa-angle-left text-xl"></i>
          Back
        </button>
        <div className="inline-flex text-base font-semibold">
          <h3>About Us</h3>
        </div>
      </div>

      <footer
        id="footer"
        className={
          "text-left h-full lg:max-h-[500px] overflow-y-auto overflow-x-hidden p-4"
        }
      >
        <div
          className={
            "flex flex-col text-xs leading-relaxed text-base-content/70 px-2"
          }
        >
          <div className={"flex flex-col-reverse"}>
            <div className={"flex flex-col items-start mt-12"}>
              <div className="">
                {theme === "light" ? (
                  <LogoSvgLight className="h-6 w-24" />
                ) : (
                  <LogoSvg className="h-6 w-24" />
                )}
              </div>

              <div className="mt-6">
                Goal3 is solely an interface designed to facilitate user
                interaction, data display, and visualization with a
                decentralized protocol on zkSync network. It does not represent
                any bookmaker or house controlled by a single entity.
              </div>

              <div className="mt-3">
                We hereby disclaim any responsibility for any transactions or
                activities undertaken by users through the Goal3 interface, as
                we do not have control over the protocol or its outcomes. Users
                are advised to conduct their own research and exercise caution
                when engaging in any activities on the platform. We shall not be
                held liable for any losses or damages incurred as a result of
                using the Goal3 interface.
              </div>

              <div className="mt-3">
                By accessing, you also agreed to our{" "}
                <a
                  href="https://docs.goal3.xyz/legal/privacy-and-cookie"
                  target="_blank"
                  rel="noreferrer"
                  className="text-base-content hover:text-primary font-medium"
                >
                  Cookies Policy
                </a>
                .
              </div>

              <div className="mt-3">
                &copy; 2023 Goal3. All Rights Reserved.
              </div>

              <div className="flex flex-col mt-6">
                <a
                  href="https://peckshield.com/"
                  data-theme="dark"
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-col items-center justify-center pt-2 pb-3 px-4 bg-base-300/80 hover:bg-base-300 rounded-box transition"
                >
                  <span className="text-[10px] font-medium tracking-wideer mb-4 bg-success text-success-content px-3 py-0 rounded-full -mt-4 uppercase">
                    Audited by
                  </span>
                  <img
                    alt="PeckShield"
                    className="h-6 flex-shrink-0"
                    src="/logo/peckshield-logo.png"
                  />
                </a>
              </div>
            </div>

            <div className="w-full">
              <Partners />

              <div className="w-full flex gap-6 mt-12">
                <div className="">
                  <h5 className="mb-4 uppercase tracking-wide">About Us</h5>
                  <ul className="space-y-3">
                    <li>
                      <a
                        href="https://docs.goal3.xyz/legal/terms-and-conditions"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-primary"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.goal3.xyz/legal/privacy-and-cookie"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-primary"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.goal3.xyz/legal/betting-rules"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-primary"
                      >
                        Betting Rules
                      </a>
                    </li>
                  </ul>
                </div>

                <div className={""}>
                  <h5 className="mb-4 uppercase tracking-wide">Platform</h5>
                  <ul className="space-y-3">
                    <li>
                      <a
                        href={"https://twitter.com/Goal3_xyz"}
                        target={"_blank"}
                        rel="noreferrer"
                        className="text-base-content text-opacity-70 hover:text-primary transition"
                      >
                        <span className="inline-flex items-center justify-center text-sm w-5 mr-2">
                          <i className="fa-regular fa-circle-question"></i>
                        </span>
                        Help
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://docs.goal3.xyz/"}
                        target={"_blank"}
                        rel="noreferrer"
                        className="text-base-content text-opacity-70 hover:text-primary transition"
                      >
                        <span className="inline-flex items-center justify-center text-sm w-5 mr-2">
                          <i className="fa-regular fa-file-lines"></i>
                        </span>
                        Docs
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://blog.goal3.xyz/"}
                        target={"_blank"}
                        rel="noreferrer"
                        className="text-base-content text-opacity-70 hover:text-primary transition"
                      >
                        <span className="inline-flex items-center justify-center text-sm w-5 mr-2">
                          <i className="fa-brands fa-medium"></i>
                        </span>
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://portal.goal3.xyz/"}
                        target={"_blank"}
                        rel="noreferrer"
                        className="text-base-content text-opacity-70 hover:text-primary transition"
                      >
                        <span className="inline-flex items-center justify-center text-sm w-5 mr-2">
                          <i className="fa-regular fa-coins"></i>
                        </span>
                        Go to Portal
                      </a>
                    </li>
                  </ul>
                </div>

                <div className={""}>
                  <h5 className="mb-4 uppercase tracking-wide">Community</h5>
                  <ul className="space-y-3">
                    <li>
                      <a
                        href={"https://twitter.com/Goal3_xyz"}
                        target={"_blank"}
                        rel="noreferrer"
                        className="text-base-content text-opacity-70 hover:text-primary transition"
                      >
                        <span className="inline-flex items-center justify-center text-sm w-5 mr-2">
                          <i className="fa-brands fa-twitter"></i>
                        </span>
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        href={"http://discord.gg/goal3"}
                        target={"_blank"}
                        rel="noreferrer"
                        className="text-base-content text-opacity-70 hover:text-primary transition"
                      >
                        <span className="inline-flex items-center justify-center text-sm w-5 mr-2">
                          <i className="fa-brands fa-discord"></i>
                        </span>
                        Discord
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-12 w-full text-sm">
					<Addresses />
				</div> */}
      </footer>
    </>
  );
}

export { Footer };
