import utils, { floatFormat } from "../common/utils";
import { getItem, saveItem } from "local-storage";

export const SinglePlaceOrder = (props: any) => {
  const { betSlips, setBetSlips, data } = props;
  setBetSlips([...betSlips, data]);
};
export const checkSameOrder = (betSlips: any) => {
  const eventId: any[] = [];
  const idSameOrder: any = [];
  betSlips.forEach((i: any) => {
    if (eventId.indexOf(i?.eventId) > -1) {
      idSameOrder.push(i?.eventId);
    }
    eventId.push(i?.eventId);
  });

  return idSameOrder;
};
export const getPriceImpact = (
  target: number,
  odd: number,
  potentialWin: number
) => {
  const perfectWin = target * odd;
  let priceImpact =
    Number(floatFormat(perfectWin - potentialWin, 2)) / perfectWin;
  if (target === 0) priceImpact = 0;
  const priceImpactPercent = priceImpact > 0 ? priceImpact * 100 : 0;
  return { priceImpactPercent };
};
