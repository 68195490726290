import React, { Fragment, useEffect, useState } from "react";
import { MainNavigation } from "../Nav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import slugs from "navigation/slugs";
import { useSnapshot } from "valtio";
import { isIOS } from "react-device-detect";
import { orderSlipProxy } from "proxy-state/betting/OrderSlip";
import { navFunc } from "proxy-state/global";
import { useThemeContext } from "contexts/themeContext";

import { ReactComponent as LogoSvgSm } from "assets/logo/logo-dark-sm-goal3.svg";
import { ReactComponent as LogoSvgLightSm } from "assets/logo/logo-light-sm-goal3.svg";
import { useAuth } from "contexts/authContext";
// import { ReactComponent as LogoSvgSm } from "assets/logo/Goal3_xmas_dark.svg";
// import { ReactComponent as LogoSvgLightSm } from "assets/logo/Goal3_xmas_light.svg";

const pdNavMobile = [
  {
    icon: "fa-duotone fa-wreath-laurel",
    text: "Sportsbook",
    href: slugs.home,
    isActive: !window.location.pathname.includes("x1000") && !window.location.pathname.includes("lottery"),
    isSoon: false,
  },
  {
    icon: "fa-duotone fa-arrows-cross",
    text: "X1000",
    href: slugs.x1000,
    href2: "https://twitter.com/Goal3_xyz/status/1729100610040131634",
    isSoon: false,
    isNew: false,
    isActive: window.location.pathname.includes("x1000"),
    isDemo: true,
  },
  {
    icon: "fa-duotone fa-slot-machine",
    text: "Lottery",
    href: slugs.lottery,
    isActive: window.location.pathname.includes("lottery"),
    isSoon: false,
    isNew: true,
  },
  {
    icon: "fa-duotone fa-coins",
    text: "Portal",
    href: "https://portal.goal3.xyz",
    target: "_blank",
    isSoon: false,
  },
];

interface MobileNavigationProps {}
// Mobile Bottom Navigation
export const MobileNavigation: React.FC<MobileNavigationProps> = () => {
  const pathName = useLocation().pathname;
  const { total } = useSnapshot(orderSlipProxy);
  // const [chatOpen, setChatOpen] = useState<boolean>(false);
  // const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [menuModalOpen, setMenuModalOpen] = useState<boolean>(false);
  // const [notifModal, setNotiModal] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<boolean>(false);
  const [pdNav, setPdNav] = useState(false);
  // const navigator = useNavigate();
  const { theme } = useThemeContext();
  const { token: isAuthenticated } = useAuth();
  useEffect(() => {
    let prevScrollPos = window.scrollY;
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos < prevScrollPos) {
        setScrollDirection(false);
      } else if (currentScrollPos > prevScrollPos) {
        setScrollDirection(true);
      }

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const itemClass =
    "w-full h-full flex flex-col md:flex-row items-center justify-center py-0 gap-1 md:gap-2 font-medium transition cursor-pointer text-base-content/50";
  const pdItemClass =
    "flex items-center justify-center py-2 px-3 gap-2 font-medium transition cursor-pointer text-base-content/50 border rounded-box hover:bg-base-200 ";

  // const setModalToggle = (e: any) => {
  //   const checked: boolean = e?.target?.checked || false;
  //   const body = document.querySelector("body");
  //   if (checked) {
  //     body?.classList.add("has-Modal");
  //   } else {
  //     body?.classList.remove("has-Modal");
  //   }
  // };

  // const setChatModal = (e: any) => {
  //   setModalToggle(e);
  //   setChatOpen(e.target.checked);
  // };

  const modalClasses = `modal modal-bottom sm:modal-middle`;
  //  ${isIOS ? "pb-4" : "pb-3"}${ scrollDirection ? " !-bottom-40" : "" }
  return (
    <>
      <div
        className={`nav-mobile`}
      >
        {pdNav && (
          <div className="w-full text-center border-b border-base-content/10 overflow-y-hidden overflow-x-auto transition-all block">
            <ul
              className={`font-medium inline-flex justify-start gap-2 py-2 px-6 max-w-3xl mx-auto`}
            >
              {pdNavMobile.map((item, index) => (
                <>
                  <li key={index} className={`text-center`}>
                    <a
                      target={item.target || "_self"}
                      href={item.href}
                      className={`${pdItemClass}${item.isActive
                          ? " border-primary"
                          : " border-transparent "
                        }`}
                    >
                      <i
                        className={`${item.icon} text-lg leading-none ${item.isActive
                            ? " icon-primary"
                            : item.isSoon
                              ? " icon-warning"
                              : ""
                          }`}
                      ></i>
                      <span
                        className={`text-[11px] md:text-sm ${item.isActive
                            ? " text-primary"
                            : item.isSoon
                              ? "text-warning"
                              : ""
                          }`}
                      >
                        {item.isSoon ? (
                          <span className="badge-noti !ml-0">Soon</span>
                        ) : (
                          item.text
                        )}
                      </span>
                      {item.isNew && (
                        <span className="badge-noti !ml-0">New</span>
                      )}
                      {item.isDemo && (
                        <span className="badge-noti !ml-0">Demo</span>
                      )}
                    </a>
                  </li>
                </>
              ))}
            </ul>
          </div>
        )}

        <div className="flex w-full justify-between items-center px-2 max-w-3xl mx-auto">
          <button
            onClick={() => setPdNav(!pdNav)}
            className="btn btn-md rounded-full bg-gradient-to-tr from-base-200 to-base-100 border-0 w-12 !h-12 p-0 mx-2"
          >
            <span className="relative inline-flex justify-center items-center gap-2">
              {theme === "dark" ? (
                <LogoSvgSm width={24} className="h-6 cursor-pointer" />
              ) : (
                <LogoSvgLightSm width={24} className="h-6 cursor-pointer" />
              )}
            </span>
            <span className={`sr-only`}>On Goal3</span>
          </button>

          <Link
            to={slugs.home}
            className={`${itemClass} relative${
              pathName === slugs.home ? " text-primary" : ""
            }`}
          >
            <i
              className={`text-xl fa-duotone fa-home ${
                pathName === slugs.home ? " icon-primary" : ""
              }`}
            ></i>
            <span className="text-[11px] md:text-sm leading-none">Lobby</span>
          </Link>

          <button
            onClick={() => {
              navFunc.setNavOpen("isShowOrder", true);
            }}
            className={`${itemClass} relative`}
          >
            <span className="relative">
              <i className={`text-xl fa-duotone fa-receipt`}></i>
              {total > 0 && (
                <Fragment>
                  <span className="badge-noti absolute top-0">{total}</span>
                </Fragment>
              )}
            </span>
            <span className="text-[11px] md:text-sm leading-none">
              Order Slip
            </span>
          </button>

          <Link
            to={slugs.myBets}
            className={`${itemClass}${
              pathName === slugs.myBets ? " text-primary" : ""
              }${isAuthenticated ? '' : " disabled cursor-not-allowed"} relative`}
          >
            <i
              className={`text-xl fa-duotone fa-user${
                pathName === slugs.myBets ? " icon-primary" : ""
              }`}
            ></i>
            <span className="text-[11px] md:text-sm leading-none">My Bets</span>
          </Link>

          {/* Mobile Menu Toggle */}
          <label htmlFor="mobileMenu--modal" className={itemClass}>
            <i className="text-xl fa-solid fa-bars"></i>
            <span className="text-[11px] md:text-sm leading-none">Browse</span>
          </label>
          {/* Mobile Menu Toggle */}
        </div>
      </div>

      {/* Mobile Menu */}
      <input
        type="checkbox"
        id="mobileMenu--modal"
        className="modal-toggle"
        checked={menuModalOpen}
        onChange={(e) => {
          setMenuModalOpen(e.target.checked);
        }}
      />
      <div
        className={`${modalClasses}${
          menuModalOpen ? " modal-open z-[999]" : " -z-10 hidden"
        }`}
      >
        <label
          htmlFor=""
          className="modal-box text-sm relative md:max-w-sm !max-h-screen"
        >
          {/* <button
            onClick={() => setMenuModalOpen(false)}
            className="btn btn-md btn-circle btn-ghost absolute right-4 top-5 z-20"
          >
            <i className="fa-regular fa-xmark-large"></i>
          </button> */}

          <div className="modal-box--body !py-0 !px-0">
            <MainNavigation
              collapse={false}
              closeModal={() => {
                setMenuModalOpen(false);
              }}
            />
          </div>
        </label>
      </div>
      {/* //Mobile Menu */}
    </>
  );
};
