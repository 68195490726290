import React, { useEffect } from "react";

import CountDownInfo from "components/organisms/CountDown/CountDownInfo";
import moment from "moment";
import { useSnapshot } from 'valtio';
import { navProxy } from 'proxy-state/global';
import { useLocation } from "react-router-dom";
import { Img } from "components/commons/Img";


const pageNav = [
  { name: "Introduction", href: "#intro", current: true },
  { name: "Who can participate?", href: "#who", current: false },
  { name: "Prizes Breakdown", href: "#prizes", current: false },
  { name: "Terms & Conditions", href: "#terms", current: false, hasSub: false },
];

const subpageNav1 = [
  { name: "Sub-pagenav #1", href: "#", current: false },
  { name: "Sub-pagenav #2", href: "#", current: false },
  { name: "Sub-pagenav #3", href: "#", current: false },
];

const navItemClass = "py-2 flex items-center";
const navItemNormalClass = "text-base-content/70 hover:text-primary";
const navItemActiveClass = "text-primary";
interface PlaydropProps {
}
export const PlayDropEvent: React.FC<PlaydropProps> = (props) => {
  const { isShowOrder } = useSnapshot(navProxy);
  const timeCountDown = moment('2023-04-30T00:00:00+00:00').unix();
  const now = moment().unix();
  const [menuNav, setMenuNav] = React.useState<string>("#intro");
  const location = useLocation();
  const urlPath = location.pathname;
  useEffect(() => {
    const path = urlPath.split('#')[1];
    if (path) {
      setMenuNav(`#${path}`);
    } else {
      setMenuNav("#intro")
    }
  }, [urlPath])

  return (
    <>
      <div className="container-lg h-full">
        <div className="flex h-full">

          {/* Content */}
          <div className="flex-1 lg:pr-20">

            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl">
                PlayDrop
              </h1>
              {timeCountDown > now && (<CountDownInfo date={timeCountDown} />)}
            </div>

            <div className="mb-8">
              <Img addPrefix alt="" className="object-cover flex-1 rounded-box" src="../../../../event-thumbs/playdrop.png" />
            </div>

            <div id="intro" className="mb-8 text-sm anchor">
              <h5 className="uppercase py-4 sr-only">Quick Info</h5>
              <div className="divide-y divide-base-content/10">
                <div className="flex items-center py-4">
                  <span className="opacity-70 w-40">Total Prize</span>
                  <div className="flex items-center">
                    <span className="text-base-content font-medium">TBD</span>
                    {/* <span className="text-base-content font-medium opacity-70 mx-1">&amp;</span>
                    <span className="text-base-content font-medium">40,000 $zkUSD</span> */}
                  </div>
                </div>
                <div className="flex items-center py-4">
                  <span className="opacity-70 w-40">Provider</span>
                  <div className="flex items-center">
                    <Img addPrefix alt="" title="Provider: Goal3" className="h-5 inline-block" src="../../../../logo/logo-dark-goal3.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div className="type">
              <div>
                <p>PlayDrop is the next phase of Goal3, where we not only roll out the sportsbook onto Mainnet but also we go beyond - into the realm of decentralized, censorship-resistant infrastructure.</p>
                <p>PlayDrop will kickstart a flywheel of incentives by creating a virtuous cycle that benefits all stakeholders involved. The flywheel is a self-sustaining system where each action taken by one stakeholder creates value for another, leading to a continuous loop of growth</p>
              </div>

              <div id="who" className="anchor">
                <h2>Who can participate?</h2>
                <p>To participate in Playdrop, you can:</p>
                <ul>
                  <li><strong>Be a bettor</strong>: Start betting permissionlessly and trustlessly.</li>
                  <li><strong>Become the house</strong>: Provide liquidity or stake $ZKG to earn revenue.</li>
                  <li><strong>Be a bettor</strong>: Start betting permissionlessly and trustlessly</li>
                </ul>
                <p>If you fit into any of the above category, you are eligible for a share of the Playdrop reward pool.</p>
              </div>

              <div id="prizes" className="anchor">
                <h2>Prizes Breakdown</h2>
                <p>Rewards are paid out in $ZKG, xZKG or zkUSD depending on which action you perform.</p>
                <p>Rewards are distributed on a <strong>monthly basis</strong> to eligible wallets.</p>
              </div>

              <div id="terms" className="anchor">
                <h2>Terms & Conditions</h2>
                <ul>
                  <li><strong>Eligibility</strong>: You must be at least 18 years old and comply with all applicable laws and regulations. Employees and affiliates of Goal3 are not eligible to participate.</li>
                  <li><strong>Rewards</strong>: Rewards are paid out in $ZKG, xZKG, or zkUSD, and distributed monthly to eligible wallets.</li>
                  <li><strong>Responsible betting</strong>: Participants are expected to bet responsibly and not engage in any illegal or unethical activities.</li>
                  <li><strong>Referral program</strong>: Referrers must comply with all applicable laws and regulations regarding the referral of new users. Fraudulent or abusive referral practices will not be tolerated.</li>
                </ul>
                <p>By participating in Playdrop, you agree to these terms and conditions and any modifications or amendments made by Goal3.</p>
              </div>

            </div>
          </div>
          {/* //Content */}

          {/* Page Nav */}
          <div className="w-72 sticky top-40 self-start text-sm hidden lg:block">
            <div className="">
              <h5 className="mb-4 uppercase">On This Page</h5>
              <nav>
                <ul>
                  {pageNav.map((item) => (
                    <li className="relative" key={item.name}>
                      <a
                        href={item.href}
                        className={`${navItemClass} font-semibold ${item.href === menuNav
                          ? navItemActiveClass
                          : navItemNormalClass
                          }
                        `}
                        onBlur={() => {
                          setMenuNav(item.href)
                        }}
                      >
                        <span className={``}>
                          {item.name}
                        </span>
                      </a>
                      {item.hasSub &&
                        <>
                          <ul className="ml-2">
                            {subpageNav1.map((item: any, idx: any) => (
                              <li key={idx}>
                                <a
                                  href={item.href}
                                  className={`${navItemClass} ${item.href === menuNav
                                    ? navItemActiveClass
                                    : navItemNormalClass
                                    }
                                  `}
                                  onBlur={() => {
                                    setMenuNav(item.href)
                                  }}
                                >
                                  <i className="fa-sharp fa-regular fa-angle-right mr-2"></i>
                                  <span className={``}>
                                    {item.name}
                                  </span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </>
                      }
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          {/* //Page Nav */}
        </div>
      </div>
    </>
  );
}
