import HomeProvider from "contexts/homeContext";
import EventDetail from "components/organisms/Sports/EventDetail";

export default function Event() {
  return (
    <HomeProvider>
      <EventDetail />
    </HomeProvider>
  );
}
