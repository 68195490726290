export const findById = <T extends { readonly id: string }>(
  data: T[],
  id: string | undefined
): T | undefined => {
  return data?.find((item) => item.id === id);
};

export const checkExists = <T extends { readonly id: string }>(
  data: T[],
  id: string
): boolean => {
  return data.findIndex((item) => item.id === id) > 0;
};
