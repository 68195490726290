// import { Disclosure } from "@headlessui/react";
import moment from "moment";
import React, { Fragment, useEffect, useRef } from "react";
import {
  ConvertString2BN,
  currencyFormat,
  getAddressSimpleString,
} from "common/utils";
import LinkScan from "common/LinkScan";
import { LoadingSkeleton } from "../../Loading";
import { useClaimed } from "../../../../hooks/useClaimed";
import { isMobile } from "react-device-detect";
import { ethers } from "ethers";
import { shortTime } from "common/utils";
import { DECIMALS } from "functions/betUtils";
import { Pagination } from "components/organisms/Pagination/Pagination";
import { useSnapshot } from "valtio";
import { BetFilterProxy } from "proxy-state/betting/myBetFilter";
const ClaimedHistory = (props: any) => {
  // const { currentPage, contentPerPage, setCountData } = props;
  const { orderBy, timeFilter } = useSnapshot(BetFilterProxy);
  const {
    claimedData,
    loading,
    page,
    setTimeFilter,
    setFilterOrderBy,
    count,
    onPageChanged,
    PAGE_SIZE,
  } = useClaimed();
  const observerTarget = useRef(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    setTimeFilter(timeFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter])
  useEffect(() => {
    setFilterOrderBy(orderBy);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy])
  /// dev: handle infinity scroll ::::::: trigger click next button for simulate pagination on mobile
  useEffect(() => {
    let observer: IntersectionObserver;

    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          console.log("visible");
          nextButtonRef.current?.click();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current && observer) {
        console.log("unmount");
        observer.unobserve(observerTarget.current);
      }
    };
  }, []);
  // useEffect(() => {
  //   console.log('====================================');
  //   console.log('claimedData :', claimedData);
  //   console.log('====================================');
  //   const indexOfLastPost = currentPage * contentPerPage;
  //   const indexOfFirstPost = indexOfLastPost - contentPerPage;
  //   const data = claimedData.slice(indexOfFirstPost, indexOfLastPost);
  //   // setCountData(claimedData.length);
  // }, [claimedData]);

  // const indexOfLastPost = currentPage * contentPerPage;
  // const indexOfFirstPost = indexOfLastPost - contentPerPage;
  // const data = claimedData.slice(indexOfFirstPost, indexOfLastPost);
  const EmptyOrder = () => {
    return (
      <div className="mt-6">
        <div className="grid grid-cols-1">
          <div className="mt-6">
            <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
              <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
              <span className="text-lg font-bold">{`"I don't wait for luck, I create my own"`}</span>
              <span className="text-xs opacity-70 text-center mt-1 italic">
                {"- Lee, goal3.xyz"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      <div className="relative overflow-x-auto mt-2">
        {claimedData.length > 0 && (
          <table className="w-full text-sm text-left">
            <thead className="thead">
              <tr className="tr">
                <th scope="col" className="th">
                  Transactions
                </th>
                <th scope="col" className="th text-left">
                  Wagers Claimed
                </th>
                <th scope="col" className="th text-left">
                  Amount
                </th>
                <th scope="col" className="th text-right">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
              {claimedData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className="tr" key={index}>
                      <td className="td whitespace-nowrap">
                        <LinkScan
                          linkView={item?.id}
                          title={getAddressSimpleString(item?.id, 4)}
                          classes={`uppercase hover:text-primary font-semibold`}
                          icon={true}
                          subClasses={` `}
                        />
                      </td>
                      <td className="td text-left">
                        <span className="uppercase text-xs tracking-wider font-medium">
                          {item?.wagerCount}
                        </span>
                      </td>
                      <td
                        className="td text-left"
                        data-tip={currencyFormat(
                          Number(
                            ethers.utils.formatUnits(
                              ConvertString2BN(item?.amount || 0),
                              DECIMALS
                            )
                          ),
                          true
                        )}
                      >
                        {currencyFormat(
                          Number(
                            ethers.utils.formatUnits(
                              ConvertString2BN(item?.amount || 0),
                              DECIMALS
                            )
                          ),
                          true
                        )}
                      </td>
                      <td className="td text-right" data-tip={item?.created_at}>
                        {item?.created_at
                          ? shortTime(moment(item?.created_at).fromNow())
                          : ""}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
        {loading && <LoadingSkeleton rows={claimedData.length > 0 ? 2 : 8} />}
        {!loading &&  claimedData.length === 0 && <EmptyOrder />}
        {isMobile && (
          <div className="flex flex-col gap-4 pl-4 pr-2" ref={observerTarget}></div>
        )}
        {count > PAGE_SIZE && (
          <div className="hidden md:block">
            <Pagination
              count={count}
              currentPage={page}
              pageChanged={onPageChanged}
              position={"end"}
              nextButtonRef={nextButtonRef}
              pageSize={PAGE_SIZE}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default ClaimedHistory;
