import { InstalledPage } from "components/commons/LandingPage/install";
import {
  isAndroid,
  isChrome,
  isSafari,
  isIOS,
  isMobile,
} from "react-device-detect";
// import { LandingPage } from "components/commons/LandingPage";
import LoginScreenMobile from "components/commons/LandingPage/LoginScreenMobile";
import LoginScreenDesktop from "components/commons/LandingPage/LoginScreenDesktop";
import WrongBrowserPage from "components/commons/LandingPage/WrongBrowser";
import { IS_DISABLE_PWA } from "constant";
// import LoginTmpl from "components/commons/LandingPage/Login";

function Login() {
  const isInStandaloneMode =
    IS_DISABLE_PWA || window.matchMedia("(display-mode: standalone)").matches;

  if (isMobile) {
    if (!isInStandaloneMode) {
      return (
        <>
          {(isIOS && !isSafari) || (isAndroid && !isChrome) ? (
            <WrongBrowserPage isDevice={isIOS ? "ios" : "android"} />
          ) : (
            <InstalledPage isDevice={isIOS ? "ios" : "android"} />
          )}
        </>
      );
    }

    return <LoginScreenMobile />;
  }

  return <LoginScreenDesktop />;
}

export default Login;
