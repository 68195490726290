import React, { Fragment } from "react";
import { useThemeContext } from "contexts/themeContext";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";
import { ReactComponent as LogoMobile } from "assets/logo/logo-dark-goal3.svg";
import { ReactComponent as LogoMobileLight } from "assets/logo/logo-light-goal3.svg";
// import { ReactComponent as LogoMobile } from "assets/logo/Goal3_xmas_full_logo_dark.svg";
// import { ReactComponent as LogoMobileLight } from "assets/logo/Goal3_xmas_full_logo_light.svg";
const LogoApp: React.FC = () => {
  const { theme } = useThemeContext();
  return (
    <Fragment>
      <div className="inline-flex flex-shrink-0 items-center justify-center font-altsans">
        <Link to={slugs.home} className="flex items-center justify-center">
          {theme === "dark" ? (
            <LogoMobile className="w-24 h-6 cursor-pointer" />
          ) : (
            <LogoMobileLight className="w-24 h-6 cursor-pointer" />
          )}
        </Link>
      </div>
    </Fragment>
  );
};
export default LogoApp;
