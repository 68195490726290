import {
  SocialType,
  enterInviteCode,
  loginSocial,
} from "utils/apis/bookmakerApis";
import { SignInWithFirebase } from "utils/firebase/firebaseAuth";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as GoogleSvg } from "assets/icons/google.svg";
import { ReactComponent as LogoMobile } from "assets/logo/logo-dark-sm-goal3.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import slugs from "navigation/slugs";
import { getGoogleUserInfo } from "utils/apis/api";
import clsx from "clsx";
import { getDocs } from "firebase/firestore";
import { ReactComponent as Metamask } from "assets/logo/metamask-logo.svg";
import BgVideo from "assets/wallpapers/soccer.mp4";
import { IS_DISABLE_PWA } from "constant";
import OneSignal from "react-onesignal";
import { parseAddressFromToken } from "utils/index";
import { notiApis } from "utils/apis";
import { useAuth } from "contexts/authContext";
import { auth } from "utils/firebase/config2";
import { loadDataByAddress } from "proxy-state/helper";
import { queryUserBySocialId } from "utils/firebase";
import { saveItem, ADDRESS } from "local-storage";
import utils from "common/utils";

export enum AuthStep {
  Login = "login",
  LinkEOA = "link-eoa",
  Invitation = "invitation",
}
const LoginScreenPage = () => {
  const navigator = useNavigate();
  const { updateToken } = useAuth();
  const [googleToken, setGoogleToken] = useState<string | undefined>();
  const [step, setStep] = useState<AuthStep>(AuthStep.Login);
  const [token, setToken] = useState<string | undefined>();
  const [socialId, setSocialId] = useState<string>("");
  const [socialType, setSocialtype] = useState<SocialType>(SocialType.GOOGLE);
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [picture, setPicture] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
  const [isApplySuccess, setIsApplySuccess] = useState<boolean>(false);
  const [inviteCode, setInviteCode] = useState<string>("");
  const [isCopied, setIsCopied] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [enableContinue, setEnableContinue] = useState(false);
  const [isCopiedCode, setIsCopiedCode] = useState(false);

  const [isExpired, setIsExpired] = useState(false);
  const { state } = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const returnUrl = searchParams.get("return");
  const callBackUrl = returnUrl ? utils.base64URLDecode(returnUrl) : slugs.home;
  useEffect(() => {
    if (state?.expired && !isExpired) {
      setIsExpired(true);
      // navigator(slugs.login, { replace: true })
      toast.warning("Session expired. Login again!");
    }
  }, [state?.expired, isExpired]);

  // useEffect(() => {
  //   disconnect();
  //   if (step === AuthStep.Login) {
  //     if (getItem("access_token")) {
  //       localStorage.clear();
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const loginWrapper = async (googleId: string) => {
    const res = await loginSocial(googleId, SocialType.GOOGLE);
    const token = res.data.access_token;
    const address = parseAddressFromToken(token);
    saveItem(ADDRESS, address)
    loadDataByAddress(address);
    updateToken(token);
    try {
      console.log("run one signal!!!");
      await OneSignal.login(address);
      console.log("run one signal done!!!");
    } catch (error) {
      console.log(
        "🚀 ~ file: LoginScreenMobile.tsx:105 ~ loginWrapper ~ error:",
        error
      );
    }
    await notiApis.postTriggerUser(address);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setGoogleToken(tokenResponse.access_token);
      setIsGoogleLoading(true);
      // fetching userinfo can be done on the client or the server
      const userInfo = await getGoogleUserInfo(tokenResponse.access_token);
      setToken(tokenResponse.access_token);
      const socialId = userInfo.data.sub;

      setSocialId(socialId);
      setSocialtype(SocialType.GOOGLE);
      setUserName(userInfo.data.name);
      setEmail(userInfo.data.email);
      setPicture(userInfo.data.picture);
      console.log("userInfo", userInfo);

      try {
        await SignInWithFirebase(auth);
        console.log("run here!!!");
        await loginWrapper(tokenResponse.access_token);
        console.log("redirect tets");

        if(callBackUrl.includes('lottery')) {
          window.location.href = callBackUrl;
        } else navigator(slugs.home);
      } catch (error: any) {
        if (error?.response) {
          // if (error.response.data.message === AuthStep.Link) {
          // 	setStep(AuthStep.Link);
          // } else if (error.response.data.message === AuthStep.LinkEOA) {
          // 	setStep(AuthStep.LinkEOA);
          // }
          setStep(AuthStep.LinkEOA);
        }
      } finally {
        setIsGoogleLoading(false);
      }
    },
    onError(errorResponse) {
      console.log("errorResponse", errorResponse);
    },
    onNonOAuthError: (err) => {
      console.log(err);
      setIsGoogleLoading(false);
    },
  });

  const handleEnterInviteCode = async () => {
    try {
      setIsLoading(true);
      setIsApplySuccess(false);
      const resp = await enterInviteCode(inviteCode);
      if (resp.data) {
        setIsApplySuccess(true);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const startPlaying = async () => {
    try {
      if (!googleToken) return;
      await SignInWithFirebase(auth);
      await loginWrapper(googleToken);
      if(callBackUrl.includes('lottery')) {
        window.location.href = callBackUrl;
      } else navigator(slugs.home);
    } catch (error) {
      console.log(
        "🚀 ~ file: LoginScreen.tsx:303 ~ startPlaying ~ error:",
        error
      );
    }
  };

  const handleCopy = () => {
    setIsCopied(true);
    window.navigator.clipboard.writeText(
      `${window.location.origin}/metamask/${token}`
    );

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  // Dev: get user info in database

  const handleVerify = async () => {
    try {
      setIsVerifying(true);
      // if (!socialId) return;
      const userRef = queryUserBySocialId(socialId);
      // const userRef = getUserBySocialId("110636263745966662099");
      const userDoc = await getDocs(userRef);
      const userData: any = userDoc.docs?.[0]?.data();
      if (!userData) return;
      if (!googleToken) return;
      await loginWrapper(googleToken);
      setEnableContinue(true);
    } catch (error) {
      console.log("🚀 ~ file: LoginScreen.tsx:179 ~ f ~ error:", error);
    } finally {
      setIsVerifying(false);
    }
  };

  const handleContinue = async () => {
    setStep(AuthStep.Invitation);
  };

  const handleCopyCode = () => {
    setIsCopiedCode(true);
    window.navigator.clipboard.writeText(`goal`);

    setTimeout(() => {
      setIsCopiedCode(false);
    }, 2000);
  };
  const isDisableVerify = isVerifying || enableContinue;

  const currentDate = new Date();
  const next24hMilisecond = 1700211600000;

  const isShowLogin = true;
  // window.location.host.includes("localhost") ||
  // window.location.host.includes("v2")
  //   ? true
  //   : currentDate.valueOf() < next24hMilisecond;

  return (
    <>
      {step === AuthStep.Login && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-screen !max-h-screen bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>
          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-md bg text-center p-4 lg:p-0">
                <div className="flex flex-col gap-6">
                  <div className="mx-auto scale-110 relative -left-1">
                    <div className="inline-flex flex-shrink-0 items-center">
                      <div className="flex items-center">
                        <LogoMobile className="w-14 h-14" />{" "}
                      </div>
                    </div>
                  </div>

                  <div className="mx-auto text-base">
                    <h2 className="text-2xl font-semibold">Welcome to Goal3</h2>
                    <p className="mt-6">
                      The First Gasless, No-KYC & On-chain Betting App on
                      zkSync.
                    </p>
                  </div>
                </div>

                {isShowLogin ? (
                  <div className="mt-12 mx-auto grid grid-cols-1 gap-3">
                    <button
                      className="btn btn-primary gap-3"
                      onClick={() => {
                        googleLogin();
                        setIsGoogleLoading(true);
                      }}
                      disabled={isGoogleLoading}
                    >
                      {isGoogleLoading ? (
                        <i className="fa-solid fa-spinner-third animate-spin mr-2"></i>
                      ) : (
                        <span className="bg-base-100 rounded-full p-1 mr-2">
                          <GoogleSvg className="w-5 h-5 min-w-[20px] cursor-pointer" />
                        </span>
                      )}

                      <span className="text-base">Sign in with Google</span>
                    </button>
                    {/* <button className="btn btn-primary w-full md:w-auto md:px-8"><i className="fa-duotone fa-party-horn mr-2"></i>Joint the Testnet</button> */}
                    <div className="flex items-center justify-center relative my-1">
                      <span className="h-[1px] w-full absolute inset-x-0 bg-base-content bg-opacity-10"></span>
                      <span className="bg-base-200 relative z-0 text-xs uppercase px-2 rounded-full leading-none tracking-wide">
                        Or
                      </span>
                    </div>
                    <a
                      className="btn btn-block btn-outline justify-center border-base-content/10 hover:bg-transparent hover:text-base-content hover:border-base-content/20"
                      href={callBackUrl}
                    >
                      <span className="flex items-center gap-3">
                        <span className="text-base">I'll sign in later</span>
                      </span>
                    </a>
                  </div>
                ) : (
                  <div className="mt-12 ">
                    <div className="bg-primary/20 text-base-content rounded-box px-2 py-3 w-4/5 mx-auto">
                      <span className="text-sm">
                        <span className="text-base font-semibold">
                          Goal3 App Early Access Ended.
                        </span>{" "}
                        <br />
                        Follow our{" "}
                        <button
                          onClick={() => {
                            window.open(`https://twitter.com/Goal3_xyz`);
                          }}
                          rel="noreferrer"
                          className="underline underline-offset-4 text-primary"
                        >
                          twitter
                        </button>{" "}
                        for updates!
                      </span>
                    </div>
                  </div>
                )}

                {/* <footer className={"mt-16 text-center text-sm"}>
                <div className={"container px-2 md:px-4 flex flex-col items-center justify-center"}>

                  <div className={"flex flex-row items-center space-x-4 cursor-pointer mt-4"}>
                    <a
                      href={"https://blog.goal3.xyz/"}
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-slate-400 text-opacity-70 hover:text-primary transition"
                    >
                      <i className="fa-brands fa-medium text-xl"></i>
                    </a>
                    <a
                      href={"https://twitter.com/Goal3_xyz"}
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-slate-400 text-opacity-70 hover:text-primary transition"
                    >
                      <i className="fa-brands fa-twitter text-xl"></i>
                    </a>
                    <a
                      href={"http://discord.gg/goal3"}
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-slate-400 text-opacity-70 hover:text-primary transition"
                    >
                      <i className="fa-brands fa-discord text-xl"></i>
                    </a>
                  </div>

                  <div className={"text-slate-400 text-opacity-70"}>&copy; 2023 Goal3. All Rights Reserved.</div>

                </div>
              </footer> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {step === AuthStep.LinkEOA && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-screen-sm md:max-w-sm text-center p-6 pb-8 lg:p-0 h-full flex flex-col md:h-auto">
                <div className="md:absolute top-0 right-0 left-0 flex justify-center mb-[20%] md:mt-6">
                  <button
                    type="button"
                    className="btn btn-md px-3 text-xs md:text-sm !inline-flex gap-3"
                    onClick={() => {
                      setStep(AuthStep.Login);
                    }}
                  >
                    <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100 flex items-center justify-center">
                      <img height="60" width="60" src={picture} alt="Avatar" />
                    </div>
                    <span className="text-xs md:text-sm">{userName}</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center relative">
                    <Metamask width={16} height={16} className="w-16 h-16" />
                  </div>

                  <h2 className="text-2xl font-semibold">
                    Link wallet in Metamask
                  </h2>

                  <div className="text-base leading-relaxed">
                    Follow the instructions below to link your crypto wallet in
                    Metamask
                  </div>

                  <div className="bg-base-100/20 text-base-content/70 rounded-xl shadow-2xl p-6">
                    <ul className="steps steps-vertical w-full">
                      <li className="step step-neutral">
                        <div className="w-full text-left">
                          Click on{" "}
                          <button
                            onClick={() => {
                              window.open(
                                `https://metamask.app.link/dapp/${window.location.origin}/metamask/${token}`
                                // `${window.location.origin}${slugs.metamask}/${token}`
                              );
                            }}
                            rel="noreferrer"
                            className="underline underline-offset-4 text-primary"
                          >
                            this link
                          </button>
                        </div>
                      </li>
                      <li className="step step-neutral">
                        <div className="w-full text-left">
                          Follow all provided instructions
                        </div>
                      </li>
                      <li className="step step-neutral">
                        <div className="w-full text-left">
                          Return to this screen to continue
                        </div>
                      </li>

                      {/* <li
                        className={
                          status === "connected"
                            ? "step step-neutral"
                            : "step step-primary"
                        }
                      >
                        <button
                          className="btn btn-md btn-primary text-sm"
                          onClick={handleCopy}
                        >
                          {!isCopied ? (
                            <i className="fa-regular fa-copy mr-2"></i>
                          ) : (
                            <i className="fa-solid fa-copy mr-2"></i>
                          )}
                          Copy this link
                        </button>
                      </li>
                      <li
                        className={
                          status === "connected"
                            ? "step step-neutral"
                            : "step step-primary"
                        }
                      >
                        <button
                          className="btn btn-md btn-primary text-sm"
                          onClick={handleRefetch}
                        >
                          {isRefreshing && (
                            <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
                          )}
                          Go to enter Invite code
                        </button>
                      </li> */}
                      {/* <li
                        className={
                          status === "connected"
                            ? "step step-neutral"
                            : "step step-primary"
                        }
                      >
                        <RawWalletConnect />
                      </li>
                      <li
                        className={
                          status === "connected" && !isApproveSession
                            ? "step step-primary"
                            : "step step-neutral"
                        }
                      >
                        <button
                          className={`btn btn-md text-sm ${
                            status === "connected" &&
                            !isApproveSession &&
                            !isCheckingSession &&
                            !chain?.unsupported
                              ? "btn-primary"
                              : "btn-disabled"
                          }`}
                          onClick={applyWalletSession}
                          disabled={isSessionLoading || chain?.unsupported}
                        >
                          {((status === "connected" && !isApproveSession) ||
                            status !== "connected") && (
                            <>
                              {(isSessionLoading || isCheckingSession) &&
                              !chain?.unsupported ? (
                                <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
                              ) : (
                                <i className="fa-regular fa-link-simple text-lg mr-3"></i>
                              )}
                              {isCheckingSession ? (
                                <span className="">
                                  Checking Session Aproval
                                </span>
                              ) : (
                                <span className="">Approve Your Session</span>
                              )}
                            </>
                          )}
                          {status === "connected" && isApproveSession && (
                            <>
                              <i className="fa-solid fa-circle-check text-success text-lg mr-3"></i>
                              <span className="">Session approved</span>
                            </>
                          )}
                        </button>
                      </li> */}

                      {/* <li
												className={`${
													status === "connected" && isApproveSession && !isApproveZkUSD
														? "step step-primary"
														: "step step-neutral"
												}`}
											>
												<button
													className={`btn btn-md text-sm ${
														status === "connected" && isApproveSession && !isApproveZkUSD ? "btn-primary" : "btn-disabled"
													}`}
													onClick={applyZkUSD}
												>
													{((status === "connected" && isApproveSession && !isApproveZkUSD) ||
														(status === "connected" && !isApproveSession) ||
														status !== "connected") && (
														<>
															<img
																alt="zkUSD"
																className="h-5 cursor-pointer mr-3 border-2 border-base-300/50 rounded-full"
																src="/currency-icons/zkUSD.png"
															/>
															<span className="">Approve zkUSD</span>
														</>
													)}
													{status === "connected" && isApproveSession && isApproveZkUSD && (
														<>
															<i className="fa-solid fa-circle-check text-success text-lg mr-3"></i>
															<span className="">zkUSD approved</span>
														</>
													)}
												</button>
											</li> */}
                    </ul>
                  </div>
                </div>

                <div className="mt-auto md:mt-12 grid grid-cols-1 gap-3">
                  <button
                    className={clsx("btn btn-primary gap-1 btn-block", {
                      "": !isDisableVerify,
                      "btn-disabled": isDisableVerify,
                    })}
                    onClick={() => {
                      handleVerify();
                    }}
                  >
                    {isVerifying && (
                      <>
                        <i className="fa-solid fa-spin fa-circle-notch"></i>
                        <span className="text-base">Verifying</span>
                      </>
                    )}
                    {enableContinue && (
                      <>
                        <span className="text-base">Verified</span>
                        <i className="fa-solid fa-circle-check text-success text-lg"></i>
                      </>
                    )}
                    {!isVerifying && !enableContinue && (
                      <span className="text-base">Verify</span>
                    )}
                  </button>
                  {IS_DISABLE_PWA && (
                    <button
                      className="btn btn-primary btn-block gap-3"
                      onClick={handleCopy}
                    >
                      {!isCopied ? (
                        <i className="fa-regular fa-copy mr-2"></i>
                      ) : (
                        <i className="fa-solid fa-copy mr-2"></i>
                      )}
                      <span className="text-base">Or copy this link</span>
                    </button>
                  )}
                  <button
                    onClick={handleContinue}
                    className={clsx("btn btn-primary gap-3 btn-block", {
                      "": enableContinue,
                      "btn-disabled": !enableContinue,
                    })}
                  >
                    <span className="text-base">Continue</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === AuthStep.Invitation && (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/70 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-10 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div
              className={`w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative`}
            >
              <div className="mx-auto w-full max-w-sm text-center p-4 lg:p-0">
                <div className="md:absolute top-0 right-0 left-0 flex justify-center mb-[20%] md:mt-6">
                  <button
                    type="button"
                    className="btn btn-md px-3 text-xs md:text-sm !inline-flex gap-3"
                    onClick={() => {
                      setStep(AuthStep.Login);
                    }}
                  >
                    <div className="w-5 h-5 text-base rounded-full overflow-hidden bg-base-100 flex items-center justify-center">
                      <img height="60" width="60" src={picture} alt="Avatar" />
                    </div>
                    <span className="text-xs md:text-sm">{userName}</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="w-16 h-16 rounded-full bg-primary/20 inline-flex mx-auto justify-center items-center">
                    <i className="fa-duotone fa-hand-holding-heart icon-primary text-3xl"></i>
                  </div>

                  <h2 className="text-3xl">Invitation Code</h2>

                  <div className="mt-4 mx-auto text-base">
                    <p>
                      To access Goal3, you'll need a referral code.
                      <br /> Our 3-Tier Referral Program rewards early joiners
                      with more $ZKG.{" "}
                      <a
                        href="https://docs.goal3.xyz/referral-program/referral-code"
                        target={"_blank"}
                        rel="noreferrer"
                        className="link text-primary"
                      >
                        How to get one?
                      </a>
                    </p>
                  </div>
                  {/* success: <div className="pl-6 pr-2 bg-success/10 border border-base-content/20 rounded-box shadow-2xl flex items-center justify-between gap-4 text-left"> */}
                  {/* loading: <div className="pl-6 pr-2  border border-base-content/20 rounded-box shadow-2xl flex items-center justify-between gap-4 text-left"> */}
                  <div
                    className={clsx(
                      "pl-6 pr-2 border border-base-content/40 rounded-box shadow-2xl flex items-center justify-between gap-4 text-left",
                      {
                        "bg-primary/10": isLoading && !isApplySuccess,
                        "bg-base-300/70": !isLoading && !isApplySuccess,
                        "bg-success/10": isApplySuccess,
                      }
                    )}
                  >
                    <div className="flex-1">
                      <input
                        type="text"
                        placeholder="Invitation code"
                        disabled={isLoading}
                        value={inviteCode}
                        onChange={(e) => {
                          setInviteCode(e.target.value);
                        }}
                        className="input input-ghost input-lg !p-0 w-full"
                      />
                    </div>
                    {isLoading ? (
                      <i className="fa-solid fa-circle-notch fa-spin text-lg text-primary mr-2"></i>
                    ) : isApplySuccess ? (
                      <i className="fa-solid fa-check text-lg text-success mr-2"></i>
                    ) : (
                      <button
                        className="btn btn-primary btn-md"
                        onClick={handleEnterInviteCode}
                      >
                        Apply
                      </button>
                    )}
                  </div>

                  {/* {!isApplySuccess && (
                    <div className="mt-4 flex items-center justify-center gap-2">
                      Default Code:
                      <div className="bg-primary/20 rounded-full pl-3 pr-1">
                        <span className="text-primary tracking-wider">
                          goal3
                        </span>
                        <button
                          className="btn btn-sm btn-ghost text-primary btn-circle"
                          onClick={handleCopyCode}
                        >
                          {isCopiedCode ? (
                            <i className="fa-solid fa-copy"></i>
                          ) : (
                            <i className="fa-regular fa-copy"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  )} */}
                  {isApplySuccess && (
                    <div className="-mt-2 text-success text-sm">
                      Code applied successfully
                    </div>
                  )}
                </div>

                <div className="mt-12">
                  <button
                    className={`btn btn-primary btn-block ${
                      isApplySuccess ? "" : "btn-disabled"
                    }`}
                    onClick={startPlaying}
                  >
                    <span className="text-base">Start Playing</span>
                    <i className="fa-solid fa-angle-right ml-3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default LoginScreenPage;
