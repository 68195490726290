import React, { Fragment } from "react";
import utils, { floatFormat } from "common/utils";
import { Link } from "react-router-dom";
import slugs from "navigation/slugs";
import basketball from "assets/icons/basketball.svg";
import baseball from "assets/icons/baseball-bat-ball.svg";
import mma from "assets/icons/hand-fist.png";
import soccer from "assets/icons/futbol.svg";

const defaultImg: any = {
  basketball: basketball,
  baseball: baseball,
  mma: mma,
  soccer: soccer,
};

const SingleBet = (props: any) => {
  const { item, type, potentialWin } = props;
  const data = type === "wager" ? item?.orderData[0] : item?.outcomeInfo[0];

  if (data === undefined) return null;

  return (
    <>
      <div
        className={`${
          type === "wager"
            ? "flex items-center justify-between px-4 pt-3 pb-1"
            : "flex items-center justify-between px-4 pt-3 pb-1"
        }`}
      >
        <div className="">
          <div className="flex items-center justify-between text-xs mb-1">
            <Link
              to="#"
              className="text-base-content text-opacity-70 hover:text-primary transition"
            >
              <i className={`${data?.leagueIcon} mr-2`}></i>
              {data?.league}
            </Link>
          </div>
          <h5 className="w-full text-sm">
            <Link
              to={
                data?.eventUrl
                  ? `${slugs.sports}/${data?.eventUrl.replace("sports/", "")}`
                  : "#"
              }
              title={data?.eventName}
              className="font-semibold hover:text-primary transition"
            >
              {utils.getEventName(data?.eventName)}
            </Link>
          </h5>
        </div>
        <div className="isolate flex -space-x-2 shrink-0">
          {data?.flagTeam &&
            data?.flagTeam?.map((item: any, idx: number) => (
              <Fragment key={idx}>
                <div className="h-6 w-6 inline-block rounded-full border-2 border-base-100 bg-base-100 ring-1 ring-base-content/20">
                  <img
                    className={`relative z-[${
                      data?.flagTeam.length - 1 - idx
                    }] rounded-full`}
                    src={`https://assets.b365api.com/images/team/b/${item?.img_id}.png`}
                    alt=""
                    onLoad={(event: any) => {
                      if (event?.target?.height <= 1) {
                        (event?.target as HTMLImageElement).src =
                          defaultImg[item?.type];
                      }
                    }}
                  />
                </div>
              </Fragment>
            ))}
        </div>
      </div>

      <div
        className={`${
          type === "wager" ? "px-4 pb-3" : "px-4 pb-3"
        } rounded-box text-xs`}
      >
        <div className="flex justify-between items-center">
          <span className="text-base-content">
            <span className="text-base-content text-opacity-70">
              {data?.marketName}:
            </span>
            <span className="text-base-content text-opacity-70 ml-1 font-semibold">
              {utils._getOddName(data)}
            </span>
          </span>
          <div className="pl-2 flex items-center text-xs">
            <span className="text-xs sr-only">Odds</span>
            {type === "wager" && (
              <span className="text-base-content text-opacity-70 font-medium">
                {floatFormat(
                  Number(
                    item.redeemable || item.amount || item.redeemAmount || 0
                  ) / Number(item.betAmount || 1)
                )}
              </span>
            )}
            {type !== "wager" && (
              <span className="text-base-content text-opacity-70 font-medium">
                {floatFormat(
                  Number(potentialWin || 0) / Number(item.total_amount || 0)
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SingleBet;
