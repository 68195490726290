import React from "react";
const FooterLanding: React.FC = () => {
  return (
    <footer className={"mt-12 text-center text-sm"}>
      <div
        className={
          "container px-2 md:px-4 flex flex-col items-center justify-center"
        }
      >
        <div
          className={"flex flex-row items-center space-x-4 cursor-pointer mt-4"}
        >
          <a
            href={"https://twitter.com/Goal3_xyz"}
            target={"_blank"}
            rel="noreferrer"
            className="text-base-content/50 hover:text-primary transition"
          >
            <i className="fa-brands fa-twitter text-xl"></i>
          </a>
          <a
            href={"http://discord.gg/goal3"}
            target={"_blank"}
            rel="noreferrer"
            className="text-base-content/50 hover:text-primary transition"
          >
            <i className="fa-brands fa-discord text-xl"></i>
          </a>
          <a
            href={"https://blog.goal3.xyz/"}
            target={"_blank"}
            rel="noreferrer"
            className="text-base-content/50 hover:text-primary transition"
          >
            <i className="fa-brands fa-medium text-xl"></i>
          </a>
        </div>

        <div className={"text-base-content/50 mt-4"}>&copy; 2023 Goal3</div>
      </div>
    </footer>
  );
};
export default FooterLanding;
