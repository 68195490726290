import { useEffect, useState } from "react";
import Navigator from "./navigation/navigator";
import OneSignal from "react-onesignal";
import { AuthProvider } from "contexts/authContext";
import { getAppVersions } from "utils/apis/bookmakerApis";
import {
  ACCESS_TOKEN,
  CLIENT_VERSION,
  IS_REQUIRED_RE_OPEN_PWA,
  INVITATION_CODE,
  deleteItem,
  getItem,
  saveItem,
  getExpiredItem,
} from "local-storage";
import { UpdateVersion } from "components/commons/UpdateVersion";
import { ReOpenPWA } from "components/commons/ReOpenPWA";
import slugs from "navigation/slugs";
// import { getChangeLogs } from "components/commons/UpdateVersion/changelogs"
import { ThemeContextProvider } from "contexts/themeContext";
import { LanguageContextProvider } from "contexts/languageContext";

import { getScreenName } from "components/commons/Responsive";
import { IS_DISABLE_PWA } from "constant";
import { navFunc } from "proxy-state/global";
import { DepositContextProvider } from "contexts/depositContext";

function App() {
  const [isOutdated, setIsOutdated] = useState(false);
  const [version, setVersion] = useState<string>("");
  const [isRequiredReOpenPWA, setIsRequiredReOpenPWA] = useState(false);
  const { pathname } = window.location;
  const searchParams = new URLSearchParams(window.location.search);
  const checkExistsRefLink = getItem(INVITATION_CODE);
  const refLink = searchParams.get("ref");
  useEffect(() => {
    if (refLink && !checkExistsRefLink) saveItem(INVITATION_CODE, refLink);
  }, [checkExistsRefLink, refLink])
  
  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_ID!,
      allowLocalhostAsSecureOrigin: true,
      autoResubscribe: true,
      serviceWorkerParam: {
        scope: "/push/onesignal/",
      },
      serviceWorkerPath: "/push/onesignal/OneSignalSDKWorker.js",
    }).then(() => {
      // enable debug
      // OneSignal.Debug.setLogLevel("trace");
    });
  }, []);

  useEffect(() => {
    if (IS_DISABLE_PWA) return;

    const ignoreUpdateVersionInPath = [slugs.login].includes(pathname);

    let interval: any;

    if (ignoreUpdateVersionInPath) {
      return;
    }
    const f = async () => {
      const result = await getAppVersions();
      // const changeLogs = getChangeLogs();
      const currentVersion = getItem(CLIENT_VERSION);
      const version: string = result?.data || "";
      // const version: string = changeLogs[0]?.version || '';

      if (!currentVersion) {
        saveItem(CLIENT_VERSION, version);
        return;
      } else {
        if (currentVersion !== version) {
          setVersion(version);
          saveItem(CLIENT_VERSION, version);
          setIsOutdated(true);
        }
        interval = setInterval(async () => {
          const result = await getAppVersions();
          // const changeLogs = getChangeLogs();
          // const version: string = changeLogs[0]?.version || '';
          const version: string = result?.data || "";
          const currentVersion = getItem(CLIENT_VERSION);
          if (currentVersion !== version) {
            setVersion(version);
            saveItem(CLIENT_VERSION, version);
            setIsOutdated(true);
          }
        }, 1000 * 60 * 5);
      }
    };
    f();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    let interval: any;
    const f = () => {
      interval = setInterval(() => {
        const token = getItem(ACCESS_TOKEN);
        const expired = getExpiredItem(ACCESS_TOKEN);
        if (expired && token) {
          deleteItem(ACCESS_TOKEN);
          // window.location.href = slugs.login;
        }
      }, 5000);
    };
    f();
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    const isRequiredReOpenPWA = JSON.parse(getItem(IS_REQUIRED_RE_OPEN_PWA)!);

    if (isRequiredReOpenPWA) {
      setIsRequiredReOpenPWA(true);
      saveItem(IS_REQUIRED_RE_OPEN_PWA, false);
    }
  }, []);
  useEffect(() => {
    const onResize = () => {
      // update body attribute for styling
      const screenName = getScreenName(window.innerWidth);
      document.body.setAttribute("data-screen", screenName);
      // if (["xxxs", "xxs", "xs", "sm", "md"].includes(screenName)) {
      //   navFunc.setNavOpen("isShowOrder", false);
      // } else {
      //   navFunc.setNavOpen("isShowOrder", true);
      // }
      if (["lg", "xl", "2xl"].includes(screenName)) {
        navFunc.setNavOpen("isShowOrder", true);
      }
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  if (isOutdated) {
    return <UpdateVersion version={version} />;
  }

  if (isRequiredReOpenPWA) {
    return <ReOpenPWA version={version} />;
  }
  return (
    <ThemeContextProvider>
      <LanguageContextProvider>
        <DepositContextProvider>
          <AuthProvider>
            <Navigator />
          </AuthProvider>
        </DepositContextProvider>
      </LanguageContextProvider>
    </ThemeContextProvider>
  );
}

export default App;
