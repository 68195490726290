import { ACCESS_TOKEN, getItem } from "local-storage";
import _ from "lodash";
import slugs from "navigation/slugs";
import React, { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { useNavigate } from "react-router-dom";
import { notiApis } from "utils/apis";
import { isAndroid } from "react-device-detect";
import { parseAddressFromToken } from "utils/index";
import { useAccount } from "hooks/useAccount";
import { isNotAllowNotification } from "utils/notification";
import clsx from "clsx";
import { modalProxy } from "proxy-state/global";

const NotiModalBody = () => {
  const navigate = useNavigate();
  const [notiEnable, setNotiEnable] = useState(false);
  const [notiState, setNotiState] = useState({
    soccer: { status: "inactive", id: "06ad152c-4f8e-4f93-9dd5-1802182c156d" },
    basketball: {
      status: "inactive",
      id: "f89df1b1-591f-4c0a-b326-9f36c2e9b267",
    },
    mma: { status: "inactive", id: "32b8d8ed-a89c-48d0-8301-73965591aba9" },
    baseball: {
      status: "inactive",
      id: "462fd62d-c34c-4d50-a237-e5ec1e0581e7",
    },
    americanFootball: {
      status: "inactive",
      id: "c138442f-dcba-4380-92a2-46467bc5fc40",
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [enableFail, setEnableFail] = useState(false);

  const { address } = useAccount();

  /// Fetch user notifications setting1
  useEffect(() => {
    const f = async () => {
      try {
        if (!address) return;
        const result = await notiApis.getSegment(address);
        const soccer = _.find(result, { name: "soccer" });
        const basketball = _.find(result, { name: "basketball" });
        const mma = _.find(result, { name: "mma" });
        const baseball = _.find(result, { name: "baseball" });
        const americanFootball = _.find(result, { name: "american-football" });
        setNotiState({
          soccer: soccer,
          basketball,
          mma,
          baseball,
          americanFootball,
        });
      } catch (error) {
        console.log("🚀 ~ file: Notification.tsx:20 ~ f ~ error:", error);
      }
    };
    f();
  }, [address]);

  /// Check user allow one signal send noti
  useEffect(() => {
    const f = () => {
      const optedIn = OneSignal.User.PushSubscription.optedIn;

      if (optedIn) {
        setNotiEnable(true);
        setIsLoading(false);
      }
    };

    f();

    const timeout = setTimeout(() => {
      f();
      setIsLoading(false);
    }, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const handle = async (data: any) => {
      /// true -> false
      if (!data?.current?.optedIn) {
        // Never go in this block b/c the first useEffect
        return;
      }

      if (!address) {
        return;
      }

      await OneSignal.login(address).then(() => {});
    };

    OneSignal.User.PushSubscription.addEventListener("change", handle);

    return () => {
      OneSignal.User.PushSubscription.removeEventListener("change", handle);
    };
  }, [address]);

  // ==================================== Test api ====================================1
  // useEffect(() => {
  //   const handle = async (data: any) => {
  //     console.log(
  //       "🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 click  ~ file: Notification.tsx:111 ~ handle ~ data:",
  //       data
  //     );
  //   };

  //   /// 'click' | 'foregroundWillDisplay' | 'dismiss' | 'permissionChange' | 'permissionPromptDisplay'
  //   OneSignal.Notifications.addEventListener("click", handle);

  //   return () => {
  //     OneSignal.Notifications.removeEventListener("click", handle);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handle = async (data: any) => {
  //     console.log(
  //       "🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 foregroundWillDisplay  ~ file: Notification.tsx:111 ~ handle ~ data:",
  //       data
  //     );
  //   };

  //   /// 'click' | 'foregroundWillDisplay' | 'dismiss' | 'permissionChange' | 'permissionPromptDisplay'
  //   OneSignal.Notifications.addEventListener("foregroundWillDisplay", handle);

  //   return () => {
  //     OneSignal.Notifications.removeEventListener(
  //       "foregroundWillDisplay",
  //       handle
  //     );
  //   };
  // }, []);

  // useEffect(() => {
  //   const handle = async (data: any) => {
  //     console.log(
  //       "🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 dismiss  ~ file: Notification.tsx:111 ~ handle ~ data:",
  //       data
  //     );
  //   };

  //   /// 'click' | 'foregroundWillDisplay' | 'dismiss' | 'permissionChange' | 'permissionPromptDisplay'
  //   OneSignal.Notifications.addEventListener("dismiss", handle);

  //   return () => {
  //     OneSignal.Notifications.removeEventListener("dismiss", handle);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handle = async (data: any) => {
  //     console.log(
  //       "🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 permissionChange  ~ file: Notification.tsx:111 ~ handle ~ data:",
  //       data
  //     );
  //   };

  //   /// 'click' | 'foregroundWillDisplay' | 'dismiss' | 'permissionChange' | 'permissionPromptDisplay'
  //   OneSignal.Notifications.addEventListener("permissionChange", handle);

  //   return () => {
  //     OneSignal.Notifications.removeEventListener("permissionChange", handle);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handle = async (data: any) => {
  //     console.log(
  //       "🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 permissionPromptDisplay  ~ file: Notification.tsx:111 ~ handle ~ data:",
  //       data
  //     );
  //   };

  //   /// 'click' | 'foregroundWillDisplay' | 'dismiss' | 'permissionChange' | 'permissionPromptDisplay'
  //   OneSignal.Notifications.addEventListener("permissionPromptDisplay", handle);

  //   return () => {
  //     OneSignal.Notifications.removeEventListener(
  //       "permissionPromptDisplay",
  //       handle
  //     );
  //   };
  // }, []);

  // useEffect(() => {
  //   const handle = async (data: any) => {
  //     console.log(
  //       "🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 slidedownShown  ~ file: Notification.tsx:111 ~ handle ~ data:",
  //       data
  //     );
  //   };

  //   /// 'click' | 'foregroundWillDisplay' | 'dismiss' | 'permissionChange' | 'permissionPromptDisplay'
  //   OneSignal.Slidedown.addEventListener("slidedownShown", handle);

  //   return () => {
  //     OneSignal.Slidedown.removeEventListener("slidedownShown", handle);
  //   };
  // }, []);
  // ==================================== Test api ====================================

  const handleSwitch = async (
    key: "soccer" | "basketball" | "mma" | "baseball" | "americanFootball"
  ) => {
    if (!address) return;
    const clone = _.cloneDeep(notiState);
    const value = clone[key];
    try {
      value.status = value.status === "active" ? "inactive" : "active";
      setNotiState(clone);
      await notiApis.putSegmentSwitchById(address, value.id);
    } catch (error) {
      value.status = value.status === "active" ? "active" : "inactive";
      setNotiState(clone);
      console.log(
        "🚀 ~ file: Notification.tsx:69 ~ Notification ~ error:",
        error
      );
    }
  };

  const handleEnableNoti = async () => {
    try {
      setIsLoading(true);

      const token = getItem(ACCESS_TOKEN)!;
      const address = parseAddressFromToken(token);

      await OneSignal.User.PushSubscription.optIn();

      await OneSignal.login(address).then(() => {
        if (isAndroid) {
          alert("Enable notification!");
        }
        console.log("Login oneSignal successfully1");

        if (window?.Notification?.permission === "denied") {
          setEnableFail(true);
        }
      });

      await OneSignal.Notifications.requestPermission();

      const optedIn = OneSignal.User.PushSubscription.optedIn;

      if (optedIn) {
        setNotiEnable(true);
        setIsLoading(false);
      } else {
        setTimeout(() => {
          const optedIn = OneSignal.User.PushSubscription.optedIn;

          if (optedIn) {
            setNotiEnable(true);
          } else {
            setNotiEnable(false);
          }

          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setNotiEnable(false);
      console.log(
        "🚀 ~ file: Notification.tsx:105 ~ handleEnableNoti ~ error:",
        error
      );
    }
  };

  const handleDisable = async () => {
    try {
      setNotiEnable(false);
      await OneSignal.User.PushSubscription.optOut();
    } catch (error) {
      setNotiEnable(true);
      console.log(
        "🚀 ~ file: Notification.tsx:114 ~ handleDisable ~ error:",
        error
      );
    }
  };

  const handleSwitchNoti = () => {
    if (isLoading) return;

    if (notiEnable) {
      handleDisable();
    } else {
      handleEnableNoti();
    }
  };

  const renderMainContent = () => {
    if (isNotAllowNotification || enableFail) {
      return (
        <div className="flex-1 flex justify-center items-center min-h-[100dvh] p-8 text-base">
          <div className="flex flex-col rounded-lg gap-2 border border-dk-gray-800 p-4">
            <div className="text-primary">
              You have disabled notifications for Goal3 in your Settings. To
              allow, please follow the instructions below:
            </div>
            <div className="flex gap-2 items-start self-stretch">
              <div className="flex min-w-[24px] h-6 w-6 justify-center items-center rounded-full bg-dk-gray-800 text-xs font-medium text-gray-100 uppercase">
                1
              </div>
              <div className="text-gray-500">
                Go to the Settings App on your phone
              </div>
            </div>
            <div className="flex gap-2 items-start self-stretch">
              <div className="flex min-w-[24px] h-6 w-6 justify-center items-center rounded-full bg-dk-gray-800 text-xs font-medium text-gray-100 uppercase">
                2
              </div>
              <div className="text-gray-500">Find the Goal3 app</div>
            </div>
            <div className="flex gap-2 items-start self-stretch">
              <div className="flex min-w-[24px] h-6 w-6 justify-center items-center rounded-full bg-dk-gray-800 text-xs font-medium text-gray-100 uppercase">
                3
              </div>
              <div className="text-gray-500">
                Go to Notifications, then click Allow Notifications
              </div>
            </div>
            <div className="flex gap-2 items-start self-stretch">
              <div className="flex min-w-[24px] h-6 w-6 justify-center items-center rounded-full bg-dk-gray-800 text-xs font-medium text-gray-100 uppercase">
                4
              </div>
              <div className="text-gray-500">Close the app and reopen it</div>
            </div>
            <div className="flex gap-2 items-start self-stretch">
              <div className="flex min-w-[24px] h-6 w-6 justify-center items-center rounded-full bg-dk-gray-800 text-xs font-medium text-gray-100 uppercase">
                5
              </div>
              <div className="text-gray-500">
                You should be able to click on Enable Notifications now
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="p-4 md:p-6 !pb-8 gap-8 flex flex-col fadein text-base">
        <div className="space-y-4">
          <div className="flex flex-col bg-base-100 px-3 rounded-box">
            <label
              className="flex items-center justify-between py-4 px-2 cursor-pointer"
              onClick={handleSwitchNoti}
            >
              <span className="flex items-center text-base-content">
                <span className="flex items-center justify-center w-6 mr-4">
                  {isLoading ? (
                    <i className="fa-solid fa-spin fa-circle-notch text-lg"></i>
                  ) : (
                    <i className="fa-duotone fa-bell icon-primary text-lg"></i>
                  )}
                </span>
                <span className="">Enable Notifications</span>
              </span>
              <div className="form-control">
                <input
                  type="checkbox"
                  className={clsx("toggle toggle-primary", {
                    "cursor-none": isLoading,
                  })}
                  checked={notiEnable}
                  onChange={handleSwitchNoti}
                />
              </div>
            </label>
          </div>
        </div>

        <div className={`space-y-4 ${!notiEnable && "disabled"}`}>
          <div className="text-xs uppercase tracking-wide opacity-70 ml-3">
            New match alerts
          </div>

          <div className="flex flex-col bg-base-100 px-3 rounded-box">
            <label className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <span className="flex items-center text-base-content">
                <span className="flex items-center justify-center w-6 mr-4">
                  <i className="fa-duotone fa-futbol text-lg"></i>
                </span>
                <div className="flex flex-col gap-1">
                  <span className="">Soccer</span>
                </div>
              </span>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={notiState["soccer"].status === "active"}
                  onChange={() => {
                    handleSwitch("soccer");
                  }}
                />
              </div>
            </label>

            <span
              className="ml-12 mr-2 h-[1px] bg-base-content/10"
              role="separator"
            ></span>

            <label className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <span className="flex items-center text-base-content">
                <span className="flex items-center justify-center w-6 mr-4">
                  <i className="fa-duotone fa-basketball text-lg"></i>
                </span>
                <div className="flex flex-col gap-1">
                  <span className="">Basketball</span>
                </div>
              </span>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={notiState["basketball"].status === "active"}
                  onChange={() => {
                    handleSwitch("basketball");
                  }}
                />
              </div>
            </label>

            <span
              className="ml-12 mr-2 h-[1px] bg-base-content/10"
              role="separator"
            ></span>

            <label className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <span className="flex items-center text-base-content">
                <span className="flex items-center justify-center w-6 mr-4">
                  <i className="fa-duotone fa-baseball-bat-ball text-lg"></i>
                </span>
                <div className="flex flex-col gap-1">
                  <span className="">Baseball</span>
                </div>
              </span>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={notiState["baseball"].status === "active"}
                  onChange={() => {
                    handleSwitch("baseball");
                  }}
                />
              </div>
            </label>

            <span
              className="ml-12 mr-2 h-[1px] bg-base-content/10"
              role="separator"
            ></span>

            <label className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <span className="flex items-center text-base-content">
                <span className="flex items-center justify-center w-6 mr-4">
                  <i className="fa-solid fa-football"></i>
                </span>
                <div className="flex flex-col gap-1">
                  <span className="">American Football</span>
                </div>
              </span>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={notiState["americanFootball"].status === "active"}
                  onChange={() => {
                    handleSwitch("americanFootball");
                  }}
                />
              </div>
            </label>

            <span
              className="ml-12 mr-2 h-[1px] bg-base-content/10"
              role="separator"
            ></span>

            <label className="flex items-center justify-between py-4 px-2 cursor-pointer">
              <span className="flex items-center text-base-content">
                <span className="flex items-center justify-center w-6 mr-4">
                  <i className="fa-duotone fa-hand-fist text-lg"></i>
                </span>
                <div className="flex flex-col gap-1">
                  <span className="">MMA</span>
                </div>
              </span>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={notiState["mma"].status === "active"}
                  onChange={() => {
                    handleSwitch("mma");
                  }}
                />
              </div>
            </label>
          </div>
        </div>

        {/* <div className="space-y-4">
        <div className="text-xs uppercase tracking-wide opacity-70 ml-3">Others</div>

        <div className="flex flex-col bg-base-200 px-3 rounded-box">
          <a
            href="https://portal.goal3.xyz/deposit"
            target={"_blank"}
            rel="noreferrer"
            className="flex items-center justify-between py-4 px-2"
          >
            <span className="flex items-center text-base-content">
              <span className="flex items-center justify-center w-6 mr-4">
                <i className="fa-duotone fa-coins text-lg"></i>
              </span>
              <span className="">Deposit</span>
            </span>
            <span className="flex items-center">
              <span className="text-sm opacity-50 mr-3">via Goal3 Portal</span>
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </span>
          </a>

          <span className="ml-12 mr-2 h-[1px] bg-base-content/10" role="separator"></span>

          <a
            href="https://portal.goal3.xyz/withdraw"
            target={"_blank"}
            rel="noreferrer"
            className="flex items-center justify-between py-4 px-2"
          >
            <span className="flex items-center text-base-content">
              <span className="flex items-center justify-center w-6 mr-4">
                <i className="fa-duotone fa-money-from-bracket text-lg"></i>
              </span>
              <span className="">Withdraw</span>
            </span>
            <span className="flex items-center">
              <span className="text-sm opacity-50 mr-3">via Goal3 Portal</span>
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </span>
          </a>
        </div>
      </div> */}
      </div>
    );
  };

  return (
    <>
      <div
        className={`sticky top-0 left-0 right-0 px-4 md:px-6 py-5 z-50 bg-base-200/80 backdrop-blur text-center`}
      >
        <button
          className="btn btn-sm rounded-full btn-ghost mr-2 absolute left-4 top-4 gap-2 !text-primary font-normal text-base"
          onClick={() => {
            modalProxy.isNotiSettingOpen = false;
          }}
        >
          <i className="fa-solid fa-angle-left text-xl"></i>
          Profile
        </button>
        <div className="inline-flex text-base font-semibold">
          <h3>Notifications</h3>
        </div>
      </div>

      {renderMainContent()}
    </>
  );
};
export default NotiModalBody;
