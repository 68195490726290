import { useHistory } from "hooks/useHistory";
import React, { useEffect } from "react";
import OrderHistory from "./history";
import ClaimedHistory from "../ClaimedHistory";
import { useSnapshot } from "valtio";
import { BetFilterProxy } from "proxy-state/betting/myBetFilter";
import { IPageType } from "shared/enum";

const HistoryLayout: React.FC = () => {
  const { pageType, layoutType, orderBy, timeFilter, } = useSnapshot(BetFilterProxy);
  const { search, setSearch, setTimeFilter, setFilterOrderBy, ...props } =
    useHistory();
  useEffect(() => {
    setTimeFilter(timeFilter)
  }, [timeFilter])
  useEffect(() => {
    setFilterOrderBy(orderBy);
  }, [orderBy])
    return (
    <>
      {pageType === IPageType.ORDER && <OrderHistory view={layoutType} {...props} />}
      {pageType === IPageType.CLAIMS && <ClaimedHistory />}
    </>
  );
};
export default HistoryLayout;
