import moment from "moment";
import { floatFormat } from "common/utils";
import LinkScan from "common/LinkScan";
import ParlayBet from "../../betCard/parlay";
import { BigNumber } from "ethers";
import { AMM } from "../../../../../functions/amm";
import { SingleBet } from "../../betCard";
import utils from "../../../../../common/utils";
import { WEI6 } from "functions/betUtils";
import { useSnapshot } from "valtio";
import { leagueProxy } from "proxy-state/leagueProxy";
import { findById } from "utils/proxy";
import { configProxy } from "proxy-state/configProxy";

const fetchMarketByOutcomeId = (outcomeId: number, markets: any) => {
  if (!markets) return;
  let result: any;
  for (let m in markets) {
    const market = markets[m].find((item: any) =>
      item?.odds?.find((odd: any) => odd?.outcome_id === outcomeId)
    );
    if (market) {
      result = market;
      break;
    }
  }
  return result;
};
interface MyOrderGridProps {
  ordersData: any;
  eventsData: any;
  type: string;
  loading: boolean;
}
const MyOrderGrid: React.FC<MyOrderGridProps> = (props) => {
  const { data: leagues } = useSnapshot(leagueProxy);
  const { symbol } = useSnapshot(configProxy);
  const { ordersData, eventsData, loading } = props;
  const itemClass = "bet group fadein";

  const EmptyOrder = () => {
    return (
      <div className="mt-6">
        <div className="grid grid-cols-1">
          <div className="mt-6">
            <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
              <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
              <span className="text-[14px] font-bold">{`"A dollar won is better than a dollar earned"`}</span>
              <span className="text-xs opacity-70 text-center mt-1 italic">
                {"- Elon Musk, DogeCoin"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* {loading && <HistorySkeleton length={8} />} */}
      {/* {!loading && ordersData.length === 0 && <EmptyOrder />} */}
      {ordersData.map((item: any, idx: any) => {
        const potentialWin =
          item?.wagers && item?.wagers.length
            ? item?.wagers?.reduce(
                (a: any, b: any) =>
                  a +
                  (Number(b.redeemable) > 0
                    ? Number(b.redeemable)
                    : Number(b.amount || b.history_wager?.amount || 0)),
                0
              )
            : 0;
        const OrderPayout =
          item?.wagers
            ?.filter((w: any) => Number(w?.status) === 4)
            .reduce(
              (a: any, b: any) =>
                a +
                (Number(b.redeemable) > 0
                  ? Number(b.redeemable)
                  : Number(b.amount || b.history_wager?.amount || 0)),
              0
            ) || 0;
        let _item_status = 4;
        let wager_status = 0;

        let oddValue = 1;
        const _outcomeIds = item?.outcomes;
        const flagTeam2: any[] = [];
        const matchesNumber: Array<number> = [];
        const _outcomes =
          _outcomeIds &&
          _outcomeIds.map((outcomeId: number, idx: any) => {
            if (outcomeId) {
              const flagTeam: any[] = [];
              const eventId = AMM.parseOutcomeId(
                BigNumber.from(outcomeId)
              ).eventId;
              matchesNumber.push(eventId);
              const event = eventsData?.find(
                (item: any) => Number(item?.id) === eventId
              );
              if (event === undefined) return null;

              const league = findById(leagues as any[], event?.league_id);
              const eventName = event?.home?.name + " vs " + event?.away?.name;
              const eventUrl = `${league?.url_path}/${event?.url_key}`;
              const eventItem = fetchMarketByOutcomeId(
                outcomeId,
                event?.markets
              );
              const oddWinList = event?.markets
                ? utils.getOddWin(event?.markets)
                : [];
              const oddItem = eventItem?.odds?.find(
                (outcome: any) => outcome?.outcome_id === outcomeId
              );
              const oddStatus =
                event?.resolved === 1
                  ? oddWinList.includes(oddItem?.id?.toString())
                    ? 4
                    : 3
                  : Number(event?.time_status) === 4 || event?.cancelled
                  ? 5
                  : 0;
              flagTeam.push({
                img_id: event?.home?.image_id,
                type: league?.category_id,
              });
              flagTeam.push({
                img_id: event?.away?.image_id,
                type: league?.category_id,
              });
              // if (['single', 'combo'].includes(item?.type?.toLowerCase())) {
              // 	if (Number(oddStatus) === 0 && _item_status !== 3) _item_status = 0;
              // 	if (Number(oddStatus) === 3) _item_status = 3;
              // }
              // if (item?.type === 'combo') oddValue = oddValue * Number(o?.odds?.roi);
              if (
                item?.type === "system" &&
                Number(oddStatus) === 4 &&
                _item_status !== 0
              ) {
                _item_status = 4;
              }
              if (item?.type === "system" && Number(oddStatus) === 0) {
                _item_status = 0;
              }
              flagTeam2.push(flagTeam);
              return {
                eventUrl,
                eventId,
                amount: item?.amounts?.[idx],
                cancelled: event?.cancelled,
                name: eventItem?.name,
                eventName: eventName,
                home: event?.home?.name,
                away: event?.away?.name,
                key: eventItem?.key,
                marketName: eventItem?.name,
                odds: oddItem,
                status: oddStatus,
                league_id: event?.league_id,
                league: league?.name,
                leagueIcon: league?.icon_classes,
                flagTeam: [
                  {
                    img_id: event?.home?.image_id,
                    type: league?.category_id,
                  },
                  {
                    img_id: event?.away?.image_id,
                    type: league?.category_id,
                  },
                ],
                oddValue: eventItem?.odds?.find(
                  (outcome: any) => outcome?.outcome_id === outcomeId
                )?.odds?.roi,
              };
            }
          });

        item.outcomeInfo = _outcomes.filter((item: any) => item !== undefined);
        item.item_status = wager_status;
        item.matchesNumber = [...matchesNumber].length;
        item.flagTeam = flagTeam2;
        item.status = _item_status;
        if (item?.type === "combo") {
          item.totalOdds = oddValue;
        }
        if (item?.type === "single" && item?.outcomes?.length > 1) {
          item?.outcomes?.forEach((out: any) => {
            if (out?.status === 4) {
              item.status = 4;
            }
          });
        }
        item.wonCount = item?.outcomeInfo?.filter(
          (o: any) => Number(o?.status) === 4
        ).length;
        item.lostCount = item?.outcomeInfo?.filter(
          (o: any) => Number(o?.status) === 3
        ).length;
        item.openCount = item?.outcomeInfo?.filter(
          (o: any) => Number(o?.status) === 0
        ).length;
        if (item?.type === "single" || item?.type === "system") {
          item.item_status =
            item?.openCount !== 0 ? 0 : item?.wonCount !== 0 ? 4 : 3;
          if (item?.outcomeInfo.length === 1) {
            item.item_status = item?.outcomeInfo[0]?.status;
          }
        } else if (item?.type === "combo") {
          item.item_status =
            item?.lostCount !== 0 ? 3 : item?.openCount !== 0 ? 0 : 4;
        }
        if (item?.order_status === "pending") {
          item.item_status = 6;
        }
        if (item?.order_status === "failed") {
          item.item_status = 7;
        }
        
        // if(!item?.outcomes) return null;
        return (
          <div className={`${itemClass}`} key={idx}>
            <div className="bet-header text-xs">
              <div className="flex items-center">
                <div className="dropdown">
                  <label tabIndex={0} className="dropdown-toggle">
                    <button className="dropdown-toggle-title">
                      {/* <span className="flex items-center text-xs opacity-70">
												<span className="truncate">More</span>
											</span> */}
                      <i className="fa-solid fa-bars text-xs"></i>
                    </button>
                  </label>
                  <div tabIndex={0} className="dropdown-content menu w-52">
                    {item?.txHash && (
                      <button className="dropdown-menu-item">
                        <span className="flex items-start">
                          <span className="flex items-baseline">
                            <i className="fa-regular fa-square-up-right mr-2 w-5"></i>
                            <LinkScan
                              linkView={item?.txHash}
                              title={`Order Tx.`}
                              classes={`truncate hover:text-primary`}
                              icon={false}
                              subClasses={` `}
                            />
                          </span>
                        </span>
                      </button>
                    )}
                    {item?.claimed_txn && (
                      <button className="dropdown-menu-item">
                        <span className="flex items-start">
                          <span className="flex items-baseline">
                            <i className="fa-regular fa-square-up-right mr-2 w-5"></i>
                            <LinkScan
                              linkView={item?.claimed_txn}
                              title={`Claim Tx.`}
                              classes={`truncate hover:text-primary`}
                              icon={false}
                              subClasses={` `}
                            />
                          </span>
                        </span>
                      </button>
                    )}
                    {item?.isRefunded && (
                      <button className="dropdown-menu-item">
                        <span className="flex items-start">
                          <span className="flex items-baseline">
                            <i className="fa-regular fa-square-up-right mr-2 w-5"></i>
                            <span className="truncate">Refunded Tx.</span>
                          </span>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <span className="text-base-content ml-2 uppercase font-semibold">
                  {item?.type === "combo" ? "Parlay" : item?.type}
                </span>
                <span className="text-base-content text-opacity-70 ml-1">
                  •
                </span>
                <span className="text-base-content text-opacity-70 ml-1">
                  {moment(item?.transactionTime).fromNow()}
                </span>
              </div>
              <div className="">
                {item?.order_status === "pending" ? (
                  <>
                    <span className="uppercase font-semibold opacity-70">
                      <i className="fa-solid fa-spin fa-circle-notch mr-1 text-xs"></i>
                      Pending
                    </span>
                  </>
                ) : item?.order_status === "failed" ? (
                  <>
                    <span className="uppercase font-semibold text-error">
                      Failed
                    </span>
                  </>
                ) : (
                  <>
                    {item?.type === "single" &&
                      item?.outcomeInfo.length === 1 && (
                        <>
                          {item?.outcomeInfo.length === 1 ? (
                            <>
                              {item?.outcomeInfo[0]?.status === 4 ? (
                                <>
                                  <span className="uppercase font-semibold text-success">
                                    Won
                                  </span>
                                </>
                              ) : item?.outcomeInfo[0]?.status === 3 ? (
                                <>
                                  <span className="uppercase font-semibold text-error">
                                    Lost
                                  </span>
                                </>
                              ) : item?.outcomeInfo[0]?.status === 5 ||
                                Number(item?.outcomeInfo[0]?.cancelled) ===
                                  1 ? (
                                <>
                                  <span className="uppercase font-semibold text-warning">
                                    Voided
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="uppercase font-semibold">
                                    Open
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {!item?.wonCount && !item?.lostCount
                                ? `OPEN`
                                : `${
                                    item?.wonCount !== 0
                                      ? `${item?.wonCount}W`
                                      : ""
                                  }${
                                    item?.wonCount !== 0 &&
                                    item?.lostCount !== 0
                                      ? ` • `
                                      : ""
                                  }${
                                    item?.lostCount !== 0
                                      ? `${item?.lostCount}L`
                                      : ""
                                  }`}
                            </>
                          )}
                        </>
                      )}
                    {item?.outcomeInfo.length > 1 && (
                      <>
                        {item?.item_status !== 0 && (
                          <>
                            <span className="opacity-70">
                              {item?.wonCount !== 0 ? `${item?.wonCount}W` : ""}
                              {item?.wonCount !== 0 && item?.lostCount !== 0
                                ? ` • `
                                : ""}
                              {item?.lostCount !== 0
                                ? `${item?.lostCount}L`
                                : ""}
                              {item?.type !== "single" && (
                                <>
                                  <i className="fa-light fa-long-arrow-right mx-2"></i>
                                </>
                              )}
                            </span>
                            {item?.type !== "single" && (
                              <>
                                <span
                                  className={`uppercase font-semibold ${
                                    item?.item_status === 4
                                      ? "text-success"
                                      : ""
                                  } ${
                                    item?.item_status === 3 ? "text-error" : ""
                                  } ${
                                    item?.item_status === 5
                                      ? "text-warning"
                                      : ""
                                  }`}
                                >
                                  {item?.item_status === 0 && "Open"}
                                  {item?.item_status === 4 && "Won"}
                                  {item?.item_status === 3 && "Lost"}
                                  {item?.item_status === 5 && "Refunded"}
                                </span>
                              </>
                            )}
                          </>
                        )}
                        {item?.item_status === 0 && (
                          <span className="uppercase font-semibold">Open</span>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="bet-body">
              {/* item render */}
              {item?.type === "single" && item?.outcomeInfo.length === 1 ? (
                <SingleBet
                  item={item}
                  type={"order"}
                  potentialWin={potentialWin}
                />
              ) : (
                <ParlayBet
                  item={item}
                  type={"order"}
                  potentialWin={potentialWin}
                />
              )}

              {/* end item render */}
              <div className="mx-4 py-4 border-t border-dashed border-base-content border-opacity-10">
                <div className="px-0 flex justify-between items-center">
                  <span className="text-xs text-base-content text-opacity-70">
                    Total
                  </span>
                  <div className="pl-2 flex items-center text-xs font-medium">
                    {floatFormat(Number(item?.total_amount) / WEI6.toNumber())}
                    <span className="ml-1 font-medium">{symbol}</span>
                  </div>
                </div>
                {/* {item?.isWon && (
                  <div className="px-0 flex justify-between items-center mt-1">
                    <span className="text-sm font-medium">You Won</span>
                    <div className="pl-2 flex items-center text-sm font-semibold text-primary">
                      {item?.wonAmount}
                      <span className="ml-1 font-semibold">{symbol}</span>
                    </div>
                  </div>
                )} */}
                {(potentialWin !== 0 || OrderPayout > 0) && (
                  <div className="px-0 flex justify-between items-center mt-1">
                    <span className="text-xs text-base-content text-opacity-70">
                      {(Number(item?.status) === 4 ||
                        item?.type === "single") &&
                      OrderPayout > 0
                        ? "Won"
                        : "Potential Win"}
                    </span>
                    <div
                      className={`pl-2 flex items-center text-xs font-medium`}
                    >
                      <span
                        className={`${
                          Number(item?.status) === 3 ? "line-through" : ""
                        }`}
                      >
                        {Number(item?.status) > 0 && OrderPayout !== 0
                          ? floatFormat(OrderPayout / WEI6.toNumber())
                          : floatFormat(potentialWin / WEI6.toNumber())}
                      </span>
                      <span className="ml-1 font-medium">{symbol}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default MyOrderGrid;
