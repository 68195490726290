import {
  MainNavigation,
  MobileNavigation,
  Responsive,
} from "components/commons";
import React, { Fragment } from "react";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import GlobalModal from "components/organisms/Modals";
import { GlobalModal as GlobalModalV2 } from "components/modal/GlobalModal";
import Header from "components/organisms/Header";
import slugs from "navigation/slugs";
import HeaderSub from "components/organisms/Header/header-sub";
import clsx from "clsx";
import { useSnapshot } from "valtio";
import { navFunc, navProxy } from "proxy-state/global";
import OrderSlips from "components/organisms/PlaceOrder";
import { Addresses } from "components/shared/Footer";
import { useAuth } from "contexts/authContext";
import { isIOS } from "react-device-detect";
import Snow from "components/layouts/MainLayout/snow";

const isInStandaloneMode = window.matchMedia(
  "(display-mode: standalone)"
).matches;
interface Props {
  children: React.ReactNode;
}
const paneLeftClass = "fixed left-0 top-0 bottom-0 z-50";
const paneCenterClass = "flex flex-col w-full";
const paneRightClass =
  "bg-base-200 shadow-2xl fixed top-0 bottom-0 right-0 overflow-hidden z-50 w-full lg:w-80 transition-all";

export const MainLayout: React.FC<Props> = ({ children }) => {
  const { isCollapse, isShowOrder } = useSnapshot(navProxy);

  const isSportsMatch = useMatch(slugs.sports);
  const isCategory = useMatch(slugs.category);
  const isLeague = useMatch(slugs.league);
  const isEvent = useMatch(slugs.event);

  const isSportCheck = isSportsMatch || isCategory || isLeague || isEvent;

  // const { token: isAuthenticated } = useAuth();
  // return isAuthenticated ? (
  return (
    <Fragment>
      <>
        <div className={`main-layout--wrapper min-h-screen pb-20 lg:pb-0`}>
          <div className="flex items-stretch w-full h-full z-[1] relative">
            {/* <Snow /> */}
            <Responsive from={"lg"}>
              <div
                className={`${paneLeftClass} ${isCollapse ? "w-14" : "w-60"}`}
              >
                <MainNavigation collapse={isCollapse} />
              </div>
            </Responsive>

            <Responsive upto={"md"}>
              <MobileNavigation />
            </Responsive>

            <div
              className={`${paneCenterClass}  ${
                isCollapse ? "lg:pl-14" : "lg:pl-60"
              } ${!isShowOrder ? "lg:pr-0" : "lg:pr-80"}`}
            >
              {" "}
              <div
                className={`pane-center--body ${
                  !isShowOrder ? "" : "lg:overflow-y-auto lg:overflow-x-hidden"
                }`}
              >
                <div className="flex flex-col flex-1 min-h-full items-stretch pb-8 lg:pb-0">
                  <Responsive upto={"md"}>
                    {isSportCheck ? <HeaderSub /> : <Header />}
                  </Responsive>

                  <Responsive from={"lg"}>
                    <Header />
                  </Responsive>

                  <main className="h-full flex-1">{children}</main>
                  <br className="clear-both" />
                  <Responsive from={"lg"}>
                    <div className="mt-6 w-full text-sm">
                      <Addresses />
                    </div>
                  </Responsive>
                </div>
              </div>
            </div>
            {/* <Responsive from={"md"}> */}
            <aside
              className={`${paneRightClass} ${
                isInStandaloneMode && isIOS && "pt-10"
              } ${
                !isShowOrder
                  ? "-right-full translate-x-full"
                  : "right-0 translate-x-0"
              }`}
            >
              <OrderSlips />
            </aside>
            {/* </Responsive> */}
          </div>
        </div>
        <GlobalModal />
        <GlobalModalV2 />
      </>
    </Fragment>
  )
  //  : (
  //   <Navigate to={slugs.login} replace />
  // );
};
