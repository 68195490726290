import { PAGINATION_SIZE } from "shared/constants";

export function Pagination(
  props: React.PropsWithChildren<{
    currentPage: number;
    position: "start" | "center" | "end";
    pageChanged: (page: number) => unknown;
    count: number;
    nextButtonRef?: React.RefObject<HTMLButtonElement>;
    pageSize?: number;
  }>
) {
  const pageSize = props.pageSize || PAGINATION_SIZE;
  const { position, currentPage } = props;

  const totalPages = Math.floor(props.count / pageSize);
  const canGoBack = props.currentPage >= 1;
  const canGoNext = props.currentPage < totalPages;

  const from = currentPage * pageSize + 1;
  const to = (currentPage + 1) * pageSize;

  return (
    <div
      className={`flex flex-row ${
        position === "start"
          ? "justify-start"
          : position === "center"
          ? "justify-center"
          : "justify-end"
      }`}
    >
      <div className="px-4 text-base-content text-opacity-70 mt-2 text-xs font-medium">
        Showing: {from} - {to}
      </div>
      <div className="space-x-1 md:space-x-2">
        <button
          className={`btn btn-sm rounded-full ${canGoBack ? "" : "disabled"}`}
          onClick={() => props.pageChanged(props.currentPage - 1)}
        >
          Prev
        </button>
        <button
          ref={props.nextButtonRef}
          className={`btn btn-sm rounded-full ${canGoNext ? "" : "disabled"}`}
          onClick={() => {
            if (!canGoNext) return;
            props.pageChanged(props.currentPage + 1);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
