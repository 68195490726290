import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";
import { memo } from "react";
import { mainRouters } from "./registryRoutes";
// import { OrderProvider } from "contexts/orderContext";
import Error404 from "components/layouts/ErrorLayout/404";
import Toast from "components/commons/Toast";
import Container from "components/container/Container";
import AppLoading from "components/commons/Loading/AppLoading";

const childrens = mainRouters.map((router, idx: number) => {
  const { component: Component, container: ContainerComponent, path } = router;

  return {
    key: idx.toString(),
    path,
    element: (
      <ContainerComponent>
        <Component />
      </ContainerComponent>
    ),
  };
});

const router = createBrowserRouter([
  {
    element: (
      <>
        <Outlet />
        <ScrollRestoration getKey={(location) => location.pathname} />
      </>
    ),
    children: [
      ...childrens,
      {
        key: "not_found",
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]);

const AppRouter = memo(() => {
  return (
    // <OrderProvider>
      <Container>
        <RouterProvider router={router} fallbackElement={<AppLoading />} />
      </Container>
    // </OrderProvider>
  );
});

const Navigator = () => {
  return (
    <>
      <AppRouter />
      <Toast />
    </>
  );
};

export default Navigator;
