import React, { Fragment, useEffect, useRef } from "react";
import WagerDetail from "./detail";
import { useWager } from "../../../../hooks/useWager";
import HistorySkeleton from "components/commons/Loading/HistorySkeleton";
import { Pagination } from "components/organisms/Pagination/Pagination";
import { PAGINATION_SIZE } from "shared/constants";
import { isMobile } from "react-device-detect";
import { Responsive as Screen } from "components/commons";
import { useSnapshot } from "valtio";
import { BetFilterProxy } from "proxy-state/betting/myBetFilter"
import { IWagerStatus } from "shared/enum";
interface wagerNFTProps {
  claimLoading: boolean;
  minAmountFreeGas: number;
  networkFee: number;
  wagerIdsClaimed: any[];
}

const WagerNFT: React.FC<wagerNFTProps> = (props) => {
  const {UnClaimed, wagerStatus, timeFilter, orderBy} = useSnapshot(BetFilterProxy);
  const {
    claimLoading,
    wagerIdsClaimed,
    networkFee,
  } = props;
  const {
    loading,
    setTimeFilter,
    setUnClaimOnly,
    wagersData: wagers,
    page,
    setPage,
    wagerFilter,
    setWagerFilter,
    count,
    onPageChanged,
    setFilterOrderBy,
  } = useWager();

  const observerTarget = useRef(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const TabList = [
    IWagerStatus.ALL,
    IWagerStatus.OPEN,
    IWagerStatus.WON,
    IWagerStatus.LOST,
    IWagerStatus.REFUND,
  ];
  // init filter by proxy
  useEffect(() => {
    setUnClaimOnly(UnClaimed);
  }, [UnClaimed])
  useEffect(() => {
    setTimeFilter(timeFilter);
  }, [timeFilter])
  useEffect(() => {
    setFilterOrderBy(orderBy);
  }, [orderBy])
  
  
  useEffect(() => {
    if (TabList.includes(wagerStatus)) {
      let _tab =
        wagerStatus === "Open"
          ? 0
          : wagerStatus === "Won"
          ? 4
            : wagerStatus === "Lost"
          ? 3
              : wagerStatus === "Refund"
          ? 5
          : 1;
      if (wagerFilter !== _tab) {
        setWagerFilter(_tab);
        setTimeFilter("All Times");
        setPage(0);
        if (wagerStatus !== "Won") setUnClaimOnly(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wagerStatus]);

  const wagerLoading = loading;
  const EmptyOrder: React.FC<{ tab: string }> = ({ tab }) => {
    return (
      <div className="grid grid-cols-1">
        <div className="mt-6">
          <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
            <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
            <span className="text-lg font-bold">
              {tab === "Open" ? (
                <span className="text-[14px] font-bold">{`"A dollar won is better than a dollar earned"`}</span>
              ) : tab === "Won" ? (
                `"I don't wait for luck, I create my own"`
              ) : (
                `"You don't come here to lose"`
              )}
            </span>
            <span className="text-xs opacity-70 text-center mt-1 italic">
              {tab === "Open"
                ? "- Elon Musk, DogeCoin"
                : tab === "Won"
                ? "- Lee, Goal3.xyz"
                : "- This page should not exist"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  /// dev: handle infinity scroll ::::::: trigger click next button for simulate pagination on mobile
  useEffect(() => {
    let observer: IntersectionObserver;

    observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // console.log("visible");
          nextButtonRef.current?.click();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current && observer) {
        // console.log("unmount");
        observer.unobserve(observerTarget.current);
      }
    };
  }, []);

  const renderMainList = () => {
    return (
      <div
        id="my-wager-list"
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
      >
        {wagers.length > 0 &&
          wagers.map((item, index: number) => {
            const detailProps = {
              item,
              loadingClaim: loading || claimLoading,
              wagerIdsClaimed: wagerIdsClaimed,
              type: "wager",
              minAmountFreeGas: props.minAmountFreeGas,
              networkFee: networkFee,
            };
            return (
              <Fragment key={index}>
                <WagerDetail {...detailProps} />
              </Fragment>
            );
          })}
      </div>
    );
  };

  const renderMainContent = () => {
    if (!wagerLoading && wagers.length === 0) return <EmptyOrder tab={wagerStatus} />;

    /// desktop
    if (!isMobile) {
      if (wagerLoading) {
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            <HistorySkeleton length={8} />
          </div>
        );
      }

      return renderMainList();
    }

    /// mobile
    return (
      <>
        {renderMainList()}
        {wagerLoading && <HistorySkeleton length={wagers.length > 0 ? 2 : 8} />}
      </>
    );
  };

  return (
    <Fragment>
      {/* <div className="mt-6 md:mt-8"> */}
        {renderMainContent()}
        {/* {!wagerLoading && wagers.length === 0 && <EmptyOrder tab={tab} />}
      {wagers.length > 0 && (
        <div
          id="my-wager-list"
          className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-4"
        >
          {wagers.length > 0 &&
            wagers.map((item, index: number) => {
              const detailProps = {
                item,
                loadingClaim: loading || claimLoading,
                wagerIdsClaimed: wagerIdsClaimed,
                type: "wager",
                minAmountFreeGas: props.minAmountFreeGas,
                networkFee: networkFee,
              };
              return (
                <Fragment key={index}>
                  <WagerDetail {...detailProps} />
                </Fragment>
              );
            })}
        </div>
      )}
      {wagerLoading && <HistorySkeleton length={wagers.length > 0 ? 2 : 8} />} */}

        <Screen upto="md">
          <div
            className="flex flex-col gap-4 pl-4 pr-2"
            ref={observerTarget}
          ></div>
        </Screen>
        {/* {loadingMore && <HistorySkeleton length={1} />} */}
        {/* {!loadingMore && lastVisible && wagers.length > 0 && (
        <div className="mt-8 text-sm text-base-content/50 text-center">
          End of List
        </div>
      )} */}
        {/* <div className="mt-6">
        {wagerDataPage &&
          (wagerDataPage?.docs.length >= PAGINATION_SIZE || page >= 1) && (
            <PaginationV1
              nextCursorPointer={nextCursorPointer}
              prevCursorPointer={prevCursorPointer}
              setIsNext={setIsNext}
              setIsPrev={setIsPrev}
              position={PaginationType.End}
              pageSize={PAGINATION_SIZE}
              currentPageSize={
                wagerDataPage?.docs.length === PAGINATION_SIZE + 1
                  ? PAGINATION_SIZE
                  : wagerDataPage?.docs.length || 0
              }
              curPage={page}
              setCurPage={setPage}
            />
          )}
      </div> */}
      {/* </div> */}
      {count > PAGINATION_SIZE && (
        <div className="hidden md:block">
          <Pagination
            count={count}
            currentPage={page}
            pageChanged={onPageChanged}
            position={"end"}
            nextButtonRef={nextButtonRef}
          />
        </div>
      )}
    </Fragment>
  );
};
export default WagerNFT;
