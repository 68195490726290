import { proxy } from "valtio";

export const connectWalletProxy = proxy({
  status: false,
});

export const setWalletStatus = (status: boolean) => {
  connectWalletProxy.status = status;
};
export const appLoading = proxy({
  loading: false,
  setLoading: (status: boolean) => {
    appLoading.loading = status;
  },
});
type navState = {
  isCollapse: boolean;
  isShowOrder: boolean;
};

export const navProxy = proxy<navState>({
  isCollapse: false,
  isShowOrder: false,
});

export const navFunc = {
  setNavOpen: (key: keyof navState, value: any) => {
    navProxy[key] = value;
  },
};

// interface BreadCrumbItem {
//   name: string;
//   href: string;
//   current: boolean;
//   key?: string;
//   type?: string;
// }
let BreadCrumbProxy = proxy({
  breadCrumb: [],
  home: process.env.PUBLIC_URL,
  setBreadCrumb: (data: any) => {
    BreadCrumbProxy.breadCrumb = data;
  },
});

export { BreadCrumbProxy };

type ModalState = {
  isBetSlipOpen: boolean;
  isMenuOpen: boolean;
  isChatOpen: boolean;
  isNotiOpen: boolean;
  isPremiumOpen: boolean;
  isProfileOpen: boolean;
  isNotiSettingOpen: boolean;
  isFooterOpen: boolean;
};

export const modalProxy = proxy<ModalState>({
  isBetSlipOpen: false,
  isMenuOpen: false,
  isChatOpen: false,
  isNotiOpen: false,
  isPremiumOpen: false,
  isProfileOpen: false,
  isNotiSettingOpen: false,
  isFooterOpen: false,
});

export const modalFunc = {
  setModalOpen: (key: keyof ModalState, value: boolean) => {
    modalProxy[key] = value;
  },
};

interface CurrentTab {
  Home:
    | {
        "Top Matches"?: {
          subTab?: string;
        };
        Activities?: {
          subTab?: string;
        };
        currentTab: string;
      }
    | any;
  Sports:
  | {
    "Matches"?: {
      subTab?: string;
    };
    currentTab: string;
  }
  | any;
  "My Bets":
    | {
        Bets?: {
          subTab?: string;
        };
        History?: {
          subTab?: string;
        };
        currentTab: string;
      }
    | any;
}

export const tabDefault: CurrentTab = {
  Home: {
    "Top Matches": { subTab: "soccer" },
    Activities: { subTab: "Recent" },

    currentTab: "Top Matches",
  },
  "Sports": {
    "Top Matches": { subTab: "soccer" },

    currentTab: "Top Matches",
  },

  "My Bets": {
    Bets: {
      subTab: "All",
    },
    History: {
      subTab: "Orders",
    },
    currentTab: "Bets",
  },
};

export const navigateTab = proxy<{ tab: CurrentTab }>({
  tab: tabDefault,
});

export const setNavTab = (page: "Home" | "Sports" | "My Bets", key: any, value?: any) => {
  navigateTab.tab[page].currentTab = key;

  if (!value) return;
  navigateTab.tab[page][key].subTab = value;
};
