import React, { Fragment } from "react";
import moment from "moment";
import { Disclosure } from "@headlessui/react";
import OddElements from "../OddElements";

const MarketLong = ({ item, market, filter, parentUrl }: any) => {
  const start = moment(item?.time).format("LLL");
  const [scoreView, setScoreView] = React.useState<boolean>(false);
  const marketGroupClass = "disclosure";
  const marketFilter =
    filter !== "all"
      ? market?.filter((item: any) => item?.marketType === filter)
      : market;

  return (
    <Fragment>
      <div className=" mt-4">
        <div className={`space-y-2`}>
          {marketFilter?.map((itemOdds: any, idx: any) => {
            let correct_score =
              itemOdds?.key === "correct_score" ? true : false;
            if (itemOdds?.hide_from_frontend && Number(item?.time_status) === 0)
              return null;
            return (
              <Fragment key={idx}>
                <Disclosure
                  as="div"
                  className={marketGroupClass}
                  defaultOpen={true}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        as="div"
                        className="disclosure-btn relative z-0"
                      >
                        <div className="flex items-center">
                          <h3 className="text-sm">
                            <a
                              href={`#`}
                              className="hover:text-primary transition"
                            >
                              {itemOdds.name}
                            </a>
                          </h3>
                        </div>

                        {!open ? (
                          <i className="fa-solid fa-caret-right text-sm transition-all"></i>
                        ) : (
                          <i className="fa-solid fa-caret-right text-sm rotate-90 transition-all"></i>
                        )}
                      </Disclosure.Button>
                      {/* {correct_score && (
													<>
													<div className='absolute top-1 right-8 space-x-2'>
														<button className={`btn btn-sm`} onClick={() => setScoreView(true)}>Input</button>
														<button className={`btn btn-ghost btn-sm`} onClick={() => setScoreView(false)}>All</button>
													</div>
													</>
												)} */}
                      <Disclosure.Panel className="disclosure-panel fadein">
                        <OddElements
                          odds={itemOdds?.odds}
                          item={itemOdds}
                          market={item}
                          parentUrl={parentUrl}
                          type={"long"}
                          scoreView={scoreView}
                          setScoreView={setScoreView}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
export default MarketLong;
