import { BigNumber, BigNumberish, utils } from "ethers";
const DECIMALS = 6;
const WEI6 = BigNumber.from(10).pow(DECIMALS);
const WEI = BigNumber.from(10).pow(18);
const BN0 = BigNumber.from(0);
const MAX_WEIGHT = WEI6.mul(90).div(100);
const MAX_STRENGTH = WEI6.sub(1);
const ROI_ADJUST_RATIO = 400_000;
const SLIPPAGE = 50; // 5%, base 1000

// odd: WEI6 base = original odd * WEI6
// liquid:
const getOdd = (
  odd: BigNumber,
  liquid: BigNumber,
  marketLiquid: BigNumber,
  limitLiquid: BigNumber
): BigNumber => {
  let _astrength: BigNumber = WEI6.mul(WEI6).div(odd);
  if (marketLiquid.gt(0)) {
    let _lqW = WEI6.mul(marketLiquid).div(limitLiquid);
    if (_lqW.gt(MAX_WEIGHT)) _lqW = MAX_WEIGHT;
    _astrength = _astrength
      .mul(WEI6.sub(_lqW))
      .add(WEI6.mul(liquid).mul(_lqW).div(marketLiquid))
      .div(WEI6);
  }
  if (_astrength.gt(MAX_STRENGTH)) _astrength = MAX_STRENGTH;
  const _odd = WEI6.mul(WEI6).div(_astrength);
  return _checkOdd(_odd, 1);
};

const getOdds = (
  odds: BigNumber[],
  liquids: BigNumber[],
  marketLiquids: BigNumber[],
  limitLiquid: BigNumber
): BigNumber[] => {
  let _odds: BigNumber[] = [];
  for (let i = 0; i < odds.length; i++) {
    _odds.push(getOdd(odds[i], liquids[i], marketLiquids[i], limitLiquid));
  }
  return _odds;
};

function calculateSingleBet(
  outcomes: BigNumber[],
  odds: BigNumber[],
  amounts: BigNumber[],
  liquidLimit: BigNumber
) {
  const wagerIds: BigNumber[] = [];
  const wagerAmounts: BigNumber[] = [];

  for (let i = 0; i < outcomes.length; i++) {
    wagerAmounts[i] = _calPayout(
      amounts[i],
      _checkOdd(odds[i], 1),
      liquidLimit
    );
    wagerIds[i] = buildWagerId([outcomes[i]]);
  }
  const totalAmount: BigNumber = amounts.reduce((a, b) => a.add(b), BN0);
  const totalWagerAmount: BigNumber = wagerAmounts.reduce(
    (a, b) => a.add(b),
    BN0
  );

  return { wagerIds, wagerAmounts, totalAmount, totalWagerAmount };
}

function calculateSystemBet(
  systemType: number,
  outcomes: BigNumber[],
  odds: BigNumber[],
  amount: BigNumber,
  liquidLimit: BigNumber
) {
  const _combos: number[][] = buildSystemOrders(systemType, outcomes.length);
  const wagerIds: BigNumber[] = [];
  const wagerAmounts: BigNumber[] = [];
  let oddCount: number = 0;
  const wagerOutomes: BigNumber[][] = [];
  const totalWagerAmount = BN0;

  for (let i = 0; i < _combos.length; i++) {
    // calculate roi
    let odd = WEI6;
    const _ocs: BigNumber[] = [];
    for (let j = 0; j < _combos[i].length; j++) {
      let _idx = _combos[i][j];
      _ocs[j] = outcomes[_idx];
      odd = BigNumber.from(odd).mul(odds[_idx]).div(WEI6);
    }

    wagerOutomes.push(_ocs);

    wagerAmounts[i] = _calPayout(
      amount,
      _checkOdd(odd, _combos[i].length),
      liquidLimit
    );
    wagerIds[i] = buildWagerId(_ocs);
    oddCount += _combos[i].length;
    totalWagerAmount.add(wagerAmounts[i]);
  }

  return { wagerIds, wagerAmounts, oddCount, wagerOutomes, totalWagerAmount };
}

const _calPayout = (
  amount: BigNumber,
  odd: BigNumber,
  liquidLimit: BigNumber
): BigNumber => {
  let _payout = amount.mul(odd).mul(999).div(1000).div(WEI6);

  // adjust by liquidLimit
  let _estProfit = _payout.sub(amount);
  if (_estProfit.gt(liquidLimit)) _estProfit = liquidLimit;
  let _pi = _estProfit.mul(ROI_ADJUST_RATIO).div(_estProfit.add(liquidLimit));
  _payout = amount.add(WEI6.sub(_pi).mul(_estProfit).div(WEI6));
  return _payout;
};

const buildWagerId = (outcomes: BigNumber[]): BigNumber => {
  return BigNumber.from(
    utils.solidityKeccak256(["uint256[]"], [bnSort(outcomes)])
  );
};
const buildWagerIdV2 = (outcomes: BigNumber[]): string => {
  return utils.solidityKeccak256(["uint256[]"], [bnSort(outcomes)]);
};

const toOutcomeId = (
  eid: BigNumberish,
  mid: BigNumberish,
  oid: BigNumberish
) => {
  let outcomeId = BigNumber.from(eid);
  outcomeId = outcomeId.shl(32).add(mid);
  outcomeId = outcomeId.shl(32).add(oid);
  return outcomeId;
};

const minPayoutWithSlippage = (amount: BigNumber, slippage: number) =>
  amount.mul(100 - (slippage || 1)).div(100);

/// Build Init Data to deploy event
const buildEventInitData = (eventData: any) => {
  const id: number = eventData.id;
  const openTime = BigNumber.from(eventData.open_time || 0);
  const startTime = BigNumber.from(eventData.time);
  const mids: BigNumber[] = [];
  const oids: BigNumber[][] = [];
  const strengths: BigNumber[][] = [];
  const odds: BigNumber[][] = [];
  // now convert odd value to strength
  const mainMarkets: any[] = eventData.markets.main;
  for (let i = 0; i < mainMarkets.length; i++) {
    mids[i] = BigNumber.from(mainMarkets[i].id);
    oids[i] = [];
    odds[i] = [];
    strengths[i] = [];
    for (let j = 0; j < mainMarkets[i].odds.length; j++) {
      odds[i].push(
        utils.parseUnits(
          Number(mainMarkets[i].odds[j].odds).toFixed(DECIMALS).toString(),
          DECIMALS
        )
      );
      oids[i].push(BigNumber.from(mainMarkets[i].odds[j].id));
      strengths[i].push(WEI6.mul(WEI6).div(odds[i][j]));
    }
  }
  return {
    id,
    openTime,
    startTime,
    mids,
    oids,
    strengths,
    odds,
  };
};

/// Sort BigNumber array
const bnSort = (arr: BigNumber[]): BigNumber[] => {
  return arr.sort((a, b) => (a.gt(b) ? 1 : -1));
};

// Build combo for System type
const _cxofy = (x: number, y: number): number[][] => {
  // calculate number of combo = y!/x!
  let length = 1;
  let x1 = x * 2 < y ? x : y - x;
  for (let i = 0; i < x1; i++) {
    length *= y - i;
  }
  for (let i = 0; i < x1; i++) {
    length /= i + 1;
  }
  let _cset: number[][] = [];

  let done = false;
  let aset: number[] = [];
  let counter = 0;
  let j = 0;
  let idx = 0;

  while (!done) {
    if (counter < x) {
      if (j <= y - (x - counter)) {
        aset[counter] = j;
        counter++;
        j++;
      } else {
        if (counter > 0) {
          counter--;
          j = aset[counter] + 1;
        } else {
          done = true;
        }
      }
    } else {
      // push to result
      _cset[idx++] = [...aset];
      // for (let k = 0; k < aset.length; k++) {
      //     _cset[idx][k] = aset[k];
      // }
      // idx++;
      // push back
      counter--;
      if (idx == length) done = true;
    }
  }
  return _cset;
};

const buildSystemOrders = (comboType: number, count: number): number[][] => {
  // findout all combo
  let _min = 0;
  let _max = 0;

  if (comboType <= count) {
    _min = comboType;
    _max = _min;
  } else if (comboType == count + 1) {
    // not include single
    _min = 2;
    _max = count;
  } else if (comboType == count + 2) {
    // include single
    _min = 1;
    _max = count;
  }

  // build outcomes group
  let output: number[][] = [];
  //let _idx = 0;
  for (let i = _min; i <= _max; i++) {
    let _cset = _cxofy(i, count);
    // place orders
    for (let j = 0; j < _cset.length; j++) {
      // output[_idx++] = _cset[j]
      output.push(_cset[j]);
    }
  }

  return output;
};

const _checkOdd = (odd: BigNumber, comboSize: number): BigNumber => {
  // console.log("odd: ", odd.toString(), " comboSize: ", comboSize);
  if (!comboSize) return odd;
  // check if need apply max odd
  const maxOdds = [100, 200, 300, 500, 750, 1000];
  const maxOdd = WEI6.mul(maxOdds[comboSize - 1]);
  return maxOdd.lt(odd) ? maxOdd : odd;
};
const buildSystemOrdersData = (systemType: number, outcomes: BigNumber[]) => {
  const _combos: number[][] = buildSystemOrders(systemType, outcomes.length);
  const wagerOutomes: BigNumber[][] = [];
  for (let i = 0; i < _combos.length; i++) {
    const _ocs: BigNumber[] = [];
    for (let j = 0; j < _combos[i].length; j++) {
      let _idx = _combos[i][j];
      _ocs[j] = outcomes[_idx];
    }

    wagerOutomes.push(_ocs);
  }
  return { wagerOutomes };
};
export {
  BigNumber,
  WEI6,
  WEI,
  BN0,
  DECIMALS,
  SLIPPAGE,
  buildSystemOrders,
  buildWagerId,
  toOutcomeId,
  minPayoutWithSlippage,
  getOdd,
  getOdds,
  calculateSingleBet,
  calculateSystemBet,
  buildEventInitData,
  buildSystemOrdersData,
  _checkOdd,
  bnSort,
  buildWagerIdV2,
};
