import React, { Fragment } from "react";
import OddElement from "./OddElement";
import { oddProps } from "./oddInterface";

const OddType1: React.FC<oddProps> = ({
  idx,
  market,
  item,
  odds,
  parentUrl,
  marketLiquid,
}) => {
  return (
    <Fragment key={idx}>
      <div className="grid grid-cols-3 gap-2 w-full">
        {odds.map((itemOdd: any, idx: number) => {
          return (
            <Fragment key={idx}>
              <OddElement
                idx={idx}
                odd={itemOdd}
                item={item}
                market={market}
                parentUrl={parentUrl}
                marketLiquid={marketLiquid}
              />
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export default OddType1;
