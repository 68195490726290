const slugs = {
  home: "/",
  login: "/login",
  sports: "/sports",
  category: "/sports/:category",
  league: "/sports/:category/:league",
  event: "/sports/:category/:league/:event",
  myBets: "/account/bets",
  profile: "/profile",
  notification: "/notification",
  notificationCenter: "/notification-center",
  refcode: "/referral-code",
  metamask: "/metamask/:token",
  orderSlip: "/order-slip",
  x1000: "/x1000/",
  lottery: "/lottery/",
  campaign: "/campaign",
  campaignDetail: "/campaign/:id",
};

export default slugs;
