import React, { useEffect } from "react";
import { ModalLayout } from "./Layout";
import { useSnapshot } from "valtio";
import { notiState, notitficationState } from "proxy-state/notificationProxy";
import NotificationCenter from "modules/notificationCenter/page";
import NotiHeader from "modules/notificationCenter/components/NotiHeader";

export const NotificationModal = () => {
  const { show: notiStatus } = useSnapshot(notiState);
  const toggleModal = () => notitficationState.setShow(!notiStatus);
  useEffect(() => {
    const body = document.querySelector("body");
    if (notiStatus) {
      body?.classList.add("has-Modal");
    } else {
      body?.classList.remove("has-Modal");
    }
  }, [notiStatus]);
  return (
    <ModalLayout
      modalId="notification-modal"
      isOpen={notiStatus}
      toggleModal={toggleModal}
      title="Notifications"
      icon="fa-duotone fa-bell"
      customBody
    >
      <NotificationCenter />
    </ModalLayout>
  );
};
