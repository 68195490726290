import { proxy, subscribe } from "valtio";
import { proxyMap } from "valtio/utils";
export const targetProxy: {
  default: number;
  target: number;
  singleTargetArray: number[];
  singleTarget: Map<string, number>;
} = proxy({
  default: 10,
  target: 10,
  singleTarget: proxyMap<string, number>([]),
  get singleTargetArray() {
    return Array.from(targetProxy.singleTarget.values());
  }
});
export const TargetFunc = {
  targetProxy,
  get target() {
    return targetProxy.target;
  },
  setTarget: (value: number) => {
    targetProxy.target = value;
  },
  get singleTarget() {
    return Array.from(targetProxy.singleTarget.values());
  },
  setSingleTarget: (key: string, value: number) => {
    // const _target = targetProxy.singleTarget;
    // _target.set(key, value);
    targetProxy.singleTarget.set(key, value);
  },
  setSingleTargets: (value: Map<string, number>) => {
    value.forEach((v, k) => {
      targetProxy.singleTarget.set(k, v);
    });
  },
  deleteTarget: (key: string) => {
    targetProxy.singleTarget.delete(key);
  },
  singleTargetClear: () => {
    targetProxy.singleTarget.clear();
  }
};
subscribe(targetProxy, () => {
  console.log("targetProxy", targetProxy);
});