import React, { Fragment } from "react";
import { currencyFormat } from "common/utils";
import { ReactComponent as ZKUSDSvg } from "assets/currency-icons/zkUSD.svg";
import { useSnapshot } from 'valtio';
import { configProxy } from "proxy-state/configProxy";
type AcceptedTokenProps = {
  price: number;
  endFix?: string;
  iconWidth?: string;
  justify?: string;
  showIcon?: boolean;
  icon?: string;
  showText?: boolean;
  rightText?: boolean;
  isFormat?: boolean;
};
function AcceptedToken({
  price,
  endFix,
  iconWidth = "w-4",
  justify = "justify-start",
  showIcon = false,
  icon,
  showText = false,
  rightText = false,
  isFormat = false,
}: AcceptedTokenProps) {
  const {symbol} = useSnapshot(configProxy);
  icon = !icon ? symbol : icon;
  return (
    <Fragment>
      <div
        className={`flex ${justify} items-center ${
          rightText ? "w-full !justify-between" : ""
        }`}
      >
        <div className="inline-flex items-center">
          <span className="tooltip" data-tip={`${price} ${icon}`}>
            {currencyFormat(price, true)}
          </span>
          {endFix ? <span className={""}>{endFix}</span> : null}
          {showIcon ? (
            <ZKUSDSvg
              width={16}
              height={16}
              className={`${iconWidth} h-4 cursor-pointer ml-2 color-transparent hover:color-current transition`}
            />
          ) : null}
        </div>

        {showText ? (
          <span className={"ml-1 opacity-70 font-normal"}>{icon}</span>
        ) : null}
      </div>
      {/* <ReactTooltip id={id} getContent={(dataTip) => `${dataTip}`} effect="float" /> */}
    </Fragment>
  );
}

export default AcceptedToken;
