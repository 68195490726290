import { configureChains, createConfig } from "wagmi";
import {
  bsc,
  bscTestnet,
  optimism,
  optimismGoerli,
  zkSync,
  zkSyncSepoliaTestnet,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
// import { getDefaultConfig } from "connectkit";

const chainId = Number(process.env.REACT_APP_CHAIN_ID);
// console.log("🚀 --------------------------------------------🚀");
// console.log("🚀 ~ file: client.tsx:14 ~ chainId:", chainId);
// console.log("🚀 --------------------------------------------🚀");

const initWagmiClient = () => {
  let chainToSetup: any;
  switch (chainId) {
    case 10:
      chainToSetup = optimism;
      break;
    case 420:
      chainToSetup = optimismGoerli;
      break;
    case 97:
      chainToSetup = bscTestnet;
      break;
    case 56:
      chainToSetup = bsc;
      break;

    case 324:
      chainToSetup = zkSync;
      break;
    case 300:
    default:
      const newChain = {
        ...zkSyncSepoliaTestnet,
        name: "zkSync Testnet",
        id: 300,
      };
      chainToSetup = newChain;
      break;
  }
  const projectId = process.env.REACT_APP_PROJECT_ID as string;

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [chainToSetup],
    [publicProvider()]
  );
  const walletConnect = [];
  // walletConnect.push(walletConnectWallet({ projectId, chains, version: "2" }));
  // if (!isMobile) {
  //   walletConnect.push(metaMaskWallet({ projectId, chains }));
  //   walletConnect.push(argentWallet({ projectId, chains }));
  // }
  // const connectors = connectorsForWallets([
  //   {
  //     groupName: " ",
  //     wallets: walletConnect,
  //   },
  // ]);
  // Setup client
  const config = createConfig({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      // new WalletConnectConnector({
      //   chains,
      //   options: {
      //     projectId: "740582df3e4aab53da39c544d1f17fb3",
      //   },
      // }),
    ],
    publicClient,
    webSocketPublicClient,
  });

  // const config = createConfig(
  //   getDefaultConfig({
  //     chains,
  //     // Required API Keys
  //     alchemyId: process.env.ALCHEMY_ID, // or infuraId
  //     walletConnectProjectId: "740582df3e4aab53da39c544d1f17fb3",
  //     // Required
  //     appName: "Your App Name",
  //     connectors: [
  //       new WalletConnectConnector({
  //         chains,
  //         options: {
  //           projectId: "740582df3e4aab53da39c544d1f17fb3",
  //         },
  //       }),
  //     ],
  //     // Optional
  //     appDescription: "Your App Description",
  //     appUrl: "https://family.co", // your app's url
  //     appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  //   })
  // );

  // const config = createConfig({
  //   autoConnect: true,
  //   connectors: [
  //     new WalletConnectConnector({
  //       chains,
  //       options: {
  //         projectId: "...",
  //       },
  //     }),
  //   ],
  //   publicClient,
  //   webSocketPublicClient,
  // });

  return {
    config,
    chains,
  };
};

export { initWagmiClient as default };
