import React from "react";
import { navFunc } from "proxy-state/global";
import { useSnapshot } from "valtio";
import { userWagerProxy } from "proxy-state/userWagerProxy";
import {
  OrderSlipFunc,
  OrderTab,
  orderSlipProxy,
} from "proxy-state/betting/OrderSlip";
const OrderSlipHeader = () => {
  const { data: userWager } = useSnapshot(userWagerProxy);
  const { total, orderTab } = useSnapshot(orderSlipProxy);

  const wagerOpenCounting = userWager.filter(
    (w: any) => w?.status === 0
  ).length;

  return (
    <div className="panel-header !border-b-0 !pb-0">
      <div className="flex items-center space-x-2">
        <button
          onClick={() => OrderSlipFunc.setOrderTab(OrderTab.OrderSlip)}
          className={`btn btn-sm gap-2 ${
            orderTab === OrderTab.OrderSlip
              ? "font-semibold border-primary text-primary bg-transparent hover:bg-transparent hover:border-primary"
              : "font-normal btn-ghost text-base-content/50"
          }`}
        >
          <span className="text-sm">Order Slip</span>
          {total > 0 && <span className="badge-noti lg:!hidden">{total}</span>}
        </button>
        <button
          onClick={() => OrderSlipFunc.setOrderTab(OrderTab.RecentBet)}
          className={`btn btn-sm gap-2 ${
            orderTab === OrderTab.RecentBet
              ? "font-semibold border-primary text-primary bg-transparent hover:bg-transparent hover:border-primary"
              : "font-normal btn-ghost text-base-content/50"
          }`}
        >
          <span className="text-sm">Recent</span>
          {wagerOpenCounting > 0 && (
            <span className="badge-noti">{wagerOpenCounting}</span>
          )}
        </button>
      </div>
      <button
        className="btn btn-sm btn-circle btn-ghost tooltip tooltip-left -mr-1"
        data-tip="Close Panel"
        onClick={() => navFunc.setNavOpen("isShowOrder", false)}
      >
        <i className="fa-regular fa-xmark text-lg"></i>
      </button>
    </div>
  );
};
export default OrderSlipHeader;
