import React, { useEffect } from "react";
import Market from "components/organisms/Market";
import EventHeader from "./eventHeader";
import utils from "common/utils";
import Loading from "components/organisms/Loading/loading";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { eventProxy } from "../../../../proxy-state/eventProxy";
import { myProfileProxy } from "../../../../proxy-state/myProfileProxy";

const EventDetail = () => {
  const { data: events } = useSnapshot(eventProxy);
  const { address } = useSnapshot(myProfileProxy);
  let { category, event } = useParams<{
    category: string;
    league: string;
    event: string;
  }>();

  const [loading, setLoading] = React.useState<boolean>(false);

  const isSignedIn = address?.length > 0;
  const convertSlug: string = event || "";
  const eventSlug: string = convertSlug ? utils.getEventId(convertSlug) : "";

  const data = events.get(eventSlug);

  useEffect(() => {
    if (!eventSlug || !isSignedIn || data) return;
    setLoading(true);
    eventProxy.loadByIds([eventSlug], () => {
      setLoading(false);
    });
  }, [eventSlug, isSignedIn]);

  if (loading && !data) return <Loading />;
  if (!data) return null;
  return (
    <div className="container">
      <div className="mb-4">
        <EventHeader market={data} cate={category} />
      </div>
      {/* Market Lists */}
      <div className="mt-6">
        <Market
          idx={0}
          item={data}
          type={"long"}
          eventFilter={"null"}
          parentUrl={""}
        />
      </div>
    </div>
  );
};
export default EventDetail;
