import { useEffect, useState } from "react";
import { useAccount } from "./useAccount";
import OneSignal from "react-onesignal";

export const useOneSignal = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);
  const { address } = useAccount();

  useEffect(() => {
    const handle = async (data: any) => {
      /// true -> false
      if (!data?.current?.optedIn) {
        // Never go in this block b/c the first useEffect
        return;
      }

      if (!address) {
        return;
      }

      await OneSignal.login(address).then(() => {});
    };

    OneSignal.User.PushSubscription.addEventListener("change", handle);

    return () => {
      OneSignal.User.PushSubscription.removeEventListener("change", handle);
    };
  }, [address]);

  /// Check user allow one signal send noti
  useEffect(() => {
    const f = () => {
      const optedIn = OneSignal.User.PushSubscription.optedIn;

      if (optedIn) {
        setIsAllowed(true);
        setIsLoading(false);
      }
    };

    f();

    const timeout = setTimeout(() => {
      f();
      setIsLoading(false);
    }, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return {
    isAllowed,
    isLoading,
  };
};
