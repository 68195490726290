import { formatBigNumber } from "common/utils";
import { contract, parseTX } from "contracts/Contract";
import { BigNumber, ethers } from "ethers";
import {
  _checkOdd,
  BN0,
  calculateSingleBet,
  calculateSystemBet,
  DECIMALS,
  getOdds,
  WEI,
  WEI6,
} from "functions/betUtils";
import { getPriceImpact } from "hooks/useOrderSlip";
import { calculateSingleBetV2, calculateSystemBetV2 } from "functions/amm-v2";
import { OutcomeLiquidity } from "../../../interfaces/firebase";

export const Approve = async ({
  address,
  amount,
  dataAddress,
  signer,
}: any) => {
  const erc_contract = contract(dataAddress, signer);
  return await erc_contract.approve(address, amount);
};
export const getTxReceipt = async (provider: any, trx: string) => {
  try {
    const receipt = await provider.getTransactionReceipt(trx);

    const logs = await Promise.all(
      receipt.logs.map(async (log: any) => {
        try {
          const new_log = parseTX.parseLog(log);
          if (new_log) return formatBigNumber(new_log.args[2]);
        } catch (e) {
          return null;
        }
      })
    );
    return logs.filter((x) => x);
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const calculateTotalOdds = (
  odds: BigNumber[],
  liquids: BigNumber[],
  mliquids: BigNumber[],
  liquidDefault: BigNumber
): BigNumber => {
  const odds1 = getOdds(odds, liquids, mliquids, liquidDefault);
  return odds1.reduce((a, b) => a.mul(b).div(WEI6), WEI6);
};

export const calculateSingleBetData = (
  outComeIds: BigNumber[],
  odds: BigNumber[],
  amount: BigNumber[],
  liquids: BigNumber[],
  mliquids: BigNumber[],
  liquidDefault: BigNumber
) => {
  const odds1 = getOdds(odds, liquids, mliquids, liquidDefault);
  const { wagerIds, wagerAmounts, totalWagerAmount } = calculateSingleBet(
    outComeIds,
    odds1,
    amount,
    liquidDefault
  );
  const totalOdds = _checkOdd(
    calculateTotalOdds(odds, liquids, mliquids, liquidDefault),
    outComeIds.length
  );
  const totalWagerAmountBN = BigNumber.from(totalWagerAmount);
  const potenialWin = totalWagerAmountBN.div(WEI6).toNumber();
  const maxAmountPay =
    amount.reduce((a: BigNumber, b: BigNumber) => a.add(b), BN0).toNumber() /
    WEI6.toNumber();

  return {
    wagerIds,
    wagerAmounts,
    totalWagerAmount: totalWagerAmountBN,
    totalOdds,
    potenialWin,
    maxAmountPay,
  };
};

export const calculateSystemBetData = (
  type: number,
  outComeIds: BigNumber[],
  odds: BigNumber[],
  target: number,
  liquids: BigNumber[],
  mliquids: BigNumber[],
  liquidDefault: BigNumber
) => {
  const maxOdds = _checkOdd(
    calculateTotalOdds(odds, liquids, mliquids, liquidDefault),
    outComeIds.length
  );
  const odds1 = getOdds(odds, liquids, mliquids, liquidDefault);
  const { wagerIds, wagerAmounts } = calculateSystemBet(
    type,
    outComeIds,
    odds1,
    BigNumber.from(target || 0).mul(WEI6),
    liquidDefault
  );
  const totalWagerAmountBN = wagerAmounts.reduce((a, b) => a.add(b), BN0);
  const potenialWin = totalWagerAmountBN.div(WEI6).toNumber();
  const maxAmountPay = target * wagerIds.length;
  return {
    wagerIds,
    wagerAmounts,
    totalWagerAmount: totalWagerAmountBN,
    totalOdds: maxOdds,
    potenialWin,
    maxAmountPay,
  };
};

export const calculatePriceImpact = (
  target: number,
  totalOdds: number,
  potenialWin: number
) => {
  const { priceImpactPercent } = getPriceImpact(target, totalOdds, potenialWin);
  return priceImpactPercent;
};

export const updateState = (
  systemType: number,
  type: string,
  outComeIds: BigNumber[],
  odds: BigNumber[],
  amount: BigNumber[],
  target: number,
  liquids: BigNumber[],
  mliquids: BigNumber[],
  liquidDefault: BigNumber
) => {
  const _type: number =
    type === "single-mode"
      ? 1
      : type === "combo-mode"
        ? outComeIds.length
        : Number(systemType);
  const {
    wagerIds,
    wagerAmounts,
    totalWagerAmount,
    totalOdds: _totalOdds,
    potenialWin,
    maxAmountPay,
  } = type === "single-mode"
    ? calculateSingleBetData(
        outComeIds,
        odds,
        amount,
        liquids,
        mliquids,
        liquidDefault
      )
    : calculateSystemBetData(
        _type,
        outComeIds,
        odds,
        target,
        liquids,
        mliquids,
        liquidDefault
      );
  const priceImpact =
    type === "single-mode"
      ? potenialWin
      : calculatePriceImpact(
          target,
          formatBigNumber(_totalOdds, false),
          potenialWin
        );
  const totalOdds = _totalOdds.div(WEI6).toNumber();
  return {
    _type,
    wagerIds,
    wagerAmounts,
    totalWagerAmount,
    potenialWin,
    maxAmountPay,
    priceImpact,
    totalOdds,
  };
};

export const calculateSingleBetData2 = (
  outComeIds: BigNumber[],
  eventData: any[],
  outcomeLiquids: Map<string, OutcomeLiquidity>,
  amounts: BigNumber[],
  sensitivity: number
) => {
  const { wagerIds, wagerAmounts, totalWagerAmount, wagerOdds } =
    calculateSingleBetV2(
      outComeIds,
      eventData,
      outcomeLiquids,
      amounts,
      sensitivity
    );
  const totalOdds = ethers.utils.parseUnits(
    wagerOdds.reduce((result, odd) => result * odd, 1).toFixed(DECIMALS),
    DECIMALS
  );
  const totalWagerAmountBN = BigNumber.from(totalWagerAmount);
  const potenialWin = formatBigNumber(totalWagerAmount, true);
  const maxAmountPay = formatBigNumber(
    amounts.reduce((a: BigNumber, b: BigNumber) => a.add(b), BN0),
    true
  );
  return {
    wagerIds,
    wagerAmounts,
    totalWagerAmount: totalWagerAmountBN,
    totalOdds,
    potenialWin,
    maxAmountPay,
  };
};

export const calculateSystemBetData2 = (
  type: number,
  outComeIds: BigNumber[],
  eventData: any[],
  outcomeLiquids: Map<string, OutcomeLiquidity>,
  amount: BigNumber,
  sensitivity: number
) => {
  // const maxOdds = _checkOdd(calculateTotalOdds(odds, liquids, mliquids, liquidDefault), outComeIds.length);
  // const odds1 = getOdds(odds, liquids, mliquids, liquidDefault);
  const { wagerIds, wagerAmounts, wagerOdds } = calculateSystemBetV2(
    type,
    outComeIds,
    eventData,
    outcomeLiquids,
    amount,
    sensitivity
  );
  // console.log("wagerOdds", wagerOdds);
  const totalOdds =
    type === outComeIds.length
      ? ethers.utils.parseUnits(
          wagerOdds.reduce((result, odd) => result * odd, 1).toFixed(DECIMALS),
          DECIMALS
        )
      : WEI6;
  const totalWagerAmountBN = wagerAmounts.reduce((a, b) => a.add(b), BN0);
  const potenialWin = formatBigNumber(totalWagerAmountBN, true);
  const maxAmountPay = formatBigNumber(amount, true) * wagerIds.length;
  return {
    wagerIds,
    wagerAmounts,
    totalWagerAmount: totalWagerAmountBN,
    totalOdds: totalOdds,
    potenialWin,
    maxAmountPay,
  };
};

export const updateState2 = (
  systemType: number,
  type: string,
  outComeIds: BigNumber[],
  eventData: any[],
  outcomeLiquids: Map<string, OutcomeLiquidity>,
  amounts: BigNumber[],
  sensitivity: number
) => {
  const _type: number =
    type === "single-mode"
      ? 1
      : type === "combo-mode"
        ? outComeIds.length
        : Number(systemType);
  const {
    wagerIds,
    wagerAmounts,
    totalWagerAmount,
    totalOdds: _totalOdds,
    potenialWin,
    maxAmountPay,
  } = type === "single-mode"
    ? calculateSingleBetData2(
        outComeIds,
        eventData,
        outcomeLiquids,
        amounts,
        sensitivity
      )
    : calculateSystemBetData2(
        _type,
        outComeIds,
        eventData,
        outcomeLiquids,
        amounts[0],
        sensitivity
      );
  const priceImpact =
    type === "combo-mode"
      ? calculatePriceImpact(
          formatBigNumber(amounts[0], true),
          formatBigNumber(_totalOdds, true),
          potenialWin
        )
      : potenialWin;
  const totalOdds = formatBigNumber(
    _checkOdd(_totalOdds, outComeIds.length),
    true
  );
  return {
    _type,
    wagerIds,
    wagerAmounts,
    totalWagerAmount,
    potenialWin,
    maxAmountPay,
    priceImpact,
    totalOdds,
  };
};
