import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import Home from "../page/Home";

const routes: AppRouteType[] = [
  {
    path: slugs.home,
    component: Home,
    container: MainLayout,
    isAuthNeeded: false,
  },
];

export default routes;
