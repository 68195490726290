import { proxyMap } from "valtio/utils";
import { proxy } from "valtio";
import {
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  Query,
  where,
} from "firebase/firestore";
import { eventRef } from "../utils/firebase/firebaseDBRef";
import { mapMarketData } from "../hooks/useMapData";
import _ from "lodash";
import { queryEventByIds } from "../utils/firebase/firebaseQuery";
import { Event } from "../interfaces/firebase";

let unsubscribes: any[] = [];

class EventProxy {
  isLoadingActiveEvent: boolean = true;
  data: Map<string, Event> = proxyMap<string, Event>([]);
  subscribedActiveEvent: boolean = false;

  get events() {
    return Array.from(this.data.values());
  }

  loadActiveEvent() {
    if (this.subscribedActiveEvent) return;
    this.subscribedActiveEvent = true;
    this.isLoadingActiveEvent = true;
    const ref = eventRef();
    const q: Query<DocumentData> = query(
      ref,
      where("status", "==", "published"),
      where("time_status", "<", "2"),
      orderBy("time_status", "asc")
    );
    unsubscribes.push(
      onSnapshot(q, (snapshot) => {
        this.isLoadingActiveEvent = false;

        snapshot.docs.forEach((doc) => {
          const event = mapMarketData(doc?.data());
          this.data.set(doc.id, { ...event });
        });
      })
    );
  }

  loadByIds(eventIds: string[], onComplete?: Function) {
    const fetchData = (ids: string[]) => {
      return new Promise((resolve, reject) => {
        let firstRequest = true;
        unsubscribes.push(
          onSnapshot(queryEventByIds(ids), (snapshot) => {
            snapshot.docs.forEach((doc) => {
              const event = mapMarketData(doc?.data());
              this.data.set(doc.id, event);
            });
            if (firstRequest) {
              firstRequest = false;
              resolve(ids);
            }
          })
        );
      });
    };

    const listenEventIds = [];
    for (const eventId of eventIds) {
      if (!this.data.has(eventId)) {
        listenEventIds.push(eventId);
      }
    }
    if (listenEventIds.length > 0) {
      Promise.all(
        _.chunk(listenEventIds, 10).map((chunkedLeagues) =>
          fetchData(chunkedLeagues)
        )
      ).then(() => {
        if (onComplete) {
          onComplete(eventIds);
        }
      });
    }
  }

  unsubscribeDb() {
    this.subscribedActiveEvent = false;
    for (const unsubscribe of unsubscribes) {
      if (unsubscribe) unsubscribe();
    }
  }
}

export const eventProxy = proxy(new EventProxy());
