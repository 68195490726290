const en: any = {
  language: { en: "English", kr: "Korean" },
  Hi: "Hi",
  "Your Balance": "Your Balance",
  "Wallet Info": "Wallet Info",
  "Copy Address": "Copy Address",
  Settings: "Settings",
  "Betting Preferences": "Betting Preferences",
  "Notification settings": "Notification settings",
  Themes: "Themes",
  Languages: "Languages",
  "Top Matches": "Top Matches",
  Activities: "Activities",
  "Top Outrights": "Top Outrights",
  Bets: "Bets",
  History: "History",
  "Order Slip": "Order Slip",
  Recent: "Recent",
  Save: "Save",
};

export default en;
