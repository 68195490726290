import React, { Fragment, useEffect, useRef } from "react";
import MyOrderGrid from "./Type/grid";
import MyOrderList from "./Type/list";
import _ from "lodash";
import { useSnapshot } from "valtio";
import HistorySkeleton from "components/commons/Loading/HistorySkeleton";
import { userWagerProxy } from "proxy-state/userWagerProxy";
import { eventProxy } from "proxy-state/eventProxy";
import { Pagination } from "components/organisms/Pagination/Pagination";
import { PAGINATION_SIZE } from "shared/constants";
import { isMobile } from "react-device-detect";
import { ILayoutType } from "shared/enum";
import { ADDRESS, getItem } from "local-storage";

const EmptyOrder = () => {
  return (
    <div className="mt-6">
      <div className="grid grid-cols-1">
        <div className="mt-6">
          <div className="py-2 px-4 flex flex-col min-h-[400px] max-w-sm mx-auto justify-center items-center">
            <i className="text-6xl text-primary fa-duotone fa-face-thinking icon-primary mb-6"></i>
            <span className="text-[14px] font-bold">{`"A dollar won is better than a dollar earned"`}</span>
            <span className="text-xs opacity-70 text-center mt-1 italic">
              {"- Elon Musk, DogeCoin"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderHistory: React.FC<any> = ({ view, ...props }) => {
  const { data: userWagers } = useSnapshot(userWagerProxy);
  const { events } = useSnapshot(eventProxy);
  const { historyRaw, loading, count, onPageChanged, page } = props;
  const address = getItem(ADDRESS) || "";
  const observerTarget = useRef(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const unLoadWager : string[]= [];
  const historyData = historyRaw?.map((item: any) => {
    const wagers = item.wagers.map((wager: any) => {
      const _wager = userWagers.find((d) => d.id === wager.id);
      if(!_wager) {
        unLoadWager.push(wager?.id);
      }
      return { ...wager, history_wager: wager, ..._wager };
    });
    return { ...item, wagers };
  });
  
  if (unLoadWager.length > 0 && address) {
    userWagerProxy.loadByIds(address, _.uniq(unLoadWager), ()=> {
      console.log('load wager success');
    });
  }
  /// dev: handle infinity scroll ::::::: trigger click next button for simulate pagination on mobile
  useEffect(() => {
    let observer: IntersectionObserver;

    observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("visible");
          nextButtonRef.current?.click();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current && observer) {
        console.log("unmount");
        observer.unobserve(observerTarget.current);
      }
    };
  }, []);

  const renderMainList = () => {
    if (!loading && historyData.length === 0) return <EmptyOrder />;

    if (view === ILayoutType.LIST)
      return <MyOrderList ordersData={historyData} />;

    return (
      <MyOrderGrid
        ordersData={historyData}
        eventsData={events}
        loading={false}
        type={""}
      />
    );
  };

  const renderMainContent = () => {
    /// desktop
    if (!isMobile) {
      if (loading) {
        return <HistorySkeleton length={8} />;
      }

      renderMainList();
    }

    /// mobile
    return (
      <>
        {renderMainList()}
        {loading && <HistorySkeleton length={historyData.length > 0 ? 2 : 8} />}
      </>
    );
  };
  if (!loading && historyRaw.length === 0) return <EmptyOrder />;
  return (
    <Fragment>
      <div id={isMobile ? 'history' : 'history-destop'} className={`${view === ILayoutType.GRID ? 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4' : ''}`}>
        {renderMainContent()}
        {isMobile && (
          <div className="flex flex-col gap-4 pl-4 pr-2" ref={observerTarget}></div>
        )}
      </div>
      {count > PAGINATION_SIZE && (
        <div className="hidden md:block">
          <Pagination
            count={count}
            currentPage={page}
            pageChanged={onPageChanged}
            position={"end"}
            nextButtonRef={nextButtonRef}
          />
        </div>
      )}
    </Fragment>
  );
};
export default OrderHistory;

// {
//   false &&
//     historyDataPage &&
//     (historyDataPage?.docs.length >= PAGE_SIZE || page >= 1) && (
//       <div className="mt-6 md:mt-8">
//         {/* Pagination */}
//         <PaginationV1
//           nextCursorPointer={nextCursorPointer}
//           prevCursorPointer={prevCursorPointer}
//           setIsNext={setIsNext}
//           setIsPrev={setIsPrev}
//           position={PaginationType.End}
//           pageSize={PAGE_SIZE}
//           currentPageSize={
//             historyDataPage.docs.length === PAGE_SIZE + 1
//               ? PAGE_SIZE
//               : historyDataPage.docs.length || 0
//           }
//           curPage={page}
//           setCurPage={setPage}
//         />
//       </div>
//     );
// }
