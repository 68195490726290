const kr: any = {
  language: { en: "영어", kr: "한국인" },
  Hi: "안녕",
  "Your Balance": "귀하의 잔액",
  "Wallet Info": "지갑 정보",
  "Copy Address": "주소 복사",
  Settings: "설정",
  "Betting Preferences": "베팅 기본 설정",
  "Notification settings": "알림 설정",
  Themes: "테마",
  Languages: "언어",
  "Top Matches": "인기 경기",
  Activities: "활동",
  "Top Outrights": "상위 아웃라이트",
  Bets: "베팅",
  History: "역사",
  "Order Slip": "주문전표",
  Recent: "최근의",
  Save: "저장",
};

export default kr;
