import { ethers, BigNumber, PopulatedTransaction } from "ethers";
import BookMakerABI from "./abi/BookMaker.json";
import BookMakerV2ABI from "./abi/BookMakerV2.json";
import PaymasterBookMakerABI from "./abi/PaymasterBookMaker.json";
import PaymasterRedeemABI from "./abi/PaymasterRedeem.json";
import wagerABI from "./abi/Wager.json";
import abi2 from "./abi/PoolManagerBalanceABI.json";
import abiERC20 from "./abi/ERC20.abi.json";
import { BaseProvider } from "@ethersproject/providers";
import { DataChain } from "./config";
import { Provider, Signer, utils, Web3Provider, types } from "zksync-web3";
import { CHAIN_ID } from "constant";
//
// const poolAddress: any = process.env.NEXT_PUBLIC_PULL_ADDRESS;
// const poolBalanceAddress: any = process.env.NEXT_PUBLIC_PULL_BALANCE_ADDRESS;
// const myPoolBalanceAddress: any = process.env.NEXT_PUBLIC_PULL_MY_BALANCE_ADDRESS;
export const makeOutcomeId = (eventId: any, marketId: any, oddId: any) => {
  let outcomeId = BigNumber.from(eventId);
  outcomeId = outcomeId.shl(32).add(marketId);
  outcomeId = outcomeId.shl(32).add(oddId);
  return outcomeId.toString();
};
export const parseTX = new ethers.utils.Interface([
  // Constructor
  "constructor(string symbol, string name)",

  // State mutating method
  "function transferFrom(address from, address to, uint amount)",

  // State mutating method, which is payable
  "function mint(uint amount) payable",

  // Constant method (i.e. "view" or "pure")
  "function balanceOf(address owner) view returns (uint)",

  // An Event
  "event Transfer(address indexed from, address indexed to, uint256 amount)",

  // A Custom Solidity Error
  "error AccountLocked(address owner, uint256 balance)",

  // Examples with structured types
  "function addUser(tuple(string name, address addr) user) returns (uint id)",
  "function addUsers(tuple(string name, address addr)[] user) returns (uint[] id)",
  "function getUser(uint id) view returns (tuple(string name, address addr) user)",
]);
//
// export const JsonERCProviderContract = (address: any) => {
//   try {
//     const provider = new ethers.providers.JsonRpcProvider("https://goerli.optimism.io");
//     // @ts-ignore
//     const connect = new ethers.providers.Web3Provider(provider)
//     return new ethers.Contract(address, abiERC20, connect.getSigner())
//   } catch (e) {
//     console.log(e);
//   }
// }
const JsonERCProviderContract = (chainId: any) => {
  let netWork_url;
  switch (chainId) {
    case "10":
      netWork_url =
        "https://opt-mainnet.g.alchemy.com/v2/V7Xa8Oh1iqloS1AJNfI9mJmLUwA7Z-bC";
      break;
    case "420":
      netWork_url =
        "https://opt-mainnet.g.alchemy.com/v2/V7Xa8Oh1iqloS1AJNfI9mJmLUwA7Z-bC";
      break;
    case "56":
      netWork_url =
        "https://nd-777-275-953.p2pify.com/96cfc2cbab51c7160b1774ca14c8a029";
      break;
    case "97":
      netWork_url =
        "https://nd-552-280-216.p2pify.com/c57f99a8ceeb60a4446b300594421b78";
      break;
  }
  return netWork_url;
};
const bookABI = BookMakerABI.abi;
/*const getProvider = () => {
  let provider
  if (!(window as any)?.ethereum?.selectedAddress) {
    const chainId = process.env.REACT_APP_CHAIN_ID;
    const netWork_url = JsonERCProviderContract(chainId);
    provider = new ethers.providers.JsonRpcProvider(netWork_url)
  } else {
    const connect = new ethers.providers.Web3Provider((window as any).ethereum);
    provider = connect.getSigner()
  }
  return provider
}*/
const getDefaultSigner = () => {
  let signer;
  if ((window as any)?.ethereum?.selectedAddress) {
    const connect = new ethers.providers.Web3Provider((window as any).ethereum);
    signer = connect.getSigner();
  }

  return signer;
};
export const contract = (dataAddress: any, signer: any) => {
  if (!signer) {
    signer = getDefaultSigner();
  }

  return new ethers.Contract(dataAddress, bookABI, signer);
};

export const erc_contract = (address: any, signer: any) => {
  if (!signer) {
    signer = getDefaultSigner();
  }
  return new ethers.Contract(address, abiERC20, signer);
};

export const getContract = (
  contractName: string,
  contractAddress: string,
  signer: any
) => {
  if (!signer) {
    signer = getDefaultSigner();
  }
  let contractAbi: any = [];
  switch (contractName) {
    case "BookMaker":
      contractAbi = bookABI;
      break;
    case "BookMakerV2":
      contractAbi = BookMakerV2ABI.abi;
      break;
    case "PaymasterBookMaker":
      contractAbi = PaymasterBookMakerABI.abi;
      break;
    case "PaymasterRedeem":
      contractAbi = PaymasterRedeemABI.abi;
      break;

    case "Wager":
      contractAbi = wagerABI.abi;
      break;
  }
  return new ethers.Contract(contractAddress, contractAbi, signer);
};
export const balance_contract = (dataAddress: any, signer: any) => {
  if (!signer) {
    signer = getDefaultSigner();
  }

  return new ethers.Contract(dataAddress, abi2, signer);
};

export const readContract = async (
  contractAddress: string,
  provider: any,
  abi: ethers.ContractInterface,
  readMethod: string | number,
  args: any
) => {
  if (!contractAddress || !abi) return;

  const contract = new ethers.Contract(contractAddress, abi, provider);

  const _args = args ? args : [];
  return await contract[readMethod](..._args);
};
export const balance_contract_wtout_signer = (dataAddress: any) => {
  const rpcUrl = DataChain[CHAIN_ID].NEXT_PUBLIC_RPC_URL;
  // console.log("🚀 ---------------------------------------------🚀");
  // console.log("🚀 ~ file: Contract.tsx:186 ~ rpcUrl:", rpcUrl, chainId);
  // console.log("🚀 ---------------------------------------------🚀");

  const provider = new Provider(rpcUrl);
  return new ethers.Contract(dataAddress, abi2, provider);
};

export const sendTransactionPaymaster = async (
  transaction: PopulatedTransaction,
  paymasterParams: types.PaymasterParams,
  signerProvider: Signer,
  jsonRPCProvider: BaseProvider
) => {
  const address = await signerProvider.getAddress();
  transaction.from ??= address;
  if (transaction.from.toLowerCase() !== address.toLowerCase()) {
    throw new Error("Transaction `from` address mismatch");
  }
  transaction.type = utils.EIP712_TX_TYPE;
  // @ts-ignore
  transaction.value ??= 0;
  transaction.data ??= "0x";
  transaction.customData = {
    gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
    paymasterParams,
  };
  transaction.nonce ??= await signerProvider.getNonce();
  transaction.gasPrice ??= await signerProvider.provider.getGasPrice();
  transaction.gasLimit = await signerProvider.provider.estimateGas(transaction);
  transaction.chainId ??= (await signerProvider.provider.getNetwork()).chainId;

  // @ts-ignore
  transaction.customData.customSignature = await signerProvider.eip712.sign(
    transaction
  );
  const txBytes = utils.serialize(transaction);
  const sentTx = await jsonRPCProvider.perform("sendTransaction", {
    signedTransaction: txBytes,
  });
  return await jsonRPCProvider.getTransaction(sentTx);
};
