import { proxy } from "valtio";
import { Token } from "interfaces/balance";
import { balance_contract_wtout_signer } from "../contracts/Contract";
import { formatBigNumber } from "../common/utils";
import { AcceptedTokens, ContractAddress } from "contracts/config";
import { DECIMALS } from "functions/betUtils";

const chainID = Number(process.env.REACT_APP_CHAIN_ID);
const tokens: Token[] = AcceptedTokens[chainID] || [];
const contractAddress = ContractAddress[Number(chainID)];

export let myBalanceProxy = proxy<{
  isLoading: boolean;
  data: Token[];
  balanceOf: number;
  getBalance: (address: string) => void;
  updateBalance: (newValue: number) => void;
}>({
  isLoading: false,
  data: [],
  balanceOf: 0,
  getBalance: async (address: string) => {
    try {
      const balancePromises = tokens.map(async (token) => {
        const { address: acceptToken, decimals } = token;
        console.log(
          "🚀 ~ file: myBalanceProxy.ts ~ getBalance ~ acceptToken",
          acceptToken
        );

        const blContract = balance_contract_wtout_signer(acceptToken);
        console.log("🚀 ~ balancePromises ~ address:", address);
        const myBalanceOf = await blContract.balanceOf(address);
        console.log("🚀 ~ balancePromises ~ myBalanceOf:", myBalanceOf);
        return {
          ...token,
          balance: Number(formatBigNumber(myBalanceOf, true, decimals)),
        };
      });

      const updatedBalance = await Promise.all(balancePromises);

      const acceptToken = contractAddress?.zkUSD;
      const spenderAddress = contractAddress?.Wager;
      const blContract = balance_contract_wtout_signer(acceptToken);

      const myBalanceOf = await blContract.freeBalanceBySpender(
        address,
        spenderAddress
      );
      console.log("updatedBalance:", updatedBalance);
      myBalanceProxy.data = updatedBalance;
      myBalanceProxy.balanceOf = Number(
        formatBigNumber(myBalanceOf, true, DECIMALS)
      );
    } catch (error) {
      console.log("myBalanceProxy error", error);
    }
  },
  updateBalance: (newValue: number) => {
    myBalanceProxy.balanceOf = newValue;
  },
});
export const MyBalanceFunc = {
  balanceOf: myBalanceProxy.balanceOf,
  setBalanceOf: (value: number) => {
    myBalanceProxy.balanceOf = value;
  },
};
