import React from "react";
export const NotiSkeleton = () => {
  return (
    <>
      <div className="rounded-box bg-base-100/20 group w-full p-4">
        <div className="h-full w-full animate-pulse">
          <div className="flex items-center gap-4">
            <div className="isolate flex -space-x-2 shrink-0 opacity-30 w-8 justify-center">
              <div className="w-5 h-5 bg-base-content rounded-full z-[0]"></div>
            </div>

            <div className="w-full">
              <div className="flex flex-row items-center justify-between w-full space-x-5">
                <div className="flex flex-col space-y-2 opacity-30">
                  <div className="w-24 h-2 bg-base-content rounded-box"></div>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between w-full space-x-5 mt-3">
                <div className="flex flex-1 flex-col space-y-2 opacity-30 pr-16">
                  <div className="w-5/6 h-2 bg-base-content rounded-box"></div>
                  <div className="w-full h-2 bg-base-content rounded-box"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
