import { proxy } from "valtio";
import { onSnapshot } from "firebase/firestore";
import { UserWagers } from "../interfaces/firebase";
import _ from "lodash";
import {
  queryUserWagerByIds,
  queryValidUserWagersByAddress,
} from "../utils/firebase/firebaseQuery";
import { proxyMap } from "valtio/utils";

let unsubscribes: any[] = [];

class UserWagerProxy {
  isLoading: boolean = false;
  items: Map<string, UserWagers> = proxyMap<string, UserWagers>([]);
  subscribed: boolean = false;
  totalWon: number = 0;

  private parseDoc(doc: any) {
    return {
      amount: doc.amount,
      created_at: doc.created_at,
      wager_id: doc.wager_id,
      isRedeemed: doc.isRedeemed,
      status: doc.status,
      redeemAmount: doc.redeemAmount || 0,
      redeemable: doc?.redeemable || 0,
      outcomeIds: doc?.outcome_ids || [],
      txhash: doc?.txhash || [],
      odds: doc?.odds,
    };
  }

  get data() {
    return Array.from(this.items.values());
  }

  load(account: string) {
    if (this.subscribed) return;
    this.subscribed = true;
    this.isLoading = true;
    const query = queryValidUserWagersByAddress(account);

    unsubscribes.push(
      onSnapshot(query, (snapshot) => {
        this.isLoading = false;
        this.items.clear();

        snapshot.docs.forEach((doc) => {
          const _doc = doc?.data();

          if (_doc?.amount > 0) {
            this.items.set(doc?.id, {
              id: doc?.id,
              ...this.parseDoc(_doc),
            } as any);
          }
          if (_doc?.status === 4) {
            this.totalWon += _doc?.redeemAmount;
          }
        });
      })
    );
  }

  loadByIds(account: string, ids: string[], onComplete?: Function) {
    const fetchData = (ids: string[]) => {
      return new Promise((resolve, reject) => {
        let firstRequest = true;
        const ref = queryUserWagerByIds(account, ids);
        unsubscribes.push(
          onSnapshot(ref, (snapshot) => {
            snapshot.docs.forEach((doc) => {
              const _doc = doc?.data();
              this.items.set(doc?.id, {
                id: doc?.id,
                ...this.parseDoc(_doc),
              } as any);
            });
            if (firstRequest) {
              firstRequest = false;
              resolve(ids);
            }
          })
        );
      });
    };

    const listenIds = [];
    for (const id of ids) {
      if (!this.items.has(id)) {
        listenIds.push(id);
      }
    }
    if (listenIds.length > 0) {
      Promise.all(
        _.chunk(listenIds, 10).map((chunkedUserWagers) =>
          fetchData(chunkedUserWagers)
        )
      ).then(() => {
        if (onComplete) {
          onComplete(ids);
        }
      });
    }
  }

  unsubscribeDb() {
    this.subscribed = false;
    for (const unsubscribe of unsubscribes) {
      if (unsubscribe) unsubscribe();
    }
  }
}

export const userWagerProxy = proxy(new UserWagerProxy());
